import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.scss';
import header from '../../assets/header.png';
import { api } from '../../services/apiClient';

const PdfImpressao = ({ orcamentoId }) => {
  const pdfRef = useRef(null);

  const [orcamentoData, setOrcamentoData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrcamento = async (id) => {
    try {
      const response = await api.get(`/orcamentos-id/${id}`);
      const responseOrcamento = response.data;

      setOrcamentoData(responseOrcamento);
      setLoading(false);
      return responseOrcamento;
    } catch (error) {
      console.error('Erro ao buscar o orçamento:', error);
    }
  };

  const dataIso = orcamentoData?.criado_em;
  const dataObj = new Date(dataIso);

  const opcoesDeFormatacaoData = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  const opcoesDeFormatacaoHora = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const dataFormatada = dataObj.toLocaleDateString('pt-BR', opcoesDeFormatacaoData);
  const horaFormatada = dataObj.toLocaleTimeString('pt-BR', opcoesDeFormatacaoHora);

  useEffect(() => {
    getOrcamento(orcamentoId);
  }, [orcamentoId]);

  return (
    <>
      <div ref={pdfRef} id='conteudoParaPDF'>
        {orcamentoData.id ? (
          <div className={styles.container}>
            <div className={styles.conteudoParaPDF}>
              <div className={styles.header}>
                <svg className={styles.svgLogo} xmlns='http://www.w3.org/2000/svg' width='44' height='46' viewBox='0 0 44 46' fill='none'>
                  <path
                    d='M8.14815 9.5877C8.14815 11.7664 6.32412 13.5325 4.07407 13.5325C1.82403 13.5325 0 11.7664 0 9.5877C0 7.40903 1.82403 5.64288 4.07407 5.64288C6.32412 5.64288 8.14815 7.40903 8.14815 9.5877Z'
                    fill='#D67D32'
                  />
                  <path
                    d='M44 3.67046C44 5.63126 42.3584 7.22081 40.3333 7.22081C38.3083 7.22081 36.6667 5.63126 36.6667 3.67046C36.6667 1.70966 38.3083 0.120117 40.3333 0.120117C42.3584 0.120117 44 1.70966 44 3.67046Z'
                    fill='#D67D32'
                  />
                  <path
                    d='M23.6296 42.7243C23.6296 44.4672 22.1704 45.8801 20.3704 45.8801C18.5703 45.8801 17.1111 44.4672 17.1111 42.7243C17.1111 40.9813 18.5703 39.5684 20.3704 39.5684C22.1704 39.5684 23.6296 40.9813 23.6296 42.7243Z'
                    fill='#D67D32'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M24.4064 29.6242C24.4316 29.3919 24.4444 29.1561 24.4444 28.9174C24.4444 25.2136 21.3436 22.2112 17.5185 22.2112C13.6934 22.2112 10.5926 25.2136 10.5926 28.9174C10.5926 32.6211 13.6934 35.6236 17.5185 35.6236C18.1633 35.6236 18.7875 35.5382 19.3798 35.3786C20.1035 36.4748 21.3715 37.2015 22.8148 37.2015C25.0649 37.2015 26.8889 35.4353 26.8889 33.2567C26.8889 31.6248 25.8656 30.2244 24.4064 29.6242ZM24.4064 29.6242C23.9175 29.4231 23.3796 29.3118 22.8148 29.3118C20.5648 29.3118 18.7407 31.078 18.7407 33.2567C18.7407 34.0378 18.9752 34.7659 19.3798 35.3786C22.0744 34.6524 24.1074 32.3882 24.4064 29.6242Z'
                    fill='#D67D32'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M29.1188 17.2212C30.2809 15.619 30.963 13.6667 30.963 11.5601C30.963 9.17033 30.0851 6.97907 28.6246 5.27219C29.3504 5.002 30.1388 4.85391 30.963 4.85391C34.563 4.85391 37.4815 7.67977 37.4815 11.1656C37.4815 14.6515 34.563 17.4774 30.963 17.4774C30.3225 17.4774 29.7036 17.3879 29.1188 17.2212ZM29.1188 17.2212C27.2763 19.7617 24.2273 21.4222 20.7778 21.4222C15.1527 21.4222 10.5926 17.0068 10.5926 11.5601C10.5926 6.11345 15.1527 1.69805 20.7778 1.69805C23.9348 1.69805 26.7564 3.08885 28.6246 5.27219C26.1797 6.18229 24.4444 8.47775 24.4444 11.1656C24.4444 14.0313 26.4168 16.451 29.1188 17.2212Z'
                    fill='#D67D32'
                  />
                </svg>

                <div className={styles.infosHeader}>
                  <h1>Tessa</h1>
                  <div className={styles.subInfosHeader}>
                    <div className={styles.detalhesProposta}>
                      <h2>
                        N° proposta: <span>#{orcamentoData?.id.split('-')}</span>
                      </h2>
                      <div>
                        <h2>
                          Data: <span>{dataFormatada}</span> Hora: <span>{horaFormatada}</span>
                        </h2>
                      </div>
                    </div>
                    <div className={styles.orcamentoPara}>
                      <h2>
                        Orçamento Para: <span>{orcamentoData?.cliente.nome_cliente}</span>
                      </h2>
                      <h2>
                        Representante: <span>{orcamentoData?.representante.nome_contato}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <section className={styles.dadosCliente}>
                <h2>Dados do cliente</h2>
                <div className={styles.inputContainerRow}>
                  <div className={styles.inputContainer}>
                    <label>Nome do cliente</label>
                    <input value={orcamentoData?.cliente.nome_cliente} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Celular</label>
                    <input value={orcamentoData?.cliente.celular} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>E-mail</label>
                    <input value={orcamentoData?.cliente.email} />
                  </div>
                </div>
                <div className={styles.inputContainerRow}>
                  <div className={styles.inputContainer}>
                    <label>CNPJ</label>
                    <input value={orcamentoData?.cliente.documento} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Cidade</label>
                    <input value={orcamentoData?.endereco.cidade} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Cep</label>
                    <input value={orcamentoData?.endereco.cep} />
                  </div>
                </div>
              </section>

              <section className={styles.dadosCliente}>
                <h2>Dados do Projeto</h2>
                <div className={styles.inputContainerRow}>
                  <div className={styles.inputContainer}>
                    <label>Tipo de estrutura</label>
                    <input value={orcamentoData?.tipo_estrutura} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Largura do módulo FV</label>
                    <input value={orcamentoData?.largura_palca} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Com. Módulo FV</label>
                    <input value={orcamentoData?.comprimento_placa} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Vento médio</label>
                    <input value={orcamentoData?.MEDIA ? orcamentoData.MEDIA.toFixed(2) : ''} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Potência Módulos FV</label>
                    <input value={orcamentoData?.potencia_placa} />
                  </div>
                </div>
                <div className={styles.inputContainerRow}>
                  <div className={styles.inputContainer}>
                    <label>Local da instalação</label>
                    <input value={orcamentoData?.endereco.cidade} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Isopleta indicada</label>
                    <input value={orcamentoData?.isopleta_indicada} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Isopleta utilizada</label>
                    <input value={orcamentoData?.isopleta_selecionada} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Vento max</label>
                    <input value={orcamentoData?.MAXIMO ? orcamentoData.MAXIMO.toFixed(2) : ''} />
                  </div>
                  <div className={styles.inputContainer}>
                    <label>Vento min</label>
                    <input value={orcamentoData?.MINIMO ? orcamentoData.MINIMO.toFixed(2) : ''} />
                  </div>
                </div>
                <div className={styles.inputContainerRow}>
                  <div className={styles.inputContainer}>
                    <label>Incluir</label>
                    <div className={styles.checkboxArea}>
                      <input type='checkbox' id='montagem' className={styles.customCheckbox} checked={orcamentoData?.incluir_montagem} />
                      <label htmlFor='montagem' className={styles.customCheckboxLabel}>
                        Montagem
                      </label>
                    </div>
                    <div>
                      <input type='checkbox' id='clamp' className={styles.customCheckbox} checked={orcamentoData?.incluir_clip} />
                      <label htmlFor='clamp' className={styles.customCheckboxLabel}>
                        Clamp
                      </label>
                    </div>
                  </div>

                  <div className={styles.inputContainer}>
                    <label>Revestimento</label>
                    <div className={styles.checkboxArea}>
                      <div>
                        <input type='checkbox' id='z275' checked={orcamentoData?.revestimento === 'z275' ? true : false} className={styles.customCheckboxCircle} />
                        <label htmlFor='z275' className={styles.customCheckboxLabel}>
                          Z 275
                        </label>
                      </div>
                      <div>
                        <input type='checkbox' id='Pintado' checked={orcamentoData?.revestimento === 'Pintado' ? true : false} className={styles.customCheckboxCircle} />
                        <label htmlFor='Pintado' className={styles.customCheckboxLabel}>
                          Pintado
                        </label>
                      </div>
                      <div>
                        <input type='checkbox' id='galva' checked={orcamentoData?.revestimento === 'Galvanizado a fogo' ? true : false} className={styles.customCheckboxCircle} />
                        <label htmlFor='galva' className={styles.customCheckboxLabel}>
                          Galv. Fogo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className={styles.mesasModulos}>
                <div>
                  <h2>Layout do Projeto</h2>
                  <div className={styles.inputContainerColumn}>
                    {JSON.parse(orcamentoData?.mesas_paineis).map((conjunto) => (
                      <div className={styles.modulosEMesas}>
                        <div className={styles.inputContainer}>
                          <label>Mesas</label>
                          <input value={conjunto.mesa} />
                        </div>
                        <div className={styles.inputContainer}>
                          <label>Módulos FV</label>
                          <input value={conjunto.painel} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h2>Indicadores do projeto</h2>
                  <div className={styles.inputContainerColumn}>
                    <div className={styles.modulosEMesas}>
                      <div className={styles.inputContainer}>
                        <label>N° de vãos</label>
                        <input value={orcamentoData.numero_vaos} />
                      </div>
                      <div className={styles.inputContainer}>
                        <label>Área dos módulos</label>
                        <input value={orcamentoData?.area_placa + ' m'} />
                      </div>
                      <div className={styles.inputContainer}>
                        <label>R$ por módulos</label>
                        <input value={orcamentoData.valor_por_palca.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                      </div>
                      <div className={styles.inputContainer}>
                        <label>R$ por Watts</label>
                        <input value={orcamentoData.rs_watts.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                      </div>
                    </div>
                    <div className={styles.modulosEMesas}>
                      <div className={styles.inputContainer}>
                        <label>Quant. Placas FV</label>
                        <input value={orcamentoData.somaTotalPaineisFv} readOnly />
                      </div>
                      <div className={styles.inputContainer}>
                        <label>Terça Solo</label>
                        <input value={orcamentoData?.terca_solo} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <table className={styles.tabelaOrcamento}>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>NCM</th>
                    <th>Descrição do produto</th>
                    <th>Qtd.</th>
                    {orcamentoData.mostrar_valores_produtos ? (
                      <>
                        <th>Uni.</th>
                        <th>Valor</th>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(orcamentoData.listaProdutos).map((item) => (
                    <tr key={item.codItem}>
                      <td className={styles.ellipsis}>{item.codItem}</td>
                      <td>{item.ncm}</td>
                      <td>{item.desc}</td>
                      <td>{item.qtd}</td>
                      {orcamentoData.mostrar_valores_produtos ? (
                        <>
                          <td>{item.uni}</td>
                          <td>
                            {item.total.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className={styles.footerOrc}>
                <div className={styles.firstPart}>
                  <div className={styles.left}>
                    <h1>Orçamento para:</h1>

                    <p>{orcamentoData?.cliente.nome_cliente}</p>
                    <p>
                      {orcamentoData?.endereco.logradouro}, {orcamentoData?.endereco.cidade}, {orcamentoData?.endereco.estado}
                    </p>
                    <p>{orcamentoData?.cliente.telefone}</p>
                    <p>{orcamentoData?.cliente.email}</p>
                  </div>
                  <div className={styles.right}>
                    <table>
                      <thead>
                        <tr>
                          <th>Total proposta</th>
                          <th>{orcamentoData.valor_total_produtos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>IPI</td>
                          <td>{orcamentoData.valor_ipi.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={styles.total}>
                      <h1>TOTAL</h1>
                      <h1>{orcamentoData.valor_total_proposta.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h1>
                    </div>
                  </div>
                </div>
                <div className={styles.secondPart}>
                  {orcamentoData.valor_frete > 0 ? (
                    <p className={styles.textoFrete}>
                      Valor estimado do FRETE a ser pago pelo Cliente a Transportadora (não incluso no valor total) ={' '}
                      {orcamentoData.valor_frete.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  ) : (
                    <></>
                  )}

                  <div className={styles.partSign}>
                    <div>
                      <hr />
                      <p>Tessa tecnologia e desenvolvimento</p>
                    </div>
                    <div>
                      <hr />
                      <p>{orcamentoData?.cliente.nome_cliente}</p>
                    </div>
                  </div>
                  <div className={styles.aceite}>
                    <p>Aceite em __/__/__</p>
                  </div>

                  <div className={styles.especificacoesTecnicas}>
                    <h2>Especificações Técnicas do Produto:</h2>
                    <ul>
                      <li>Aço Galv. GI NBR 7008, revestimento Z 275 g/m². Limite mínimo LE 230 MPa e LR 340 MPa.</li>
                      <li>Colunas e tesouras na espessura de 1,95mm, Terças e Tirantes na espessura de 1,55mm</li>
                      <li>Velocidade do vento considerado 40 - 45 m/s e inclinação de 10 a 30 graus</li>
                    </ul>
                    <h2>Normas Utilizadas:</h2>
                    <ul className={styles.lstaSemBolinha}>
                      <li>A) NBR 6123:2003 - Forças relacionadas ao vento em Edificações</li>
                      <li>B) NBR14762:2010 - Dimensionamento de Estruturas de Aço construídas por perfis formados a Frio</li>
                      <li>C) NBR 8681:2003 - Ações e seguranças em estruturas</li>
                      <li>D) NBR 15575:2013 - Norma de desempenho</li>
                    </ul>
                    <h2>Condições Comerciais:</h2>
                    <ol>
                      <li>Faturamento para contribuinte SIM com ICMS de 18% incluso e IPI DE 3,25% sobre o valor total</li>
                      <li>Condição de pagamento: 100% Ancecipado, 0% No embarque e 0% 28 dias da emissão da NF</li>
                      <li>Validade da proposta comercial: 5 dias</li>
                      <li>Preços sujeitos a variação pelas Usinas Siderurgicas.</li>
                      <li>Frete por conta do Cliente (FOB)</li>
                      <li>Prazo de Entrega:</li>
                      <li>Fundação e Montagem de responsabilidade do cliente,</li>
                      <li>O aceite pela TESSA somente se dará após a analise de crédito e aprovação pelo Departamento Financeiro</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
        <div className={styles.containerCarregando}>
          <div className={styles.loading}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
        )}
      </div>
    </>
  );
};

export default PdfImpressao;
