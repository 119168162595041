import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import Input from '../../Components/Inputs';
import ButtonConcluir from '../../Components/ButtonConcluir';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';


const Produto = () => {
  const [codigo, setCodigo] = useState('');
  const [peso, setPeso] = useState('');
  const [custoMaoDeObra, setCustoMaoDeObra] = useState('');
  const [nomeItem, setNomeItem] = useState('');
  const [pesoBruto, setPesoBruto] = useState('');
  const [custoGalvanizacaoFogo, setCustoGalvanizacaoFogo] = useState('');
  const [ncm, setNcm] = useState('');
  const [volume, setVolume] = useState('');
  const [custopintura, setCustoPintura] = useState('');
  const [ipi, setIpi] = useState('');
  const [espessuraMateriaPrima, setEspessuraMateriaPrima] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [custoMp, setCustoMp] = useState('');
  const [codigoItem, setCodigoItem] = useState('');

  const [formulaProduto, setFormulaProduto] = useState('');

  const [loading, setLoading] = useState(false);

  const handleCadastro = async () => {
    setLoading(true);
    const data = {
      nome_item: nomeItem,
      ncm: ncm,
      ipi: ipi,
      peso_liquido: peso,
      peso_bruto: pesoBruto,
      volume: volume,
      espessura_materia_prima: espessuraMateriaPrima,
      custo_mp: custoMp,
      custo_mao_obra: custoMaoDeObra,
      custo_galvanizacao_fogo: custoGalvanizacaoFogo,
      custo_pintura: custopintura,
      custo_total: custoTotal,
      formula_quantidade: formulaProduto,
      codigo_item: codigoItem,
    };
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        toast.warn(`O campo ${key} é inválido!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);
        return;
      }
    }

    const response = await api.post('/produtos', data);

    if (response.status === 200) {
      toast.success(`produtos adicionado com sucesso!!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
      setPeso('');
      setCustoMaoDeObra('');
      setNomeItem('');
      setPesoBruto('');
      setCustoGalvanizacaoFogo('');
      setNcm('');
      setVolume('');
      setCustoPintura('');
      setIpi('');
      setEspessuraMateriaPrima('');
      setCustoTotal('');
      setCustoMp('');
      setCodigoItem('');
    } else {
      toast.warn(`Não foi possível adicionar produtos confirme os dados`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      
    }

    setLoading(false);
    console.log('response', response);
  };

  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <div className={styles.contato}>
          <h1>Produto</h1>
          <div className={styles['first-row']}>
            {/* <Input label='Código do item' placeHolder='Digite o Código do item' color='laranja' onChange={setCodigo} value={codigo} /> */}
            <Input label='Código do item' placeHolder='Digite o código' color='laranja' onChange={setCodigoItem} value={codigoItem} />
            <Input label='Nome do Item' placeHolder='Digite o Nome do Item' color='laranja' onChange={setNomeItem} value={nomeItem} />
            <Input label='Peso líquido' type='peso' placeHolder='Digite o Peso líquido' color='laranja' onChange={setPeso} value={peso} />
          </div>
          <div className={styles['first-row']}>
            <Input label='Custo de mão de obra (MOD)' type='custo' placeHolder='Digite o Custo de mão de obra (MOD)' color='laranja' onChange={setCustoMaoDeObra} value={custoMaoDeObra} />
            <Input label='Custo MP' type='custo' placeHolder='Digite o Custo MP' color='laranja' onChange={setCustoMp} value={custoMp} />
            <Input label='Custo de Galvanização a fogo' type='custo' placeHolder='Digite o Custo de Galvanização a fogo' color='laranja' onChange={setCustoGalvanizacaoFogo} value={custoGalvanizacaoFogo} />
          </div>
          <div className={styles['first-row']}>
            <Input label='Peso Bruto' type='peso' placeHolder='Digite o Peso Bruto' color='laranja' onChange={setPesoBruto} value={pesoBruto} />

            <Input label='Volume' placeHolder='Digite o  Volume' color='laranja' onChange={setVolume} value={volume} />
            <Input label='Custo da pintura' type='custo' placeHolder='Digite o Custo da pintura' color='laranja' onChange={setCustoPintura} value={custopintura} />
          </div>
          <div className={styles['first-row']}>
            <Input label='IPI' placeHolder='Digite o IPI' color='laranja' onChange={setIpi} value={ipi} />
            <Input label='Espessura da matéria prima (MP)' placeHolder='Digite o Espessura da matéria prima (MP)' color='laranja' onChange={setEspessuraMateriaPrima} value={espessuraMateriaPrima} />
            <Input label='Custo Total' type='custo' placeHolder='Digite o Custo Total' color='laranja' onChange={setCustoTotal} value={custoTotal} />
          </div>
          <div className={styles['first-row']}>
            <Input label='NCM' placeHolder='Digite o  NCM' color='laranja' onChange={setNcm} value={ncm} />

            <Input nullable={true} />
            <Input nullable={true} />
          </div>
          <div className={styles.textArea}>
            <label>Formula</label>
            <textarea
              value={formulaProduto}
              onChange={(e) => setFormulaProduto(e.target.value)}
            />
          </div>
        </div>
        <ButtonConcluir label='Concluir cadastro' onClickFunction={handleCadastro} isLoading={loading} />
      </div>
    </div>
  );
};

export default Produto;
