import React from "react";
import styles from "./style.module.scss";
import Input from "../Inputs";
const ModalProduct = ({ dataModal, onClose }) => {
  const { nome_item, custo_galvanizacao_fogo, custo_mao_obra, custo_mp, custo_pintura, custo_total, espessura_materia_prima, formula_quantidade, ipi, ncm, peso_bruto, peso_liquido, volume } =
    dataModal;

  return (
    <div className={styles.modalBackdrop} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.headerModal}>
          <h1>{dataModal.nome_item}</h1>
          <div className={styles.containerButtons}>
            <button className={styles.closeButton} onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
        <div className={styles.bodyModal}>
          <div className={styles.rowBodyModal}>
            <Input label="Custo galvanização a fogo" placeHolder="" color="laranja" onChange={null} value={custo_galvanizacao_fogo.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} />
            <Input label="Custo mão de obra" placeHolder="" color="laranja" onChange={null} value={custo_mao_obra.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} />
            <Input label="Custo matéria prima" placeHolder="" color="laranja" onChange={null} value={custo_mp.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} />
          </div>
          <div className={styles.rowBodyModal}>
            <Input type="custo" label="Custo pintura" placeHolder="" color="laranja" onChange={null} value={custo_pintura.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} />
            <Input type="custo" label="Custo total" placeHolder="" color="laranja" onChange={null} value={custo_total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} />
            <Input label="Espessura matéria prima" placeHolder="" color="laranja" onChange={null} value={espessura_materia_prima} />
          </div>
          <div className={styles.rowBodyModal}>
            <Input label="IPI" placeHolder="" color="laranja" onChange={null} value={ipi} />
            <Input label="NCM" placeHolder="" color="laranja" onChange={null} value={ncm} />
            <Input label="Peso bruto" placeHolder="" color="laranja" onChange={null} value={peso_bruto} />
          </div>
          <div className={styles.rowBodyModal}>
            <Input label="Peso líquido" placeHolder="" color="laranja" onChange={null} value={peso_liquido} />
            <Input label="Volume" placeHolder="" color="laranja" onChange={null} value={volume} />
          </div>
          <div className={styles.textArea}>
            <label>Formula</label>
            <textarea value={formula_quantidade} onChange={null} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModalProduct);
