import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout, ConfigProvider, Button } from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import SidebarOfficial from './Components/Sidebar';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Orcamento from './Pages/Orcamento';
import Client from './Pages/Clients';
import Representante from './Pages/Representante';
import Produto from './Pages/Produtos';
import Usuarios from './Pages/Usuarios';

import BaseProdutos from './Pages/Bases/Produtos';

import ptBR from 'antd/lib/locale/pt_BR';
import BaseClientes from './Pages/Bases/Clientes';
import BaseRepresentantes from './Pages/Bases/Representantes';

const { Content, Header, Footer } = Layout;

const customLocale = {
  ...ptBR,
  Pagination: {
    ...ptBR.Pagination,
    items_per_page: '/ página',
    colorPrimary: '#00897F',
  },
};

const tokenConfig = {
  token: {
    colorPrimary: '#00897F',
    colorPrimaryBorder: 'rgba(0, 137, 127, 0.53)',
    colorPrimaryHover: '#00897F',
  },
};

const App = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const { isAuthenticated, user } = useAuth();

  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType('mobile');
      } else if (width >= 768 && width < 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Chamar na montagem para definir o valor inicial

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ConfigProvider locale={customLocale} theme={tokenConfig}>
          <Layout style={{ minHeight: '100vh' }}>
            {isAuthenticated() && <SidebarOfficial collapsed={collapsed} toggleCollapsed={toggleCollapsed} userLevel={user ? user.tipoUsuario : 'visitante'} />}
            <Layout>
              <Content>
                {isAuthenticated() ? (
                  <Routes>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/orcamento' element={<Orcamento />} />
                    <Route path='/cadastro/cliente' element={<Client />} />
                    <Route path='/cadastro/representante' element={<Representante />} />
                    <Route path='/cadastro/produtos' element={<Produto />} />
                    <Route path='/usuarios' element={<Usuarios />} />
                    <Route path='/base/produtos' element={<BaseProdutos />} />
                    <Route path='/base/clientes' element={<BaseClientes />} />
                    <Route path='/base/representantes' element={<BaseRepresentantes />} />
                  </Routes>
                ) : (
                  <Login />
                )}
                <ToastContainer />
              </Content>
            </Layout>
          </Layout>
        </ConfigProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
