import React, { useState } from 'react';
import styles from './style.module.scss';
import { toast } from 'react-toastify';
import Input from '../../Components/Inputs';
import ButtonConcluir from '../../Components/ButtonConcluir';
import { useAuth } from '../../contexts/AuthContext'; // Importe o hook useAuth para acessar as funções de autenticação
import { api } from '../../services/apiClient';

const Usuarios = () => {
  const { user } = useAuth(); // Use o hook useAuth para acessar a função de login

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [nivel, setNivel] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangePass = async () => {
    setLoading(true);
    const data = {
      id: user.id,
      senha: novaSenha,
    };
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        toast.warn(`O campo ${key} é inválido!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);
        return;
      }
    }
    if (novaSenha === confirmacaoSenha) {
      const response = await api.put('/password', data);
      setLoading(false);

      if (response.status === 200) {
        toast.success(`Senha alterada com sucesso!!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error(`Não foi possivel alterar a senha`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    } else {
      toast.error(`As senhas não coincidem!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }

    console.log('mudar senha');
  };

  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <h1>Ser perfil</h1>
        <svg stroke='currentColor' fill='#D67D32' strokeWidth='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
          <path d='M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'></path>
        </svg>
        <Input label='Nome' color='gray' value={user?.nome} disable={true}/>
        <Input label='E-mail' color='gray' value={user?.email} disable={true}/>
        <Input label='Nível' color='gray' value={user?.is_admin ? 'Administrador' : 'Básico'} disable={true}/>
        <Input label='Nova Senha' placeHolder='Digite a nova senha' color='laranja' onChange={setNovaSenha} value={novaSenha} />
        <Input label='Confirmação de senha' placeHolder='Confirme a senha' color='laranja' onChange={setConfirmacaoSenha} value={confirmacaoSenha} />
        <ButtonConcluir label='Alterar senha' onClickFunction={handleChangePass} isLoading={loading} />
      </div>
    </div>
  );
};

export default Usuarios;
