import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Input from "../../Components/Inputs";
import ButtonConcluir from "../../Components/ButtonConcluir";
import { toast } from "react-toastify";
import { api } from "../../services/apiClient";

import MultipleSelectRepresentante from '../../Components/MultipleSelectRepresentante'

const Client = () => {
  const [codigo, setCodigo] = useState("");
  const [representante, setRepresentante] = useState("");
  const [foneFixo, setFoneFixo] = useState("");
  const [nomeContato, setNomeContat] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");

  const [nomeCliente, setNomeCliente] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inscricao, setInscricao] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("fisica");

  const [endereco, setEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");

  const [complemento, setComplemento] = useState("");

  const [loading, setLoading] = useState(false);
  const [representantesArray, setRepresentantesArray] = useState([]);
  const [selectedRepresentante, setSelectedRepresentante] = useState("");

  const handleConcluirCadastro = async () => {
    setLoading(true);
    const data = {
      documento: cnpj,
      inscricao_estadual: inscricao,
      nome_cliente: nomeCliente,
      representante_id: selectedRepresentante,
      telefone: foneFixo,
      celular: celular,
      email: email,
      pessoa_juridico: tipoPessoa === "fisica" ? false : true,
      logradouro: endereco,
      cep: cep,
      bairro: bairro,
      cidade: cidade,
      uf: estado,
      numero: numero,
      complemento: complemento === "" ? " " : complemento,
    };
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === "") {
        toast.warn(`O campo ${key} é inválido!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false);
        return;
      }
    }
    const response = await api.post("/clientes", data);

    if (response.status === 200) {
      toast.success(`cliente adicionado com sucesso!!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false);

      setRepresentante("");
      setFoneFixo("");
      setCelular("");
      setEmail("");
      setEstado("");
      setCnpj("");
      setEndereco("");
      setCep("");
      setNumero("");
      setCidade("");
      setBairro("");
      setComplemento("");
      setNomeContat("");
      setInscricao("");
    } else {
      toast.warn(`Não foi possível adicionar cliente confirme os dados`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false);
    }

    setLoading(false);
  };

  const getAllRepresentantes = async () => {
    const response = await api.get("/representantes");
    const representantesData = response.data;
    console.log("representantesData", representantesData);
    setRepresentantesArray(representantesData);
  };

  useEffect(() => {
    getAllRepresentantes();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <div className={styles["infos-first-row"]}>
          <div className={styles.contato}>
            <h1>Contato</h1>
            <div className={styles["first-row"]}>
              <div className={styles.selectArea}>
                <label className={styles[`label-laranja`]}>Representante</label>
                <MultipleSelectRepresentante options={representantesArray} handleSelectEstado={setSelectedRepresentante} selectedValue={selectedRepresentante} />
              </div>
              <Input label="E-mail" type="email " placeHolder="Digite o E-mail" color="laranja" onChange={setEmail} value={email} />
            </div>
            <div className={styles["first-row"]}>
              <Input label="Fone fixo" type="telefone" placeHolder="Digite o Fone fixo" color="laranja" onChange={setFoneFixo} value={foneFixo} />
              <Input label="Nome do Contato" placeHolder="Digite o Nome do Contato" color="laranja" onChange={setNomeContat} value={nomeContato} />
            </div>
            <div className={styles["first-row"]}>
              <Input label="Celular" type="celular" placeHolder="Digite o Celular" color="laranja" onChange={setCelular} value={celular} />
              <Input nullable={true} />
            </div>
          </div>
          <div className={styles.empresa}>
            <div className={styles["second-row"]}>
              <h1>Informações da empresa</h1>
              <Input label="Nome do Cliente" placeHolder="Digite o Nome do Cliente" color="laranja" onChange={setNomeCliente} value={nomeCliente} />
              <Input label="CNPJ/CPF" type="cnpj_cpf" placeHolder="Digite o CNPJ" color="laranja" onChange={setCnpj} value={cnpj} />
              <Input label="Inscrição estadual" placeHolder="Digite a Inscrição estadual" color="laranja" onChange={setInscricao} value={inscricao} />

              <div className={styles["button-area"]}>
                <label className={styles["label-gray"]}>Tipo de pessoa</label>
                <div>
                  <button onClick={() => setTipoPessoa("fisica")} className={tipoPessoa === "fisica" ? styles.active : ""}>
                    Física
                  </button>
                  <button onClick={() => setTipoPessoa("juridica")} className={tipoPessoa === "juridica" ? styles.active : ""}>
                    Júridica
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.endereco}>
          <h1>Endereço</h1>

          <div className={styles["first-row"]}>
            <Input label="Endereço" placeHolder="Digite o Endereço" color="laranja" onChange={setEndereco} value={endereco} />
            <Input label="CEP" type="cep" color="laranja" onChange={setCep} value={cep} />
          </div>
          <div className={styles["first-row"]}>
            <Input label="Número" placeHolder="Digite o Número" color="laranja" onChange={setNumero} value={numero} />
            <Input label="Estado" placeHolder="Digite o Estado" color="laranja" onChange={setEstado} value={estado} />
          </div>
          <div className={styles["first-row"]}>
            <Input label="Cidade" placeHolder="Digite a Cidade" color="laranja" onChange={setCidade} value={cidade} />
            <Input label="Bairro" placeHolder="Digite o Bairro" color="laranja" onChange={setBairro} value={bairro} />
          </div>
          <div className={styles["first-row"]}>
            <Input label="Complemento" placeHolder="Caso não tiver deixar nulo" color="laranja" onChange={setComplemento} value={complemento} />
            <Input nullable={true} />
          </div>
        </div>
        <ButtonConcluir label="Concluir cadastro" onClickFunction={handleConcluirCadastro} isLoading={loading} />
      </div>
    </div>
  );
};

export default Client;
