import React, { useState, useEffect, useCallback } from "react";
import styles from "./style.module.scss";
import InputMask from "react-input-mask";

const Input = ({ label, placeHolder, color, onChange, value, nullable = false, type, limit, disable = false }) => {
  const [currentMask, setCurrentMask] = useState("");

  const masks = {
    telefone: "(99) 9999-9999",
    celular: "(99) 99999-9999",
    cnpj_cpf: "999.999.999-99999",
    cep: "99999-999",
    custo: "",
    default: "",
    peso: "9999.99"
  };
  
  const maskPlaceholders = {
    telefone: "(xx) xxxx-xxxx",
    celular: "(xx) xxxxx-xxxx",
    cnpj_cpf: "CPF ou CNPJ",
    cep: "xxxxx-xxx",
    custo: "R$ 0,00",
    peso: "00,00",
  };

  useEffect(() => {
    setCurrentMask(masks[type] || masks.default);
  }, [type]);

  const maskPlaceholder = maskPlaceholders[type] || "";

  const handleInputCepChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (e) => {
      if (type === "limite" && limit && e.target.value.length > limit) {
        e.target.value = e.target.value.slice(0, limit);
      }
      onChange(e.target.value);
    },
    [limit, onChange, type]
  );

  const handleInputCustoChange = useCallback(
    (e) => {
      const rawValue = e.target.value.replace(/[^\d]+/g, "");
      const formattedValue = formatMoney(rawValue);
      onChange(formattedValue);
    },
    [onChange]
  );

  const formatMoney = (value) => {
    if (value.length === 0) return "";

    const intValue = parseInt(value, 10);
    if (isNaN(intValue)) return "";

    const formattedValue = (intValue / 100).toFixed(2);
    return `R$ ${formattedValue}`;
  };

  return (
    <div className={styles["input-area"]}>
      {nullable ? (
        <> </>
      ) : (
        <>
          <label className={styles[`label-${color}`]}>{label}</label>
          {type === "email" ? (
            <input
              type="email"
              className={styles[`input-${color}`]}
              placeholder={placeHolder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
              title="Por favor, insira um e-mail válido."
            />
          ) : type === "cep" ? (
            <InputMask mask={currentMask} disabled={disable} placeholder={maskPlaceholder || placeHolder} value={value} onChange={handleInputCepChange} className={styles[`input-${color}`]} />
          ) : type === "cnpj_cpf" ? (
            <InputMask mask={currentMask} disabled={disable} placeholder={maskPlaceholder || placeHolder} value={value} onChange={handleInputChange} className={styles[`input-${color}`]} />
          ) : type === "custo" ? (
            <InputMask mask={currentMask} disabled={disable} placeholder={maskPlaceholder || placeHolder} value={value} onChange={handleInputCustoChange} className={styles[`input-${color}`]} />
          ) : (
            <InputMask mask={currentMask} disabled={disable} placeholder={maskPlaceholder || placeHolder} value={value} onChange={handleInputChange} className={styles[`input-${color}`]} />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(Input);
