import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { api } from '../services/apiClient';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  const login = useCallback(async (emailUser, password) => {
    try {
      const response = await api.post('/login', {
        email: emailUser,
        password,
      });

      const { id, nome, email, is_admin, telefone, documento, endereco, token } = response.data;

      setUser({ id, nome, email, is_admin, telefone, documento, endereco });
      localStorage.setItem('tessaCrm', token);
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const logout = useCallback(() => {
    console.log('chamou o logout');
    setUser(null);
    localStorage.removeItem('tessaCrm');
    window.location.href = '/';
  }, []);

  const checkAuthentication = useCallback(async () => {
    const authToken = localStorage.getItem('tessaCrm');

    if (authToken) {
      try {
        const response = await api.get('/user', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.data) {
          const { id, nome, email, is_admin, telefone, documento, endereco, token } = response.data;
          setUser({ id, nome, email, is_admin, telefone, documento, endereco });
        } else {
          logout();
        }
      } catch (error) {
        console.error('Erro ao verificar autenticação', error);
      }
    }
  }, []);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  const isAuthenticated = useCallback(() => {
    return !!user;
  }, [user]);

  return <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
