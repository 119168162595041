import React, { useState, useEffect, useCallback } from 'react';
import { DataTable } from 'mantine-datatable';
import { createStyles } from '@mantine/core';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Form, Input } from 'antd';
import InputMask from 'react-input-mask';
import PdfImpressao from '../../Pages/PdfImpressao';
import html2pdf from 'html2pdf.js';

const printPdfOptions = {
  margin: 0,
  filename: `orcamento_${new Date().getTime()}.pdf`,
  html2canvas: { scale: 2 },
  jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4' },
};
const PAGE_SIZES = [5, 10, 15, 20, 50, 100];

const ETAPAS = ['Orçamento feito', 'Pedido em análise', 'Em negociação', 'Venda feita', 'Venda não feita'];
const ETAPA_COLORS = {
  'Orçamento feito': '#34996F',
  'Pedido em análise': '#339DCD',
  'Em negociação': '#D563FD',
  'Venda feita': '#EAC194',
  'Venda não feita': 'red',
};

const Table = ({ dashData, setDashData, filteredProdutos }) => {
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);
  const [page, setPage] = useState(1);
  const [showSelect, setShowSelect] = useState(false);
  const [orcamentoId, setOrcamentoId] = useState();
  const useStyles = createStyles((theme) => ({
    root: {
      border: `1px solid #FFF`,
      borderRadius: '50px',
      background: '#E9E9E9',
      tr: {
        background: '#E9E9E9',
        height: '3rem',
      },
      zIndex: 0,
    },
    header: {
      zIndex: 0,
    },
    td: {
      input: {
        zIndex: 99,
      },
    },

    pagination: {
      color: theme.colors.black,
      'button[data-active="true"]': {
        background: theme.colors.blue[4],
      },
      'button[data-active="true"]:not([data-disabled="true"]):hover': {
        background: theme.colors.blue[5],
      },

      background: '#E9E9E9',
    },

    modalHeader: {
      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
      marginBottom: theme.spacing.md,
    },
    modalTitle: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[8],
      fontWeight: 700,
    },
    modalContent: {
      maxWidth: 300,
    },
    modalLabel: { width: 80 },
  }));

  const { classes } = useStyles();

  useEffect(() => {
    console.log('dashData.slice(0, pageSize)', dashData.slice(0, pageSize));
  }, [dashData, pageSize]);

  const handleSelectChange = useCallback(
    async (id, valueEtapa) => {
      const data = {
        etapa: valueEtapa,
      };

      try {
        await api.put(`/orcamentos/${id}`, data);
        toast.success(`Etapa alterada com sucesso!!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });

        const updatedDashData = dashData.map((item) => {
          if (item.id === id) {
            return { ...item, etapa: valueEtapa };
          }
          return item;
        });

        setDashData(updatedDashData);
      } catch (error) {
        console.error('Error updating etapa:', error);
      }

      setShowSelect(false);
    },
    [dashData]
  );

  const handlePrint = useCallback((id) => {
    setOrcamentoId(id);
    handleOpenModal();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCliente, setIsModalOpenCliente] = useState(false);
  const [modalClienteData, setModalClienteData] = useState({});

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const formatarTelefone = (telefone) => {
    if (!telefone) return;
    const numeros = telefone.replace(/\D/g, '');

    if (numeros.length === 11) {
      return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (numeros.length === 10) {
      return numeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return numeros;
  };

  const abrirModalContato = (cliente) => {
    console.log('cliente', cliente);
    setIsModalOpenCliente(true);
    setModalClienteData(cliente);
  };

  const printPdf = useCallback(async () => {
    try {
      const element = document.getElementById('conteudoParaPDF');
      if (element) {
        const worker = html2pdf().from(element).set(printPdfOptions).toPdf().get('pdf');

        worker.then((pdf) => {
          var blob = pdf.output('blob');
          var url = URL.createObjectURL(blob);
          window.open(url, '_blank');
          URL.revokeObjectURL(url);
        });
      } else {
        console.error('Element not found');
      }
    } catch (error) {
      console.error('Error generating PDF', error);
    }
  }, []);

  return (
    <>
      <DataTable
        withBorder
        borderRadius='sm'
        withColumnBorders
        highlightOnHover
        records={filteredProdutos.slice((page - 1) * pageSize, page * pageSize)}
        columns={[
          {
            accessor: 'Cliente',
            ellipsis: true,
            width: 200,
            render: (record) => {
              return (
                <button className={styles.nomeCliente} onClick={() => abrirModalContato(record.cliente)}>
                  <p>{record.cliente.nome_cliente}</p>
                </button>
              );
            },
          },
          {
            accessor: 'Etapa',
            width: 100,
            render: (record) => {
              return (
                <select value={record.etapa} onChange={(e) => handleSelectChange(record.id, e.target.value)} onBlur={() => setShowSelect(false)} className={styles.selectEtapa}>
                  {ETAPAS.map((etapa) => (
                    <option key={etapa} value={etapa}>
                      {etapa}
                    </option>
                  ))}
                </select>
              );
            },
            cellsStyle: (record) => {
              const etapaValue = record.etapa;
              const backgroundColor = ETAPA_COLORS[etapaValue];
              return {
                background: backgroundColor || 'defaultColor',
                padding: '0 !important',
              };
            },
          },
          {
            accessor: 'representante.nome_contato',
            title: 'Representante',
            width: 100,
            ellipsis: true,
            render: (record) => {
              return <p className={styles.sellerName}>{record.representante.nome_contato}</p>;
            },
          },
          {
            accessor: 'Contato',
            ellipsis: true,
            width: 150,
            render: (record) => {
              return (
                <button className={styles.commomBackground} onClick={() => abrirModalContato(record.cliente)}>
                  <p>{formatarTelefone(record.cliente.celular)}</p>
                </button>
              );
            },
          },
          {
            accessor: 'MEDIA',
            title: 'Vento médio',
            width: 100,
            render: (record) => {
              const roundedMedia = Number(record.MEDIA).toFixed(2);
              return <p className={styles.lastSele}>{roundedMedia}</p>;
            },
          },
          {
            accessor: 'incluir_clip',
            title: 'Clamp',
            width: 100,
            render: (record) => {
              const style = record.incluir_clip ? { background: '#7cd67e' } : { background: '#e38374' };
              return (
                <p className={styles.lastSele} style={style}>
                  {record.incluir_clip ? 'Sim' : 'Não'}
                </p>
              );
            },
          },
          {
            accessor: 'incluir_montagem',
            title: 'Montagem',
            width: 100,
            render: (record) => {
              const style = record.incluir_montagem ? { background: '#7cd67e' } : { background: '#e38374' };
              return (
                <p className={styles.lastSele} style={style}>
                  {record.incluir_montagem ? 'Sim' : 'Não'}
                </p>
              );
            },
          },
          {
            accessor: 'tipo_estrutura',
            title: 'Estrutura',
            width: 100,
            render: (record) => {
              return <p className={styles.lastSele}>{record.tipo_estrutura}</p>;
            },
          },
          {
            accessor: '',
            title: 'Imprimir',
            width: 50,
            render: (record) => {
              return (
                <button onClick={() => handlePrint(record.id)} className={styles.printButton}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path
                      d='M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V6H19.5V4.5C19.5 3.70435 19.1839 2.94129 18.6213 2.37868C18.0587 1.81607 17.2956 1.5 16.5 1.5H7.5ZM16.5 13.5H7.5C7.10218 13.5 6.72064 13.658 6.43934 13.9393C6.15804 14.2206 6 14.6022 6 15V19.5C6 19.8978 6.15804 20.2794 6.43934 20.5607C6.72064 20.842 7.10218 21 7.5 21H16.5C16.8978 21 17.2794 20.842 17.5607 20.5607C17.842 20.2794 18 19.8978 18 19.5V15C18 14.6022 17.842 14.2206 17.5607 13.9393C17.2794 13.658 16.8978 13.5 16.5 13.5Z'
                      fill='#fff'
                    />
                    <path
                      d='M0 10.5C0 9.70435 0.316071 8.94129 0.87868 8.37868C1.44129 7.81607 2.20435 7.5 3 7.5H21C21.7956 7.5 22.5587 7.81607 23.1213 8.37868C23.6839 8.94129 24 9.70435 24 10.5V15C24 15.7956 23.6839 16.5587 23.1213 17.1213C22.5587 17.6839 21.7956 18 21 18H19.5V15C19.5 14.2044 19.1839 13.4413 18.6213 12.8787C18.0587 12.3161 17.2956 12 16.5 12H7.5C6.70435 12 5.94129 12.3161 5.37868 12.8787C4.81607 13.4413 4.5 14.2044 4.5 15V18H3C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.316071 16.5587 0 15.7956 0 15V10.5ZM3.75 12C3.94891 12 4.13968 11.921 4.28033 11.7803C4.42098 11.6397 4.5 11.4489 4.5 11.25C4.5 11.0511 4.42098 10.8603 4.28033 10.7197C4.13968 10.579 3.94891 10.5 3.75 10.5C3.55109 10.5 3.36032 10.579 3.21967 10.7197C3.07902 10.8603 3 11.0511 3 11.25C3 11.4489 3.07902 11.6397 3.21967 11.7803C3.36032 11.921 3.55109 12 3.75 12Z'
                      fill='#fff'
                    />
                  </svg>
                </button>
              );
            },
          },
        ]}
        classNames={classes}
        totalRecords={filteredProdutos.length} // Calcula o número total de registros com base nos registros filtrados
        recordsPerPage={pageSize}
        page={page}
        onPageChange={(p) => setPage(p)}
        recordsPerPageOptions={PAGE_SIZES}
        onRecordsPerPageChange={setPageSize}
        loadingText='Carregando...'
        noRecordsText='Nenhum registro encontrado!'
        paginationText={({ from, to, totalRecords }) => `Registros ${from} - ${to} de ${totalRecords}`}
        textSelectionDisabled
      />

      <Modal title='Impressão' open={isModalOpen} onOk={printPdf} onCancel={() => setIsModalOpen(false)} width={900} okText='Imprimir'>
        <PdfImpressao orcamentoId={orcamentoId} />
      </Modal>

      <Modal title='Dados do cliente' open={isModalOpenCliente} onOk={() => setIsModalOpenCliente(false)} onCancel={() => setIsModalOpenCliente(false)} width={900}>
        <Form layout='vertical'>
          <div>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item label='Cliente'>
                  <Input value={modalClienteData.nome_cliente} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item label='Celular'>
                  <Input value={formatarTelefone(modalClienteData.celular)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item label='E-mail'>
                  <Input value={modalClienteData.email} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item label='CPF' className={styles.formItem}>
                  <div className={styles.inputGroup}>
                    <InputMask mask='999.999.999-99' value={modalClienteData.documento}>
                      {(inputProps) => <Input {...inputProps} />}
                    </InputMask>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default Table;
