import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { api } from '../../../services/apiClient';
import { Select } from 'antd';
import ModalRepresentante from '../../../Components/ModalRepresentants';
import { FiHome, FiSearch } from 'react-icons/fi';
import { DataTable } from 'mantine-datatable';
import { createStyles } from '@mantine/core';

const PAGE_SIZES = [5, 10, 15, 20, 50, 100];

const BaseRepresentantes = () => {
  const [arrayProdutos, setArrayProdutos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(filteredProdutos.slice(0, pageSize));
  const [loading, setLoading] = useState(false);
  const [recordClick, setRecordClick] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getProdutos = async () => {
    const response = await api.get('/representantes');
    console.log('response', response.data)
    setArrayProdutos(response.data);
  };

  useEffect(() => {
    getProdutos();
  }, []);

  useEffect(() => {
    const results = arrayProdutos.filter(produto =>
      produto.nome_contato.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProdutos(results);
  }, [searchTerm, arrayProdutos]);

  useEffect(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    setRecords(filteredProdutos.slice(start, end));
  }, [filteredProdutos, page, pageSize]);


  const useStyles = createStyles((theme) => ({
    root: {
      color: '#fff',
      border: `1px solid #d67d32`,
      borderRadius: '50px',
      background: '#4e4e4e',
      tr: {
        th: {
          color: '#d67d32 !important',
        },
        color: '#d67d32 !important',

        background: '#4e4e4e',
        height: '3rem',
      },
      zIndex: 0,
    },
    header: {
      zIndex: 0,
    },
    td: {
      input: {
        zIndex: 99,
      },
    },
    pagination: {
      color: '#fff',
      'button[data-active="true"]': {
        background: theme.colors.blue[4],
      },
      'button[data-active="true"]:not([data-disabled="true"]):hover': {
        background: theme.colors.blue[5],
      },
      background: '#4e4e4e',
    },
    modalHeader: {
      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
      marginBottom: theme.spacing.md,
    },
    modalTitle: {
      color: '#fff',
      fontWeight: 700,
    },
    modalContent: {
      maxWidth: 300,
    },
    modalLabel: { width: 80 },
  }));

  const { classes } = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.containerTables}>
        <div style={{ marginBottom: 20 }}>
          <Select
            suffixIcon={<FiSearch />}
            allowClear={true}
            showSearch
            style={{ width: 250 }}
            placeholder='Buscar cliente'
            optionFilterProp='children'
            onChange={(value) => setSearchTerm(value || '')}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {arrayProdutos.map((cliente) => (
              <Select.Option key={cliente.id} value={cliente.nome_contato}>
                {cliente.nome_contato}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.containerTable}>
          <DataTable
            withBorder
            borderRadius='sm'
            withColumnBorders
            records={records}
            columns={[
              // { accessor: 'id', title: 'ID', textAlignment: 'right', width: 15, ellipsis: true },
              { accessor: 'nome_contato', title: 'Nome', ellipsis: true, width: 250 },
              { accessor: 'celular', title: 'Celular', ellipsis: true, width: 100 },
              { accessor: 'cnpj', title: 'CNPJ', ellipsis: true, width: 100 },
              { accessor: 'email', title: 'E-mail', ellipsis: true, width: 100 },
            ]}
            classNames={classes}
            totalRecords={arrayProdutos.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            loadingText='Carregando...'
            noRecordsText='Nenhum registro encontrado!'
            paginationText={({ from, to, totalRecords }) => `Registros ${from} - ${to} de ${totalRecords}`}
            textSelectionDisabled
            onCellClick={({ event, record, index, column, columnIndex }) => {
              console.log('columnIndex', columnIndex);
              console.log('column', column);
              console.log('index', index);
              console.log('record', record);
              console.log('event', event);
              setRecordClick(record);
              handleOpenModal();
            }}
          />
        </div>
      </div>
      {isModalOpen && <ModalRepresentante onClose={handleCloseModal} dataModal={recordClick} />}
    </div>
  );
};

export default BaseRepresentantes;
