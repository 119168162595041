import React, { useState, useEffect, useCallback } from 'react';
import styles from './style.module.scss';
import Table from '../../Components/Table';
import { api } from '../../services/apiClient';
import { Select } from 'antd';
import { FiHome, FiSearch } from 'react-icons/fi';
import { FaChevronDown } from 'react-icons/fa';

const PAGE_SIZES = [5, 10, 15, 20, 50, 100];

const Dashboard = () => {
  const [dashData, setDashData] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const getDash = async () => {
      try {
        const response = await api.get('/orcamentos');
        setDashData(response.data);
        setFilteredProdutos(response.data); // Inicialmente, exibir todos os resultados
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    getDash();
  }, []); // Executa apenas uma vez ao montar o componente

  const handleSearch = useCallback((value) => {
    const results = dashData.filter(item =>
      item.cliente.nome_cliente.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProdutos(results);
  }, [dashData]);

  return (
    <div className={styles.container}>
      <div className={styles.searchArea}>
        <h1>Início</h1>
        <div>
          <Select
            suffixIcon={<FiSearch />}
            allowClear={true}
            showSearch
            style={{ width: 250 }}
            placeholder='Buscar cliente'
            optionFilterProp='children'
            onChange={handleSearch}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {/* Usar filteredProdutos para as opções do Select */}
            {filteredProdutos.map((item) => (
              <Select.Option key={item.cliente.id} value={item.cliente.nome_cliente}>
                {item.cliente.nome_cliente}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.tab}>
          <button>
            <FiHome style={{ fontSize: '24px' }}/>
            Padrão
          </button>
        </div>
        <hr />
        <div className={styles.selectContainer}>
          <FaChevronDown style={{ position: 'absolute', left: '5px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
          <select>
            <option>Orçamentos cadastrados</option>
          </select>
        </div>
      </div>
      <div className={styles.container}>
      <Table dashData={dashData} setDashData={setDashData} filteredProdutos={filteredProdutos} handleSearch={handleSearch} />
    </div>
    </div>
  );
};

export default Dashboard;
