import React, { useState, useEffect, useCallback } from 'react';
import styles from './style.module.scss';
import Modal from '../../Components/Modal';
import Input from '../../Components/Inputs';
import ButtonConcluir from '../../Components/ButtonConcluir';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';
import tabelaEstados from './tabelaEstados';
import MultipleSelectClientes from '../../Components/MultipleSelectClientes';
import MultipleSelect from '../../Components/MultipleSelect';
import { useAuth } from '../../contexts/AuthContext';
import PdfImpressao from '../PdfImpressao';
import ModalParameters from '../../Components/ModalParameters';

const Orcamento = () => {
  const { user } = useAuth();
  const [currentDate, setCurrentDate] = useState('');
  const [numberPaineis, setNumberPaineis] = useState(1);
  const [tipoPessoa, setTipoPessoa] = useState('fisica');
  const [industriaOuProdutor, setIndustriaOuProdutor] = useState(false);
  const [foneFixo, setFoneFixo] = useState('');
  const [nomeContato, setNomeContat] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricao, setInscricao] = useState('');
  const [endereco, setEndereco] = useState('');
  const [cep, setCep] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [icms, setIcms] = useState('');
  const [isopletaIndicada, setIsopletaIndicada] = useState('');
  const [comprimentoPlaca, setComprimentoPlaca] = useState('');
  const [isopletaSelecionada, setIsopletaSelecionada] = useState('');
  const [potenciaPlaca, setPotenciaPlaca] = useState('');
  const [tipoEstrutura, setTipoEstrutura] = useState('');
  const [quantidadePlaca, setQuantidadePlaca] = useState('');
  const [larguraPlaca, setLarguraPlaca] = useState('');
  const [quantidadeMesas, setQuantidadeMesas] = useState('');
  const [areaPlaca, setAreaPlaca] = useState('');
  const [tecaSolo, setTercaSolo] = useState('CALCULAR');
  const [incluirMontagem, setIncluirMontagem] = useState(false);
  const [incluirClip, setIncluirClip] = useState(true);
  const [z275, setZ275] = useState(true);
  const [Pintado, setPintado] = useState();
  const [galva, setGalva] = useState();
  const [zerarTercas, setZerarTercas] = useState(false);
  const [paineisData, setPaineisData] = useState([{ mesa: '', painel: '' }]);
  const [clienteArray, setClientesArray] = useState([]);
  const [clienteSelected, setClienteSelected] = useState('');
  const [selectedEstado, setSelectedEstado] = useState('');
  const [representantesArray, setRepresentantesArray] = useState([]);
  const [selectedRepresentante, setSelectedRepresentante] = useState('');
  const [MINIMO, setMINIMO] = useState();
  const [MAXIMO, setMAXIMO] = useState();
  const [MEDIA, setMEDIA] = useState();
  const [OFICIAL, setOFICIAL] = useState();
  const [orcamentoId, setOrcamentoId] = useState('');
  const [loading, setLoading] = useState(false);
  const [distanciaEntrePlacas, setDistanciaEntrePlacas] = useState(2);
  const [valorFrete, setValorFrete] = useState(0);
  const [mostrarValoresProdutos, setMostrarValoresProdutos] = useState(true);
  const [cidadeCliente, setCidadeCliente] = useState('');

  const changeRevestimento = (revestimentoValue) => {
    if (revestimentoValue === 'z275') {
      setZ275(true);
      setPintado(false);
      setGalva(false);
    } else if (revestimentoValue === 'Pintado') {
      setZ275(false);
      setPintado(true);
      setGalva(false);
    } else if (revestimentoValue === 'galva') {
      setZ275(false);
      setPintado(false);
      setGalva(true);
    }
  };

  const addPainel = useCallback(() => {
    setNumberPaineis((prev) => prev + 1);
    setPaineisData((prev) => [...prev, { mesa: '', painel: '' }]);
  }, []);

  const removePainel = useCallback(
    (indexToRemove) => {
      setNumberPaineis((prev) => prev - 1);
      setPaineisData((prev) => prev.filter((_, index) => index !== indexToRemove));
    },
    [numberPaineis]
  );

  const handleInputChange = useCallback(
    (index, field, value) => {
      const newData = [...paineisData];
      newData[index][field] = value;
      setPaineisData(newData);

      let newQuantidadePlaca = 0;
      let newQuantidadeMesas = 0;

      newData.forEach((value) => {
        newQuantidadePlaca += Number(value.mesa) * Number(value.painel);
        newQuantidadeMesas += Number(value.mesa);
      });

      setQuantidadePlaca(newQuantidadePlaca);
      setQuantidadeMesas(newQuantidadeMesas);
    },
    [paineisData]
  );

  const handleGerarOrcamento = useCallback(async () => {
    setLoading(true);

    const data = {
      usuario_id: user.id,
      representante_id: selectedRepresentante,
      cliente_id: clienteSelected,
      industria_produtor_rural: industriaOuProdutor,
      icms: icms,
      isopleta_indicada: isopletaIndicada.toString(),
      isopleta_selecionada: isopletaSelecionada,
      tipo_estrutura: tipoEstrutura,
      largura_palca: larguraPlaca,
      comprimento_placa: comprimentoPlaca,
      potencia_placa: potenciaPlaca,
      quantidade_placa: quantidadePlaca,
      quantidade_mesas: quantidadeMesas,
      area_placa: areaPlaca,
      terca_solo: tecaSolo ? tecaSolo : ' ',
      revestimento: z275 ? 'z275' : Pintado ? 'Pintado' : galva ? 'Galvanizado a fogo' : '',
      incluir_montagem: incluirMontagem,
      incluir_clip: incluirClip,

      paineis_data: JSON.stringify(paineisData),
      zerar_tercas: zerarTercas,
      cep: cep ? cep : 'naoInformado',
      estado: estado,
      cidade: cidade,
      latitude: latitude ? latitude : 'naoInformado',
      longitude: longitude ? longitude : 'naoInformado',
      MINIMO: MINIMO,
      MAXIMO: MAXIMO,
      MEDIA: MEDIA,
      OFICIAL: OFICIAL,
      etapa: 'Orçamento feito',
      distanciaModulos: distanciaEntrePlacas,
      valor_frete: valorFrete,
      mostrar_valores_produtos: mostrarValoresProdutos,
    };
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        toast.warn(`O campo ${key} é inválido!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);

        return;
      }
    }
    try {
      const response = await api.post('/orcamentos', data);
      console.log('response', response);
      if (response.status === 200) {
        setOrcamentoId(response.data.id);
        handleOpenModal();
        toast.success(`Orçamento adicionado com sucesso!!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);
      } else {
        toast.warn(`Não foi possível adicionar o Orçamento confirme os dados`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);
      }
    } catch (e) {
      console.log('erro ao gerar orçamento: ', e);
      toast.warn(`Não foi possível adicionar o Orçamento confirme os dados`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
    }

    setLoading(false);
  }, [
    selectedRepresentante,
    clienteSelected,
    industriaOuProdutor,
    icms,
    isopletaIndicada,
    isopletaSelecionada,
    tipoEstrutura,
    larguraPlaca,
    comprimentoPlaca,
    potenciaPlaca,
    quantidadePlaca,
    quantidadeMesas,
    areaPlaca,
    z275,
    Pintado,
    galva,
    incluirMontagem,
    incluirClip,
    paineisData,
    zerarTercas,
    cep,
    estado,
    cidade,
    latitude,
    longitude,
    MINIMO,
    MAXIMO,
    MEDIA,
    OFICIAL,
  ]);

  const getAllClients = useCallback(async () => {
    const response = await api.get('/clientes');
    const clienteArrayData = response.data;
    setClientesArray(clienteArrayData);
  }, []);
  const getAllRepresentantes = useCallback(async () => {
    const response = await api.get('/representantes');
    const representantesData = response.data;
    console.log('representantesData', representantesData)
    setRepresentantesArray(representantesData);
  }, []);

  const handleSelectRepresentante = useCallback(
    (value) => {
      console.log('value', value)
      console.log('representantesArray', representantesArray)
      const selectedRepresentante = representantesArray?.find((representante) => representante?.id === value);
      console.log('selectedRepresentante', selectedRepresentante)
      console.log('selectedRepresentante', selectedRepresentante)
      

      if (selectedRepresentante) {
        setSelectedRepresentante(selectedRepresentante.representante_id);
       
      } else {
        console.log('Cliente não encontrado');
      }
    },
    [representantesArray]
  );

  const handleSelectCliente = useCallback(
    (value) => {
      const selectedClient = clienteArray.find((cliente) => cliente.id === value);

      if (selectedClient) {
        selectIcmBaseadoEndCliente(selectedClient.endereco.cidade);
        console.log('selectedClient', selectedClient);
        setSelectedRepresentante(selectedClient.representante_id);
        setCidadeCliente(selectedClient.endereco.cidade);
        setClienteSelected(selectedClient.id);
        setFoneFixo(selectedClient.telefone);
        setNomeContat(selectedClient.nome_cliente);
        setCelular(selectedClient.celular);
        setEmail(selectedClient.email);
        setNomeCliente(selectedClient.nome_cliente);
        setCnpj(selectedClient.documento);
        setInscricao(selectedClient.inscricao_estadual);
        setTipoPessoa(selectedClient.pessoa_juridica ? 'fisica' : 'juridica');
      } else {
        console.log('Cliente não encontrado');
      }
    },
    [clienteArray]
  );

  const removerAcentos = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const selectIcmBaseadoEndCliente = (cidadeCliente) => {
    const cidadeClienteSemAcento = removerAcentos(cidadeCliente.toLowerCase());
    console.log('cidadeCliente', cidadeClienteSemAcento);
    const selectedEstado = tabelaEstados.find((estado) => removerAcentos(estado['CIDADE'].toLowerCase()) === cidadeClienteSemAcento);
    console.log('tabelaEstados', tabelaEstados);
    console.log('selectedEstado', selectedEstado);

    if (selectedEstado) {
      setIcms(selectedEstado['ICMSCONTR']);
    } else {
      console.log('Estado não encontrado para a cidade:', cidadeClienteSemAcento);
      return null;
    }
  };

  const handleSelectEstado = useCallback(
    (e) => {
      console.log('e', e);
      const selectedEstadoId = e;
      const selectedEstado = tabelaEstados.find((estado) => estado.id === selectedEstadoId);

      if (selectedEstado) {
        setCidade(selectedEstado.CIDADE);
        setEstado(selectedEstado.SIGLA);
        setSelectedEstado(selectedEstado.id);
        // setIcms(selectedEstado['ICMSCONTR']);
        setIsopletaIndicada(selectedEstado.ISOPLETA);

        setMINIMO(selectedEstado.MINIMO);
        setMAXIMO(selectedEstado.MAXIMO);
        setMEDIA(selectedEstado.MEDIA);
        setOFICIAL(selectedEstado.OFICIAL);
      }
    },
    [tabelaEstados]
  );

  const [selectedEstadoId, setSelectedEstadoId] = useState(null);

  const handleCepChange = useCallback(
    async (cep) => {
      const newCep = cep.replace(/\D/g, '');
      setCep(newCep);
      if (newCep.length === 8 && /^[0-9]+$/.test(newCep)) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${newCep}/json/`);
          const data = await response.json();
          const selectedEstado = tabelaEstados.find((estado) => estado.CIDADE === data.localidade);
          setEndereco(data.logradouro);
          if (selectedEstado) {
            setCidade(selectedEstado.CIDADE);
            setEstado(selectedEstado.SIGLA);
            setSelectedEstado(selectedEstado.id);
            setIcms(selectedEstado['ICMSCONTR']);
            setIsopletaIndicada(selectedEstado.ISOPLETA);
            setMINIMO(selectedEstado.MINIMO);
            setMAXIMO(selectedEstado.MAXIMO);
            setMEDIA(selectedEstado.MEDIA);
            setOFICIAL(selectedEstado.OFICIAL);
            setSelectedEstadoId(selectedEstado.id);
            console.log(selectedEstadoId);
          }
        } catch (error) {
          console.log('O erro é:', error);
        }
      }
    },
    [tabelaEstados]
  );

  const handleCalcularAreaComprimento = (event) => {
    const comprimento = parseFloat(event);
    setComprimentoPlaca(comprimento);
    const areaM2 = (comprimento * larguraPlaca) / 1000000;
    setAreaPlaca(areaM2.toFixed(2));
  };

  const handleCalcularAreaLargura = (event) => {
    const largura = parseFloat(event);
    setLarguraPlaca(largura);
    const areaM2 = (comprimentoPlaca * largura) / 1000000;
    setAreaPlaca(areaM2.toFixed(2));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const [isModalParametersOpen, setIsModalParametersOpen] = useState(false);

  const handleOpenModalParameters = useCallback(() => {
    setIsModalParametersOpen(true);
  }, []);

  const handleCloseModalParameters = useCallback(() => {
    setIsModalParametersOpen(false);
  }, []);

  useEffect(() => {
    getAllRepresentantes();
    getAllClients();
  }, [getAllRepresentantes, getAllClients]);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
    setCurrentDate(formattedDate);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.infos}>
          <div className={styles.header}>
            <div className={styles.headerInfos}>
              <div className={styles.headerText}>
                <label>Data</label>
                <p>{currentDate}</p>
              </div>
              <div className={styles.svgAddParameters} onClick={setIsModalParametersOpen}>
                <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 20 20' fill='none'>
                  <rect y='0.000488281' width='20' height='20' rx='2' fill='#D67D32' />
                  <path d='M4 10.0005H16' stroke='#3E3E3D' strokeWidth='2' strokeLinecap='round' />
                  <path d='M10 16.0005L10 4.00049' stroke='#3E3E3D' strokeWidth='2' strokeLinecap='round' />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.infosOrcamento}>
            <div className={styles['infos-first-row']}>
              <div className={styles.contato}>
                <h1>Contato</h1>
                <div className={styles['first-row']}>
                  <div className={styles.selectArea}>
                    <label className={styles[`label-laranja`]}>Representante</label>
                    <div className={styles.selectAreaCliente}>
                      <MultipleSelectClientes options={representantesArray} handleSelectCliente={handleSelectRepresentante} selectedValue={selectedRepresentante} isRepresentante={true} />
                    </div>
                  </div>
                  <div className={styles.selectArea}>
                    <label className={styles[`label-laranja`]}>Cliente</label>
                    <div className={styles.selectAreaCliente}>
                      <MultipleSelectClientes options={clienteArray} handleSelectCliente={handleSelectCliente} selectedValue={clienteSelected} />
                    </div>
                  </div>
                </div>
                <div className={styles['first-row']}>
                  <Input disable={true} label='Fone fixo' type='telefone' placeHolder='Digite o Fone fixo' color='laranja' onChange={setFoneFixo} value={foneFixo} />
                  <Input disable={true} label='Nome do Contato' placeHolder='Digite o Nome do Contato' color='laranja' onChange={setNomeContat} value={nomeContato} />
                </div>
                <div className={styles['first-row']}>
                  <Input disable={true} label='Celular' type='celular' placeHolder='Digite o Celular' color='laranja' onChange={setCelular} value={celular} />
                  <Input disable={true} label='E-mail' type='email ' placeHolder='Digite o E-mail' color='laranja' onChange={setEmail} value={email} />
                </div>
                <div className={styles['first-row']}>
                  <Input disable={true} label='Cidade' placeHolder='Digite a cidade' color='laranja' onChange={setCidadeCliente} value={cidadeCliente} />
                  <Input nullable={true} />
                </div>
              </div>
              <div className={styles.empresa}>
                <div className={styles['second-row']}>
                  <h1>Informações da empresa</h1>
                  <Input disable={true} label='Nome do Cliente' placeHolder='Digite o Nome do Cliente' color='laranja' onChange={setNomeCliente} value={nomeCliente} />
                  <Input disable={true} label='Cnpj/CPF' type='cnpj_cpf' placeHolder='Digite o Cnpj' color='laranja' onChange={setCnpj} value={cnpj} />
                  <Input disable={true} label='Inscrição estadual' placeHolder='Digite a Inscrição estadual' color='laranja' onChange={setInscricao} value={inscricao} />
                  <div className={styles['button-area']}>
                    <label className={styles['label-gray']}>Tipo de pessoa</label>
                    <div>
                      <button onClick={() => setTipoPessoa('fisica')} className={tipoPessoa === 'fisica' ? styles.active : ''}>
                        Física
                      </button>
                      <button onClick={() => setTipoPessoa('juridica')} className={tipoPessoa != 'fisica' ? styles.active : ''}>
                        Júridica
                      </button>
                    </div>
                  </div>
                  <div className={styles['button-area']}>
                    <label className={styles['label-gray']}>Tipo de empresa</label>
                    <div>
                      <button onClick={() => setIndustriaOuProdutor(true)} className={industriaOuProdutor ? styles.active : ''}>
                        {/* Sim para produtor e nao para industria */}
                        Produtor rural
                      </button>
                      <button onClick={() => setIndustriaOuProdutor(false)} className={!industriaOuProdutor ? styles.active : ''}>
                        Industria
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className={styles['infos-first-row']}>
              <div className={styles.endereco}>
                <h1>Endereço de instalação</h1>

                <div className={styles['first-row']}>
                  <div className={styles.selectArea}>
                    <label className={styles[`label-laranja`]}>Cidade - Estado</label>
                    <MultipleSelect options={tabelaEstados} handleSelectEstado={handleSelectEstado} selectedValue={selectedEstadoId} />
                  </div>
                  <Input label='Cep' type='cep' placeHolder='Digite o Cep' color='gray' onChange={handleCepChange} value={cep} />
                </div>
                <div className={styles['first-row']}>
                  <Input label='Endereço' placeHolder='Digite o Endereço' color='gray' onChange={setEndereco} value={endereco} />
                  <Input label='Latitude' placeHolder='Digite a latitude' color='gray' onChange={setLatitude} value={latitude} />
                </div>
                <div className={styles['first-row']}>
                  <Input label='Longitude' placeHolder='Digite a longitude' color='gray' onChange={setLongitude} value={longitude} />
                  <Input nullable={true} />
                </div>
              </div>
              <div className={styles.empresa}>
                <div className={styles['second-row']}>
                  <h1>Informações adicionais</h1>
                  {/* <Input label='ICMS' placeHolder='Digite o ICMS' color='gray' onChange={setNumero} value={numero} /> */}
                  <Input label='ICMS' disable={true} placeHolder='Selecione o estado para preencher o ICMS' color='laranja' onChange={() => console.log()} value={icms} />
                </div>
              </div>
            </div>
            <hr />
            <div className={styles['infos-first-row']}>
              <div className={styles.contato}>
                <h1>Informações sobre a estrutura</h1>
                <div className={styles['first-row']}>
                  <div className={styles.selectArea}>
                    <label className={styles[`label-laranja`]}>Estrutura</label>
                    <select onChange={(e) => setTipoEstrutura(e.target.value)} value={tipoEstrutura}>
                      <option value=''>Selecione um tipo</option>
                      <option value='SOLO 1L'>SOLO 1L</option>
                      <option value='SOLO 2L'>SOLO 2L</option>
                      <option value='CARPORT 2P'>CARPORT 2P</option>
                      <option value='CARPORT 2P 5.5'>CARPORT 2P 5.5</option>
                      <option value='CARPORT 3P'>CARPORT 3P</option>
                      <option value='CARP. 3P 5.5'>CARP. 3P 5.5</option>
                      <option value='CARPORT 4P'>CARPORT 4P</option>
                    </select>
                  </div>
                  <Input disable={true} label='Isopleta indicada' placeHolder='Digite a Isopleta indicada' color='gray' onChange={setIsopletaIndicada} value={isopletaIndicada} />
                </div>
                <div className={styles['first-row']}>
                  <Input label='Isopleta selecionada' placeHolder='Digite a Isopleta selecionada' color='laranja' onChange={setIsopletaSelecionada} value={isopletaSelecionada} />
                  <Input label='Largura da módulo' type='limite' limit={4} placeHolder='Digite a Largura da módulo' color='laranja' onChange={handleCalcularAreaLargura} value={larguraPlaca} />
                </div>
                <div className={styles['first-row']}>
                  <Input label='Comprimento da módulo' type='limite' limit={4} placeHolder='Digite o Comprimento da módulo' color='laranja' onChange={handleCalcularAreaComprimento} value={comprimentoPlaca} />

                  <Input label='Potência da módulo' placeHolder='Digite a Potência da módulo' color='laranja' onChange={setPotenciaPlaca} value={potenciaPlaca} />
                </div>
                <div className={styles['first-row']}>
                  <Input disable={true} label='Quantidade de Módulos' placeHolder='Digite a Quantidade de placas' color='gray' onChange={setQuantidadePlaca} value={quantidadePlaca} />
                  <Input disable={true} label='Quantidade de mesas' placeHolder='Digite a Quantidade de mesas' color='gray' onChange={setQuantidadeMesas} value={quantidadeMesas} />
                </div>
                <div className={styles['first-row']}>
                  {tipoEstrutura && tipoEstrutura.toLowerCase().includes('solo') && (
                    <div className={styles.selectArea}>
                      <label className={styles[`label-laranja`]}>Terça Solo</label>
                      <select onChange={(e) => setTercaSolo(e.target.value)} value={tecaSolo}>
                        <option value=''>Selecione um tipo</option>
                        <option value='TERÇA FV SOLO 2000'>TERÇA FV SOLO 2000</option>
                        <option value='TERÇA FV SOLO 2200'>TERÇA FV SOLO 2200</option>
                        <option value='TERÇA FV SOLO 3000'>TERÇA FV SOLO 3000</option>
                        <option value='TERÇA FV SOLO 3200'>TERÇA FV SOLO 3200</option>
                        <option value='TERÇA FV SOLO 3750'>TERÇA FV SOLO 3750</option>
                        <option value='CALCULAR'>CALCULAR</option>
                      </select>
                    </div>
                  )}
                  <Input nullable={true} />
                </div>
              </div>
              <div className={styles.empresa}>
                <div className={styles['second-row']}>
                  <h1>Complemento estrutura</h1>
                  <Input label='Área da placa' placeHolder='Digite a  Área da placa' color='gray' onChange={setAreaPlaca} value={areaPlaca} />
                </div>
              </div>
            </div>
            <hr />
            <div className={styles.secondPart}>
              <div className={styles.secondPart}>
                <div className={styles.containerSecondPartItems}>
                  <h1>Deseja incluir</h1>
                  <div className={styles.checkboxArea}>
                    <div>
                      <input type='checkbox' id='montagem' className={styles.customCheckbox} checked={incluirMontagem} onChange={() => setIncluirMontagem(!incluirMontagem)} />
                      <label htmlFor='montagem' className={styles.customCheckboxLabel}>
                        Montagem
                      </label>
                    </div>
                    <div>
                      <input type='checkbox' id='area' className={styles.customCheckbox} checked={incluirClip} onChange={() => setIncluirClip(!incluirClip)} />
                      <label htmlFor='area' className={styles.customCheckboxLabel}>
                        Clamp
                      </label>
                    </div>
                  </div>

                  <h1>Zerar terças</h1>
                  <div className={styles.checkboxArea}>
                    <div>
                      <input type='checkbox' id='sim' checked={zerarTercas} className={styles.customCheckboxCircle} onChange={() => setZerarTercas(true)} />
                      <label htmlFor='sim' className={styles.customCheckboxLabel}>
                        Sim
                      </label>
                    </div>
                    <div>
                      <input type='checkbox' id='nao' checked={!zerarTercas} className={styles.customCheckboxCircle} onChange={() => setZerarTercas(false)} />
                      <label htmlFor='nao' className={styles.customCheckboxLabel}>
                        Não
                      </label>
                    </div>
                  </div>
                  <h1>Qual revestimento</h1>
                  <div className={styles.checkboxArea}>
                    <div>
                      <input type='checkbox' id='z275' checked={z275} className={styles.customCheckboxCircle} onChange={() => changeRevestimento('z275')} />
                      <label htmlFor='z275' className={styles.customCheckboxLabel}>
                        Z 275
                      </label>
                    </div>
                    <div>
                      <input type='checkbox' id='Pintado' checked={Pintado} className={styles.customCheckboxCircle} onChange={() => changeRevestimento('Pintado')} />
                      <label htmlFor='Pintado' className={styles.customCheckboxLabel}>
                        Pintado
                      </label>
                    </div>
                    <div>
                      <input type='checkbox' id='galva' checked={galva} className={styles.customCheckboxCircle} onChange={() => changeRevestimento('galva')} />
                      <label htmlFor='galva' className={styles.customCheckboxLabel}>
                        Galv. Fogo
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.containerSecondPartItems}>
                  <h1>Mesas e módulos</h1>
                  {paineisData.map((data, index) => (
                    <div className={styles.inputSecondPart} key={index}>
                      <div>
                        <label>Mesas</label>
                        <input value={data.mesa} onChange={(e) => handleInputChange(index, 'mesa', e.target.value)} />
                      </div>
                      <div className={styles.inputMesasColumnReverse}>
                        <input value={data.painel} onChange={(e) => handleInputChange(index, 'painel', e.target.value)} />
                        <div className={styles.containerAddMorePainel}>
                          <label>Módulos</label>
                          {index === numberPaineis - 1 ? (
                            <button className={styles.buttonAddPainel} onClick={addPainel}>
                              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                                <rect y='0.000488281' width='20' height='20' rx='2' fill='#D67D32' />
                                <path d='M4 10.0005H16' stroke='#3E3E3D' strokeWidth='2' strokeLinecap='round' />
                                <path d='M10 16.0005L10 4.00049' stroke='#3E3E3D' strokeWidth='2' strokeLinecap='round' />
                              </svg>
                            </button>
                          ) : (
                            <button className={styles.buttonRemovePainel} onClick={() => removePainel(index)}>
                              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                                <rect width='20' height='20' rx='2' fill='#D67D32' />
                                <path d='M4 10H16' stroke='#3E3E3D' strokeWidth='2' strokeLinecap='round' />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <ButtonConcluir isLoading={loading} label='Gerar Orçamento' onClickFunction={handleGerarOrcamento} />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <PdfImpressao orcamentoId={orcamentoId} />
        </Modal>
      )}
      {isModalParametersOpen && (
        <ModalParameters
          onClose={handleCloseModalParameters}
          distanciaEntrePlacas={distanciaEntrePlacas}
          setDistanciaEntrePlacas={setDistanciaEntrePlacas}
          valorFrete={valorFrete}
          setValorFrete={setValorFrete}
          mostrarValoresProdutos={mostrarValoresProdutos}
          setMostrarValoresProdutos={setMostrarValoresProdutos}
        />
      )}
    </>
  );
};

export default Orcamento;