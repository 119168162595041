const tabelaEstados = [
  {
    id: '16080774-7850-44a0-9b0a-e8dedacc46d0',
    CIDADE: "Alta Floresta D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.859737396240234,
    MAXIMO: 35.97930908203125,
    MEDIA: 33.78968175252279,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'f77515ae-2ca0-4abd-9fd8-2d5375439cbc',
    CIDADE: 'Ariquemes',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.79685974121094,
    MAXIMO: 35.9344482421875,
    MEDIA: 35.474573308771305,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '400211f2-a1a4-4911-8175-80322f915212',
    CIDADE: 'Cabixi',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 33.299251556396484,
    MAXIMO: 36.34791564941406,
    MEDIA: 34.78235054016113,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '437fcbe0-d937-45b6-87e6-ca0f233eb974',
    CIDADE: 'Cacoal',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 37.87994384765625,
    MAXIMO: 40.143253326416016,
    MEDIA: 39.10852864583333,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'ae839a21-1935-4cf6-a665-df9661c3f6fa',
    CIDADE: 'Cerejeiras',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.766992568969727,
    MAXIMO: 37.024147033691406,
    MEDIA: 34.398781882392036,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'cd900ce3-3db0-4c5b-9046-2cff0c6396bb',
    CIDADE: 'Colorado do Oeste',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.82171630859375,
    MAXIMO: 40.7187385559082,
    MEDIA: 38.920140402657644,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'afc0fd1a-5f35-4d5e-aefa-fc2c282f3d38',
    CIDADE: 'Corumbiara',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.0168342590332,
    MAXIMO: 39.434322357177734,
    MEDIA: 37.02385796440972,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '93c21679-89e5-46b0-bdbc-7aa1061a7d0c',
    CIDADE: 'Costa Marques',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.529747009277344,
    MAXIMO: 33.063167572021484,
    MEDIA: 32.12868067423503,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '8d262fd7-e9a1-48fe-aae2-9033243d6eea',
    CIDADE: "Espigão D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 40.130897521972656,
    MAXIMO: 41.99121856689453,
    MEDIA: 40.96454977989197,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '72af11d1-c605-4c2f-9a14-dbcb8e308280',
    CIDADE: 'Guajará-Mirim',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.7969970703125,
    MAXIMO: 34.31688690185547,
    MEDIA: 33.1015307188034,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '3f7296d8-20eb-4c57-bee3-e21d7b8b863f',
    CIDADE: 'Jaru',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.07743453979492,
    MAXIMO: 36.612369537353516,
    MEDIA: 36.05240058898926,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '80eed44b-117e-418a-a18c-02b978afb784',
    CIDADE: 'Ji-Paraná',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.74290466308594,
    MAXIMO: 38.174415588378906,
    MEDIA: 37.43899354480562,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '4e0c982a-9b62-4e96-be8b-9f5ba2b6d3f7',
    CIDADE: "Machadinho D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.27117156982422,
    MAXIMO: 36.2944450378418,
    MEDIA: 35.38753706833412,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'c71bf512-b788-4313-b82b-2fdbcc03d8d0',
    CIDADE: "Nova Brasilândia D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.286537170410156,
    MAXIMO: 36.952640533447266,
    MEDIA: 36.044297218322754,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'ea1062ff-15e8-4a90-99e3-a5589c53c6f4',
    CIDADE: 'Ouro Preto do Oeste',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.126502990722656,
    MAXIMO: 36.99536895751953,
    MEDIA: 36.71257019042969,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '48992996-1669-4585-ace6-5372d8da2aaf',
    CIDADE: 'Pimenta Bueno',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 38.338905334472656,
    MAXIMO: 44.171173095703125,
    MEDIA: 41.23340606689453,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'cafbe2e6-dd4c-4055-8a26-cafce7ffeca9',
    CIDADE: 'Porto Velho',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 32.412010192871094,
    MAXIMO: 34.79322814941406,
    MEDIA: 33.77014311233369,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'f0d796dc-8090-49b5-bbc7-1d9f4f23a660',
    CIDADE: 'Presidente Médici',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 37.04593276977539,
    MAXIMO: 38.17610549926758,
    MEDIA: 37.69875971476237,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '2436703c-b804-4c6b-8884-f542d486f37c',
    CIDADE: 'Rio Crespo',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.078853607177734,
    MAXIMO: 35.547760009765625,
    MEDIA: 35.334711347307476,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '57804dd8-9675-4335-bdcb-5aaae0e09ee4',
    CIDADE: 'Rolim de Moura',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.32041931152344,
    MAXIMO: 37.74166488647461,
    MEDIA: 37.12421417236328,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'b58c8083-6a4d-49c4-9bf4-d55d139c2aef',
    CIDADE: "Santa Luzia D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.37592315673828,
    MAXIMO: 37.23297119140625,
    MEDIA: 36.757192611694336,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'fca2d584-3f38-4e07-97c7-09dee863d003',
    CIDADE: 'Vilhena',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 41.53064727783203,
    MAXIMO: 47.57761001586914,
    MEDIA: 43.96679083506266,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '96036d4b-2a15-4dbd-af25-124664b0b3e2',
    CIDADE: 'São Miguel do Guaporé',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 33.2833137512207,
    MAXIMO: 35.17063903808594,
    MEDIA: 34.289722747802735,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '6715fed5-bf43-44b9-9aab-b9b475989bd6',
    CIDADE: 'Nova Mamoré',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 33.77730178833008,
    MAXIMO: 34.25223159790039,
    MEDIA: 34.01196049281529,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '0e199b87-145e-4280-bb25-2b4e0d121e22',
    CIDADE: "Alvorada D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.61638259887695,
    MAXIMO: 36.616615295410156,
    MEDIA: 35.63105926513672,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'f99a9267-5921-4713-893c-500ad2cc42a3',
    CIDADE: 'Alto Alegre dos Parecis',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.7376708984375,
    MAXIMO: 36.1715087890625,
    MEDIA: 34.269299348195396,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'c5da7e9e-157b-406c-a40b-e2ec0da2dcdb',
    CIDADE: 'Alto Paraíso',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.592247009277344,
    MAXIMO: 35.12754821777344,
    MEDIA: 34.82995653152466,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'aca37d88-c5ad-4f7f-8f3f-b3a128074660',
    CIDADE: 'Buritis',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.23341751098633,
    MAXIMO: 34.67841339111328,
    MEDIA: 34.45264498392741,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'd0113b8f-9129-4616-92ae-4e58605abb4c',
    CIDADE: 'Novo Horizonte do Oeste',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.79482650756836,
    MAXIMO: 36.592926025390625,
    MEDIA: 36.15752665201823,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'e3847056-47da-4340-b7c6-2b9ae0e0744f',
    CIDADE: 'Cacaulândia',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.17911911010742,
    MAXIMO: 35.7131233215332,
    MEDIA: 35.40533256530762,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'f9c60bb0-3eb2-4328-a233-bf2a8f3de874',
    CIDADE: 'Campo Novo de Rondônia',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.26176071166992,
    MAXIMO: 34.800376892089844,
    MEDIA: 34.44594459533691,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'f27fdfad-8104-446e-be5b-3f64b3bb11c4',
    CIDADE: 'Candeias do Jamari',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 33.344120025634766,
    MAXIMO: 34.57135009765625,
    MEDIA: 33.927073097229005,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '330ab56c-95dc-40c7-ad7e-6aec333bcd5b',
    CIDADE: 'Castanheiras',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.80226135253906,
    MAXIMO: 37.318565368652344,
    MEDIA: 37.0604133605957,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '81c03db5-2875-445b-ab88-9e4e15e80461',
    CIDADE: 'Chupinguaia',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 37.732826232910156,
    MAXIMO: 42.602787017822266,
    MEDIA: 40.24763516017369,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'f7670fa6-0c7f-4718-a606-eebd694ff68f',
    CIDADE: 'Cujubim',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.433292388916016,
    MAXIMO: 35.34242630004883,
    MEDIA: 34.86918411254883,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'dfe6d84f-84a9-4a62-a825-afe0e4606f40',
    CIDADE: 'Governador Jorge Teixeira',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.3353157043457,
    MAXIMO: 35.58665084838867,
    MEDIA: 34.94914088529699,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '4e0bb365-e406-4c2a-90f0-ff7b979928fb',
    CIDADE: 'Itapuã do Oeste',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.085880279541016,
    MAXIMO: 35.084903717041016,
    MEDIA: 34.60325647989909,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'ee52d9b5-34a1-420a-8b0d-ffdb1c4e3b89',
    CIDADE: 'Ministro Andreazza',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 38.428611755371094,
    MAXIMO: 38.489261627197266,
    MEDIA: 38.45893669128418,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '1fb25293-5b87-4a3f-b98e-6a695cb87575',
    CIDADE: 'Mirante da Serra',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.871028900146484,
    MAXIMO: 35.661380767822266,
    MEDIA: 35.27314567565918,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '29629a4e-f407-4ba8-9e08-feffed61fef2',
    CIDADE: 'Monte Negro',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 34.828548431396484,
    MAXIMO: 35.16951370239258,
    MEDIA: 34.96719233194987,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '74cc5728-93e5-4d1b-a879-d7975cb119f7',
    CIDADE: 'Nova União',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.77285385131836,
    MAXIMO: 36.08389663696289,
    MEDIA: 35.94929504394531,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'e1c5ea39-b8bd-44e6-98e5-810f19749b40',
    CIDADE: 'Parecis',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 37.118141174316406,
    MAXIMO: 39.283016204833984,
    MEDIA: 38.10079138619559,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '7c06a4e9-0f14-4312-9fcc-709ecc8d5467',
    CIDADE: 'Pimenteiras do Oeste',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.223295211791992,
    MAXIMO: 36.551971435546875,
    MEDIA: 33.248559588477725,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '31a9dd60-4ed3-4c7a-8e1d-015385c31223',
    CIDADE: 'Primavera de Rondônia',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 38.70973587036133,
    MAXIMO: 38.94902038574219,
    MEDIA: 38.82937812805176,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '6d4c0367-5777-4d7e-8ee8-513264077056',
    CIDADE: "São Felipe D'Oeste",
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 37.92570877075195,
    MAXIMO: 38.17454528808594,
    MEDIA: 38.050127029418945,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '134f8f6b-6643-424f-8a03-bae726bb7202',
    CIDADE: 'São Francisco do Guaporé',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 31.910099029541016,
    MAXIMO: 33.7615966796875,
    MEDIA: 32.665060968110055,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '1d302fc3-2755-466e-b215-3be0dbc3148c',
    CIDADE: 'Seringueiras',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 33.04975128173828,
    MAXIMO: 34.19816589355469,
    MEDIA: 33.57956660877574,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'ebafe93f-439b-48cd-825c-707199abf484',
    CIDADE: 'Teixeirópolis',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.346920013427734,
    MAXIMO: 36.725433349609375,
    MEDIA: 36.536176681518555,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '72b6c87c-82bb-4fa1-8e6a-7d11c9677349',
    CIDADE: 'Theobroma',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.9193229675293,
    MAXIMO: 36.61506271362305,
    MEDIA: 36.26343091328939,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: '30bb49e6-5d58-43e9-b546-76da44f0bfcf',
    CIDADE: 'Urupá',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.86691665649414,
    MAXIMO: 36.213279724121094,
    MEDIA: 36.04009819030762,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'e15d63b4-2d44-48fd-b617-c0673ff5cf14',
    CIDADE: 'Vale do Anari',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 35.97063446044922,
    MAXIMO: 36.65911102294922,
    MEDIA: 36.384625328911675,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'c9c7c655-ed62-4f28-88cf-eb014bb73764',
    CIDADE: 'Vale do Paraíso',
    SIGLA: 'RO',
    ESTADO: 'Rondônia',
    MINIMO: 36.79447555541992,
    MAXIMO: 36.887332916259766,
    MEDIA: 36.840904235839844,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,50%',
  },
  {
    id: 'e4b47263-339e-4089-9739-c85b46d257a5',
    CIDADE: 'Acrelândia',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 34.967525482177734,
    MAXIMO: 35.6450080871582,
    MEDIA: 35.31731923421224,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4c9805bc-aaf3-4536-af69-76eb71c17f07',
    CIDADE: 'Assis Brasil',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.770221710205078,
    MAXIMO: 31.524389266967773,
    MEDIA: 31.109376017252604,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '08cf92b2-36d6-45fa-8408-de1abb9166ff',
    CIDADE: 'Brasiléia',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 31.462230682373047,
    MAXIMO: 31.98420524597168,
    MEDIA: 31.71995391845703,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'be867f8a-7f17-46c5-8f10-eeedfff24dd0',
    CIDADE: 'Bujari',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 32.78910827636719,
    MAXIMO: 33.560977935791016,
    MEDIA: 33.141985999213325,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '313ac3ad-5793-469c-812d-cfc67ea51525',
    CIDADE: 'Capixaba',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 33.63285446166992,
    MAXIMO: 34.14749526977539,
    MEDIA: 33.840353393554686,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f064141c-906a-46b0-abe1-d33fa2949d76',
    CIDADE: 'Cruzeiro do Sul',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.10483169555664,
    MAXIMO: 30.2220516204834,
    MEDIA: 30.152422256469727,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8a7d81b2-d117-48c5-a84c-fe4e31c367f4',
    CIDADE: 'Epitaciolândia',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 32.03255081176758,
    MAXIMO: 32.346920013427734,
    MEDIA: 32.203617095947266,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '67791fa0-2b4a-4e1e-b108-6a5669864eba',
    CIDADE: 'Feijó',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.304079055786133,
    MAXIMO: 31.23714256286621,
    MEDIA: 30.766608970109807,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e3bfed19-e37d-4173-b404-7c63ad588d7d',
    CIDADE: 'Jordão',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.28641700744629,
    MAXIMO: 30.496692657470703,
    MEDIA: 30.395203085506665,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eb44f5f2-8e47-41fa-a797-b34265d387f8',
    CIDADE: 'Mâncio Lima',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.030092239379883,
    MAXIMO: 30.087024688720703,
    MEDIA: 30.06452293395996,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3093c245-2f20-4f2a-b110-1b4f9e59736f',
    CIDADE: 'Manoel Urbano',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.887754440307617,
    MAXIMO: 31.68646240234375,
    MEDIA: 31.261001269022625,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6385cc29-0497-4ac7-9c2d-64a97e0fc655',
    CIDADE: 'Marechal Thaumaturgo',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.188377380371094,
    MAXIMO: 30.336267471313477,
    MEDIA: 30.246899490356444,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '53f165f6-5361-4d52-bce3-a532e0b91b6d',
    CIDADE: 'Plácido de Castro',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 34.53097152709961,
    MAXIMO: 35.768741607666016,
    MEDIA: 35.20926361083984,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '874746f4-a9a6-4941-974a-f0609a2605e2',
    CIDADE: 'Porto Walter',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.143692016601562,
    MAXIMO: 30.27045440673828,
    MEDIA: 30.195791071111504,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ed3b7910-8e7e-432c-8f76-8863a7b980fc',
    CIDADE: 'Rio Branco',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 31.879924774169922,
    MAXIMO: 34.72330856323242,
    MEDIA: 33.11169617751549,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd596d80a-6173-4684-bf36-a7af55298483',
    CIDADE: 'Rodrigues Alves',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.089157104492188,
    MAXIMO: 30.12152862548828,
    MEDIA: 30.10359491620745,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8958b1a7-55f4-4c50-a382-459db61395c9',
    CIDADE: 'Santa Rosa do Purus',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.758102416992188,
    MAXIMO: 31.27477264404297,
    MEDIA: 31.022605996382865,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '84589a4f-6b37-4060-8209-b2a0655936f9',
    CIDADE: 'Senador Guiomard',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 34.27455520629883,
    MAXIMO: 35.190555572509766,
    MEDIA: 34.852643966674805,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '77ee5c7f-82fa-4da9-b638-c043ef3bf1d2',
    CIDADE: 'Sena Madureira',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.814472198486328,
    MAXIMO: 32.6745491027832,
    MEDIA: 31.740311210219925,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '90a17716-44ce-4dc4-b013-7fc039f943ac',
    CIDADE: 'Tarauacá',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 30.232866287231445,
    MAXIMO: 30.688919067382812,
    MEDIA: 30.44472312927246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5726ea27-fa81-4762-8775-c51f68a6b6c9',
    CIDADE: 'Xapuri',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 31.98875617980957,
    MAXIMO: 33.32872009277344,
    MEDIA: 32.59454873028923,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ae273e93-61f5-49a1-b13c-f5d81354a1b4',
    CIDADE: 'Porto Acre',
    SIGLA: 'AC',
    ESTADO: 'Acre',
    MINIMO: 33.20650863647461,
    MAXIMO: 34.62533950805664,
    MEDIA: 33.85652303695679,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a91ae0cb-a968-462a-ad88-7219609464a9',
    CIDADE: 'Alvarães',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.004615783691406,
    MAXIMO: 30.0145320892334,
    MEDIA: 30.009256811702954,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88bcb303-ba86-427f-b771-4a2c4b419e7e',
    CIDADE: 'Amaturá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.01198387145996,
    MAXIMO: 30.019285202026367,
    MEDIA: 30.01569902896881,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58a9e3c2-cc88-48b7-a20e-33adf7a64081',
    CIDADE: 'Anamã',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.015186309814453,
    MAXIMO: 30.018775939941406,
    MEDIA: 30.017048699515207,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45e30737-a09a-4591-bb37-9b98eb5dde0b',
    CIDADE: 'Anori',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.997228622436523,
    MAXIMO: 30.017946243286133,
    MEDIA: 30.008276688425166,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90aadaf1-c3cc-409a-a2da-caac924becb7',
    CIDADE: 'Apuí',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 31.270187377929688,
    MAXIMO: 34.946468353271484,
    MEDIA: 32.95979623963847,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe401497-99ce-4fdf-acba-0a5a514330b4',
    CIDADE: 'Atalaia do Norte',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.002429962158203,
    MAXIMO: 30.24018096923828,
    MEDIA: 30.082800646959726,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa5540cb-1175-46b8-a371-497fc4f61ddc',
    CIDADE: 'Autazes',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.99861717224121,
    MAXIMO: 30.05284309387207,
    MEDIA: 30.00974049775497,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d3e2a63-afc6-4211-96e3-83acaf016f49',
    CIDADE: 'Barcelos',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.7563533782959,
    MAXIMO: 36.099124908447266,
    MEDIA: 31.6063550974528,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e7bcce2f-aecb-4199-9451-8ea62509589c',
    CIDADE: 'Barreirinha',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.59971809387207,
    MAXIMO: 31.28677749633789,
    MEDIA: 30.9331179167095,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd4d33af-3b7e-4fd7-a04f-3722f55a0bf8',
    CIDADE: 'Benjamin Constant',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.004886627197266,
    MAXIMO: 30.254785537719727,
    MEDIA: 30.087562040849164,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7d156a5-856a-44e9-8736-4ca856eed43c',
    CIDADE: 'Beruri',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.994272232055664,
    MAXIMO: 30.015213012695312,
    MEDIA: 30.00302120283538,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1819fbb2-5ac7-4e39-84d9-3faf1b2fc357',
    CIDADE: 'Boa Vista do Ramos',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.38129997253418,
    MAXIMO: 30.779460906982422,
    MEDIA: 30.55423927307129,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b3598a5e-3aaf-46c6-840b-870a56c284da',
    CIDADE: 'Boca do Acre',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 31.418527603149414,
    MAXIMO: 34.327125549316406,
    MEDIA: 32.56686625273331,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9a2de78-2973-4b2b-90fc-c322f0f3b068',
    CIDADE: 'Borba',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.997695922851562,
    MAXIMO: 31.432973861694336,
    MEDIA: 30.31098835503877,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad7663ac-88a1-4a06-bac8-fa3b063a7fda',
    CIDADE: 'Caapiranga',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.004335403442383,
    MAXIMO: 30.020267486572266,
    MEDIA: 30.012932096208846,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b1352949-7ce7-40ad-b00c-ee7705909c09',
    CIDADE: 'Canutama',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.02398681640625,
    MAXIMO: 33.50611114501953,
    MEDIA: 31.27843209808948,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5283846-4283-449d-a92c-ed1f7c772b1e',
    CIDADE: 'Carauari',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.999549865722656,
    MAXIMO: 30.11467742919922,
    MEDIA: 30.015565523287144,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbafffd6-f249-4d83-9e5d-170f73462ce6',
    CIDADE: 'Careiro',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.998531341552734,
    MAXIMO: 30.01695442199707,
    MEDIA: 30.006474494934082,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a859703e-92ba-4004-9de5-85240f930141',
    CIDADE: 'Careiro da Várzea',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.000240325927734,
    MAXIMO: 30.017629623413086,
    MEDIA: 30.00639322068956,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54bc4a37-7c92-4e62-8bd4-d929716fd3f2',
    CIDADE: 'Coari',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.9902400970459,
    MAXIMO: 30.02361297607422,
    MEDIA: 30.002620176835492,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b62699b-6191-423e-a95f-1f95637c6205',
    CIDADE: 'Codajás',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.001262664794922,
    MAXIMO: 30.027263641357422,
    MEDIA: 30.019197397064744,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '901f7ee5-57aa-49c0-a1c3-756dbd6c8c6a',
    CIDADE: 'Eirunepé',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.275068283081055,
    MAXIMO: 30.548776626586914,
    MEDIA: 30.411167310631793,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd357af3d-5de8-4a82-bae2-2be72be5f8d4',
    CIDADE: 'Envira',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.505868911743164,
    MAXIMO: 30.856287002563477,
    MEDIA: 30.655722991279934,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3198242-8460-44f0-8a98-7dbdbd9cee97',
    CIDADE: 'Fonte Boa',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.994800567626953,
    MAXIMO: 30.0226993560791,
    MEDIA: 30.0136900255757,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7e3b5ea7-1fc3-4fb4-85dc-e92b63fda019',
    CIDADE: 'Guajará',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.01357078552246,
    MAXIMO: 30.17319679260254,
    MEDIA: 30.085546875,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '009e01de-7484-4cb3-ac30-60bc9f33bdc5',
    CIDADE: 'Humaitá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.367691040039062,
    MAXIMO: 34.43536376953125,
    MEDIA: 31.68141246078038,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09bda318-1663-4e32-9ce8-17fdd6be554a',
    CIDADE: 'Ipixuna',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.179981231689453,
    MAXIMO: 30.381633758544922,
    MEDIA: 30.278368534185947,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b5b77586-f83c-490b-99ba-23f92e6c69c6',
    CIDADE: 'Iranduba',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.016212463378906,
    MAXIMO: 30.018701553344727,
    MEDIA: 30.017813364664715,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30013a69-68c7-4dfd-8a71-9171fc6c2ede',
    CIDADE: 'Itacoatiara',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.999168395996094,
    MAXIMO: 30.24140739440918,
    MEDIA: 30.069610082186184,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8fb1b2bc-68d6-4e25-8244-02b4d1ded38c',
    CIDADE: 'Itamarati',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.166622161865234,
    MAXIMO: 30.956674575805664,
    MEDIA: 30.478668142248083,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f73f31b8-bd58-4b9a-b4bb-640ecc5e897f',
    CIDADE: 'Itapiranga',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.99932289123535,
    MAXIMO: 30.270370483398438,
    MEDIA: 30.053834475003757,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b834394-ad22-4f15-b188-26f25fb9a821',
    CIDADE: 'Japurá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.747888565063477,
    MAXIMO: 30.02991485595703,
    MEDIA: 29.982024680736455,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6de4cbe1-90b7-4eb7-ad06-6ddee032a980',
    CIDADE: 'Juruá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.0018310546875,
    MAXIMO: 30.026226043701172,
    MEDIA: 30.015172004699707,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7433ed47-5891-40b8-a7a7-e1d72cf2b112',
    CIDADE: 'Jutaí',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.000890731811523,
    MAXIMO: 30.304553985595703,
    MEDIA: 30.053487520951492,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f545ed22-bb35-495f-8c80-819b1b76ba21',
    CIDADE: 'Lábrea',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.841575622558594,
    MAXIMO: 34.89505386352539,
    MEDIA: 32.59679514390451,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2063d11-cca9-4d7e-8ec8-60b0f7a03cf2',
    CIDADE: 'Manacapuru',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.010229110717773,
    MAXIMO: 30.018903732299805,
    MEDIA: 30.016244583129883,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b061b3e-1b7b-482d-a0ee-bd636f9595ef',
    CIDADE: 'Manaquiri',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.00136375427246,
    MAXIMO: 30.01873207092285,
    MEDIA: 30.009781403975055,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06e9ce03-768b-4cc6-b0c3-ab9339b1d420',
    CIDADE: 'Manaus',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.003582000732422,
    MAXIMO: 30.025192260742188,
    MEDIA: 30.01482628487252,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c8431c9-1b5e-4a30-aebe-9a574b3e72e8',
    CIDADE: 'Manicoré',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.991783142089844,
    MAXIMO: 34.5410041809082,
    MEDIA: 30.995601513241763,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '114d512e-fabc-418d-aac1-8ccf025215ab',
    CIDADE: 'Maraã',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.940937042236328,
    MAXIMO: 30.020545959472656,
    MEDIA: 30.003495996648613,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a42a92e-5b7f-4964-8226-d765f84ae083',
    CIDADE: 'Maués',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.2782039642334,
    MAXIMO: 32.26320266723633,
    MEDIA: 30.921684643578907,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a21be16-db3a-4aa3-827f-e417dd629553',
    CIDADE: 'Nhamundá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.9915828704834,
    MAXIMO: 32.064659118652344,
    MEDIA: 30.406864166259766,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0bb5d40d-22cb-4719-8f20-25185d412de3',
    CIDADE: 'Nova Olinda do Norte',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.04972267150879,
    MAXIMO: 30.46664810180664,
    MEDIA: 30.231448809305828,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd5cf235-a824-4e8a-8d89-bae1dfd16d65',
    CIDADE: 'Novo Airão',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.99761199951172,
    MAXIMO: 30.02840805053711,
    MEDIA: 30.01628832231488,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '305d96c8-b0ba-442e-ae1d-b57457f47a90',
    CIDADE: 'Novo Aripuanã',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.01569938659668,
    MAXIMO: 34.917335510253906,
    MEDIA: 31.742856979370117,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53ffe479-b35d-47b1-85de-6a16c8292608',
    CIDADE: 'Parintins',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.624460220336914,
    MAXIMO: 31.716211318969727,
    MEDIA: 31.21244972630551,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '168d47ba-ad31-4cc3-abdf-b731d2c9d91c',
    CIDADE: 'Pauini',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.592069625854492,
    MAXIMO: 32.519832611083984,
    MEDIA: 31.311758733564808,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c54e444-3d73-4737-99d7-fd5b1955ce37',
    CIDADE: 'Presidente Figueiredo',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.003576278686523,
    MAXIMO: 30.02813720703125,
    MEDIA: 30.017897183382058,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2a095bf-dafc-4ced-949a-cce8f93961a4',
    CIDADE: 'Rio Preto da Eva',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.00139045715332,
    MAXIMO: 30.025047302246094,
    MEDIA: 30.01333461088293,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1daa3bc-6c4a-458e-a129-daa1ced33994',
    CIDADE: 'Santa Isabel do Rio Negro',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.76141357421875,
    MAXIMO: 36.61045455932617,
    MEDIA: 31.75831311941147,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8f1f913-f50f-483b-ab9d-8d62cf43763c',
    CIDADE: 'Santo Antônio do Içá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.00183868408203,
    MAXIMO: 30.01958656311035,
    MEDIA: 30.013047193869568,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd62c691-a6d5-4689-bf0d-713bd030873a',
    CIDADE: 'São Gabriel da Cachoeira',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.716718673706055,
    MAXIMO: 37.68537902832031,
    MEDIA: 33.28522694075497,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd0984b0-7d0a-488d-92c1-8595abfa3c78',
    CIDADE: 'São Paulo de Olivença',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.000713348388672,
    MAXIMO: 30.216440200805664,
    MEDIA: 30.031309445699055,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd51af469-c259-41eb-98ae-408a0f2c6af0',
    CIDADE: 'São Sebastião do Uatumã',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.999916076660156,
    MAXIMO: 30.216140747070312,
    MEDIA: 30.02102565765381,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6620916d-533f-4d8c-b052-c96cc46365f8',
    CIDADE: 'Silves',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.00182342529297,
    MAXIMO: 30.187908172607422,
    MEDIA: 30.074765985662285,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b47377e3-5cfd-44cf-8921-243b73849199',
    CIDADE: 'Tabatinga',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.008207321166992,
    MAXIMO: 30.0146427154541,
    MEDIA: 30.011466113003817,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba7e8126-df4b-4512-b551-e5799eaf5337',
    CIDADE: 'Tapauá',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.987659454345703,
    MAXIMO: 31.00702476501465,
    MEDIA: 30.206009360773937,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3379c8b5-3ab2-4e11-ae41-9c596e80126e',
    CIDADE: 'Tefé',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.99716567993164,
    MAXIMO: 30.011808395385742,
    MEDIA: 30.0055929210088,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2d71cef-e46e-43eb-9ee2-1ccc6e913dfb',
    CIDADE: 'Tonantins',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.005352020263672,
    MAXIMO: 30.030916213989258,
    MEDIA: 30.02109146118164,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b12059ab-ab66-483e-bcbb-3349974e406d',
    CIDADE: 'Uarini',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.004348754882812,
    MAXIMO: 30.023210525512695,
    MEDIA: 30.0158935546875,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef352b74-629b-4c1a-96ed-e8039b6abe98',
    CIDADE: 'Urucará',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 29.994104385375977,
    MAXIMO: 30.485260009765625,
    MEDIA: 30.032837834851495,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '537c78d9-decc-4d72-b82f-0e5c7cb45faf',
    CIDADE: 'Urucurituba',
    SIGLA: 'AM',
    ESTADO: 'Amazonas',
    MINIMO: 30.230484008789062,
    MAXIMO: 30.539825439453125,
    MEDIA: 30.380269241333007,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44df680c-155d-4f85-9f36-e3367553f375',
    CIDADE: 'Amajari',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 35.05986404418945,
    MAXIMO: 35.916297912597656,
    MEDIA: 35.506752277242725,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '92d942e6-fedd-412c-b6a8-ae6ab661efda',
    CIDADE: 'Alto Alegre',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 34.59231948852539,
    MAXIMO: 36.00614929199219,
    MEDIA: 35.418806614019935,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd95f412e-1812-4bab-a44e-8a5e1a819825',
    CIDADE: 'Boa Vista',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 32.609580993652344,
    MAXIMO: 36.033260345458984,
    MEDIA: 34.97366213798523,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a4b0f42c-561f-490a-88c1-0ff37310b600',
    CIDADE: 'Bonfim',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.83102035522461,
    MAXIMO: 35.55666732788086,
    MEDIA: 32.8454532623291,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a42e08a9-9e80-4202-91f2-d6285f625715',
    CIDADE: 'Cantá',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.93805503845215,
    MAXIMO: 32.96755599975586,
    MEDIA: 30.821098251342775,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cd35ff20-b030-43de-9059-7148cfded0bc',
    CIDADE: 'Caracaraí',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.720849990844727,
    MAXIMO: 35.701454162597656,
    MEDIA: 30.990907225810307,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b9954935-06dd-414e-94b4-2c3325f08794',
    CIDADE: 'Caroebe',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.90387725830078,
    MAXIMO: 30.009326934814453,
    MEDIA: 29.986473670372597,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'abc1faca-b3fc-49b4-bfbf-546594f74a24',
    CIDADE: 'Iracema',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 33.49410629272461,
    MAXIMO: 36.1805305480957,
    MEDIA: 35.49380196465386,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '98337ea6-efb2-4dd7-9d92-4bbcab4f7eff',
    CIDADE: 'Mucajaí',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 32.27460861206055,
    MAXIMO: 36.169349670410156,
    MEDIA: 35.58562830874794,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0bb7701b-5449-42a6-989e-faedb644f040',
    CIDADE: 'Normandia',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 33.842830657958984,
    MAXIMO: 36.10038375854492,
    MEDIA: 35.33049083891369,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd8c85470-aea6-4e07-bf15-03b00300769e',
    CIDADE: 'Pacaraima',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 35.70855712890625,
    MAXIMO: 36.10374450683594,
    MEDIA: 35.94546303382287,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '32125a13-4fcc-4d82-a52c-6c784748b9ff',
    CIDADE: 'Rorainópolis',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.856550216674805,
    MAXIMO: 30.095304489135742,
    MEDIA: 29.983520674474033,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cbb3bd88-315a-4b39-9b67-8aaf982e813b',
    CIDADE: 'São João da Baliza',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.993406295776367,
    MAXIMO: 30.01058578491211,
    MEDIA: 30.000857786698774,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c46f8dc9-3335-4298-8043-2a2eba13e631',
    CIDADE: 'São Luiz',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 29.989013671875,
    MAXIMO: 29.995004653930664,
    MEDIA: 29.99201545715332,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '00a6efa3-3e61-4f21-bd4f-9cdfe0367133',
    CIDADE: 'Uiramutã',
    SIGLA: 'RR',
    ESTADO: 'Roraima',
    MINIMO: 34.18329620361328,
    MAXIMO: 36.00505447387695,
    MEDIA: 35.70624192555746,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c498ceef-9862-4348-83c3-46980a5173e8',
    CIDADE: 'Abaetetuba',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.83222770690918,
    MAXIMO: 29.85340118408203,
    MEDIA: 29.84434636433919,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'be090043-7fd5-4f20-a9bd-6e161ed2bfa7',
    CIDADE: 'Abel Figueiredo',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.06295394897461,
    MAXIMO: 31.2119083404541,
    MEDIA: 31.137431144714355,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a5c5b534-ca1c-4760-8cd3-a6b34af62574',
    CIDADE: 'Acará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.807151794433594,
    MAXIMO: 29.850862503051758,
    MEDIA: 29.827319962637766,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '05cf5a92-9f03-4e3a-8043-2e09ef86711d',
    CIDADE: 'Afuá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.167165756225586,
    MAXIMO: 30.63227081298828,
    MEDIA: 30.366612890492316,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b3f5a512-a6ca-40b6-8ef7-ade96eb1687b',
    CIDADE: 'Água Azul do Norte',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.541410446166992,
    MAXIMO: 31.04553985595703,
    MEDIA: 30.817793083190917,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '46d9a1f1-3315-4851-b908-08cf077880b5',
    CIDADE: 'Alenquer',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.418014526367188,
    MAXIMO: 36.479793548583984,
    MEDIA: 32.73558110776155,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd557d149-e70e-439a-be18-a879f9c6b81a',
    CIDADE: 'Almeirim',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.031978607177734,
    MAXIMO: 34.08489227294922,
    MEDIA: 31.566955289126494,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '16c1e537-0797-46f5-a546-ff8e969a3008',
    CIDADE: 'Altamira',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.014284133911133,
    MAXIMO: 32.64993667602539,
    MEDIA: 30.974604637392105,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5a42d99b-cf79-4670-9472-42ea58de8f3a',
    CIDADE: 'Anajás',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.91912269592285,
    MAXIMO: 30.177261352539062,
    MEDIA: 30.03839328072288,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5e105bfa-d9cb-485e-982c-d266f38bf463',
    CIDADE: 'Ananindeua',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.788427352905273,
    MAXIMO: 29.81252098083496,
    MEDIA: 29.802357499217468,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a7a87297-396c-47e4-ad0a-950263f2e3c2',
    CIDADE: 'Anapu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.320796966552734,
    MAXIMO: 30.87885093688965,
    MEDIA: 30.49292267070097,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '24dccb22-a8d5-4a50-a9eb-3b70caffacd1',
    CIDADE: 'Augusto Corrêa',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.64176368713379,
    MAXIMO: 29.733789443969727,
    MEDIA: 29.69172477722168,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '14facf82-f75a-4668-afe9-4057806ce732',
    CIDADE: 'Aurora do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.84245491027832,
    MAXIMO: 29.923545837402344,
    MEDIA: 29.87208843231201,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3af5c6f8-57f9-4f6b-b3bb-ed4a6e6e537d',
    CIDADE: 'Aveiro',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.372459411621094,
    MAXIMO: 33.6690559387207,
    MEDIA: 32.330558953461825,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6da9569f-20d8-42eb-a500-0aa2746a216c',
    CIDADE: 'Bagre',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.893903732299805,
    MAXIMO: 29.96859359741211,
    MEDIA: 29.92261518750872,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5ed9564d-d4c0-4341-8789-f22c8acd9168',
    CIDADE: 'Baião',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.8817138671875,
    MAXIMO: 29.96864128112793,
    MEDIA: 29.917537982647236,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '25ac0a39-7c4a-4138-8a4c-63c2efa16b28',
    CIDADE: 'Bannach',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.691999435424805,
    MAXIMO: 30.998558044433594,
    MEDIA: 30.851747894287108,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5a6713ee-c0f5-4c9a-927d-f0a108e86da7',
    CIDADE: 'Barcarena',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.81411361694336,
    MAXIMO: 29.83483123779297,
    MEDIA: 29.82114028930664,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ad70a3b6-d73e-4669-85b4-fbb49256db6f',
    CIDADE: 'Belém',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.799007415771484,
    MAXIMO: 29.956388473510742,
    MEDIA: 29.855972290039062,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '11660a30-3d96-44b0-b011-7f6646dc6c98',
    CIDADE: 'Belterra',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 33.20450210571289,
    MAXIMO: 34.97691345214844,
    MEDIA: 34.03205833435059,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c3c1c10e-5678-49e8-9d89-a98899001d2b',
    CIDADE: 'Benevides',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.788427352905273,
    MAXIMO: 29.807151794433594,
    MEDIA: 29.797937916073785,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1430e153-1dac-4061-8548-300f1ad36f04',
    CIDADE: 'Bom Jesus do Tocantins',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.723602294921875,
    MAXIMO: 31.036970138549805,
    MEDIA: 30.900070428848267,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c47f0148-0e5c-460f-b7d3-462b311724a6',
    CIDADE: 'Bonito',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.80088233947754,
    MAXIMO: 29.823440551757812,
    MEDIA: 29.812161445617676,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '781f8f20-8947-4ab7-8671-d3a281743cca',
    CIDADE: 'Bragança',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.716413497924805,
    MAXIMO: 29.806108474731445,
    MEDIA: 29.752855512830948,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2136c1e6-f1c4-49b5-b994-18eed22edb68',
    CIDADE: 'Brasil Novo',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.574541091918945,
    MAXIMO: 32.81013107299805,
    MEDIA: 32.08901844024658,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fc22192d-e9b7-4dd2-9383-4cc0eeb81837',
    CIDADE: 'Brejo Grande do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.532012939453125,
    MAXIMO: 31.795379638671875,
    MEDIA: 31.634123229980467,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b1e0a5b9-1042-4b23-a259-e65ca62b2ef9',
    CIDADE: 'Breu Branco',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.970247268676758,
    MAXIMO: 30.137266159057617,
    MEDIA: 30.03620749253493,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f97d5d47-dfb1-4a39-9479-ed5d4f9e39b4',
    CIDADE: 'Breves',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.017215728759766,
    MAXIMO: 30.51511573791504,
    MEDIA: 30.23526736668178,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f2ab7bae-03c6-4859-a96c-db95354f4640',
    CIDADE: 'Bujaru',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.80181121826172,
    MAXIMO: 29.82267189025879,
    MEDIA: 29.81433169047038,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '154ebe84-d657-498a-8308-dbbc424a59a6',
    CIDADE: 'Cachoeira do Piriá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.82316780090332,
    MAXIMO: 30.253952026367188,
    MEDIA: 30.018112182617188,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef44d8d3-578d-48e7-b32d-ae918c2213d2',
    CIDADE: 'Cachoeira do Arari',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.948564529418945,
    MAXIMO: 29.965051651000977,
    MEDIA: 29.955059051513672,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'de8d94e1-ae93-4609-b78e-69fc2b7f0132',
    CIDADE: 'Cametá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.831144332885742,
    MAXIMO: 29.877344131469727,
    MEDIA: 29.856751702048562,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c94f9bab-8c2c-4a72-98b8-968f7970734c',
    CIDADE: 'Canaã dos Carajás',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.660139083862305,
    MAXIMO: 31.07600212097168,
    MEDIA: 30.87592124938965,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '11a6115e-74f0-4a01-a3fc-37cd3704144a',
    CIDADE: 'Capanema',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.75040054321289,
    MAXIMO: 29.780851364135742,
    MEDIA: 29.764989852905273,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b3fb8955-db76-4934-84d2-d17b183c0899',
    CIDADE: 'Capitão Poço',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.841333389282227,
    MAXIMO: 30.031339645385742,
    MEDIA: 29.899699211120605,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3e9c2759-2168-40a6-adbb-e260d3905cee',
    CIDADE: 'Castanhal',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.80727767944336,
    MAXIMO: 29.8359375,
    MEDIA: 29.82132625579834,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86f42046-0af3-4e97-8e96-30ad3a7fe4f0',
    CIDADE: 'Chaves',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.972490310668945,
    MAXIMO: 30.319360733032227,
    MEDIA: 30.095929446973297,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '111ecde2-e82f-4621-aab2-5f95c0af4f1f',
    CIDADE: 'Colares',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.956388473510742,
    MAXIMO: 29.98129653930664,
    MEDIA: 29.975547538533917,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ad34d48e-f8e0-48e4-b2db-1e02150a8833',
    CIDADE: 'Conceição do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.59516143798828,
    MAXIMO: 31.965579986572266,
    MEDIA: 31.78447087605794,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3e67f1a6-a227-4afc-baab-632a663e1a17',
    CIDADE: 'Concórdia do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.83815574645996,
    MAXIMO: 29.894775390625,
    MEDIA: 29.86646556854248,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6374f209-9d37-4bd8-ab8e-a4fa6a5e55ae',
    CIDADE: 'Cumaru do Norte',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.316936492919922,
    MAXIMO: 30.970745086669922,
    MEDIA: 30.630467308892143,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '82c88d5a-5a80-4d42-94d2-3d651bf35ee5',
    CIDADE: 'Curionópolis',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.871232986450195,
    MAXIMO: 31.22282600402832,
    MEDIA: 31.045292960272896,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bf97a3bd-bb71-4b2b-b4c1-c320da5c7aa1',
    CIDADE: 'Curralinho',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.88689613342285,
    MAXIMO: 29.998199462890625,
    MEDIA: 29.944756825764973,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '225b55b5-94ed-4e2e-acca-9ff6267f5bf4',
    CIDADE: 'Curuá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 34.1468391418457,
    MAXIMO: 34.94926071166992,
    MEDIA: 34.53389549255371,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '60f3717a-4252-452f-8001-5fdb67aa61c1',
    CIDADE: 'Curuçá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.773353576660156,
    MAXIMO: 29.8388729095459,
    MEDIA: 29.806113243103027,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0088c9f4-4426-47a2-a8d7-39195201da1b',
    CIDADE: 'Dom Eliseu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.34380340576172,
    MAXIMO: 31.427227020263672,
    MEDIA: 30.884183771470013,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '98d010e5-c1b6-4917-9a54-c4ba6cd0e421',
    CIDADE: 'Eldorado do Carajás',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.037511825561523,
    MAXIMO: 31.317842483520508,
    MEDIA: 31.20319366455078,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd4fe2796-b9a6-45c5-a4fa-02c7e50f9b5e',
    CIDADE: 'Faro',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.993070602416992,
    MAXIMO: 31.318878173828125,
    MEDIA: 30.22878212398953,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '78e83294-40cb-4bad-beeb-f350584412c3',
    CIDADE: 'Floresta do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.436452865600586,
    MAXIMO: 31.796756744384766,
    MEDIA: 31.618716430664062,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'aebf8320-dc20-4e28-a837-c68b5c14272a',
    CIDADE: 'Garrafão do Norte',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.89746856689453,
    MAXIMO: 30.09585189819336,
    MEDIA: 29.978958511352538,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e4fc4930-2252-476e-a7b7-46ebfb28f021',
    CIDADE: 'Goianésia do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.028409957885742,
    MAXIMO: 30.36311912536621,
    MEDIA: 30.212338402157737,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'faca6b66-ec2b-4af0-8824-3cc83c250ef8',
    CIDADE: 'Gurupá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.598852157592773,
    MAXIMO: 31.694595336914062,
    MEDIA: 30.92311981201172,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9e24b1e3-c616-4516-a671-2feb3d93de64',
    CIDADE: 'Igarapé-Açu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.79001235961914,
    MAXIMO: 29.808713912963867,
    MEDIA: 29.799363136291504,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '65095a27-c9e1-4275-b956-0a81e4316b55',
    CIDADE: 'Igarapé-Miri',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.824636459350586,
    MAXIMO: 29.855804443359375,
    MEDIA: 29.84470405578613,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '89e32782-8b1a-4da4-be81-c5877d4ff047',
    CIDADE: 'Inhangapi',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.80181121826172,
    MAXIMO: 29.8359375,
    MEDIA: 29.82390777247468,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b4b065aa-dc32-4c10-bdcf-c44e35848947',
    CIDADE: 'Ipixuna do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.8823299407959,
    MAXIMO: 30.16801643371582,
    MEDIA: 30.01522147655487,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '53963c73-89c2-40ac-a821-905584727da4',
    CIDADE: 'Irituia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.848100662231445,
    MAXIMO: 29.8745174407959,
    MEDIA: 29.85936403274536,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f4cd1575-6975-4ec3-8cd4-b506a3272ed6',
    CIDADE: 'Itaituba',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.04972267150879,
    MAXIMO: 32.27642059326172,
    MEDIA: 31.60127336602462,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'efc8ac69-1463-4de5-99b3-542ddded011d',
    CIDADE: 'Itupiranga',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.348047256469727,
    MAXIMO: 30.588815689086914,
    MEDIA: 30.441619179465555,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd4a2e9c6-9434-47a8-a0c2-dc4ec8c5f39d',
    CIDADE: 'Jacareacanga',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.271718978881836,
    MAXIMO: 33.37307357788086,
    MEDIA: 32.08782744257705,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b2075356-31f5-4242-9b3f-9182832fa072',
    CIDADE: 'Jacundá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.331838607788086,
    MAXIMO: 30.565568923950195,
    MEDIA: 30.428576278686524,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ea9d144a-c5b3-4fe7-838b-5949f3ed2a4a',
    CIDADE: 'Juruti',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.553178787231445,
    MAXIMO: 32.86042785644531,
    MEDIA: 32.14962395377781,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '11407f4c-a4bc-4774-90c5-1f5366fc2a33',
    CIDADE: 'Limoeiro do Ajuru',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.86020278930664,
    MAXIMO: 29.87834930419922,
    MEDIA: 29.867164611816406,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0d1c79cf-5c1f-4461-809c-a8c7584c7c67',
    CIDADE: 'Mãe do Rio',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.849937438964844,
    MAXIMO: 29.87129783630371,
    MEDIA: 29.860617637634277,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0d1660fc-46b3-4bd9-9328-9e2760a8c824',
    CIDADE: 'Magalhães Barata',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.75299072265625,
    MAXIMO: 29.820558547973633,
    MEDIA: 29.794407296821866,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2e78413f-4b0c-4134-9ecc-57d76b4a9b39',
    CIDADE: 'Marabá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.332979202270508,
    MAXIMO: 31.218101501464844,
    MEDIA: 30.62253604888916,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5a8e85d5-bc06-44d9-b1c1-21280b555346',
    CIDADE: 'Maracanã',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.75299072265625,
    MAXIMO: 29.801616668701172,
    MEDIA: 29.77730369567871,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '465184b4-180a-4a08-9f52-ef880fce4cd4',
    CIDADE: 'Marapanim',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.766199111938477,
    MAXIMO: 30.029888153076172,
    MEDIA: 29.940832773844402,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '821ae944-6f20-4026-a44d-d64d18ab72fb',
    CIDADE: 'Marituba',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.802003860473633,
    MAXIMO: 29.807151794433594,
    MEDIA: 29.802081671046864,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7e3ae286-9056-4f04-8c71-732ba0edafe8',
    CIDADE: 'Medicilândia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 32.2547492980957,
    MAXIMO: 34.31871032714844,
    MEDIA: 33.205689969270125,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bd404d73-88c6-4c61-a98c-30f5510b79bd',
    CIDADE: 'Melgaço',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.997251510620117,
    MAXIMO: 31.223876953125,
    MEDIA: 30.447053660517152,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'aa0d4994-6ab4-4c99-93f0-a6b835b3f58f',
    CIDADE: 'Mocajuba',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.835580825805664,
    MAXIMO: 29.88664436340332,
    MEDIA: 29.879031916107255,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e3bacdd8-1024-48a9-ba3f-a0e3d2ca39b2',
    CIDADE: 'Moju',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.81551742553711,
    MAXIMO: 29.98080825805664,
    MEDIA: 29.893407344818115,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '408c6088-87a5-4427-9bc0-9bb4949f4dd6',
    CIDADE: 'Mojuí dos Campos',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 33.77979278564453,
    MAXIMO: 35.76348876953125,
    MEDIA: 34.756733152601456,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef8f24fc-605c-4d37-b73a-08276aaa2317',
    CIDADE: 'Monte Alegre',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.30988121032715,
    MAXIMO: 37.919105529785156,
    MEDIA: 34.15872212818691,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '87ebe5b9-8c4f-4dbb-b401-b080af5d4a3f',
    CIDADE: 'Muaná',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.845123291015625,
    MAXIMO: 29.95534896850586,
    MEDIA: 29.88834991455078,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4d34e623-9aa1-4343-8c62-2cde41d6d3ea',
    CIDADE: 'Nova Esperança do Piriá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.91707992553711,
    MAXIMO: 30.207096099853516,
    MEDIA: 30.081113576889038,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e8ff7a5e-92fa-4fe9-b64b-2a43d91f4a4f',
    CIDADE: 'Nova Ipixuna',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.49010467529297,
    MAXIMO: 30.65610694885254,
    MEDIA: 30.594753742218018,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5681c805-323c-4e89-9638-a0a604a00abd',
    CIDADE: 'Nova Timboteua',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.79351234436035,
    MAXIMO: 29.795392990112305,
    MEDIA: 29.794452667236328,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '93ebe8e7-4b94-4ed5-9ae7-366a26a09271',
    CIDADE: 'Novo Progresso',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.467018127441406,
    MAXIMO: 31.69367790222168,
    MEDIA: 31.157037330886066,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7c3e9b8f-7810-489f-8811-a03d5444cdce',
    CIDADE: 'Novo Repartimento',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.04229736328125,
    MAXIMO: 30.436128616333008,
    MEDIA: 30.237509460449218,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ae6e1333-bcce-4838-bec4-231b497fbe6f',
    CIDADE: 'Óbidos',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.01728630065918,
    MAXIMO: 34.73677444458008,
    MEDIA: 31.33219752200814,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4bad1626-17c2-4ab7-abce-a8928a2b63fc',
    CIDADE: 'Oeiras do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.858129501342773,
    MAXIMO: 29.90983009338379,
    MEDIA: 29.88663805448092,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '161f4f54-c016-46a8-bfd7-4f7bacf50c4f',
    CIDADE: 'Oriximiná',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.978031158447266,
    MAXIMO: 32.642494201660156,
    MEDIA: 30.235201087998757,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c94f4b41-f351-4f9b-8384-ec71956867c4',
    CIDADE: 'Ourém',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.790172576904297,
    MAXIMO: 29.816471099853516,
    MEDIA: 29.805287679036457,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3241b93e-24a4-45c7-95c1-759d7344db5d',
    CIDADE: 'Ourilândia do Norte',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.18941879272461,
    MAXIMO: 30.808534622192383,
    MEDIA: 30.450990552487582,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '06f56997-69bf-4809-bb72-8cf7ca278ca7',
    CIDADE: 'Pacajá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.926372528076172,
    MAXIMO: 30.38100242614746,
    MEDIA: 30.102424363832217,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2b79e3dd-2fd5-4875-8f86-e4898d977688',
    CIDADE: 'Palestina do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.713808059692383,
    MAXIMO: 31.969539642333984,
    MEDIA: 31.813868204752605,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '467e759c-458f-478a-87a2-13cb8cf17cf5',
    CIDADE: 'Paragominas',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.989057540893555,
    MAXIMO: 31.0728759765625,
    MEDIA: 30.376650023878668,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '38bab658-db25-4a9d-b9fd-09946cb5c299',
    CIDADE: 'Parauapebas',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.51277732849121,
    MAXIMO: 30.876920700073242,
    MEDIA: 30.622840178640264,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ae82316f-8056-48f2-80a9-7cadb369c15d',
    CIDADE: "Pau D'Arco",
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.1356201171875,
    MAXIMO: 31.38552474975586,
    MEDIA: 31.255070877075195,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1fbc6499-f54d-42fa-9471-6cea9d1431a1',
    CIDADE: 'Peixe-Boi',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.763717651367188,
    MAXIMO: 29.795392990112305,
    MEDIA: 29.789193463185633,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '71a1ba58-c05d-4216-9b4e-0ea3e68ba962',
    CIDADE: 'Piçarra',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.337177276611328,
    MAXIMO: 31.848913192749023,
    MEDIA: 31.626793479919435,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c8e972f5-e801-40b2-9625-e6fcbcbdc722',
    CIDADE: 'Placas',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 32.652496337890625,
    MAXIMO: 33.93046951293945,
    MEDIA: 33.239392961774556,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e93c0af8-e974-41f0-ab85-1a35eb8fc24e',
    CIDADE: 'Ponta de Pedras',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.83072853088379,
    MAXIMO: 29.968765258789062,
    MEDIA: 29.900811036427815,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f8084cd8-cdfe-4263-8e0f-9eae78868695',
    CIDADE: 'Portel',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.915212631225586,
    MAXIMO: 31.21161651611328,
    MEDIA: 30.28519671122233,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '25c0769a-f62a-4d7e-b962-62b1b5f07e84',
    CIDADE: 'Porto de Moz',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.144773483276367,
    MAXIMO: 34.348140716552734,
    MEDIA: 32.43147061905771,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bee21950-dd64-4a55-9297-27228fcab8f3',
    CIDADE: 'Prainha',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 33.58040237426758,
    MAXIMO: 36.6977653503418,
    MEDIA: 34.94092273712158,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bd7105b2-720d-4783-98cc-c7486021cfdd',
    CIDADE: 'Primavera',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.75040054321289,
    MAXIMO: 29.788557052612305,
    MEDIA: 29.771471562464935,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'adf67938-cb5b-42f3-a27b-7176f1086648',
    CIDADE: 'Quatipuru',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.75040054321289,
    MAXIMO: 30.089527130126953,
    MEDIA: 29.848472878669984,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0a99e722-3736-4c33-aebb-154b3e62f624',
    CIDADE: 'Redenção',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.018054962158203,
    MAXIMO: 31.532352447509766,
    MEDIA: 31.265586679632012,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd0e23e54-7c5b-4366-80dd-c3c9d3407963',
    CIDADE: 'Rio Maria',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.027435302734375,
    MAXIMO: 31.67173194885254,
    MEDIA: 31.34400441096379,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '594b0825-e46a-4366-86e4-0a49aec14e2c',
    CIDADE: 'Rondon do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.385356903076172,
    MAXIMO: 31.246835708618164,
    MEDIA: 30.746206436157227,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c6939694-a288-43cd-be54-4f341818fc9c',
    CIDADE: 'Rurópolis',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 32.14826965332031,
    MAXIMO: 33.23627471923828,
    MEDIA: 32.562417348225914,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e94894bb-c36c-4dae-8d16-2831e09b5362',
    CIDADE: 'Salinópolis',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.782466888427734,
    MAXIMO: 30.092697143554688,
    MEDIA: 29.868511559739233,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '93f530e6-06d9-4685-a898-69e2ac02fc6d',
    CIDADE: 'Salvaterra',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.955116271972656,
    MAXIMO: 29.97062873840332,
    MEDIA: 29.96158218383789,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ff00d8ea-20f3-409f-8206-c73c0449e92e',
    CIDADE: 'Santa Bárbara do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.788427352905273,
    MAXIMO: 29.98129653930664,
    MEDIA: 29.812300700887747,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0d143445-560d-4e3b-bd5f-3da20317ca2c',
    CIDADE: 'Santa Cruz do Arari',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.96164894104004,
    MAXIMO: 29.977556228637695,
    MEDIA: 29.969855626424152,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c7c7268c-891b-464c-a1cb-81e6f36fec7f',
    CIDADE: 'Santa Izabel do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.791040420532227,
    MAXIMO: 29.79218864440918,
    MEDIA: 29.791614532470703,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7cea07ca-886f-4289-b97b-7976269363eb',
    CIDADE: 'Santa Luzia do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.781856536865234,
    MAXIMO: 29.876556396484375,
    MEDIA: 29.817034403483074,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '25eb9370-09ce-4e26-95c5-899d3b61b049',
    CIDADE: 'Santa Maria das Barreiras',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.790555953979492,
    MAXIMO: 31.643388748168945,
    MEDIA: 31.199199676513672,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6e3ba0c1-1b61-4a68-96b6-f726e7e41d7a',
    CIDADE: 'Santa Maria do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.79351234436035,
    MAXIMO: 29.828598022460938,
    MEDIA: 29.811670505428363,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '55cc0591-7cb4-4d6e-a29c-8bed6aa7c1d9',
    CIDADE: 'Santana do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.561737060546875,
    MAXIMO: 31.41541290283203,
    MEDIA: 30.97264076681698,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8aa8d768-5f0b-494e-ab69-5278b931dd5c',
    CIDADE: 'Santarém',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 32.286720275878906,
    MAXIMO: 37.3294792175293,
    MEDIA: 34.32405150564093,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '806aa27f-9184-49bf-a32c-ac990944b382',
    CIDADE: 'Santarém Novo',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.75299072265625,
    MAXIMO: 29.795392990112305,
    MEDIA: 29.786837650477963,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '041daeaf-3654-498d-a07e-b4ab167e4764',
    CIDADE: 'Santo Antônio do Tauá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.972623825073242,
    MAXIMO: 29.98129653930664,
    MEDIA: 29.97696018218994,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd43987cf-3dbc-4a15-83ec-50329a1cf3da',
    CIDADE: 'São Caetano de Odivelas',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.980810165405273,
    MAXIMO: 30.022842407226562,
    MEDIA: 30.01141733823791,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '21513ff4-1d06-42e7-9561-ca61cfdc98a2',
    CIDADE: 'São Domingos do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.244060516357422,
    MAXIMO: 31.47867202758789,
    MEDIA: 31.35888147354126,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'de105241-feb0-4f9f-a427-cf54e2255c55',
    CIDADE: 'São Domingos do Capim',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.819473266601562,
    MAXIMO: 29.873733520507812,
    MEDIA: 29.851687622070312,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ea1e5687-09e3-4d06-9912-4f6dda36dc30',
    CIDADE: 'São Félix do Xingu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.013437271118164,
    MAXIMO: 30.947965621948242,
    MEDIA: 30.357077041326786,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '31d4b037-6d05-4ccc-9bad-d8c7a7a8f248',
    CIDADE: 'São Francisco do Pará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.79586410522461,
    MAXIMO: 30.029888153076172,
    MEDIA: 29.86212549477802,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd42add2a-dda8-409c-8112-7408aa4fc36a',
    CIDADE: 'São Geraldo do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.41197967529297,
    MAXIMO: 31.99958038330078,
    MEDIA: 31.668784921819512,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '27e4f5f6-3191-4a56-b41d-0414e3b7409b',
    CIDADE: 'São João da Ponta',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.013460159301758,
    MAXIMO: 30.02040672302246,
    MEDIA: 30.01908016767109,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f4cc89a6-fd18-41f1-87b9-4d916a62c646',
    CIDADE: 'São João de Pirabas',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.77251625061035,
    MAXIMO: 29.782466888427734,
    MEDIA: 29.777491569519043,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3f37853e-b799-476b-a6bc-8d0dfc32b43c',
    CIDADE: 'São João do Araguaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.914670944213867,
    MAXIMO: 31.444738388061523,
    MEDIA: 31.158821868896485,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2a4c50c4-9e21-477b-aeb3-72835884d71c',
    CIDADE: 'São Miguel do Guamá',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.798498153686523,
    MAXIMO: 29.828598022460938,
    MEDIA: 29.810292561848957,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ecee87be-100e-4ab0-8eaa-1ab7835b8bee',
    CIDADE: 'São Sebastião da Boa Vista',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.872455596923828,
    MAXIMO: 29.989356994628906,
    MEDIA: 29.915754318237305,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3a320090-e217-4ae4-a590-5131942e95e1',
    CIDADE: 'Sapucaia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.25631332397461,
    MAXIMO: 31.495227813720703,
    MEDIA: 31.40421152114868,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1792b76e-d308-4282-8b58-7e7458b9594e',
    CIDADE: 'Senador José Porfírio',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.48921775817871,
    MAXIMO: 31.359025955200195,
    MEDIA: 30.77179789543152,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1e15c389-82f5-42e6-b8c2-7129ee43db0d',
    CIDADE: 'Soure',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.963796615600586,
    MAXIMO: 29.982868194580078,
    MEDIA: 29.97451400756836,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '160409d9-8c7c-488d-aa87-005ca9768bf7',
    CIDADE: 'Tailândia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.89204216003418,
    MAXIMO: 30.007654190063477,
    MEDIA: 29.93774618421282,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0de093a5-65ff-45f1-b5c8-6dcf172cd6a3',
    CIDADE: 'Terra Alta',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.02040672302246,
    MAXIMO: 30.026411056518555,
    MEDIA: 30.024841663425743,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3d3afda9-56b5-414a-86d3-29e5b7b4fff0',
    CIDADE: 'Terra Santa',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.063982009887695,
    MAXIMO: 31.780048370361328,
    MEDIA: 31.399401092529295,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0475e84b-2f07-4ad9-8fcf-5138a2251d81',
    CIDADE: 'Tomé-Açu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.813295364379883,
    MAXIMO: 30.01865005493164,
    MEDIA: 29.91609506046071,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3450b37e-94ff-4f36-813f-37eaad99ac4a',
    CIDADE: 'Tracuateua',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.727209091186523,
    MAXIMO: 30.088420867919922,
    MEDIA: 29.763164800695794,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0fc8dffa-d3a3-458e-b40e-90729a5b72ca',
    CIDADE: 'Trairão',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.550060272216797,
    MAXIMO: 31.95696449279785,
    MEDIA: 31.76043072262326,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '965e89b9-76d3-45ed-b53f-3b6722170d90',
    CIDADE: 'Tucumã',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.378009796142578,
    MAXIMO: 30.4758243560791,
    MEDIA: 30.4295171101888,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '96777923-093b-47f3-a8a5-b89b5767f28b',
    CIDADE: 'Tucuruí',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.97652244567871,
    MAXIMO: 30.047773361206055,
    MEDIA: 30.015708287556965,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bacb8f27-dac5-42c6-a985-595d619b8b79',
    CIDADE: 'Ulianópolis',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.514896392822266,
    MAXIMO: 31.27326011657715,
    MEDIA: 30.88698361714681,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6f3ec023-9901-4a1f-98a5-aa34205290a0',
    CIDADE: 'Uruará',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 32.56336975097656,
    MAXIMO: 34.85405731201172,
    MEDIA: 33.43236708641052,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'def53ea1-85e4-40b3-a7dd-0f36a34679e3',
    CIDADE: 'Vigia',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.980810165405273,
    MAXIMO: 30.022842407226562,
    MEDIA: 30.001826286315918,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0cd256f3-64e5-4a05-9a40-721e0333dae3',
    CIDADE: 'Viseu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 29.665565490722656,
    MAXIMO: 29.935779571533203,
    MEDIA: 29.80371004740397,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9ad461fe-986c-4018-89c7-19c4837430bc',
    CIDADE: 'Vitória do Xingu',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 30.82371711730957,
    MAXIMO: 31.580699920654297,
    MEDIA: 31.174645614624023,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '965e180a-9f19-41bb-966e-70d5ff6ef3f3',
    CIDADE: 'Xinguara',
    SIGLA: 'PA',
    ESTADO: 'Pará',
    MINIMO: 31.044496536254883,
    MAXIMO: 31.607418060302734,
    MEDIA: 31.301783180236818,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5f7067f7-76e7-4f2d-9442-81a78a4518d7',
    CIDADE: 'Serra do Navio',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.436019897460938,
    MAXIMO: 30.808443069458008,
    MEDIA: 30.61711304004376,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3bcdf0ce-244e-436b-89db-902ce572504e',
    CIDADE: 'Amapá',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.160160064697266,
    MAXIMO: 30.465349197387695,
    MEDIA: 30.287020463209885,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b5574693-d3fc-4ed2-9d0c-0792d36c0e5e',
    CIDADE: 'Pedra Branca do Amapari',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.59190559387207,
    MAXIMO: 31.068449020385742,
    MEDIA: 30.85009740193685,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42a7cefe-5563-402b-be9f-b60e209d672c',
    CIDADE: 'Calçoene',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.256755828857422,
    MAXIMO: 30.605695724487305,
    MEDIA: 30.367599047147312,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5db4b75b-b272-40bb-8118-a5146c6bbcd0',
    CIDADE: 'Cutias',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.224454879760742,
    MAXIMO: 30.423744201660156,
    MEDIA: 30.309469767979213,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae5f9306-9ad9-4053-b23c-e387879499cb',
    CIDADE: 'Ferreira Gomes',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.43487548828125,
    MAXIMO: 30.725065231323242,
    MEDIA: 30.585671016148158,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50308325-e835-45ab-9d16-0d7a4bc69ae1',
    CIDADE: 'Itaubal',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.317230224609375,
    MAXIMO: 30.380746841430664,
    MEDIA: 30.33430576324463,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1298ebec-6e87-4814-934d-6aa0dad29fc6',
    CIDADE: 'Laranjal do Jari',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.19771957397461,
    MAXIMO: 31.762554168701172,
    MEDIA: 30.9230782467386,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7e09225e-e94d-4925-bd86-1c36bbfb2cd4',
    CIDADE: 'Macapá',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.1558780670166,
    MAXIMO: 30.660234451293945,
    MEDIA: 30.38221450079055,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd90ab4cf-e125-408c-aad4-acdbf22471d4',
    CIDADE: 'Mazagão',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.724729537963867,
    MAXIMO: 31.374448776245117,
    MEDIA: 31.04558014288181,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9e19061-2108-4a45-aef7-ceb9f93ebd88',
    CIDADE: 'Oiapoque',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.20840835571289,
    MAXIMO: 30.539203643798828,
    MEDIA: 30.31626009259905,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9485de1d-6e56-474e-85d8-e9ff76cf4479',
    CIDADE: 'Porto Grande',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.570537567138672,
    MAXIMO: 31.005220413208008,
    MEDIA: 30.744794573102677,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31044d4b-da0b-410e-a83f-4c1911b58dc4',
    CIDADE: 'Pracuúba',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.29007339477539,
    MAXIMO: 30.636699676513672,
    MEDIA: 30.45873120625814,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '425d44b7-33b2-423c-9378-a710292e56eb',
    CIDADE: 'Santana',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.619552612304688,
    MAXIMO: 30.711122512817383,
    MEDIA: 30.661232471466064,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ce57e8a-466e-4e6a-9234-4928737ce364',
    CIDADE: 'Tartarugalzinho',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 30.274890899658203,
    MAXIMO: 30.57346534729004,
    MEDIA: 30.426300230480376,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '490036b9-64d1-40ac-8d86-3e59d688924e',
    CIDADE: 'Vitória do Jari',
    SIGLA: 'AP',
    ESTADO: 'Amapá',
    MINIMO: 31.028959274291992,
    MAXIMO: 31.750747680664062,
    MEDIA: 31.35707664489746,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ba7bed8-b7f8-4e2a-89e4-e10520907de8',
    CIDADE: 'Abreulândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.944198608398438,
    MAXIMO: 32.482398986816406,
    MEDIA: 32.18481521606445,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb159463-5548-4acc-bc74-92a025def529',
    CIDADE: 'Aguiarnópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.0350341796875,
    MAXIMO: 33.33171463012695,
    MEDIA: 33.22653257197838,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3576acb5-ef61-4d5b-8083-d9008b75c53b',
    CIDADE: 'Aliança do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.36064910888672,
    MAXIMO: 31.7432861328125,
    MEDIA: 31.53098258972168,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e60e4f4-bd34-4cdb-a044-08728530b7d8',
    CIDADE: 'Almas',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.343395233154297,
    MAXIMO: 32.5584831237793,
    MEDIA: 31.973692380464993,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3c0a6ad-b3e3-4ab3-8763-50df7e02631a',
    CIDADE: 'Alvorada',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.43828010559082,
    MAXIMO: 30.649518966674805,
    MEDIA: 30.54154396057129,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a81c592d-5a97-4927-8854-3c0c804b6a8b',
    CIDADE: 'Ananás',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.058223724365234,
    MAXIMO: 32.50788116455078,
    MEDIA: 32.25422058105469,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1f16cf3-86ea-4eee-ad3c-861a35e2c3bc',
    CIDADE: 'Angico',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.50788116455078,
    MAXIMO: 32.84556198120117,
    MEDIA: 32.649969884873975,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6dfd759-4f5a-4d85-9778-94774c6bcd9d',
    CIDADE: 'Aparecida do Rio Negro',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.26023864746094,
    MAXIMO: 33.434200286865234,
    MEDIA: 33.3641471862793,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6dc770c-374a-4e43-8f00-fe0bd1c06e7a',
    CIDADE: 'Aragominas',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.9265193939209,
    MAXIMO: 32.376583099365234,
    MEDIA: 32.14888827006022,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30dedc68-402a-464e-bf0f-36bc8d8efc01',
    CIDADE: 'Araguacema',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.67058563232422,
    MAXIMO: 32.11555862426758,
    MEDIA: 31.905829620361327,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e406a15-1181-423b-a851-8a1fca463ae5',
    CIDADE: 'Araguaçu',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.033706665039062,
    MAXIMO: 30.309799194335938,
    MEDIA: 30.144046425819397,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42e3a87f-92d2-4d8c-aec3-86cf7f05a651',
    CIDADE: 'Araguaína',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.85059928894043,
    MAXIMO: 32.82158660888672,
    MEDIA: 32.367625163151665,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6797a999-733c-4aa2-be8f-c8f5dba4999c',
    CIDADE: 'Araguanã',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.989850997924805,
    MAXIMO: 32.30318832397461,
    MEDIA: 32.12052090962728,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41612db4-3827-40b0-af0f-4241d46e626c',
    CIDADE: 'Araguatins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.614158630371094,
    MAXIMO: 32.21833419799805,
    MEDIA: 31.914616584777832,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39cd8ecf-805f-406c-882c-897eefa7257e',
    CIDADE: 'Arapoema',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.943523406982422,
    MAXIMO: 32.46039581298828,
    MEDIA: 32.23824405670166,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47873a17-ab22-411e-b606-2e389955d2d8',
    CIDADE: 'Arraias',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.067106246948242,
    MAXIMO: 30.770828247070312,
    MEDIA: 30.36642848744112,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '351f9271-dced-494c-bdb6-f5d9cc4b3b5d',
    CIDADE: 'Augustinópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.59198570251465,
    MAXIMO: 32.19442367553711,
    MEDIA: 31.98285909769295,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea9d6984-18dc-4d05-a13e-a2afb957e716',
    CIDADE: 'Aurora do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.60099220275879,
    MAXIMO: 30.646516799926758,
    MEDIA: 30.622172673543293,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8eb3662c-e014-418d-87b5-a651b76d1aee',
    CIDADE: 'Axixá do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.19442367553711,
    MAXIMO: 32.397789001464844,
    MEDIA: 32.23795751787915,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8af15da9-e420-4afa-b976-65cdd5d4dffa',
    CIDADE: 'Babaçulândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.88056564331055,
    MAXIMO: 33.22603225708008,
    MEDIA: 33.040480041503905,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2ee817b-7d09-4d82-b8e1-4479417ecae9',
    CIDADE: 'Bandeirantes do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.44810485839844,
    MAXIMO: 32.6824951171875,
    MEDIA: 32.572566223144534,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ff87ff6-8da8-41e5-a78e-ff793139aa72',
    CIDADE: 'Barra do Ouro',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.40450668334961,
    MAXIMO: 33.53990936279297,
    MEDIA: 33.45562489827474,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a712b5e-7f00-4cc2-9895-421cf54a024e',
    CIDADE: 'Barrolândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.598365783691406,
    MAXIMO: 32.7919921875,
    MEDIA: 32.6951789855957,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e5c90697-4158-4127-88bd-891f6f0a2233',
    CIDADE: 'Bernardo Sayão',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.998849868774414,
    MAXIMO: 32.397457122802734,
    MEDIA: 32.24994977315267,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8ac0c3b-77c2-49e9-84f2-e9109358d097',
    CIDADE: 'Bom Jesus do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.25068664550781,
    MAXIMO: 33.722434997558594,
    MEDIA: 33.5234317779541,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '158d507f-e309-4283-bdd6-17c726600521',
    CIDADE: 'Brasilândia do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.88195037841797,
    MAXIMO: 32.916900634765625,
    MEDIA: 32.8994255065918,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6e77856-b7c9-4dbe-9234-de54f825db05',
    CIDADE: 'Brejinho de Nazaré',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.923124313354492,
    MAXIMO: 32.46094512939453,
    MEDIA: 32.112309265136716,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a674dac-2b9e-464f-b7a8-2378f0b669fc',
    CIDADE: 'Buriti do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.42464256286621,
    MAXIMO: 31.769336700439453,
    MEDIA: 31.608496949720163,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14233316-92eb-4aca-9a72-8bc251812e95',
    CIDADE: 'Cachoeirinha',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.32807922363281,
    MAXIMO: 32.86592102050781,
    MEDIA: 32.57531566109659,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9210336-b97b-4133-be18-120ef6d3d2a5',
    CIDADE: 'Campos Lindos',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.9400749206543,
    MAXIMO: 34.761688232421875,
    MEDIA: 34.36333656311035,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8216f41-aa35-49c5-b503-f80a9bb952e3',
    CIDADE: 'Cariri do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.657699584960938,
    MAXIMO: 30.986854553222656,
    MEDIA: 30.8521089553833,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd5baefb5-3c12-4936-896f-423fbec0c693',
    CIDADE: 'Carmolândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.30318832397461,
    MAXIMO: 32.541080474853516,
    MEDIA: 32.44880794645788,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0f4e9d9c-1848-483c-8374-0c19f4180b52',
    CIDADE: 'Carrasco Bonito',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.59198570251465,
    MAXIMO: 31.899110794067383,
    MEDIA: 31.775870067835243,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b62761b3-cec2-475c-906e-de8801ea928e',
    CIDADE: 'Caseara',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.5382080078125,
    MAXIMO: 31.681608200073242,
    MEDIA: 31.608781178792317,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '145902ea-2877-45f6-8de2-650eba966bf6',
    CIDADE: 'Centenário',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.56570816040039,
    MAXIMO: 33.9860725402832,
    MEDIA: 33.78360094342913,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ff94c7b-13db-4a94-911f-2dc23ca90d35',
    CIDADE: 'Chapada de Areia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.872467041015625,
    MAXIMO: 32.33634567260742,
    MEDIA: 32.0733528419909,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ede01fd-b174-4d43-b2d8-ea9776af81bf',
    CIDADE: 'Chapada da Natividade',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.494056701660156,
    MAXIMO: 31.989604949951172,
    MEDIA: 31.70189603169759,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28527365-627a-4fbe-bf99-71e4bec16916',
    CIDADE: 'Colinas do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.622100830078125,
    MAXIMO: 32.84221649169922,
    MEDIA: 32.72912343343099,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc0c9a11-54f2-4efc-b843-feadde7fd0f1',
    CIDADE: 'Combinado',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.277523040771484,
    MAXIMO: 30.441953659057617,
    MEDIA: 30.390895626809886,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10206dc5-ad3f-4d89-b951-14c460f0d1f9',
    CIDADE: 'Conceição do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.813617706298828,
    MAXIMO: 31.187463760375977,
    MEDIA: 30.97754042489188,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '67c1499c-aa84-4570-8b5d-26027c6c4ade',
    CIDADE: 'Couto Magalhães',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.007713317871094,
    MAXIMO: 32.19066619873047,
    MEDIA: 32.096384048461914,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36034921-7f91-40c4-8600-5d6de76f5839',
    CIDADE: 'Cristalândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.565746307373047,
    MAXIMO: 31.906034469604492,
    MEDIA: 31.727866172790527,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47c520b4-d32b-49bc-9601-60bdcfbd9f82',
    CIDADE: 'Crixás do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.424734115600586,
    MAXIMO: 31.79561424255371,
    MEDIA: 31.608405113220215,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e5a9706-95d3-4643-b530-76347a94f437',
    CIDADE: 'Darcinópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.65517807006836,
    MAXIMO: 33.197166442871094,
    MEDIA: 32.94983825683594,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b254c41-bda2-40b1-a6b3-97301f995bb6',
    CIDADE: 'Dianópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.112548828125,
    MAXIMO: 31.90674591064453,
    MEDIA: 31.568771015514027,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09aff8a4-4896-49fb-8e98-93a1b61663b8',
    CIDADE: 'Divinópolis do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.794612884521484,
    MAXIMO: 32.23994445800781,
    MEDIA: 31.98160719871521,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fac33e0-59b5-48d8-8c94-ade53d2ad3ae',
    CIDADE: 'Dois Irmãos do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.11738967895508,
    MAXIMO: 32.675315856933594,
    MEDIA: 32.38367878306996,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '902265e2-9042-48fa-bea0-1d4cc6f096b3',
    CIDADE: 'Dueré',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.960405349731445,
    MAXIMO: 31.298826217651367,
    MEDIA: 31.14635107252333,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b1472f5-cd07-4095-b5d9-f3dff4f4dd4c',
    CIDADE: 'Esperantina',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.000593185424805,
    MAXIMO: 31.444738388061523,
    MEDIA: 31.26712662578425,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '07a30717-83d8-43d3-8e01-278c2a02ec63',
    CIDADE: 'Fátima',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.94983673095703,
    MAXIMO: 32.24411392211914,
    MEDIA: 32.066110792940776,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed88585e-33e9-4825-8281-2eb6aa109fed',
    CIDADE: 'Figueirópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.40264129638672,
    MAXIMO: 30.715335845947266,
    MEDIA: 30.539690017700195,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69b30c59-f481-41ef-896e-7cb737963952',
    CIDADE: 'Filadélfia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.97123718261719,
    MAXIMO: 33.36751937866211,
    MEDIA: 33.178400312151226,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6cffc1f4-8369-42fb-8461-655f47aa82f3',
    CIDADE: 'Formoso do Araguaia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.092498779296875,
    MAXIMO: 30.990259170532227,
    MEDIA: 30.533333894683093,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b58553a7-a109-42a0-9c68-78b276d7a870',
    CIDADE: 'Tabocão',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.82044982910156,
    MAXIMO: 33.021568298339844,
    MEDIA: 32.9210090637207,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df99e7a2-9e7b-4a49-b51b-72aa78e547df',
    CIDADE: 'Goianorte',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.22542190551758,
    MAXIMO: 32.67986297607422,
    MEDIA: 32.44245529174805,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '400d4213-4638-440a-97b0-b601f308d3d3',
    CIDADE: 'Goiatins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.261592864990234,
    MAXIMO: 33.958213806152344,
    MEDIA: 33.62696798224198,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba051c2c-c9b8-4b2e-bfb1-b7443be02cde',
    CIDADE: 'Guaraí',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.74283218383789,
    MAXIMO: 33.231807708740234,
    MEDIA: 32.987401750352646,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fce60fa2-d9fe-48d1-80a2-c5bc602d0057',
    CIDADE: 'Gurupi',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.060447692871094,
    MAXIMO: 31.562057495117188,
    MEDIA: 31.286688804626465,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a455afd-b5a1-4f43-91db-76339ef80fce',
    CIDADE: 'Ipueiras',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.05292510986328,
    MAXIMO: 32.25996780395508,
    MEDIA: 32.14587529500326,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e4f25d9-e4cd-4788-9493-45b4fe7c94d0',
    CIDADE: 'Itacajá',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.18174362182617,
    MAXIMO: 33.82884979248047,
    MEDIA: 33.51246376037598,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'be0e7775-8c89-473c-8158-44bf23f43154',
    CIDADE: 'Itaguatins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.516746520996094,
    MAXIMO: 32.7360725402832,
    MEDIA: 32.62640953063965,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dade3fe9-fc23-4182-b6ef-db28c921db49',
    CIDADE: 'Itapiratins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.005516052246094,
    MAXIMO: 33.44542694091797,
    MEDIA: 33.19261932373047,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a266a47a-d164-4055-96c3-6e213f824dd5',
    CIDADE: 'Itaporã do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.602012634277344,
    MAXIMO: 32.634033203125,
    MEDIA: 32.61802291870117,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7d880a3-4b36-4655-8cf5-e1f044c22475',
    CIDADE: 'Jaú do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.258197784423828,
    MAXIMO: 30.66900634765625,
    MEDIA: 30.42966365814209,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2db726a3-ee38-4f7c-9ca6-06a0051ce754',
    CIDADE: 'Juarina',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.048736572265625,
    MAXIMO: 32.09663391113281,
    MEDIA: 32.07268524169922,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72b39cbf-7b48-4fae-98f3-6e6de6162382',
    CIDADE: 'Lagoa da Confusão',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.4937801361084,
    MAXIMO: 31.46344566345215,
    MEDIA: 30.92868330819266,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba467219-df52-4d72-9878-8dc93544913b',
    CIDADE: 'Lagoa do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.243003845214844,
    MAXIMO: 33.3543815612793,
    MEDIA: 33.29869270324707,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5ed0367-3b18-4f14-8cb4-89acfdb0f1f1',
    CIDADE: 'Lajeado',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.13588333129883,
    MAXIMO: 33.28061294555664,
    MEDIA: 33.164628605627996,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09fae5cf-32d2-47cc-b3a4-ac4f7e5a2c28',
    CIDADE: 'Lavandeira',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.292160034179688,
    MAXIMO: 30.434423446655273,
    MEDIA: 30.36329174041748,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53052cea-3bc2-4da1-974d-7184bfe639cf',
    CIDADE: 'Lizarda',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.55923843383789,
    MAXIMO: 34.278167724609375,
    MEDIA: 33.97828247967888,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75d479a1-cb7a-480a-aabf-9d190603a57a',
    CIDADE: 'Luzinópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.43674087524414,
    MAXIMO: 32.970191955566406,
    MEDIA: 32.66307332972452,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5630e02c-d676-4e02-9049-acb4ef0fca28',
    CIDADE: 'Marianópolis do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.47223472595215,
    MAXIMO: 31.790925979614258,
    MEDIA: 31.636094729105633,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37d303cf-b500-4382-86b1-706c8ae17bb7',
    CIDADE: 'Mateiros',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.22739028930664,
    MAXIMO: 33.5948600769043,
    MEDIA: 33.06236241658529,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4713cd23-0d0a-46b9-ace9-347cf2561594',
    CIDADE: 'Maurilândia do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.629730224609375,
    MAXIMO: 32.879112243652344,
    MEDIA: 32.791587829589844,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c48bf1c-f3e3-406e-8e14-0aa9f8d35cc5',
    CIDADE: 'Miracema do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.58329772949219,
    MAXIMO: 33.044219970703125,
    MEDIA: 32.85610029432509,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f889445e-3265-44bd-82de-d4d6300a2482',
    CIDADE: 'Miranorte',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.668701171875,
    MAXIMO: 32.8414306640625,
    MEDIA: 32.79434013366699,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54e1d88a-cfac-4f97-b358-a3ceb677d4e5',
    CIDADE: 'Monte do Carmo',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.48708724975586,
    MAXIMO: 33.302711486816406,
    MEDIA: 32.91049020940607,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f059805-bdbd-430b-9426-46e8bac9a4b8',
    CIDADE: 'Monte Santo do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.04106521606445,
    MAXIMO: 32.47154998779297,
    MEDIA: 32.27205944061279,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bcbb9ce6-60f1-493b-ad79-b52eb508c137',
    CIDADE: 'Palmeiras do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.08483123779297,
    MAXIMO: 33.33171463012695,
    MEDIA: 33.20827293395996,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '968144bb-8be8-4ea4-bf83-40dadd300d00',
    CIDADE: 'Muricilândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.86146354675293,
    MAXIMO: 32.287391662597656,
    MEDIA: 32.08674278259277,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b70d67b-6524-4844-b226-bd8c9b43b070',
    CIDADE: 'Natividade',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.930408477783203,
    MAXIMO: 31.964269638061523,
    MEDIA: 31.385115990271935,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19f8c050-cd28-48fd-b2dc-601e9f69ece9',
    CIDADE: 'Nazaré',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.50788116455078,
    MAXIMO: 33.248146057128906,
    MEDIA: 32.808439969304224,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a177f55a-8dff-4230-a5e4-b103fdbf9864',
    CIDADE: 'Nova Olinda',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.55195617675781,
    MAXIMO: 32.73160171508789,
    MEDIA: 32.65138530731201,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b96e2f7-02eb-449e-9acf-7f9f30a39116',
    CIDADE: 'Nova Rosalândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.906034469604492,
    MAXIMO: 32.37382507324219,
    MEDIA: 32.14558623675309,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '821bd43d-be15-4e16-a715-ab85ff9451ed',
    CIDADE: 'Novo Acordo',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.27958679199219,
    MAXIMO: 33.570552825927734,
    MEDIA: 33.423155042860245,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e7ea0ef1-dbef-4a4b-a0a3-3cbcf6900f9d',
    CIDADE: 'Novo Alegre',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.241052627563477,
    MAXIMO: 30.441953659057617,
    MEDIA: 30.306615566819588,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a3afce7-3caf-49cf-bffa-7b6552f0b345',
    CIDADE: 'Novo Jardim',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.302169799804688,
    MAXIMO: 31.734743118286133,
    MEDIA: 31.559330463409424,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71232c46-3400-4691-9d17-b4e2424a3aac',
    CIDADE: 'Oliveira de Fátima',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.07252502441406,
    MAXIMO: 32.37382507324219,
    MEDIA: 32.17197796339489,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4d1db34-99d2-4a30-96de-b6d4e9486737',
    CIDADE: 'Palmeirante',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.78219985961914,
    MAXIMO: 33.10432434082031,
    MEDIA: 32.920612812042236,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58372902-a12f-4cf8-b8d0-39654599b215',
    CIDADE: 'Palmeirópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.114723205566406,
    MAXIMO: 30.273494720458984,
    MEDIA: 30.19328784942627,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0917aea9-1a3f-465a-932f-a2d61f59a787',
    CIDADE: 'Paraíso do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.33634567260742,
    MAXIMO: 32.52810287475586,
    MEDIA: 32.44240188598633,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25b47d8d-ad67-4eb9-909c-a68cb66b97a3',
    CIDADE: 'Paranã',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.01519775390625,
    MAXIMO: 30.925443649291992,
    MEDIA: 30.41582155227661,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '196bf366-f7fd-4182-b10a-85129fbb37bc',
    CIDADE: "Pau D'Arco",
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.85386848449707,
    MAXIMO: 32.39405059814453,
    MEDIA: 32.05934104919434,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4dc77081-816f-4ce2-92ba-e4d9f4f933ad',
    CIDADE: 'Pedro Afonso',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.19339370727539,
    MAXIMO: 33.60028839111328,
    MEDIA: 33.36853917439779,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd56462b-7de3-4ae8-899e-fd287d35f189',
    CIDADE: 'Peixe',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.528648376464844,
    MAXIMO: 31.650026321411133,
    MEDIA: 31.027249230278862,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2367a7e2-1823-4119-9e61-d676861f249c',
    CIDADE: 'Pequizeiro',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.43688201904297,
    MAXIMO: 32.56612014770508,
    MEDIA: 32.482370376586914,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a05a907-a8e3-4253-b518-2530931f4e65',
    CIDADE: 'Colméia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.610652923583984,
    MAXIMO: 32.63155746459961,
    MEDIA: 32.6211051940918,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db27ae60-b845-42f9-b533-103a1fa69a3f',
    CIDADE: 'Pindorama do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.22710418701172,
    MAXIMO: 32.51734924316406,
    MEDIA: 32.339857737223305,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ccaaf318-bd43-4a59-9452-a98b1e2b25e9',
    CIDADE: 'Piraquê',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.312129974365234,
    MAXIMO: 32.622310638427734,
    MEDIA: 32.47086842854818,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef1d7e22-7fb0-481f-a2ba-08bc6ce85403',
    CIDADE: 'Pium',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.897768020629883,
    MAXIMO: 32.077308654785156,
    MEDIA: 31.50052969796317,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db1f1d9a-ae98-4004-a888-55dfda5745da',
    CIDADE: 'Ponte Alta do Bom Jesus',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.943275451660156,
    MAXIMO: 31.530364990234375,
    MEDIA: 31.23810338973999,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48f6ac4b-60a4-4c98-9af8-7bb4bf01bc9c',
    CIDADE: 'Ponte Alta do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.44839859008789,
    MAXIMO: 33.17462158203125,
    MEDIA: 32.86168730886359,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a586f45-54ba-4f6f-be83-5760619bfdfa',
    CIDADE: 'Porto Alegre do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.34002113342285,
    MAXIMO: 32.049137115478516,
    MEDIA: 31.800747280644167,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4df38bf7-ed37-4741-9ff1-50eac9b8d4a7',
    CIDADE: 'Porto Nacional',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.24411392211914,
    MAXIMO: 32.97740173339844,
    MEDIA: 32.61353276570638,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a275714-1545-4fb1-90c4-54cc84778343',
    CIDADE: 'Praia Norte',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.95090103149414,
    MAXIMO: 32.30448532104492,
    MEDIA: 32.13867530989982,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e953f3c-3d45-4147-9f2e-64add8bf5c2e',
    CIDADE: 'Presidente Kennedy',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.75868225097656,
    MAXIMO: 33.07361602783203,
    MEDIA: 32.92501958211263,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f390bc16-5812-4689-a515-7552dcc423e5',
    CIDADE: 'Pugmil',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.17747497558594,
    MAXIMO: 32.4627571105957,
    MEDIA: 32.303601970984445,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a145c3a-495c-47b6-857d-55ed9320a612',
    CIDADE: 'Recursolândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.85417938232422,
    MAXIMO: 34.176536560058594,
    MEDIA: 34.010775883992515,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7cdf6482-8c19-4270-946e-64d6697c4ee6',
    CIDADE: 'Riachinho',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.36315155029297,
    MAXIMO: 32.56370162963867,
    MEDIA: 32.46342658996582,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '335b89da-8067-4fae-9e6b-b166481fcb10',
    CIDADE: 'Rio da Conceição',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.027400970458984,
    MAXIMO: 32.03802490234375,
    MEDIA: 32.03271293640137,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f7ee472-616c-4299-8eba-476b98c17759',
    CIDADE: 'Rio dos Bois',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.82982635498047,
    MAXIMO: 33.19339370727539,
    MEDIA: 33.043699451153145,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85b5749e-9dea-4340-9689-48fcc8a101b9',
    CIDADE: 'Rio Sono',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.517372131347656,
    MAXIMO: 33.95320129394531,
    MEDIA: 33.701955223083495,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41d3ac01-daf0-4d22-adea-45dcad3e1c6a',
    CIDADE: 'Sampaio',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.769336700439453,
    MAXIMO: 32.10199737548828,
    MEDIA: 31.85313014630083,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b58069a0-a582-4069-83b9-4c9ef1f00ae5',
    CIDADE: 'Sandolândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.222698211669922,
    MAXIMO: 30.518163681030273,
    MEDIA: 30.359667142232258,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a37d0367-86a9-4ff9-9e6f-4fce3d9b1aea',
    CIDADE: 'Santa Fé do Araguaia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.730281829833984,
    MAXIMO: 32.136474609375,
    MEDIA: 31.89648723602295,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd549477-d5c0-48df-97f0-12b6af9f1f60',
    CIDADE: 'Santa Maria do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.45796585083008,
    MAXIMO: 33.70861053466797,
    MEDIA: 33.54407215118408,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'deb37930-b4b6-4534-95f5-50674ceb2eac',
    CIDADE: 'Santa Rita do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.195465087890625,
    MAXIMO: 31.94983673095703,
    MEDIA: 31.56386444785378,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7bfbf242-2c01-4d51-b881-ff0ea805e934',
    CIDADE: 'Santa Rosa do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.71341896057129,
    MAXIMO: 32.00634002685547,
    MEDIA: 31.865834426879882,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b3c224d-c6c7-4915-b993-ea3a70d75a95',
    CIDADE: 'Santa Tereza do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.268463134765625,
    MAXIMO: 33.285831451416016,
    MEDIA: 33.27714729309082,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '91d2fdae-839b-49c6-bdae-89933898c14b',
    CIDADE: 'Santa Terezinha do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.79592514038086,
    MAXIMO: 33.0350341796875,
    MEDIA: 32.91547966003418,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9629c958-0248-41f3-8d34-8bc0640bfa5d',
    CIDADE: 'São Bento do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.3140983581543,
    MAXIMO: 32.52109146118164,
    MEDIA: 32.40000247955322,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '516947a4-2659-4a31-8742-7d5ab20ec569',
    CIDADE: 'São Félix do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.55621337890625,
    MAXIMO: 34.12987518310547,
    MEDIA: 33.782714298793245,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3acc684-de34-450c-9b46-e27b493990e7',
    CIDADE: 'São Miguel do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.30448532104492,
    MAXIMO: 32.61509704589844,
    MEDIA: 32.45979118347168,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fdbffcc-fbec-4c93-a1f5-2baada3b2a3c',
    CIDADE: 'São Salvador do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.319204330444336,
    MAXIMO: 30.75124168395996,
    MEDIA: 30.504372596740723,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7261a73-139e-4d0a-b157-d3e71d5fcfdd',
    CIDADE: 'São Sebastião do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 31.150774002075195,
    MAXIMO: 31.59198570251465,
    MEDIA: 31.37039616478591,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '015e9940-b1c2-452b-8a5c-aac0d983dae5',
    CIDADE: 'São Valério',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.917375564575195,
    MAXIMO: 31.846181869506836,
    MEDIA: 31.33462119102478,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cafb2319-ed9c-4c43-b1ce-62e54df1e63e',
    CIDADE: 'Silvanópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.23469161987305,
    MAXIMO: 32.5319709777832,
    MEDIA: 32.32389450073242,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '776e1fd5-e9a4-48bc-ae0f-1e94d506f55c',
    CIDADE: 'Sítio Novo do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.10199737548828,
    MAXIMO: 32.516746520996094,
    MEDIA: 32.36930683116171,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '655eb1f2-8f7d-4512-a3ac-6cce3a0c53cc',
    CIDADE: 'Sucupira',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.775556564331055,
    MAXIMO: 30.980880737304688,
    MEDIA: 30.876009941101074,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f04a309d-9e2c-4e65-b9db-4b25b7d7817a',
    CIDADE: 'Taguatinga',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.786602020263672,
    MAXIMO: 31.17877197265625,
    MEDIA: 30.907259259905135,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9266251-9482-4eab-bbc6-f2d3668c2dc7',
    CIDADE: 'Taipas do Tocantins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.931415557861328,
    MAXIMO: 31.2741756439209,
    MEDIA: 31.102737426757812,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '883a358b-2a75-43a9-890b-0dafa825da5f',
    CIDADE: 'Talismã',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 30.232858657836914,
    MAXIMO: 30.57383918762207,
    MEDIA: 30.37343947092692,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c762466-1a90-480d-98b0-c5b1cb3ec92c',
    CIDADE: 'Palmas',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.059295654296875,
    MAXIMO: 33.41325759887695,
    MEDIA: 33.215365818568635,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5d50929-be94-4e1d-95bf-5bae0a628c23',
    CIDADE: 'Tocantínia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.15498733520508,
    MAXIMO: 33.48431396484375,
    MEDIA: 33.31135982937283,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7ccafa88-0982-4889-bcc1-486f07bfd38b',
    CIDADE: 'Tocantinópolis',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.970191955566406,
    MAXIMO: 33.191383361816406,
    MEDIA: 33.049296061197914,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f547ccf5-0ac7-46a1-a62f-10a0af2672b3',
    CIDADE: 'Tupirama',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.12419891357422,
    MAXIMO: 33.18341827392578,
    MEDIA: 33.15380859375,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bec4ff68-1397-4704-9beb-e348fddb916b',
    CIDADE: 'Tupiratins',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 33.04353713989258,
    MAXIMO: 33.21026611328125,
    MEDIA: 33.126901626586914,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60a2fdce-5bc9-4a33-906e-390e2797adb4',
    CIDADE: 'Wanderlândia',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.692787170410156,
    MAXIMO: 33.00907516479492,
    MEDIA: 32.818448066711426,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c328f71-6ea3-4153-9027-e829afe3bf39',
    CIDADE: 'Xambioá',
    SIGLA: 'TO',
    ESTADO: 'Tocantins',
    MINIMO: 32.0680046081543,
    MAXIMO: 32.223426818847656,
    MEDIA: 32.164740562438965,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2007200-84da-40a1-a5d8-fec08768065b',
    CIDADE: 'Açailândia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.39824676513672,
    MAXIMO: 33.03925323486328,
    MEDIA: 32.28325776492848,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0621efb-aeaa-4111-b571-746d2b710462',
    CIDADE: 'Afonso Cunha',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.71389389038086,
    MAXIMO: 40.35951232910156,
    MEDIA: 40.03670310974121,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02b9cbd6-1911-498f-bd2d-7263cb676704',
    CIDADE: 'Água Doce do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.402074813842773,
    MAXIMO: 31.01993751525879,
    MEDIA: 30.70128059387207,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39343955-1e73-4e27-a8ec-b00a3113391d',
    CIDADE: 'Alcântara',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.28095245361328,
    MAXIMO: 36.4698486328125,
    MEDIA: 35.59543991088867,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77f4c0d0-809f-4690-8d0c-f284856902e3',
    CIDADE: 'Aldeias Altas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.636741638183594,
    MAXIMO: 41.50680160522461,
    MEDIA: 40.997843424479164,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a481a1f7-823a-4fe2-b6ee-c1e4e4925132',
    CIDADE: 'Altamira do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.673614501953125,
    MAXIMO: 36.200889587402344,
    MEDIA: 35.937252044677734,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8aaa310-9682-4e60-a2f9-a47f9fc02712',
    CIDADE: 'Alto Alegre do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.6630973815918,
    MAXIMO: 39.54555130004883,
    MEDIA: 39.075107384572306,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e666a24-e495-4f7a-9000-290307f2517a',
    CIDADE: 'Alto Alegre do Pindaré',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.091007232666016,
    MAXIMO: 34.16938018798828,
    MEDIA: 33.66768741607666,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b7e6813-a9e7-4479-b94c-ffa4164fbb83',
    CIDADE: 'Alto Parnaíba',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.465728759765625,
    MAXIMO: 34.733001708984375,
    MEDIA: 34.163031073177564,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a002da4b-297c-4f46-a395-32c281c5e3df',
    CIDADE: 'Amapá do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.871633529663086,
    MAXIMO: 29.996875762939453,
    MEDIA: 29.93935203552246,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e1d678a-7356-4dc7-8a29-06efb9d967e0',
    CIDADE: 'Amarante do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.31844711303711,
    MAXIMO: 35.087615966796875,
    MEDIA: 34.19023531959171,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b1327d7-5b02-481a-b6ea-a8312e5b6b36',
    CIDADE: 'Anajatuba',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.14672088623047,
    MAXIMO: 37.68266296386719,
    MEDIA: 37.44462331136068,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '73be9087-5c72-4b4b-8cba-049473730593',
    CIDADE: 'Anapurus',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.410980224609375,
    MAXIMO: 37.209163665771484,
    MEDIA: 36.89246114095052,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '955cef95-67e7-4bd2-8a68-55b46e5a654c',
    CIDADE: 'Apicum-Açu',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.042028427124023,
    MAXIMO: 30.73955726623535,
    MEDIA: 30.354026846292093,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e70dc0c0-85e4-42f9-8c2e-fa1dabdd7be4',
    CIDADE: 'Araguanã',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.23447799682617,
    MAXIMO: 32.679893493652344,
    MEDIA: 32.512939453125,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65f292d5-c62a-4d0c-a118-6acded30b3ff',
    CIDADE: 'Araioses',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.163545608520508,
    MAXIMO: 31.0368709564209,
    MEDIA: 30.571821212768555,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f933608c-a145-4d2c-9272-6481c0a05235',
    CIDADE: 'Arame',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.79742431640625,
    MAXIMO: 36.19693374633789,
    MEDIA: 35.449497604370116,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'adb3a8c5-cdcc-4890-9974-285a4a01075b',
    CIDADE: 'Arari',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.383155822753906,
    MAXIMO: 37.61784362792969,
    MEDIA: 37.5004997253418,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b15de490-6d98-4756-8bd7-7a575140bc23',
    CIDADE: 'Axixá',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.26006317138672,
    MAXIMO: 38.54267501831055,
    MEDIA: 38.41863037494058,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a3698ea-bc7f-4db9-a227-8b44a57d745f',
    CIDADE: 'Bacabal',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.77609634399414,
    MAXIMO: 38.6630973815918,
    MEDIA: 38.12498016357422,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d4a3034-547a-4e7f-9f1d-da544ce53769',
    CIDADE: 'Bacabeira',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.79306411743164,
    MAXIMO: 37.889339447021484,
    MEDIA: 37.84120178222656,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0029754-266f-45ba-a1e1-93025825f8ca',
    CIDADE: 'Bacuri',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.282533645629883,
    MAXIMO: 30.563135147094727,
    MEDIA: 30.422834396362305,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '51d25d9c-4650-405a-9fde-33bace2a771c',
    CIDADE: 'Bacurituba',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.976905822753906,
    MAXIMO: 36.76287078857422,
    MEDIA: 35.98930021138537,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '018a6bb8-665d-4b4a-8a47-85bf1ae3bd08',
    CIDADE: 'Balsas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.04353332519531,
    MAXIMO: 35.97304916381836,
    MEDIA: 34.98687628812568,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7f74319c-88ac-4a2c-a0f0-cb6d215c827e',
    CIDADE: 'Barão de Grajaú',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.82094955444336,
    MAXIMO: 40.8744010925293,
    MEDIA: 40.44511686052595,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '466ac996-9426-4e0f-8e71-701159154e2f',
    CIDADE: 'Barra do Corda',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.314212799072266,
    MAXIMO: 38.63715362548828,
    MEDIA: 37.90899887084961,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '420cc4f8-8f47-4773-a976-5c5c2169f7e7',
    CIDADE: 'Barreirinhas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.536785125732422,
    MAXIMO: 34.29536819458008,
    MEDIA: 32.843478520711265,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ef0db57-cc90-42f9-bd19-48c23df408de',
    CIDADE: 'Belágua',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.122718811035156,
    MAXIMO: 37.31486511230469,
    MEDIA: 36.71879196166992,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29cf5162-5b18-411a-8633-7da552a8ad04',
    CIDADE: 'Bela Vista do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.271881103515625,
    MAXIMO: 36.20549392700195,
    MEDIA: 35.82179026538517,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fa9d967-b41d-4244-9d0f-4531c8ccbb21',
    CIDADE: 'Benedito Leite',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.22367477416992,
    MAXIMO: 38.41501998901367,
    MEDIA: 37.878317515055336,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1ebac7f-9b97-49e9-8068-597b1f5ea311',
    CIDADE: 'Bequimão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.43768310546875,
    MAXIMO: 35.24297332763672,
    MEDIA: 34.840328216552734,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f51bd3f8-d003-4acb-a165-ebb7f548af16',
    CIDADE: 'Bernardo do Mearim',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.25135803222656,
    MAXIMO: 39.31423568725586,
    MEDIA: 38.84082516408241,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90e55b1b-ba7c-4df6-81d7-073512907b04',
    CIDADE: 'Boa Vista do Gurupi',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.900423049926758,
    MAXIMO: 30.008047103881836,
    MEDIA: 29.954235076904297,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ab6ecb1-f953-4b15-b114-d1173f93c01d',
    CIDADE: 'Bom Jardim',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.947023391723633,
    MAXIMO: 33.81300354003906,
    MEDIA: 32.608090496063234,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd919a05-02fd-45c2-a115-a0083561b16f',
    CIDADE: 'Bom Jesus das Selvas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.71010208129883,
    MAXIMO: 33.83429718017578,
    MEDIA: 33.2490966796875,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9cfecf82-3f86-4af6-962e-4a5fda369272',
    CIDADE: 'Bom Lugar',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.936458587646484,
    MAXIMO: 38.10649108886719,
    MEDIA: 37.54125872420207,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd720c4c9-1e0a-4058-8b03-24600b2fb0ed',
    CIDADE: 'Brejo',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.908573150634766,
    MAXIMO: 36.8872184753418,
    MEDIA: 36.46522013346354,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '173e9ae8-6662-488f-befd-f3610cb5b603',
    CIDADE: 'Brejo de Areia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.41192626953125,
    MAXIMO: 35.912349700927734,
    MEDIA: 35.65660858154297,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c59078f-5cf5-48e8-87df-142bbf31b5ef',
    CIDADE: 'Buriti',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.461326599121094,
    MAXIMO: 38.73469543457031,
    MEDIA: 38.125436782836914,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3133f38-173c-4730-aea3-da0af53daf88',
    CIDADE: 'Buriti Bravo',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.90256881713867,
    MAXIMO: 41.55463790893555,
    MEDIA: 41.227716064453126,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03583abb-44df-41cf-8d8d-c7fddc84439d',
    CIDADE: 'Buriticupu',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.269676208496094,
    MAXIMO: 34.64225769042969,
    MEDIA: 33.9163023630778,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef6cd0b4-8816-40c1-9374-b520f01f7583',
    CIDADE: 'Buritirana',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.132659912109375,
    MAXIMO: 33.6775016784668,
    MEDIA: 33.44059499104818,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fc61f62-e881-4e11-b1e5-aa81a50c4c66',
    CIDADE: 'Cachoeira Grande',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.24285125732422,
    MAXIMO: 38.35645294189453,
    MEDIA: 38.299652099609375,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d467987-2d4f-4a1d-800d-92baa6b7287a',
    CIDADE: 'Cajapió',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.30199432373047,
    MAXIMO: 37.09707260131836,
    MEDIA: 36.699533462524414,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52ad22a9-feee-4467-a9b3-3505e5972d4b',
    CIDADE: 'Cajari',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.26621627807617,
    MAXIMO: 36.54404067993164,
    MEDIA: 35.90813954671224,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f12e1a2-b679-4b95-87b1-cc68a855d5c5',
    CIDADE: 'Campestre do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.305259704589844,
    MAXIMO: 33.55475616455078,
    MEDIA: 33.43000793457031,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd692c07b-b01f-45ae-b5ab-f3efed1935bc',
    CIDADE: 'Cândido Mendes',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.849925994873047,
    MAXIMO: 30.30215835571289,
    MEDIA: 30.02048568725586,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fbd583ff-ecee-4627-b05b-5b86007c48c9',
    CIDADE: 'Cantanhede',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.450077056884766,
    MAXIMO: 38.838985443115234,
    MEDIA: 38.66134897867838,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7a6d392-0e03-4c0e-8dfb-440638b7257f',
    CIDADE: 'Capinzal do Norte',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.407142639160156,
    MAXIMO: 40.084163665771484,
    MEDIA: 39.782549586857606,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '407a94ad-0ef6-4ee1-a944-b3ec7f82239f',
    CIDADE: 'Carolina',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.269039154052734,
    MAXIMO: 34.55028533935547,
    MEDIA: 33.91561279296875,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9de72d54-4303-4a6e-8869-d79a1e2439ff',
    CIDADE: 'Carutapera',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.688207626342773,
    MAXIMO: 29.769020080566406,
    MEDIA: 29.725059509277344,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4907c77e-9c0d-4941-adf9-eb054bbedbf2',
    CIDADE: 'Caxias',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.92274475097656,
    MAXIMO: 42.63496780395508,
    MEDIA: 41.80081692863913,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bcb93266-3428-4d17-8dd3-6a8c790ad26d',
    CIDADE: 'Cedral',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.7254638671875,
    MAXIMO: 33.070709228515625,
    MEDIA: 32.58427775035378,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '34a282c3-e84d-494d-b068-07aa133fa29f',
    CIDADE: 'Central do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.80469512939453,
    MAXIMO: 33.60294723510742,
    MEDIA: 33.20382118225098,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d4c669f-4f72-458f-ab20-7cb274a6c85c',
    CIDADE: 'Centro do Guilherme',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.455753326416016,
    MAXIMO: 30.72887420654297,
    MEDIA: 30.59224271774292,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8ada79d-9384-4d54-95e5-695e4ccf1c4f',
    CIDADE: 'Centro Novo do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.306631088256836,
    MAXIMO: 31.980857849121094,
    MEDIA: 31.144291162490845,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b161ae03-9ec2-468e-a7cd-8fd077126838',
    CIDADE: 'Chapadinha',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.617149353027344,
    MAXIMO: 39.93584060668945,
    MEDIA: 38.930444197221235,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fcc1b918-0ea5-4fce-9a9c-c8a082a16788',
    CIDADE: 'Cidelândia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.368276596069336,
    MAXIMO: 32.04593276977539,
    MEDIA: 31.737548510233562,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac1f73be-1bf6-4f53-841a-53ce68ed08ab',
    CIDADE: 'Codó',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.92158508300781,
    MAXIMO: 41.089202880859375,
    MEDIA: 40.502890723092214,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '51ac3fc2-f8b0-4141-823d-d0c92556a16b',
    CIDADE: 'Coelho Neto',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.50019836425781,
    MAXIMO: 40.250823974609375,
    MEDIA: 39.875511169433594,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '728ca7ac-fd79-48ee-a94b-0e828b3fd9ef',
    CIDADE: 'Colinas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.49164581298828,
    MAXIMO: 40.557518005371094,
    MEDIA: 40.08606175013951,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e48ed1a3-564e-46b9-b543-31c2b0eb6ed1',
    CIDADE: 'Conceição do Lago-Açu',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.32341766357422,
    MAXIMO: 37.84034729003906,
    MEDIA: 37.58188247680664,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69cf7f4a-01bc-450c-9a46-6466f475b56f',
    CIDADE: 'Coroatá',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.11790466308594,
    MAXIMO: 39.5688362121582,
    MEDIA: 39.33659267425537,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '756c2562-cba3-4d34-b02e-eb21964f2f53',
    CIDADE: 'Cururupu',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.92624855041504,
    MAXIMO: 31.7254638671875,
    MEDIA: 31.357444763183594,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62b34ff8-cad6-4e45-b5ce-6f4bab47ab8e',
    CIDADE: 'Davinópolis',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.8988037109375,
    MAXIMO: 33.21628189086914,
    MEDIA: 33.05754280090332,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '709bc458-00e7-4230-bf4e-0cb99c99fb4a',
    CIDADE: 'Dom Pedro',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.38389205932617,
    MAXIMO: 39.87925338745117,
    MEDIA: 39.783678971155915,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0b7ba21-28c8-493a-baf8-689e9202785e',
    CIDADE: 'Duque Bacelar',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.33894729614258,
    MAXIMO: 39.50019836425781,
    MEDIA: 39.13484986146357,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11c9e2a2-0e1d-449a-bf1d-2604abc63fea',
    CIDADE: 'Esperantinópolis',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.92644119262695,
    MAXIMO: 38.94082260131836,
    MEDIA: 38.397059238807095,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e0ff744-a20c-4679-8e9f-15d8c8fc2b72',
    CIDADE: 'Estreito',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.37105941772461,
    MAXIMO: 34.27072525024414,
    MEDIA: 33.82184076309204,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '447c79ff-60bd-42d5-87c7-d46b840ef1fc',
    CIDADE: 'Feira Nova do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.5131950378418,
    MAXIMO: 34.90109634399414,
    MEDIA: 34.74701436360677,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f38e833-9ef7-4957-83f0-44abde7648f7',
    CIDADE: 'Fernando Falcão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.63335037231445,
    MAXIMO: 38.631614685058594,
    MEDIA: 37.62991129557292,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82912b35-b605-4c72-b1f7-7d16978ccd52',
    CIDADE: 'Formosa da Serra Negra',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.18021774291992,
    MAXIMO: 36.21236038208008,
    MEDIA: 35.665586911714996,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e632c963-3da8-4ad0-b666-61bcfd99778f',
    CIDADE: 'Fortaleza dos Nogueiras',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.45026397705078,
    MAXIMO: 35.735191345214844,
    MEDIA: 35.64543342590332,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5bdb247-bd17-4c3b-8893-f4b3f2c76517',
    CIDADE: 'Fortuna',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.72597122192383,
    MAXIMO: 41.268768310546875,
    MEDIA: 41.00444984436035,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '385ca75f-33b9-4791-a3ef-75e3dbd8f42f',
    CIDADE: 'Godofredo Viana',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.764799118041992,
    MAXIMO: 29.881784439086914,
    MEDIA: 29.823291778564453,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55b59dbe-6fb4-4b97-abaa-fe074a59d42e',
    CIDADE: 'Gonçalves Dias',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.30207061767578,
    MAXIMO: 40.85139846801758,
    MEDIA: 40.57673454284668,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5348ee38-0c8d-4ae8-a1d9-d985e5b0f557',
    CIDADE: 'Governador Archer',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.817195892333984,
    MAXIMO: 40.750179290771484,
    MEDIA: 40.25077258304625,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3eb4ed32-0288-4b83-86d7-f6ba9555918f',
    CIDADE: 'Governador Edison Lobão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.61509704589844,
    MAXIMO: 33.341796875,
    MEDIA: 32.98367024405828,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1e9069f-9d11-4a9e-99bc-2eef20019693',
    CIDADE: 'Governador Eugênio Barros',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.953338623046875,
    MAXIMO: 41.271663665771484,
    MEDIA: 40.75044079745735,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'daca8951-a317-42c5-940d-0fdd900eb75f',
    CIDADE: 'Governador Luiz Rocha',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.269493103027344,
    MAXIMO: 41.268768310546875,
    MEDIA: 40.758981447602956,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12d9b015-7a06-40bd-b420-57cc5fc1433a',
    CIDADE: 'Governador Newton Bello',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.0071907043457,
    MAXIMO: 33.492313385009766,
    MEDIA: 33.249752044677734,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e51a717d-881b-4501-af43-2b593d18b244',
    CIDADE: 'Governador Nunes Freire',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.178464889526367,
    MAXIMO: 30.828176498413086,
    MEDIA: 30.48793935775757,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a9a107d-6bf1-44b1-b723-50271134c26b',
    CIDADE: 'Graça Aranha',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.971763610839844,
    MAXIMO: 40.357032775878906,
    MEDIA: 40.164398193359375,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a88a99d-d876-4171-a819-21502fec033f',
    CIDADE: 'Grajaú',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.14654541015625,
    MAXIMO: 37.079833984375,
    MEDIA: 35.98650184044471,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60fbedaa-ac74-4ab0-a04b-5afaaa319a73',
    CIDADE: 'Guimarães',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.070709228515625,
    MAXIMO: 33.485206604003906,
    MEDIA: 33.277957916259766,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0997551-9acb-4b6a-9825-0f190e817bc2',
    CIDADE: 'Humberto de Campos',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.58535385131836,
    MAXIMO: 36.957489013671875,
    MEDIA: 35.94950485229492,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2d4ae32-8db0-4dbc-8187-15630e4adf45',
    CIDADE: 'Icatu',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.804317474365234,
    MAXIMO: 38.12092590332031,
    MEDIA: 37.46262168884277,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5a72472-2285-4479-9021-8250493271fe',
    CIDADE: 'Igarapé do Meio',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.922760009765625,
    MAXIMO: 36.52987289428711,
    MEDIA: 36.22631645202637,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8b5ea2d-a896-4e2a-b48a-48063f0c42d4',
    CIDADE: 'Igarapé Grande',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.10649108886719,
    MAXIMO: 38.712684631347656,
    MEDIA: 38.30728849642594,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '201bc529-a218-4dbf-af8b-cc5f8484899a',
    CIDADE: 'Imperatriz',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.16602325439453,
    MAXIMO: 32.523643493652344,
    MEDIA: 32.357521057128906,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e5d7741-ad47-47ea-96cf-a7788ee0627b',
    CIDADE: 'Itaipava do Grajaú',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.94839859008789,
    MAXIMO: 37.175994873046875,
    MEDIA: 36.54807758331299,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8830ad4-e21a-4747-95dd-d32fd0520ea1',
    CIDADE: 'Itapecuru Mirim',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.105995178222656,
    MAXIMO: 38.528465270996094,
    MEDIA: 38.35054397583008,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5547de0f-0337-4293-9ade-dca98d93d37e',
    CIDADE: 'Itinga do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.455060958862305,
    MAXIMO: 32.04698181152344,
    MEDIA: 31.681721210479736,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0727eb1-0029-4999-a6eb-9189e40932c8',
    CIDADE: 'Jatobá',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.94675064086914,
    MAXIMO: 40.30864334106445,
    MEDIA: 40.1276969909668,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbc5b8e7-6ea8-4fbb-9b8a-c2ca06251149',
    CIDADE: 'Jenipapo dos Vieiras',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.47882080078125,
    MAXIMO: 37.03712463378906,
    MEDIA: 36.789852905273435,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92335a0b-5b6d-48c4-8708-a9a3eed71268',
    CIDADE: 'João Lisboa',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.73615646362305,
    MAXIMO: 33.20712661743164,
    MEDIA: 32.969441731770836,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '758a8844-5a43-4208-9722-f79ae9fddcbb',
    CIDADE: 'Joselândia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.49715042114258,
    MAXIMO: 38.966651916503906,
    MEDIA: 38.80154164632162,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb3ed235-6334-499a-8ef8-26017c674905',
    CIDADE: 'Junco do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.900423049926758,
    MAXIMO: 30.258760452270508,
    MEDIA: 30.087330344289448,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e7cf42be-8a1a-4eb1-80aa-0ab3e01b1cbc',
    CIDADE: 'Lago da Pedra',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.25828552246094,
    MAXIMO: 37.52215576171875,
    MEDIA: 37.390088399251304,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25b46a21-a9a5-41f1-933f-125d81fc4339',
    CIDADE: 'Lago do Junco',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.6136589050293,
    MAXIMO: 38.10649108886719,
    MEDIA: 37.83313496907552,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7e0b6964-aa4b-458e-8ae1-f752930d33c6',
    CIDADE: 'Lago Verde',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.78559112548828,
    MAXIMO: 38.280391693115234,
    MEDIA: 37.520097044858645,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2108ebe8-f418-482a-ab4f-e20c674ba278',
    CIDADE: 'Lagoa do Mato',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 41.19020080566406,
    MAXIMO: 41.7900390625,
    MEDIA: 41.516152954101564,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '677fed65-a981-4b38-b0c2-07e2e3d802b8',
    CIDADE: 'Lago dos Rodrigues',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.6136589050293,
    MAXIMO: 38.25135803222656,
    MEDIA: 38.06781099355341,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41617287-9b52-47e0-bd7f-c36f81a14961',
    CIDADE: 'Lagoa Grande do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.61763000488281,
    MAXIMO: 37.055511474609375,
    MEDIA: 36.87189865112305,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a09df001-5bd7-44a4-b209-fd00c1c5a99d',
    CIDADE: 'Lajeado Novo',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.900474548339844,
    MAXIMO: 34.1881217956543,
    MEDIA: 34.04429817199707,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a96899b-7105-4ee7-be17-5634de385859',
    CIDADE: 'Lima Campos',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.018165588378906,
    MAXIMO: 39.57675552368164,
    MEDIA: 39.293380471510645,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a80a699-cbfa-47c8-b91c-732414dc7ec2',
    CIDADE: 'Loreto',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.36947250366211,
    MAXIMO: 37.746124267578125,
    MEDIA: 37.024054209391274,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc0b6d34-aeaf-495c-af3d-8a76a1b8d157',
    CIDADE: 'Luís Domingues',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.688207626342773,
    MAXIMO: 29.881784439086914,
    MEDIA: 29.80416516201356,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b5a30dd-2697-45f7-bdf1-6071d32945d2',
    CIDADE: 'Magalhães de Almeida',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.69776153564453,
    MAXIMO: 32.799495697021484,
    MEDIA: 31.633334319607638,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fef4b187-0da1-4274-a80e-df35c100155c',
    CIDADE: 'Maracaçumé',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.0881404876709,
    MAXIMO: 30.258760452270508,
    MEDIA: 30.173450469970703,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c1fba94-f32a-4b38-8912-cdd38ce9a3e6',
    CIDADE: 'Marajá do Sena',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.325782775878906,
    MAXIMO: 36.820892333984375,
    MEDIA: 36.14265823364258,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e2263edc-f921-4127-a967-44c590616872',
    CIDADE: 'Maranhãozinho',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.625566482543945,
    MAXIMO: 31.22060203552246,
    MEDIA: 30.925498962402344,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7598bb55-dce4-4c97-ac6f-84d2a5050a58',
    CIDADE: 'Mata Roma',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.410980224609375,
    MAXIMO: 38.402008056640625,
    MEDIA: 37.50471819523694,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '509243b6-ffb8-447d-8783-f8e64813cecf',
    CIDADE: 'Matinha',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.44046401977539,
    MAXIMO: 36.245269775390625,
    MEDIA: 35.46195287325052,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b42a7581-97c7-40b2-9277-e84b2a912166',
    CIDADE: 'Matões',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 42.022216796875,
    MAXIMO: 43.01565170288086,
    MEDIA: 42.562564849853516,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ca047cf4-ea66-4989-8c86-859d370a899e',
    CIDADE: 'Matões do Norte',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.079689025878906,
    MAXIMO: 38.62400436401367,
    MEDIA: 38.3280283610026,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1941483-ab40-4080-8cf6-dfda854c10c8',
    CIDADE: 'Milagres do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.98540496826172,
    MAXIMO: 35.723392486572266,
    MEDIA: 35.35439872741699,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94082100-8256-4e28-b242-590c888aabb5',
    CIDADE: 'Mirador',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.604286193847656,
    MAXIMO: 39.83152389526367,
    MEDIA: 38.24082286541279,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '283e8bbd-e183-4f1b-a7c8-d51b22b0b895',
    CIDADE: 'Miranda do Norte',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.8739128112793,
    MAXIMO: 38.26449966430664,
    MEDIA: 37.98107548453378,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3de08d3-6379-4cbe-873f-36227d7d3788',
    CIDADE: 'Mirinzal',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.05109405517578,
    MAXIMO: 32.53229522705078,
    MEDIA: 32.29169464111328,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '353cea31-dbc8-4989-ad90-43fde3d60433',
    CIDADE: 'Monção',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.35595703125,
    MAXIMO: 35.53681945800781,
    MEDIA: 34.942370096842446,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0e30179-7e47-452c-8fca-8583237e316f',
    CIDADE: 'Montes Altos',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.08110809326172,
    MAXIMO: 34.089637756347656,
    MEDIA: 33.574185053507485,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19d1f6fd-27c3-4c49-ba28-8c2d7da21cb2',
    CIDADE: 'Morros',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.230125427246094,
    MAXIMO: 38.14668273925781,
    MEDIA: 37.747781117757164,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13fe51a4-64c0-489d-92ed-a9f1ebfae11f',
    CIDADE: 'Nina Rodrigues',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.865867614746094,
    MAXIMO: 38.7479248046875,
    MEDIA: 38.37491190508492,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df305cbb-3411-47f2-af56-d810f2808229',
    CIDADE: 'Nova Colinas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.18879699707031,
    MAXIMO: 35.435272216796875,
    MEDIA: 35.312034606933594,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b280501-3ce4-4846-9a80-baabbef714b5',
    CIDADE: 'Nova Iorque',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.59969711303711,
    MAXIMO: 39.78623962402344,
    MEDIA: 39.1765661239624,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36bc46d6-0cda-46b5-954c-366a2bce8c78',
    CIDADE: 'Nova Olinda do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.512514114379883,
    MAXIMO: 32.27537155151367,
    MEDIA: 31.75305438041687,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '217aa04d-6062-43fa-8ab1-9b30c5072d41',
    CIDADE: "Olho d'Água das Cunhãs",
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.49733352661133,
    MAXIMO: 37.27593231201172,
    MEDIA: 36.8907585144043,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '017fb98d-e8d6-4515-a1d1-9c03cdb18583',
    CIDADE: 'Olinda Nova do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.975128173828125,
    MAXIMO: 35.89191436767578,
    MEDIA: 35.18924475525823,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5617777-c457-4b33-9d11-d3b645a5a1b0',
    CIDADE: 'Paço do Lumiar',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.24169158935547,
    MAXIMO: 38.75630569458008,
    MEDIA: 38.579855227124064,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd88dfaa1-90b1-4205-a7da-506f70783555',
    CIDADE: 'Palmeirândia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.169368743896484,
    MAXIMO: 34.976905822753906,
    MEDIA: 34.573137283325195,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b6db694-2aa3-454b-a1fb-0b6106d27a60',
    CIDADE: 'Paraibano',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.78623962402344,
    MAXIMO: 40.416500091552734,
    MEDIA: 40.13645858976432,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'acea2844-53c8-4b78-a2b5-3229fdbb4bc2',
    CIDADE: 'Parnarama',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 41.62960433959961,
    MAXIMO: 42.5644416809082,
    MEDIA: 42.140939712524414,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85308fef-6673-4df6-b5d5-8bb3c7a93042',
    CIDADE: 'Passagem Franca',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.416500091552734,
    MAXIMO: 40.95943832397461,
    MEDIA: 40.67118740081787,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5e232a8-2b30-407f-bae2-81578e27f850',
    CIDADE: 'Pastos Bons',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.748329162597656,
    MAXIMO: 39.87767028808594,
    MEDIA: 39.38967514038086,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e5ff6e0b-62d4-4a6c-a817-ef5d6507a510',
    CIDADE: 'Paulino Neves',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.067195892333984,
    MAXIMO: 32.64178466796875,
    MEDIA: 31.78840446472168,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8505fdf-9fe0-4d62-be05-0c92fdb8d255',
    CIDADE: 'Paulo Ramos',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.12959671020508,
    MAXIMO: 37.13108825683594,
    MEDIA: 36.62864685058594,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c989872d-6de5-450b-bd3f-bfd161feb9c6',
    CIDADE: 'Pedreiras',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.712684631347656,
    MAXIMO: 39.31423568725586,
    MEDIA: 39.09463242874795,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bde33e8d-eccd-4247-99d8-97065d9ace2e',
    CIDADE: 'Pedro do Rosário',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.19654846191406,
    MAXIMO: 34.226890563964844,
    MEDIA: 33.632318115234376,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f000f88b-e2a1-4a2c-9400-f2349ecc6645',
    CIDADE: 'Penalva',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.6337776184082,
    MAXIMO: 34.873565673828125,
    MEDIA: 34.753671646118164,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e36708bc-259c-43d8-9310-695a8ada5a67',
    CIDADE: 'Peri Mirim',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.55818557739258,
    MAXIMO: 34.34498977661133,
    MEDIA: 33.95158767700195,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a633c0f1-d0bb-4d95-ba8f-62916c192005',
    CIDADE: 'Peritoró',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.22100067138672,
    MAXIMO: 39.7554817199707,
    MEDIA: 39.4822940826416,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20138eda-890d-41ae-9b82-41e5c314a0cf',
    CIDADE: 'Pindaré-Mirim',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.70695877075195,
    MAXIMO: 35.922760009765625,
    MEDIA: 35.03169804689396,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd26c4318-2ff4-4b25-a5e9-c3c845a48cdf',
    CIDADE: 'Pinheiro',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.883785247802734,
    MAXIMO: 33.44792556762695,
    MEDIA: 33.076470692952476,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd70c406b-a175-4a48-a05e-e87327fba72b',
    CIDADE: 'Pio XII',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.922760009765625,
    MAXIMO: 37.32341766357422,
    MEDIA: 36.60540394150342,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '069f03b9-a6e0-44fb-aa1f-4e2c476a9f87',
    CIDADE: 'Pirapemas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.884735107421875,
    MAXIMO: 39.05886459350586,
    MEDIA: 38.97179985046387,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fce7c939-5e60-49b2-a4a9-778618b85104',
    CIDADE: 'Poção de Pedras',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.92644119262695,
    MAXIMO: 38.83123016357422,
    MEDIA: 38.38203811645508,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0982bc81-ea2d-4dc8-9ba4-c95f25ce4c27',
    CIDADE: 'Porto Franco',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.39967727661133,
    MAXIMO: 34.20794677734375,
    MEDIA: 33.69477920532226,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '83894800-df90-4376-a194-f4bd452fd8fd',
    CIDADE: 'Porto Rico do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.29132080078125,
    MAXIMO: 33.070709228515625,
    MEDIA: 31.938275878050785,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '46692995-f415-4695-9dac-a69db647f648',
    CIDADE: 'Presidente Dutra',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.55284881591797,
    MAXIMO: 39.953338623046875,
    MEDIA: 39.79514694213867,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c003488a-0a83-4fa6-bf23-7cd4eb1ef6e6',
    CIDADE: 'Presidente Juscelino',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.26006317138672,
    MAXIMO: 38.47200393676758,
    MEDIA: 38.3740763219342,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b9fe916-6188-4f5b-a4cf-5e7be9ee915f',
    CIDADE: 'Presidente Médici',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.886383056640625,
    MAXIMO: 31.13834571838379,
    MEDIA: 31.012364387512207,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '065e2959-dfab-4517-8019-8048e85a34bd',
    CIDADE: 'Presidente Sarney',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.32321548461914,
    MAXIMO: 32.8289909362793,
    MEDIA: 32.57610321044922,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e350d094-cb5d-4f09-9d4f-515da884b6e4',
    CIDADE: 'Presidente Vargas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.865867614746094,
    MAXIMO: 38.63578414916992,
    MEDIA: 38.419775106620655,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b93d7a15-8893-46cc-b1f5-ea8b37df8d75',
    CIDADE: 'Primeira Cruz',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.405147552490234,
    MAXIMO: 35.589500427246094,
    MEDIA: 34.55726432800293,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe226703-bb3c-4a20-8809-e5e979c65796',
    CIDADE: 'Raposa',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.59018325805664,
    MAXIMO: 38.75630569458008,
    MEDIA: 38.559315320005425,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4b04ade-eb09-4d93-8bb8-3b19a94faeda',
    CIDADE: 'Riachão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.927120208740234,
    MAXIMO: 35.21217346191406,
    MEDIA: 34.6838716506958,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a4036fd-de1b-49f1-900b-49e939b5f27f',
    CIDADE: 'Ribamar Fiquene',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.7360725402832,
    MAXIMO: 33.55475616455078,
    MEDIA: 33.2063024559047,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d995519-547f-4f7b-bb51-06a20c2dbcaa',
    CIDADE: 'Rosário',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.26006317138672,
    MAXIMO: 38.276275634765625,
    MEDIA: 38.26816940307617,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c1a640d8-4757-4a47-ad09-002f1c2db7a2',
    CIDADE: 'Sambaíba',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.07960891723633,
    MAXIMO: 36.95416259765625,
    MEDIA: 36.41685422261556,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '26b6fc33-b2d2-48c2-bd54-acac652e4917',
    CIDADE: 'Santa Filomena do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.08884811401367,
    MAXIMO: 39.711509704589844,
    MEDIA: 39.29816182454427,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fbe37f23-d38f-4d94-8e93-46a5197c04e5',
    CIDADE: 'Santa Helena',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.448265075683594,
    MAXIMO: 32.758392333984375,
    MEDIA: 32.065773725509644,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef5d0f2f-1fcb-4c1d-bab7-68ab2b5a3907',
    CIDADE: 'Santa Inês',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.271881103515625,
    MAXIMO: 35.64039611816406,
    MEDIA: 35.43869145711263,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e60b138-ef3f-4c91-8636-e59849e8c9db',
    CIDADE: 'Santa Luzia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.93389129638672,
    MAXIMO: 35.14931869506836,
    MEDIA: 34.56159618922642,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bdcb4a4d-eaca-4cd0-8704-2e85fd8817f9',
    CIDADE: 'Santa Luzia do Paruá',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.182376861572266,
    MAXIMO: 31.487472534179688,
    MEDIA: 31.334924697875977,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9725f591-c543-4ef6-9c9e-3ba1fd270fa5',
    CIDADE: 'Santa Quitéria do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.121826171875,
    MAXIMO: 35.693607330322266,
    MEDIA: 34.74589729309082,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0021a157-57bd-4a37-9013-7e36fc3ef82a',
    CIDADE: 'Santa Rita',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.98477554321289,
    MAXIMO: 38.30332565307617,
    MEDIA: 38.14405059814453,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c53c3bd-7431-46ed-9e67-7ce30d2be5ea',
    CIDADE: 'Santana do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.971534729003906,
    MAXIMO: 33.33808517456055,
    MEDIA: 32.6381581624349,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61a25b16-c3e9-4637-97ff-06fa60a71c3a',
    CIDADE: 'Santo Amaro do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.30321502685547,
    MAXIMO: 34.57418441772461,
    MEDIA: 33.22445106506348,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a93d14e-11d0-4ab0-a50e-323c783b9d88',
    CIDADE: 'Santo Antônio dos Lopes',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.31423568725586,
    MAXIMO: 39.70020294189453,
    MEDIA: 39.507219314575195,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '566c1b12-6a5d-433f-9d71-52a35ae0d716',
    CIDADE: 'São Benedito do Rio Preto',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.67192840576172,
    MAXIMO: 38.10206985473633,
    MEDIA: 37.94968287150065,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89b5f491-c194-4b4b-a8cb-f61bb99ac775',
    CIDADE: 'São Bento',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.975128173828125,
    MAXIMO: 34.70333480834961,
    MEDIA: 34.33923149108887,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e03e72e-b037-44fc-a43b-22d8b2a2ffcf',
    CIDADE: 'São Bernardo',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.452417373657227,
    MAXIMO: 33.379966735839844,
    MEDIA: 32.495229721069336,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4de97720-a710-4c73-b5eb-c5f452784091',
    CIDADE: 'São Domingos do Azeitão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.08698272705078,
    MAXIMO: 38.518741607666016,
    MEDIA: 38.29646968841553,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea84c18c-a0f8-48e7-80fb-e57f69d4b541',
    CIDADE: 'São Domingos do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.074729919433594,
    MAXIMO: 40.269493103027344,
    MEDIA: 40.17211151123047,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '049e886d-1076-4fc8-966f-daba8165afb6',
    CIDADE: 'São Félix de Balsas',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.13551330566406,
    MAXIMO: 38.02388381958008,
    MEDIA: 37.63966064453125,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3017aaf-bc35-4a75-9237-bcbf24b44430',
    CIDADE: 'São Francisco do Brejão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.3431282043457,
    MAXIMO: 32.62668991088867,
    MEDIA: 32.48490905761719,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '800a442f-d39d-4bd6-b9a0-0f366f73bfe4',
    CIDADE: 'São Francisco do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.92154312133789,
    MAXIMO: 42.022682189941406,
    MEDIA: 41.49877439226423,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d28da50-b719-4808-bc68-2857276131de',
    CIDADE: 'São João Batista',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.89191436767578,
    MAXIMO: 36.62789535522461,
    MEDIA: 36.259904861450195,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7fbf2b9-58a0-41e0-88d8-4f9ff9068207',
    CIDADE: 'São João do Carú',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.75263023376465,
    MAXIMO: 32.440452575683594,
    MEDIA: 32.03445100784302,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60cb5007-7924-48d7-b141-7b952850e210',
    CIDADE: 'São João do Paraíso',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.809410095214844,
    MAXIMO: 34.495941162109375,
    MEDIA: 34.13385445731027,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e3c533c-b6a7-4b94-b4d2-43a390e5015c',
    CIDADE: 'São João do Soter',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 41.207305908203125,
    MAXIMO: 41.73080062866211,
    MEDIA: 41.50023651123047,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45f8c4dc-b0ab-4664-ab73-a06315e2f4e6',
    CIDADE: 'São João dos Patos',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.982078552246094,
    MAXIMO: 40.31747055053711,
    MEDIA: 40.14007314046224,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4b20dc7-2e99-4056-9e2f-0422a509d3a3',
    CIDADE: 'São José de Ribamar',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.24169158935547,
    MAXIMO: 38.75630569458008,
    MEDIA: 38.49625496031941,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6541eee6-00bf-4b5c-bd38-3de1fefdaa16',
    CIDADE: 'São José dos Basílios',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.38389205932617,
    MAXIMO: 39.46288299560547,
    MEDIA: 39.42338752746582,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b84235f6-0dca-434d-aa7d-3adb3b170f56',
    CIDADE: 'São Luís',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.24169158935547,
    MAXIMO: 38.30776596069336,
    MEDIA: 38.274728775024414,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '298c2203-6ac5-4749-98cb-d542f54fe64b',
    CIDADE: 'São Luís Gonzaga do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.389244079589844,
    MAXIMO: 38.842323303222656,
    MEDIA: 38.61578369140625,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dba5aef0-9e62-4c55-a6ea-c1b959d628d8',
    CIDADE: 'São Mateus do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.47098159790039,
    MAXIMO: 38.832275390625,
    MEDIA: 38.651628494262695,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a516dc1-d26c-4d96-be88-5a7f7b431e67',
    CIDADE: 'São Pedro da Água Branca',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.138362884521484,
    MAXIMO: 31.337848663330078,
    MEDIA: 31.208995183308918,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d11b5a7-9f2f-4830-811b-b9c7af54142e',
    CIDADE: 'São Pedro dos Crentes',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.37804412841797,
    MAXIMO: 35.18967819213867,
    MEDIA: 34.73374048868815,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54aba011-9873-4062-8d00-6dd44e12a670',
    CIDADE: 'São Raimundo das Mangabeiras',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.781742095947266,
    MAXIMO: 37.04576873779297,
    MEDIA: 36.31955432891846,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53e9f53e-7bd4-4d62-a779-ee93040ef3e4',
    CIDADE: 'São Raimundo do Doca Bezerra',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.175994873046875,
    MAXIMO: 38.16065216064453,
    MEDIA: 37.800781765401624,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02515c54-ccf6-4142-a67c-7f0a2f2dcdec',
    CIDADE: 'São Roberto',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 37.52215576171875,
    MAXIMO: 38.602725982666016,
    MEDIA: 38.05872986530158,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2eea032c-1d16-4134-b86b-d13bdce19606',
    CIDADE: 'São Vicente Ferrer',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 33.975128173828125,
    MAXIMO: 36.30199432373047,
    MEDIA: 35.15695863412028,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '385fde3d-4fd3-4529-a1dc-86a23b65f8d4',
    CIDADE: 'Satubinha',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.64039611816406,
    MAXIMO: 36.73752212524414,
    MEDIA: 36.23484142698539,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ceda64aa-8e88-4d98-9371-9c5582dd7548',
    CIDADE: 'Senador Alexandre Costa',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.91877365112305,
    MAXIMO: 41.271663665771484,
    MEDIA: 41.095218658447266,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a2cf895-8b6f-4c01-92cf-f8c3a9fd6d9d',
    CIDADE: 'Senador La Rocque',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 32.76677703857422,
    MAXIMO: 33.29072189331055,
    MEDIA: 33.02874946594238,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f292c40-bd4d-4e77-af33-a54fc124eeb4',
    CIDADE: 'Serrano do Maranhão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.73955726623535,
    MAXIMO: 31.639551162719727,
    MEDIA: 31.17352803548177,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4e8e44b-d9f1-4d50-bc73-3a20c3d68564',
    CIDADE: 'Sítio Novo',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.40946960449219,
    MAXIMO: 34.906463623046875,
    MEDIA: 34.699893951416016,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4121581-4c88-4109-8584-21617ef26c17',
    CIDADE: 'Sucupira do Norte',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.11891555786133,
    MAXIMO: 39.59743881225586,
    MEDIA: 39.358177185058594,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02ddded2-9eb5-4776-8187-32e816c754f8',
    CIDADE: 'Sucupira do Riachão',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 40.239200592041016,
    MAXIMO: 41.05583572387695,
    MEDIA: 40.66529655456543,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03189228-a438-49f2-ae51-2b0972007733',
    CIDADE: 'Tasso Fragoso',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.78047180175781,
    MAXIMO: 36.1268196105957,
    MEDIA: 35.303399085998535,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b421427d-b51f-4aa9-8a05-391904a74a96',
    CIDADE: 'Timbiras',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 39.54354476928711,
    MAXIMO: 40.147403717041016,
    MEDIA: 39.80583699544271,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a359496-3368-4ba6-860f-1cd378add513',
    CIDADE: 'Timon',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 42.925209045410156,
    MAXIMO: 43.41714859008789,
    MEDIA: 43.12218475341797,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '435cf97b-c2b1-4283-a7a9-0dba859c3449',
    CIDADE: 'Trizidela do Vale',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.5631103515625,
    MAXIMO: 38.712684631347656,
    MEDIA: 38.63789749145508,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3bfd29e1-d858-4936-9a39-2cc3dfb953ec',
    CIDADE: 'Tufilândia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.16938018798828,
    MAXIMO: 35.4037971496582,
    MEDIA: 34.74777855934481,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41cce7b9-2e59-4e63-a4ef-11ec35359730',
    CIDADE: 'Tuntum',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.6627311706543,
    MAXIMO: 39.69038391113281,
    MEDIA: 39.0567831993103,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd6ab7cd-730c-4354-892e-ad7e713149a8',
    CIDADE: 'Turiaçu',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 29.98290252685547,
    MAXIMO: 30.754724502563477,
    MEDIA: 30.344018697738647,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e36c0aaf-a331-484f-95b4-3dbcf59816ce',
    CIDADE: 'Turilândia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.89399528503418,
    MAXIMO: 31.386112213134766,
    MEDIA: 31.13024139404297,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d6873ff-5941-4670-8c40-488361cde979',
    CIDADE: 'Tutóia',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 30.684045791625977,
    MAXIMO: 31.43943977355957,
    MEDIA: 31.050381978352863,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24643b97-aa81-425e-9b0b-2ce168e507fc',
    CIDADE: 'Urbano Santos',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.99037170410156,
    MAXIMO: 37.10065841674805,
    MEDIA: 36.029350916544594,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e20b012-d865-4d22-bd11-a730e137bf8e',
    CIDADE: 'Vargem Grande',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 38.59564208984375,
    MAXIMO: 39.14508056640625,
    MEDIA: 38.84901364644369,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fef73de2-e69c-4674-9019-99bebe2a6142',
    CIDADE: 'Viana',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 34.44046401977539,
    MAXIMO: 36.904415130615234,
    MEDIA: 35.786325454711914,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0f1dd0b-e09f-4613-ab77-df84d5174b44',
    CIDADE: 'Vila Nova dos Martírios',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.346046447753906,
    MAXIMO: 31.668167114257812,
    MEDIA: 31.497044881184895,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54d00ff5-7e31-40fd-84e2-ecbf0bc613c6',
    CIDADE: 'Vitória do Mearim',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 36.228004455566406,
    MAXIMO: 37.095176696777344,
    MEDIA: 36.715929667154946,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3f7e9ec-cb9b-48b8-b9be-d0d8dd0a2d7c',
    CIDADE: 'Vitorino Freire',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 35.95211410522461,
    MAXIMO: 36.936458587646484,
    MEDIA: 36.43653361002604,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6176d2b3-5759-4f3d-8eb0-8ed1d4e37582',
    CIDADE: 'Zé Doca',
    SIGLA: 'MA',
    ESTADO: 'Maranhão',
    MINIMO: 31.863162994384766,
    MAXIMO: 34.03939437866211,
    MEDIA: 32.66002909342448,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4cee41c7-c35f-4a34-bcdf-cefc73b22cb6',
    CIDADE: 'Acauã',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.694374084472656,
    MAXIMO: 32.26942825317383,
    MEDIA: 31.924820327758788,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e5cab8a-08a7-4d1a-8b80-c71af4a3840a',
    CIDADE: 'Agricolândia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.918975830078125,
    MAXIMO: 43.471412658691406,
    MEDIA: 42.936320822956446,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c428833-c3e0-4740-a321-a2244fe082f7',
    CIDADE: 'Água Branca',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.40547561645508,
    MAXIMO: 42.9578742980957,
    MEDIA: 42.44942964073825,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbbf71b1-9c39-47a8-b5ad-058eec6109ec',
    CIDADE: 'Alagoinha do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.41695022583008,
    MAXIMO: 34.75623321533203,
    MEDIA: 34.586591720581055,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49c68157-c8f1-4b96-8653-43b616adb587',
    CIDADE: 'Alegrete do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.37710952758789,
    MAXIMO: 34.41695022583008,
    MEDIA: 33.80229505546459,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b95b79d-fe4e-4c7f-b109-8cf9aa97cc37',
    CIDADE: 'Alto Longá',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.17457962036133,
    MAXIMO: 44.450477600097656,
    MEDIA: 43.736612701416014,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1937d23-416e-4dbc-ad48-e1f1420107ae',
    CIDADE: 'Altos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.50690460205078,
    MAXIMO: 44.47658157348633,
    MEDIA: 43.93829536437988,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c079bd5-08bf-41ae-9996-db7621c50287',
    CIDADE: 'Alvorada do Gurguéia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.31285095214844,
    MAXIMO: 36.055049896240234,
    MEDIA: 35.649611473083496,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e777717f-f0c4-424b-b833-dadac8e1bb15',
    CIDADE: 'Amarante',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.73927688598633,
    MAXIMO: 41.29184341430664,
    MEDIA: 41.015560150146484,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cba6703b-d0e4-4af1-aa00-fe97428993ce',
    CIDADE: 'Angical do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.29184341430664,
    MAXIMO: 42.46479415893555,
    MEDIA: 41.99606800481972,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a104f36-9c43-465f-8c6c-9b0dab622482',
    CIDADE: 'Anísio de Abreu',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.57176208496094,
    MAXIMO: 34.401248931884766,
    MEDIA: 33.943080134044955,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2264daf7-3fb2-419c-b0b6-31ddee03d87d',
    CIDADE: 'Antônio Almeida',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.96710205078125,
    MAXIMO: 38.32501983642578,
    MEDIA: 38.146060943603516,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84c0e4a1-3c28-4747-8aa1-295f6fd0b4f6',
    CIDADE: 'Aroazes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.708038330078125,
    MAXIMO: 40.32250213623047,
    MEDIA: 40.0152702331543,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '026a6822-deb3-4899-98a5-37ce863e9305',
    CIDADE: 'Aroeiras do Itaim',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.306251525878906,
    MAXIMO: 35.735836029052734,
    MEDIA: 35.52104377746582,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '753788e8-9342-4e0f-a0d7-9f6656be51d8',
    CIDADE: 'Arraial',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.43180847167969,
    MAXIMO: 40.0076789855957,
    MEDIA: 39.743231455485024,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54d52695-c8f8-47ad-af8b-4805ce08df69',
    CIDADE: 'Assunção do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.307682037353516,
    MAXIMO: 37.16995620727539,
    MEDIA: 36.726346015930176,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3965c716-63fb-48c3-bf8e-0ae5d194cf41',
    CIDADE: 'Avelino Lopes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.00997543334961,
    MAXIMO: 33.31144332885742,
    MEDIA: 33.159600257873535,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c39989a-bd1f-4573-bfb5-f8ccbbbcccf0',
    CIDADE: 'Baixa Grande do Ribeiro',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.396324157714844,
    MAXIMO: 36.430999755859375,
    MEDIA: 35.395441202016976,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af1c8827-4215-471a-88c3-479eae81a828',
    CIDADE: "Barra D'Alcântara",
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.058773040771484,
    MAXIMO: 39.645381927490234,
    MEDIA: 39.35207748413086,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5faa4eec-5049-4ebe-bc40-bd64f2ae68b9',
    CIDADE: 'Barras',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.01231002807617,
    MAXIMO: 39.056514739990234,
    MEDIA: 37.680168696812224,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8fdc4bb-1a40-4c8b-a050-75d58c3f0851',
    CIDADE: 'Barreiras do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.409584045410156,
    MAXIMO: 33.766963958740234,
    MEDIA: 33.62577819824219,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6583abb3-b811-4fa5-80f4-7f44c8408306',
    CIDADE: 'Barro Duro',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.20013427734375,
    MAXIMO: 42.9578742980957,
    MEDIA: 42.85449153306287,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52fa892e-9052-4ce0-bc9d-eb12b7b504cd',
    CIDADE: 'Batalha',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.39564514160156,
    MAXIMO: 36.517608642578125,
    MEDIA: 35.08524017333984,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '201a7c22-e38a-4c27-adc8-be7e3bdaa8ac',
    CIDADE: 'Bela Vista do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.91374206542969,
    MAXIMO: 34.81028366088867,
    MEDIA: 34.36201286315918,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a6f05a9-39a2-4723-bfa2-5cc2112cba96',
    CIDADE: 'Belém do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.29761505126953,
    MAXIMO: 34.470279693603516,
    MEDIA: 33.736243686954374,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64ca9a6e-147a-4a37-b3f4-d0c44a731aae',
    CIDADE: 'Beneditinos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.175838470458984,
    MAXIMO: 44.0196647644043,
    MEDIA: 43.55209223429362,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '08c60282-8885-4436-9360-e681a9ba71db',
    CIDADE: 'Bertolínia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.95717239379883,
    MAXIMO: 37.537418365478516,
    MEDIA: 37.254005432128906,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43ac0fa8-b9e5-4c90-8290-54a3073e7bb7',
    CIDADE: 'Betânia do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.816165924072266,
    MAXIMO: 32.03349304199219,
    MEDIA: 31.924829483032227,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fe7c5f3-ca67-42fe-8b5f-dde73dde34c1',
    CIDADE: 'Boa Hora',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.000003814697266,
    MAXIMO: 39.06835174560547,
    MEDIA: 38.06385081676859,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58c3e600-2725-4bc3-87d8-b00b92a9e04f',
    CIDADE: 'Bocaina',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.228668212890625,
    MAXIMO: 36.20839309692383,
    MEDIA: 35.81187640458131,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48fa682b-3405-4be8-bcf5-7bdeb1c27b7c',
    CIDADE: 'Bom Jesus',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.03464889526367,
    MAXIMO: 34.951141357421875,
    MEDIA: 34.59549480014377,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82bfa1a7-82b4-4b4a-888b-6e9dc7caf2f2',
    CIDADE: 'Bom Princípio do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 29.956064224243164,
    MAXIMO: 30.568025588989258,
    MEDIA: 30.21712217947145,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '405734e3-1f74-4c64-b159-58b693c01e77',
    CIDADE: 'Bonfim do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.57176208496094,
    MAXIMO: 34.401248931884766,
    MEDIA: 33.8930770596543,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16ba624d-b232-4372-9e13-9a60032a2cd9',
    CIDADE: 'Boqueirão do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.06835174560547,
    MAXIMO: 40.35123825073242,
    MEDIA: 39.549008917747365,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eccc4afa-c504-4a84-aba1-d578a8e519f4',
    CIDADE: 'Brasileira',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.42607116699219,
    MAXIMO: 34.92609786987305,
    MEDIA: 34.1631825764974,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '176edd41-db52-4284-b2cc-d10c091cbc96',
    CIDADE: 'Brejo do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.62762451171875,
    MAXIMO: 35.68744659423828,
    MEDIA: 35.08617719014486,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6d9aae6-2cb2-4e5a-bb2b-936d0e17603a',
    CIDADE: 'Buriti dos Lopes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 30.123430252075195,
    MAXIMO: 31.281160354614258,
    MEDIA: 30.63782535384364,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '089c8dc3-bf26-4436-b605-631798ff154d',
    CIDADE: 'Buriti dos Montes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.967491149902344,
    MAXIMO: 37.83671188354492,
    MEDIA: 36.93744405110677,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee7f1c5d-b063-47a3-bbf9-d7c0f6b242b0',
    CIDADE: 'Cabeceiras do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.800479888916016,
    MAXIMO: 40.259620666503906,
    MEDIA: 39.55903180573564,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0d12f54-35d8-45fd-a5b6-f5cd42f97083',
    CIDADE: 'Cajazeiras do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.629276275634766,
    MAXIMO: 39.79020690917969,
    MEDIA: 39.19997896075211,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28341e53-f2e6-42bf-a2d5-a10424f1ba6d',
    CIDADE: 'Cajueiro da Praia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 29.710168838500977,
    MAXIMO: 29.898622512817383,
    MEDIA: 29.85417452894022,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfa3d68c-f2fe-4330-99f7-624ba62429d3',
    CIDADE: 'Caldeirão Grande do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.064327239990234,
    MAXIMO: 33.37710952758789,
    MEDIA: 33.22071838378906,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b42300f-e68b-49b0-bc5c-5c89ee6c9f37',
    CIDADE: 'Campinas do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.85942459106445,
    MAXIMO: 35.66724395751953,
    MEDIA: 35.26082515716553,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01a7af88-387d-4c05-8da8-ce9120967415',
    CIDADE: 'Campo Alegre do Fidalgo',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.88288879394531,
    MAXIMO: 34.427879333496094,
    MEDIA: 33.543525365022305,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f650e2c7-5e42-4549-97f8-4926606ac720',
    CIDADE: 'Campo Grande do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.01184844970703,
    MAXIMO: 34.848907470703125,
    MEDIA: 34.4934391158564,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f643ad89-9462-4653-9054-2fa354d6ce94',
    CIDADE: 'Campo Largo do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.23063278198242,
    MAXIMO: 37.30240249633789,
    MEDIA: 36.391630441718384,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f838530c-d778-4459-8fc7-0e9d3e5033d5',
    CIDADE: 'Campo Maior',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.610374450683594,
    MAXIMO: 43.601104736328125,
    MEDIA: 42.577765464782715,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b535d2c4-aa14-4c9d-bfc0-937ecb19b0c4',
    CIDADE: 'Canavieira',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.06626892089844,
    MAXIMO: 38.13774108886719,
    MEDIA: 37.49113137381418,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e041395f-229a-452a-9fa8-8e3b2aa3113f',
    CIDADE: 'Canto do Buriti',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.97056198120117,
    MAXIMO: 36.27352523803711,
    MEDIA: 35.62853486197336,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab78c26c-18f2-4802-a221-84dc8d7dbe1d',
    CIDADE: 'Capitão de Campos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.261138916015625,
    MAXIMO: 38.4931755065918,
    MEDIA: 38.37715721130371,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e387e1d-248e-42d7-a3c4-8b9dd88d1b54',
    CIDADE: 'Capitão Gervásio Oliveira',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.94858169555664,
    MAXIMO: 33.54696273803711,
    MEDIA: 33.32249069213867,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b73f9cc-fde4-406a-9212-51667e3db7c0',
    CIDADE: 'Caracol',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.88313293457031,
    MAXIMO: 34.59362030029297,
    MEDIA: 34.204551696777344,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1f17c4b-7b3f-47cc-a0f3-afb949f6a3e8',
    CIDADE: 'Caraúbas do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 30.982688903808594,
    MAXIMO: 32.44031524658203,
    MEDIA: 31.67312825123837,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52bea172-0a5f-482a-a922-a01c093278b0',
    CIDADE: 'Caridade do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.694801330566406,
    MAXIMO: 33.6275749206543,
    MEDIA: 33.03087775608574,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e5766bf9-14c2-4679-ae38-3f2581dd4953',
    CIDADE: 'Castelo do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.5712776184082,
    MAXIMO: 41.23616027832031,
    MEDIA: 39.829726219177246,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6ae09928-937c-42da-a936-05e0baa6d623',
    CIDADE: 'Caxingó',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 30.800251007080078,
    MAXIMO: 31.681888580322266,
    MEDIA: 31.198240915934246,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4195083d-8a23-4f15-9c10-fe5011e54903',
    CIDADE: 'Cocal',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 30.39453887939453,
    MAXIMO: 30.982688903808594,
    MEDIA: 30.67263174057007,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b3e2f1c-4ae0-407e-945d-e5130f5c692c',
    CIDADE: 'Cocal de Telha',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.261138916015625,
    MAXIMO: 40.35123825073242,
    MEDIA: 39.36443665722163,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6f4ef022-c047-4022-b3f3-2f22081f6d92',
    CIDADE: 'Cocal dos Alves',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 30.5448055267334,
    MAXIMO: 31.769214630126953,
    MEDIA: 31.050179725087926,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df0e0fe2-3665-4a66-920a-cc4119924496',
    CIDADE: 'Coivaras',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.781436920166016,
    MAXIMO: 44.88450622558594,
    MEDIA: 44.43859957799315,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd7694be-65e8-422b-b748-a8d4439f57ad',
    CIDADE: 'Colônia do Gurguéia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.82909393310547,
    MAXIMO: 36.210296630859375,
    MEDIA: 35.97788747151693,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c3d7f5c-a080-45dc-9a98-ae8c0d21ce12',
    CIDADE: 'Colônia do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.73442459106445,
    MAXIMO: 37.24846649169922,
    MEDIA: 36.941637674967446,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ab8421e-f121-4840-88e5-34e95bd17456',
    CIDADE: 'Conceição do Canindé',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.20735168457031,
    MAXIMO: 34.45869064331055,
    MEDIA: 33.84709343409884,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a9f060a-7912-4345-aff1-cf768d8ef047',
    CIDADE: 'Coronel José Dias',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.69638442993164,
    MAXIMO: 34.475582122802734,
    MEDIA: 33.55600684029715,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '641d8ad2-28bb-471d-8d43-7cdcac4b4c0e',
    CIDADE: 'Corrente',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.063873291015625,
    MAXIMO: 33.39816665649414,
    MEDIA: 33.21308390299479,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aae87664-882a-4ca4-985a-ddc52ccc59ca',
    CIDADE: 'Cristalândia do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.7256965637207,
    MAXIMO: 32.92098617553711,
    MEDIA: 32.83977584838867,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35b92ad3-f1be-4b00-bdd9-a9dc1f90bf6a',
    CIDADE: 'Cristino Castro',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.78385925292969,
    MAXIMO: 35.1400146484375,
    MEDIA: 34.98422431945801,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93dfbdc3-e135-4c70-8470-50910b183dbe',
    CIDADE: 'Curimatá',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.25973892211914,
    MAXIMO: 33.691429138183594,
    MEDIA: 33.51944078717913,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0029a1b-f4dc-4531-a6a1-26cb63904c6b',
    CIDADE: 'Currais',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.967987060546875,
    MAXIMO: 35.800174713134766,
    MEDIA: 35.438961029052734,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90b22656-c3ec-482e-88e7-c2cabd40f639',
    CIDADE: 'Curralinhos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.11692428588867,
    MAXIMO: 43.657440185546875,
    MEDIA: 43.24637920773823,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '233c3cce-68f9-4729-8b33-696fa98b56e6',
    CIDADE: 'Curral Novo do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.816165924072266,
    MAXIMO: 32.941497802734375,
    MEDIA: 32.38516555550593,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de863d7d-45c7-4598-b5d3-37f390d599d9',
    CIDADE: 'Demerval Lobão',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.657440185546875,
    MAXIMO: 44.148475646972656,
    MEDIA: 43.946957030344386,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c0472f4-5a0f-4668-b350-19ba19f06b8a',
    CIDADE: 'Dirceu Arcoverde',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.992191314697266,
    MAXIMO: 33.20881271362305,
    MEDIA: 33.095147132873535,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e35b36c-eda3-434a-8ef7-598f35d67d0a',
    CIDADE: 'Dom Expedito Lopes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.20287322998047,
    MAXIMO: 37.20594787597656,
    MEDIA: 36.91326421144843,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bf7bf284-dfa2-45b4-b61d-46e47393db82',
    CIDADE: 'Domingos Mourão',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.06645202636719,
    MAXIMO: 33.69423294067383,
    MEDIA: 33.38034248352051,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54f56b8e-b141-436c-8b88-d46c415cf181',
    CIDADE: 'Dom Inocêncio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.09798049926758,
    MAXIMO: 33.27309036254883,
    MEDIA: 32.66523430564187,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'add4319a-18fa-4249-9987-7473871be7e6',
    CIDADE: 'Elesbão Veloso',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.24072265625,
    MAXIMO: 41.298580169677734,
    MEDIA: 40.76639175415039,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db7495c1-7758-461e-8454-1aa044cf5804',
    CIDADE: 'Eliseu Martins',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.36454391479492,
    MAXIMO: 36.72055435180664,
    MEDIA: 36.54138310750326,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75c0a118-ba4d-4a50-84d8-488b8b25e8ae',
    CIDADE: 'Esperantina',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.097259521484375,
    MAXIMO: 34.8347053527832,
    MEDIA: 34.46598243713379,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cee2aa96-a87f-409f-8379-f2985fdfc867',
    CIDADE: 'Fartura do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.24021911621094,
    MAXIMO: 33.41170883178711,
    MEDIA: 33.32596397399902,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15fb0156-48e3-446d-ba87-0626d5e00754',
    CIDADE: 'Flores do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.71174621582031,
    MAXIMO: 37.47478103637695,
    MEDIA: 37.055283864339195,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab42c268-9d06-4397-a9cc-c80c6a73de94',
    CIDADE: 'Floresta do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.29111862182617,
    MAXIMO: 36.12698745727539,
    MEDIA: 35.597848988265675,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f16e6b8a-a532-4966-9c3f-1fd7d933577a',
    CIDADE: 'Floriano',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.131465911865234,
    MAXIMO: 39.868370056152344,
    MEDIA: 39.043447494506836,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2cdbd1d0-5ec3-404c-9091-08805dfba205',
    CIDADE: 'Francinópolis',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.645381927490234,
    MAXIMO: 40.685791015625,
    MEDIA: 40.03888081142339,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90349fbc-4741-4946-8288-d5305eca7d1e',
    CIDADE: 'Francisco Ayres',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.6089973449707,
    MAXIMO: 40.16209411621094,
    MEDIA: 39.88554573059082,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1aff02e3-efbf-4cc6-b5e2-7e3982db1bf7',
    CIDADE: 'Francisco Macedo',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.372589111328125,
    MAXIMO: 33.714271545410156,
    MEDIA: 33.59662978003633,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb592e81-03c3-4e5b-a412-eb90b7f645ac',
    CIDADE: 'Francisco Santos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.848907470703125,
    MAXIMO: 35.59870147705078,
    MEDIA: 35.225425720214844,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e60565a7-4b98-4abf-ae08-8aabd1fc8db4',
    CIDADE: 'Fronteiras',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.34389877319336,
    MAXIMO: 33.682743072509766,
    MEDIA: 33.51332092285156,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c83063f-c474-4b07-a453-d3d4041a025b',
    CIDADE: 'Geminiano',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.848907470703125,
    MAXIMO: 35.7611083984375,
    MEDIA: 35.21091573642073,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53db55c3-e82b-4ccc-bf18-92d1e932927d',
    CIDADE: 'Gilbués',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.709957122802734,
    MAXIMO: 34.6257209777832,
    MEDIA: 34.20519332885742,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4e7bef6-8134-4760-a7d6-c9135f967ee2',
    CIDADE: 'Guadalupe',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.11180114746094,
    MAXIMO: 39.73639678955078,
    MEDIA: 39.41289710998535,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c79edf0a-8335-4a2d-b7b9-81635ada4388',
    CIDADE: 'Guaribas',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.044227600097656,
    MAXIMO: 34.795528411865234,
    MEDIA: 34.417263984680176,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8a51157-dd77-46b1-92e4-b324acb1b680',
    CIDADE: 'Hugo Napoleão',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.58469772338867,
    MAXIMO: 42.40547561645508,
    MEDIA: 41.990281106981364,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71ca16c8-9c33-45aa-878d-eaebf64ee7a6',
    CIDADE: 'Ilha Grande',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 29.83810806274414,
    MAXIMO: 30.163545608520508,
    MEDIA: 29.956711286689753,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab916d40-12f3-48e0-af8c-e56388a70bd2',
    CIDADE: 'Inhuma',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.17426681518555,
    MAXIMO: 38.15949249267578,
    MEDIA: 37.678157806396484,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc8623f9-658e-4659-a6d4-72bf2d029254',
    CIDADE: 'Ipiranga do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.69195556640625,
    MAXIMO: 38.637908935546875,
    MEDIA: 37.54835663514609,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '322b3fd4-3f31-4df4-b9eb-d789fe845a3c',
    CIDADE: 'Isaías Coelho',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.90742492675781,
    MAXIMO: 35.740116119384766,
    MEDIA: 34.77289554383625,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed24adf1-ed17-4c68-993c-a5d655969ac5',
    CIDADE: 'Itainópolis',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.87451171875,
    MAXIMO: 35.282222747802734,
    MEDIA: 35.07836723327637,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0533cca-391e-4068-92b3-0a217a0fb82a',
    CIDADE: 'Itaueira',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.15999984741211,
    MAXIMO: 37.82808303833008,
    MEDIA: 37.506103515625,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '861587d7-e843-4b33-9564-77a3dab3e95e',
    CIDADE: 'Jacobina do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.909523010253906,
    MAXIMO: 33.599449157714844,
    MEDIA: 33.31341018676758,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de96eb54-9525-48f8-90d0-45f1496ed97c',
    CIDADE: 'Jaicós',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.01931381225586,
    MAXIMO: 34.4716796875,
    MEDIA: 34.24549674987793,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8fcc990b-07e7-4b35-8a95-e8fb7d651ae5',
    CIDADE: 'Jardim do Mulato',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.685791015625,
    MAXIMO: 42.40547561645508,
    MEDIA: 41.58694431988679,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6a1721c-7d14-4228-a9c5-c48b823e1e90',
    CIDADE: 'Jatobá do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.62948989868164,
    MAXIMO: 41.61361312866211,
    MEDIA: 41.121551513671875,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65d86096-3d1b-44c3-a55f-416342290826',
    CIDADE: 'Jerumenha',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.30929183959961,
    MAXIMO: 38.974266052246094,
    MEDIA: 38.7305419921875,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3cbc70c5-8e2b-4f60-8273-f935585552fa',
    CIDADE: 'João Costa',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.890541076660156,
    MAXIMO: 35.01777648925781,
    MEDIA: 34.567980194091795,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c80f8ad7-6649-4cd9-a79b-8b6ed95d5f77',
    CIDADE: 'Joaquim Pires',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.19300842285156,
    MAXIMO: 33.059688568115234,
    MEDIA: 32.564337412516274,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'caac7abb-5be9-4e54-a93b-fc812daa12b2',
    CIDADE: 'Joca Marques',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.51207733154297,
    MAXIMO: 34.49340057373047,
    MEDIA: 33.74301866335009,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '454f2ad7-4b26-4543-9859-a2afe2f3200e',
    CIDADE: 'José de Freitas',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.259620666503906,
    MAXIMO: 42.66462707519531,
    MEDIA: 41.87413660685221,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f56b994-b8e7-48b6-9f65-769a4158119c',
    CIDADE: 'Juazeiro do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.04269027709961,
    MAXIMO: 39.17621612548828,
    MEDIA: 38.54258473714193,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bcc019ef-6a6a-459e-b647-c0951dc52400',
    CIDADE: 'Júlio Borges',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.90372085571289,
    MAXIMO: 33.11275863647461,
    MEDIA: 33.00028133392334,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16e058d4-cac1-4639-900b-ab24f6e17af8',
    CIDADE: 'Jurema',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.72721481323242,
    MAXIMO: 34.57527160644531,
    MEDIA: 34.20913441975912,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '056b5d29-8571-410c-a60a-e00a048d7838',
    CIDADE: 'Lagoinha do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.40547561645508,
    MAXIMO: 42.9578742980957,
    MEDIA: 42.925036637989464,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d6aa748-240c-4b52-a5e8-c3a329e7cd88',
    CIDADE: 'Lagoa Alegre',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.056514739990234,
    MAXIMO: 41.66389465332031,
    MEDIA: 40.56801011811325,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '743d7d23-a888-43ec-9c88-c9d42251952b',
    CIDADE: 'Lagoa do Barro do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.021820068359375,
    MAXIMO: 32.88288879394531,
    MEDIA: 32.470537185668945,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0ec3f60-d74a-49f1-b58e-003142977da0',
    CIDADE: 'Lagoa de São Francisco',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.43067169189453,
    MAXIMO: 36.38475036621094,
    MEDIA: 35.20977138449509,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a836892-4616-4d8f-8442-d5209c81225e',
    CIDADE: 'Lagoa do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.46077346801758,
    MAXIMO: 44.148475646972656,
    MEDIA: 43.86192230325492,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa4280ec-80e7-411d-b8ba-7aac7a0d33cb',
    CIDADE: 'Lagoa do Sítio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.52287292480469,
    MAXIMO: 38.13134765625,
    MEDIA: 37.39260482788086,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a7ec177-1d95-406a-9243-5718153e976d',
    CIDADE: 'Landri Sales',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.78084182739258,
    MAXIMO: 38.499027252197266,
    MEDIA: 38.13381385803223,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f3f4185-3eb0-4f35-bc99-4d7951bac501',
    CIDADE: 'Luís Correia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 29.898622512817383,
    MAXIMO: 30.109228134155273,
    MEDIA: 29.987971623738606,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d0a5dcf-0f1e-4201-942c-12b496fe9c80',
    CIDADE: 'Luzilândia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.799495697021484,
    MAXIMO: 34.49340057373047,
    MEDIA: 33.64644813537598,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '718744af-6f98-420c-b0d0-16f7a702c6c5',
    CIDADE: 'Madeiro',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.51207733154297,
    MAXIMO: 35.23063278198242,
    MEDIA: 34.35018115462982,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17b735ad-6111-48ff-83b8-22c1b6cff4f1',
    CIDADE: 'Manoel Emídio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.93659973144531,
    MAXIMO: 36.67988967895508,
    MEDIA: 36.31540775299072,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6955be6f-316f-4db9-b9c5-129cbf99fa39',
    CIDADE: 'Marcolândia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.064327239990234,
    MAXIMO: 33.372589111328125,
    MEDIA: 33.28639640671288,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6ee60c4-2590-4077-8e0f-07d72c120957',
    CIDADE: 'Marcos Parente',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.085350036621094,
    MAXIMO: 39.228885650634766,
    MEDIA: 38.763874907600076,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2405f71-2e6a-48b9-980a-f0cb712bb723',
    CIDADE: 'Massapê do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.29761505126953,
    MAXIMO: 34.4716796875,
    MEDIA: 33.68656609076143,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f28825e9-5a56-4ef0-ac51-39e97a623fe9',
    CIDADE: 'Matias Olímpio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.49340057373047,
    MAXIMO: 36.25938415527344,
    MEDIA: 35.37945699864691,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4518b666-4e27-4478-ac3f-3c8773c89cd7',
    CIDADE: 'Miguel Alves',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.85452651977539,
    MAXIMO: 40.08325958251953,
    MEDIA: 39.500040435791014,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2290041-d57d-43cd-8601-c319aefa5b33',
    CIDADE: 'Miguel Leão',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.918975830078125,
    MAXIMO: 43.471412658691406,
    MEDIA: 43.208253536466835,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd760b9ad-4daa-4613-818c-aea57a52314b',
    CIDADE: 'Milton Brandão',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.44308090209961,
    MAXIMO: 37.45736312866211,
    MEDIA: 37.07312520345052,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ddd916d-0403-4ff9-8a71-839ff795b957',
    CIDADE: 'Monsenhor Gil',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.344539642333984,
    MAXIMO: 43.471412658691406,
    MEDIA: 43.407976150512695,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c49c2156-fe39-4740-8ae3-4efffd2e5e9e',
    CIDADE: 'Monsenhor Hipólito',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.41695022583008,
    MAXIMO: 35.59870147705078,
    MEDIA: 35.000538421177424,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e999729-d29d-4bdf-afe4-a8738aa60811',
    CIDADE: 'Monte Alegre do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.87467575073242,
    MAXIMO: 34.7007942199707,
    MEDIA: 34.320308208465576,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dccf8c8e-35fb-43e4-a319-1c976ad9e5f2',
    CIDADE: 'Morro Cabeça no Tempo',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.18255615234375,
    MAXIMO: 33.9249382019043,
    MEDIA: 33.53296947479248,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f98c21a2-3f44-4c15-b634-1f79e9571eac',
    CIDADE: 'Morro do Chapéu do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.19300842285156,
    MAXIMO: 35.56197738647461,
    MEDIA: 34.08022396196795,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8fec972-d0b9-4b8a-a0be-d112e983af16',
    CIDADE: 'Murici dos Portelas',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 30.69776153564453,
    MAXIMO: 32.19300842285156,
    MEDIA: 31.405346660836635,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '164e1e5b-daf3-4099-981b-4aa02a5d68d0',
    CIDADE: 'Nazaré do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.52176284790039,
    MAXIMO: 39.060691833496094,
    MEDIA: 38.7714786529541,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5633a51-0766-4226-9431-6ba5c9f43f3c',
    CIDADE: 'Nazária',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.8918571472168,
    MAXIMO: 43.78780746459961,
    MEDIA: 43.47385902246352,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee8e2144-a7cd-418d-8cd9-784f682ea896',
    CIDADE: 'Nossa Senhora de Nazaré',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.35123825073242,
    MAXIMO: 41.03746795654297,
    MEDIA: 40.694353103637695,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '098059d3-b2e2-468e-a25a-c60c9ca0aa10',
    CIDADE: 'Nossa Senhora dos Remédios',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.25938415527344,
    MAXIMO: 38.85452651977539,
    MEDIA: 37.563354189255385,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fded5cff-7e57-431c-b51c-d7198bbb6031',
    CIDADE: 'Novo Oriente do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.637908935546875,
    MAXIMO: 39.200103759765625,
    MEDIA: 38.91900634765625,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19b05b84-5e9d-4388-af60-cfb8aab9076d',
    CIDADE: 'Novo Santo Antônio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.973419189453125,
    MAXIMO: 43.620933532714844,
    MEDIA: 42.24903330714938,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '219aaabc-efda-4734-b314-31d4d80ee5aa',
    CIDADE: 'Oeiras',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.12110137939453,
    MAXIMO: 38.629276275634766,
    MEDIA: 37.965144634246826,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4176cc78-2aea-4a79-9037-e564c714cfe6',
    CIDADE: "Olho D'Água do Piauí",
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.20013427734375,
    MAXIMO: 42.9578742980957,
    MEDIA: 42.636981989765935,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b6278c6-4ce6-4330-9ad7-d0ded04a8490',
    CIDADE: 'Padre Marcos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.372589111328125,
    MAXIMO: 34.01184844970703,
    MEDIA: 33.631851818812706,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4a80bb0-a882-4071-873a-dddd952cffff',
    CIDADE: 'Paes Landim',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.55498123168945,
    MAXIMO: 35.98231887817383,
    MEDIA: 35.76865005493164,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '535a229e-1775-446d-bd82-4b5fdbdfcfa3',
    CIDADE: 'Pajeú do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.09299087524414,
    MAXIMO: 36.52306365966797,
    MEDIA: 36.29452260335287,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff50fff2-93e5-4928-8244-51173641f1e5',
    CIDADE: 'Palmeira do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.17404556274414,
    MAXIMO: 36.03387451171875,
    MEDIA: 35.69043459211077,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e805bb12-b697-4050-a19e-0b6932c04306',
    CIDADE: 'Palmeirais',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.92511749267578,
    MAXIMO: 43.11692428588867,
    MEDIA: 42.57198878696987,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c482f31b-efb6-42cd-8a17-26f8a600ef5e',
    CIDADE: 'Paquetá',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.20287322998047,
    MAXIMO: 36.7033805847168,
    MEDIA: 36.45312690734863,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '772c8aa1-b292-4402-a3c6-159113233063',
    CIDADE: 'Parnaguá',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.87887954711914,
    MAXIMO: 33.487884521484375,
    MEDIA: 33.17097438465465,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81f88003-709b-4e43-8829-8145564ffd8c',
    CIDADE: 'Parnaíba',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 29.8299617767334,
    MAXIMO: 30.415536880493164,
    MEDIA: 30.04939770476812,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a370658-a880-4575-8f66-366ea854a2a1',
    CIDADE: 'Passagem Franca do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.20013427734375,
    MAXIMO: 42.81428527832031,
    MEDIA: 42.50720977783203,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d8d7001-1211-4c75-b824-159752bcb608',
    CIDADE: 'Patos do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.95014953613281,
    MAXIMO: 33.98637008666992,
    MEDIA: 33.96825981140137,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b31aa0c0-8078-45d1-af48-d69b68018ec2',
    CIDADE: "Pau D'Arco do Piauí",
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 44.148475646972656,
    MAXIMO: 44.46743392944336,
    MEDIA: 44.30795478820801,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2ab12fb-3d37-4c92-94de-ac603283d2b9',
    CIDADE: 'Paulistana',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.29146957397461,
    MAXIMO: 32.88518524169922,
    MEDIA: 32.58564682006836,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e2795b5-23cc-40fd-8ecb-bd30f7c79ede',
    CIDADE: 'Pavussu',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.572731018066406,
    MAXIMO: 36.642669677734375,
    MEDIA: 36.60770034790039,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55261a87-a0aa-41f7-b890-b689602db91f',
    CIDADE: 'Pedro II',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.43067169189453,
    MAXIMO: 36.38475036621094,
    MEDIA: 35.4470157623291,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0a1c794-2622-4aa6-a0d0-8e083d450b99',
    CIDADE: 'Pedro Laurentino',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.156761169433594,
    MAXIMO: 35.46180725097656,
    MEDIA: 35.30928421020508,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'baa415b9-475b-4309-861d-f947ca431a84',
    CIDADE: 'Nova Santa Rita',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.87730407714844,
    MAXIMO: 34.760643005371094,
    MEDIA: 34.35527547200521,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '131b0862-0cd1-46ba-b073-db8682570b72',
    CIDADE: 'Picos',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.7611083984375,
    MAXIMO: 36.69195556640625,
    MEDIA: 36.226531982421875,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ce9a2077-1dc5-44b8-a076-5796c22491cc',
    CIDADE: 'Pimenteiras',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.13330078125,
    MAXIMO: 38.981903076171875,
    MEDIA: 36.55598302987906,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'caec520c-16bf-401a-b5dd-a332dd76cef8',
    CIDADE: 'Pio IX',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.51605987548828,
    MAXIMO: 35.08725357055664,
    MEDIA: 34.316221714019775,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0c1bf272-846e-4519-adb9-9f31382e2e07',
    CIDADE: 'Piracuruca',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.514129638671875,
    MAXIMO: 33.77692413330078,
    MEDIA: 32.44724917411804,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '816e87f5-648d-4afb-b94b-38beeb4f4db4',
    CIDADE: 'Piripiri',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.1335563659668,
    MAXIMO: 37.34217834472656,
    MEDIA: 36.82524617513021,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5aa17af4-5017-4bc1-a7bc-0a1527bbb9f8',
    CIDADE: 'Porto',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.25938415527344,
    MAXIMO: 38.85452651977539,
    MEDIA: 37.49917421187581,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc5d386c-1af9-4f18-a631-cf6eeb009059',
    CIDADE: 'Porto Alegre do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.38918685913086,
    MAXIMO: 38.985321044921875,
    MEDIA: 38.68725395202637,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16fcab4f-74cd-45e7-a151-d9207a8f7b21',
    CIDADE: 'Prata do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.03952407836914,
    MAXIMO: 43.175838470458984,
    MEDIA: 42.4349483879845,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b3ccd2c-7562-4459-ae99-ff27a77f2451',
    CIDADE: 'Queimada Nova',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.971773147583008,
    MAXIMO: 32.576515197753906,
    MEDIA: 32.28288793563843,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5d8b796-bc37-469b-9dc8-46bb2e2bbd24',
    CIDADE: 'Redenção do Gurguéia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.73292541503906,
    MAXIMO: 34.13462448120117,
    MEDIA: 33.96048879623413,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10bd5b9c-bc89-43f5-b21e-706823d1e9b8',
    CIDADE: 'Regeneração',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.38705062866211,
    MAXIMO: 41.17973709106445,
    MEDIA: 40.78597927093506,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0464936f-06f7-4334-b51b-f7788e7153c3',
    CIDADE: 'Riacho Frio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.369564056396484,
    MAXIMO: 33.85574722290039,
    MEDIA: 33.59849875313895,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed75233d-748b-40c6-94b7-23ea149bc2b2',
    CIDADE: 'Ribeira do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.49079132080078,
    MAXIMO: 35.901912689208984,
    MEDIA: 35.69635200500488,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f76d1b0e-4d31-4c5d-a084-35eda0d099ca',
    CIDADE: 'Ribeiro Gonçalves',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.08745574951172,
    MAXIMO: 36.780460357666016,
    MEDIA: 35.988341938365586,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '368933f2-690c-4657-9629-e49864b5c97c',
    CIDADE: 'Rio Grande do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.42228698730469,
    MAXIMO: 36.98887634277344,
    MEDIA: 36.75976308186849,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1f5051f-526d-4c04-900f-75795b9209c3',
    CIDADE: 'Santa Cruz do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.740116119384766,
    MAXIMO: 36.213623046875,
    MEDIA: 35.97686958312988,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8006c93d-3dbd-4dae-b41d-23f26d3d23ea',
    CIDADE: 'Santa Cruz dos Milagres',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.183433532714844,
    MAXIMO: 40.86909103393555,
    MEDIA: 40.526262283325195,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4d6f3b8-6781-4298-ab5b-8a444b31365e',
    CIDADE: 'Santa Filomena',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.97426986694336,
    MAXIMO: 34.965370178222656,
    MEDIA: 34.4599494934082,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19eaeed1-4a21-4ce6-a23c-c5275f83ede6',
    CIDADE: 'Santa Luz',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.55223083496094,
    MAXIMO: 34.712745666503906,
    MEDIA: 34.64330164591471,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9bb5c3b8-b82a-460e-b26f-1eadd6f622f2',
    CIDADE: 'Santana do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.20839309692383,
    MAXIMO: 37.17426681518555,
    MEDIA: 36.29767689703854,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '623df7ce-1e5e-4b29-8731-05f7fa7bbe10',
    CIDADE: 'Santa Rosa do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.91788101196289,
    MAXIMO: 39.200828552246094,
    MEDIA: 38.68663296149986,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '91897696-17e4-45c8-ba4b-8f5de7807a7b',
    CIDADE: 'Santo Antônio de Lisboa',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.848907470703125,
    MAXIMO: 36.12565994262695,
    MEDIA: 35.50039511988546,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '637a0fb2-2b1f-4388-8c71-fcc8e6fe834f',
    CIDADE: 'Santo Antônio dos Milagres',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.76995849609375,
    MAXIMO: 42.46479415893555,
    MEDIA: 42.04163194591734,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9a7fc8a-741d-4526-a061-aeb5fcdd1a26',
    CIDADE: 'Santo Inácio do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.02256774902344,
    MAXIMO: 36.4893913269043,
    MEDIA: 36.212982177734375,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f482aa2-6ee7-499f-a806-7232242b8ccc',
    CIDADE: 'São Braz do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.18419647216797,
    MAXIMO: 34.459739685058594,
    MEDIA: 34.348395029703774,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '167bfbf4-5764-422e-ac5a-80e0c7302278',
    CIDADE: 'São Félix do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.44005584716797,
    MAXIMO: 42.03952407836914,
    MEDIA: 41.739789962768555,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '046679e5-1179-483e-b28a-812f3631144a',
    CIDADE: 'São Francisco de Assis do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.8763542175293,
    MAXIMO: 33.55784606933594,
    MEDIA: 33.274506378173825,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7748f5a-ddba-49b9-a6e9-dd9111ffaebc',
    CIDADE: 'São Francisco do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 37.53912353515625,
    MAXIMO: 38.316951751708984,
    MEDIA: 37.929386138916016,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8d1d198-01a9-4d11-b2b6-e7c6655e7f1e',
    CIDADE: 'São Gonçalo do Gurguéia',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.35792922973633,
    MAXIMO: 34.08449172973633,
    MEDIA: 33.79440002441406,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '005a0ae7-5182-4e7d-8e8b-827f2e62fb4b',
    CIDADE: 'São Gonçalo do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.76995849609375,
    MAXIMO: 42.46479415893555,
    MEDIA: 42.31328360256371,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b68c997b-d001-4df0-8e58-86d0f5cf1270',
    CIDADE: 'São João da Canabrava',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.716552734375,
    MAXIMO: 37.0964241027832,
    MEDIA: 36.674090449827894,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb81c8b1-9697-4177-ae2e-128910811b0f',
    CIDADE: 'São João da Fronteira',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.40798568725586,
    MAXIMO: 32.81428527832031,
    MEDIA: 32.086106618245445,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eab4c61c-9b39-402e-b652-f650b5eb167d',
    CIDADE: 'São João da Serra',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.98756790161133,
    MAXIMO: 42.2667121887207,
    MEDIA: 41.584110260009766,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3708301a-e95d-4438-af39-2753a5e792ce',
    CIDADE: 'São João da Varjota',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.7033805847168,
    MAXIMO: 38.0791130065918,
    MEDIA: 37.350842528031514,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'def72f7e-3c96-4d3f-904a-835d3f73b276',
    CIDADE: 'São João do Arraial',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.49340057373047,
    MAXIMO: 36.25938415527344,
    MEDIA: 35.62438498719578,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c5a2b80-22c3-4a61-9483-dacf87ab24ad',
    CIDADE: 'São João do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.84808349609375,
    MAXIMO: 35.09202575683594,
    MEDIA: 34.51660995483398,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45fde294-2be6-435b-912e-d5d19293aa34',
    CIDADE: 'São José do Divino',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 31.924509048461914,
    MAXIMO: 33.39564514160156,
    MEDIA: 32.863587426868605,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c0c0c49-5f66-43db-826d-f2bb0154d046',
    CIDADE: 'São José do Peixe',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.8040885925293,
    MAXIMO: 37.97520446777344,
    MEDIA: 37.271484375,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c77f174-38a6-4c33-8ef5-8b014bb04e2c',
    CIDADE: 'São José do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.20839309692383,
    MAXIMO: 37.17426681518555,
    MEDIA: 36.65013335557621,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b916b88-da76-4da5-ae87-5a71d756cd10',
    CIDADE: 'São Julião',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.682743072509766,
    MAXIMO: 34.41695022583008,
    MEDIA: 34.13189679899616,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7a0aed8-c005-4694-9a13-0478b451fabb',
    CIDADE: 'São Lourenço do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.992191314697266,
    MAXIMO: 33.605377197265625,
    MEDIA: 33.382320387130775,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db241782-00be-4ca0-9fb1-fe331ff1423f',
    CIDADE: 'São Luis do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.59870147705078,
    MAXIMO: 36.52287292480469,
    MEDIA: 36.096891948463615,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4027069-f2e1-4af8-b7f6-c705008868e2',
    CIDADE: 'São Miguel da Baixa Grande',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 41.92185974121094,
    MAXIMO: 42.54694747924805,
    MEDIA: 42.23440361022949,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b815dad0-9f44-405a-94d0-ac4de04929ae',
    CIDADE: 'São Miguel do Fidalgo',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.251808166503906,
    MAXIMO: 36.5468635559082,
    MEDIA: 36.399335861206055,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a06030f-e2a2-4695-9104-94e59c38f04b',
    CIDADE: 'São Miguel do Tapuio',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.211971282958984,
    MAXIMO: 42.62406539916992,
    MEDIA: 38.68777807135331,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '823d3c54-e1ea-4ddf-9aa5-cda146af3d98',
    CIDADE: 'São Pedro do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 42.46479415893555,
    MAXIMO: 42.918975830078125,
    MEDIA: 42.691884994506836,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dcfc8999-351e-4fb1-9835-6a8a4cdd928a',
    CIDADE: 'São Raimundo Nonato',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.415733337402344,
    MAXIMO: 34.644622802734375,
    MEDIA: 34.011798010932075,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6eefe1e0-15a3-469d-b8c7-ab7daf4439bf',
    CIDADE: 'Sebastião Barros',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.893497467041016,
    MAXIMO: 33.050045013427734,
    MEDIA: 32.971771240234375,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7829934-bc55-40f8-ac43-2e9749557375',
    CIDADE: 'Sebastião Leal',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.41440200805664,
    MAXIMO: 37.88763427734375,
    MEDIA: 37.22895219590929,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cc589d32-8db1-48bf-a740-506322eaff25',
    CIDADE: 'Sigefredo Pacheco',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.5308723449707,
    MAXIMO: 42.26420974731445,
    MEDIA: 40.727745056152344,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e8fcd9d-b2c9-478f-b681-ed9f3f2a96c1',
    CIDADE: 'Simões',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 32.1102180480957,
    MAXIMO: 33.29761505126953,
    MEDIA: 32.78312015533447,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a24aee7-afbf-4016-8912-1d78b460c79a',
    CIDADE: 'Simplício Mendes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.45869064331055,
    MAXIMO: 35.57404708862305,
    MEDIA: 35.061850229899086,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '625f6edb-d658-4447-ad83-c6d7ae47d812',
    CIDADE: 'Socorro do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.64063262939453,
    MAXIMO: 36.3404655456543,
    MEDIA: 36.02043914794922,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0ab4670-1b24-4026-b0df-42c022bc5e50',
    CIDADE: 'Sussuapara',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.3021354675293,
    MAXIMO: 36.20839309692383,
    MEDIA: 35.70249780749473,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '965350ac-702b-42b7-bb23-12ae50583fa3',
    CIDADE: 'Tamboril do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.71309280395508,
    MAXIMO: 35.51520919799805,
    MEDIA: 35.11002731323242,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '83890216-40de-40ee-ac4b-88a74dc5216d',
    CIDADE: 'Tanque do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.48157501220703,
    MAXIMO: 40.095523834228516,
    MEDIA: 39.252493736511106,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4bec8442-7056-44f3-822b-d2e6c30c8b75',
    CIDADE: 'Teresina',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 43.229522705078125,
    MAXIMO: 43.78780746459961,
    MEDIA: 43.58235454559326,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d1b50e8-e508-4385-b22e-1ad9feea2909',
    CIDADE: 'União',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 40.81268310546875,
    MAXIMO: 42.39057159423828,
    MEDIA: 41.64564895629883,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd77759fe-f01b-4e14-bf7d-41e967a13e24',
    CIDADE: 'Uruçuí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 36.1453742980957,
    MAXIMO: 37.90896987915039,
    MEDIA: 36.93095764747033,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c541adb-b5cd-43b2-8e4a-6ddf2f4f92a1',
    CIDADE: 'Valença do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 38.53881072998047,
    MAXIMO: 39.76362609863281,
    MEDIA: 39.04817008972168,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6839af4-346c-4cfc-af6f-345b9651d266',
    CIDADE: 'Várzea Branca',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.57176208496094,
    MAXIMO: 33.791160583496094,
    MEDIA: 33.681461334228516,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e1f2ee7-cf72-4073-a1c5-597b91c84521',
    CIDADE: 'Várzea Grande',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 39.058773040771484,
    MAXIMO: 40.095523834228516,
    MEDIA: 39.571825359943425,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c00f57cd-e51a-48fc-b4f3-f43b63883663',
    CIDADE: 'Vera Mendes',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 34.463741302490234,
    MAXIMO: 34.849830627441406,
    MEDIA: 34.65678596496582,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1257d5b2-95e3-4dd3-ac30-8973c0c5cf41',
    CIDADE: 'Vila Nova do Piauí',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 33.714271545410156,
    MAXIMO: 34.41695022583008,
    MEDIA: 34.13484158702806,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b765ead-f78b-4894-b6b1-06ad9a8bd83f',
    CIDADE: 'Wall Ferraz',
    SIGLA: 'PI',
    ESTADO: 'Piauí',
    MINIMO: 35.740116119384766,
    MAXIMO: 36.61454772949219,
    MEDIA: 36.43121879532375,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8061c8a6-66fe-4bc0-af3e-72c28b4c87db',
    CIDADE: 'Abaiara',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.41376304626465,
    MAXIMO: 31.570056915283203,
    MEDIA: 31.445188783797718,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f7d6044-990d-432b-abd5-fbd65927ece0',
    CIDADE: 'Acarape',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.4178409576416,
    MAXIMO: 30.535598754882812,
    MEDIA: 30.461326663335186,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea1d50fc-99e6-432a-9320-1d5d7f9aaf0e',
    CIDADE: 'Acaraú',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.63950538635254,
    MAXIMO: 29.733673095703125,
    MEDIA: 29.677451133728027,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81f56ebd-408a-4252-aea9-2de540c82a1d',
    CIDADE: 'Acopiara',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.85439109802246,
    MAXIMO: 32.782596588134766,
    MEDIA: 32.29943602425711,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c100a805-5e53-4704-9398-f2c3f541b95c',
    CIDADE: 'Aiuaba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 33.05764389038086,
    MAXIMO: 33.962684631347656,
    MEDIA: 33.45225851876395,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '860d2bf3-060e-41d5-af9e-eb1b1b1c4e32',
    CIDADE: 'Alcântaras',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.124116897583008,
    MAXIMO: 30.358905792236328,
    MEDIA: 30.24776669342837,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5083aaaf-a809-4809-9101-57a1889702ec',
    CIDADE: 'Altaneira',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.1178092956543,
    MAXIMO: 32.38618850708008,
    MEDIA: 32.26732300079847,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa2e2bb0-ddd0-408a-998a-ca1ceae01cf7',
    CIDADE: 'Alto Santo',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.94235610961914,
    MAXIMO: 31.03594207763672,
    MEDIA: 30.99373944600423,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c7bef70-6f9c-463f-b25d-6be8612bb427',
    CIDADE: 'Amontada',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.87303924560547,
    MAXIMO: 29.9271183013916,
    MEDIA: 29.900078773498535,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '414b0874-303e-4cf2-920b-b58e7a4a3597',
    CIDADE: 'Antonina do Norte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.51082992553711,
    MAXIMO: 33.05764389038086,
    MEDIA: 32.77350334631497,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24699752-bd4f-4bd9-9107-1ecc2bee153c',
    CIDADE: 'Apuiarés',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.283525466918945,
    MAXIMO: 30.555627822875977,
    MEDIA: 30.39348104037102,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f40e3b47-1e79-407e-ac30-90f192b85678',
    CIDADE: 'Aquiraz',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.323118209838867,
    MAXIMO: 30.335607528686523,
    MEDIA: 30.329362869262695,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2163a30f-d9ee-4047-905b-d80735c4da6c',
    CIDADE: 'Aracati',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.450056076049805,
    MAXIMO: 30.525545120239258,
    MEDIA: 30.49134111404419,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e6c9ce6-1dac-40a5-ab25-5d1fa9a18408',
    CIDADE: 'Aracoiaba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.63650131225586,
    MAXIMO: 30.747482299804688,
    MEDIA: 30.691991806030273,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a254601-b2f9-49b4-a8ac-af733aeb795b',
    CIDADE: 'Ararendá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.810909271240234,
    MAXIMO: 34.56728744506836,
    MEDIA: 33.59303386827302,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '662be44d-00f1-48a7-b8f6-de8153a6f43b',
    CIDADE: 'Araripe',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.253746032714844,
    MAXIMO: 32.69588851928711,
    MEDIA: 32.47919718424479,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5b2100b-8e6d-424d-9e99-296c0e649542',
    CIDADE: 'Aratuba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.613353729248047,
    MAXIMO: 30.799327850341797,
    MEDIA: 30.69408516754292,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e3b63f2-2de6-46c9-bf44-693713c878b4',
    CIDADE: 'Arneiroz',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 33.10411834716797,
    MAXIMO: 33.795509338378906,
    MEDIA: 33.450079917907715,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd5b0c88a-6da5-4156-bfcd-14b64b803b8f',
    CIDADE: 'Assaré',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.244285583496094,
    MAXIMO: 32.61967849731445,
    MEDIA: 32.416717529296875,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db19d5de-bb0d-483e-b2b7-0afb66e1d73b',
    CIDADE: 'Aurora',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.491201400756836,
    MAXIMO: 31.61402702331543,
    MEDIA: 31.55398114522298,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0154d02f-b2a7-49c5-b766-406a8c5c4b57',
    CIDADE: 'Baixio',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.432382583618164,
    MAXIMO: 31.548152923583984,
    MEDIA: 31.47761546002131,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b3bcfeb-47fb-44d6-9d4c-28c761fb64b9',
    CIDADE: 'Banabuiú',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.052536010742188,
    MAXIMO: 31.464948654174805,
    MEDIA: 31.253968238830566,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '458c27c1-40d3-4c01-9fe4-6a44af4bf8d1',
    CIDADE: 'Barbalha',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.487462997436523,
    MAXIMO: 31.836660385131836,
    MEDIA: 31.628141727561538,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64b07f36-7300-4ecd-8fbd-1f7caa695443',
    CIDADE: 'Barreira',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.4178409576416,
    MAXIMO: 30.63650131225586,
    MEDIA: 30.526570638835445,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a925736-6276-4cb9-b260-2d106558d912',
    CIDADE: 'Barro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.382709503173828,
    MAXIMO: 31.448116302490234,
    MEDIA: 31.41541290283203,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39dd5c9d-d732-486a-93ca-52171a95ff78',
    CIDADE: 'Barroquinha',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.67013168334961,
    MAXIMO: 29.926034927368164,
    MEDIA: 29.766252398878724,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a6599b4-ace3-4c20-a724-a2eec022e34d',
    CIDADE: 'Baturité',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.462120056152344,
    MAXIMO: 30.804973602294922,
    MEDIA: 30.618059193564978,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc3fe75f-5c99-4304-940b-79cccb5076f6',
    CIDADE: 'Beberibe',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.426393508911133,
    MAXIMO: 30.52510643005371,
    MEDIA: 30.474005699157715,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cc3e4085-d01f-4419-98c7-b4505ba3abf1',
    CIDADE: 'Bela Cruz',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.709318161010742,
    MAXIMO: 29.82564926147461,
    MEDIA: 29.752291997273762,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd1fb970-0c7e-4a09-8e95-6dbb10056e98',
    CIDADE: 'Boa Viagem',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.60252571105957,
    MAXIMO: 32.596458435058594,
    MEDIA: 32.049050649007164,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '503ff227-a91c-4f6f-9769-0f138e9d82f5',
    CIDADE: 'Brejo Santo',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.269258499145508,
    MAXIMO: 31.374711990356445,
    MEDIA: 31.321985244750977,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96b8c532-1692-44a8-bc8b-51e7691a49fe',
    CIDADE: 'Camocim',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.60578155517578,
    MAXIMO: 29.73621940612793,
    MEDIA: 29.683551788330078,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a4c115b-f891-4ddc-bbb3-c0b9272b9953',
    CIDADE: 'Campos Sales',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.88727569580078,
    MAXIMO: 33.17953109741211,
    MEDIA: 33.033403396606445,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35e50cc0-60ea-4f76-8ba3-360730ad3e9b',
    CIDADE: 'Canindé',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.64789581298828,
    MAXIMO: 31.048236846923828,
    MEDIA: 30.851739671495224,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '178b517c-518e-4126-92b2-7cce9a0b3874',
    CIDADE: 'Capistrano',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.569257736206055,
    MAXIMO: 30.804973602294922,
    MEDIA: 30.700669772829944,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0f52ce38-621b-4109-9591-b37e68d69ac9',
    CIDADE: 'Caridade',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.487730026245117,
    MAXIMO: 30.656808853149414,
    MEDIA: 30.55546506245931,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '203c88d6-5980-48c7-9455-d73d517d0af7',
    CIDADE: 'Cariré',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.82445526123047,
    MAXIMO: 30.950098037719727,
    MEDIA: 30.887276649475098,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b2a8485-290b-4fbe-9c65-2aca390f8efb',
    CIDADE: 'Caririaçu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.652944564819336,
    MAXIMO: 31.7652645111084,
    MEDIA: 31.709104537963867,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ff9251e-01a1-4921-9f31-a62646a24c0c',
    CIDADE: 'Cariús',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.924301147460938,
    MAXIMO: 32.16765594482422,
    MEDIA: 32.0228271484375,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfc2801a-5693-41c5-a419-a8bd47ceca1d',
    CIDADE: 'Carnaubal',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.914106369018555,
    MAXIMO: 32.309974670410156,
    MEDIA: 32.112040519714355,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d19561f-db53-42a9-9d14-a7705bd6d3b2',
    CIDADE: 'Cascavel',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.379365921020508,
    MAXIMO: 30.559059143066406,
    MEDIA: 30.453372478485107,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7eeae3b6-ca57-4e50-a3d4-b7ce635a947c',
    CIDADE: 'Catarina',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.720970153808594,
    MAXIMO: 33.433990478515625,
    MEDIA: 32.92461799328903,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7fb7d534-d539-4641-9d40-cbd9ee4d84df',
    CIDADE: 'Catunda',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.811811447143555,
    MAXIMO: 32.0756721496582,
    MEDIA: 31.94374179840088,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48cc2ba1-97d1-4396-a4fc-05d95ba145ac',
    CIDADE: 'Caucaia',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.18946075439453,
    MAXIMO: 30.273391723632812,
    MEDIA: 30.230473518371582,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fae088d0-3953-4ee4-81ac-235b88a9a768',
    CIDADE: 'Cedro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.69036102294922,
    MAXIMO: 31.824262619018555,
    MEDIA: 31.757311820983887,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2da0c551-2a54-44e0-b53b-d764146870e4',
    CIDADE: 'Chaval',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.78177261352539,
    MAXIMO: 30.016082763671875,
    MEDIA: 29.914387414167017,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8618a3c-aeea-4259-88aa-d6ccc5c6a490',
    CIDADE: 'Choró',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.9461612701416,
    MAXIMO: 31.20099639892578,
    MEDIA: 31.08026631673177,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d25d4a3-cb0c-4b26-ba47-96024a153488',
    CIDADE: 'Chorozinho',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.398025512695312,
    MAXIMO: 30.595890045166016,
    MEDIA: 30.493181124543845,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36e1e8a9-6cdc-4e24-9e73-c92f759bd688',
    CIDADE: 'Coreaú',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.129362106323242,
    MAXIMO: 30.73952865600586,
    MEDIA: 30.3552009212278,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01306626-b394-44e3-b89f-a217be4cc627',
    CIDADE: 'Crateús',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 33.59687042236328,
    MAXIMO: 35.61239242553711,
    MEDIA: 34.60131327311198,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a781e06-d70d-4a17-afc9-6eb6ebd62528',
    CIDADE: 'Crato',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.703081130981445,
    MAXIMO: 31.959163665771484,
    MEDIA: 31.83296839396159,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93c65ce5-5246-427a-ba7c-9b2ae6be378b',
    CIDADE: 'Croatá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.14045715332031,
    MAXIMO: 33.86427307128906,
    MEDIA: 32.91199151111031,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7474399c-9816-4c2b-9c54-e3dd00982a74',
    CIDADE: 'Cruz',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.611392974853516,
    MAXIMO: 29.733673095703125,
    MEDIA: 29.647942236695364,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c167348c-04fd-4666-bf46-1f996202255f',
    CIDADE: 'Deputado Irapuan Pinheiro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.946996688842773,
    MAXIMO: 32.0013542175293,
    MEDIA: 31.974175453186035,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75a98d2d-47dc-4928-aac2-f7f337b03c64',
    CIDADE: 'Ereré',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.10163688659668,
    MAXIMO: 31.185527801513672,
    MEDIA: 31.143582344055176,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1774bdc6-162c-4108-ac55-f804ce30f075',
    CIDADE: 'Eusébio',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.251407623291016,
    MAXIMO: 30.335607528686523,
    MEDIA: 30.269858315167365,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c7a82bc-ab60-41be-a937-a9cef23e8a15',
    CIDADE: 'Farias Brito',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.851890563964844,
    MAXIMO: 32.29198455810547,
    MEDIA: 32.10580288581517,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e08ff540-e3bc-467e-b1c9-b37e87bd4eb3',
    CIDADE: 'Forquilha',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.432912826538086,
    MAXIMO: 30.49371337890625,
    MEDIA: 30.463313102722168,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa0fefc3-2b5f-40f8-a109-f0d152c84e13',
    CIDADE: 'Fortaleza',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.187923431396484,
    MAXIMO: 30.267940521240234,
    MEDIA: 30.245052388732365,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a13e3976-8cf2-4522-a346-a3bbebcf9dd9',
    CIDADE: 'Fortim',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.370731353759766,
    MAXIMO: 30.49347686767578,
    MEDIA: 30.465225414647403,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8aa660c3-8faf-4ea1-a665-07d775f0897b',
    CIDADE: 'Frecheirinha',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.413726806640625,
    MAXIMO: 30.90799903869629,
    MEDIA: 30.495378016760274,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b3de6d22-07da-4839-8032-f058c3876ac3',
    CIDADE: 'General Sampaio',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.41537094116211,
    MAXIMO: 30.59656524658203,
    MEDIA: 30.495205531494328,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e0bccb0-ff4b-4ec1-a0c7-edff624d078e',
    CIDADE: 'Graça',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.950098037719727,
    MAXIMO: 31.601865768432617,
    MEDIA: 31.277600849311796,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c059296a-10f1-4df5-8a2a-c61c8d68e8ee',
    CIDADE: 'Granja',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.703048706054688,
    MAXIMO: 30.24248695373535,
    MEDIA: 29.94672966003418,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e1d4395-f5cc-4d62-951e-c0bc0e933fea',
    CIDADE: 'Granjeiro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.724185943603516,
    MAXIMO: 31.851890563964844,
    MEDIA: 31.790725431081317,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '83e0fab1-3c1c-4904-a20a-3a579789a386',
    CIDADE: 'Groaíras',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.49371337890625,
    MAXIMO: 30.82445526123047,
    MEDIA: 30.632725806238703,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd5d3e78a-a418-45c9-97c1-a61e4d1b2f43',
    CIDADE: 'Guaiúba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.335607528686523,
    MAXIMO: 30.462120056152344,
    MEDIA: 30.413624812950893,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e740ac45-c30b-4959-9b84-2cecb546b342',
    CIDADE: 'Guaraciaba do Norte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.601865768432617,
    MAXIMO: 32.14045715332031,
    MEDIA: 31.871161460876465,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7cb39107-e7cb-4f88-ae3f-063f0d8bc932',
    CIDADE: 'Guaramiranga',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.487730026245117,
    MAXIMO: 30.613353729248047,
    MEDIA: 30.558953296490383,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '73a9c83d-ba6b-4f8b-8300-c055ab638efa',
    CIDADE: 'Hidrolândia',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.555866241455078,
    MAXIMO: 31.96917724609375,
    MEDIA: 31.74371592203776,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b332273-b158-4871-a79c-d65aa5ed9975',
    CIDADE: 'Horizonte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.323118209838867,
    MAXIMO: 30.4178409576416,
    MEDIA: 30.399911006464006,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da47250e-8006-4ba3-a0b1-3b85829b2123',
    CIDADE: 'Ibaretama',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.8519287109375,
    MAXIMO: 30.92177391052246,
    MEDIA: 30.88685131072998,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '67c7d485-e84e-4e2b-92db-742e058b4461',
    CIDADE: 'Ibiapina',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.73952865600586,
    MAXIMO: 32.036048889160156,
    MEDIA: 31.396890735989107,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e146ec0-a7d2-448c-8eba-aed1048e0282',
    CIDADE: 'Ibicuitinga',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.72661590576172,
    MAXIMO: 31.052536010742188,
    MEDIA: 30.90454900661604,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfd9bb80-78ae-4c46-948b-81d2731ac6f2',
    CIDADE: 'Icapuí',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.40447425842285,
    MAXIMO: 30.49858856201172,
    MEDIA: 30.44446826601706,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c565c89d-a3fb-406c-b050-61de90d9fc4e',
    CIDADE: 'Icó',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.44230079650879,
    MAXIMO: 31.706388473510742,
    MEDIA: 31.540030002593994,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6100771c-81c6-4e10-a1ad-4a786d0833bc',
    CIDADE: 'Iguatu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.847320556640625,
    MAXIMO: 32.04043197631836,
    MEDIA: 31.97125244140625,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4edb2a8c-a531-4d54-9ed2-1c12742a8f61',
    CIDADE: 'Independência',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.9603157043457,
    MAXIMO: 34.374656677246094,
    MEDIA: 33.72337934705946,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d96d580-98e8-41cd-94d0-0912febc5e8c',
    CIDADE: 'Ipaporanga',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 33.3103141784668,
    MAXIMO: 35.13978958129883,
    MEDIA: 34.22505187988281,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6cd43fa3-440d-4cc9-9674-7909c3ebb060',
    CIDADE: 'Ipaumirim',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.414756774902344,
    MAXIMO: 31.523801803588867,
    MEDIA: 31.467089920456395,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc3e3417-8746-464d-a52a-88b083244ad1',
    CIDADE: 'Ipu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.813125610351562,
    MAXIMO: 32.30568313598633,
    MEDIA: 32.059404373168945,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da7efa8c-c540-4fae-87e1-d83934201bcc',
    CIDADE: 'Ipueiras',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.71986770629883,
    MAXIMO: 33.926597595214844,
    MEDIA: 33.41238784790039,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2776dbce-be5c-4a4e-ba00-793e384e385b',
    CIDADE: 'Iracema',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.081499099731445,
    MAXIMO: 31.138988494873047,
    MEDIA: 31.110243797302246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f0c1aea-fae9-49ac-ae5f-fcd93901846c',
    CIDADE: 'Irauçuba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.170358657836914,
    MAXIMO: 30.513286590576172,
    MEDIA: 30.339614868164062,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8477fbf1-cf19-438f-9643-d7a036a2eb87',
    CIDADE: 'Itaiçaba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.491174697875977,
    MAXIMO: 30.630393981933594,
    MEDIA: 30.542977662043636,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '099ba98a-4b96-421e-94e9-04a235feccfa',
    CIDADE: 'Itaitinga',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.261991500854492,
    MAXIMO: 30.439102172851562,
    MEDIA: 30.340512974869398,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a75963b9-5290-4d4d-8ecb-f65f237a8051',
    CIDADE: 'Itapajé',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.16058921813965,
    MAXIMO: 30.308856964111328,
    MEDIA: 30.216597441909848,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b723320e-5b56-4f39-a63f-9d7442d72d11',
    CIDADE: 'Itapipoca',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.8874568939209,
    MAXIMO: 30.059682846069336,
    MEDIA: 29.968257904052734,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93a4de40-8245-4414-b173-7a834acc309c',
    CIDADE: 'Itapiúna',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.74016761779785,
    MAXIMO: 30.87058448791504,
    MEDIA: 30.80524190266927,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6f6aa200-e70c-48ad-acf9-2cc9c5700f54',
    CIDADE: 'Itarema',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.74852752685547,
    MAXIMO: 29.868328094482422,
    MEDIA: 29.794607798258465,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfbe2bc6-1cde-4ff3-9259-9288c9f03fba',
    CIDADE: 'Itatira',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.138397216796875,
    MAXIMO: 31.262372970581055,
    MEDIA: 31.200385093688965,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ecf7643-a91f-4d38-bbb2-76a510971286',
    CIDADE: 'Jaguaretama',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.217971801757812,
    MAXIMO: 31.418699264526367,
    MEDIA: 31.298520278930663,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b8c42fe-ea43-4167-8ffe-d3a1d0ae712e',
    CIDADE: 'Jaguaribara',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.12152099609375,
    MAXIMO: 31.1900577545166,
    MEDIA: 31.155789375305176,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11229163-6206-4fa5-ab76-c3ab7871a858',
    CIDADE: 'Jaguaribe',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.22923469543457,
    MAXIMO: 31.53569221496582,
    MEDIA: 31.393916811261857,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6ed19240-c6f8-4ee3-8f8e-24eabd830ef6',
    CIDADE: 'Jaguaruana',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.547426223754883,
    MAXIMO: 30.59189224243164,
    MEDIA: 30.575664520263672,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59e0bb1a-7081-452d-bd07-0b2a2b2a6d85',
    CIDADE: 'Jardim',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.425355911254883,
    MAXIMO: 31.487462997436523,
    MEDIA: 31.456409454345703,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f66f3437-7984-4f85-b341-6433cc5026ca',
    CIDADE: 'Jati',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.192977905273438,
    MAXIMO: 31.425355911254883,
    MEDIA: 31.31013558586219,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2fa9095f-1af2-44c8-bb35-a0efccb5fed4',
    CIDADE: 'Jijoca de Jericoacoara',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.60578155517578,
    MAXIMO: 29.709318161010742,
    MEDIA: 29.62627885546179,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b048a2fa-83d4-4b2b-b234-93de1393ca34',
    CIDADE: 'Juazeiro do Norte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.570056915283203,
    MAXIMO: 31.7652645111084,
    MEDIA: 31.7143839359547,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdcf5cb4-3941-4643-9566-c818a05bdfae',
    CIDADE: 'Jucás',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.21446990966797,
    MAXIMO: 32.43602752685547,
    MEDIA: 32.342184702555336,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6066ed49-fda0-4bb2-bb84-cc4bd0022a0c',
    CIDADE: 'Lavras da Mangabeira',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.523801803588867,
    MAXIMO: 31.724185943603516,
    MEDIA: 31.635674794514973,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c07c8d99-96c2-4b14-877c-4161ba3031a8',
    CIDADE: 'Limoeiro do Norte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.764739990234375,
    MAXIMO: 30.832509994506836,
    MEDIA: 30.798624992370605,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6204fded-7a1f-46b2-bc81-b6e93f97ace1',
    CIDADE: 'Madalena',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.32737922668457,
    MAXIMO: 31.460966110229492,
    MEDIA: 31.40620231628418,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '347a8c6d-060a-4f33-bf35-2db575bebd61',
    CIDADE: 'Maracanaú',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.261991500854492,
    MAXIMO: 30.348575592041016,
    MEDIA: 30.284562698778306,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3f78fa7-ed5c-47fb-8872-814718bca438',
    CIDADE: 'Maranguape',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.348575592041016,
    MAXIMO: 30.376697540283203,
    MEDIA: 30.362457275390625,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '959286c4-9ffc-4787-8ba2-9f6087f5045d',
    CIDADE: 'Marco',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.709318161010742,
    MAXIMO: 29.944799423217773,
    MEDIA: 29.847892919241353,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5887aa7e-505a-42e4-823c-61bf75b4646e',
    CIDADE: 'Martinópole',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.703048706054688,
    MAXIMO: 29.817874908447266,
    MEDIA: 29.802328547911248,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6007b692-f5f0-4f50-8e62-8123fc38657a',
    CIDADE: 'Massapê',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.10934066772461,
    MAXIMO: 30.124116897583008,
    MEDIA: 30.11672878265381,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d941791-79c0-41bc-8055-4b688071f29c',
    CIDADE: 'Mauriti',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.29063606262207,
    MAXIMO: 31.338340759277344,
    MEDIA: 31.32162920633952,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54b0df89-5250-4eed-b26d-f330a52beab4',
    CIDADE: 'Meruoca',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.124116897583008,
    MAXIMO: 30.3201961517334,
    MEDIA: 30.214723336535297,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3eff842-3cf4-4fb6-a7b2-dcf29011292a',
    CIDADE: 'Milagres',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.395919799804688,
    MAXIMO: 31.490381240844727,
    MEDIA: 31.443150520324707,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '809d69c4-dbaf-4884-9331-f064d47870bc',
    CIDADE: 'Milhã',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.694713592529297,
    MAXIMO: 31.76659393310547,
    MEDIA: 31.730653762817383,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2567e867-1a7c-423f-88bd-e39500ab8de6',
    CIDADE: 'Miraíma',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.025388717651367,
    MAXIMO: 30.203628540039062,
    MEDIA: 30.09598668416341,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '07ab1365-cce3-4ccf-980b-9a0d05801ac3',
    CIDADE: 'Missão Velha',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.528596878051758,
    MAXIMO: 31.570056915283203,
    MEDIA: 31.54932689666748,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3ee7f83-9fee-4f59-886f-f22194334ec4',
    CIDADE: 'Mombaça',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.07120895385742,
    MAXIMO: 33.02359390258789,
    MEDIA: 32.537755489349365,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0f1d407-d1a9-4d6e-b193-e08811b74c12',
    CIDADE: 'Monsenhor Tabosa',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.872814178466797,
    MAXIMO: 32.138427734375,
    MEDIA: 32.0056209564209,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c814e2c0-65be-45ae-bbb2-321f64f50ccb',
    CIDADE: 'Morada Nova',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.64266014099121,
    MAXIMO: 31.13458251953125,
    MEDIA: 30.907984972000122,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18478a9a-a25a-436f-a23f-4f800728b0ba',
    CIDADE: 'Moraújo',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.129362106323242,
    MAXIMO: 30.134971618652344,
    MEDIA: 30.132166862487793,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbccda94-65a6-4ba4-9e52-274e7ae649fb',
    CIDADE: 'Morrinhos',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.867164611816406,
    MAXIMO: 29.944799423217773,
    MEDIA: 29.91741489606536,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f958b900-df9d-4ee8-8d8c-089e896f2eba',
    CIDADE: 'Mucambo',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.632753372192383,
    MAXIMO: 31.140592575073242,
    MEDIA: 30.871342114333512,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5083837-6ce3-4634-b81d-11d6b5b27751',
    CIDADE: 'Mulungu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.613353729248047,
    MAXIMO: 30.613353729248047,
    MEDIA: 30.613353729248047,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1abfc981-6f66-47f0-af1a-20534ceef599',
    CIDADE: 'Nova Olinda',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.97150421142578,
    MAXIMO: 32.38618850708008,
    MEDIA: 32.11742041570058,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b6d2ac4-5e08-49e2-8f1f-c5cb22bc5223',
    CIDADE: 'Nova Russas',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.40990447998047,
    MAXIMO: 32.810909271240234,
    MEDIA: 32.61040687561035,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c03e81c-e5aa-47eb-bd65-a601fc016c04',
    CIDADE: 'Novo Oriente',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 34.72000503540039,
    MAXIMO: 35.566688537597656,
    MEDIA: 35.05597813924154,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b42d63b6-a4f1-496a-a7cb-d35e4dd2c46c',
    CIDADE: 'Ocara',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.595890045166016,
    MAXIMO: 30.688785552978516,
    MEDIA: 30.642337799072266,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a6b0336-0a87-462a-ae65-e836c2b046c6',
    CIDADE: 'Orós',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.554672241210938,
    MAXIMO: 31.70201301574707,
    MEDIA: 31.628342628479004,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1870ee0a-b1d3-42df-a436-94c732c4b1bc',
    CIDADE: 'Pacajus',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.398025512695312,
    MAXIMO: 30.505043029785156,
    MEDIA: 30.41579612204056,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b7b34f6-15c8-45f3-ab94-520a61ec2abf',
    CIDADE: 'Pacatuba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.261991500854492,
    MAXIMO: 30.348575592041016,
    MEDIA: 30.322384247349095,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdce0bc1-1d1a-4be3-82fd-609031b2667c',
    CIDADE: 'Pacoti',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.462120056152344,
    MAXIMO: 30.613353729248047,
    MEDIA: 30.480443222881803,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '927bea87-94d8-49ed-9555-79487327c383',
    CIDADE: 'Pacujá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.950098037719727,
    MAXIMO: 31.140592575073242,
    MEDIA: 30.969073155224397,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4899138-be13-4f76-9856-69b2abe54083',
    CIDADE: 'Palhano',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.561786651611328,
    MAXIMO: 30.630393981933594,
    MEDIA: 30.59609031677246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61810de0-2707-49a2-98ad-6ff86aa09815',
    CIDADE: 'Palmácia',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.362098693847656,
    MAXIMO: 30.487730026245117,
    MEDIA: 30.463368637427592,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2df810db-e833-4021-a8dd-3c683e4e8db9',
    CIDADE: 'Paracuru',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.019128799438477,
    MAXIMO: 30.10564613342285,
    MEDIA: 30.096768037683074,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd044e3fd-2b70-4ebb-9b7c-12b884edf255',
    CIDADE: 'Paraipaba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.019128799438477,
    MAXIMO: 30.09453582763672,
    MEDIA: 30.056832313537598,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f493f597-6925-4f64-83fb-ff7c6cd23a06',
    CIDADE: 'Parambu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 34.125160217285156,
    MAXIMO: 35.481266021728516,
    MEDIA: 34.799049377441406,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7795f5c7-9310-4ec1-badb-fb8ec4146fde',
    CIDADE: 'Paramoti',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.555627822875977,
    MAXIMO: 30.59656524658203,
    MEDIA: 30.576096534729004,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84361853-eeb4-4f96-a2be-50106b0acd6d',
    CIDADE: 'Pedra Branca',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.187965393066406,
    MAXIMO: 32.92597579956055,
    MEDIA: 32.59059238433838,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6054afae-2911-4efe-91e2-66b672d863b7',
    CIDADE: 'Penaforte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.192977905273438,
    MAXIMO: 31.31774139404297,
    MEDIA: 31.286609192326964,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c805a90e-d4aa-402d-8470-ed717630b701',
    CIDADE: 'Pentecoste',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.27855682373047,
    MAXIMO: 30.392793655395508,
    MEDIA: 30.3108491897583,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60fde21d-74e5-4714-bd5d-b2137bc89d1b',
    CIDADE: 'Pereiro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.185527801513672,
    MAXIMO: 31.421062469482422,
    MEDIA: 31.29940377615402,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0054862-1211-42b7-b88d-ce67e16316cc',
    CIDADE: 'Pindoretama',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.302705764770508,
    MAXIMO: 30.398025512695312,
    MEDIA: 30.33350433011038,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ccd282e-a9a9-46aa-8719-431c3740a96a',
    CIDADE: 'Piquet Carneiro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.15351486206055,
    MAXIMO: 32.20954513549805,
    MEDIA: 32.1815299987793,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6812827f-f169-4cf9-b0fc-04fe402c2637',
    CIDADE: 'Pires Ferreira',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.146560668945312,
    MAXIMO: 31.813125610351562,
    MEDIA: 31.619064330961834,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a9aef67-ece6-442e-a494-c8be37251787',
    CIDADE: 'Poranga',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 34.56728744506836,
    MAXIMO: 35.99897003173828,
    MEDIA: 35.148948669433594,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b487054-676f-4554-ac50-d78aad153298',
    CIDADE: 'Porteiras',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.374711990356445,
    MAXIMO: 31.528596878051758,
    MEDIA: 31.42801232206534,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95d78ffd-c0bc-4384-b649-fda4c160c00d',
    CIDADE: 'Potengi',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.229248046875,
    MAXIMO: 32.88727569580078,
    MEDIA: 32.54806701720332,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5413064f-1350-4f3f-9cce-b797406d9937',
    CIDADE: 'Potiretama',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.928319931030273,
    MAXIMO: 31.10163688659668,
    MEDIA: 31.032301727520153,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb63cb33-5c20-427f-993a-8117815ba1d9',
    CIDADE: 'Quiterianópolis',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 34.964107513427734,
    MAXIMO: 35.58981704711914,
    MEDIA: 35.374891916910805,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '633e1606-2dba-4db1-90d7-4e9fe2cd1fa9',
    CIDADE: 'Quixadá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.95427131652832,
    MAXIMO: 31.251535415649414,
    MEDIA: 31.101757049560547,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19fd6035-78ff-4efc-b8e7-a7ffabc2434a',
    CIDADE: 'Quixelô',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.70021629333496,
    MAXIMO: 31.866647720336914,
    MEDIA: 31.783432006835938,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e65d65a-ff8a-40f4-ac4d-755467947048',
    CIDADE: 'Quixeramobim',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.36336326599121,
    MAXIMO: 31.828495025634766,
    MEDIA: 31.576812532212998,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '027565e9-c733-40b8-9ba5-5a8b137c1525',
    CIDADE: 'Quixeré',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.59189224243164,
    MAXIMO: 30.764739990234375,
    MEDIA: 30.68386973134905,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '219a6e6c-afb7-445c-bc7d-4c2cd1d2acf1',
    CIDADE: 'Redenção',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.439102172851562,
    MAXIMO: 30.569257736206055,
    MEDIA: 30.498835710929885,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'abf3bb49-9512-49b2-b57b-3993759929f5',
    CIDADE: 'Reriutaba',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.82445526123047,
    MAXIMO: 31.601865768432617,
    MEDIA: 31.244634313693563,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b074ee6f-4c21-4019-8955-d67ce62806e1',
    CIDADE: 'Russas',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.600690841674805,
    MAXIMO: 30.751440048217773,
    MEDIA: 30.683845202128094,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '066fc09b-9593-437a-856d-ea08c0c7b70c',
    CIDADE: 'Saboeiro',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.64414596557617,
    MAXIMO: 33.015647888183594,
    MEDIA: 32.8072868347168,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb26b002-537b-4099-b8fd-188233c854e5',
    CIDADE: 'Salitre',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.79242706298828,
    MAXIMO: 33.06911087036133,
    MEDIA: 32.94068272908529,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6ffd06c-a198-4619-aea5-dbac5ef1b2f3',
    CIDADE: 'Santana do Acaraú',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.94007110595703,
    MAXIMO: 30.24359130859375,
    MEDIA: 30.05400514602661,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f52ad7f1-8292-48ab-9bd3-a808b359663a',
    CIDADE: 'Santana do Cariri',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.97150421142578,
    MAXIMO: 32.229248046875,
    MEDIA: 32.09087371826172,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0040f219-2cba-45a2-9c2b-29b65cd75b6e',
    CIDADE: 'Santa Quitéria',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.71343994140625,
    MAXIMO: 31.542394638061523,
    MEDIA: 31.098151206970215,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8366c77a-45b5-43d2-a964-512abb4fba73',
    CIDADE: 'São Benedito',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.35899543762207,
    MAXIMO: 31.654863357543945,
    MEDIA: 31.506929397583008,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '059d4e40-d956-44e9-b8fb-8b18229be7bd',
    CIDADE: 'São Gonçalo do Amarante',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.182279586791992,
    MAXIMO: 30.186580657958984,
    MEDIA: 30.18443012237549,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e49aa2f-20ea-4eef-892b-a7782416792a',
    CIDADE: 'São João do Jaguaribe',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.832509994506836,
    MAXIMO: 31.03594207763672,
    MEDIA: 30.936397713531445,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd245b66-937c-4d57-aa78-f0313c3406ce',
    CIDADE: 'São Luís do Curu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.079893112182617,
    MAXIMO: 30.182279586791992,
    MEDIA: 30.175601960094,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65693ed8-914e-4fb9-bffc-39dc92e703e3',
    CIDADE: 'Senador Pompeu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.86690330505371,
    MAXIMO: 31.959754943847656,
    MEDIA: 31.913329124450684,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '447a7840-ec30-40bc-993b-6a7bee00c622',
    CIDADE: 'Senador Sá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.817874908447266,
    MAXIMO: 29.944263458251953,
    MEDIA: 29.88106918334961,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12aa5799-bd6c-42d8-be65-26d67cc94aa6',
    CIDADE: 'Sobral',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.282949447631836,
    MAXIMO: 30.64716148376465,
    MEDIA: 30.484906332833425,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d11ca78-ff60-446d-af6b-9ab9e9f45116',
    CIDADE: 'Solonópole',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.485288619995117,
    MAXIMO: 31.821882247924805,
    MEDIA: 31.627255249023438,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86d40dde-fd94-461d-930e-7e0f54b5e50d',
    CIDADE: 'Tabuleiro do Norte',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.73857307434082,
    MAXIMO: 30.8939266204834,
    MEDIA: 30.832879543304443,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a150a15-44f1-4891-9f17-ef827d69bba6',
    CIDADE: 'Tamboril',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.12977981567383,
    MAXIMO: 33.242549896240234,
    MEDIA: 32.719581604003906,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1267de44-31c4-4650-af29-cbe89296bba8',
    CIDADE: 'Tarrafas',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 32.29198455810547,
    MAXIMO: 32.51082992553711,
    MEDIA: 32.40140724182129,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7375d92-67d4-45fb-809e-24c4deadcb89',
    CIDADE: 'Tauá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 33.08284378051758,
    MAXIMO: 34.93404006958008,
    MEDIA: 33.89514368230646,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4919fab2-41e9-463a-8195-3749a2ee66f1',
    CIDADE: 'Tejuçuoca',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.29650115966797,
    MAXIMO: 30.469648361206055,
    MEDIA: 30.378337860107422,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76cf443b-70f2-41d1-8bf9-74b61d862c3b',
    CIDADE: 'Tianguá',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.520532608032227,
    MAXIMO: 31.12288475036621,
    MEDIA: 30.82170867919922,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0bcb0adc-749c-459e-96fe-c1e0883ce382',
    CIDADE: 'Trairi',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.975738525390625,
    MAXIMO: 30.079893112182617,
    MEDIA: 30.01850191752116,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'be2ae88e-568c-4f16-889a-45eadcb30c99',
    CIDADE: 'Tururu',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.975738525390625,
    MAXIMO: 30.175689697265625,
    MEDIA: 30.107144837500528,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e483845c-391b-46e3-8b6d-90e3b7259ed0',
    CIDADE: 'Ubajara',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.73952865600586,
    MAXIMO: 30.90799903869629,
    MEDIA: 30.823763847351074,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a09383f-5e5a-458d-9549-a013f8dc028e',
    CIDADE: 'Umari',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.449398040771484,
    MAXIMO: 31.548152923583984,
    MEDIA: 31.498775482177734,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5eeda954-b44d-4b6f-b08a-1fa082f654ca',
    CIDADE: 'Umirim',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.166622161865234,
    MAXIMO: 30.175689697265625,
    MEDIA: 30.17115592956543,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '484b8037-c9f0-4029-9325-2aff99a01108',
    CIDADE: 'Uruburetama',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.059682846069336,
    MAXIMO: 30.166622161865234,
    MEDIA: 30.15920964519578,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1d5ec7a-64c2-409d-a0b2-f534242e7a88',
    CIDADE: 'Uruoca',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 29.933391571044922,
    MAXIMO: 29.93962287902832,
    MEDIA: 29.93650722503662,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19a5dd47-603d-4168-a599-33a97cec738f',
    CIDADE: 'Varjota',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.146560668945312,
    MAXIMO: 31.555866241455078,
    MEDIA: 31.17191282689871,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0ec0c80-900c-4222-86e5-e2efba39101a',
    CIDADE: 'Várzea Alegre',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 31.781694412231445,
    MAXIMO: 32.08712387084961,
    MEDIA: 31.934409141540527,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '090b8c98-9578-4996-a099-6ff7002bd498',
    CIDADE: 'Viçosa do Ceará',
    SIGLA: 'CE',
    ESTADO: 'Ceará',
    MINIMO: 30.203393936157227,
    MAXIMO: 30.921300888061523,
    MEDIA: 30.545787048339843,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b2e2fb5-658d-47b5-bace-635d4ac4fb86',
    CIDADE: 'Acari',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.668046951293945,
    MAXIMO: 30.71575355529785,
    MEDIA: 30.6919002532959,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35898d7d-64cc-41c0-9cfa-ac8012494397',
    CIDADE: 'Açu',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.477785110473633,
    MAXIMO: 30.56657600402832,
    MEDIA: 30.523346583048504,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25a5a11a-7d68-415e-945f-0d05409f8169',
    CIDADE: 'Afonso Bezerra',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.29391098022461,
    MAXIMO: 30.477785110473633,
    MEDIA: 30.38631709330937,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1c64845-fc96-4c5f-8279-f42859466b32',
    CIDADE: 'Água Nova',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.14198112487793,
    MAXIMO: 31.26795196533203,
    MEDIA: 31.259858860538635,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64a4af0e-bc91-4015-a548-28ff3f451647',
    CIDADE: 'Alexandria',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.041093826293945,
    MAXIMO: 31.215545654296875,
    MEDIA: 31.108661233176132,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6a457dd-216e-4ab6-ae5a-c02b7309da29',
    CIDADE: 'Almino Afonso',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.940757751464844,
    MAXIMO: 31.041093826293945,
    MEDIA: 30.966483256565954,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39bf72fa-7d48-4a1f-bf6f-cf5040dbf1dd',
    CIDADE: 'Alto do Rodrigues',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.336498260498047,
    MAXIMO: 30.477785110473633,
    MEDIA: 30.3875291512748,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20ef06fe-d584-4581-9e8c-f9aa82e87325',
    CIDADE: 'Angicos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.35472297668457,
    MAXIMO: 30.471410751342773,
    MEDIA: 30.40902264912923,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ea2a055-ac68-4f31-9b6c-bba2eec90c86',
    CIDADE: 'Antônio Martins',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.981348037719727,
    MAXIMO: 31.105987548828125,
    MEDIA: 31.057904458489972,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b508ad1-715f-4953-a683-95092e65cdb3',
    CIDADE: 'Apodi',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.734560012817383,
    MAXIMO: 30.928319931030273,
    MEDIA: 30.841266949971516,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e0db9dc-a6f2-4560-a7a0-a4604b13f5d9',
    CIDADE: 'Areia Branca',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.37459373474121,
    MAXIMO: 30.4144344329834,
    MEDIA: 30.394514083862305,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6988615b-e537-4982-b230-086ede6226d0',
    CIDADE: 'Arês',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.0009708404541,
    MAXIMO: 30.071674346923828,
    MEDIA: 30.043915077008094,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb8c5e2d-e6e9-43eb-977d-ed17de4526a2',
    CIDADE: 'Campo Grande',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.719606399536133,
    MAXIMO: 30.772531509399414,
    MEDIA: 30.746068954467773,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '382bf91a-41a1-40f9-b3a9-02e0e0e7f5bb',
    CIDADE: 'Baía Formosa',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00400161743164,
    MAXIMO: 30.229095458984375,
    MEDIA: 30.08378900442853,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '05663c89-c395-444e-bbf2-a11e80dd71af',
    CIDADE: 'Baraúna',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.5499210357666,
    MAXIMO: 30.64775276184082,
    MEDIA: 30.598456064860027,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77d493d0-ba3d-4282-9a3e-eb0bcfd288fd',
    CIDADE: 'Barcelona',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.259675979614258,
    MAXIMO: 30.362712860107422,
    MEDIA: 30.26378484605774,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b650872-5961-4ac0-b3eb-0fcf1a66b0e2',
    CIDADE: 'Bento Fernandes',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.04798126220703,
    MAXIMO: 30.228872299194336,
    MEDIA: 30.182608050628705,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bff0fca-6382-4267-bdf3-ef0ad0e14906',
    CIDADE: 'Bodó',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.389686584472656,
    MAXIMO: 30.54888343811035,
    MEDIA: 30.463771274629867,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '21aaf5b5-e0db-4c15-921f-a43c7958f240',
    CIDADE: 'Bom Jesus',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.14202308654785,
    MAXIMO: 30.171836853027344,
    MEDIA: 30.14711027749875,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e94ae29-7f15-431a-8879-3d68ae6f5b26',
    CIDADE: 'Brejinho',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.10666847229004,
    MAXIMO: 30.147254943847656,
    MEDIA: 30.137539190575627,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ef610f9-15f8-4f83-b0d9-6d28774ab8e0',
    CIDADE: 'Caiçara do Norte',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.197065353393555,
    MAXIMO: 30.21363067626953,
    MEDIA: 30.205348014831543,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53b03d4e-3eca-41a4-8e0c-f3629355c2ed',
    CIDADE: 'Caiçara do Rio do Vento',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.228872299194336,
    MAXIMO: 30.28035545349121,
    MEDIA: 30.26701553520598,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10805f43-4d56-4f8b-a1fb-9e0160ef5e60',
    CIDADE: 'Caicó',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.771713256835938,
    MAXIMO: 30.918869018554688,
    MEDIA: 30.84700918197632,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9fcbad1-7fec-46cf-8bf4-b872d1628dc2',
    CIDADE: 'Campo Redondo',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.362712860107422,
    MAXIMO: 30.49260711669922,
    MEDIA: 30.479153595529958,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c32e7564-0e3b-46fb-bec0-233affb5971c',
    CIDADE: 'Canguaretama',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00400161743164,
    MAXIMO: 30.229095458984375,
    MEDIA: 30.083691383943933,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6cc7bd58-7a58-436b-a278-bcd19d15f558',
    CIDADE: 'Caraúbas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.728193283081055,
    MAXIMO: 30.84935188293457,
    MEDIA: 30.789849599202473,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bcec06f8-9ac2-42dc-b7bc-c3a0a086631e',
    CIDADE: 'Carnaúba dos Dantas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.668046951293945,
    MAXIMO: 30.79085350036621,
    MEDIA: 30.72022727681584,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b706d06-8d3f-4d00-bc74-9e94ec49288d',
    CIDADE: 'Carnaubais',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.417856216430664,
    MAXIMO: 30.48625946044922,
    MEDIA: 30.45205783843994,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8d6275d-f777-437c-bec6-0a9d9e698323',
    CIDADE: 'Ceará-Mirim',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.02108383178711,
    MAXIMO: 30.0250186920166,
    MEDIA: 30.023051261901855,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93a5e3bc-6a68-4ab1-b704-63dc0282ac30',
    CIDADE: 'Cerro Corá',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.33689308166504,
    MAXIMO: 30.492563247680664,
    MEDIA: 30.430066346921137,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19d70b46-0f63-4d0a-9b73-7fe797e25a43',
    CIDADE: 'Coronel Ezequiel',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.430866241455078,
    MAXIMO: 30.5600528717041,
    MEDIA: 30.51503755740992,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2720baa1-fb3e-4958-aafb-621b1607edad',
    CIDADE: 'Coronel João Pessoa',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.237398147583008,
    MAXIMO: 31.344070434570312,
    MEDIA: 31.30015327298463,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47c926ec-d6d5-45e5-9b4d-a537494dc3ef',
    CIDADE: 'Cruzeta',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.65676498413086,
    MAXIMO: 30.77359390258789,
    MEDIA: 30.71960255595998,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '355f9376-82ba-4c4d-b74c-bd8771041e38',
    CIDADE: 'Currais Novos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.492563247680664,
    MAXIMO: 30.604049682617188,
    MEDIA: 30.549187978108723,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d599ac0-1703-46ae-b717-dbf04c8aa6fe',
    CIDADE: 'Doutor Severiano',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.237398147583008,
    MAXIMO: 31.33001136779785,
    MEDIA: 31.251670781058618,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4873528f-80d5-4cf8-8f1d-d4ba858406ad',
    CIDADE: 'Parnamirim',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 29.9951171875,
    MAXIMO: 30.029264450073242,
    MEDIA: 30.024479719846152,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b03ff18a-f104-4bee-a8b5-240e8552af3f',
    CIDADE: 'Encanto',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.14198112487793,
    MAXIMO: 31.26795196533203,
    MEDIA: 31.227014786222913,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d2813cb-bdb0-4348-8828-5743f7838ee2',
    CIDADE: 'Equador',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.821273803710938,
    MAXIMO: 30.93120574951172,
    MEDIA: 30.90181063888788,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfd9b04e-e18d-466c-8d56-e2b9c60eea3f',
    CIDADE: 'Espírito Santo',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.04632568359375,
    MAXIMO: 30.22046661376953,
    MEDIA: 30.090726003813874,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbadd504-c5ca-47b7-b323-71b172160980',
    CIDADE: 'Extremoz',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00704002380371,
    MAXIMO: 30.031932830810547,
    MEDIA: 30.008785740125795,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5968ade9-1fe7-47de-a735-e2be9f813c12',
    CIDADE: 'Felipe Guerra',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.680368423461914,
    MAXIMO: 30.80104637145996,
    MEDIA: 30.754027331404718,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '846ba93d-14c7-4b8b-ac2f-7131193adf24',
    CIDADE: 'Fernando Pedroza',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.305095672607422,
    MAXIMO: 30.461233139038086,
    MEDIA: 30.392498458177865,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63f00c6b-af35-4664-ab26-d82b3540a30f',
    CIDADE: 'Florânia',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.605871200561523,
    MAXIMO: 30.709741592407227,
    MEDIA: 30.65738555285854,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75c87ecc-01a4-495a-ab04-3b11704778bb',
    CIDADE: 'Francisco Dantas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.02643394470215,
    MAXIMO: 31.14198112487793,
    MEDIA: 31.096831523634123,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd40569fc-bbcc-477f-8881-26890e778419',
    CIDADE: 'Frutuoso Gomes',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.002883911132812,
    MAXIMO: 31.041093826293945,
    MEDIA: 31.00560874696307,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93cb8c15-ed78-41d7-aa89-66c5ad676c5f',
    CIDADE: 'Galinhos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.197065353393555,
    MAXIMO: 30.251684188842773,
    MEDIA: 30.24421360371471,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8927f742-7b46-41cc-82b8-8b83ac955fb3',
    CIDADE: 'Goianinha',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00400161743164,
    MAXIMO: 30.146833419799805,
    MEDIA: 30.063818916475224,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c889f17-7151-4481-99cd-4ff0efe66325',
    CIDADE: 'Governador Dix-Sept Rosado',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.6286678314209,
    MAXIMO: 30.682737350463867,
    MEDIA: 30.655884742736816,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85de25e1-e145-4146-84a3-a36bec2a70ae',
    CIDADE: 'Grossos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.412643432617188,
    MAXIMO: 30.506105422973633,
    MEDIA: 30.45351898633039,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4c3e532-1bd3-4fea-a2a5-99ccc735fda6',
    CIDADE: 'Guamaré',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.25044059753418,
    MAXIMO: 30.29391098022461,
    MEDIA: 30.28191597564445,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c43d570-5ad7-4629-822b-b5eae8d56fac',
    CIDADE: 'Ielmo Marinho',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.031932830810547,
    MAXIMO: 30.14202308654785,
    MEDIA: 30.108720280363464,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58761b3b-e3fc-4b92-ae65-7ef8aff00c80',
    CIDADE: 'Ipanguaçu',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.413007736206055,
    MAXIMO: 30.52204132080078,
    MEDIA: 30.469247174403964,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '829c73cb-decf-45f0-913e-c734c53240a9',
    CIDADE: 'Ipueira',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.929502487182617,
    MAXIMO: 30.967519760131836,
    MEDIA: 30.96180665467653,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d326194-46fb-4c5c-b021-17ae7814048b',
    CIDADE: 'Itajá',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.471410751342773,
    MAXIMO: 30.563657760620117,
    MEDIA: 30.512512154386172,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e0e051f-90e7-47fd-8edd-8eeb1a62579c',
    CIDADE: 'Itaú',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.862293243408203,
    MAXIMO: 30.979841232299805,
    MEDIA: 30.932341571302178,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7e66208-27e5-45ef-8596-1cd55d9f12b3',
    CIDADE: 'Jaçanã',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.500377655029297,
    MAXIMO: 30.5600528717041,
    MEDIA: 30.553330868824492,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5531894e-e293-4b09-9b23-ca8bd29ef5d9',
    CIDADE: 'Jandaíra',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.21363067626953,
    MAXIMO: 30.278316497802734,
    MEDIA: 30.23900574902675,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d434b7d-915b-44ce-a1ff-b66a81057133',
    CIDADE: 'Janduís',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.772531509399414,
    MAXIMO: 30.882539749145508,
    MEDIA: 30.827829790222673,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3cb57704-5d15-40bc-aa06-80f109716c02',
    CIDADE: 'Januário Cicco',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.171836853027344,
    MAXIMO: 30.2896671295166,
    MEDIA: 30.18393245531413,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9a4f02a-078a-4158-b0ac-e1f1c2fa18b9',
    CIDADE: 'Japi',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.356048583984375,
    MAXIMO: 30.436656951904297,
    MEDIA: 30.433312955480385,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b863ae1-77e1-4d4c-85c5-eba497a5e7a5',
    CIDADE: 'Jardim de Angicos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.187942504882812,
    MAXIMO: 30.28035545349121,
    MEDIA: 30.22822780032106,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '460e1c2c-4778-4a22-bff3-895a25443d57',
    CIDADE: 'Jardim de Piranhas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.772903442382812,
    MAXIMO: 30.91583251953125,
    MEDIA: 30.861521234066323,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92fb557f-7435-40e9-8754-3c4f3f91026c',
    CIDADE: 'Jardim do Seridó',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.79085350036621,
    MAXIMO: 30.83247947692871,
    MEDIA: 30.81166648864746,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '530e8f00-65c0-4ff1-9044-a99164b4305e',
    CIDADE: 'João Câmara',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.187942504882812,
    MAXIMO: 30.202913284301758,
    MEDIA: 30.195427894592285,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd65938ee-707f-454d-b9bc-1c66ea08894f',
    CIDADE: 'João Dias',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.981348037719727,
    MAXIMO: 31.041093826293945,
    MEDIA: 31.036052370081745,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d9ae399-8961-4ddb-bc5b-590a3129499e',
    CIDADE: 'José da Penha',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.18661880493164,
    MAXIMO: 31.27680206298828,
    MEDIA: 31.24516406590331,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f0c8add-038b-41bd-955e-8edbd688726f',
    CIDADE: 'Jucurutu',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.66081428527832,
    MAXIMO: 30.708154678344727,
    MEDIA: 30.684484481811523,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20ec9dc8-fac2-4d97-898c-a2e98f103f28',
    CIDADE: 'Jundiá',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.04632568359375,
    MAXIMO: 30.147254943847656,
    MEDIA: 30.127893223431915,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18ee37f7-537c-49a0-a570-c9df328c62db',
    CIDADE: "Lagoa d'Anta",
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.220035552978516,
    MAXIMO: 30.36178207397461,
    MEDIA: 30.291522884844866,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50d8e417-44a3-4fab-9067-8d8b7b9aae48',
    CIDADE: 'Lagoa de Pedras',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.10666847229004,
    MAXIMO: 30.220035552978516,
    MEDIA: 30.136457757819034,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac6b634c-dfab-4f72-9284-944a23f84de1',
    CIDADE: 'Lagoa de Velhos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.200523376464844,
    MAXIMO: 30.301044464111328,
    MEDIA: 30.253067959284405,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9b61c8a-6a15-4a1a-8ce0-8602a7058ac7',
    CIDADE: 'Lagoa Nova',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.492563247680664,
    MAXIMO: 30.605871200561523,
    MEDIA: 30.53398525960518,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c53cd768-2e18-4e4b-8e66-ed6f67845cd1',
    CIDADE: 'Lagoa Salgada',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.10666847229004,
    MAXIMO: 30.220035552978516,
    MEDIA: 30.150564969993585,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba4f8f53-661b-4725-95ad-658448ca7e80',
    CIDADE: 'Lajes',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.305095672607422,
    MAXIMO: 30.33689308166504,
    MEDIA: 30.32099437713623,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '258eacb3-0f29-4dfc-b1a9-b312fdc6c3c6',
    CIDADE: 'Lajes Pintadas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.362712860107422,
    MAXIMO: 30.43628692626953,
    MEDIA: 30.388593080124412,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aad7724a-8ec2-4718-9f18-d84f157b94b0',
    CIDADE: 'Lucrécia',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.002883911132812,
    MAXIMO: 31.002883911132812,
    MEDIA: 31.002883911132812,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff93b195-75dd-40d0-9b91-f49e4dd567b0',
    CIDADE: 'Luís Gomes',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.26795196533203,
    MAXIMO: 31.359268188476562,
    MEDIA: 31.294999063209993,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4d1b6a5-e5ac-48ec-a935-27c3d740cd48',
    CIDADE: 'Macaíba',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.008745193481445,
    MAXIMO: 30.171836853027344,
    MEDIA: 30.084941474743257,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2900239a-6a4f-4628-b210-4f310bdd671d',
    CIDADE: 'Macau',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.29391098022461,
    MAXIMO: 30.36717414855957,
    MEDIA: 30.329071680704754,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '119a5f80-812b-459e-b901-5e0528ae9f91',
    CIDADE: 'Major Sales',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.27680206298828,
    MAXIMO: 31.27680206298828,
    MEDIA: 31.27680206298828,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ffa700c-938b-416b-8991-46af7188eb58',
    CIDADE: 'Marcelino Vieira',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.067678451538086,
    MAXIMO: 31.215545654296875,
    MEDIA: 31.173888403163676,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e81d1bbe-221c-4faf-a629-088be7535b26',
    CIDADE: 'Martins',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.95829200744629,
    MAXIMO: 31.067678451538086,
    MEDIA: 31.00515848454009,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0d5f00a-4400-4893-87e4-9b34011950a0',
    CIDADE: 'Maxaranguape',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00516128540039,
    MAXIMO: 30.03955841064453,
    MEDIA: 30.014847104613533,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3da121af-5961-4b64-89b9-879adebac3f8',
    CIDADE: 'Messias Targino',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.772531509399414,
    MAXIMO: 30.882539749145508,
    MEDIA: 30.863030821668517,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c44747d7-f8cb-48a5-9ab4-c6ec4723c11a',
    CIDADE: 'Montanhas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.146833419799805,
    MAXIMO: 30.32777976989746,
    MEDIA: 30.184132767960453,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94b1a654-4ebd-4902-994e-576c69d2d925',
    CIDADE: 'Monte Alegre',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.04632568359375,
    MAXIMO: 30.171836853027344,
    MEDIA: 30.10843814226706,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dbe04c77-1cfa-4f2b-934a-fe65d50aec9a',
    CIDADE: 'Monte das Gameleiras',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.36178207397461,
    MAXIMO: 30.436656951904297,
    MEDIA: 30.387226964277698,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7e2d8ba9-cc15-4ae7-8e86-3a58bd225bf1',
    CIDADE: 'Mossoró',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.506105422973633,
    MAXIMO: 30.583072662353516,
    MEDIA: 30.54374408721924,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '466ed2b9-bc49-4105-9a98-fb7f9175135f',
    CIDADE: 'Natal',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 29.9951171875,
    MAXIMO: 30.029264450073242,
    MEDIA: 30.009749370814525,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb78bee9-1abe-4485-968d-79cc5755b151',
    CIDADE: 'Nísia Floresta',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 29.9951171875,
    MAXIMO: 30.04632568359375,
    MEDIA: 30.021740122869755,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75516742-d866-4cc3-9693-186d8fc58012',
    CIDADE: 'Nova Cruz',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.146833419799805,
    MAXIMO: 30.32777976989746,
    MEDIA: 30.232540965878062,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fcda33c-a77c-4ad3-81a0-89aa0a3089f3',
    CIDADE: "Olho d'Água do Borges",
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.897249221801758,
    MAXIMO: 30.940757751464844,
    MEDIA: 30.89988251540048,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1112f15f-7b06-42a2-af74-70882c783edc',
    CIDADE: 'Ouro Branco',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.83247947692871,
    MAXIMO: 30.929502487182617,
    MEDIA: 30.868972692667654,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dde7bb78-7531-47af-b78b-7667f28f14c6',
    CIDADE: 'Paraná',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.215545654296875,
    MAXIMO: 31.27680206298828,
    MEDIA: 31.259546802858335,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30821ea0-6f52-40d9-95de-4f69abc2b5a0',
    CIDADE: 'Paraú',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.615711212158203,
    MAXIMO: 30.6610164642334,
    MEDIA: 30.6383638381958,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b418e7e5-48a3-4e21-8b3c-94ab02bdb20d',
    CIDADE: 'Parazinho',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.07012939453125,
    MAXIMO: 30.232633590698242,
    MEDIA: 30.109874184070936,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '324e696f-2f74-4049-834b-1242a2a6bdbc',
    CIDADE: 'Parelhas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.73705291748047,
    MAXIMO: 30.821273803710938,
    MEDIA: 30.779163360595703,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea04fc1a-a0d6-4c11-b88f-314c1d97f0e3',
    CIDADE: 'Rio do Fogo',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00516128540039,
    MAXIMO: 30.02108383178711,
    MEDIA: 30.019939693914488,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b18c340-1cfb-4a53-9599-a54d5bcf78b2',
    CIDADE: 'Passa e Fica',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.292713165283203,
    MAXIMO: 30.36178207397461,
    MEDIA: 30.29865630958696,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a8a728cc-0e70-4185-a251-9a2dacb06d6f',
    CIDADE: 'Passagem',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.147254943847656,
    MAXIMO: 30.147254943847656,
    MEDIA: 30.147254943847656,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '474ca59f-1b1a-41ea-ac4b-b758e9ed1d52',
    CIDADE: 'Patu',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.837970733642578,
    MAXIMO: 30.940757751464844,
    MEDIA: 30.90505507129669,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de919b2e-5160-4de8-871e-33b66f953020',
    CIDADE: 'Santa Maria',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.123952865600586,
    MAXIMO: 30.200523376464844,
    MEDIA: 30.173062948599785,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ccbfcbd5-1527-4262-b437-1a9cc630e675',
    CIDADE: 'Pau dos Ferros',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.067678451538086,
    MAXIMO: 31.237398147583008,
    MEDIA: 31.126401527294234,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f4656d6-abfd-41d2-9eac-04052db87a12',
    CIDADE: 'Pedra Grande',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.07012939453125,
    MAXIMO: 30.16053581237793,
    MEDIA: 30.153570837982198,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8f8c3b4-d50e-4e5c-b66d-689bee47bbc4',
    CIDADE: 'Pedra Preta',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.232633590698242,
    MAXIMO: 30.255277633666992,
    MEDIA: 30.243955612182617,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c090ae49-7698-4140-8f15-3483f31c774a',
    CIDADE: 'Pedro Avelino',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.278316497802734,
    MAXIMO: 30.32109260559082,
    MEDIA: 30.299704551696777,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87c3b459-1d25-4997-9de7-7ce76f5f4f19',
    CIDADE: 'Pedro Velho',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.086978912353516,
    MAXIMO: 30.269126892089844,
    MEDIA: 30.157621643135894,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01869b32-f1c9-4dfb-b370-da0d5c893979',
    CIDADE: 'Pendências',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.336498260498047,
    MAXIMO: 30.375314712524414,
    MEDIA: 30.35590648651123,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ec5c7f9-7861-4a9d-8b60-dee3007e4f98',
    CIDADE: 'Pilões',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.105987548828125,
    MAXIMO: 31.105987548828125,
    MEDIA: 31.105987548828125,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '404a9c8d-4d10-4b37-aa08-4c1b03c6c95e',
    CIDADE: 'Poço Branco',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.057004928588867,
    MAXIMO: 30.15777587890625,
    MEDIA: 30.10739040374756,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a61fdc3-8042-4eb8-850f-4e4f6c4fc337',
    CIDADE: 'Portalegre',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.02643394470215,
    MAXIMO: 31.067678451538086,
    MEDIA: 31.04697778653827,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '331734d4-6fca-4573-a20f-8d8aef41851f',
    CIDADE: 'Porto do Mangue',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.33741569519043,
    MAXIMO: 30.451457977294922,
    MEDIA: 30.389968120920887,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a724a02-48a7-42cb-8f4a-5b8ab0935aed',
    CIDADE: 'Serra Caiada',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.171836853027344,
    MAXIMO: 30.301044464111328,
    MEDIA: 30.22762427964102,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5dd55cc2-6e4f-4b50-a401-2c744818395a',
    CIDADE: 'Pureza',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.018768310546875,
    MAXIMO: 30.057004928588867,
    MEDIA: 30.03776174349544,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59cac05d-2eee-430b-9f78-540cac41f3b2',
    CIDADE: 'Rafael Fernandes',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.14198112487793,
    MAXIMO: 31.26795196533203,
    MEDIA: 31.164013348116228,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd200bb5-f269-4c0e-b1e2-2ff19dba345b',
    CIDADE: 'Rafael Godeiro',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.897249221801758,
    MAXIMO: 31.002883911132812,
    MEDIA: 30.93564299416879,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40b040c6-620e-4680-b6f8-37a4edd0efb8',
    CIDADE: 'Riacho da Cruz',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.91209602355957,
    MAXIMO: 31.02643394470215,
    MEDIA: 31.00282181890039,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44c07c5a-17b4-46b2-81ff-0253e91f7efd',
    CIDADE: 'Riacho de Santana',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.18661880493164,
    MAXIMO: 31.26795196533203,
    MEDIA: 31.26167047348569,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4232dc97-3bf8-488f-aee4-9106a011ae5d',
    CIDADE: 'Riachuelo',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.175342559814453,
    MAXIMO: 30.259675979614258,
    MEDIA: 30.216852935829877,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54ca42b0-6ef8-41bf-acbd-da9637348c4a',
    CIDADE: 'Rodolfo Fernandes',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.979841232299805,
    MAXIMO: 31.10163688659668,
    MEDIA: 31.01728566196195,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1f00238-eaef-4bf8-bfe2-1fcbff2032d3',
    CIDADE: 'Tibau',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.412643432617188,
    MAXIMO: 30.506105422973633,
    MEDIA: 30.46091735779512,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c97fe52d-fad7-4a8e-bc1c-743a7c7718aa',
    CIDADE: 'Ruy Barbosa',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.228872299194336,
    MAXIMO: 30.318113327026367,
    MEDIA: 30.24561064754215,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49387de2-2986-491f-aa5c-013c48f249e3',
    CIDADE: 'Santa Cruz',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.356048583984375,
    MAXIMO: 30.430866241455078,
    MEDIA: 30.383209228515625,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2a37d9c-630e-4bee-a0d9-c9b4e8c44c04',
    CIDADE: 'Santana do Matos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.461233139038086,
    MAXIMO: 30.55777931213379,
    MEDIA: 30.508461952209473,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '528c7de1-bcf6-4dbf-85cb-b05f4a824f00',
    CIDADE: 'Santana do Seridó',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.79085350036621,
    MAXIMO: 30.895185470581055,
    MEDIA: 30.85531065375064,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7288f26d-4bc0-4748-bbf0-db7cdea69673',
    CIDADE: 'Santo Antônio',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.147254943847656,
    MAXIMO: 30.292713165283203,
    MEDIA: 30.209008537096103,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24db2f2c-57ae-49b6-abf4-52ebfaf783f3',
    CIDADE: 'São Bento do Norte',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.07012939453125,
    MAXIMO: 30.21363067626953,
    MEDIA: 30.17819155194057,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98c94679-394b-44f3-87f0-9c63d935ec7e',
    CIDADE: 'São Bento do Trairí',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.430866241455078,
    MAXIMO: 30.500377655029297,
    MEDIA: 30.47252937217781,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fbe649b3-d78b-49d5-ab24-2c79a9d0921f',
    CIDADE: 'São Fernando',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.771713256835938,
    MAXIMO: 30.869659423828125,
    MEDIA: 30.821616362727827,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65bfd0e9-3c07-4a53-a7a2-4be92c3b0139',
    CIDADE: 'São Francisco do Oeste',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.10163688659668,
    MAXIMO: 31.14198112487793,
    MEDIA: 31.102218521287234,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d39cd02-a50c-4977-bddd-0e7eecd1581b',
    CIDADE: 'São Gonçalo do Amarante',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00704002380371,
    MAXIMO: 30.031932830810547,
    MEDIA: 30.024503051184627,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9d7a8f16-c24a-4ceb-a45f-200240d70e13',
    CIDADE: 'São João do Sabugi',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.874473571777344,
    MAXIMO: 31.003877639770508,
    MEDIA: 30.94071326703617,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9122ecde-ce34-494e-8402-4634bb57e228',
    CIDADE: 'São José de Mipibu',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.029264450073242,
    MAXIMO: 30.10666847229004,
    MEDIA: 30.04792300803278,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85defc18-1781-42a0-80b4-d23af36e440c',
    CIDADE: 'São José do Campestre',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.220035552978516,
    MAXIMO: 30.436656951904297,
    MEDIA: 30.30566721581614,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d44f659-ad3c-45ea-aa31-10c189a86bf9',
    CIDADE: 'São José do Seridó',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.71575355529785,
    MAXIMO: 30.83247947692871,
    MEDIA: 30.77060723266354,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55f94839-49b6-4a7c-9c37-6dd010dca375',
    CIDADE: 'São Miguel',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.237398147583008,
    MAXIMO: 31.344070434570312,
    MEDIA: 31.322809263142823,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a33dcff1-43cc-449e-81ed-656605ad2d82',
    CIDADE: 'São Miguel do Gostoso',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.030269622802734,
    MAXIMO: 30.16053581237793,
    MEDIA: 30.097241235918805,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1ee009a7-925b-490f-9b7f-274de2e68c42',
    CIDADE: 'São Paulo do Potengi',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.175342559814453,
    MAXIMO: 30.259675979614258,
    MEDIA: 30.202548241978118,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69491e9a-8baf-4feb-acf8-c1eaba46e0d4',
    CIDADE: 'São Pedro',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.123952865600586,
    MAXIMO: 30.200523376464844,
    MEDIA: 30.151103551908324,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6915c8c4-bae0-4860-9ae7-8ce0c717143a',
    CIDADE: 'São Rafael',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.563657760620117,
    MAXIMO: 30.60830307006836,
    MEDIA: 30.58598041534424,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55c8a324-a268-473c-affa-d50037350af9',
    CIDADE: 'São Tomé',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.318113327026367,
    MAXIMO: 30.43628692626953,
    MEDIA: 30.375710169474285,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8e411fb-4b35-4fe8-a11e-4f30c562ace7',
    CIDADE: 'São Vicente',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.54888343811035,
    MAXIMO: 30.65676498413086,
    MEDIA: 30.621190552480464,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9b43603-7187-4dc2-b93c-3abb1b550756',
    CIDADE: 'Senador Elói de Souza',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.14202308654785,
    MAXIMO: 30.23716163635254,
    MEDIA: 30.20143559465014,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0954288e-fc9a-4e71-a288-c66090f73214',
    CIDADE: 'Senador Georgino Avelino',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.0009708404541,
    MAXIMO: 30.0009708404541,
    MEDIA: 30.000970840454098,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8553b234-4640-42fb-b352-99b8f369da3b',
    CIDADE: 'Serra de São Bento',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.2896671295166,
    MAXIMO: 30.36178207397461,
    MEDIA: 30.361764279747472,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bccae22c-9ad6-4d21-a036-2d2e8e606aef',
    CIDADE: 'Serra do Mel',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.37459373474121,
    MAXIMO: 30.535974502563477,
    MEDIA: 30.45295427842359,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70116758-107d-4323-ab4a-ce1bb7d32d07',
    CIDADE: 'Serra Negra do Norte',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.91583251953125,
    MAXIMO: 30.95989418029785,
    MEDIA: 30.93786334991455,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3da6d18c-eabb-42b8-998c-2c460749413c',
    CIDADE: 'Serrinha',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.147254943847656,
    MAXIMO: 30.2896671295166,
    MEDIA: 30.214358327523943,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a461ed3-f5be-4812-9a99-943e29b401b1',
    CIDADE: 'Serrinha dos Pintos',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.002883911132812,
    MAXIMO: 31.105987548828125,
    MEDIA: 31.069324906471678,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47bf0526-289e-4f1f-9514-67113daa2a04',
    CIDADE: 'Severiano Melo',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.862293243408203,
    MAXIMO: 30.979841232299805,
    MEDIA: 30.96050629143891,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59ce1a13-93ea-466b-ada0-4978fe56fa55',
    CIDADE: 'Sítio Novo',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.259675979614258,
    MAXIMO: 30.362712860107422,
    MEDIA: 30.303693693068787,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af8de01f-157a-4707-bbe0-20faac9c772c',
    CIDADE: 'Taboleiro Grande',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.979841232299805,
    MAXIMO: 31.10163688659668,
    MEDIA: 31.026166339464186,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f1a3c11-24af-4807-bb4a-17a12861b029',
    CIDADE: 'Taipu',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.0250186920166,
    MAXIMO: 30.15777587890625,
    MEDIA: 30.048590304474743,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b128be8-dbbd-44eb-a897-ede2e450e8e1',
    CIDADE: 'Tangará',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.23716163635254,
    MAXIMO: 30.436656951904297,
    MEDIA: 30.309869399937195,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a810ad1f-8d99-48af-9f3e-8ebe65ccf02f',
    CIDADE: 'Tenente Ananias',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.146549224853516,
    MAXIMO: 31.215545654296875,
    MEDIA: 31.20979629473011,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3008076d-8a1b-4422-a94a-18e3ef7d08a5',
    CIDADE: 'Tenente Laurentino Cruz',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.605871200561523,
    MAXIMO: 30.65676498413086,
    MEDIA: 30.613440547588155,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4107ec45-0c7a-4a30-8ea2-990b88f0530e',
    CIDADE: 'Tibau do Sul',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.0009708404541,
    MAXIMO: 30.00400161743164,
    MEDIA: 30.00387505769085,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35aaadc1-5936-4438-b2d9-9d053aefab8b',
    CIDADE: 'Timbaúba dos Batistas',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.869659423828125,
    MAXIMO: 30.918869018554688,
    MEDIA: 30.880314424853246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5706b601-65ae-4541-9263-1ffbb3075558',
    CIDADE: 'Touros',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.018768310546875,
    MAXIMO: 30.095129013061523,
    MEDIA: 30.048922538757324,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7fa98aa5-abe7-4cf4-ba92-fab57d2c5c56',
    CIDADE: 'Triunfo Potiguar',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.66081428527832,
    MAXIMO: 30.7133846282959,
    MEDIA: 30.691370234176556,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4063040-14ec-4347-9929-554ba6bfa147',
    CIDADE: 'Umarizal',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.897249221801758,
    MAXIMO: 31.002883911132812,
    MEDIA: 30.961165638876896,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8166208d-1db9-42fe-8484-a26d591e1a73',
    CIDADE: 'Upanema',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.617128372192383,
    MAXIMO: 30.67556381225586,
    MEDIA: 30.64634609222412,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e5cb4f2e-900c-408c-8088-6dff626ea9cc',
    CIDADE: 'Várzea',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.146833419799805,
    MAXIMO: 30.22046661376953,
    MEDIA: 30.180237308953085,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c60e7821-f214-4c1c-85ef-8fa2bde6a250',
    CIDADE: 'Venha-Ver',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 31.344070434570312,
    MAXIMO: 31.344070434570312,
    MEDIA: 31.344070434570312,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3551e292-0517-452a-b628-4fe08e27d281',
    CIDADE: 'Vera Cruz',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.08670997619629,
    MAXIMO: 30.171836853027344,
    MEDIA: 30.103645270178138,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0724d173-d877-45dd-9be5-abd2c3ce7514',
    CIDADE: 'Viçosa',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.95829200744629,
    MAXIMO: 31.02643394470215,
    MEDIA: 31.010491549135324,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '001e8469-a9b3-4578-b663-51beb5e7931d',
    CIDADE: 'Vila Flor',
    SIGLA: 'RN',
    ESTADO: 'Rio Grande do Norte',
    MINIMO: 30.00400161743164,
    MAXIMO: 30.00400161743164,
    MEDIA: 30.004001617431644,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8431949b-3902-4b03-b567-0405420c65f9',
    CIDADE: 'Água Branca',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.0733699798584,
    MAXIMO: 31.0836181640625,
    MEDIA: 31.075680467690503,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aaac0e6e-ade8-42e8-90bf-cb4644643e7f',
    CIDADE: 'Aguiar',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.161643981933594,
    MAXIMO: 31.287368774414062,
    MEDIA: 31.213103276105308,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81a39e91-71a5-4fa9-8bf6-279d9fb52d8b',
    CIDADE: 'Alagoa Grande',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.66459083557129,
    MAXIMO: 30.860382080078125,
    MEDIA: 30.80559370776892,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25b0ca64-f7b4-4081-bd3d-39f02b34af44',
    CIDADE: 'Alagoa Nova',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.860382080078125,
    MAXIMO: 30.89064598083496,
    MEDIA: 30.86406200407345,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4140d07d-2fcc-4da0-a0ad-9829748fb071',
    CIDADE: 'Alagoinha',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.620332717895508,
    MAXIMO: 30.827716827392578,
    MEDIA: 30.661368765234236,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d7a63f4-0943-4b84-b9df-193909845e5f',
    CIDADE: 'Alcantil',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.33853530883789,
    MAXIMO: 31.530866622924805,
    MEDIA: 31.42593910970138,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c51deb1d-8997-4d68-bcf5-61b23f0200b8',
    CIDADE: 'Algodão de Jandaíra',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.631467819213867,
    MAXIMO: 30.794519424438477,
    MEDIA: 30.745864807269623,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '057861ca-f72e-4b95-abc8-c0c6d2589963',
    CIDADE: 'Alhandra',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.909318923950195,
    MAXIMO: 31.131059646606445,
    MEDIA: 31.027732992613505,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2fe65c1b-5bcb-4684-a731-c81d9d8936c0',
    CIDADE: 'São João do Rio do Peixe',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.287368774414062,
    MAXIMO: 31.362064361572266,
    MEDIA: 31.316504327066696,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9b8ee6f-dae4-4018-8ead-c105f6cc38e9',
    CIDADE: 'Amparo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.14661979675293,
    MAXIMO: 31.18971061706543,
    MEDIA: 31.189532837858806,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45da437e-0abb-4377-891c-fdfdc9ba0978',
    CIDADE: 'Aparecida',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.154720306396484,
    MAXIMO: 31.206579208374023,
    MEDIA: 31.16671398635392,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e7c7a74-8af5-477f-a611-b7507225fe60',
    CIDADE: 'Araçagi',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.41490936279297,
    MAXIMO: 30.620332717895508,
    MEDIA: 30.53099366779129,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'be7207b4-938d-4137-a695-56f3dc425dfe',
    CIDADE: 'Arara',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.57657814025879,
    MAXIMO: 30.71001625061035,
    MEDIA: 30.660237936808887,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7befd1f9-2233-40c4-a40b-2dce23f06f3f',
    CIDADE: 'Araruna',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.292713165283203,
    MAXIMO: 30.52528190612793,
    MEDIA: 30.419985038390955,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae086c70-2bfb-438d-adf6-db85ba06667e',
    CIDADE: 'Areia',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.66459083557129,
    MAXIMO: 30.860382080078125,
    MEDIA: 30.711723833229104,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '21aeffa6-8b96-4d9b-b7c2-3cc23f01e13c',
    CIDADE: 'Areia de Baraúnas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.022306442260742,
    MAXIMO: 31.091678619384766,
    MEDIA: 31.03203162855505,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1057568b-118d-4bcb-92d9-174c1e1ad4a1',
    CIDADE: 'Areial',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.89064598083496,
    MAXIMO: 30.89064598083496,
    MEDIA: 30.890645980834964,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3b17818-e9d1-4e7d-96a3-5fed8b2f478f',
    CIDADE: 'Aroeiras',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.202177047729492,
    MAXIMO: 31.4104061126709,
    MEDIA: 31.343040916839232,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2524b12-7f62-47d3-a2d0-2581c1c96075',
    CIDADE: 'Assunção',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.93120574951172,
    MAXIMO: 31.005422592163086,
    MEDIA: 31.004055730670963,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc57a7ff-f212-4f15-bf48-da21ff7053c9',
    CIDADE: 'Baía da Traição',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.221162796020508,
    MAXIMO: 30.382247924804688,
    MEDIA: 30.267187589785365,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb44baa6-1b00-46d9-9a4f-806a1b7643b7',
    CIDADE: 'Bananeiras',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.391618728637695,
    MAXIMO: 30.57657814025879,
    MEDIA: 30.47251855564474,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f945a1c-d0c7-438a-a414-49f115972b62',
    CIDADE: 'Baraúna',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.63922882080078,
    MAXIMO: 30.729949951171875,
    MEDIA: 30.640343379195798,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '912bd6c3-2d40-4bd3-b9f1-56aec7ace060',
    CIDADE: 'Barra de Santana',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.33853530883789,
    MAXIMO: 31.36871910095215,
    MEDIA: 31.35362720489502,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7fc035b-a778-4c29-b651-fc9f2b2fe41b',
    CIDADE: 'Barra de Santa Rosa',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.631467819213867,
    MAXIMO: 30.68274688720703,
    MEDIA: 30.65710735321045,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0c410b01-8029-4c9c-97d5-2536907bd862',
    CIDADE: 'Barra de São Miguel',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.30107307434082,
    MAXIMO: 31.413257598876953,
    MEDIA: 31.3589661916097,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c22f224-3edb-4011-8f9d-d1dcb1d1a9b1',
    CIDADE: 'Bayeux',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.71014976501465,
    MAXIMO: 30.71014976501465,
    MEDIA: 30.71014976501465,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d3f6053-f85a-4f38-9ecf-1328eeb411f2',
    CIDADE: 'Belém',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.32777976989746,
    MAXIMO: 30.520158767700195,
    MEDIA: 30.44773790488895,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41fa0d61-6b03-4446-9dbe-7305939416e3',
    CIDADE: 'Belém do Brejo do Cruz',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.772903442382812,
    MAXIMO: 30.826416015625,
    MEDIA: 30.799659729003906,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '476173e3-03bb-4b02-85ca-b2d9f410bed7',
    CIDADE: 'Bernardino Batista',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.359268188476562,
    MAXIMO: 31.450368881225586,
    MEDIA: 31.366841171251895,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1883b6bc-8c16-4218-b989-01795a4f35c9',
    CIDADE: 'Boa Ventura',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.14771270751953,
    MAXIMO: 31.167638778686523,
    MEDIA: 31.167581515341602,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '593e96cb-63fd-4c0c-8fc4-0d6c1afd3e93',
    CIDADE: 'Boa Vista',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.917560577392578,
    MAXIMO: 31.199085235595703,
    MEDIA: 31.075220651600873,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c57d4df6-707a-4658-a414-3e39cb7afe51',
    CIDADE: 'Bom Jesus',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.362064361572266,
    MAXIMO: 31.432382583618164,
    MEDIA: 31.424209092894497,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c94eb596-4daf-4512-9a33-af2bf5a3b428',
    CIDADE: 'Bom Sucesso',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.074766159057617,
    MAXIMO: 31.170093536376953,
    MEDIA: 31.115704618111778,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e220774-22ec-40ed-888c-86708be939b2',
    CIDADE: 'Bonito de Santa Fé',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.226552963256836,
    MAXIMO: 31.292932510375977,
    MEDIA: 31.281928011995515,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'be2a4cb8-0046-43d8-ac9d-f76a203bb8e4',
    CIDADE: 'Boqueirão',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.182863235473633,
    MAXIMO: 31.33853530883789,
    MEDIA: 31.256808536034303,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33ddf8d7-f157-4a69-ad03-7c44d26cf162',
    CIDADE: 'Igaracy',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.16079330444336,
    MAXIMO: 31.198156356811523,
    MEDIA: 31.181688040197336,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43568055-44e5-43d6-9464-18251e8acb84',
    CIDADE: 'Borborema',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.520158767700195,
    MAXIMO: 30.520158767700195,
    MEDIA: 30.520158767700195,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd34653b5-b632-4fbb-9b66-2bc555295bd9',
    CIDADE: 'Brejo do Cruz',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.87140655517578,
    MAXIMO: 30.964990615844727,
    MEDIA: 30.921511849288354,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb9a7474-baf8-4489-be59-5e02db1c409c',
    CIDADE: 'Brejo dos Santos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.041093826293945,
    MAXIMO: 31.074766159057617,
    MEDIA: 31.07204237508789,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b24743f-e6c3-4100-83c9-71e166f1fd88',
    CIDADE: 'Caaporã',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.131059646606445,
    MAXIMO: 31.37444496154785,
    MEDIA: 31.218064645593255,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b4ede74-8186-4ab7-85c5-dffc2d341ece',
    CIDADE: 'Cabaceiras',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.171794891357422,
    MAXIMO: 31.182863235473633,
    MEDIA: 31.177329063415527,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '137f3823-8dcc-41e8-8a84-855a7b5bd022',
    CIDADE: 'Cabedelo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.518205642700195,
    MAXIMO: 30.71014976501465,
    MEDIA: 30.63566724323532,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f62b06a5-046d-4171-b528-f8fd7cbb93d9',
    CIDADE: 'Cachoeira dos Índios',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.382709503173828,
    MAXIMO: 31.491201400756836,
    MEDIA: 31.413760737384056,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd224df4-9b27-4493-9301-95d3254f7dd0',
    CIDADE: 'Cacimba de Areia',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.0394229888916,
    MAXIMO: 31.10702896118164,
    MEDIA: 31.064354878276838,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a931200-17d2-4e53-a15a-a6239efcaa6e',
    CIDADE: 'Cacimba de Dentro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.462444305419922,
    MAXIMO: 30.57657814025879,
    MEDIA: 30.491936264668258,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d775aad-607e-47cd-bea4-76e581dd9095',
    CIDADE: 'Cacimbas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.0394229888916,
    MAXIMO: 31.10702896118164,
    MEDIA: 31.091051199792194,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2af94a1f-fd54-415e-9670-1318ab9534f7',
    CIDADE: 'Caiçara',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.22046661376953,
    MAXIMO: 30.391618728637695,
    MEDIA: 30.318279588317406,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c69efca-4b7c-4a14-bddc-f224c0da71a2',
    CIDADE: 'Cajazeiras',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.287368774414062,
    MAXIMO: 31.432382583618164,
    MEDIA: 31.360266409858816,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7c3acd7-0bd1-4ac8-8c83-118f7feb81e0',
    CIDADE: 'Cajazeirinhas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.054513931274414,
    MAXIMO: 31.13302993774414,
    MEDIA: 31.119775236625475,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '68e56f93-59e6-4765-8c39-445042ca6d67',
    CIDADE: 'Caldas Brandão',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.76300048828125,
    MAXIMO: 30.986780166625977,
    MEDIA: 30.796404938226217,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c48b1470-2539-4148-bae8-6fd4dd1d624c',
    CIDADE: 'Camalaú',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.240478515625,
    MAXIMO: 31.3372745513916,
    MEDIA: 31.2888765335083,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5286cc6a-01c3-469b-bbe5-5785df34f1d3',
    CIDADE: 'Campina Grande',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.03934097290039,
    MAXIMO: 31.061107635498047,
    MEDIA: 31.05022430419922,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7cd07b9-9104-48f0-a184-5d69d162f5e3',
    CIDADE: 'Capim',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.41490936279297,
    MAXIMO: 30.578454971313477,
    MEDIA: 30.564237436686962,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c70f1513-70bf-4f6e-8319-872cda746888',
    CIDADE: 'Caraúbas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.24335479736328,
    MAXIMO: 31.441749572753906,
    MEDIA: 31.345562670253063,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9415021f-ee21-4db6-83d4-e0ba6a8b4b05',
    CIDADE: 'Carrapateira',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.271116256713867,
    MAXIMO: 31.287368774414062,
    MEDIA: 31.273271666436315,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae880f0a-c35a-4346-abe1-d82872d7350e',
    CIDADE: 'Casserengue',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.462444305419922,
    MAXIMO: 30.75377082824707,
    MEDIA: 30.613282222586022,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9864953-7ffc-4e1b-9974-1038393c957e',
    CIDADE: 'Catingueira',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.06165885925293,
    MAXIMO: 31.112548828125,
    MEDIA: 31.087103843688965,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f726416-292d-43ac-87e8-e39a41b22e7f',
    CIDADE: 'Catolé do Rocha',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.882539749145508,
    MAXIMO: 31.074766159057617,
    MEDIA: 30.996713732873705,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc124d1d-a949-4ba6-bc8e-028f649d7837',
    CIDADE: 'Caturité',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.199085235595703,
    MAXIMO: 31.199085235595703,
    MEDIA: 31.199085235595703,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b6111e7-e4a9-499d-9782-d93b52a51f0e',
    CIDADE: 'Conceição',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.213151931762695,
    MAXIMO: 31.253759384155273,
    MEDIA: 31.233455657958984,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18b6f5f2-8768-429e-b407-b358564e2307',
    CIDADE: 'Condado',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.039127349853516,
    MAXIMO: 31.11380958557129,
    MEDIA: 31.09019235223846,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '565a8252-90a5-41f3-995a-684d1f72b5bc',
    CIDADE: 'Conde',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.878814697265625,
    MAXIMO: 31.131059646606445,
    MEDIA: 30.912975690208857,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '837b3069-7905-476d-915a-c65b04ba12a8',
    CIDADE: 'Congo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.289440155029297,
    MAXIMO: 31.388866424560547,
    MEDIA: 31.322437770741406,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31a4b4a6-9dfb-4eb7-b06c-91440c4a5b22',
    CIDADE: 'Coremas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.13302993774414,
    MAXIMO: 31.161643981933594,
    MEDIA: 31.147336959838867,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eed5f5c4-778d-470a-ae91-4e09564cfaac',
    CIDADE: 'Coxixola',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.219711303710938,
    MAXIMO: 31.317611694335938,
    MEDIA: 31.25595653825158,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50e1ecc2-fd69-4988-a39e-902fb2d533b1',
    CIDADE: 'Cruz do Espírito Santo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.733179092407227,
    MAXIMO: 30.963499069213867,
    MEDIA: 30.802064868316517,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88b12dc8-ad86-4275-b650-68603d5e7322',
    CIDADE: 'Cubati',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.729949951171875,
    MAXIMO: 30.87162971496582,
    MEDIA: 30.81735715050789,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1ccd38d9-12b1-412e-85c8-ebc5601984c7',
    CIDADE: 'Cuité',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.500377655029297,
    MAXIMO: 30.63922882080078,
    MEDIA: 30.57465426127116,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb8b032a-3414-4d5a-a66a-9cc903ec1ba7',
    CIDADE: 'Cuitegi',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.620332717895508,
    MAXIMO: 30.66459083557129,
    MEDIA: 30.659385570663353,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c87b47d4-f937-4a75-837d-d66390c6db27',
    CIDADE: 'Cuité de Mamanguape',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.41490936279297,
    MAXIMO: 30.578454971313477,
    MEDIA: 30.577996422409296,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfefa934-95b8-4f2f-853b-8b12091d9ad2',
    CIDADE: 'Curral de Cima',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.41490936279297,
    MAXIMO: 30.461484909057617,
    MEDIA: 30.422132973539824,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c861046-370c-42a1-b091-00911ba49a8c',
    CIDADE: 'Curral Velho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.139625549316406,
    MAXIMO: 31.167638778686523,
    MEDIA: 31.15250644537673,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a2b8a2e-76bc-4577-be6b-ba754604bab2',
    CIDADE: 'Damião',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.52528190612793,
    MAXIMO: 30.68274688720703,
    MEDIA: 30.54408200994026,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '632c1543-63da-444f-b73d-41a07ab00d14',
    CIDADE: 'Desterro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.091678619384766,
    MAXIMO: 31.14661979675293,
    MEDIA: 31.102905112105958,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31097d1c-7992-41b2-8bfb-bf4c96aa403c',
    CIDADE: 'Vista Serrana',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.039127349853516,
    MAXIMO: 31.089216232299805,
    MEDIA: 31.04071746903147,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf86f41f-188b-4265-b38e-c5986027cfd0',
    CIDADE: 'Diamante',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.15030288696289,
    MAXIMO: 31.251827239990234,
    MEDIA: 31.214162699593057,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42551a07-94fb-4c73-8987-410dab253bec',
    CIDADE: 'Dona Inês',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.391618728637695,
    MAXIMO: 30.462444305419922,
    MEDIA: 30.420728750174888,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b240f4de-3dcd-4c1a-b221-de045fbb30ce',
    CIDADE: 'Duas Estradas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.32777976989746,
    MAXIMO: 30.461484909057617,
    MEDIA: 30.456836485587733,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60da973c-5797-4baf-9cca-27615b76cb6a',
    CIDADE: 'Emas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.054513931274414,
    MAXIMO: 31.13302993774414,
    MEDIA: 31.074892513485434,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8030f420-892a-4732-bd17-e8f2834fcb94',
    CIDADE: 'Esperança',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.71001625061035,
    MAXIMO: 30.89064598083496,
    MEDIA: 30.795767814833784,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c8f6edb-2a36-4fba-8b38-5d1477ff0e01',
    CIDADE: 'Fagundes',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.025310516357422,
    MAXIMO: 31.206342697143555,
    MEDIA: 31.184407406434424,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81b1733a-0b61-4d67-9af2-71fa6a41d4e7',
    CIDADE: 'Frei Martinho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.55095100402832,
    MAXIMO: 30.668046951293945,
    MEDIA: 30.62327425940622,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e15124ac-950d-497c-95a4-e7711478ac21',
    CIDADE: 'Gado Bravo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.206342697143555,
    MAXIMO: 31.530866622924805,
    MEDIA: 31.378999193457673,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e99404e-ac11-42db-8921-8244c33f8ba0',
    CIDADE: 'Guarabira',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.461484909057617,
    MAXIMO: 30.66459083557129,
    MEDIA: 30.587499063824097,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd584d385-580e-40b9-970b-0eeea7721531',
    CIDADE: 'Gurinhém',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.79447364807129,
    MAXIMO: 31.007638931274414,
    MEDIA: 30.862462567262227,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e991b5d-fd92-4582-bc3e-95ce8542015a',
    CIDADE: 'Gurjão',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.070140838623047,
    MAXIMO: 31.07516098022461,
    MEDIA: 31.072650909423828,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b63674a-f62f-42fb-8f52-e6119b9ab0d2',
    CIDADE: 'Ibiara',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.192888259887695,
    MAXIMO: 31.253759384155273,
    MEDIA: 31.220201289154655,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27391ea8-7bd4-4598-909a-d64e68779584',
    CIDADE: 'Imaculada',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.06165885925293,
    MAXIMO: 31.118209838867188,
    MEDIA: 31.07806169855772,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74cb6a92-d845-4295-88bd-273e83e2018a',
    CIDADE: 'Ingá',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.827716827392578,
    MAXIMO: 31.202556610107422,
    MEDIA: 31.026617845534325,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b9aaed34-57ab-4027-b36f-82c27fcb45bd',
    CIDADE: 'Itabaiana',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.963499069213867,
    MAXIMO: 31.1966552734375,
    MEDIA: 31.10668194168851,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40f2c76e-96b5-410f-b8da-3ca441a206db',
    CIDADE: 'Itaporanga',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.14771270751953,
    MAXIMO: 31.271116256713867,
    MEDIA: 31.180567736306298,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4db49b7-37c6-4e34-a173-5030d3c8651c',
    CIDADE: 'Itapororoca',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.382247924804688,
    MAXIMO: 30.578454971313477,
    MEDIA: 30.428742509110517,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6da3f2d6-4ee5-45db-bd46-025468fac4f1',
    CIDADE: 'Itatuba',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.007638931274414,
    MAXIMO: 31.202556610107422,
    MEDIA: 31.200855731501832,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae6e8f3c-5862-496b-b048-40f55ac75f31',
    CIDADE: 'Jacaraú',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.146833419799805,
    MAXIMO: 30.41490936279297,
    MEDIA: 30.27955423072985,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b166e8a8-f765-4d48-8728-d43b5cf593df',
    CIDADE: 'Jericó',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.020374298095703,
    MAXIMO: 31.115083694458008,
    MEDIA: 31.07873694102801,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b600070e-5b45-4c7a-90c8-fb51a5007e75',
    CIDADE: 'João Pessoa',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.68885612487793,
    MAXIMO: 30.909318923950195,
    MEDIA: 30.796239934540694,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f41b75be-7f82-4469-9708-ff4be7953d77',
    CIDADE: 'Juarez Távora',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.827716827392578,
    MAXIMO: 31.007638931274414,
    MEDIA: 30.87901477432993,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c217bdbc-16fc-41e6-ab32-80da5d715ef5',
    CIDADE: 'Juazeirinho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.902570724487305,
    MAXIMO: 30.987930297851562,
    MEDIA: 30.945250511169434,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a02a1bc-250d-48ff-b853-bcff31e9e11c',
    CIDADE: 'Junco do Seridó',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.93120574951172,
    MAXIMO: 31.022306442260742,
    MEDIA: 30.960496978110875,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6bbc41cd-8180-47cb-a9c8-49b1059e18e6',
    CIDADE: 'Juripiranga',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.963499069213867,
    MAXIMO: 31.183439254760742,
    MEDIA: 31.128709469135217,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9cf67b0a-45c9-430a-829d-a2c86dae9658',
    CIDADE: 'Juru',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.0733699798584,
    MAXIMO: 31.142240524291992,
    MEDIA: 31.11541769556334,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c362048-fa6c-43a7-89d1-5afdcb8a7875',
    CIDADE: 'Lagoa',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.115083694458008,
    MAXIMO: 31.170093536376953,
    MEDIA: 31.125121414538874,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a5820ef-d59c-4b10-a46d-a7aaa67b754b',
    CIDADE: 'Lagoa de Dentro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.269126892089844,
    MAXIMO: 30.461484909057617,
    MEDIA: 30.379789826010846,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c677c7c-dfb4-4c1a-9b19-678fe3936e88',
    CIDADE: 'Lagoa Seca',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.860382080078125,
    MAXIMO: 31.03934097290039,
    MEDIA: 30.907126277328203,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a45e375-7195-4ca5-8b85-5c007cdc7b49',
    CIDADE: 'Lastro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.196216583251953,
    MAXIMO: 31.215545654296875,
    MEDIA: 31.201339058062402,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f99f88a-6092-4778-9436-d1a59bdf40e3',
    CIDADE: 'Livramento',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.07989501953125,
    MAXIMO: 31.152246475219727,
    MEDIA: 31.12031550981836,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66ce0123-1b8e-4234-b10b-76c61552453b',
    CIDADE: 'Logradouro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.22046661376953,
    MAXIMO: 30.32777976989746,
    MEDIA: 30.29938865762848,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8882ab1e-9d3c-41d5-8430-3bc465d27b20',
    CIDADE: 'Lucena',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.530166625976562,
    MAXIMO: 30.530166625976562,
    MEDIA: 30.530166625976562,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0127971d-3f48-431f-957e-ade786609ae7',
    CIDADE: "Mãe d'Água",
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.06165885925293,
    MAXIMO: 31.118209838867188,
    MEDIA: 31.09500820056797,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a42b6faa-6972-44e0-8992-06f964984f5b',
    CIDADE: 'Malta',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.039127349853516,
    MAXIMO: 31.083343505859375,
    MEDIA: 31.070573419452064,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2721346-b877-447a-9076-77bd3a92b062',
    CIDADE: 'Mamanguape',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.086978912353516,
    MAXIMO: 30.54932975769043,
    MEDIA: 30.35536973204912,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5df0d692-e448-4e11-9d9b-91bbde92a23a',
    CIDADE: 'Manaíra',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.101449966430664,
    MAXIMO: 31.15998649597168,
    MEDIA: 31.133221525853415,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c36d98d-9e52-43a5-affa-f89b91167dbf',
    CIDADE: 'Marcação',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.367570877075195,
    MAXIMO: 30.382247924804688,
    MEDIA: 30.374813342309505,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dad768b-d9e3-4b31-b5cb-9d34aabe9ea3',
    CIDADE: 'Mari',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.578454971313477,
    MAXIMO: 30.79447364807129,
    MEDIA: 30.7203450102284,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0036943-f7b1-4b82-9346-ebf03fc35e43',
    CIDADE: 'Marizópolis',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.287368774414062,
    MAXIMO: 31.29257583618164,
    MEDIA: 31.29139587829372,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1b6dd78-6c57-4782-bfaf-37fd95f814ef',
    CIDADE: 'Massaranduba',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.860382080078125,
    MAXIMO: 31.03934097290039,
    MEDIA: 30.975015398790845,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5678c23-5346-4d69-928d-2c99dbcb46be',
    CIDADE: 'Mataraca',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.086978912353516,
    MAXIMO: 30.229095458984375,
    MEDIA: 30.206439724142843,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e5ccf97-49e9-4161-848d-bd02973afc8e',
    CIDADE: 'Matinhas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.860382080078125,
    MAXIMO: 30.89064598083496,
    MEDIA: 30.861162081837175,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74b9783a-d87e-4c97-a2c7-91df882c41ca',
    CIDADE: 'Mato Grosso',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.020374298095703,
    MAXIMO: 31.115083694458008,
    MEDIA: 31.05397417459623,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '482b24f4-dd67-4fd0-b580-6e8d420b96b0',
    CIDADE: 'Maturéia',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.07921028137207,
    MAXIMO: 31.118209838867188,
    MEDIA: 31.11605270266571,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b14b5ee4-4a74-4700-990d-bbfae6cf1c0e',
    CIDADE: 'Mogeiro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.986780166625977,
    MAXIMO: 31.202556610107422,
    MEDIA: 31.03508071523279,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e2a7f545-d1bc-4672-9f9b-7472c8b96e09',
    CIDADE: 'Montadas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.89064598083496,
    MAXIMO: 30.917560577392578,
    MEDIA: 30.890651015538264,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01b0ff10-ae5e-4202-9e98-5bde9b092cfd',
    CIDADE: 'Monte Horebe',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.251827239990234,
    MAXIMO: 31.338340759277344,
    MEDIA: 31.298345986141626,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1ffe21da-e986-416a-ad4c-38c1ed697e68',
    CIDADE: 'Monteiro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.158145904541016,
    MAXIMO: 31.201154708862305,
    MEDIA: 31.176998774210613,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f87ec76-e6bb-4705-8179-5e17db533771',
    CIDADE: 'Mulungu',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.578454971313477,
    MAXIMO: 30.827716827392578,
    MEDIA: 30.687034967398652,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4511d6d-bd97-4cd5-8121-21b917de5019',
    CIDADE: 'Natuba',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.1966552734375,
    MAXIMO: 31.4104061126709,
    MEDIA: 31.36557720472172,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27f999bc-b602-4860-9edc-df130bc0c892',
    CIDADE: 'Nazarezinho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.202377319335938,
    MAXIMO: 31.287368774414062,
    MEDIA: 31.278041017426503,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53fa2e80-286e-4ab2-ae3c-d1772c133939',
    CIDADE: 'Nova Floresta',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.5600528717041,
    MAXIMO: 30.63922882080078,
    MEDIA: 30.566955663762357,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d6fecc5-ea61-48c2-84bd-591a281d75ab',
    CIDADE: 'Nova Olinda',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.139625549316406,
    MAXIMO: 31.14771270751953,
    MEDIA: 31.145557016044297,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48ba3dbe-04b1-465c-89d0-614445b88986',
    CIDADE: 'Nova Palmeira',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.63922882080078,
    MAXIMO: 30.821273803710938,
    MEDIA: 30.737419639005317,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3cb6fd90-9241-4322-86f2-35e1529fba12',
    CIDADE: "Olho d'Água",
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.054513931274414,
    MAXIMO: 31.142240524291992,
    MEDIA: 31.098971223493013,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3427d054-e585-44e5-8cc8-1919b89f5716',
    CIDADE: 'Olivedos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.729949951171875,
    MAXIMO: 30.96895408630371,
    MEDIA: 30.86629579840269,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42406a15-ad1b-4f05-ac17-8a3641372093',
    CIDADE: 'Ouro Velho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.117572784423828,
    MAXIMO: 31.18971061706543,
    MEDIA: 31.14682558697214,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13bffc07-0930-4f79-88e7-c35937beea6c',
    CIDADE: 'Parari',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.07516098022461,
    MAXIMO: 31.16151237487793,
    MEDIA: 31.10545585973825,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9dda76f4-b286-4fd9-9b12-5c82694a64e2',
    CIDADE: 'Passagem',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.0394229888916,
    MAXIMO: 31.091678619384766,
    MEDIA: 31.04080119317203,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fda8ddf9-4555-4a75-9480-df21952e45fc',
    CIDADE: 'Patos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.012374877929688,
    MAXIMO: 31.08927345275879,
    MEDIA: 31.048046383232332,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02ab9527-2486-44c8-8de9-66df02093aa8',
    CIDADE: 'Paulista',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.003509521484375,
    MAXIMO: 31.05410385131836,
    MEDIA: 31.028806686401367,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de6abf2b-df38-4025-960f-cf783977bd2b',
    CIDADE: 'Pedra Branca',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.139625549316406,
    MAXIMO: 31.167638778686523,
    MEDIA: 31.14875245623386,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc3eadee-0f71-4504-9113-51ff08d7c54a',
    CIDADE: 'Pedra Lavrada',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.63922882080078,
    MAXIMO: 30.902570724487305,
    MEDIA: 30.77090816747881,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5b1c7d5-b083-4fbb-a5ae-3381a16962f2',
    CIDADE: 'Pedras de Fogo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.909318923950195,
    MAXIMO: 31.183439254760742,
    MEDIA: 31.04471799426871,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3cefc25-41b0-42ac-88fe-413a6a3d7ec0',
    CIDADE: 'Piancó',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.13811683654785,
    MAXIMO: 31.16079330444336,
    MEDIA: 31.149455070495605,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4126eedd-040a-499d-a5f3-ab78653c1803',
    CIDADE: 'Picuí',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.5600528717041,
    MAXIMO: 30.689977645874023,
    MEDIA: 30.621946970621746,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba0a8c82-7eec-44e4-8f96-9c355791fa1f',
    CIDADE: 'Pilar',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.963499069213867,
    MAXIMO: 31.183439254760742,
    MEDIA: 30.972548074521125,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f28fc08-e589-48d7-889b-9f2d2d8b3c28',
    CIDADE: 'Pilões',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.520158767700195,
    MAXIMO: 30.66459083557129,
    MEDIA: 30.660074536028887,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5513dfe8-fc18-444b-b99b-781728748198',
    CIDADE: 'Pilõezinhos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.461484909057617,
    MAXIMO: 30.66459083557129,
    MEDIA: 30.577758511886266,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81cf5011-4c74-4fea-b48d-2a99591b8349',
    CIDADE: 'Pirpirituba',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.461484909057617,
    MAXIMO: 30.520158767700195,
    MEDIA: 30.492412709479687,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f9e8c42-6163-49f3-985b-f33b34ac4cb0',
    CIDADE: 'Pitimbu',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.08935546875,
    MAXIMO: 31.37444496154785,
    MEDIA: 31.140492712164136,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f46d9f1-2ec9-4451-bfd8-e615f8009963',
    CIDADE: 'Pocinhos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.917560577392578,
    MAXIMO: 30.947628021240234,
    MEDIA: 30.932594299316406,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0488c5ff-ff80-4e82-b9a4-3708b3c419d9',
    CIDADE: 'Poço Dantas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.344070434570312,
    MAXIMO: 31.450368881225586,
    MEDIA: 31.357622746139032,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3585b00-e24d-4b0a-84bf-cc429eaf55b0',
    CIDADE: 'Poço de José de Moura',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.359268188476562,
    MAXIMO: 31.362022399902344,
    MEDIA: 31.36200450857219,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '97567cb8-3c24-4bb7-97f0-0e237217926f',
    CIDADE: 'Pombal',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.089216232299805,
    MAXIMO: 31.138559341430664,
    MEDIA: 31.113887786865234,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2244c97-01e0-4f05-ad4d-f0871c2e6987',
    CIDADE: 'Prata',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.117572784423828,
    MAXIMO: 31.18971061706543,
    MEDIA: 31.171841771382887,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76e735db-8b67-476e-950e-97fb8e3a72da',
    CIDADE: 'Princesa Isabel',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.127735137939453,
    MAXIMO: 31.139625549316406,
    MEDIA: 31.13368034362793,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c48d0c8-7124-4a6c-bfbf-cd9c28bc93eb',
    CIDADE: 'Puxinanã',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.89064598083496,
    MAXIMO: 31.03934097290039,
    MEDIA: 30.912794418013462,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '530477db-26cc-4fbe-afe4-b875ed1bec84',
    CIDADE: 'Queimadas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.03934097290039,
    MAXIMO: 31.36871910095215,
    MEDIA: 31.1761859446918,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2772fb99-b9d7-4411-bb03-30e9592ce578',
    CIDADE: 'Quixaba',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.985136032104492,
    MAXIMO: 31.057540893554688,
    MEDIA: 31.044054311712962,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3bd0fa0c-30f2-4bf0-927d-688ad5c731e7',
    CIDADE: 'Remígio',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.71001625061035,
    MAXIMO: 30.860382080078125,
    MEDIA: 30.740716570071257,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d43caa9-1c0c-422d-bb78-8fb0011cc4a3',
    CIDADE: 'Pedro Régis',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.269126892089844,
    MAXIMO: 30.41490936279297,
    MEDIA: 30.312041885311928,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e7a6e6a-9c46-4fd9-966d-0eddd100b454',
    CIDADE: 'Riachão',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.292713165283203,
    MAXIMO: 30.462444305419922,
    MEDIA: 30.39816506858345,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6460191b-755c-4dd0-9833-ef42c7e175c0',
    CIDADE: 'Riachão do Bacamarte',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.007638931274414,
    MAXIMO: 31.025310516357422,
    MEDIA: 31.020108289739756,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4ddd42e-266f-432a-933d-293240597be4',
    CIDADE: 'Riachão do Poço',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.76300048828125,
    MAXIMO: 30.963499069213867,
    MEDIA: 30.788419686826,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e89a276-45e7-4e11-9b7f-a0feee8bd268',
    CIDADE: 'Riacho de Santo Antônio',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.30107307434082,
    MAXIMO: 31.474241256713867,
    MEDIA: 31.403575156941113,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d0b6560-f52d-447d-abc4-4a93b50a10ff',
    CIDADE: 'Riacho dos Cavalos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.964990615844727,
    MAXIMO: 31.05410385131836,
    MEDIA: 31.004215820228094,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe09f8d8-9fde-4461-ba34-ab13b1f1a505',
    CIDADE: 'Rio Tinto',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.382247924804688,
    MAXIMO: 30.54932975769043,
    MEDIA: 30.46578884124756,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd22b76fa-a641-4c6a-8b31-68ea20ef06ad',
    CIDADE: 'Salgadinho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.005422592163086,
    MAXIMO: 31.022306442260742,
    MEDIA: 31.018507237517007,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e45b9c27-e769-40e2-903b-f4c9c6da540b',
    CIDADE: 'Salgado de São Félix',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.986780166625977,
    MAXIMO: 31.202556610107422,
    MEDIA: 31.1887578334663,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed11c03c-3ce1-4986-8d83-268939da45e3',
    CIDADE: 'Santa Cecília',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.36871910095215,
    MAXIMO: 31.530866622924805,
    MEDIA: 31.52279113876902,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bc831da-868d-482c-86e7-41974967df5c',
    CIDADE: 'Santa Cruz',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.146549224853516,
    MAXIMO: 31.215545654296875,
    MEDIA: 31.16864241886874,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f429a42a-557a-4599-8b65-0b90e8df4a7c',
    CIDADE: 'Santa Helena',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.362022399902344,
    MAXIMO: 31.449398040771484,
    MEDIA: 31.397299681538446,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c79a283-3b77-43c3-b46b-66c34c48838b',
    CIDADE: 'Santa Inês',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.167892456054688,
    MAXIMO: 31.237825393676758,
    MEDIA: 31.202858924865723,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d490d31-0f38-4bac-94a9-3b6fdf4baf58',
    CIDADE: 'Santa Luzia',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.895185470581055,
    MAXIMO: 31.022306442260742,
    MEDIA: 30.958338188946417,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '427b59dc-5013-4591-ba6d-98d1daff9cee',
    CIDADE: 'Santana de Mangueira',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.12386703491211,
    MAXIMO: 31.213151931762695,
    MEDIA: 31.178056642230242,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39f94696-5d0a-44a3-aff4-adb4759bb287',
    CIDADE: 'Santana dos Garrotes',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.142240524291992,
    MAXIMO: 31.14771270751953,
    MEDIA: 31.14497661590576,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b9a00c3-f515-47be-9ed6-9f88c9b39f4c',
    CIDADE: 'Joca Claudino',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.359268188476562,
    MAXIMO: 31.362022399902344,
    MEDIA: 31.35938921074679,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6647e4a-5ac5-4e88-86b5-abd26f416191',
    CIDADE: 'Santa Rita',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.530166625976562,
    MAXIMO: 30.938310623168945,
    MEDIA: 30.694892951740353,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28fa1a1f-ed0b-4ac9-a719-b98cfe430ad8',
    CIDADE: 'Santa Teresinha',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.0441951751709,
    MAXIMO: 31.118209838867188,
    MEDIA: 31.094893275072394,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5259cf20-a31c-4c9d-8dac-fc6cd7e88f65',
    CIDADE: 'Santo André',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.987930297851562,
    MAXIMO: 31.07989501953125,
    MEDIA: 31.06315053221488,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d554d9f-50f5-43f4-a99c-cca18e820932',
    CIDADE: 'São Bento',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.91583251953125,
    MAXIMO: 31.003509521484375,
    MEDIA: 30.946370622865803,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ca795122-d33a-4f70-8fc3-eb7b473add63',
    CIDADE: 'São Bentinho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.089216232299805,
    MAXIMO: 31.138559341430664,
    MEDIA: 31.105359372201253,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4dc064c-c4cf-4210-ac36-76cdfc765b03',
    CIDADE: 'São Domingos do Cariri',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.171794891357422,
    MAXIMO: 31.362567901611328,
    MEDIA: 31.27195269153874,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e25c19ec-ca00-482f-98ad-86dce29ad268',
    CIDADE: 'São Domingos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.12589454650879,
    MAXIMO: 31.165386199951172,
    MEDIA: 31.148980552528087,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8346773e-f8eb-4a17-91e8-1e903f6c9d88',
    CIDADE: 'São Francisco',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.154720306396484,
    MAXIMO: 31.196216583251953,
    MEDIA: 31.170017540587768,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a2fb857-608b-4db0-b6ab-b939bb092287',
    CIDADE: 'São João do Cariri',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.16151237487793,
    MAXIMO: 31.24335479736328,
    MEDIA: 31.202433586120605,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc5f0a1c-f328-489f-a684-a45f5e2562aa',
    CIDADE: 'São João do Tigre',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.267040252685547,
    MAXIMO: 31.31500244140625,
    MEDIA: 31.284849802652996,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94700c46-9a8b-4a69-be93-df709ae88b2a',
    CIDADE: 'São José da Lagoa Tapada',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.165386199951172,
    MAXIMO: 31.202377319335938,
    MEDIA: 31.183881759643555,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6bcb2d2c-afee-42cf-b82b-512cd0a61bca',
    CIDADE: 'São José de Caiana',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.179859161376953,
    MAXIMO: 31.271116256713867,
    MEDIA: 31.25065952283545,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3ba3a7f-d2da-4c79-81bd-5f11a8765626',
    CIDADE: 'São José de Espinharas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.003877639770508,
    MAXIMO: 31.0441951751709,
    MEDIA: 31.02906672159831,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea59ebe2-37e4-407d-b195-ed1f7e6dc3fa',
    CIDADE: 'São José dos Ramos',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.963499069213867,
    MAXIMO: 30.986780166625977,
    MEDIA: 30.977183453041754,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f83598e-244b-4b8d-ba61-e30c537c7a18',
    CIDADE: 'São José de Piranhas',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.271116256713867,
    MAXIMO: 31.322755813598633,
    MEDIA: 31.29693603515625,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2add0340-418b-4172-8d9b-fbcc38ba5272',
    CIDADE: 'São José de Princesa',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.09992027282715,
    MAXIMO: 31.15030288696289,
    MEDIA: 31.132107958596183,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '943bc25c-fd81-4afb-8c8b-4fd93ec80791',
    CIDADE: 'São José do Bonfim',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.057540893554688,
    MAXIMO: 31.118209838867188,
    MEDIA: 31.08553536747525,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd89a889f-27f1-4e77-a460-bf3bf5065742',
    CIDADE: 'São José do Brejo do Cruz',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.772903442382812,
    MAXIMO: 30.92425537109375,
    MEDIA: 30.861639338055838,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e15973de-63a1-44da-9295-4dc9b1b46eac',
    CIDADE: 'São José do Sabugi',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.859189987182617,
    MAXIMO: 30.958425521850586,
    MEDIA: 30.901974135609134,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0c7d8bd-957c-4ac4-9378-c25bfa91ab18',
    CIDADE: 'São José dos Cordeiros',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.07989501953125,
    MAXIMO: 31.20526885986328,
    MEDIA: 31.14908887369585,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ebb1e83-0497-4834-8db3-4818b3c0ec52',
    CIDADE: 'São Mamede',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.929502487182617,
    MAXIMO: 31.0394229888916,
    MEDIA: 30.99098696538734,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe4e093b-e2d6-4549-a66f-167058b61e2b',
    CIDADE: 'São Miguel de Taipu',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.938310623168945,
    MAXIMO: 30.963499069213867,
    MEDIA: 30.961653235815195,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f839385-75c1-4a89-b83e-a25f4b753594',
    CIDADE: 'São Sebastião de Lagoa de Roça',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.860382080078125,
    MAXIMO: 30.89064598083496,
    MEDIA: 30.888721025204436,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '08212897-fca4-403e-8622-32ace1525f33',
    CIDADE: 'São Sebastião do Umbuzeiro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.17104148864746,
    MAXIMO: 31.272506713867188,
    MEDIA: 31.22543538816397,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8341e236-4821-4a30-b27e-a9893cd338c0',
    CIDADE: 'Sapé',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.54932975769043,
    MAXIMO: 30.963499069213867,
    MEDIA: 30.700365234271867,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '950a6097-fcde-4bed-8232-5752852507a7',
    CIDADE: 'São Vicente do Seridó',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.77312660217285,
    MAXIMO: 30.902570724487305,
    MEDIA: 30.87883358679445,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '257bb59d-a7fa-4b07-9a4d-3c88ffdbde75',
    CIDADE: 'Serra Branca',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.152246475219727,
    MAXIMO: 31.219711303710938,
    MEDIA: 31.185978889465332,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31539da2-f83f-4517-84dd-94d135403466',
    CIDADE: 'Serra da Raiz',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.32777976989746,
    MAXIMO: 30.520158767700195,
    MEDIA: 30.43584524765821,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7f0f89c-6a6a-428d-a4db-a836ce8fd23a',
    CIDADE: 'Serra Grande',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.251827239990234,
    MAXIMO: 31.271116256713867,
    MEDIA: 31.252302795356147,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa0c568e-7196-428a-aef8-8963c82c111b',
    CIDADE: 'Serra Redonda',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.827716827392578,
    MAXIMO: 31.025310516357422,
    MEDIA: 30.91160598329945,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7776a948-76db-4407-9aed-e4c64826fc32',
    CIDADE: 'Serraria',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.520158767700195,
    MAXIMO: 30.71001625061035,
    MEDIA: 30.563445094174575,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c97e511-1210-42e7-840d-6a3ddadfe3c4',
    CIDADE: 'Sertãozinho',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.461484909057617,
    MAXIMO: 30.461484909057617,
    MEDIA: 30.461484909057617,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0396a0e2-bbcc-4e5a-8156-58f0f59efb2d',
    CIDADE: 'Sobrado',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.76300048828125,
    MAXIMO: 30.986780166625977,
    MEDIA: 30.894906443818535,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '38c299d4-6517-47e0-bf0a-8857396e9245',
    CIDADE: 'Solânea',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.462444305419922,
    MAXIMO: 30.57657814025879,
    MEDIA: 30.566306593954675,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '363fb671-62ee-41c4-8a1f-9693557496f7',
    CIDADE: 'Soledade',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.87162971496582,
    MAXIMO: 31.07516098022461,
    MEDIA: 30.968032611487036,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '894f7276-420e-4062-b3cd-4548a7b1ab1f',
    CIDADE: 'Sossêgo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.5843563079834,
    MAXIMO: 30.729949951171875,
    MEDIA: 30.709382092487683,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37883072-9188-4f63-8d06-838738800570',
    CIDADE: 'Sousa',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.196216583251953,
    MAXIMO: 31.29257583618164,
    MEDIA: 31.23179054260254,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '38be62ae-9af7-4441-b635-7e12041cce9e',
    CIDADE: 'Sumé',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.20526885986328,
    MAXIMO: 31.252033233642578,
    MEDIA: 31.22865104675293,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13474b70-cab9-42cb-bf8d-f978242f5dca',
    CIDADE: 'Tacima',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.22046661376953,
    MAXIMO: 30.391618728637695,
    MEDIA: 30.323385925229974,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ce92bd67-6121-4544-bb3f-f47e870e3ee0',
    CIDADE: 'Taperoá',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.07989501953125,
    MAXIMO: 31.08516502380371,
    MEDIA: 31.08253002166748,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18354c32-105b-4299-be6f-4c83cc9581a2',
    CIDADE: 'Tavares',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.072860717773438,
    MAXIMO: 31.14771270751953,
    MEDIA: 31.131495666748783,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c1ebb5b-9390-46a7-8a63-e8a237edaf5a',
    CIDADE: 'Teixeira',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.10702896118164,
    MAXIMO: 31.118209838867188,
    MEDIA: 31.109259516280815,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87c0862e-2d77-4c56-813d-6b5a31db83a4',
    CIDADE: 'Tenório',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.902570724487305,
    MAXIMO: 31.005422592163086,
    MEDIA: 30.916256986196906,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c4a56e8-e7f0-4180-bc95-90cc2d4d0204',
    CIDADE: 'Triunfo',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.359268188476562,
    MAXIMO: 31.450368881225586,
    MEDIA: 31.385252318294295,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2831658e-8a42-45c6-8354-0199d36a8902',
    CIDADE: 'Uiraúna',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.27680206298828,
    MAXIMO: 31.362022399902344,
    MEDIA: 31.30083980592885,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89838e0d-2fbd-4de7-86e9-292335fd8b0b',
    CIDADE: 'Umbuzeiro',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.36871910095215,
    MAXIMO: 31.63516616821289,
    MEDIA: 31.526721125932987,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e11cfb42-9673-402c-a704-57d90a450d0f',
    CIDADE: 'Várzea',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 30.895185470581055,
    MAXIMO: 30.985136032104492,
    MEDIA: 30.93238269255491,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc83508d-655c-4a51-b147-0d43c6166f25',
    CIDADE: 'Vieirópolis',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.196216583251953,
    MAXIMO: 31.2918701171875,
    MEDIA: 31.245633336906785,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '604ce3b9-3337-4d25-9823-0e1e386238fc',
    CIDADE: 'Zabelê',
    SIGLA: 'PB',
    ESTADO: 'Paraíba',
    MINIMO: 31.172710418701172,
    MAXIMO: 31.211822509765625,
    MEDIA: 31.201873069131405,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd08a627e-6580-4328-97ad-874cdc372506',
    CIDADE: 'Abreu e Lima',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.678396224975586,
    MAXIMO: 31.97601318359375,
    MEDIA: 31.929160410528944,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5adb6a06-8846-4c5a-bb01-afc9225ed9bf',
    CIDADE: 'Afogados da Ingazeira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.072860717773438,
    MAXIMO: 31.097660064697266,
    MEDIA: 31.083933053708762,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f62d020-6174-4e93-914f-0c6f29f14252',
    CIDADE: 'Afrânio',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.217041015625,
    MAXIMO: 31.74686622619629,
    MEDIA: 31.522311687469482,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5de9332-13e9-4dd9-931b-34b33932a35b',
    CIDADE: 'Agrestina',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.819950103759766,
    MAXIMO: 32.02351379394531,
    MEDIA: 31.99350115623605,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e098bf3-e660-49b2-92bf-1f151d11441d',
    CIDADE: 'Água Preta',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.43436813354492,
    MAXIMO: 33.0578727722168,
    MEDIA: 32.83032289573951,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a00fbe8c-4570-47e1-a3b9-170cc7bb7fe3',
    CIDADE: 'Águas Belas',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.068862915039062,
    MAXIMO: 31.106021881103516,
    MEDIA: 31.08744239807129,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f820f143-3af0-49f0-988f-4e9743d05246',
    CIDADE: 'Alagoinha',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.247888565063477,
    MAXIMO: 31.325275421142578,
    MEDIA: 31.30623007506311,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a4b3a24a-8793-4410-b022-1958b3b76761',
    CIDADE: 'Aliança',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.161977767944336,
    MAXIMO: 31.684846878051758,
    MEDIA: 31.456962089526886,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25e671ef-8bee-4625-a5ad-3ba6f7ab78ff',
    CIDADE: 'Altinho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.612844467163086,
    MAXIMO: 31.836706161499023,
    MEDIA: 31.756500244140625,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb2c4567-905c-47ba-a2fa-e99267b8972a',
    CIDADE: 'Amaraji',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.29320526123047,
    MAXIMO: 32.731529235839844,
    MEDIA: 32.63347925847779,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'affed802-8395-4c9f-92a2-8707502c0ff5',
    CIDADE: 'Angelim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.324005126953125,
    MAXIMO: 31.42387580871582,
    MEDIA: 31.413374775790132,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4dec79df-741d-421a-a127-ba9827491aeb',
    CIDADE: 'Araçoiaba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.678396224975586,
    MAXIMO: 31.97601318359375,
    MEDIA: 31.721713077861267,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f366cd61-5f66-4575-9eb6-caa5502ad2af',
    CIDADE: 'Araripina',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.18962478637695,
    MAXIMO: 32.78495407104492,
    MEDIA: 32.48520596822103,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ed687af-e515-40a1-be53-b4fca44373eb',
    CIDADE: 'Arcoverde',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.16950225830078,
    MAXIMO: 31.272506713867188,
    MEDIA: 31.22865338199005,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8653357a-c55c-4c78-9bc9-bb7c8ccafec1',
    CIDADE: 'Barra de Guabiraba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.22319412231445,
    MAXIMO: 32.58586883544922,
    MEDIA: 32.40666578311591,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98e55bd5-0c17-4803-97d2-90babb993d04',
    CIDADE: 'Barreiros',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.94199752807617,
    MAXIMO: 34.66779327392578,
    MEDIA: 33.61095230980742,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9ffbca0-1cad-4541-a0ca-5d42a846b3a7',
    CIDADE: 'Belém de Maria',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.02351379394531,
    MAXIMO: 32.269535064697266,
    MEDIA: 32.15278109414424,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2698617b-5d92-4d55-b7b5-7faa3f06be3e',
    CIDADE: 'Belém do São Francisco',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.810747146606445,
    MAXIMO: 30.934537887573242,
    MEDIA: 30.868198776245116,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a64ae198-484b-494b-9e30-01cd20f621f5',
    CIDADE: 'Belo Jardim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.409711837768555,
    MAXIMO: 31.654796600341797,
    MEDIA: 31.537022908528645,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54135fa8-ea7c-41b1-891f-b4105cffaffa',
    CIDADE: 'Betânia',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.052589416503906,
    MAXIMO: 31.09130859375,
    MEDIA: 31.070444107055664,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6038b6e-ef1a-4bdc-a304-3997d2c3c1e2',
    CIDADE: 'Bezerros',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.82544708251953,
    MAXIMO: 32.29320526123047,
    MEDIA: 32.03482987870096,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de55ef72-50e3-4a32-8936-e7a81bceb34c',
    CIDADE: 'Bodocó',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.386249542236328,
    MAXIMO: 32.306888580322266,
    MEDIA: 31.820329984029133,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ee95838-f5a9-45d3-a014-84523b1e8402',
    CIDADE: 'Bom Conselho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.045047760009766,
    MAXIMO: 31.1268367767334,
    MEDIA: 31.072415669759113,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f744194e-9e02-4545-b44e-07c4abdd2501',
    CIDADE: 'Bom Jardim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.586177825927734,
    MAXIMO: 31.85923194885254,
    MEDIA: 31.647290318593587,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac35f7b6-c7f3-4a48-bc0a-2d5a9a02f491',
    CIDADE: 'Bonito',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.22319412231445,
    MAXIMO: 32.269535064697266,
    MEDIA: 32.24636459350586,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd558b8e7-ba51-438d-a693-45ffb5ebb360',
    CIDADE: 'Brejão',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.153627395629883,
    MAXIMO: 31.249723434448242,
    MEDIA: 31.159077667598876,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6e6ed66-82c8-423a-a444-662e5d12a785',
    CIDADE: 'Brejinho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.07921028137207,
    MAXIMO: 31.145244598388672,
    MEDIA: 31.101585914731253,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a73d44c-7330-4bd2-bb9e-1cd65fdcb21a',
    CIDADE: 'Brejo da Madre de Deus',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.510482788085938,
    MAXIMO: 31.594398498535156,
    MEDIA: 31.552440643310547,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '21efd3c3-9baa-4e6e-b529-b9ebe05fbe37',
    CIDADE: 'Buenos Aires',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.666257858276367,
    MAXIMO: 31.684846878051758,
    MEDIA: 31.67338891566786,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2498c9ab-1402-4bd6-bd8d-d0582b1c2b56',
    CIDADE: 'Buíque',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.14080238342285,
    MAXIMO: 31.199687957763672,
    MEDIA: 31.16377410888672,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64c5cfff-2ae1-4817-8a02-fa2321c54307',
    CIDADE: 'Cabo de Santo Agostinho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.60799789428711,
    MAXIMO: 32.672340393066406,
    MEDIA: 32.64016914367676,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9ac20e6-29b1-4a41-8aed-8ff9c791e5bb',
    CIDADE: 'Cabrobó',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.852394104003906,
    MAXIMO: 30.988994598388672,
    MEDIA: 30.904041290283203,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa565330-e958-4b6e-acbc-ad2ba837bb1f',
    CIDADE: 'Cachoeirinha',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.486068725585938,
    MAXIMO: 31.672454833984375,
    MEDIA: 31.598897289323972,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8f4512a-356c-4332-bb40-eaf50e0d1077',
    CIDADE: 'Caetés',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.199588775634766,
    MAXIMO: 31.330171585083008,
    MEDIA: 31.255904854503544,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '695a75e7-1dff-4736-b961-3a42c60e4949',
    CIDADE: 'Calçado',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.324005126953125,
    MAXIMO: 31.543621063232422,
    MEDIA: 31.457673191192388,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc6f14b4-d129-4eff-a008-389b61dfbef2',
    CIDADE: 'Calumbi',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.06714630126953,
    MAXIMO: 31.101999282836914,
    MEDIA: 31.091844865245378,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7056b6d1-5926-443d-8297-bc965178697c',
    CIDADE: 'Camaragibe',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.940364837646484,
    MAXIMO: 32.30457305908203,
    MEDIA: 32.109343450355894,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e20913c-b04e-40b6-8a2e-288fd0a3f64f',
    CIDADE: 'Camocim de São Félix',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.004581451416016,
    MAXIMO: 32.22319412231445,
    MEDIA: 32.19104443259652,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c1109db-9baa-486a-97b1-fddc2e819e9e',
    CIDADE: 'Camutanga',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.183439254760742,
    MAXIMO: 31.1966552734375,
    MEDIA: 31.18389369996494,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd84c2f31-9a08-4e1a-85ae-9d1074925472',
    CIDADE: 'Canhotinho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.269184112548828,
    MAXIMO: 31.56905746459961,
    MEDIA: 31.449118771960457,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bcb0d050-bad8-4006-b28a-4b1228bb63af',
    CIDADE: 'Capoeiras',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.25834846496582,
    MAXIMO: 31.381336212158203,
    MEDIA: 31.322080276890425,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '07b4131d-629f-4acd-a3b7-727c368721ce',
    CIDADE: 'Carnaíba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.072860717773438,
    MAXIMO: 31.076684951782227,
    MEDIA: 31.074772834777832,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c33a2ba-d654-44b9-8392-3f52951fae27',
    CIDADE: 'Carnaubeira da Penha',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.945701599121094,
    MAXIMO: 31.001317977905273,
    MEDIA: 30.971489588419598,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95e8e0f7-d3fc-4ae4-801a-fbfb8d687572',
    CIDADE: 'Carpina',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.666257858276367,
    MAXIMO: 31.96817398071289,
    MEDIA: 31.7722337526409,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '709105a0-bf7b-40c1-8a98-9a7730616510',
    CIDADE: 'Caruaru',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.706253051757812,
    MAXIMO: 31.9367618560791,
    MEDIA: 31.809010823567707,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6a88b0e-16bc-44a6-a8bf-91e81b8fc2e8',
    CIDADE: 'Casinhas',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.586177825927734,
    MAXIMO: 31.7750186920166,
    MEDIA: 31.587496130209082,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7de2eff4-4cf9-48f1-afbd-d955fd5b63c2',
    CIDADE: 'Catende',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.22920608520508,
    MAXIMO: 32.58696746826172,
    MEDIA: 32.24344604197414,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98b99d3f-0dca-451e-bc01-7337fd39529b',
    CIDADE: 'Cedro',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.31774139404297,
    MAXIMO: 31.487462997436523,
    MEDIA: 31.32681782616253,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5581e82c-39f3-40d7-bf4d-99b589f0885f',
    CIDADE: 'Chã de Alegria',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.96817398071289,
    MAXIMO: 32.27870559692383,
    MEDIA: 32.14376535785774,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2191541-1541-42b7-bc1f-abcc3e0e4e2c',
    CIDADE: 'Chã Grande',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.29320526123047,
    MAXIMO: 32.469539642333984,
    MEDIA: 32.430596981018965,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c1e29308-11e3-4ede-9d3e-df551525da17',
    CIDADE: 'Condado',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.407968521118164,
    MAXIMO: 31.678396224975586,
    MEDIA: 31.40970437656899,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a26f34c8-3073-45fe-b54d-1e4054ae206e',
    CIDADE: 'Correntes',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.05614471435547,
    MAXIMO: 31.19743537902832,
    MEDIA: 31.126790046691895,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb6574c3-84b2-4ec4-b2be-b95502b942c0',
    CIDADE: 'Cortês',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.471961975097656,
    MAXIMO: 32.731529235839844,
    MEDIA: 32.51080154298538,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4d627f2-8cd8-419c-936d-17b044003d6b',
    CIDADE: 'Cumaru',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.7750186920166,
    MAXIMO: 32.0823974609375,
    MEDIA: 31.9337535989576,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '269ddd1c-5abd-4d2e-aed4-1bb61c12d762',
    CIDADE: 'Cupira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.819950103759766,
    MAXIMO: 32.02351379394531,
    MEDIA: 32.017945236029874,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '268d7022-53ad-4fe5-9c86-af465c778ac4',
    CIDADE: 'Custódia',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.068742752075195,
    MAXIMO: 31.1019287109375,
    MEDIA: 31.082040309906006,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81727df7-3265-4780-94e9-0f7d4620f923',
    CIDADE: 'Dormentes',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.89203643798828,
    MAXIMO: 31.453001022338867,
    MEDIA: 31.16318130493164,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b481f82f-b182-45d8-89a6-2917ee2e3f86',
    CIDADE: 'Escada',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.469539642333984,
    MAXIMO: 33.3143310546875,
    MEDIA: 32.87080568791443,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fedc68a5-bb71-451b-bf34-4a31146cd970',
    CIDADE: 'Exu',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.653718948364258,
    MAXIMO: 31.896053314208984,
    MEDIA: 31.770912647247314,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09a56630-eaed-4ad5-82d0-08ec38ddbdd1',
    CIDADE: 'Feira Nova',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.928895950317383,
    MAXIMO: 32.19712448120117,
    MEDIA: 31.945276577743165,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af4ff1db-bf24-4ee9-8b35-6e374353c681',
    CIDADE: 'Fernando de Noronha',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 0.0,
    MAXIMO: 0.0,
    MEDIA: 0.0,
    OFICIAL: 0,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f79de42-4b14-4b70-823e-46d951c70d03',
    CIDADE: 'Ferreiros',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.161977767944336,
    MAXIMO: 31.423595428466797,
    MEDIA: 31.212764442482975,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a230581-c48c-4034-8ffe-03b36b5c6fcc',
    CIDADE: 'Flores',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.083681106567383,
    MAXIMO: 31.115718841552734,
    MEDIA: 31.100329875946045,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b8078ad-b16e-4ab3-870e-45d41d41a61b',
    CIDADE: 'Floresta',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.96346664428711,
    MAXIMO: 31.090328216552734,
    MEDIA: 31.03362512588501,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ccc1dc6-a517-4dd9-b528-1d070b16a96b',
    CIDADE: 'Frei Miguelinho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.68611717224121,
    MAXIMO: 31.82544708251953,
    MEDIA: 31.6958194452621,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ea6b3d9-937b-4443-897e-c2a490654027',
    CIDADE: 'Gameleira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.950706481933594,
    MAXIMO: 33.621192932128906,
    MEDIA: 33.00696421075582,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6de8b8b-478f-4795-855d-dd8ed0d80c51',
    CIDADE: 'Garanhuns',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.1268367767334,
    MAXIMO: 31.41936683654785,
    MEDIA: 31.24669797697554,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92fe786c-2308-479b-8948-1b1e6b6706aa',
    CIDADE: 'Glória do Goitá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.928895950317383,
    MAXIMO: 32.27870559692383,
    MEDIA: 32.15893109722804,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d1c490c-eac9-4d5a-b999-fed30aee0ca1',
    CIDADE: 'Goiana',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.131059646606445,
    MAXIMO: 31.64320945739746,
    MEDIA: 31.396228398858117,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5f713f2-e480-4711-8a59-45e88d6510ef',
    CIDADE: 'Granito',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.268964767456055,
    MAXIMO: 31.57786750793457,
    MEDIA: 31.45968893463285,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '303c85e3-d499-4055-81ef-863fab0842ae',
    CIDADE: 'Gravatá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.0823974609375,
    MAXIMO: 32.471961975097656,
    MEDIA: 32.249571786792814,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d59e9bc-6217-4b68-bc09-e87af60d97e0',
    CIDADE: 'Iati',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.05876350402832,
    MAXIMO: 31.115882873535156,
    MEDIA: 31.08732318878174,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3beb1f4c-b418-4ede-8ed4-1ece4ebec87f',
    CIDADE: 'Ibimirim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.061365127563477,
    MAXIMO: 31.09087562561035,
    MEDIA: 31.07487907409668,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fea4d96b-f65c-4265-b92a-2f3f264d3c74',
    CIDADE: 'Ibirajuba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.543621063232422,
    MAXIMO: 31.819950103759766,
    MEDIA: 31.622756800627364,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b1cae9ac-52c5-4947-b6c7-9844ae5a8693',
    CIDADE: 'Igarassu',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.64320945739746,
    MAXIMO: 31.97601318359375,
    MEDIA: 31.79412571976362,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d9b977c-c98d-4f75-b875-981ffab348f7',
    CIDADE: 'Iguaracy',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.096479415893555,
    MAXIMO: 31.146392822265625,
    MEDIA: 31.124322255452473,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81148b61-2eab-4a58-a3df-4c9bdf9f1e55',
    CIDADE: 'Inajá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.058351516723633,
    MAXIMO: 31.10984230041504,
    MEDIA: 31.09492063522339,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a11884b3-532d-4698-9553-618f9ae40b7b',
    CIDADE: 'Ingazeira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.097660064697266,
    MAXIMO: 31.114431381225586,
    MEDIA: 31.10927402732998,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6484e027-0a09-410d-9d08-7179ee3b263c',
    CIDADE: 'Ipojuca',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.672340393066406,
    MAXIMO: 33.54743576049805,
    MEDIA: 33.1707647894132,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf8384e0-71b3-41a0-a96e-f806dd8c9316',
    CIDADE: 'Ipubi',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.07184600830078,
    MAXIMO: 32.53623580932617,
    MEDIA: 32.30404090881348,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '801e7855-727a-42f1-9799-1e2d7b9d033d',
    CIDADE: 'Itacuruba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.854633331298828,
    MAXIMO: 30.912939071655273,
    MEDIA: 30.886377970377605,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54f4d337-49d6-437a-894e-17a64984528c',
    CIDADE: 'Itaíba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.07806968688965,
    MAXIMO: 31.100950241088867,
    MEDIA: 31.09250768025716,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '26533907-5b88-49a2-b6ae-baf40e7f82f8',
    CIDADE: 'Ilha de Itamaracá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.579938888549805,
    MAXIMO: 31.64320945739746,
    MEDIA: 31.6268836236614,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94658b21-1d3a-4018-b999-3a3f0384df62',
    CIDADE: 'Itambé',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.161977767944336,
    MAXIMO: 31.183439254760742,
    MEDIA: 31.17270851135254,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e47485c2-1812-4db0-afc3-805e588c1df8',
    CIDADE: 'Itapetim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.10702896118164,
    MAXIMO: 31.14661979675293,
    MEDIA: 31.14027357622267,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03329a22-9226-4565-a7f5-2d0b559e51bb',
    CIDADE: 'Itapissuma',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.64320945739746,
    MAXIMO: 31.64320945739746,
    MEDIA: 31.64320945739746,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b63fe581-498f-49ec-895e-7acc2b635f00',
    CIDADE: 'Itaquitinga',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.37444496154785,
    MAXIMO: 31.678396224975586,
    MEDIA: 31.603093712294463,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a81751c-07fa-4723-afac-9f445f976aac',
    CIDADE: 'Jaboatão dos Guararapes',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.26976013183594,
    MAXIMO: 32.672340393066406,
    MEDIA: 32.47553202277397,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf0a2a1e-4982-4131-b99c-084c1416619c',
    CIDADE: 'Jaqueira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.781490325927734,
    MAXIMO: 32.22920608520508,
    MEDIA: 32.12507926063079,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9162d58a-6fc1-43a1-b064-ec729d849c78',
    CIDADE: 'Jataúba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.388866424560547,
    MAXIMO: 31.43393898010254,
    MEDIA: 31.411402702331543,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31547ae1-2aa1-4fe3-b972-df28f372b3c0',
    CIDADE: 'Jatobá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.006444931030273,
    MAXIMO: 31.10032844543457,
    MEDIA: 31.063277372091953,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5c7502a-c7cd-4806-95e2-b34291333c8c',
    CIDADE: 'João Alfredo',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.63516616821289,
    MAXIMO: 31.85923194885254,
    MEDIA: 31.827297945097484,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9fe173d1-1a01-4c0e-a675-6d33ba7a5828',
    CIDADE: 'Joaquim Nabuco',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.58586883544922,
    MAXIMO: 32.950706481933594,
    MEDIA: 32.590454806024944,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44dedd8e-8586-470d-a587-191e10d77b01',
    CIDADE: 'Jucati',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.330171585083008,
    MAXIMO: 31.41936683654785,
    MEDIA: 31.39196506050118,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b7ca925-c3e1-4889-b096-28728e705ff2',
    CIDADE: 'Jupi',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.41936683654785,
    MAXIMO: 31.486068725585938,
    MEDIA: 31.420290904328507,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6c8abbf-3e31-4e71-97a0-f3c08964358e',
    CIDADE: 'Jurema',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.42387580871582,
    MAXIMO: 31.704587936401367,
    MEDIA: 31.621937168211527,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11894df7-af73-4df4-b10f-cb4414021244',
    CIDADE: 'Lagoa do Carro',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.666257858276367,
    MAXIMO: 31.96817398071289,
    MEDIA: 31.929297300161053,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e159b7f-6c2b-4194-b7c0-a2cd333bf816',
    CIDADE: 'Lagoa de Itaenga',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.928895950317383,
    MAXIMO: 31.96817398071289,
    MEDIA: 31.96514293199321,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e359b822-738f-4148-aee0-5b705b061025',
    CIDADE: 'Lagoa do Ouro',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.04536247253418,
    MAXIMO: 31.19743537902832,
    MEDIA: 31.092069134052476,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0bedf955-2ca0-4ee0-a20d-5a5341685982',
    CIDADE: 'Lagoa dos Gatos',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.95081329345703,
    MAXIMO: 32.269535064697266,
    MEDIA: 31.97055718394591,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6222ed04-4897-4c2d-85d0-376d5f1d60dd',
    CIDADE: 'Lagoa Grande',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.2138671875,
    MAXIMO: 30.786787033081055,
    MEDIA: 30.499475479125977,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c27c8a6c-5fb5-46ee-a151-006d424deca3',
    CIDADE: 'Lajedo',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.41936683654785,
    MAXIMO: 31.612844467163086,
    MEDIA: 31.52746459985228,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '767e3f80-5a25-4632-96c8-3767caa5973e',
    CIDADE: 'Limoeiro',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.63516616821289,
    MAXIMO: 31.928895950317383,
    MEDIA: 31.831259527727752,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebc2ca8d-86ae-41a0-ac0a-14e2f1f402a8',
    CIDADE: 'Macaparana',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.1966552734375,
    MAXIMO: 31.423460006713867,
    MEDIA: 31.360604293604805,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b93c49a4-ee3b-4825-9f62-50a49f0d3495',
    CIDADE: 'Machados',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.4104061126709,
    MAXIMO: 31.666257858276367,
    MEDIA: 31.63957116295993,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0f7c2d4-6f7a-4c16-be3b-26eaa9a18ab6',
    CIDADE: 'Manari',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.081008911132812,
    MAXIMO: 31.120973587036133,
    MEDIA: 31.088866954080697,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f68650da-32a1-4090-89e4-0e04ee645684',
    CIDADE: 'Maraial',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.781490325927734,
    MAXIMO: 32.22920608520508,
    MEDIA: 32.07757515827271,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bab1c063-daaf-4a6a-bc05-b3cc63b228ed',
    CIDADE: 'Mirandiba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.05396270751953,
    MAXIMO: 31.104129791259766,
    MEDIA: 31.07904624938965,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '777035f5-a458-4bfe-93d4-e04ec6d7b12e',
    CIDADE: 'Moreno',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.27870559692383,
    MAXIMO: 32.672340393066406,
    MEDIA: 32.4382143614689,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f4ee2b6-6229-43ee-9a7a-70ee56632b14',
    CIDADE: 'Nazaré da Mata',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.678396224975586,
    MAXIMO: 31.684846878051758,
    MEDIA: 31.68471333456849,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f83314f8-17c3-4296-8cd8-7334b2b44176',
    CIDADE: 'Olinda',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.862565994262695,
    MAXIMO: 32.26976013183594,
    MEDIA: 32.09985221741011,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d566ceb-7aea-46bf-be1e-a2380faf414c',
    CIDADE: 'Orobó',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.4104061126709,
    MAXIMO: 31.63516616821289,
    MEDIA: 31.614131936711566,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '848afd87-f103-4bb6-a1e3-04afef60d657',
    CIDADE: 'Orocó',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.72996711730957,
    MAXIMO: 30.839771270751953,
    MEDIA: 30.78486919403076,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '792c51d8-0ebf-45c1-be3c-4ec4e7b5e748',
    CIDADE: 'Ouricuri',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.26279067993164,
    MAXIMO: 31.9066104888916,
    MEDIA: 31.589595249720983,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed50e40f-498c-4f9a-bc91-a11ce32ec790',
    CIDADE: 'Palmares',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.22920608520508,
    MAXIMO: 32.58696746826172,
    MEDIA: 32.49666157335065,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9d9deb87-0e1c-4f44-bbbe-f7a0cc9154f8',
    CIDADE: 'Palmeirina',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.19743537902832,
    MAXIMO: 31.42387580871582,
    MEDIA: 31.265715340046093,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb800bde-27e3-4a6f-8364-b8e63b982b56',
    CIDADE: 'Panelas',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.543621063232422,
    MAXIMO: 32.02351379394531,
    MEDIA: 31.76133219294103,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2c88302-7357-46df-9547-c874be2a1e74',
    CIDADE: 'Paranatama',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.171125411987305,
    MAXIMO: 31.249723434448242,
    MEDIA: 31.202910046014093,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30f5a778-ef28-4d8d-85bc-4b9ed7d00e3e',
    CIDADE: 'Parnamirim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.984981536865234,
    MAXIMO: 31.318634033203125,
    MEDIA: 31.10785484313965,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0c6b1dbf-379b-4bdf-b060-83ac694c02f9',
    CIDADE: 'Passira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.85923194885254,
    MAXIMO: 32.19712448120117,
    MEDIA: 32.02479234329827,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4451664-59ae-47d9-835b-b9d826c9b783',
    CIDADE: 'Paudalho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.96817398071289,
    MAXIMO: 31.97601318359375,
    MEDIA: 31.97209358215332,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a1f70d9-c2ad-419c-8fa2-7f44e91f68d9',
    CIDADE: 'Paulista',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.862565994262695,
    MAXIMO: 31.97601318359375,
    MEDIA: 31.92367958855357,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd945f838-3179-4d59-a5f0-a484502485f0',
    CIDADE: 'Pedra',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.171125411987305,
    MAXIMO: 31.215896606445312,
    MEDIA: 31.19351100921631,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fbd1616d-4e97-473c-9ade-91d53aa8520b',
    CIDADE: 'Pesqueira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.27050018310547,
    MAXIMO: 31.325275421142578,
    MEDIA: 31.300143559773762,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17ac71d7-06cb-4c51-a3fe-3be33534dac0',
    CIDADE: 'Petrolândia',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.001108169555664,
    MAXIMO: 31.045068740844727,
    MEDIA: 31.023088455200195,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2467711-f3ca-4c1f-bc38-720ea7101940',
    CIDADE: 'Petrolina',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.128910064697266,
    MAXIMO: 31.010379791259766,
    MEDIA: 30.526922464370728,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a461d582-b998-49ff-bc0f-0fdb99092f2b',
    CIDADE: 'Poção',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.272506713867188,
    MAXIMO: 31.409711837768555,
    MEDIA: 31.33778479058847,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9646183-5112-479e-bef1-9b24100fdf49',
    CIDADE: 'Pombos',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.19712448120117,
    MAXIMO: 32.60799789428711,
    MEDIA: 32.38120158594625,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ad0b56b-7354-468b-8bfd-06c1640fb0c2',
    CIDADE: 'Primavera',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.469539642333984,
    MAXIMO: 32.957603454589844,
    MEDIA: 32.60454841372676,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3306dc47-b5c9-4e68-b1a6-49b0d653a6f6',
    CIDADE: 'Quipapá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.56905746459961,
    MAXIMO: 31.95081329345703,
    MEDIA: 31.647836689096255,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90683118-960c-47bc-a7cf-b95a36482153',
    CIDADE: 'Quixaba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.072860717773438,
    MAXIMO: 31.134626388549805,
    MEDIA: 31.123300711013755,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c082eb7-f21f-46ac-9883-ef36458c49fb',
    CIDADE: 'Recife',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.940364837646484,
    MAXIMO: 32.63632583618164,
    MEDIA: 32.195357235555434,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f87a59b-2baf-4804-88d0-9a3edd856914',
    CIDADE: 'Riacho das Almas',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.68611717224121,
    MAXIMO: 31.953737258911133,
    MEDIA: 31.848747121481075,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62548c89-584f-4ce4-b36a-b84849058d44',
    CIDADE: 'Ribeirão',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.471961975097656,
    MAXIMO: 33.3143310546875,
    MEDIA: 32.99898864195824,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0716b59-4021-4e31-a88a-0841d5d8dcad',
    CIDADE: 'Rio Formoso',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 33.0578727722168,
    MAXIMO: 34.094154357910156,
    MEDIA: 33.68611885963597,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b87d70bf-f6ba-44b2-baf4-232652a2da00',
    CIDADE: 'Sairé',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.10941696166992,
    MAXIMO: 32.471961975097656,
    MEDIA: 32.20698965228676,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29205d2c-6ff3-43f9-a5ce-a2e6ab302218',
    CIDADE: 'Salgadinho',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.7750186920166,
    MAXIMO: 31.85923194885254,
    MEDIA: 31.855294316207598,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36900335-eb92-438a-8ea5-69426d86832c',
    CIDADE: 'Salgueiro',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.99769401550293,
    MAXIMO: 31.212425231933594,
    MEDIA: 31.076621691385906,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55e4eed4-0d63-4ebe-ab52-d6b85d50663f',
    CIDADE: 'Saloá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.115882873535156,
    MAXIMO: 31.249723434448242,
    MEDIA: 31.15072246938196,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11bd18ca-cf6e-4b00-8341-49500ec2e016',
    CIDADE: 'Sanharó',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.381336212158203,
    MAXIMO: 31.41232681274414,
    MEDIA: 31.41101856728603,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4bcf4c87-60c5-4c3d-834a-9b8f24ed8e40',
    CIDADE: 'Santa Cruz',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.997079849243164,
    MAXIMO: 31.469364166259766,
    MEDIA: 31.23106638590495,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0b0bce7-9304-4268-8195-bdd54d4e8f0d',
    CIDADE: 'Santa Cruz da Baixa Verde',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.101449966430664,
    MAXIMO: 31.12386703491211,
    MEDIA: 31.10899566393004,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '04dea7eb-dd68-47fa-91ef-506cfb1c7135',
    CIDADE: 'Santa Cruz do Capibaribe',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.441749572753906,
    MAXIMO: 31.51340675354004,
    MEDIA: 31.477578163146973,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab460800-f454-4b91-ab99-55c0d8388e3f',
    CIDADE: 'Santa Filomena',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.35687255859375,
    MAXIMO: 31.61739158630371,
    MEDIA: 31.49853579203288,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e43ab5a9-e21d-4a60-9c48-1d2995b9e508',
    CIDADE: 'Santa Maria da Boa Vista',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.245933532714844,
    MAXIMO: 30.916133880615234,
    MEDIA: 30.643918124112215,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ca4c62ae-2ab7-4a14-a071-754dc7cc4e61',
    CIDADE: 'Santa Maria do Cambucá',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.530866622924805,
    MAXIMO: 31.68611717224121,
    MEDIA: 31.602898330031103,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e68ef83-2e24-483f-843b-edec02667596',
    CIDADE: 'Santa Terezinha',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.079029083251953,
    MAXIMO: 31.105268478393555,
    MEDIA: 31.079928628979324,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85d0ff40-589c-4f03-8b4d-969d26cfa78e',
    CIDADE: 'São Benedito do Sul',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.781490325927734,
    MAXIMO: 31.95081329345703,
    MEDIA: 31.863470168871146,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4ddbf5d-b6ff-470a-b41a-468106355157',
    CIDADE: 'São Bento do Una',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.381336212158203,
    MAXIMO: 31.51127815246582,
    MEDIA: 31.459561030069988,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db70ce83-3155-463e-9e0c-aabf6c370afa',
    CIDADE: 'São Caitano',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.612844467163086,
    MAXIMO: 31.836706161499023,
    MEDIA: 31.734971513773836,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9873c30a-1aaf-4364-ab86-e1f361649b53',
    CIDADE: 'São João',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.19743537902832,
    MAXIMO: 31.42387580871582,
    MEDIA: 31.325611369043678,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98a6b528-b70c-412e-aadf-0ddb0d85c0ab',
    CIDADE: 'São Joaquim do Monte',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.004581451416016,
    MAXIMO: 32.269535064697266,
    MEDIA: 32.06967302094594,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b3dd29b-a0e1-402c-8c75-0dc7ea753933',
    CIDADE: 'São José da Coroa Grande',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 33.65459442138672,
    MAXIMO: 34.66779327392578,
    MEDIA: 34.04402097143839,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad078d98-36b6-419c-bf4c-fc8701b5d1ea',
    CIDADE: 'São José do Belmonte',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.112302780151367,
    MAXIMO: 31.256397247314453,
    MEDIA: 31.180764389038085,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e61d1f2-8b67-4e85-9a16-84b5695f7643',
    CIDADE: 'São José do Egito',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.105268478393555,
    MAXIMO: 31.145244598388672,
    MEDIA: 31.122695287068684,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82852e0a-34c9-426b-8273-4429e6c475a4',
    CIDADE: 'São Lourenço da Mata',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.96817398071289,
    MAXIMO: 32.30457305908203,
    MEDIA: 32.23147384653963,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0f4c46f4-1a27-4f89-b771-9797f6fbe76d',
    CIDADE: 'São Vicente Férrer',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.4104061126709,
    MAXIMO: 31.666257858276367,
    MEDIA: 31.43225589432927,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d9dea6d-9a10-481b-b452-3e593205a9f5',
    CIDADE: 'Serra Talhada',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.02267837524414,
    MAXIMO: 31.15998649597168,
    MEDIA: 31.092649113048207,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b267d5e-575e-4493-86b5-2630e94c2414',
    CIDADE: 'Serrita',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.233308792114258,
    MAXIMO: 31.417104721069336,
    MEDIA: 31.3201322555542,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4e60fdc-9174-4aed-aa1a-18d685a74a83',
    CIDADE: 'Sertânia',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.09562873840332,
    MAXIMO: 31.172710418701172,
    MEDIA: 31.138734340667725,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7bc7258b-089c-4751-a279-af5229ad2c1e',
    CIDADE: 'Sirinhaém',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 33.3143310546875,
    MAXIMO: 33.54743576049805,
    MEDIA: 33.43088340759277,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7571dd4-6b77-41fd-a90a-d0dd32b59add',
    CIDADE: 'Moreilândia',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.417104721069336,
    MAXIMO: 31.703081130981445,
    MEDIA: 31.547032291222873,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a8c8c6b-d85e-46dc-b918-02d8cd6a5fd8',
    CIDADE: 'Solidão',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.078227996826172,
    MAXIMO: 31.0836181640625,
    MEDIA: 31.079335521278615,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9b92f04-bebd-4dc8-bc46-11714b8e54da',
    CIDADE: 'Surubim',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.530866622924805,
    MAXIMO: 31.85923194885254,
    MEDIA: 31.756616699858437,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52954e07-4380-4b77-b3b1-ac122d466cb9',
    CIDADE: 'Tabira',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.078227996826172,
    MAXIMO: 31.114431381225586,
    MEDIA: 31.086533504799554,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e6224fd-bec7-4cfe-9a04-c1681ab295d6',
    CIDADE: 'Tacaimbó',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.51127815246582,
    MAXIMO: 31.672454833984375,
    MEDIA: 31.63943989464672,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c13e52e0-af9f-4a27-a3a9-15840f8c347a',
    CIDADE: 'Tacaratu',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.040746688842773,
    MAXIMO: 31.082332611083984,
    MEDIA: 31.06535758972168,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18c55e01-7b14-4c46-8b11-72c25b24dcc2',
    CIDADE: 'Tamandaré',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 33.0578727722168,
    MAXIMO: 34.094154357910156,
    MEDIA: 33.704239983893096,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd69357a6-196c-4805-bc04-996deee38b37',
    CIDADE: 'Taquaritinga do Norte',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.413257598876953,
    MAXIMO: 31.706253051757812,
    MEDIA: 31.536887131120885,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b51b177-87ce-4ac6-bd7a-d7762ab01de7',
    CIDADE: 'Terezinha',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.1268367767334,
    MAXIMO: 31.153627395629883,
    MEDIA: 31.140819115183206,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c82c577e-2348-40d3-934d-97884f133882',
    CIDADE: 'Terra Nova',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 30.984981536865234,
    MAXIMO: 31.12320327758789,
    MEDIA: 31.040375473142525,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2783657-6339-40ec-9019-231ede121784',
    CIDADE: 'Timbaúba',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.183439254760742,
    MAXIMO: 31.423595428466797,
    MEDIA: 31.36732983947491,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84b49c05-f8b6-4245-944f-a3825810dcd7',
    CIDADE: 'Toritama',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.599590301513672,
    MAXIMO: 31.706253051757812,
    MEDIA: 31.682885385542335,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '91cc177a-5d54-40aa-9410-800f296327e5',
    CIDADE: 'Tracunhaém',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.407968521118164,
    MAXIMO: 31.684846878051758,
    MEDIA: 31.679589256755854,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1dc4a220-84fb-4d28-8a81-ee8dfbc089c6',
    CIDADE: 'Trindade',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.72238540649414,
    MAXIMO: 32.258663177490234,
    MEDIA: 32.0367835354315,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ad39c5f-9625-4e7f-b5dd-f8e224196904',
    CIDADE: 'Triunfo',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.09992027282715,
    MAXIMO: 31.127735137939453,
    MEDIA: 31.107761210263327,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff0975b9-49b5-4263-a4ce-9837c675c8a1',
    CIDADE: 'Tupanatinga',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.1122989654541,
    MAXIMO: 31.122032165527344,
    MEDIA: 31.11829948425293,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '089f2e58-c017-441e-b526-cf830ff0106b',
    CIDADE: 'Tuparetama',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.105268478393555,
    MAXIMO: 31.146392822265625,
    MEDIA: 31.128517933279557,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b9571d27-518b-4aba-b03e-fa91a722b95a',
    CIDADE: 'Venturosa',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.247888565063477,
    MAXIMO: 31.25834846496582,
    MEDIA: 31.25311851501465,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e61d090-f095-4261-8759-5f58a9b944b6',
    CIDADE: 'Verdejante',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.10124969482422,
    MAXIMO: 31.28545379638672,
    MEDIA: 31.151382461592057,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d921d99-71db-4a84-9a3e-8a22ae2d0b2c',
    CIDADE: 'Vertente do Lério',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.530866622924805,
    MAXIMO: 31.586177825927734,
    MEDIA: 31.562366994738383,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd536816-0ec6-4c85-9e39-e3690fb2b913',
    CIDADE: 'Vertentes',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.474241256713867,
    MAXIMO: 31.68611717224121,
    MEDIA: 31.622006199474818,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a8fc084-123c-43b9-85da-30134da78ae4',
    CIDADE: 'Vicência',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 31.423460006713867,
    MAXIMO: 31.684846878051758,
    MEDIA: 31.53185926818056,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7607646e-8309-4aeb-a2b8-4ec1bc72a9d8',
    CIDADE: 'Vitória de Santo Antão',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.19712448120117,
    MAXIMO: 32.60799789428711,
    MEDIA: 32.40429152105173,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '829a472a-ff21-440c-a9a7-be205cb935b4',
    CIDADE: 'Xexéu',
    SIGLA: 'PE',
    ESTADO: 'Pernambuco',
    MINIMO: 32.06206130981445,
    MAXIMO: 32.58696746826172,
    MEDIA: 32.299740667700114,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a86c9069-da2c-4b91-8e98-19e656180077',
    CIDADE: 'Água Branca',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.122732162475586,
    MAXIMO: 31.14752960205078,
    MEDIA: 31.135130882263184,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4994dee7-8b97-4e47-83e4-a24ef34dcd90',
    CIDADE: 'Anadia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.56952667236328,
    MAXIMO: 30.77242660522461,
    MEDIA: 30.63377586249296,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f5820295-1e68-4c06-b3f6-255b8f8429f5',
    CIDADE: 'Arapiraca',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.69754981994629,
    MAXIMO: 30.83833885192871,
    MEDIA: 30.7679443359375,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c5a1256a-2785-418a-a648-bd5aff168025',
    CIDADE: 'Atalaia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.66810417175293,
    MAXIMO: 30.95770835876465,
    MEDIA: 30.740020398520837,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b39bb074-9634-4e05-a5da-eb4c2dcf0a7c',
    CIDADE: 'Barra de Santo Antônio',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.068540573120117,
    MAXIMO: 31.494983673095703,
    MEDIA: 31.246856527876854,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ea266b61-f3f5-4363-b061-a642f6f1b27d',
    CIDADE: 'Barra de São Miguel',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.219865798950195,
    MAXIMO: 30.482736587524414,
    MEDIA: 30.252945297578208,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '94c600b1-270a-4fbb-813a-b1627bfa0ad3',
    CIDADE: 'Batalha',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.020530700683594,
    MAXIMO: 31.088285446166992,
    MEDIA: 31.054408073425293,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '01fbe8ef-31cc-4364-829a-0c197a5c6ea8',
    CIDADE: 'Belém',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.77242660522461,
    MAXIMO: 30.83220672607422,
    MEDIA: 30.820156783514843,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '69096bb8-c02f-4b09-8471-0dae92f368dd',
    CIDADE: 'Belo Monte',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.020530700683594,
    MAXIMO: 31.184173583984375,
    MEDIA: 31.094963559567102,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '18321ab2-2c3b-4eff-9568-ffad1a95c8bd',
    CIDADE: 'Boca da Mata',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.482736587524414,
    MAXIMO: 30.718738555908203,
    MEDIA: 30.561596486215965,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '69cbc787-e502-48a3-8986-f2bb1a6908e6',
    CIDADE: 'Branquinha',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.086624145507812,
    MAXIMO: 31.2559871673584,
    MEDIA: 31.168767091899596,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8ac670dc-cd27-46e1-a0c3-f163cb006599',
    CIDADE: 'Cacimbinhas',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.955516815185547,
    MAXIMO: 31.033750534057617,
    MEDIA: 30.99365651094223,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '57295a36-8831-467a-b7ed-515a723cf8f6',
    CIDADE: 'Cajueiro',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.718738555908203,
    MAXIMO: 30.90792465209961,
    MEDIA: 30.894378504189582,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e2ad5173-18a7-4c83-bc27-fb87255ea1f6',
    CIDADE: 'Campestre',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 32.43436813354492,
    MAXIMO: 32.94199752807617,
    MEDIA: 32.436778990595954,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6452a98a-b761-4235-a52f-114c16ff73aa',
    CIDADE: 'Campo Alegre',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.465730667114258,
    MAXIMO: 30.660451889038086,
    MEDIA: 30.531103608945674,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '52febded-b7da-4950-852e-795a41afd960',
    CIDADE: 'Campo Grande',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.813190460205078,
    MAXIMO: 30.939163208007812,
    MEDIA: 30.822225648696445,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5baff4bc-2b6c-4f6b-9201-a6162b56dbb3',
    CIDADE: 'Canapi',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.120973587036133,
    MAXIMO: 31.121753692626953,
    MEDIA: 31.121363639831543,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '83df85e1-1fc2-4ca9-97b1-dd7373694c3e',
    CIDADE: 'Capela',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.678865432739258,
    MAXIMO: 31.149646759033203,
    MEDIA: 30.962296628606083,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e2b48801-0be5-4fd8-b64a-f843db9e5643',
    CIDADE: 'Carneiros',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.082782745361328,
    MAXIMO: 31.122650146484375,
    MEDIA: 31.11791951762994,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a294f93f-6aa6-4b0c-968a-6b464c98a455',
    CIDADE: 'Chã Preta',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.8968505859375,
    MAXIMO: 31.086624145507812,
    MEDIA: 31.072294691025423,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '291f819f-11e2-4afd-8bd7-bfb5df4b9918',
    CIDADE: 'Coité do Nóia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.75101661682129,
    MAXIMO: 30.83220672607422,
    MEDIA: 30.784997082693817,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2ec41c91-e897-4912-a716-8a073c5086fc',
    CIDADE: 'Colônia Leopoldina',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.549654006958008,
    MAXIMO: 32.43436813354492,
    MEDIA: 31.971816794713618,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '875205c3-b0a9-42db-bf07-6ac2a4662a2f',
    CIDADE: 'Coqueiro Seco',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.38674545288086,
    MAXIMO: 30.71673011779785,
    MEDIA: 30.433739296458246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f0a41f55-f773-45c0-8055-f6c930b6fd5b',
    CIDADE: 'Coruripe',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.28419303894043,
    MAXIMO: 30.418384552001953,
    MEDIA: 30.372832616170246,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '16c4699b-00e0-4f6b-b5c3-56e858d8a692',
    CIDADE: 'Craíbas',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.83833885192871,
    MAXIMO: 30.955516815185547,
    MEDIA: 30.897193448148744,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '563106cb-9ef4-4141-af2e-9f2d3429a370',
    CIDADE: 'Delmiro Gouveia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.086034774780273,
    MAXIMO: 31.13099479675293,
    MEDIA: 31.1085147857666,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c31666ed-5347-4188-9163-3ddc31b1d0d5',
    CIDADE: 'Dois Riachos',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.03328514099121,
    MAXIMO: 31.091205596923828,
    MEDIA: 31.036212450376855,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6ba48b67-06e8-4551-bbce-6a8727a7af10',
    CIDADE: 'Estrela de Alagoas',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.892850875854492,
    MAXIMO: 31.045047760009766,
    MEDIA: 30.96655272404588,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eebc2b47-1cb9-4075-95fb-87c587695cfe',
    CIDADE: 'Feira Grande',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.68548583984375,
    MAXIMO: 30.819372177124023,
    MEDIA: 30.7895707470413,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7f4696b6-9767-4d4e-9118-bcdcb2e5a1bc',
    CIDADE: 'Feliz Deserto',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.418384552001953,
    MAXIMO: 30.59812355041504,
    MEDIA: 30.54942903176616,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '605279c9-6753-4519-8978-d5795c58efd2',
    CIDADE: 'Flexeiras',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.068540573120117,
    MAXIMO: 31.43467140197754,
    MEDIA: 31.251605987548828,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1a7f4b54-f52f-411c-8c39-51a846290eeb',
    CIDADE: 'Girau do Ponciano',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.929203033447266,
    MAXIMO: 30.943153381347656,
    MEDIA: 30.93617820739746,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5443d8fe-0d23-41ae-8034-1668ac14acd0',
    CIDADE: 'Ibateguara',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.549654006958008,
    MAXIMO: 31.781490325927734,
    MEDIA: 31.66557216644287,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'aaba68b9-0ec4-4f20-a6a3-a9ac63d25bef',
    CIDADE: 'Igaci',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.83220672607422,
    MAXIMO: 30.892850875854492,
    MEDIA: 30.862528800964355,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '67617d54-a37d-44ab-aa52-8483dcd03af2',
    CIDADE: 'Igreja Nova',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.68548583984375,
    MAXIMO: 30.835899353027344,
    MEDIA: 30.752862641004267,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eabf55b5-5020-4480-bfcf-84baecf5af5a',
    CIDADE: 'Inhapi',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.117151260375977,
    MAXIMO: 31.13616943359375,
    MEDIA: 31.123782836365706,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef657813-54c3-4ba9-9940-346ff34ecb27',
    CIDADE: 'Jacaré dos Homens',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.082782745361328,
    MAXIMO: 31.144731521606445,
    MEDIA: 31.088545099785957,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0c3492da-d791-4529-91c0-fcad1ed2310f',
    CIDADE: 'Jacuípe',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 32.43436813354492,
    MAXIMO: 32.94199752807617,
    MEDIA: 32.847792855408926,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '23af82c1-2da6-4a22-9d55-3574454345ba',
    CIDADE: 'Japaratinga',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 32.41655731201172,
    MAXIMO: 33.1379508972168,
    MEDIA: 33.081607627200974,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e714b7e1-c3db-45d6-9e97-857d6142f9a4',
    CIDADE: 'Jaramataia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.943153381347656,
    MAXIMO: 31.033750534057617,
    MEDIA: 31.006854165929436,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8b4ce1dd-193a-4bc6-9bcb-f76c39382b79',
    CIDADE: 'Jequiá da Praia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.156099319458008,
    MAXIMO: 30.465730667114258,
    MEDIA: 30.358784568361767,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '60606ff2-daf1-4ebc-83eb-572b3643cd9f',
    CIDADE: 'Joaquim Gomes',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.2559871673584,
    MAXIMO: 32.11054611206055,
    MEDIA: 31.705147447142288,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e96a7830-1ca6-4f66-832e-04e7471ab9c8',
    CIDADE: 'Jundiá',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 32.11054611206055,
    MAXIMO: 32.94199752807617,
    MEDIA: 32.37330548103897,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3c9ae33e-ac9f-4ca0-83f0-07866a28109d',
    CIDADE: 'Junqueiro',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.547212600708008,
    MAXIMO: 30.69754981994629,
    MEDIA: 30.6116782592579,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ce6cb03b-feaa-4386-aa79-a6ae30753408',
    CIDADE: 'Lagoa da Canoa',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.819372177124023,
    MAXIMO: 30.83833885192871,
    MEDIA: 30.82573435591467,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9e706768-907c-46fc-9211-8beaa902f1f6',
    CIDADE: 'Limoeiro de Anadia',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.660451889038086,
    MAXIMO: 30.75101661682129,
    MEDIA: 30.705734252929688,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e44134ee-dc12-49a6-89dc-f6707dec27ce',
    CIDADE: 'Maceió',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.38674545288086,
    MAXIMO: 31.2464656829834,
    MEDIA: 30.777731113659687,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5786d21c-fefe-4eec-9526-92475fcde76e',
    CIDADE: 'Major Isidoro',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.955516815185547,
    MAXIMO: 31.033750534057617,
    MEDIA: 30.994633674621582,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '06155ea0-4ce5-4362-ae70-494a48fbb781',
    CIDADE: 'Maragogi',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 33.1379508972168,
    MAXIMO: 33.65459442138672,
    MEDIA: 33.39627265930176,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4d20fefa-137e-4951-b6f6-f8e362ba0dcf',
    CIDADE: 'Maravilha',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.07806968688965,
    MAXIMO: 31.130096435546875,
    MEDIA: 31.114256708037022,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '55381790-32ce-43e5-b288-c519272ae6f0',
    CIDADE: 'Marechal Deodoro',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.219865798950195,
    MAXIMO: 30.482736587524414,
    MEDIA: 30.406679384181103,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8cf3950d-f76d-4874-ab06-8fac2b8da1cb',
    CIDADE: 'Maribondo',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.56952667236328,
    MAXIMO: 30.77242660522461,
    MEDIA: 30.740691114180695,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b5dd926d-284b-4f82-88cb-969b3434514e',
    CIDADE: 'Mar Vermelho',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.77242660522461,
    MAXIMO: 30.908798217773438,
    MEDIA: 30.81221049995224,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ebaff7f1-5a94-4dd2-bc47-431b4ff55838',
    CIDADE: 'Mata Grande',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.107595443725586,
    MAXIMO: 31.109066009521484,
    MEDIA: 31.108330726623535,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f92b9315-e722-495e-8805-f3cb9d97481c',
    CIDADE: 'Matriz de Camaragibe',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.683624267578125,
    MAXIMO: 32.11054611206055,
    MEDIA: 31.951286908953463,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '69195b9b-d22b-4d32-b619-755ba354dac5',
    CIDADE: 'Messias',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.95770835876465,
    MAXIMO: 31.43467140197754,
    MEDIA: 31.077534421772064,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ba8925a4-65dc-4b33-8c2a-27b505a1583e',
    CIDADE: 'Minador do Negrão',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.96380615234375,
    MAXIMO: 31.05876350402832,
    MEDIA: 31.008460018630856,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c8291b85-35c6-48da-90dd-1ca1c008ceae',
    CIDADE: 'Monteirópolis',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.082782745361328,
    MAXIMO: 31.144731521606445,
    MEDIA: 31.110054348425635,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3853e5af-abc3-4b4e-8fc9-d81faf928e3a',
    CIDADE: 'Murici',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.95770835876465,
    MAXIMO: 31.2559871673584,
    MEDIA: 31.106847763061523,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '59080b9a-f935-4f3e-bf06-3a8725066567',
    CIDADE: 'Novo Lino',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.77859115600586,
    MAXIMO: 32.43436813354492,
    MEDIA: 32.19240514716006,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b4c97f9b-bb69-47bf-affa-57c580944110',
    CIDADE: "Olho d'Água das Flores",
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.033750534057617,
    MAXIMO: 31.122650146484375,
    MEDIA: 31.0958593251701,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '546172d8-7718-42ea-8f05-03f056138978',
    CIDADE: "Olho d'Água do Casado",
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.117151260375977,
    MAXIMO: 31.1882266998291,
    MEDIA: 31.160238192495246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd6f53f7b-0897-4206-b3e7-c68034ceb7c4',
    CIDADE: "Olho d'Água Grande",
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.813190460205078,
    MAXIMO: 30.939163208007812,
    MEDIA: 30.86733951210428,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '22bb6b51-ecf7-494c-a8e6-2a46c3fd420e',
    CIDADE: 'Olivença',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.03328514099121,
    MAXIMO: 31.091205596923828,
    MEDIA: 31.079225974396504,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '78631cf4-3527-42bb-a465-cde9023b5df6',
    CIDADE: 'Ouro Branco',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.07806968688965,
    MAXIMO: 31.11910629272461,
    MEDIA: 31.103969934400133,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '94138b01-cb10-4fb7-9a37-46d495fce205',
    CIDADE: 'Palestina',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.088285446166992,
    MAXIMO: 31.144731521606445,
    MEDIA: 31.130879883593995,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cce1f9f5-da31-49a7-8c8e-52b9bf809f1c',
    CIDADE: 'Palmeira dos Índios',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.77242660522461,
    MAXIMO: 31.045047760009766,
    MEDIA: 30.92185240174262,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '61d7dd65-ea56-487e-abb6-2b8409cb124a',
    CIDADE: 'Pão de Açúcar',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.144731521606445,
    MAXIMO: 31.188398361206055,
    MEDIA: 31.16656494140625,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c6b943f4-cb93-4977-aaf9-a1edadaaa6f1',
    CIDADE: 'Pariconha',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.067537307739258,
    MAXIMO: 31.13099479675293,
    MEDIA: 31.099258655681975,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5718fe55-1c91-4116-b53e-ab1cd707449f',
    CIDADE: 'Paripueira',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.843006134033203,
    MAXIMO: 31.2464656829834,
    MEDIA: 31.17877774886121,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3172a49-1841-4113-861a-b00698c061d0',
    CIDADE: 'Passo de Camaragibe',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.2464656829834,
    MAXIMO: 32.02348709106445,
    MEDIA: 31.74600576827376,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3fac37b9-17f7-4546-b6c1-8efcedbaff79',
    CIDADE: 'Paulo Jacinto',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.908798217773438,
    MAXIMO: 31.05614471435547,
    MEDIA: 30.913941571360844,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd017c9a1-94e3-44a2-9036-0248538cf874',
    CIDADE: 'Penedo',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.560728073120117,
    MAXIMO: 30.74358558654785,
    MEDIA: 30.66699981689453,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '12cfbbcd-e1a5-4a68-84ed-29952cbf8aad',
    CIDADE: 'Piaçabuçu',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.461509704589844,
    MAXIMO: 30.74358558654785,
    MEDIA: 30.61704987207393,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '107b491b-d445-46bf-a860-f31dd05eef14',
    CIDADE: 'Pilar',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.409879684448242,
    MAXIMO: 30.718738555908203,
    MEDIA: 30.584976590084953,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '619f17c4-084e-4f68-9913-989f9556090f',
    CIDADE: 'Pindoba',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.718738555908203,
    MAXIMO: 30.908798217773438,
    MEDIA: 30.77960485431967,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4be8bbe4-542c-42fe-9381-f0880d11ab07',
    CIDADE: 'Piranhas',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.13616943359375,
    MAXIMO: 31.169023513793945,
    MEDIA: 31.152596473693848,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dfb8649f-8599-4709-9858-c73058c9b20d',
    CIDADE: 'Poço das Trincheiras',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.07806968688965,
    MAXIMO: 31.130096435546875,
    MEDIA: 31.102913747510886,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2bce1365-cc8e-4b65-b5f6-a032653605b3',
    CIDADE: 'Porto Calvo',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 32.02348709106445,
    MAXIMO: 33.1379508972168,
    MEDIA: 32.53504165752355,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fafd8468-985d-436f-84c6-d0136ecb94c0',
    CIDADE: 'Porto de Pedras',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.683624267578125,
    MAXIMO: 33.1379508972168,
    MEDIA: 32.25936860528479,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5a321ef0-60f9-4281-84bf-37907ca20341',
    CIDADE: 'Porto Real do Colégio',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.813190460205078,
    MAXIMO: 30.975027084350586,
    MEDIA: 30.843162679687897,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3832b20-a770-42c6-a482-655214cb7a27',
    CIDADE: 'Quebrangulo',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.908798217773438,
    MAXIMO: 31.05614471435547,
    MEDIA: 30.982739897631912,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b19ec28b-1ef7-4820-8580-cd0619d89330',
    CIDADE: 'Rio Largo',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.66810417175293,
    MAXIMO: 31.068540573120117,
    MEDIA: 30.79452380839843,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0f1c6e4a-3fc2-4018-b344-c0997357de8e',
    CIDADE: 'Roteiro',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.219865798950195,
    MAXIMO: 30.344924926757812,
    MEDIA: 30.279569914403247,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dda461f4-39bb-4274-8262-7d77655ddef4',
    CIDADE: 'Santa Luzia do Norte',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.38674545288086,
    MAXIMO: 30.71673011779785,
    MEDIA: 30.603637480730104,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'db9cdcc7-2966-4f3b-bc2a-10dc9796982d',
    CIDADE: 'Santana do Ipanema',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.091205596923828,
    MAXIMO: 31.114774703979492,
    MEDIA: 31.10299015045166,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c7092d63-f299-4a88-8f0f-6c8731bb31f1',
    CIDADE: 'Santana do Mundaú',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.086624145507812,
    MAXIMO: 31.375951766967773,
    MEDIA: 31.191550690947686,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4d03d50e-49c1-465a-bffc-b5391d744bf5',
    CIDADE: 'São Brás',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.813190460205078,
    MAXIMO: 30.975027084350586,
    MEDIA: 30.948173512909047,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '68d690c6-8b23-4945-8f30-a7d9902a3227',
    CIDADE: 'São José da Laje',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.375951766967773,
    MAXIMO: 31.781490325927734,
    MEDIA: 31.473518162694383,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6cdf10b6-17e1-40b8-8a80-ab8a692efb8c',
    CIDADE: 'São José da Tapera',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.122650146484375,
    MAXIMO: 31.151819229125977,
    MEDIA: 31.137234687805176,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '664599d9-32cd-4473-896c-3b2e0ae6e3a4',
    CIDADE: 'São Luís do Quitunde',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.068540573120117,
    MAXIMO: 32.11054611206055,
    MEDIA: 31.47419868378993,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd099cb34-aa0c-4991-aa4a-12d14602fafa',
    CIDADE: 'São Miguel dos Campos',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.344924926757812,
    MAXIMO: 30.482736587524414,
    MEDIA: 30.413830757141113,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '054f969d-8915-4398-8c5d-c12d767b5b26',
    CIDADE: 'São Miguel dos Milagres',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 32.02348709106445,
    MAXIMO: 32.02348709106445,
    MEDIA: 32.02348709106445,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6c3d4305-97b8-42d3-baec-5c271e35f53a',
    CIDADE: 'São Sebastião',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.547212600708008,
    MAXIMO: 30.813190460205078,
    MEDIA: 30.691363682652298,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3dee8c03-a1d4-4e77-8767-8a43177ee333',
    CIDADE: 'Satuba',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.409879684448242,
    MAXIMO: 30.71673011779785,
    MEDIA: 30.675643707431153,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9d9de91c-b44a-4610-a793-b27410396ac3',
    CIDADE: 'Senador Rui Palmeira',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.114774703979492,
    MAXIMO: 31.151819229125977,
    MEDIA: 31.127459123600882,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8e079838-0130-4829-8aea-98391ea03c12',
    CIDADE: "Tanque d'Arca",
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.660451889038086,
    MAXIMO: 30.83220672607422,
    MEDIA: 30.77028242238386,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c70ca8b7-768d-4623-8a70-53cef5286cc9',
    CIDADE: 'Taquarana',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.660451889038086,
    MAXIMO: 30.83220672607422,
    MEDIA: 30.75921158051423,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5577fb92-03b1-49cc-8fb0-b074c8015f21',
    CIDADE: 'Teotônio Vilela',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.41592025756836,
    MAXIMO: 30.68548583984375,
    MEDIA: 30.545174809228392,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2ffcb2af-d9a7-4d6f-8509-8b8ea377219c',
    CIDADE: 'Traipu',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.939163208007812,
    MAXIMO: 31.025726318359375,
    MEDIA: 30.982444763183594,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8251ff84-6f4d-4a7e-8fb7-74fff48afa4d',
    CIDADE: 'União dos Palmares',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 31.086624145507812,
    MAXIMO: 31.549654006958008,
    MEDIA: 31.344528739331835,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b931e2c3-ae0e-4799-bc78-accdb9e08020',
    CIDADE: 'Viçosa',
    SIGLA: 'AL',
    ESTADO: 'Alagoas',
    MINIMO: 30.77242660522461,
    MAXIMO: 31.086624145507812,
    MEDIA: 30.92054769428622,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f467c1d8-f02f-4de4-8151-78e1134804ad',
    CIDADE: 'Amparo do São Francisco',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.975027084350586,
    MAXIMO: 30.975027084350586,
    MEDIA: 30.975027084350586,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '688c032f-464d-4759-98d6-44f56f24877b',
    CIDADE: 'Aquidabã',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.975027084350586,
    MAXIMO: 31.30881118774414,
    MEDIA: 31.14667520950954,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86b51063-b531-4d95-bb10-ba919a6b89a6',
    CIDADE: 'Aracaju',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.559152603149414,
    MAXIMO: 32.169498443603516,
    MEDIA: 31.76623726216168,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da9ce45c-3039-4d33-9f72-642a6e4156b3',
    CIDADE: 'Arauá',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.59905242919922,
    MAXIMO: 33.130767822265625,
    MEDIA: 32.844785054174,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '046cc6dc-6f6d-4798-9970-239f25e1cff9',
    CIDADE: 'Areia Branca',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.581249237060547,
    MAXIMO: 31.915796279907227,
    MEDIA: 31.84127691919045,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fd21fc9-fbf8-4d04-9702-3c82466a1cde',
    CIDADE: 'Barra dos Coqueiros',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.244121551513672,
    MAXIMO: 31.737268447875977,
    MEDIA: 31.48135894652716,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a757cf0a-b458-42a4-a37a-95cbf470d56a',
    CIDADE: 'Boquim',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.31905746459961,
    MAXIMO: 32.78394317626953,
    MEDIA: 32.55150032043457,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '538d0a32-2471-4fdd-bd19-8c204dc2f276',
    CIDADE: 'Brejo Grande',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.59812355041504,
    MAXIMO: 30.812456130981445,
    MEDIA: 30.725654715111727,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fbddfc1-7800-4b38-a8c4-8b5a9a7f84e0',
    CIDADE: 'Campo do Brito',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.735761642456055,
    MAXIMO: 32.07386779785156,
    MEDIA: 31.952212242093417,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4abb3c3-c3a1-40b4-8666-1e9c73f3f3d3',
    CIDADE: 'Canhoba',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.939163208007812,
    MAXIMO: 31.221546173095703,
    MEDIA: 31.062022816457812,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '620696af-b48a-4b73-8d49-e72c2ce8f7a2',
    CIDADE: 'Canindé de São Francisco',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.233400344848633,
    MAXIMO: 31.233783721923828,
    MEDIA: 31.23359203338623,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1fcfe5a-318f-4b8d-b905-044bdfd4908e',
    CIDADE: 'Capela',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.179702758789062,
    MAXIMO: 31.28755760192871,
    MEDIA: 31.233630180358887,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96f782b1-09c1-447b-b1cf-b9b7dc2d8c0a',
    CIDADE: 'Carira',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.408946990966797,
    MAXIMO: 31.870925903320312,
    MEDIA: 31.63417805141138,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac0cf337-f6c2-4e0c-891a-a07b14d02fed',
    CIDADE: 'Carmópolis',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.244121551513672,
    MAXIMO: 31.415637969970703,
    MEDIA: 31.34499721136423,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc69da3d-1c49-49dd-88a4-7b6ba074ea50',
    CIDADE: 'Cedro de São João',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.975027084350586,
    MAXIMO: 31.037294387817383,
    MEDIA: 30.996929212827645,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3fe1203b-1bf7-48bb-8bb6-9c808ebbb36a',
    CIDADE: 'Cristinápolis',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 33.130767822265625,
    MAXIMO: 33.73882293701172,
    MEDIA: 33.54666532448501,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1cfd41a2-f90f-4536-ab62-60003bf5c761',
    CIDADE: 'Cumbe',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.179702758789062,
    MAXIMO: 31.320737838745117,
    MEDIA: 31.29852093379936,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93c0f318-406a-4665-baa4-5249f8b59613',
    CIDADE: 'Divina Pastora',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.415637969970703,
    MAXIMO: 31.581249237060547,
    MEDIA: 31.554601797389687,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fec6bb1-9867-46e7-8cd6-fa6ff56dc63b',
    CIDADE: 'Estância',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.39201736450195,
    MAXIMO: 32.67767333984375,
    MEDIA: 32.55624771118164,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c748efc1-1d32-4e81-a3fa-ae77643b3866',
    CIDADE: 'Feira Nova',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.221546173095703,
    MAXIMO: 31.428613662719727,
    MEDIA: 31.365015734122544,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44b80e0d-1ddf-475f-9771-6711e855b17f',
    CIDADE: 'Frei Paulo',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.68327522277832,
    MAXIMO: 31.78438377380371,
    MEDIA: 31.733829498291016,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a91abb1-ed3a-4974-bfec-387845308373',
    CIDADE: 'Gararu',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.052650451660156,
    MAXIMO: 31.243816375732422,
    MEDIA: 31.150472005208332,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0fc40738-dfef-4fd5-9310-42f191940008',
    CIDADE: 'General Maynard',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.244121551513672,
    MAXIMO: 31.415637969970703,
    MEDIA: 31.344498019375088,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b070891f-88e2-4839-a7b6-9e4ef6894449',
    CIDADE: 'Gracho Cardoso',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.221546173095703,
    MAXIMO: 31.320737838745117,
    MEDIA: 31.24519760455975,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b695c393-3fec-49fa-9359-de41bebb6bc9',
    CIDADE: 'Ilha das Flores',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.74358558654785,
    MAXIMO: 30.812456130981445,
    MEDIA: 30.79908236981996,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14b3db97-1551-46ec-ab73-a145e7293103',
    CIDADE: 'Indiaroba',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.91621780395508,
    MAXIMO: 33.52001953125,
    MEDIA: 33.22980851449619,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8eedfb6e-9f55-4d57-bb70-80c7a796ec89',
    CIDADE: 'Itabaiana',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.562959671020508,
    MAXIMO: 31.915796279907227,
    MEDIA: 31.778877552869293,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2fff6c7c-0311-4dd5-86c2-2148cbd75349',
    CIDADE: 'Itabaianinha',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.78394317626953,
    MAXIMO: 33.31911849975586,
    MEDIA: 33.04375234953093,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4193fd0-150a-4770-82c1-96654321ed79',
    CIDADE: 'Itabi',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.052650451660156,
    MAXIMO: 31.221546173095703,
    MEDIA: 31.145111332129982,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0948b298-a434-4515-acbd-b34f8323f4ef',
    CIDADE: "Itaporanga d'Ajuda",
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.13814926147461,
    MAXIMO: 32.169498443603516,
    MEDIA: 32.15382385253906,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76edf8f4-f5ab-429b-9c36-3a0fea2bde18',
    CIDADE: 'Japaratuba',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.969799041748047,
    MAXIMO: 31.415637969970703,
    MEDIA: 31.169377426783207,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3a61bbf-21a1-4265-8cf8-b500c7cb4504',
    CIDADE: 'Japoatã',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.892688751220703,
    MAXIMO: 31.131826400756836,
    MEDIA: 30.989697484223733,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de462f1d-1b07-4f64-8253-c982013fb8e3',
    CIDADE: 'Lagarto',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.07386779785156,
    MAXIMO: 32.47793197631836,
    MEDIA: 32.254433949788414,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4710accb-8e01-4bcf-b1f2-00a55395aa87',
    CIDADE: 'Laranjeiras',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.559152603149414,
    MAXIMO: 31.915796279907227,
    MEDIA: 31.712240965930025,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfaa2cb5-45bf-485a-8f1b-98ab7739c183',
    CIDADE: 'Macambira',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.87436294555664,
    MAXIMO: 31.982011795043945,
    MEDIA: 31.91577884231792,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa197f4d-f0ef-49a6-a760-0d3c4ffae233',
    CIDADE: 'Malhada dos Bois',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.037294387817383,
    MAXIMO: 31.179702758789062,
    MEDIA: 31.069436159631273,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c398b3c-58a0-4e51-9b1b-d4bcb57c073a',
    CIDADE: 'Malhador',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.562959671020508,
    MAXIMO: 31.735761642456055,
    MEDIA: 31.662782515417135,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '025d375c-02d6-42f4-bfd8-ef3b3e2c9619',
    CIDADE: 'Maruim',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.415637969970703,
    MAXIMO: 31.74236488342285,
    MEDIA: 31.47248761035153,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f2d79cb-9b91-41c0-b039-17eac168bb7f',
    CIDADE: 'Moita Bonita',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.436635971069336,
    MAXIMO: 31.735761642456055,
    MEDIA: 31.619478601978585,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f37b82a9-3b05-4a56-b79e-b43a82e61853',
    CIDADE: 'Monte Alegre de Sergipe',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.310548782348633,
    MAXIMO: 31.36666488647461,
    MEDIA: 31.33860683441162,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6b09b2d-6004-4342-b363-cae67475e20b',
    CIDADE: 'Muribeca',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.037294387817383,
    MAXIMO: 31.179702758789062,
    MEDIA: 31.10180515845206,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6f897fe-0966-4bb9-a22e-0f4f816f28dd',
    CIDADE: 'Neópolis',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.74358558654785,
    MAXIMO: 31.037294387817383,
    MEDIA: 30.831878795341144,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b39c8eb-0e29-41f0-b0ad-51c9e1ed2495',
    CIDADE: 'Nossa Senhora Aparecida',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.408946990966797,
    MAXIMO: 31.68327522277832,
    MEDIA: 31.51862832185353,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ee54186-0825-433f-889a-7622bcb5520b',
    CIDADE: 'Nossa Senhora da Glória',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.320737838745117,
    MAXIMO: 31.479249954223633,
    MEDIA: 31.402978261311848,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd62ac47b-6fc6-41a3-9d90-5cb54f50ca24',
    CIDADE: 'Nossa Senhora das Dores',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.179702758789062,
    MAXIMO: 31.581249237060547,
    MEDIA: 31.41293269237182,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df43a998-6b6a-4806-a947-72475d6db005',
    CIDADE: 'Nossa Senhora de Lourdes',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.939163208007812,
    MAXIMO: 31.103557586669922,
    MEDIA: 31.050499249714004,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e0c8b4c-80da-42fe-b07d-30566bbcf22b',
    CIDADE: 'Nossa Senhora do Socorro',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.559152603149414,
    MAXIMO: 31.942049026489258,
    MEDIA: 31.67118937361796,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c4c81aa-472a-4ed1-9932-e310da71463e',
    CIDADE: 'Pacatuba',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.812456130981445,
    MAXIMO: 30.969799041748047,
    MEDIA: 30.891127586364746,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '164da870-9373-4f24-bfcb-f4f6a48503dd',
    CIDADE: 'Pedra Mole',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.78438377380371,
    MAXIMO: 31.982011795043945,
    MEDIA: 31.923905957289524,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff024b87-047d-403c-aae1-043d0bb72ea7',
    CIDADE: 'Pedrinhas',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.59905242919922,
    MAXIMO: 32.78394317626953,
    MEDIA: 32.76619244343225,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f81f5a75-7cd5-4298-8af3-9577a709820b',
    CIDADE: 'Pinhão',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.78438377380371,
    MAXIMO: 31.982011795043945,
    MEDIA: 31.835010267434836,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f04fba4-b868-40fa-8b64-d6fe9d9b4582',
    CIDADE: 'Pirambu',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.969799041748047,
    MAXIMO: 31.244121551513672,
    MEDIA: 31.15909616649274,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7073eee5-69cd-4e65-9251-6118b32ec941',
    CIDADE: 'Poço Redondo',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.21805191040039,
    MAXIMO: 31.31278419494629,
    MEDIA: 31.269404729207356,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4e7a3db-9635-4d34-babf-8bf67e023187',
    CIDADE: 'Poço Verde',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.15311050415039,
    MAXIMO: 32.789337158203125,
    MEDIA: 32.37624517572554,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31dd587a-bea1-4e2b-8b30-babe1ee61dc1',
    CIDADE: 'Porto da Folha',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.184173583984375,
    MAXIMO: 31.242660522460938,
    MEDIA: 31.213417053222656,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5bd99ab8-1bd7-40ef-bf29-bd031f00ed8c',
    CIDADE: 'Propriá',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.835899353027344,
    MAXIMO: 31.037294387817383,
    MEDIA: 30.928611530782753,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37eae095-47a8-4c06-ac44-2a981763e090',
    CIDADE: 'Riachão do Dantas',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.31905746459961,
    MAXIMO: 32.94635772705078,
    MEDIA: 32.601874769531264,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'edba90ae-7ddd-48c2-833e-f5e833847441',
    CIDADE: 'Riachuelo',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.581249237060547,
    MAXIMO: 31.74236488342285,
    MEDIA: 31.58629359122992,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58dc0587-e19a-4fc2-9699-2840e484896e',
    CIDADE: 'Ribeirópolis',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.428613662719727,
    MAXIMO: 31.68327522277832,
    MEDIA: 31.59295358101293,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0562ef2c-998f-435b-9849-4197da54648e',
    CIDADE: 'Rosário do Catete',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.244121551513672,
    MAXIMO: 31.415637969970703,
    MEDIA: 31.4125204652411,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03353482-529b-488b-85e5-1475568498c1',
    CIDADE: 'Salgado',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.13814926147461,
    MAXIMO: 32.59905242919922,
    MEDIA: 32.36613729884261,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55c121fd-53a9-496f-8978-2ac5b20869cd',
    CIDADE: 'Santa Luzia do Itanhy',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.67767333984375,
    MAXIMO: 33.2703971862793,
    MEDIA: 32.8959178304928,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '07ec633c-64e7-4423-9703-adfb3521b138',
    CIDADE: 'Santana do São Francisco',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.696685791015625,
    MAXIMO: 30.892688751220703,
    MEDIA: 30.804052985865518,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79fe7eab-82f8-458c-b70a-28a5ad44eb66',
    CIDADE: 'Santa Rosa de Lima',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.436635971069336,
    MAXIMO: 31.735761642456055,
    MEDIA: 31.56969473203104,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63dd7172-4abc-4c81-b718-17d49a0c25b5',
    CIDADE: 'Santo Amaro das Brotas',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.244121551513672,
    MAXIMO: 31.559152603149414,
    MEDIA: 31.431188714805185,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16c7b9ec-667f-46b0-97bb-268bb80fa01f',
    CIDADE: 'São Cristóvão',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.559152603149414,
    MAXIMO: 32.169498443603516,
    MEDIA: 31.902193254877798,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6770f934-e701-4587-bff6-f54b96935598',
    CIDADE: 'São Domingos',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.87436294555664,
    MAXIMO: 32.07386779785156,
    MEDIA: 32.01202903110514,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa0e243b-f5a4-4816-87d8-0744fee7a793',
    CIDADE: 'São Francisco',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.975027084350586,
    MAXIMO: 31.037294387817383,
    MEDIA: 31.03637428314987,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3060a949-1949-496a-af3b-8ac5e9ea4e89',
    CIDADE: 'São Miguel do Aleixo',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.320737838745117,
    MAXIMO: 31.562959671020508,
    MEDIA: 31.464532043633323,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aadd3764-939f-4778-a038-e91f560d90c4',
    CIDADE: 'Simão Dias',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.982011795043945,
    MAXIMO: 32.3309440612793,
    MEDIA: 32.15647792816162,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3707d04e-193c-4847-bf65-5e1598e05847',
    CIDADE: 'Siriri',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 31.28755760192871,
    MAXIMO: 31.581249237060547,
    MEDIA: 31.400395540680538,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '394d9109-4c2a-4fab-b382-25e0d4617375',
    CIDADE: 'Telha',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 30.975027084350586,
    MAXIMO: 30.975027084350586,
    MEDIA: 30.975027084350586,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8985d799-ee52-47f3-9f1f-340c081d0962',
    CIDADE: 'Tobias Barreto',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.41644287109375,
    MAXIMO: 33.067771911621094,
    MEDIA: 32.787760734558105,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5842b964-6913-4002-9abb-c75818e2c641',
    CIDADE: 'Tomar do Geru',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 33.130767822265625,
    MAXIMO: 33.90009307861328,
    MEDIA: 33.39639932171898,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b7f1b94-869f-4119-b237-eb77c925a4cd',
    CIDADE: 'Umbaúba',
    SIGLA: 'SE',
    ESTADO: 'Sergipe',
    MINIMO: 32.91621780395508,
    MAXIMO: 33.52001953125,
    MEDIA: 33.244221515751946,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '208c74fd-3aa7-4ef6-b0f3-3b991a54863e',
    CIDADE: 'Abaíra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.579790115356445,
    MAXIMO: 31.662389755249023,
    MEDIA: 31.621089935302734,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '97e218ff-9724-4fb6-9218-00dde437a503',
    CIDADE: 'Abaré',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.58045196533203,
    MAXIMO: 30.7711238861084,
    MEDIA: 30.681419372558594,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0b8b378-4f49-4fb0-9961-37f845fde23e',
    CIDADE: 'Acajutiba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.202728271484375,
    MAXIMO: 34.94834518432617,
    MEDIA: 34.40720703589999,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8eeaf8f-5e4a-4740-a875-0d1a8bd5283e',
    CIDADE: 'Adustina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.92677879333496,
    MAXIMO: 32.15311050415039,
    MEDIA: 32.039944648742676,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7abc2f51-528e-40e9-8a97-608c95f5c26a',
    CIDADE: 'Água Fria',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.885005950927734,
    MAXIMO: 35.0399055480957,
    MEDIA: 34.96245574951172,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '73e95200-5373-427f-9fd0-226de12f2265',
    CIDADE: 'Érico Cardoso',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.33061408996582,
    MAXIMO: 31.388378143310547,
    MEDIA: 31.359496116638184,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f57f3908-ed59-41a2-87e0-5f5e0aa97003',
    CIDADE: 'Aiquara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.94415283203125,
    MAXIMO: 33.375144958496094,
    MEDIA: 33.041506074840996,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6bc6596-4b57-4426-890d-7db20e50c67f',
    CIDADE: 'Alagoinhas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.782798767089844,
    MAXIMO: 36.45098114013672,
    MEDIA: 36.11688995361328,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c360c1c2-7476-463b-8b21-55b0d68511d3',
    CIDADE: 'Alcobaça',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.473051071166992,
    MAXIMO: 29.522951126098633,
    MEDIA: 29.500829696655273,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b808bab5-a738-40f7-b0fb-f9b0432a06e1',
    CIDADE: 'Almadina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.38327980041504,
    MAXIMO: 32.174495697021484,
    MEDIA: 31.951658465038072,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24408d66-1379-459f-a019-bb3accd176d2',
    CIDADE: 'Amargosa',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.44700622558594,
    MAXIMO: 35.256778717041016,
    MEDIA: 34.90151179482669,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '997a3887-d61c-4f80-a6ee-3f3eee1a5f47',
    CIDADE: 'Amélia Rodrigues',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.38414001464844,
    MAXIMO: 37.25776290893555,
    MEDIA: 37.01651975457981,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9923f67-7b20-49e1-bc8c-73df23e06e24',
    CIDADE: 'América Dourada',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.89096450805664,
    MAXIMO: 31.921483993530273,
    MEDIA: 31.906224250793457,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e945ec8e-f90e-4972-a262-66348c274f94',
    CIDADE: 'Anagé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.902318954467773,
    MAXIMO: 32.04502487182617,
    MEDIA: 31.61650053660075,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b02ee6bb-1b89-4558-9db9-7017c26c0ddd',
    CIDADE: 'Andaraí',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.165863037109375,
    MAXIMO: 32.421180725097656,
    MEDIA: 32.28809070587158,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9c80f45-bd61-4f0d-871c-8be51f1c4285',
    CIDADE: 'Andorinha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.729949951171875,
    MAXIMO: 31.104753494262695,
    MEDIA: 30.931532669067384,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '291fcef1-6ef4-488d-a65a-9555a768496e',
    CIDADE: 'Angical',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.185251235961914,
    MAXIMO: 31.87505531311035,
    MEDIA: 31.546677017211913,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40385059-490a-4fed-95dc-b6844339b354',
    CIDADE: 'Anguera',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.957542419433594,
    MAXIMO: 36.00523376464844,
    MEDIA: 35.271283064070815,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56b44c96-4775-4e58-9970-62dd0bde52f5',
    CIDADE: 'Antas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.75254249572754,
    MAXIMO: 31.971858978271484,
    MEDIA: 31.823773092516095,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3a8a405-6d4a-44fd-8f73-f34f669dbc78',
    CIDADE: 'Antônio Cardoso',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.620628356933594,
    MAXIMO: 36.351619720458984,
    MEDIA: 35.76635482812527,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7f4bc97-6b69-4234-87e2-1701f67f6d3e',
    CIDADE: 'Antônio Gonçalves',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.212491989135742,
    MAXIMO: 31.43564796447754,
    MEDIA: 31.307948280458056,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0301bd0-7b2e-44e0-ac9d-b358b9eef34e',
    CIDADE: 'Aporá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.397098541259766,
    MAXIMO: 35.162113189697266,
    MEDIA: 34.751457784512276,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49c8a01f-4f9e-43de-a569-c80a318ec84a',
    CIDADE: 'Apuarema',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.375144958496094,
    MAXIMO: 33.87468338012695,
    MEDIA: 33.68904370412556,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '108362cd-b1d9-4177-839e-fea33459acfc',
    CIDADE: 'Aracatu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.08536720275879,
    MAXIMO: 31.220720291137695,
    MEDIA: 31.164284229278564,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9950cb7f-6e78-4db9-8dce-78f9bb625816',
    CIDADE: 'Araçás',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.719947814941406,
    MAXIMO: 37.15744400024414,
    MEDIA: 36.46565581254392,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03e17698-acd2-41d1-ac85-bf0d64f08933',
    CIDADE: 'Araci',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.92430877685547,
    MAXIMO: 33.41014099121094,
    MEDIA: 33.1203244527181,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b81a1aef-f3c2-4404-b159-925dd06369cc',
    CIDADE: 'Aramari',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.73208999633789,
    MAXIMO: 36.359703063964844,
    MEDIA: 36.04589653015137,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c2db903-7910-4d92-98a1-6dfce22c2474',
    CIDADE: 'Arataca',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.742630004882812,
    MAXIMO: 30.757333755493164,
    MEDIA: 30.74998188018799,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39b66470-4431-4fa3-a24c-54630b53e7b9',
    CIDADE: 'Aratuípe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.02941131591797,
    MAXIMO: 36.710506439208984,
    MEDIA: 36.331019072222816,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ab55a6e-43da-429d-b5e8-a66b66d1c70b',
    CIDADE: 'Aurelino Leal',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.50575637817383,
    MAXIMO: 33.061309814453125,
    MEDIA: 32.74241370351562,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc8c304a-4495-4158-a6e8-a3f957bdc5be',
    CIDADE: 'Baianópolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.463834762573242,
    MAXIMO: 31.04347801208496,
    MEDIA: 30.78077524358576,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6e91989-c902-4d03-a2fa-8d0866a63876',
    CIDADE: 'Baixa Grande',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.80462646484375,
    MAXIMO: 33.67922592163086,
    MEDIA: 33.23280143737793,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98a603a3-1ec3-4a7a-af89-ee16b415c0cd',
    CIDADE: 'Banzaê',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.915990829467773,
    MAXIMO: 32.17538833618164,
    MEDIA: 32.04568958282471,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9c8c7ac-1be7-4a18-b4cf-dcbf27eb54a0',
    CIDADE: 'Barra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.493227005004883,
    MAXIMO: 32.80180358886719,
    MEDIA: 32.05485018561868,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0eb732e-d6b9-441f-8988-0e5daf8ea6a6',
    CIDADE: 'Barra da Estiva',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.291799545288086,
    MAXIMO: 32.740325927734375,
    MEDIA: 32.04678122202555,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '07ec6952-8056-46de-81fd-57b073178b0f',
    CIDADE: 'Barra do Choça',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.882564544677734,
    MAXIMO: 31.567058563232422,
    MEDIA: 31.224811553955078,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ac757b9-0635-4ea3-bf59-ff95553883fd',
    CIDADE: 'Barra do Mendes',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.785093307495117,
    MAXIMO: 31.918899536132812,
    MEDIA: 31.864054679870605,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ca5cbb4b-9485-4f6e-b5a3-ab97595e136c',
    CIDADE: 'Barra do Rocha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.841983795166016,
    MAXIMO: 33.73447799682617,
    MEDIA: 33.296550454431696,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c001be7-e7f9-4b53-ac88-3f8fa0233e49',
    CIDADE: 'Barreiras',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.118356704711914,
    MAXIMO: 31.95364761352539,
    MEDIA: 31.484551923028356,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac0b7704-8652-4849-b678-aefe95c217bf',
    CIDADE: 'Barro Alto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.918899536132812,
    MAXIMO: 31.989870071411133,
    MEDIA: 31.946984121542688,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44cadb51-f83b-43a8-88da-a16f79daca3d',
    CIDADE: 'Barrocas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.675811767578125,
    MAXIMO: 34.247737884521484,
    MEDIA: 33.830775774350485,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6313698-ca72-49fa-bec5-633e7bf94f5f',
    CIDADE: 'Barro Preto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.225072860717773,
    MAXIMO: 31.887903213500977,
    MEDIA: 31.78806559905441,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23c87303-d254-476a-916e-f7b966fdb1d4',
    CIDADE: 'Belmonte',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.903648376464844,
    MAXIMO: 30.017616271972656,
    MEDIA: 29.963778813680012,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28b7b23b-1083-42d7-8f0a-e08be0371791',
    CIDADE: 'Belo Campo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.55002784729004,
    MAXIMO: 30.579448699951172,
    MEDIA: 30.564738273620605,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ede924b4-da30-493f-8593-4e04cbd81693',
    CIDADE: 'Biritinga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.93634796142578,
    MAXIMO: 34.39838790893555,
    MEDIA: 34.167367935180664,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a664b86-fd09-48a7-94e6-f9cf23f85928',
    CIDADE: 'Boa Nova',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.306949615478516,
    MAXIMO: 32.554542541503906,
    MEDIA: 32.43074607849121,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c33d6fa-0d92-4f59-8230-7ccf4d631808',
    CIDADE: 'Boa Vista do Tupim',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.06709671020508,
    MAXIMO: 33.55106735229492,
    MEDIA: 33.309106063842776,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84577ae8-44f4-4ad5-a600-d5b439c2b208',
    CIDADE: 'Bom Jesus da Lapa',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.663644790649414,
    MAXIMO: 30.89008331298828,
    MEDIA: 30.785970211029053,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4321544f-fe5e-4f95-927a-9b5a87aa5af4',
    CIDADE: 'Bom Jesus da Serra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.10210037231445,
    MAXIMO: 32.17023468017578,
    MEDIA: 32.13616752624512,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea0d5840-8311-4065-8b13-3f9078091795',
    CIDADE: 'Boninal',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.55413246154785,
    MAXIMO: 31.74803924560547,
    MEDIA: 31.65108585357666,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9292e785-acb2-4306-bd04-2e376c17becd',
    CIDADE: 'Bonito',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.092994689941406,
    MAXIMO: 32.16448211669922,
    MEDIA: 32.13087717692057,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4737928a-8694-424b-80d4-53cdea461cf3',
    CIDADE: 'Boquira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.1650390625,
    MAXIMO: 31.456663131713867,
    MEDIA: 31.284732818603516,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62ea091d-4aac-4fb9-a833-d711c31d050b',
    CIDADE: 'Botuporã',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.871694564819336,
    MAXIMO: 31.156240463256836,
    MEDIA: 31.043134994538647,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7ffe3165-6b38-403d-b62d-9d27b4c68a84',
    CIDADE: 'Brejões',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.152950286865234,
    MAXIMO: 34.77622985839844,
    MEDIA: 34.41212962213308,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '80505051-5217-4c53-a90a-c13152abfb30',
    CIDADE: 'Brejolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.886754989624023,
    MAXIMO: 31.178621292114258,
    MEDIA: 31.04419157240126,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7927e815-980e-4d2d-9ef5-7355e64e659a',
    CIDADE: 'Brotas de Macaúbas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.71624755859375,
    MAXIMO: 31.807666778564453,
    MEDIA: 31.765249524797714,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfa381f4-ea16-4b95-b764-200f065051e0',
    CIDADE: 'Brumado',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.374773025512695,
    MAXIMO: 31.623046875,
    MEDIA: 31.473838170369465,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fdbc98b-cbd3-4e7a-8016-835c456fb64a',
    CIDADE: 'Buerarema',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.998188018798828,
    MAXIMO: 31.317668914794922,
    MEDIA: 31.166901660003884,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b10895fb-01cb-4cd0-87a1-35073f971dc1',
    CIDADE: 'Buritirama',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.20999526977539,
    MAXIMO: 32.87602233886719,
    MEDIA: 32.635505948747905,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d63f37c-725b-4f7e-8a9d-bf6137c0837d',
    CIDADE: 'Caatiba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.38692283630371,
    MAXIMO: 31.576091766357422,
    MEDIA: 31.481507301330566,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0204c51d-01d2-43fa-ab0f-9d8dde0dac80',
    CIDADE: 'Cabaceiras do Paraguaçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.48442840576172,
    MAXIMO: 36.351619720458984,
    MEDIA: 35.93518622475581,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b71e56c0-54e4-41db-a2ef-27faf5039ffc',
    CIDADE: 'Cachoeira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.135765075683594,
    MAXIMO: 37.31086730957031,
    MEDIA: 37.22331619262695,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2be2aa77-41ad-42a1-acfc-3b0173daa2c4',
    CIDADE: 'Caculé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.93936538696289,
    MAXIMO: 31.056245803833008,
    MEDIA: 30.99780559539795,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52a4ab99-1233-4fc8-82fe-67a83de6b5ef',
    CIDADE: 'Caém',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.97206687927246,
    MAXIMO: 32.101383209228516,
    MEDIA: 32.03672504425049,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0eb2eea-9965-41a1-a704-2ba9ae97d222',
    CIDADE: 'Caetanos',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.15480041503906,
    MAXIMO: 32.21394348144531,
    MEDIA: 32.18437194824219,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53dda49d-e49d-4fca-a432-40b18c00449b',
    CIDADE: 'Caetité',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.95471954345703,
    MAXIMO: 31.411148071289062,
    MEDIA: 31.164822472466362,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd06d8a6-355e-40e1-963e-ec8fde729db0',
    CIDADE: 'Cafarnaum',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.975549697875977,
    MAXIMO: 32.03076934814453,
    MEDIA: 31.999640146891277,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98ec38b9-37a7-46a9-b500-83ead0b6ae0b',
    CIDADE: 'Cairu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.05109786987305,
    MAXIMO: 36.71687698364258,
    MEDIA: 35.70684766504327,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '133f123c-204e-4af3-932e-c228fd1013d7',
    CIDADE: 'Caldeirão Grande',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.571998596191406,
    MAXIMO: 32.196510314941406,
    MEDIA: 31.852364814717625,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b568144a-7af3-4c18-929d-14889d9981f8',
    CIDADE: 'Camacan',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.232149124145508,
    MAXIMO: 30.742630004882812,
    MEDIA: 30.431852747236125,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ea66290-9e9d-4213-8753-8d0430259cc4',
    CIDADE: 'Camaçari',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.88827896118164,
    MAXIMO: 38.670379638671875,
    MEDIA: 38.38499069213867,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '095ebcbf-947a-4102-9e79-da3fdfcf7a47',
    CIDADE: 'Camamu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.5047492980957,
    MAXIMO: 34.336002349853516,
    MEDIA: 33.9763765335083,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6b5d077-1c51-436b-a564-542c4fef2007',
    CIDADE: 'Campo Alegre de Lourdes',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.069271087646484,
    MAXIMO: 34.008548736572266,
    MEDIA: 33.52735786437988,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4126fb4-1859-4074-bff0-7f5eba48e8d7',
    CIDADE: 'Campo Formoso',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.68915557861328,
    MAXIMO: 31.29571533203125,
    MEDIA: 31.042591094970703,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1939a3e3-d07b-4d2c-9042-6da58b69001c',
    CIDADE: 'Canápolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.3928165435791,
    MAXIMO: 30.589860916137695,
    MEDIA: 30.479154330273047,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '799ab5ab-464a-418d-86be-3a6932f00483',
    CIDADE: 'Canarana',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.948837280273438,
    MAXIMO: 31.95237922668457,
    MEDIA: 31.950608253479004,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81704991-7f07-4efe-be3f-146bf5254faa',
    CIDADE: 'Canavieiras',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.13172721862793,
    MAXIMO: 30.518022537231445,
    MEDIA: 30.248974990844726,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '851f2c4b-2cfb-4b20-80c6-977f740efff8',
    CIDADE: 'Candeal',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.27309799194336,
    MAXIMO: 34.895023345947266,
    MEDIA: 34.566670063993044,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e6f9bbb-d911-48b6-8f73-c64642db0bee',
    CIDADE: 'Candeias',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 38.20396423339844,
    MAXIMO: 38.5963134765625,
    MEDIA: 38.327030657310985,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6542270e-b177-4cff-8e1e-d7804391d010',
    CIDADE: 'Candiba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.54722023010254,
    MAXIMO: 30.867265701293945,
    MEDIA: 30.77519796832975,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53ae8670-283b-4aa7-a100-7ac8ddb0be1a',
    CIDADE: 'Cândido Sales',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.98902702331543,
    MAXIMO: 30.389375686645508,
    MEDIA: 30.118393898010254,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3eea9cb3-a6d3-48a5-acaa-0c65342cf6c9',
    CIDADE: 'Cansanção',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.725341796875,
    MAXIMO: 32.08115005493164,
    MEDIA: 31.874513626098633,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '572844d1-d7cc-4e85-b650-7e36fda8ffed',
    CIDADE: 'Canudos',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.82428550720215,
    MAXIMO: 31.496475219726562,
    MEDIA: 31.149436473846436,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98f5d1da-2748-4375-80ce-f0a9637a4ad0',
    CIDADE: 'Capela do Alto Alegre',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.704124450683594,
    MAXIMO: 33.83647537231445,
    MEDIA: 33.327027714062574,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92b46fba-012a-425e-ba5c-04f18f5a19b8',
    CIDADE: 'Capim Grosso',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.196510314941406,
    MAXIMO: 32.44031524658203,
    MEDIA: 32.31841278076172,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae2d0d52-3940-4800-8438-2196cdfa004a',
    CIDADE: 'Caraíbas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.854995727539062,
    MAXIMO: 30.99433135986328,
    MEDIA: 30.924663543701172,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9d6dffae-c290-46cf-a3a1-217f4b43dee6',
    CIDADE: 'Caravelas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.348005294799805,
    MAXIMO: 29.5579891204834,
    MEDIA: 29.447200502668107,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ba0c2cc-5c41-45cc-a328-63f92e22ad7a',
    CIDADE: 'Cardeal da Silva',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.28424072265625,
    MAXIMO: 36.207584381103516,
    MEDIA: 35.62048625007983,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82f7d86c-3d2c-412a-9c55-79adcc3ea3a0',
    CIDADE: 'Carinhanha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.342573165893555,
    MAXIMO: 30.600473403930664,
    MEDIA: 30.476384162902832,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4d485ae-04a3-404c-8eef-36f821340bf8',
    CIDADE: 'Casa Nova',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.595043182373047,
    MAXIMO: 32.06462097167969,
    MEDIA: 31.352949997474408,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '370c15c9-ce1a-441e-8885-f22bfbd661fc',
    CIDADE: 'Castro Alves',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.28354263305664,
    MAXIMO: 35.716495513916016,
    MEDIA: 35.54833857218424,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b85591b-18e8-413b-8566-97e346cb7fb5',
    CIDADE: 'Catolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.03289222717285,
    MAXIMO: 31.159170150756836,
    MEDIA: 31.096031188964844,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59122dc4-374c-47de-a6c2-d2cc503eddc4',
    CIDADE: 'Catu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.004573822021484,
    MAXIMO: 37.15744400024414,
    MEDIA: 37.08100891113281,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0ad04f7-84e2-4936-a278-6487bcc04fdf',
    CIDADE: 'Caturama',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.088407516479492,
    MAXIMO: 31.2857608795166,
    MEDIA: 31.20172182718913,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7ae63174-389d-4b12-8af8-8f5ce5c93822',
    CIDADE: 'Central',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.922529220581055,
    MAXIMO: 31.946533203125,
    MEDIA: 31.934531211853027,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87b8533a-db65-469e-989f-ac11d5a472b6',
    CIDADE: 'Chorrochó',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.564250946044922,
    MAXIMO: 30.839662551879883,
    MEDIA: 30.69357577237216,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd37e8184-5584-4bb2-98c5-eeacfc2b5e6e',
    CIDADE: 'Cícero Dantas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.95457649230957,
    MAXIMO: 31.971858978271484,
    MEDIA: 31.963217735290527,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '32bd8cde-acff-4d64-9993-22d651f2bed2',
    CIDADE: 'Cipó',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.82774353027344,
    MAXIMO: 33.199092864990234,
    MEDIA: 33.082640966726764,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1321966a-362f-4e46-99b5-efc0af3fdb0a',
    CIDADE: 'Coaraci',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.873308181762695,
    MAXIMO: 32.22098159790039,
    MEDIA: 32.132426903230744,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '485bb164-1d2d-4300-99f5-1e145dce32c4',
    CIDADE: 'Cocos',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.591833114624023,
    MAXIMO: 30.21708869934082,
    MEDIA: 29.761241277058918,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '924bf146-9396-476d-af9d-5ae588994397',
    CIDADE: 'Conceição da Feira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.90445327758789,
    MAXIMO: 36.809906005859375,
    MEDIA: 36.38798283085816,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebab2cbb-c116-4188-816c-e896fb53ec9d',
    CIDADE: 'Conceição do Almeida',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.69026565551758,
    MAXIMO: 36.19899368286133,
    MEDIA: 35.94462966918945,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '857eec0e-b9bc-4135-adb5-6ec08c7b0a61',
    CIDADE: 'Conceição do Coité',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.2904052734375,
    MAXIMO: 34.075042724609375,
    MEDIA: 33.68984680175781,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44a4e89a-c5a4-47ff-b84b-4e753bff38ba',
    CIDADE: 'Conceição do Jacuípe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.38414001464844,
    MAXIMO: 36.72398376464844,
    MEDIA: 36.60343914146399,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e069e746-d245-4a0e-b86d-aef97d1ffa3d',
    CIDADE: 'Conde',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.95210647583008,
    MAXIMO: 34.93520736694336,
    MEDIA: 34.437853495279946,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d1df7c7-f15e-4a6d-ad91-0f90bc5383d9',
    CIDADE: 'Condeúba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.37765884399414,
    MAXIMO: 31.21710968017578,
    MEDIA: 30.948190689086914,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f975a1d-5c8e-4cd5-9d1e-d67ca356c712',
    CIDADE: 'Contendas do Sincorá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.683692932128906,
    MAXIMO: 31.817790985107422,
    MEDIA: 31.750741958618164,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e6bb19d-b4ee-41ae-808c-b51f20c682d6',
    CIDADE: 'Coração de Maria',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.889678955078125,
    MAXIMO: 36.72398376464844,
    MEDIA: 36.164759883651335,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c923a1b-a4b5-4007-8cde-e62262980d65',
    CIDADE: 'Cordeiros',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.295440673828125,
    MAXIMO: 31.107519149780273,
    MEDIA: 31.038757354451363,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87cef4d6-37b9-4189-a83f-03389d37e249',
    CIDADE: 'Coribe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.263408660888672,
    MAXIMO: 30.504180908203125,
    MEDIA: 30.38151592678494,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60cae03c-f6c9-485c-a51e-093e1046ef62',
    CIDADE: 'Coronel João Sá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.568803787231445,
    MAXIMO: 31.732601165771484,
    MEDIA: 31.663764317830402,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06b75565-6f08-4844-97f3-2f2b24da3a90',
    CIDADE: 'Correntina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.809579849243164,
    MAXIMO: 30.3022403717041,
    MEDIA: 30.0651519563463,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a4b1e16-109f-462c-9614-d9136ad9ddee',
    CIDADE: 'Cotegipe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.310367584228516,
    MAXIMO: 32.11716079711914,
    MEDIA: 31.679818425859725,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4bfed3c2-3c02-45d2-947c-0ce983b81dc8',
    CIDADE: 'Cravolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.11658477783203,
    MAXIMO: 34.39823532104492,
    MEDIA: 34.17338744030957,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42d3bb07-1490-4fc5-b2e0-7660cf925063',
    CIDADE: 'Crisópolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.5527229309082,
    MAXIMO: 34.59294509887695,
    MEDIA: 34.068241644612975,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22b7a2cf-acc2-4a67-b503-5085f57b99cf',
    CIDADE: 'Cristópolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.16558074951172,
    MAXIMO: 31.21281623840332,
    MEDIA: 31.18919849395752,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a157efb-81d9-485e-a48d-37a1a779370b',
    CIDADE: 'Cruz das Almas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.10439682006836,
    MAXIMO: 36.731754302978516,
    MEDIA: 36.23559486192232,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b3249a30-7fb4-4ad8-ae75-c2b9c87f96c4',
    CIDADE: 'Curaçá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.263225555419922,
    MAXIMO: 30.626285552978516,
    MEDIA: 30.43702456825658,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85046dcc-8e60-496a-8d63-e8c88e35013a',
    CIDADE: 'Dário Meira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.05414581298828,
    MAXIMO: 32.647010803222656,
    MEDIA: 32.422429386954086,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30edb21a-12ee-4b99-86eb-7f5b17849475',
    CIDADE: "Dias d'Ávila",
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.88409423828125,
    MAXIMO: 38.670379638671875,
    MEDIA: 38.56597626007377,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31e05f0b-e0d8-4e5e-b469-4129f1d18270',
    CIDADE: 'Dom Basílio',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.551427841186523,
    MAXIMO: 31.635005950927734,
    MEDIA: 31.60126495361328,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39b32151-e4d1-45eb-b729-92e147aae357',
    CIDADE: 'Dom Macedo Costa',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.1741943359375,
    MAXIMO: 36.19899368286133,
    MEDIA: 36.17896264109844,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3215faeb-8189-478b-b714-c031e3032557',
    CIDADE: 'Elísio Medrado',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.8718147277832,
    MAXIMO: 35.28354263305664,
    MEDIA: 35.20939003267104,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f438976c-1ac6-4edb-b46c-80948f3b758b',
    CIDADE: 'Encruzilhada',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.762788772583008,
    MAXIMO: 29.970502853393555,
    MEDIA: 29.860589663187664,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98557595-d083-43ab-997a-1cf15e9c8c51',
    CIDADE: 'Entre Rios',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.094444274902344,
    MAXIMO: 36.50944137573242,
    MEDIA: 35.816803741455075,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18227863-51be-41ce-bea2-078b6523f56b',
    CIDADE: 'Esplanada',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.720420837402344,
    MAXIMO: 35.88568115234375,
    MEDIA: 35.20967197418213,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd78e470f-c40e-456d-b82e-967d2cf2f1bb',
    CIDADE: 'Euclides da Cunha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.559009552001953,
    MAXIMO: 31.860645294189453,
    MEDIA: 31.706440734863282,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35ab2b0b-bded-453a-a35a-10e044447c22',
    CIDADE: 'Eunápolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.712623596191406,
    MAXIMO: 29.805299758911133,
    MEDIA: 29.759541988372803,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19f3ff11-199e-460f-b114-aa394cd6e7a9',
    CIDADE: 'Fátima',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.956493377685547,
    MAXIMO: 32.196189880371094,
    MEDIA: 32.07634162902832,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a78926cc-c41b-4d35-a257-e3884fff93e6',
    CIDADE: 'Feira da Mata',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.92099952697754,
    MAXIMO: 30.36237907409668,
    MEDIA: 30.247484525044758,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31260141-acef-436a-8cf8-00fbc94e8d47',
    CIDADE: 'Feira de Santana',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.27677917480469,
    MAXIMO: 36.38414001464844,
    MEDIA: 35.81619167327881,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a5d2899-e090-4aad-9e70-2e32b5948043',
    CIDADE: 'Filadélfia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.43564796447754,
    MAXIMO: 31.618974685668945,
    MEDIA: 31.527311325073242,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15c7b393-5509-4b96-8e54-7323c23b4da2',
    CIDADE: 'Firmino Alves',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.877962112426758,
    MAXIMO: 31.840486526489258,
    MEDIA: 31.41051712627652,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0522d97-eddf-4043-a18c-9fcc6bb8061b',
    CIDADE: 'Floresta Azul',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.134727478027344,
    MAXIMO: 31.873308181762695,
    MEDIA: 31.56416614837056,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'baa33302-bc27-4028-91bc-cd8acd4ee1d8',
    CIDADE: 'Formosa do Rio Preto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.923025131225586,
    MAXIMO: 33.389530181884766,
    MEDIA: 32.58151491483053,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '175f70c4-c89d-48a4-b4e7-97f1c46bf2b4',
    CIDADE: 'Gandu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.73447799682617,
    MAXIMO: 34.348121643066406,
    MEDIA: 34.09225873039581,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2ac9a7c-95bf-4e73-89c8-c4393b442a87',
    CIDADE: 'Gavião',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.569522857666016,
    MAXIMO: 33.53651809692383,
    MEDIA: 33.00895563614104,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c624da6d-d086-4ced-b98a-9997404cfa01',
    CIDADE: 'Gentio do Ouro',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.72917366027832,
    MAXIMO: 31.9725341796875,
    MEDIA: 31.849896748860676,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a66d4eb-e1fc-4fc0-beee-7d3c4621315d',
    CIDADE: 'Glória',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.951419830322266,
    MAXIMO: 31.062910079956055,
    MEDIA: 31.005260785420734,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a580a64-b8f6-4766-afa1-ca165c409b5a',
    CIDADE: 'Gongogi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.50575637817383,
    MAXIMO: 33.20510482788086,
    MEDIA: 32.887110506588115,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92de4ff0-0dc9-4261-be2c-cb91e7671f95',
    CIDADE: 'Governador Mangabeira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.90445327758789,
    MAXIMO: 36.60466384887695,
    MEDIA: 36.402728837577385,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6e9acfb-a358-462d-bd78-2d83a5bd36d4',
    CIDADE: 'Guajeru',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.990432739257812,
    MAXIMO: 31.294649124145508,
    MEDIA: 31.145654042561848,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fca909bc-3a53-411f-a459-d4febd8945cc',
    CIDADE: 'Guanambi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.805639266967773,
    MAXIMO: 30.94127082824707,
    MEDIA: 30.873184204101562,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54bafb1a-38d3-4d01-ba54-90439bbeecf2',
    CIDADE: 'Guaratinga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.562490463256836,
    MAXIMO: 29.67400360107422,
    MEDIA: 29.61099656422933,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0f084ad-0251-4a2a-8818-e9c2b3a5577e',
    CIDADE: 'Heliópolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.21989059448242,
    MAXIMO: 32.5052375793457,
    MEDIA: 32.36256408691406,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de476d61-fb0a-4d29-9118-ac7f578448ad',
    CIDADE: 'Iaçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.53033447265625,
    MAXIMO: 34.5231819152832,
    MEDIA: 33.99609327316284,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '874f85eb-ab7d-4443-a760-7209d7db14ef',
    CIDADE: 'Ibiassucê',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.98731803894043,
    MAXIMO: 31.2397403717041,
    MEDIA: 31.115125423841352,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61e41e3b-19ea-4027-9fe2-c6abf668bce7',
    CIDADE: 'Ibicaraí',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.225072860717773,
    MAXIMO: 31.887903213500977,
    MEDIA: 31.599894112790082,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0661783f-66d3-498a-8e16-2f58e892be62',
    CIDADE: 'Ibicoara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.193227767944336,
    MAXIMO: 32.060089111328125,
    MEDIA: 31.572595596313477,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af594b78-67b4-4d47-8e3b-8e41ca0f92c1',
    CIDADE: 'Ibicuí',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.592300415039062,
    MAXIMO: 32.43925857543945,
    MEDIA: 32.00245904922485,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad756d7f-fbbe-43a9-bb58-5e787452025d',
    CIDADE: 'Ibipeba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.901750564575195,
    MAXIMO: 31.949047088623047,
    MEDIA: 31.93422222137451,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '114ef065-c61e-45d2-9eea-dec253597a4e',
    CIDADE: 'Ibipitanga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.29414176940918,
    MAXIMO: 31.36664581298828,
    MEDIA: 31.319239298502605,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ef846d1-3274-4484-85c3-cc899bdffbba',
    CIDADE: 'Ibiquera',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.54498291015625,
    MAXIMO: 33.21428680419922,
    MEDIA: 32.747758865356445,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '060b9734-5a6a-43de-b293-a164071e9623',
    CIDADE: 'Ibirapitanga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.9501838684082,
    MAXIMO: 33.930728912353516,
    MEDIA: 33.5011811142908,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '509fa0f2-e535-4c02-bf73-5b84e020260c',
    CIDADE: 'Ibirapuã',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.338333129882812,
    MAXIMO: 29.373186111450195,
    MEDIA: 29.355759620666504,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc647730-0987-4f6b-be0b-fdb82b532ea9',
    CIDADE: 'Ibirataia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.20510482788086,
    MAXIMO: 33.54873275756836,
    MEDIA: 33.37691879272461,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1df7ef1d-58ae-4486-b230-7fa0c0779df0',
    CIDADE: 'Ibitiara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.52066993713379,
    MAXIMO: 31.808115005493164,
    MEDIA: 31.672358830769856,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56fa777a-9f0c-4101-acd3-0844c5e4ab21',
    CIDADE: 'Ibititá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.9355411529541,
    MAXIMO: 31.94013023376465,
    MEDIA: 31.93731180826823,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09bdfa7b-351c-40bb-b595-a374edfe4c5f',
    CIDADE: 'Ibotirama',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.38519287109375,
    MAXIMO: 31.617918014526367,
    MEDIA: 31.497276033673966,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '607701b3-0397-454c-a47b-19cf1b74b88b',
    CIDADE: 'Ichu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.075042724609375,
    MAXIMO: 34.693336486816406,
    MEDIA: 34.24346406433057,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c281e732-6ef0-4a2b-a943-156c5adc0147',
    CIDADE: 'Igaporã',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.021114349365234,
    MAXIMO: 31.116657257080078,
    MEDIA: 31.075485865275066,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09fe7afb-5d61-4805-b6aa-dccbff35d183',
    CIDADE: 'Igrapiúna',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.5047492980957,
    MAXIMO: 34.864288330078125,
    MEDIA: 34.30948299259741,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8d22557-afe9-4f38-ab6c-6ed0284df15d',
    CIDADE: 'Iguaí',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.05414581298828,
    MAXIMO: 32.09532165527344,
    MEDIA: 32.07473373413086,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c3a2959-f3a4-4b75-ab4b-ccfe87b58a22',
    CIDADE: 'Ilhéus',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.059162139892578,
    MAXIMO: 32.578086853027344,
    MEDIA: 31.723272705078124,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c40da10-9c39-495c-a62b-299aedfc0232',
    CIDADE: 'Inhambupe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.59294509887695,
    MAXIMO: 35.162113189697266,
    MEDIA: 34.964263916015625,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d9cb890-722d-4bad-b1c0-d255a4aff41b',
    CIDADE: 'Ipecaetá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.90130615234375,
    MAXIMO: 35.620628356933594,
    MEDIA: 35.22812734908003,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98007190-e679-49a1-aed4-6eced737a9dd',
    CIDADE: 'Ipiaú',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.07231521606445,
    MAXIMO: 33.54873275756836,
    MEDIA: 33.264316463582965,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88cde52a-8a30-4405-a1c8-0f491c3b1001',
    CIDADE: 'Ipirá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.87495422363281,
    MAXIMO: 34.90130615234375,
    MEDIA: 34.283314895629886,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56794fdb-7e12-449b-a62d-d30ed4a553ce',
    CIDADE: 'Ipupiara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.77853775024414,
    MAXIMO: 31.826955795288086,
    MEDIA: 31.7993590037028,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '231d2a45-a05b-4516-8095-9b1db462aa37',
    CIDADE: 'Irajuba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.892452239990234,
    MAXIMO: 34.02904510498047,
    MEDIA: 33.96074867248535,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '346bdc69-fc59-493a-abfb-6219e0c92955',
    CIDADE: 'Iramaia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.973648071289062,
    MAXIMO: 32.89533996582031,
    MEDIA: 32.434033203125,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b0d1bda-0429-40ef-a613-cca15eba4785',
    CIDADE: 'Iraquara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.962114334106445,
    MAXIMO: 32.09689712524414,
    MEDIA: 32.03064613342285,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f883d29e-52b7-457e-b530-f04117f5467f',
    CIDADE: 'Irará',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.387001037597656,
    MAXIMO: 36.16054153442383,
    MEDIA: 35.7174510044085,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39d03ddd-445a-468d-8c6e-2648c5c13b17',
    CIDADE: 'Irecê',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.92319679260254,
    MAXIMO: 31.930526733398438,
    MEDIA: 31.92686176300049,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e2f0d407-7955-48d0-946e-41b71391f446',
    CIDADE: 'Itabela',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.601408004760742,
    MAXIMO: 29.67867088317871,
    MEDIA: 29.6377592086792,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ec695bea-b1cc-41ff-8bf8-ef1049959dcd',
    CIDADE: 'Itaberaba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.421836853027344,
    MAXIMO: 34.15787887573242,
    MEDIA: 33.74977684020996,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1bca6b08-f209-41f5-801a-3b29774a0a59',
    CIDADE: 'Itabuna',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.271026611328125,
    MAXIMO: 31.58163070678711,
    MEDIA: 31.426328659057617,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85391d0d-d216-4ebf-8249-86ca73a4ac87',
    CIDADE: 'Itacaré',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.41573715209961,
    MAXIMO: 32.99597930908203,
    MEDIA: 32.70585823059082,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c095f8c1-cc13-473f-b0ed-9e83f780ac21',
    CIDADE: 'Itaeté',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.828609466552734,
    MAXIMO: 33.014610290527344,
    MEDIA: 32.928794860839844,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0fcc1cf1-bc2a-49b9-a154-f4788c437468',
    CIDADE: 'Itagi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.554542541503906,
    MAXIMO: 32.94415283203125,
    MEDIA: 32.82652585211954,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ca077ea-df31-4968-b3c1-339a992c8967',
    CIDADE: 'Itagibá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.647010803222656,
    MAXIMO: 33.07231521606445,
    MEDIA: 32.82109832763672,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0b69017-d83c-4550-b51b-c6e5cc0dc250',
    CIDADE: 'Itagimirim',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.749183654785156,
    MAXIMO: 29.890058517456055,
    MEDIA: 29.840957164764404,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78603c40-31f0-4276-94b2-1f747c809477',
    CIDADE: 'Itaguaçu da Bahia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.85834503173828,
    MAXIMO: 31.982662200927734,
    MEDIA: 31.90431240626744,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '21e5a0d3-ea1d-4051-ae1b-518c16006152',
    CIDADE: 'Itaju do Colônia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.708864212036133,
    MAXIMO: 31.32516860961914,
    MEDIA: 30.91774272918701,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f7cd42d-be00-414a-839b-82acaedd52da',
    CIDADE: 'Itajuípe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.58163070678711,
    MAXIMO: 32.26380157470703,
    MEDIA: 32.02311659255447,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db25109e-01bd-4aef-8559-e452a50dc762',
    CIDADE: 'Itamaraju',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.440357208251953,
    MAXIMO: 29.538311004638672,
    MEDIA: 29.492316246032715,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fe598c3-79c1-46a2-8ae2-d654057e27eb',
    CIDADE: 'Itamari',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.54873275756836,
    MAXIMO: 34.15998458862305,
    MEDIA: 33.878244591406244,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7e96d95-480e-4bfa-ad99-5a3335081595',
    CIDADE: 'Itambé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.538570404052734,
    MAXIMO: 31.39153480529785,
    MEDIA: 30.972446123758953,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '221f7a31-4016-42fb-aeff-97149d96cd80',
    CIDADE: 'Itanagra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.207584381103516,
    MAXIMO: 37.88827896118164,
    MEDIA: 36.941534061642884,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbebb125-dde2-453d-9ef4-a884d7f00cd0',
    CIDADE: 'Itanhém',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.313892364501953,
    MAXIMO: 29.395830154418945,
    MEDIA: 29.359784762064617,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae11feaa-165b-48e2-84ab-50b674cf0b1d',
    CIDADE: 'Itaparica',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.92763137817383,
    MAXIMO: 38.59076690673828,
    MEDIA: 37.99084253991193,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c94c25ca-2eba-46c1-8717-3431d0ea526d',
    CIDADE: 'Itapé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.969690322875977,
    MAXIMO: 31.887903213500977,
    MEDIA: 31.242456099226292,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3430581a-9e06-464e-a84a-76a010a4036e',
    CIDADE: 'Itapebi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.96673011779785,
    MAXIMO: 30.000102996826172,
    MEDIA: 29.984174728393555,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8d56403-82af-4561-9b7f-b56223c70fc1',
    CIDADE: 'Itapetinga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.307296752929688,
    MAXIMO: 31.212623596191406,
    MEDIA: 30.61083189646403,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '148399e5-9931-49a2-bad6-e2c8511e7610',
    CIDADE: 'Itapicuru',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.789337158203125,
    MAXIMO: 33.5527229309082,
    MEDIA: 33.22989349365234,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b2975880-cfe5-4fa4-9d04-147a06c6ca86',
    CIDADE: 'Itapitanga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.174495697021484,
    MAXIMO: 32.578086853027344,
    MEDIA: 32.44285103257393,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e2cfb1f5-0bd7-4136-b92e-d65f54e1403c',
    CIDADE: 'Itaquara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.861961364746094,
    MAXIMO: 34.11658477783203,
    MEDIA: 33.98927307128906,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f66cae7-7738-4687-bb41-9743bd5b775c',
    CIDADE: 'Itarantim',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.943313598632812,
    MAXIMO: 30.34943389892578,
    MEDIA: 30.106860733032228,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb487295-81e9-4ca8-bb89-1e69055fd564',
    CIDADE: 'Itatim',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.43331527709961,
    MAXIMO: 34.897274017333984,
    MEDIA: 34.714509859807336,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ddde2717-47f1-419c-96b8-ce0c3f7f28a7',
    CIDADE: 'Itiruçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.48638916015625,
    MAXIMO: 33.63401412963867,
    MEDIA: 33.56020164489746,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7192590a-b897-496c-920d-d65dac170c64',
    CIDADE: 'Itiúba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.319496154785156,
    MAXIMO: 31.880481719970703,
    MEDIA: 31.61822573343913,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7b631a2-c64d-454a-ace6-abe29f530a32',
    CIDADE: 'Itororó',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.877962112426758,
    MAXIMO: 31.592300415039062,
    MEDIA: 31.090407566373997,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54fdaea4-bb90-404b-9a0c-4d88b4259389',
    CIDADE: 'Ituaçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.080665588378906,
    MAXIMO: 31.594575881958008,
    MEDIA: 31.28766679763794,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0fdbc52-31e0-48c3-820d-f287e465f2b6',
    CIDADE: 'Ituberá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.348121643066406,
    MAXIMO: 35.37466812133789,
    MEDIA: 34.7009499221319,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd6d9340-68a6-4d26-8886-edb3a44253a2',
    CIDADE: 'Iuiu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.223697662353516,
    MAXIMO: 30.56206512451172,
    MEDIA: 30.382954406738282,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8333485-8ea8-4219-8c8a-db0876ad4eb1',
    CIDADE: 'Jaborandi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.611347198486328,
    MAXIMO: 30.379911422729492,
    MEDIA: 29.8775524928652,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '048cc728-9eb1-475c-9e66-0d82ecb837c6',
    CIDADE: 'Jacaraci',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.024995803833008,
    MAXIMO: 31.1793212890625,
    MEDIA: 30.583026123046874,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '734f5938-9ad1-4451-81e6-4bf2f9533a46',
    CIDADE: 'Jacobina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.687850952148438,
    MAXIMO: 32.1071662902832,
    MEDIA: 31.85926513671875,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '110501cf-c71c-4b04-80f7-280273fe39f7',
    CIDADE: 'Jaguaquara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.68810272216797,
    MAXIMO: 33.91301345825195,
    MEDIA: 33.80055809020996,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6808fa4c-afa9-47e5-853b-5f0584da9855',
    CIDADE: 'Jaguarari',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.515710830688477,
    MAXIMO: 30.8352108001709,
    MEDIA: 30.651437441507976,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28619654-70b5-43e8-89e5-7b66fc411729',
    CIDADE: 'Jaguaripe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.02941131591797,
    MAXIMO: 37.29570007324219,
    MEDIA: 36.73701286315918,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6edc4fd-8449-48f0-a117-666c2ed835d6',
    CIDADE: 'Jandaíra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.995094299316406,
    MAXIMO: 34.202728271484375,
    MEDIA: 33.61459377447143,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e168004-e37d-4ab7-ad1b-a0eadad0e303',
    CIDADE: 'Jequié',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.78767776489258,
    MAXIMO: 33.66628646850586,
    MEDIA: 33.13036689758301,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1a136bc-c347-4cef-86f9-d44a8b83a3eb',
    CIDADE: 'Jeremoabo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.086671829223633,
    MAXIMO: 31.57693099975586,
    MEDIA: 31.31740681330363,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31d02911-7b44-4087-9b0e-c2f77938fa7d',
    CIDADE: 'Jiquiriçá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.39823532104492,
    MAXIMO: 35.14900207519531,
    MEDIA: 34.69671752585352,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b9192b87-1a12-446e-9f78-fbd720369afb',
    CIDADE: 'Jitaúna',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.94415283203125,
    MAXIMO: 33.66628646850586,
    MEDIA: 33.32120775871866,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed08f119-f7ef-494a-bf44-da7111930181',
    CIDADE: 'João Dourado',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.848283767700195,
    MAXIMO: 31.902448654174805,
    MEDIA: 31.87018648783366,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c6fc653-7238-418f-9ce0-849dd9143c5c',
    CIDADE: 'Juazeiro',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.07552719116211,
    MAXIMO: 30.74591827392578,
    MEDIA: 30.350699043273927,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e392a1d-5fb6-4c49-ad0b-a189bcafb3f0',
    CIDADE: 'Jucuruçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.413311004638672,
    MAXIMO: 29.53546142578125,
    MEDIA: 29.477897262573244,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6162466-07a0-4352-99e4-d0a6cb088f78',
    CIDADE: 'Jussara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.815174102783203,
    MAXIMO: 31.910293579101562,
    MEDIA: 31.855409622192383,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39ddc49a-b016-4297-97a7-53a5c13c05e5',
    CIDADE: 'Jussari',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.726058959960938,
    MAXIMO: 31.32516860961914,
    MEDIA: 30.904723513433265,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b5a19141-714b-4113-8b04-0a809186edab',
    CIDADE: 'Jussiape',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.193227767944336,
    MAXIMO: 31.79084014892578,
    MEDIA: 31.57389997821329,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6052c086-5647-4985-beb9-a14fb8b9d252',
    CIDADE: 'Lafaiete Coutinho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.977237701416016,
    MAXIMO: 33.63401412963867,
    MEDIA: 33.31266790446668,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6f755ab-f476-4212-a071-ca82b15da910',
    CIDADE: 'Lagoa Real',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.2397403717041,
    MAXIMO: 31.29737663269043,
    MEDIA: 31.26765505472819,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3a29833-50fd-446a-80fb-44b51ae48e55',
    CIDADE: 'Laje',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.14900207519531,
    MAXIMO: 35.56526184082031,
    MEDIA: 35.35713195800781,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '216ebe39-d572-4bf7-bb47-037fde163a3b',
    CIDADE: 'Lajedão',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.26814842224121,
    MAXIMO: 29.316450119018555,
    MEDIA: 29.292299270629883,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d744ef5-be2f-48b5-9bc6-7db6742a105b',
    CIDADE: 'Lajedinho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.41224670410156,
    MAXIMO: 32.5222282409668,
    MEDIA: 32.46723747253418,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '518cf433-7692-4290-9284-0a1f685fa1ff',
    CIDADE: 'Lajedo do Tabocal',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.13880920410156,
    MAXIMO: 33.78208923339844,
    MEDIA: 33.462391144959085,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f332387-ac6f-4f6c-a7d6-19d9200e2081',
    CIDADE: 'Lamarão',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.247737884521484,
    MAXIMO: 34.885005950927734,
    MEDIA: 34.74356004863237,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b2b94ff-45ba-4380-84a9-860720a2d496',
    CIDADE: 'Lapão',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.921483993530273,
    MAXIMO: 31.95237922668457,
    MEDIA: 31.93838999026699,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'edf50642-fff0-43bd-9dc6-0a4710d0d669',
    CIDADE: 'Lauro de Freitas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 39.19675064086914,
    MAXIMO: 39.261512756347656,
    MEDIA: 39.21167373164458,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee10828f-0158-4267-8e10-6e9eb4de8bc9',
    CIDADE: 'Lençóis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.18426513671875,
    MAXIMO: 32.30746078491211,
    MEDIA: 32.246310234069824,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a5d9f7a-fbfa-400b-a433-dca07f2004f3',
    CIDADE: 'Licínio de Almeida',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.87192726135254,
    MAXIMO: 30.98731803894043,
    MEDIA: 30.929622650146484,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad40270c-ff84-401e-aa9a-d2c6a449f4ae',
    CIDADE: 'Livramento de Nossa Senhora',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.337989807128906,
    MAXIMO: 31.57374382019043,
    MEDIA: 31.45833619435628,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '05499192-b6ad-4cd3-a184-e9f37882f37a',
    CIDADE: 'Luís Eduardo Magalhães',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.77777671813965,
    MAXIMO: 31.620269775390625,
    MEDIA: 31.203708061805138,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ec5a4e5-3ec7-4729-b82e-c1a1e18f11ab',
    CIDADE: 'Macajuba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.47772979736328,
    MAXIMO: 33.66328811645508,
    MEDIA: 33.57050895690918,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd01d3e08-bb9e-4b94-ba3e-a9729480b9cf',
    CIDADE: 'Macarani',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.946805953979492,
    MAXIMO: 30.84307289123535,
    MEDIA: 30.23656129837036,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1009837c-a3e1-40f3-bd91-0e5121d1b291',
    CIDADE: 'Macaúbas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.92610740661621,
    MAXIMO: 31.221580505371094,
    MEDIA: 31.064651277330185,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd754bd43-a740-400a-a62a-74aa1965d69f',
    CIDADE: 'Macururé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.74566650390625,
    MAXIMO: 30.926149368286133,
    MEDIA: 30.824103673299152,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3840c7e-014f-478e-8137-ea9f402dc87b',
    CIDADE: 'Madre de Deus',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.93216323852539,
    MAXIMO: 38.57577896118164,
    MEDIA: 37.96360125324414,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14d8dfe0-ccff-4a74-87eb-7c41127005b1',
    CIDADE: 'Maetinga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.810922622680664,
    MAXIMO: 31.37531089782715,
    MEDIA: 31.043113708496094,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0c718fe-f39d-4ef2-b755-2ca3e560404c',
    CIDADE: 'Maiquinique',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.98187828063965,
    MAXIMO: 30.137279510498047,
    MEDIA: 30.059578895568848,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a8dadcb-a840-42d5-9568-895fd3a7953b',
    CIDADE: 'Mairi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.633697509765625,
    MAXIMO: 32.96992874145508,
    MEDIA: 32.79318364461263,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9638e055-ce44-4a90-8221-86db9c18bebd',
    CIDADE: 'Malhada',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.26058006286621,
    MAXIMO: 30.655855178833008,
    MEDIA: 30.460741996765137,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '525aa31b-4b2a-4945-8aa3-1e5a2a399782',
    CIDADE: 'Malhada de Pedras',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.247364044189453,
    MAXIMO: 31.465627670288086,
    MEDIA: 31.34373156229655,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdc4a230-ebe5-48f7-b048-2ea3241843f5',
    CIDADE: 'Manoel Vitorino',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.45529556274414,
    MAXIMO: 32.69430160522461,
    MEDIA: 32.5806090037028,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3485d26a-ac2a-4035-9c22-a3c8836bb45d',
    CIDADE: 'Mansidão',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.977924346923828,
    MAXIMO: 32.73854446411133,
    MEDIA: 32.312796401977536,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '240b270f-8e29-4406-a7b2-ee6359cdd4d7',
    CIDADE: 'Maracás',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.832759857177734,
    MAXIMO: 33.38173294067383,
    MEDIA: 33.13093893868582,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '713ea3ec-4bf0-4ed3-8093-3a9761924c7e',
    CIDADE: 'Maragogipe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.60466384887695,
    MAXIMO: 37.31086730957031,
    MEDIA: 36.969835890854746,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f357c312-447a-47e8-9e15-5a1463ae4ebe',
    CIDADE: 'Maraú',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.17394256591797,
    MAXIMO: 33.65885925292969,
    MEDIA: 33.44554011027018,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '178af5a6-bd70-480e-ab1e-c6fb4178b0d8',
    CIDADE: 'Marcionílio Souza',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.55042266845703,
    MAXIMO: 32.811283111572266,
    MEDIA: 32.66456731160482,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9785e8e-a588-4cfb-a601-d70bda1ddb16',
    CIDADE: 'Mascote',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.117633819580078,
    MAXIMO: 30.275402069091797,
    MEDIA: 30.216315587361652,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a254c074-6e46-4969-9543-32a7e9290212',
    CIDADE: 'Mata de São João',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.61478805541992,
    MAXIMO: 37.88409423828125,
    MEDIA: 37.749441146850586,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e170fbc0-b979-432c-8d11-8050859504e2',
    CIDADE: 'Matina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.83120346069336,
    MAXIMO: 30.957717895507812,
    MEDIA: 30.894720713297527,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e06c77b3-67ae-4fe8-a7e7-a118c1eea0a9',
    CIDADE: 'Medeiros Neto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.291913986206055,
    MAXIMO: 29.36304473876953,
    MEDIA: 29.324588139851887,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '897d5f6d-29a4-41c1-85ef-7c4e58b205fe',
    CIDADE: 'Miguel Calmon',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.976306915283203,
    MAXIMO: 32.342437744140625,
    MEDIA: 32.134615898132324,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27ea27b5-d271-43f6-8f6e-cc19d8369c2c',
    CIDADE: 'Milagres',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.228904724121094,
    MAXIMO: 34.897274017333984,
    MEDIA: 34.60604612473379,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5828483-0545-43ef-849c-662484b6a698',
    CIDADE: 'Mirangaba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.42983627319336,
    MAXIMO: 31.539880752563477,
    MEDIA: 31.487237294514973,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5b018aa-ee0f-44ba-8a8b-c2c4b79177ed',
    CIDADE: 'Mirante',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.28679275512695,
    MAXIMO: 32.48686218261719,
    MEDIA: 32.37682418823242,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77544ae5-a7ec-402f-805c-34fa5f049142',
    CIDADE: 'Monte Santo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.97563934326172,
    MAXIMO: 31.71109962463379,
    MEDIA: 31.341369108720258,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6866e90-0aa1-4fdf-a974-62936b318f3a',
    CIDADE: 'Morpará',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.496992111206055,
    MAXIMO: 31.711660385131836,
    MEDIA: 31.641100247701008,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e34ac21-8d98-4604-8da1-28109ce3c4d6',
    CIDADE: 'Morro do Chapéu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.705657958984375,
    MAXIMO: 32.41762161254883,
    MEDIA: 32.017025897377415,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '862cba09-ef49-482b-b24d-48d16cbddcda',
    CIDADE: 'Mortugaba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.060789108276367,
    MAXIMO: 31.1793212890625,
    MEDIA: 30.624190375254866,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7726b00-5e5a-48ad-bda6-c8aa12135c11',
    CIDADE: 'Mucugê',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.730085372924805,
    MAXIMO: 32.52003479003906,
    MEDIA: 31.95201063156128,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f06115b-d03f-4a9a-833b-4f0f0bc1254f',
    CIDADE: 'Mucuri',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.33824348449707,
    MAXIMO: 29.517709732055664,
    MEDIA: 29.446996688842773,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96734b6d-d57d-44d7-83e1-14dc477343a4',
    CIDADE: 'Mulungu do Morro',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.989870071411133,
    MAXIMO: 32.05873489379883,
    MEDIA: 32.02430248260498,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88af4f8c-4c5d-4dee-b8b4-c1b78e873aac',
    CIDADE: 'Mundo Novo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.519309997558594,
    MAXIMO: 32.78823471069336,
    MEDIA: 32.65164756774902,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5da0edcb-1737-4f98-8975-020195654b34',
    CIDADE: 'Muniz Ferreira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.02941131591797,
    MAXIMO: 36.710506439208984,
    MEDIA: 36.43187290776956,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b024a4e2-1feb-4771-8e57-a47bcec5cfa8',
    CIDADE: 'Muquém do São Francisco',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.128568649291992,
    MAXIMO: 31.51180648803711,
    MEDIA: 31.317856629689533,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7747c587-d256-41b1-a1cf-530ee1374598',
    CIDADE: 'Muritiba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.10439682006836,
    MAXIMO: 36.60466384887695,
    MEDIA: 36.31152973366082,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c437af00-855d-4ef7-9bcd-f226b54fe890',
    CIDADE: 'Mutuípe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.39823532104492,
    MAXIMO: 35.14900207519531,
    MEDIA: 34.904181276106684,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41e78b39-dfbc-4903-ac22-b628c60cc78f',
    CIDADE: 'Nazaré',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.53781509399414,
    MAXIMO: 37.29570007324219,
    MEDIA: 36.75865167729905,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '99b5fc0f-d69a-4185-8e55-8b574b8b4cbb',
    CIDADE: 'Nilo Peçanha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.05109786987305,
    MAXIMO: 35.37466812133789,
    MEDIA: 35.21288299560547,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0a1f975-0cc1-4909-bf6d-ccd5e59d17c8',
    CIDADE: 'Nordestina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.80356216430664,
    MAXIMO: 32.37212371826172,
    MEDIA: 32.07537065483091,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c11ea399-3de4-417d-8355-d2d9866585cc',
    CIDADE: 'Nova Canaã',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.59175682067871,
    MAXIMO: 31.820043563842773,
    MEDIA: 31.733421961466473,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7c019f5-8371-4f22-9354-3862f3ab5643',
    CIDADE: 'Nova Fátima',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.20689392089844,
    MAXIMO: 33.71806335449219,
    MEDIA: 33.47385452498369,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '360df84c-7bd3-4495-8ae9-4906760770c6',
    CIDADE: 'Nova Ibiá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.54873275756836,
    MAXIMO: 34.10368347167969,
    MEDIA: 33.88085013530138,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d068485-83d8-49c1-933d-5b2ba25d4ea6',
    CIDADE: 'Nova Itarana',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.152950286865234,
    MAXIMO: 34.228904724121094,
    MEDIA: 34.190927505493164,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '543d81f7-3cc6-48f3-8d53-ce22d8ffa622',
    CIDADE: 'Nova Redenção',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.165863037109375,
    MAXIMO: 33.096595764160156,
    MEDIA: 32.584634023199406,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd262b436-6a65-4808-8f8d-97f588c167dd',
    CIDADE: 'Nova Soure',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.199092864990234,
    MAXIMO: 33.6155891418457,
    MEDIA: 33.46403376261393,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18ff57f0-791b-44fd-88bb-120af18b010f',
    CIDADE: 'Nova Viçosa',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.37471580505371,
    MAXIMO: 29.526168823242188,
    MEDIA: 29.450188827514648,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89d7a613-786c-48aa-9b62-cc845d9dd34d',
    CIDADE: 'Novo Horizonte',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.400930404663086,
    MAXIMO: 31.47311019897461,
    MEDIA: 31.437020301818848,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9daa6480-6d9e-46c0-9483-aa70591be1a6',
    CIDADE: 'Novo Triunfo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.733844757080078,
    MAXIMO: 31.75823211669922,
    MEDIA: 31.74603843688965,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22fa9f20-903b-43e5-b317-d2e5a0e3b8a7',
    CIDADE: 'Olindina',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.61127853393555,
    MAXIMO: 34.0766716003418,
    MEDIA: 33.84397506713867,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f050154-984d-4dab-94ee-ffca24cc0803',
    CIDADE: 'Oliveira dos Brejinhos',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.48943328857422,
    MAXIMO: 31.704071044921875,
    MEDIA: 31.616309252652254,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9998899-aae3-43ab-848d-d327f50079dd',
    CIDADE: 'Ouriçangas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.592140197753906,
    MAXIMO: 36.359703063964844,
    MEDIA: 35.619197437847774,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da85eb53-aaa9-4af5-bda5-68b8bff5a896',
    CIDADE: 'Ourolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.55414390563965,
    MAXIMO: 31.706493377685547,
    MEDIA: 31.62007293701172,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd737d55a-73c7-44fe-b22f-872e0d246f1b',
    CIDADE: 'Palmas de Monte Alto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.5119571685791,
    MAXIMO: 30.817949295043945,
    MEDIA: 30.669277403089737,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15928618-8dfb-4dbe-84da-9bd5cc25eeac',
    CIDADE: 'Palmeiras',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.98907470703125,
    MAXIMO: 32.016822814941406,
    MEDIA: 32.00294876098633,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1dc3cec-13d3-40a7-9311-1a44424914d5',
    CIDADE: 'Paramirim',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.14109230041504,
    MAXIMO: 31.490949630737305,
    MEDIA: 31.28213055928548,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd3ca5aa-1684-44ce-b6f2-978982eaad15',
    CIDADE: 'Paratinga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.936656951904297,
    MAXIMO: 31.415220260620117,
    MEDIA: 31.17153685433524,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25e8dac0-5a92-45c4-b490-e19d3bf2aa1d',
    CIDADE: 'Paripiranga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.870925903320312,
    MAXIMO: 32.08423614501953,
    MEDIA: 31.977581024169922,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '34c775f4-216e-4be9-a5f2-9ef2d619e8e1',
    CIDADE: 'Pau Brasil',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.232149124145508,
    MAXIMO: 30.43773078918457,
    MEDIA: 30.33493995666504,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7ddc7ae7-85da-40be-a59f-0f03fedd1c77',
    CIDADE: 'Paulo Afonso',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.119956970214844,
    MAXIMO: 31.165088653564453,
    MEDIA: 31.142250061035156,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd01ab845-9100-4a0a-87df-a15120d33e94',
    CIDADE: 'Pé de Serra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.53651809692383,
    MAXIMO: 34.366920471191406,
    MEDIA: 33.98114438199993,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9cf8bd96-f7eb-4833-a2f1-90d72fabdd03',
    CIDADE: 'Pedrão',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.592140197753906,
    MAXIMO: 36.359703063964844,
    MEDIA: 36.17148347154222,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5c2dc4b-8cde-4f5c-8974-81f0fa79b4f7',
    CIDADE: 'Pedro Alexandre',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.413455963134766,
    MAXIMO: 31.538297653198242,
    MEDIA: 31.461458206176758,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3306f94-6743-4693-a91c-d9111182442c',
    CIDADE: 'Piatã',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.45790672302246,
    MAXIMO: 31.691070556640625,
    MEDIA: 31.588153839111328,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17b638af-3627-4008-a4de-282b64577891',
    CIDADE: 'Pilão Arcado',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.10081481933594,
    MAXIMO: 33.85050964355469,
    MEDIA: 32.89437637329102,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1032f010-78c3-4acc-b875-74e6d05fd38e',
    CIDADE: 'Pindaí',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.54722023010254,
    MAXIMO: 30.925617218017578,
    MEDIA: 30.754753748575848,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37ee1c96-22b0-4469-b6d4-483e174bee70',
    CIDADE: 'Pindobaçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.212491989135742,
    MAXIMO: 31.618974685668945,
    MEDIA: 31.45483441897813,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61697744-d7ae-452e-b2bc-887c5d4f9a07',
    CIDADE: 'Pintadas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.455997467041016,
    MAXIMO: 33.6387939453125,
    MEDIA: 33.54739570617676,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1cec5135-c950-419a-b02b-618ab0fa2a26',
    CIDADE: 'Piraí do Norte',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.73447799682617,
    MAXIMO: 34.348121643066406,
    MEDIA: 34.24006703386676,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a9a71d9-afb0-4fd9-a1f8-520a70bf292d',
    CIDADE: 'Piripá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.002866744995117,
    MAXIMO: 31.107519149780273,
    MEDIA: 31.055192947387695,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e949e80e-9c44-4428-8d60-a74c5f952c5d',
    CIDADE: 'Piritiba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.397315979003906,
    MAXIMO: 32.52669906616211,
    MEDIA: 32.47690200805664,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7617bf0a-3cc6-49f1-b75d-bf76a61b8670',
    CIDADE: 'Planaltino',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.4633903503418,
    MAXIMO: 33.78208923339844,
    MEDIA: 33.61837863922119,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16c111a9-f7fb-4fe2-94a6-f40ac092bb25',
    CIDADE: 'Planalto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.2393856048584,
    MAXIMO: 31.95809555053711,
    MEDIA: 31.653961181640625,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b65ba6dd-ff1e-4ee2-854e-10b85967022b',
    CIDADE: 'Poções',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.00199890136719,
    MAXIMO: 32.23097229003906,
    MEDIA: 32.116485595703125,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab86241b-d4fd-4698-8249-47c9437f14c4',
    CIDADE: 'Pojuca',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.12604904174805,
    MAXIMO: 37.88827896118164,
    MEDIA: 37.33863365298474,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4835e85a-a6d7-449c-a734-788f1715c29e',
    CIDADE: 'Ponto Novo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.571998596191406,
    MAXIMO: 32.196510314941406,
    MEDIA: 31.794381548267147,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16aa0d69-80ef-408b-8dbc-d17df5b6e3fe',
    CIDADE: 'Porto Seguro',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.56817626953125,
    MAXIMO: 29.763689041137695,
    MEDIA: 29.669762929280598,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '104082df-f7cc-4511-a09e-a5dd30fa0e13',
    CIDADE: 'Potiraguá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.07670783996582,
    MAXIMO: 30.204763412475586,
    MEDIA: 30.126848220825195,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'feae956e-5e65-417b-8cd3-36e3ede7acf4',
    CIDADE: 'Prado',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.503847122192383,
    MAXIMO: 29.565298080444336,
    MEDIA: 29.535980224609375,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0144a04-74a1-4b0a-9600-2c0b5a44fc39',
    CIDADE: 'Presidente Dutra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.922529220581055,
    MAXIMO: 31.946533203125,
    MEDIA: 31.93224450201337,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '389a7f2e-64fd-4a60-b3de-96690cde5577',
    CIDADE: 'Presidente Jânio Quadros',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.229782104492188,
    MAXIMO: 31.340970993041992,
    MEDIA: 31.276582717895508,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d4d4434-0782-402b-aee3-b5dd73578d5e',
    CIDADE: 'Presidente Tancredo Neves',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.713348388671875,
    MAXIMO: 35.058162689208984,
    MEDIA: 34.88575553894043,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b7e4972-376a-4780-9c1a-bc37070d7f6c',
    CIDADE: 'Queimadas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.95381736755371,
    MAXIMO: 32.42436981201172,
    MEDIA: 32.18899885813395,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90acc9d5-5b25-4834-959f-eb4356628780',
    CIDADE: 'Quijingue',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.97373390197754,
    MAXIMO: 32.581241607666016,
    MEDIA: 32.20912011464437,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e31c3f7-67cc-4dbb-8e3a-53cf178a189f',
    CIDADE: 'Quixabeira',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.34762191772461,
    MAXIMO: 32.56728744506836,
    MEDIA: 32.457454681396484,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdd04aba-5716-4604-bdbf-0d1ceb3b3998',
    CIDADE: 'Rafael Jambeiro',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.43331527709961,
    MAXIMO: 35.09777069091797,
    MEDIA: 34.75924555460612,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fab72d99-f02a-4967-a229-a0d06428f8f0',
    CIDADE: 'Remanso',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.00957107543945,
    MAXIMO: 33.04943084716797,
    MEDIA: 32.49958419799805,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a797cd7-b4d9-4713-bf27-6a196ad9d9bd',
    CIDADE: 'Retirolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.15992736816406,
    MAXIMO: 33.71806335449219,
    MEDIA: 33.424440465654875,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '567a8ba0-c6ca-414d-b101-868932948bb9',
    CIDADE: 'Riachão das Neves',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.690645217895508,
    MAXIMO: 32.160484313964844,
    MEDIA: 31.96413511388442,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1acdea36-ee7b-4b2b-b6b4-b29e23db3c05',
    CIDADE: 'Riachão do Jacuípe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.71806335449219,
    MAXIMO: 34.366920471191406,
    MEDIA: 34.10211753845215,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53fd6d78-a92f-4527-a761-2696a05eac85',
    CIDADE: 'Riacho de Santana',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.708141326904297,
    MAXIMO: 31.027236938476562,
    MEDIA: 30.84748035007053,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd084613-71aa-47c8-9816-2d0ecad31668',
    CIDADE: 'Ribeira do Amparo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.82774353027344,
    MAXIMO: 32.829586029052734,
    MEDIA: 32.828664779663086,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '300d5416-dcef-4c61-adca-891023b888d0',
    CIDADE: 'Ribeira do Pombal',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.209468841552734,
    MAXIMO: 32.50107955932617,
    MEDIA: 32.39217631022135,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd0c94f8-2265-4233-a1ee-06b0d8c622df',
    CIDADE: 'Ribeirão do Largo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.01258087158203,
    MAXIMO: 30.227270126342773,
    MEDIA: 30.142499923706055,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7a0beb1-7d20-460d-afc5-f0463169e537',
    CIDADE: 'Rio de Contas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.414464950561523,
    MAXIMO: 31.79084014892578,
    MEDIA: 31.626637649536132,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2958c5a2-7a6a-4da4-9c4e-ec7644315d0b',
    CIDADE: 'Rio do Antônio',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.10692024230957,
    MAXIMO: 31.198856353759766,
    MEDIA: 31.152888298034668,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'abbdb0d6-b248-4165-a887-d08ff685e2bd',
    CIDADE: 'Rio do Pires',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.156240463256836,
    MAXIMO: 31.540504455566406,
    MEDIA: 31.373526383513948,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4087d84-0208-4473-ae45-e410d2022bb3',
    CIDADE: 'Rio Real',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.73882293701172,
    MAXIMO: 34.202728271484375,
    MEDIA: 33.94721476236979,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8466b27-b761-4818-be2b-a7157ec731aa',
    CIDADE: 'Rodelas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.82798194885254,
    MAXIMO: 31.06804084777832,
    MEDIA: 30.94215256827218,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '228ae2bf-cb38-4c16-8025-467719e14e07',
    CIDADE: 'Ruy Barbosa',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.47806930541992,
    MAXIMO: 33.80397033691406,
    MEDIA: 32.85312707083566,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e445a06-db6e-4745-bb24-832cafef3efd',
    CIDADE: 'Salinas da Margarida',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.29570007324219,
    MAXIMO: 37.93216323852539,
    MEDIA: 37.61320144186362,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2308caa3-50c0-41d9-84e8-dc5fce026984',
    CIDADE: 'Salvador',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.93216323852539,
    MAXIMO: 38.59076690673828,
    MEDIA: 38.3662363688151,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14d54d89-23d5-4d75-aa56-ff9966e89c0a',
    CIDADE: 'Santa Bárbara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.693336486816406,
    MAXIMO: 35.143035888671875,
    MEDIA: 34.91818618774414,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2da692df-5ad6-46d0-8c69-8f86891b15a8',
    CIDADE: 'Santa Brígida',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.19865608215332,
    MAXIMO: 31.32147216796875,
    MEDIA: 31.262471675872803,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76a728ab-d473-4b9f-af73-ea4a20d21239',
    CIDADE: 'Santa Cruz Cabrália',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.82158851623535,
    MAXIMO: 29.844045639038086,
    MEDIA: 29.833812713623047,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b52f205e-c6de-49fd-abfb-9454b8062e16',
    CIDADE: 'Santa Cruz da Vitória',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.134727478027344,
    MAXIMO: 31.38327980041504,
    MEDIA: 31.25900363922119,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1e9d7e7-0cc9-465b-b241-3849a4876505',
    CIDADE: 'Santa Inês',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.02904510498047,
    MAXIMO: 34.6157341003418,
    MEDIA: 34.26417103397145,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23693234-6aee-4554-97e0-a4442cba43da',
    CIDADE: 'Santaluz',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.37212371826172,
    MAXIMO: 33.15992736816406,
    MEDIA: 32.69593111673991,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '714974c9-dd48-4e5b-9c15-14618fa7b9a1',
    CIDADE: 'Santa Luzia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.29183006286621,
    MAXIMO: 30.498273849487305,
    MEDIA: 30.423472722371418,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c79961f-51ba-4552-9df1-ad14499086db',
    CIDADE: 'Santa Maria da Vitória',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.270505905151367,
    MAXIMO: 30.546762466430664,
    MEDIA: 30.393296105521067,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f45e8479-3b32-44ed-995c-ca3b4351250b',
    CIDADE: 'Santana',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.516611099243164,
    MAXIMO: 30.802181243896484,
    MEDIA: 30.646442413330078,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df74cd20-bbb5-471d-9321-5281bcb2c319',
    CIDADE: 'Santanópolis',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.885005950927734,
    MAXIMO: 35.889678955078125,
    MEDIA: 35.331938951157994,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e255d65c-60bc-4920-bee5-4279e7224a59',
    CIDADE: 'Santa Rita de Cássia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.104095458984375,
    MAXIMO: 32.867591857910156,
    MEDIA: 32.50760329397101,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58945ca9-4494-4a7c-b996-b7bc70b68d45',
    CIDADE: 'Santa Terezinha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.85456848144531,
    MAXIMO: 35.22858428955078,
    MEDIA: 34.993475596110024,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4dfa9715-ea23-4d70-b530-c74807112c2e',
    CIDADE: 'Santo Amaro',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.38414001464844,
    MAXIMO: 37.93216323852539,
    MEDIA: 37.166267371011315,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a1ec7cd-97e1-4f87-8737-2adbc5dcfcdd',
    CIDADE: 'Santo Antônio de Jesus',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.56526184082031,
    MAXIMO: 36.1741943359375,
    MEDIA: 35.97651559815367,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b8e2785-7669-4cc2-b331-dafbfd596e44',
    CIDADE: 'Santo Estêvão',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.48442840576172,
    MAXIMO: 35.90445327758789,
    MEDIA: 35.694440841674805,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '57ac3f49-4d38-4c68-b05e-6c51a1509ec0',
    CIDADE: 'São Desidério',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.079662322998047,
    MAXIMO: 31.061378479003906,
    MEDIA: 30.568327171857966,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bca589ef-cbba-4c3e-8885-e59a45fcad5f',
    CIDADE: 'São Domingos',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.709136962890625,
    MAXIMO: 33.36162185668945,
    MEDIA: 33.15491988404621,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96160e47-e056-4fca-a6d7-e74a9a2ac7f1',
    CIDADE: 'São Félix',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.60466384887695,
    MAXIMO: 36.731754302978516,
    MEDIA: 36.61236364945863,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ecc19a22-fd34-4040-afaa-87cb283f0a35',
    CIDADE: 'São Félix do Coribe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.419845581054688,
    MAXIMO: 30.6051082611084,
    MEDIA: 30.51181650161743,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '294b53ea-e185-4aad-940d-1a9aa64fa6ce',
    CIDADE: 'São Felipe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.1741943359375,
    MAXIMO: 36.731754302978516,
    MEDIA: 36.3874924289766,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f1360ea-e268-4e2a-9d7a-1edf340e764c',
    CIDADE: 'São Francisco do Conde',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.25776290893555,
    MAXIMO: 38.57577896118164,
    MEDIA: 37.81374123681953,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d19798c-2183-4317-beb9-dc7dc1498462',
    CIDADE: 'São Gabriel',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.772750854492188,
    MAXIMO: 31.89830780029297,
    MEDIA: 31.839056491851807,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b893c43-b58c-4080-8cd6-f172c5826d52',
    CIDADE: 'São Gonçalo dos Campos',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.00523376464844,
    MAXIMO: 36.809906005859375,
    MEDIA: 36.405730568643946,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47137093-e434-42e7-95fe-aa455e17cdba',
    CIDADE: 'São José da Vitória',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.969690322875977,
    MAXIMO: 30.998188018798828,
    MEDIA: 30.998169756002508,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7138a17-0dc6-4cbd-8326-d98f0662ac35',
    CIDADE: 'São José do Jacuípe',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.44031524658203,
    MAXIMO: 32.85539245605469,
    MEDIA: 32.701815585053794,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13daea46-94e3-47a1-811e-7d7afca38f24',
    CIDADE: 'São Miguel das Matas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.14900207519531,
    MAXIMO: 35.69026565551758,
    MEDIA: 35.35140986623085,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7fd42b70-a7f5-4024-8b4d-be6fe4c9fb88',
    CIDADE: 'São Sebastião do Passé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.25776290893555,
    MAXIMO: 38.5963134765625,
    MEDIA: 37.806010094954836,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3169734e-3d3c-446c-b0a7-b42d6ffb8b7a',
    CIDADE: 'Sapeaçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.64497756958008,
    MAXIMO: 36.19899368286133,
    MEDIA: 36.09508111845103,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '682b9dc6-5172-4364-8bff-e804e84558b6',
    CIDADE: 'Sátiro Dias',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.027503967285156,
    MAXIMO: 34.58480453491211,
    MEDIA: 34.3013334274292,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45861a84-d6f3-4b9b-b713-e60b3d4ef089',
    CIDADE: 'Saubara',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.135765075683594,
    MAXIMO: 37.93216323852539,
    MEDIA: 37.55670933124749,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '667fafd7-3819-440b-ae86-91526b99c0e4',
    CIDADE: 'Saúde',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.571998596191406,
    MAXIMO: 31.72224998474121,
    MEDIA: 31.64712429046631,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35b51226-5151-4c69-9baf-5214216ad244',
    CIDADE: 'Seabra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.749656677246094,
    MAXIMO: 31.94544792175293,
    MEDIA: 31.860632366604275,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c1533c0-2f30-40e0-ac54-e49955f4c2b3',
    CIDADE: 'Sebastião Laranjeiras',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.01061248779297,
    MAXIMO: 30.67620849609375,
    MEDIA: 30.49573669433594,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e41deba6-93f5-4d2f-baeb-5cd70aaedaed',
    CIDADE: 'Senhor do Bonfim',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.0264949798584,
    MAXIMO: 31.2625675201416,
    MEDIA: 31.171209971110027,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a6700f4-155b-49c4-928d-57b1b84edb18',
    CIDADE: 'Serra do Ramalho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.513032913208008,
    MAXIMO: 30.72045135498047,
    MEDIA: 30.628808430262975,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c15753a-9c5b-4e04-a6b2-0a04325b4a25',
    CIDADE: 'Sento Sé',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.968076705932617,
    MAXIMO: 32.03172302246094,
    MEDIA: 31.589020342440218,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6bc07dc-fcab-4f58-a71c-e63d60ee5ac1',
    CIDADE: 'Serra Dourada',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.68250846862793,
    MAXIMO: 30.898714065551758,
    MEDIA: 30.799662113189697,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25bb5f4c-9f46-45d8-970f-754a1fdb6feb',
    CIDADE: 'Serra Preta',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.6257438659668,
    MAXIMO: 34.957542419433594,
    MEDIA: 34.791643142700195,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '700aa883-bd09-45d6-b25b-35d31e4b39d8',
    CIDADE: 'Serrinha',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.81983947753906,
    MAXIMO: 34.693336486816406,
    MEDIA: 34.271934502794785,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '015eaf9b-ca94-4d88-927c-34b36dda2f12',
    CIDADE: 'Serrolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.1071662902832,
    MAXIMO: 32.56728744506836,
    MEDIA: 32.379952706496034,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7ad2b26-aa26-4b94-aea1-1c2b534a62ad',
    CIDADE: 'Simões Filho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 38.20396423339844,
    MAXIMO: 39.19675064086914,
    MEDIA: 38.97103609006117,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16c94686-6630-410f-9190-9ac7cd3f4a10',
    CIDADE: 'Sítio do Mato',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.768970489501953,
    MAXIMO: 31.27070426940918,
    MEDIA: 30.980367342631023,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3f334fd-66b7-4fea-89bb-b2512d6de5a1',
    CIDADE: 'Sítio do Quinto',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.578292846679688,
    MAXIMO: 31.75254249572754,
    MEDIA: 31.665417671203613,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bea46a0-3c65-4867-80b7-d692dd50e952',
    CIDADE: 'Sobradinho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.62109375,
    MAXIMO: 30.89873504638672,
    MEDIA: 30.762917709350585,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f03d1f31-7045-4185-b960-b690bb2a3515',
    CIDADE: 'Souto Soares',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.921194076538086,
    MAXIMO: 31.969802856445312,
    MEDIA: 31.9454984664917,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc199b8b-04b7-4afd-bb8b-93541c148bd9',
    CIDADE: 'Tabocas do Brejo Velho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.846923828125,
    MAXIMO: 31.077247619628906,
    MEDIA: 30.98651933670044,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39874688-fa19-4996-9945-20a58d3c126b',
    CIDADE: 'Tanhaçu',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.286701202392578,
    MAXIMO: 31.534122467041016,
    MEDIA: 31.40832281112671,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c145f5fd-8212-457f-9a9b-a32ff77e014f',
    CIDADE: 'Tanque Novo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.946929931640625,
    MAXIMO: 31.328189849853516,
    MEDIA: 31.13755989074707,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd44aab6f-5ceb-4e19-be8b-2c490675974b',
    CIDADE: 'Tanquinho',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.476112365722656,
    MAXIMO: 35.59861373901367,
    MEDIA: 34.90932200757697,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c925706-2c35-49bf-9f27-7af5d44a85c6',
    CIDADE: 'Taperoá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.73821258544922,
    MAXIMO: 35.832584381103516,
    MEDIA: 35.006982943460585,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a83e227-ccbf-464c-ba07-5d0ac0b1ca3a',
    CIDADE: 'Tapiramutá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.23031997680664,
    MAXIMO: 32.642032623291016,
    MEDIA: 32.448353582245424,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01836400-7db5-4a46-bf4d-de3df37369f3',
    CIDADE: 'Teixeira de Freitas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.398386001586914,
    MAXIMO: 29.4505558013916,
    MEDIA: 29.426459121704102,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e9b6383-bd4d-46a8-a919-10117f479969',
    CIDADE: 'Teodoro Sampaio',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.16054153442383,
    MAXIMO: 37.004573822021484,
    MEDIA: 36.68331287768619,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd585055d-4683-4716-8a3e-ffdc7cb005d2',
    CIDADE: 'Teofilândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.41014099121094,
    MAXIMO: 33.93634796142578,
    MEDIA: 33.81342170947853,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dcb98c00-9d31-45a1-bea7-ddad92a2317e',
    CIDADE: 'Teolândia',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.15998458862305,
    MAXIMO: 35.058162689208984,
    MEDIA: 34.52227079837313,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '272fbab9-778e-44b6-b55a-f7ed46dab247',
    CIDADE: 'Terra Nova',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 36.72398376464844,
    MAXIMO: 37.636539459228516,
    MEDIA: 37.12243223279736,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8dfd4db-0a64-4893-9ab5-e8b0538af403',
    CIDADE: 'Tremedal',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.42799186706543,
    MAXIMO: 31.132518768310547,
    MEDIA: 30.775994982038224,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f03e75cb-cdff-4933-81dd-33fad0d51f61',
    CIDADE: 'Tucano',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.41316223144531,
    MAXIMO: 33.50680923461914,
    MEDIA: 32.91580581665039,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c4ad00e-35a6-4add-9eae-60f3da2eca0f',
    CIDADE: 'Uauá',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.620601654052734,
    MAXIMO: 30.97894859313965,
    MEDIA: 30.77897620201111,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '213e6ec4-4103-44cd-b51d-78c936ea827b',
    CIDADE: 'Ubaíra',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.39823532104492,
    MAXIMO: 34.77622985839844,
    MEDIA: 34.59673309326172,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78f55f64-7166-4097-8a54-69554825407c',
    CIDADE: 'Ubaitaba',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.9501838684082,
    MAXIMO: 33.061309814453125,
    MEDIA: 33.005746841430664,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c289551-7bc1-42aa-8b66-24da081e15e9',
    CIDADE: 'Ubatã',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 33.353084564208984,
    MAXIMO: 33.73447799682617,
    MEDIA: 33.54378128051758,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bef9fc85-b7c9-4399-b896-438c6902972e',
    CIDADE: 'Uibaí',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.93107032775879,
    MAXIMO: 31.941247940063477,
    MEDIA: 31.936159133911133,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '608bf770-176a-4f58-929d-d4fafc5538c7',
    CIDADE: 'Umburanas',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.326496124267578,
    MAXIMO: 31.622657775878906,
    MEDIA: 31.45221201578776,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c706caf9-bb79-418d-a5c3-306179b30b70',
    CIDADE: 'Una',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.77210235595703,
    MAXIMO: 31.02716064453125,
    MEDIA: 30.863162994384766,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b3ac9e5-5e04-426c-b102-a59bcd5a2dd5',
    CIDADE: 'Urandi',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.131927490234375,
    MAXIMO: 30.81298828125,
    MEDIA: 30.472843170166016,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '00ccffb4-5421-43d3-9678-5b1014da682e',
    CIDADE: 'Uruçuca',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.01224899291992,
    MAXIMO: 32.652244567871094,
    MEDIA: 32.338330839887796,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8fbe1a8c-3ef7-4cc8-8221-f5de75101803',
    CIDADE: 'Utinga',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.135154724121094,
    MAXIMO: 32.59564208984375,
    MEDIA: 32.33148458612715,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28268705-eda4-4709-a0cc-d0395cba4f52',
    CIDADE: 'Valença',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.350826263427734,
    MAXIMO: 36.23310470581055,
    MEDIA: 35.69956111907959,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '469a9e06-eed3-4c03-bb22-b47b9936e370',
    CIDADE: 'Valente',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.709136962890625,
    MAXIMO: 33.520267486572266,
    MEDIA: 33.11696221468866,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b00a7e6c-53ac-40e6-bfc9-14f950c6ea0c',
    CIDADE: 'Várzea da Roça',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.704124450683594,
    MAXIMO: 32.93655014038086,
    MEDIA: 32.82033729553223,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a4b45109-91ce-44d6-ac7b-87f0f56dfe50',
    CIDADE: 'Várzea do Poço',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.4470329284668,
    MAXIMO: 32.77592468261719,
    MEDIA: 32.48755903569542,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '155c5241-13ae-4b53-a3b9-f647be5d206c',
    CIDADE: 'Várzea Nova',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.714160919189453,
    MAXIMO: 31.838483810424805,
    MEDIA: 31.782439708709717,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a904664-4833-41cf-9fec-a2f6a3252b45',
    CIDADE: 'Varzedo',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 35.256778717041016,
    MAXIMO: 35.69026565551758,
    MEDIA: 35.52682342455798,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4639e05-1728-4eaa-b286-6d7b88d66faa',
    CIDADE: 'Vera Cruz',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 37.08512496948242,
    MAXIMO: 38.59076690673828,
    MEDIA: 37.79875612033137,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aabfdb77-b5ab-4899-8da9-788b8e539e16',
    CIDADE: 'Vereda',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 29.4163761138916,
    MAXIMO: 29.470003128051758,
    MEDIA: 29.44318962097168,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4cc4d96e-910e-4920-baf6-77e6eb28d4a0',
    CIDADE: 'Vitória da Conquista',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 30.13791275024414,
    MAXIMO: 31.681589126586914,
    MEDIA: 30.775728745894,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dfb00d4d-777e-4493-8c98-b0aef3cdd8d5',
    CIDADE: 'Wagner',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 32.36756896972656,
    MAXIMO: 32.39753341674805,
    MEDIA: 32.382551193237305,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '412eb1fd-d55f-49d0-8124-a8452105fecc',
    CIDADE: 'Wanderley',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.194595336914062,
    MAXIMO: 31.74303436279297,
    MEDIA: 31.46482229232788,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06a2b848-20cc-41b1-b784-67654a5913be',
    CIDADE: 'Wenceslau Guimarães',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 34.15998458862305,
    MAXIMO: 34.44375991821289,
    MEDIA: 34.30187225341797,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71df4140-2d79-4008-ac1a-1ef85e968657',
    CIDADE: 'Xique-Xique',
    SIGLA: 'BA',
    ESTADO: 'Bahia',
    MINIMO: 31.748762130737305,
    MAXIMO: 32.0991325378418,
    MEDIA: 31.942156519208634,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74dc4f69-5f15-4add-9b79-7d87fe4813b8',
    CIDADE: 'Abadia dos Dourados',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.54752731323242,
    MAXIMO: 39.83658981323242,
    MEDIA: 39.10659917195638,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a852e563-8d7f-4434-aa2c-2a30a5fd3a6d',
    CIDADE: 'Abaeté',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.641990661621094,
    MAXIMO: 31.257976531982422,
    MEDIA: 30.9469788869222,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '892aa778-e539-4aff-9fc1-868e27e544a1',
    CIDADE: 'Abre Campo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.166019439697266,
    MAXIMO: 33.42061996459961,
    MEDIA: 32.03605140761195,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53f156f5-4e33-49fb-8220-a273709a220c',
    CIDADE: 'Acaiaca',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.329105377197266,
    MAXIMO: 32.11799240112305,
    MEDIA: 31.870397655057737,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30bc1190-a2f6-4cc8-a023-42880c7a438f',
    CIDADE: 'Açucena',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.384780883789062,
    MAXIMO: 29.483074188232422,
    MEDIA: 29.41533851623535,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '21c1d823-b707-46cd-9389-a611408bb0a9',
    CIDADE: 'Água Boa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.10407257080078,
    MAXIMO: 29.21530532836914,
    MEDIA: 29.148266315460205,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1de94a62-26b4-4ebf-91fb-75b19ad9506a',
    CIDADE: 'Água Comprida',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.03468322753906,
    MAXIMO: 37.67694091796875,
    MEDIA: 36.67239699304466,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df7c1968-c42e-4b49-9b10-86644dff512f',
    CIDADE: 'Aguanil',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.5101432800293,
    MAXIMO: 33.882225036621094,
    MEDIA: 33.584966638645824,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '093093e8-e8c6-4c7f-a6c8-1e3723cb0aad',
    CIDADE: 'Águas Formosas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.274385452270508,
    MAXIMO: 29.299741744995117,
    MEDIA: 29.29128138224284,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4bc69fc7-4f48-4121-b4b1-31f873ea12dd',
    CIDADE: 'Águas Vermelhas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.551727294921875,
    MAXIMO: 30.28081512451172,
    MEDIA: 29.807879638671874,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '738ee4b7-864f-476d-94c7-141eb501e5b2',
    CIDADE: 'Aimorés',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.187496185302734,
    MAXIMO: 31.067773818969727,
    MEDIA: 30.56801923116048,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '151c9f36-1afc-4929-b7d4-d3796cf9ba8f',
    CIDADE: 'Aiuruoca',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.09291458129883,
    MAXIMO: 36.26304244995117,
    MEDIA: 35.566602071126304,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b3dc0a5-958d-494b-8dc7-289ab4f877bc',
    CIDADE: 'Alagoa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.571678161621094,
    MAXIMO: 36.72703552246094,
    MEDIA: 35.99237713625999,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43a2337f-b25a-4098-b82e-98f055f8f245',
    CIDADE: 'Albertina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 41.53323745727539,
    MAXIMO: 43.0093994140625,
    MEDIA: 42.670723549717174,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '824d26d1-de0f-41dc-8a1d-90aee00ba325',
    CIDADE: 'Além Paraíba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.189701080322266,
    MAXIMO: 40.20524978637695,
    MEDIA: 40.19747543334961,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f3bdbc8-3f59-4baf-9fa0-2c68bc252bb7',
    CIDADE: 'Alfenas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.20809555053711,
    MAXIMO: 36.297035217285156,
    MEDIA: 35.726797103881836,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '68fc5c7c-622e-464a-9faa-0b15a3643001',
    CIDADE: 'Alfredo Vasconcelos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.266422271728516,
    MAXIMO: 34.795230865478516,
    MEDIA: 34.562863090203415,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5fdda0f-ac67-48c0-aa94-0f5461785894',
    CIDADE: 'Almenara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.53803825378418,
    MAXIMO: 29.775691986083984,
    MEDIA: 29.648615731133354,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ffb46ed6-ecc3-4f27-8983-36ad48b59036',
    CIDADE: 'Alpercata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.391618728637695,
    MAXIMO: 29.39353370666504,
    MEDIA: 29.39263811242055,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b0d2c8f-ed4f-4fa2-b274-35d657f38f3c',
    CIDADE: 'Alpinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.967803955078125,
    MAXIMO: 34.99362564086914,
    MEDIA: 34.479324002758155,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27b26d0e-0a89-449d-8e0e-edcb8b4bf95c',
    CIDADE: 'Alterosa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.77336883544922,
    MAXIMO: 36.14165115356445,
    MEDIA: 35.45290869318451,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f73e662e-84b6-45d4-a018-b7ca8e8b71d5',
    CIDADE: 'Alto Caparaó',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.62099838256836,
    MAXIMO: 36.62651062011719,
    MEDIA: 36.076490923853235,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f80394b-0e18-4209-a31a-6a8caa1a50bd',
    CIDADE: 'Alto Rio Doce',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.00690841674805,
    MAXIMO: 35.473880767822266,
    MEDIA: 34.64536715258177,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a93574a-9648-4c93-9189-ebc9227bb26a',
    CIDADE: 'Alvarenga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.622499465942383,
    MAXIMO: 29.80160140991211,
    MEDIA: 29.730666843060057,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7924f39c-99b9-4f42-8805-6910c050cc36',
    CIDADE: 'Alvinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.852371215820312,
    MAXIMO: 30.893329620361328,
    MEDIA: 30.87285041809082,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ee75762-906f-4742-9318-d19c6d47ad75',
    CIDADE: 'Alvorada de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.378232955932617,
    MAXIMO: 29.49622917175293,
    MEDIA: 29.4302023331012,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2cc518a2-1b44-4466-b81a-8cd523d8dc5c',
    CIDADE: 'Amparo do Serra',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.3278694152832,
    MAXIMO: 33.68172836303711,
    MEDIA: 32.82251804822904,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b15aa455-c3be-4ced-9d25-9b322c37f2e0',
    CIDADE: 'Andradas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.67311096191406,
    MAXIMO: 43.0093994140625,
    MEDIA: 41.579344666755944,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '650ce4e5-7177-414d-b205-202334074418',
    CIDADE: 'Cachoeira de Pajeú',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.507951736450195,
    MAXIMO: 29.5885009765625,
    MEDIA: 29.548226356506348,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39518563-8099-486e-9b72-8099f4e854c7',
    CIDADE: 'Andrelândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.33298873901367,
    MAXIMO: 36.34307098388672,
    MEDIA: 35.82255840301514,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45e35685-1049-4717-a5d1-7472296f58d2',
    CIDADE: 'Angelândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.095409393310547,
    MAXIMO: 29.10831069946289,
    MEDIA: 29.096205121159297,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dcb5f3bb-bd68-465b-a66c-3d8db615c7bc',
    CIDADE: 'Antônio Carlos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.01381301879883,
    MAXIMO: 36.84111404418945,
    MEDIA: 35.76347070260875,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0e8f2fb-0897-4ed2-8fba-627c3657a2f7',
    CIDADE: 'Antônio Dias',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.723064422607422,
    MAXIMO: 29.928325653076172,
    MEDIA: 29.825695037841797,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dd749be-8f3f-4691-8358-d613494a5394',
    CIDADE: 'Antônio Prado de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.4468879699707,
    MAXIMO: 40.56550979614258,
    MEDIA: 39.51660199658767,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a31003e5-68e0-4069-905d-c5f641b2d8bd',
    CIDADE: 'Araçaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.854257583618164,
    MAXIMO: 30.04709243774414,
    MEDIA: 29.975454134570054,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '324f82c4-1c4f-4c57-9ac4-14161e323d5c',
    CIDADE: 'Aracitaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.97340393066406,
    MAXIMO: 36.7841682434082,
    MEDIA: 36.254934307100825,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '523cdcf4-25fb-42ae-9fb1-50bfd2b14225',
    CIDADE: 'Araçuaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.121488571166992,
    MAXIMO: 29.230161666870117,
    MEDIA: 29.18741257985433,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5c0407d-0ee3-4d0f-9b60-3a3d12b7b216',
    CIDADE: 'Araguari',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 41.56216049194336,
    MAXIMO: 43.98603820800781,
    MEDIA: 42.77757093641493,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b0cab45b-8f2d-47dc-9fbe-0519fd70dbf8',
    CIDADE: 'Arantina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.34307098388672,
    MAXIMO: 36.97910690307617,
    MEDIA: 36.86088377651967,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd3a62a2-9046-4227-837f-75b1094bb522',
    CIDADE: 'Araponga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.18052673339844,
    MAXIMO: 36.18572998046875,
    MEDIA: 34.83079241445206,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c35c8af9-6fff-4a04-8b54-13b48f24aef6',
    CIDADE: 'Araporã',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.21601486206055,
    MAXIMO: 40.258304595947266,
    MEDIA: 39.11622164740131,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e976d610-245f-4cb5-991c-d00d033970e9',
    CIDADE: 'Arapuá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.195343017578125,
    MAXIMO: 32.84931182861328,
    MEDIA: 32.57208661809275,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a704a8df-f7d2-4926-b86f-4fd0b06ec33b',
    CIDADE: 'Araújos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.231002807617188,
    MAXIMO: 31.624282836914062,
    MEDIA: 31.509253328420836,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c941039e-97a1-48e7-8c46-b27d36b90ffd',
    CIDADE: 'Araxá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.05070114135742,
    MAXIMO: 36.19865798950195,
    MEDIA: 35.68612289428711,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b2fb227f-61f2-41d2-ab4c-6b43bfa14185',
    CIDADE: 'Arceburgo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.303279876708984,
    MAXIMO: 38.59757995605469,
    MEDIA: 37.96780920136567,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b866698b-a946-40ed-ab23-8d5bea3939b7',
    CIDADE: 'Arcos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.285423278808594,
    MAXIMO: 32.72466278076172,
    MEDIA: 32.463957604931636,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f70c3a18-5854-4367-a648-b5f5616ef281',
    CIDADE: 'Areado',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.49620819091797,
    MAXIMO: 36.700645446777344,
    MEDIA: 36.06288141851756,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eac84fb7-d061-475e-a9e1-b3cbc53acb0b',
    CIDADE: 'Argirita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.01167678833008,
    MAXIMO: 40.189701080322266,
    MEDIA: 39.33458992008392,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82dbc99f-1f84-4321-9874-2bc26e478e57',
    CIDADE: 'Aricanduva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.10407257080078,
    MAXIMO: 29.150278091430664,
    MEDIA: 29.115743467205714,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c28e5295-baf5-47d8-8213-84a81271318d',
    CIDADE: 'Arinos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.71577262878418,
    MAXIMO: 30.699003219604492,
    MEDIA: 30.045515284818762,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c3920b2-e57b-4597-aaea-f01126d00491',
    CIDADE: 'Astolfo Dutra',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.459503173828125,
    MAXIMO: 38.85106658935547,
    MEDIA: 37.580879735517264,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ece60ffa-d674-49a3-8764-99070e362a5c',
    CIDADE: 'Ataléia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.221778869628906,
    MAXIMO: 29.239103317260742,
    MEDIA: 29.233369827270508,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a8247bf-a42b-4ab4-9fe4-907f1b1e7366',
    CIDADE: 'Augusto de Lima',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.413293838500977,
    MAXIMO: 29.563310623168945,
    MEDIA: 29.482405026753742,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dccbdbf5-9ba8-4fe3-973f-50a8afde313c',
    CIDADE: 'Baependi',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.05034637451172,
    MAXIMO: 35.571678161621094,
    MEDIA: 35.22488911946615,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82eb2a50-7ded-46dc-a02d-fb8ae4b4f51a',
    CIDADE: 'Baldim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.603363037109375,
    MAXIMO: 30.02306365966797,
    MEDIA: 29.827098362521447,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7392183b-5a21-4525-9799-2886e0dc3173',
    CIDADE: 'Bambuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.623565673828125,
    MAXIMO: 33.3472785949707,
    MEDIA: 32.970370610555015,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '049e5c21-9abd-47e1-919d-1ab72ced1801',
    CIDADE: 'Bandeira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.80408477783203,
    MAXIMO: 29.899578094482422,
    MEDIA: 29.852216084798176,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1725d7b0-25dd-41fa-93f1-d44af529660f',
    CIDADE: 'Bandeira do Sul',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.12675476074219,
    MAXIMO: 39.6886100769043,
    MEDIA: 39.11983980464511,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45e320b9-9c6f-4772-b3d7-1bebdcffedcb',
    CIDADE: 'Barão de Cocais',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.168838500976562,
    MAXIMO: 30.503293991088867,
    MEDIA: 30.336066246032715,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70bd9c2a-b2bc-45d5-a663-ac86dba4a5bb',
    CIDADE: 'Barão de Monte Alto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.5863151550293,
    MAXIMO: 40.46804428100586,
    MEDIA: 40.410812622684475,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0fdd093a-c996-4a07-a54d-f0f6bb4c6882',
    CIDADE: 'Barbacena',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.01381301879883,
    MAXIMO: 35.303932189941406,
    MEDIA: 35.15887260437012,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9bea846-87c2-4f3e-8084-8c9deae93c7d',
    CIDADE: 'Barra Longa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.852371215820312,
    MAXIMO: 32.11799240112305,
    MEDIA: 31.3367356346506,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a8c8908-b4c4-492e-a19c-5f02aae14c40',
    CIDADE: 'Barroso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.027793884277344,
    MAXIMO: 35.01381301879883,
    MEDIA: 34.33038289355894,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b9e6aeb-a227-4b4b-8fec-da9bd9d2dbe0',
    CIDADE: 'Bela Vista de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.93684196472168,
    MAXIMO: 30.178590774536133,
    MEDIA: 30.17093904819951,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7a1ebc5-3aa7-46a1-bcf6-7cc961657254',
    CIDADE: 'Belmiro Braga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.35067367553711,
    MAXIMO: 39.614723205566406,
    MEDIA: 39.06566060193446,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c214b031-805d-4f10-8eb8-9e96cb98ad37',
    CIDADE: 'Belo Horizonte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.381145477294922,
    MAXIMO: 30.701614379882812,
    MEDIA: 30.53885332125817,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '801e2ae7-d3be-4a00-b466-35b60a9866bd',
    CIDADE: 'Belo Oriente',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.483074188232422,
    MAXIMO: 29.62070655822754,
    MEDIA: 29.559010183832555,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f73df8a0-7d6e-4bcb-90fa-facdd37d3476',
    CIDADE: 'Belo Vale',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.346271514892578,
    MAXIMO: 31.80696678161621,
    MEDIA: 31.668071083527934,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8c172e8-a51a-40ba-b351-a473bb9817c8',
    CIDADE: 'Berilo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.09526252746582,
    MAXIMO: 29.122636795043945,
    MEDIA: 29.108949661254883,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'be989091-dc1c-4d03-b489-64aa28cc743e',
    CIDADE: 'Bertópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.304059982299805,
    MAXIMO: 29.46090316772461,
    MEDIA: 29.36074753077771,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c509bc1e-8de6-4f97-b95c-13480deee9d1',
    CIDADE: 'Berizal',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.55304718017578,
    MAXIMO: 30.28081512451172,
    MEDIA: 29.700689789551472,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f17d908f-babf-45cc-b307-21af646136f1',
    CIDADE: 'Betim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.56388282775879,
    MAXIMO: 30.903505325317383,
    MEDIA: 30.73782351712481,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c580ac75-c954-4c1c-8372-fa883f77f2c2',
    CIDADE: 'Bias Fortes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.05275344848633,
    MAXIMO: 37.57796096801758,
    MEDIA: 36.79921151233368,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4285213f-adab-4d8b-9a62-93ea94815bbc',
    CIDADE: 'Bicas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.4815673828125,
    MAXIMO: 39.2028694152832,
    MEDIA: 39.06948555419478,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a7065a9-a748-4e2a-abea-1586279ddfa3',
    CIDADE: 'Biquinhas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.628915786743164,
    MAXIMO: 31.274227142333984,
    MEDIA: 30.981234055506935,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a87d44b-a590-4163-966f-bb6a8f216fa3',
    CIDADE: 'Boa Esperança',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.66691589355469,
    MAXIMO: 34.091331481933594,
    MEDIA: 33.87912368774414,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d64024f-7984-45e2-bc1f-a8c5e3d05e1e',
    CIDADE: 'Bocaina de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.72703552246094,
    MAXIMO: 37.56478500366211,
    MEDIA: 37.14591026306152,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e91abd5d-d01f-4e2d-baa0-38ca6a00f681',
    CIDADE: 'Bocaiúva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.141006469726562,
    MAXIMO: 29.265769958496094,
    MEDIA: 29.205871061845258,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '596233bd-3da1-4b13-84a4-841491a4c4a2',
    CIDADE: 'Bom Despacho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.278152465820312,
    MAXIMO: 31.51358413696289,
    MEDIA: 31.414117177327473,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47c3e41d-2945-42ea-aa28-9b4a1605886a',
    CIDADE: 'Bom Jardim de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.97910690307617,
    MAXIMO: 37.47632598876953,
    MEDIA: 37.22771644592285,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0e1cf0b-5db4-46bf-a75e-0276cdea9f08',
    CIDADE: 'Bom Jesus da Penha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.623191833496094,
    MAXIMO: 36.069400787353516,
    MEDIA: 35.19645865748531,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1c636ab-f713-4af8-8e35-4e6d5c21c1d4',
    CIDADE: 'Bom Jesus do Amparo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.94426727294922,
    MAXIMO: 30.22337532043457,
    MEDIA: 30.04707080271182,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb21972b-ad58-45bb-83e1-35b6aa80157e',
    CIDADE: 'Bom Jesus do Galho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.97899627685547,
    MAXIMO: 30.404489517211914,
    MEDIA: 30.145994186401367,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4082b29-d65e-4958-8eff-62a72656d860',
    CIDADE: 'Bom Repouso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.949378967285156,
    MAXIMO: 41.836273193359375,
    MEDIA: 40.85510201392835,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8493e254-adb0-4874-aa10-b8499bf9aabc',
    CIDADE: 'Bom Sucesso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.15473556518555,
    MAXIMO: 33.58332061767578,
    MEDIA: 33.4364267985026,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f373947b-e6bc-4fdd-a281-ba2caa92fdee',
    CIDADE: 'Bonfim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.06976318359375,
    MAXIMO: 31.871835708618164,
    MEDIA: 31.440093231936594,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58db1da0-70cb-45e6-a479-7984c284ad47',
    CIDADE: 'Bonfinópolis de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.552291870117188,
    MAXIMO: 31.804546356201172,
    MEDIA: 31.087072372436523,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c811d19-4a4a-4469-8db3-57c4e12f44f4',
    CIDADE: 'Bonito de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.45180892944336,
    MAXIMO: 29.696767807006836,
    MEDIA: 29.632240295410156,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89aa8fe0-3897-4a5f-aa22-ba2bdfe6f0b4',
    CIDADE: 'Borda da Mata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.683326721191406,
    MAXIMO: 41.38753890991211,
    MEDIA: 40.33314565242982,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e05ef238-ccd9-4ac4-bdc0-164786f600e4',
    CIDADE: 'Botelhos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.12675476074219,
    MAXIMO: 38.633941650390625,
    MEDIA: 38.380348205566406,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e423a532-0192-4a90-9b4e-742259cd7aa2',
    CIDADE: 'Botumirim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.119497299194336,
    MAXIMO: 29.161760330200195,
    MEDIA: 29.136850992838543,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4cc86f53-2f51-4698-8eef-cd9005f5a4a4',
    CIDADE: 'Brasilândia de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.319320678710938,
    MAXIMO: 31.257226943969727,
    MEDIA: 30.723631143569946,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee436ee5-0d87-469d-a072-0c9a5e56d01c',
    CIDADE: 'Brasília de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.3797550201416,
    MAXIMO: 29.4549503326416,
    MEDIA: 29.407267252604168,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8afcd10-7d82-48a6-b62d-ed8901ee542f',
    CIDADE: 'Brás Pires',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.62935256958008,
    MAXIMO: 34.955345153808594,
    MEDIA: 34.071075220061495,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '306b6977-bd6d-4d0a-a285-d2081b6d5015',
    CIDADE: 'Braúnas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.369102478027344,
    MAXIMO: 29.487390518188477,
    MEDIA: 29.43071608441814,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9be7a80a-6b8f-43c8-b493-05d220f39b2a',
    CIDADE: 'Brazópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.281673431396484,
    MAXIMO: 38.152793884277344,
    MEDIA: 37.852875222008656,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a163a72-ab67-4ff6-a2fc-aeb91cc54fd1',
    CIDADE: 'Brumadinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.001157760620117,
    MAXIMO: 31.346271514892578,
    MEDIA: 31.13906415303548,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'caee39b4-e987-44d4-9af1-cbfe7481a600',
    CIDADE: 'Bueno Brandão',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.85018539428711,
    MAXIMO: 42.82511520385742,
    MEDIA: 41.82498759223336,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '38bb393d-1287-4c20-89f8-ba35d3fff507',
    CIDADE: 'Buenópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.279611587524414,
    MAXIMO: 29.411691665649414,
    MEDIA: 29.350099563598633,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d376db6-c87a-424d-920d-f9c4fd9c9629',
    CIDADE: 'Bugre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.610382080078125,
    MAXIMO: 29.803558349609375,
    MEDIA: 29.639566887967444,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93f81c64-c13c-48b9-9997-e445edda2dab',
    CIDADE: 'Buritis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.79487419128418,
    MAXIMO: 31.39651870727539,
    MEDIA: 30.411352045395795,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4d69b61-56ae-433d-a536-c0523909974e',
    CIDADE: 'Buritizeiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.62062644958496,
    MAXIMO: 30.226688385009766,
    MEDIA: 29.88489059448242,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b23a92fe-8658-42f7-9699-12c188dfd5f8',
    CIDADE: 'Cabeceira Grande',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.62404251098633,
    MAXIMO: 35.2760124206543,
    MEDIA: 34.95002746582031,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '831c00af-1699-4f7c-ab9c-fd42307b8c92',
    CIDADE: 'Cabo Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.700645446777344,
    MAXIMO: 38.633941650390625,
    MEDIA: 37.46537822636195,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b704fcd-32d2-4941-b33d-a7c2133af006',
    CIDADE: 'Cachoeira da Prata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.306039810180664,
    MAXIMO: 30.4060115814209,
    MEDIA: 30.36384704705686,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '04995c71-c752-46c6-9818-f38fc9794508',
    CIDADE: 'Cachoeira de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.89725112915039,
    MAXIMO: 39.00172805786133,
    MEDIA: 38.53867741702069,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da5815af-4843-4f29-93c5-618a1fa8b4bf',
    CIDADE: 'Cachoeira Dourada',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.333194732666016,
    MAXIMO: 37.325435638427734,
    MEDIA: 37.07511031893945,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b0806ef-dd47-43e6-8a9b-b57076c37502',
    CIDADE: 'Caetanópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.04709243774414,
    MAXIMO: 30.306039810180664,
    MEDIA: 30.160288349122776,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '778a1608-583c-4329-a4fe-321f44df861b',
    CIDADE: 'Caeté',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.22337532043457,
    MAXIMO: 30.559635162353516,
    MEDIA: 30.391505241394043,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd14b4876-51f0-456d-9000-a7f34d21785e',
    CIDADE: 'Caiana',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.54000473022461,
    MAXIMO: 40.44245147705078,
    MEDIA: 38.49263830610194,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48695c96-4864-4ea6-a331-e203d7ca9893',
    CIDADE: 'Cajuri',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.83203887939453,
    MAXIMO: 34.83203887939453,
    MEDIA: 34.83203887939453,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e50aabd3-f897-4583-80fe-91c650820ff7',
    CIDADE: 'Caldas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.026668548583984,
    MAXIMO: 40.67311096191406,
    MEDIA: 40.34988975524902,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a4b22ef-e0ed-429b-bb60-a51dc4bf9333',
    CIDADE: 'Camacho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.562015533447266,
    MAXIMO: 33.00895309448242,
    MEDIA: 32.63639479285056,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11b13504-2250-41af-9a3f-1d7638104446',
    CIDADE: 'Camanducaia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.92103576660156,
    MAXIMO: 40.749263763427734,
    MEDIA: 40.33514976501465,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6157304d-66b9-4ef2-9a56-aa9a5065768e',
    CIDADE: 'Cambuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.92103576660156,
    MAXIMO: 40.93825912475586,
    MEDIA: 40.378717740277736,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f0b4ab0-60ed-4452-8b65-7854988ca2e2',
    CIDADE: 'Cambuquira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.22475051879883,
    MAXIMO: 35.94318389892578,
    MEDIA: 35.513337267644765,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14f3d076-b1c3-4c12-9ee0-f7b54a82315b',
    CIDADE: 'Campanário',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.148740768432617,
    MAXIMO: 29.208486557006836,
    MEDIA: 29.176879726780353,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5824353-c32b-47e9-a46f-190f94fefa6d',
    CIDADE: 'Campanha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.626224517822266,
    MAXIMO: 35.94318389892578,
    MEDIA: 35.78470420837402,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ef27c60-ebca-4842-af45-359916fc1346',
    CIDADE: 'Campestre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.61656188964844,
    MAXIMO: 39.108768463134766,
    MEDIA: 38.41163126627604,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a2693f4-0f27-45d6-a627-731e9038488b',
    CIDADE: 'Campina Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.8193473815918,
    MAXIMO: 35.410743713378906,
    MEDIA: 35.0306781133016,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7dd0ba4a-ccbe-4801-a17a-0a323b13275b',
    CIDADE: 'Campo Azul',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.487558364868164,
    MAXIMO: 29.562286376953125,
    MEDIA: 29.519489288330078,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98f5965f-87ef-4c4f-865b-c3befa9639ae',
    CIDADE: 'Campo Belo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.89241409301758,
    MAXIMO: 33.882225036621094,
    MEDIA: 33.31110983880376,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3aa46fe-13b4-41ee-8903-a7a649e1d323',
    CIDADE: 'Campo do Meio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.3428840637207,
    MAXIMO: 34.572750091552734,
    MEDIA: 34.45781707763672,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb8d3c3e-f807-4503-a2dd-46b82f2ac33c',
    CIDADE: 'Campo Florido',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.73258972167969,
    MAXIMO: 37.62419891357422,
    MEDIA: 36.67014122009277,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81d114e9-85a6-4b1b-99a1-e4b41ebe2e17',
    CIDADE: 'Campos Altos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.0607795715332,
    MAXIMO: 33.11668395996094,
    MEDIA: 33.08873176574707,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '296ca65f-5c3d-4f9d-b773-0b4a7d7c1b1a',
    CIDADE: 'Campos Gerais',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.091331481933594,
    MAXIMO: 35.90584945678711,
    MEDIA: 34.93378651643902,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0846aaac-f302-4547-871a-153b61e04350',
    CIDADE: 'Canaã',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.68172836303711,
    MAXIMO: 35.43781661987305,
    MEDIA: 34.33937495161776,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0c751b78-a765-421d-8b08-13bfb3defa27',
    CIDADE: 'Canápolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.69297790527344,
    MAXIMO: 38.05026626586914,
    MEDIA: 37.87162208557129,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfa4d29b-9c8c-4a6d-8903-2b388b9aa998',
    CIDADE: 'Cana Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.24784469604492,
    MAXIMO: 33.882225036621094,
    MEDIA: 33.49668219087891,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5dabbfa1-7eda-4b07-b41c-eb0b15042b93',
    CIDADE: 'Candeias',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.00895309448242,
    MAXIMO: 33.15585708618164,
    MEDIA: 33.08240509033203,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5186d9e1-df36-4179-add3-ff642454eb1f',
    CIDADE: 'Cantagalo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.178882598876953,
    MAXIMO: 29.29726219177246,
    MEDIA: 29.213046660086107,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b97d44be-a2ec-4718-a951-258e1c9637cc',
    CIDADE: 'Caparaó',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.7639045715332,
    MAXIMO: 37.54000473022461,
    MEDIA: 35.811774711387585,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '99e65558-c5d9-476a-b0b4-0e983196e9fb',
    CIDADE: 'Capela Nova',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.17351150512695,
    MAXIMO: 34.00690841674805,
    MEDIA: 33.9048381585342,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe7f17b2-d1fb-4f3f-a528-a8b9694a31db',
    CIDADE: 'Capelinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.10831069946289,
    MAXIMO: 29.11701202392578,
    MEDIA: 29.112661361694336,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '603fb6b9-b6c2-4afc-b0d2-def451744f1b',
    CIDADE: 'Capetinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.657981872558594,
    MAXIMO: 35.267860412597656,
    MEDIA: 35.056981449001555,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9627f94c-5ea5-4f5a-a488-9668f7c530e7',
    CIDADE: 'Capim Branco',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.100387573242188,
    MAXIMO: 30.36792755126953,
    MEDIA: 30.24734526736655,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc2eb436-977d-486a-a713-8a00e46f7bca',
    CIDADE: 'Capinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.492088317871094,
    MAXIMO: 37.10749053955078,
    MEDIA: 36.438130075338776,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1df52054-295e-4af2-b467-b9b1d3a8cc40',
    CIDADE: 'Capitão Andrade',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.391618728637695,
    MAXIMO: 29.518491744995117,
    MEDIA: 29.415066422689446,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b3b34a7-e76a-47bf-88ab-67a8a88b3970',
    CIDADE: 'Capitão Enéas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.27328872680664,
    MAXIMO: 29.302677154541016,
    MEDIA: 29.28450584411621,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aefea241-a188-434f-ad47-d6cbe277b77b',
    CIDADE: 'Capitólio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.553985595703125,
    MAXIMO: 33.768314361572266,
    MEDIA: 33.661149978637695,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18d7e899-96b1-4a5d-9732-049f83417a63',
    CIDADE: 'Caputira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.58228874206543,
    MAXIMO: 32.6292724609375,
    MEDIA: 31.743684972312437,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19ac64c0-e97e-4210-8b78-efcf8166bb76',
    CIDADE: 'Caraí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.145694732666016,
    MAXIMO: 29.200725555419922,
    MEDIA: 29.17061185836792,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0dd41eaf-538d-4bd3-95b0-cd43aebeb3e0',
    CIDADE: 'Caranaíba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.99774932861328,
    MAXIMO: 34.00690841674805,
    MEDIA: 33.49925306786872,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d268f35-16ec-422b-9549-51bffccdadf6',
    CIDADE: 'Carandaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.5413703918457,
    MAXIMO: 33.7545051574707,
    MEDIA: 33.6479377746582,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbbd8711-0f48-4108-aad8-046b72fae6af',
    CIDADE: 'Carangola',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.567691802978516,
    MAXIMO: 39.207908630371094,
    MEDIA: 37.236225597240605,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '653eb6ff-1071-49be-b1f1-b2fe3d289edd',
    CIDADE: 'Caratinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.7691593170166,
    MAXIMO: 30.547222137451172,
    MEDIA: 30.269140243530273,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1632ac4d-19d2-4f3b-8dcd-cfee2cd42dc5',
    CIDADE: 'Carbonita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.091732025146484,
    MAXIMO: 29.113367080688477,
    MEDIA: 29.103153705596924,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c6c5b71-ec14-4785-be9d-bba2b96e9a1b',
    CIDADE: 'Careaçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.84628677368164,
    MAXIMO: 37.57450485229492,
    MEDIA: 37.49141503199576,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f0720b4-5255-4a48-9557-4d1df9407b9c',
    CIDADE: 'Carlos Chagas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.167097091674805,
    MAXIMO: 29.25261878967285,
    MEDIA: 29.21023292541504,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cc277a62-abc4-4bdd-87b5-14568b10809f',
    CIDADE: 'Carmésia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.445663452148438,
    MAXIMO: 29.539226531982422,
    MEDIA: 29.48691473453946,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0478da71-c770-4980-98c1-753ef2cdc3df',
    CIDADE: 'Carmo da Cachoeira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.032264709472656,
    MAXIMO: 35.25164794921875,
    MEDIA: 34.562552470885606,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b20b8b6f-457b-4638-90ac-06936ecb0413',
    CIDADE: 'Carmo da Mata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.00384521484375,
    MAXIMO: 32.562015533447266,
    MEDIA: 32.31865936417634,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bf466665-cca4-4135-a86b-26fc8254503b',
    CIDADE: 'Carmo de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.12205505371094,
    MAXIMO: 35.86198043823242,
    MEDIA: 35.5372778128292,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e32d1a1-46cd-4364-9426-a760ff4bf1aa',
    CIDADE: 'Carmo do Cajuru',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.401508331298828,
    MAXIMO: 31.683624267578125,
    MEDIA: 31.542566299438477,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '373b0f4e-8c77-43fd-a1da-a6b6ad13d082',
    CIDADE: 'Carmo do Paranaíba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.05437469482422,
    MAXIMO: 33.32847595214844,
    MEDIA: 32.68838024139404,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ddab4774-d542-4509-8c5c-8a43d25447db',
    CIDADE: 'Carmo do Rio Claro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.196590423583984,
    MAXIMO: 34.77336883544922,
    MEDIA: 34.492051124572754,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '085ad322-4499-425e-b4d2-0a15af475c4c',
    CIDADE: 'Carmópolis de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.912281036376953,
    MAXIMO: 32.34408950805664,
    MEDIA: 32.16792692033703,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbbfb970-bd12-46ff-9116-83019e16366a',
    CIDADE: 'Carneirinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.493370056152344,
    MAXIMO: 37.46046447753906,
    MEDIA: 36.88112422398159,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19ef5f50-4e2c-4d26-8436-fc1c160d9e0d',
    CIDADE: 'Carrancas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.411956787109375,
    MAXIMO: 34.78030776977539,
    MEDIA: 34.581799825032554,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba23f154-8a88-4e46-8372-5b151e982e06',
    CIDADE: 'Carvalhópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.66914749145508,
    MAXIMO: 37.27698516845703,
    MEDIA: 37.26510758315337,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f822e721-bc77-4c32-898b-343d7a1c653a',
    CIDADE: 'Carvalhos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.853511810302734,
    MAXIMO: 36.97467803955078,
    MEDIA: 36.25413935758533,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9c1c0c7-6a0e-4d2e-9c7f-bb698e7b127b',
    CIDADE: 'Casa Grande',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.819236755371094,
    MAXIMO: 33.5413703918457,
    MEDIA: 33.0070683357206,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89444899-0345-4cb7-b155-4614f18a8c0d',
    CIDADE: 'Cascalho Rico',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 41.171539306640625,
    MAXIMO: 42.5991096496582,
    MEDIA: 41.66618328513546,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e2e1cdef-84ad-47db-b342-3135b08891ac',
    CIDADE: 'Cássia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.809295654296875,
    MAXIMO: 34.94481658935547,
    MEDIA: 34.87705612182617,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd968415d-ca3e-47ed-83d8-0f0077839886',
    CIDADE: 'Conceição da Barra de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.215003967285156,
    MAXIMO: 34.261966705322266,
    MEDIA: 33.729970643866984,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '256c9510-19b8-4681-b4a6-501f2b5ca234',
    CIDADE: 'Cataguases',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.05137634277344,
    MAXIMO: 39.64208984375,
    MEDIA: 38.724353431354174,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea5e0110-dbff-45a1-a2a2-c4ead820472c',
    CIDADE: 'Catas Altas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.469877243041992,
    MAXIMO: 30.852371215820312,
    MEDIA: 30.69665558566146,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7daf146-1d39-4868-a109-9a805a51f72c',
    CIDADE: 'Catas Altas da Noruega',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.419029235839844,
    MAXIMO: 33.38236999511719,
    MEDIA: 32.55938553849989,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ffbd224-efd6-4b9e-8dd8-2a109dd970ec',
    CIDADE: 'Catuji',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.115413665771484,
    MAXIMO: 29.200725555419922,
    MEDIA: 29.15749775263368,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7faa622-db48-440b-be18-f18d8c624489',
    CIDADE: 'Catuti',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.4295654296875,
    MAXIMO: 29.78058433532715,
    MEDIA: 29.587117013500226,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '950f2c68-b96a-4c09-b1db-b093b67aa4e3',
    CIDADE: 'Caxambu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.05034637451172,
    MAXIMO: 35.20684814453125,
    MEDIA: 35.07139262838612,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a588bfed-9d4a-4bca-8f35-f212e1f125ea',
    CIDADE: 'Cedro do Abaeté',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.415538787841797,
    MAXIMO: 31.560138702392578,
    MEDIA: 31.487838745117188,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0fea30d7-1961-4150-ac29-7040fdfb5eac',
    CIDADE: 'Central de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.298675537109375,
    MAXIMO: 29.393728256225586,
    MEDIA: 29.357732732555416,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82da9846-531b-43f8-bd35-8a208dac1597',
    CIDADE: 'Centralina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.05026626586914,
    MAXIMO: 39.21393585205078,
    MEDIA: 38.808239274671095,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b53c1d88-caa6-4440-b5ae-758fda21a518',
    CIDADE: 'Chácara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.02883529663086,
    MAXIMO: 39.2028694152832,
    MEDIA: 38.35478012381653,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c44a522-c713-4240-86fe-d93efa33383e',
    CIDADE: 'Chalé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.32077980041504,
    MAXIMO: 33.0161018371582,
    MEDIA: 31.732932535476674,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0859072-d015-4f92-95c3-b4588cf719a8',
    CIDADE: 'Chapada do Norte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.09464454650879,
    MAXIMO: 29.166580200195312,
    MEDIA: 29.13061237335205,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '217661d6-7e44-4609-927c-b2a3f849d33d',
    CIDADE: 'Chapada Gaúcha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.61823272705078,
    MAXIMO: 29.720361709594727,
    MEDIA: 29.649856914173473,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee2ec7f3-3955-4714-988e-2f67b5aa9abc',
    CIDADE: 'Chiador',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.662174224853516,
    MAXIMO: 40.65816879272461,
    MEDIA: 40.093642037165175,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc46f5da-01f1-4eca-b515-fa76f0d97427',
    CIDADE: 'Cipotânea',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.38236999511719,
    MAXIMO: 34.566436767578125,
    MEDIA: 34.2675061156898,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8700cea1-8a3c-4b1c-9c95-8394c53e8b68',
    CIDADE: 'Claraval',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.059364318847656,
    MAXIMO: 35.287986755371094,
    MEDIA: 35.15905082758624,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '728a4c17-594d-4482-93ac-b75d5c54c8fc',
    CIDADE: 'Claro dos Poções',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.324417114257812,
    MAXIMO: 29.354965209960938,
    MEDIA: 29.335660298665363,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7ade566-245d-43e9-a569-359c309db8c2',
    CIDADE: 'Cláudio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.825565338134766,
    MAXIMO: 32.1221809387207,
    MEDIA: 31.983863830566406,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd88920ba-124d-40fb-9738-5c9d509af00c',
    CIDADE: 'Coimbra',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.335548400878906,
    MAXIMO: 35.98847579956055,
    MEDIA: 34.73987829123373,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '00b72153-b02b-40db-b872-b5ecec9db769',
    CIDADE: 'Coluna',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.17325210571289,
    MAXIMO: 29.230892181396484,
    MEDIA: 29.206178450277758,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22201bdd-b6b3-4631-ab6c-961e0730525a',
    CIDADE: 'Comendador Gomes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.16795349121094,
    MAXIMO: 36.181922912597656,
    MEDIA: 35.6565907796224,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a88bd3b7-9118-4a4b-9711-c4b840ee4f61',
    CIDADE: 'Comercinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.35717010498047,
    MAXIMO: 29.41961669921875,
    MEDIA: 29.38839340209961,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9f8aba4-ebe1-41d1-ac6b-8e6f420856c9',
    CIDADE: 'Conceição da Aparecida',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.37505340576172,
    MAXIMO: 35.22565460205078,
    MEDIA: 34.94994382468863,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd344c5dc-91b5-4a5f-9325-bf58616ed599',
    CIDADE: 'Conceição das Pedras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.192020416259766,
    MAXIMO: 37.10893249511719,
    MEDIA: 36.44489275053703,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8967c2dc-054f-491f-8c2c-d5ab6532f277',
    CIDADE: 'Conceição das Alagoas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.262550354003906,
    MAXIMO: 37.540992736816406,
    MEDIA: 36.8639404296875,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '80637a75-8094-49e2-adcd-9bddba561c57',
    CIDADE: 'Conceição de Ipanema',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.630661010742188,
    MAXIMO: 31.32077980041504,
    MEDIA: 30.975720405578613,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a80756e-c564-4f91-9fe0-40befd6e5d3f',
    CIDADE: 'Conceição do Mato Dentro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.46090316772461,
    MAXIMO: 29.539226531982422,
    MEDIA: 29.50510025024414,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11034227-3e43-4a47-82a5-f32919c25791',
    CIDADE: 'Conceição do Pará',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.86182975769043,
    MAXIMO: 31.231002807617188,
    MEDIA: 31.018364206696113,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ce81f592-5364-43b0-be76-b80964fda721',
    CIDADE: 'Conceição do Rio Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.12205505371094,
    MAXIMO: 35.45344543457031,
    MEDIA: 35.287750244140625,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f6be02e-163a-4147-abf0-b614cb8a6fb3',
    CIDADE: 'Conceição dos Ouros',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.09806442260742,
    MAXIMO: 39.00172805786133,
    MEDIA: 38.57104585753606,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bb93e93-24dd-4053-8636-243852d95907',
    CIDADE: 'Cônego Marinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.688739776611328,
    MAXIMO: 29.778499603271484,
    MEDIA: 29.723656972249348,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c188237e-1fb4-47d5-be61-16c7928c47cd',
    CIDADE: 'Confins',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.188066482543945,
    MAXIMO: 30.25873565673828,
    MEDIA: 30.21981013192119,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2da975cb-71db-4e35-b5fa-b9cb6970ad69',
    CIDADE: 'Congonhal',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.25932693481445,
    MAXIMO: 40.57164001464844,
    MEDIA: 39.38257061432903,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e85d1ad7-60f5-454d-b203-6ea7b81e6266',
    CIDADE: 'Congonhas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.746442794799805,
    MAXIMO: 32.33933639526367,
    MEDIA: 31.869349775522863,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c3b6172-74d4-49c3-b019-5d1dc9633760',
    CIDADE: 'Congonhas do Norte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.56464195251465,
    MAXIMO: 29.603363037109375,
    MEDIA: 29.58400249481201,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7343b80d-f576-4b28-b097-7dcc63dc6df9',
    CIDADE: 'Conquista',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.41748046875,
    MAXIMO: 37.01812744140625,
    MEDIA: 36.717803955078125,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c33acec0-2852-4e5a-972e-5f095e6d607b',
    CIDADE: 'Conselheiro Lafaiete',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.29513168334961,
    MAXIMO: 32.33933639526367,
    MEDIA: 32.31723403930664,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41e3818b-bc3a-4bc2-8e20-454831d077fb',
    CIDADE: 'Conselheiro Pena',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.488353729248047,
    MAXIMO: 29.707475662231445,
    MEDIA: 29.58381233215332,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d723834-006e-43b1-939a-0ab2a088b3f8',
    CIDADE: 'Consolação',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.00172805786133,
    MAXIMO: 40.07771301269531,
    MEDIA: 39.259081541368126,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92108619-83c0-4578-b9e6-a0f7302d7471',
    CIDADE: 'Contagem',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.472272872924805,
    MAXIMO: 30.795007705688477,
    MEDIA: 30.56726356311951,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06ab7775-fb3d-49f6-9902-4d54e0a37556',
    CIDADE: 'Coqueiral',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.882225036621094,
    MAXIMO: 34.609134674072266,
    MEDIA: 34.045295391904006,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27de1cdd-5d6b-4d48-a14d-9c05dec350d9',
    CIDADE: 'Coração de Jesus',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.308433532714844,
    MAXIMO: 29.43328094482422,
    MEDIA: 29.376508440290177,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '951ebd12-86b2-4623-bf1e-8fe1871e3d64',
    CIDADE: 'Cordisburgo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.843399047851562,
    MAXIMO: 30.032203674316406,
    MEDIA: 29.937801361083984,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9fdf2887-375f-43b1-92c7-8622bd9a19d3',
    CIDADE: 'Cordislândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.124420166015625,
    MAXIMO: 37.16448211669922,
    MEDIA: 36.59035868650236,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a520099-31c7-48aa-b665-94f4b345bac8',
    CIDADE: 'Corinto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.59726333618164,
    MAXIMO: 29.876178741455078,
    MEDIA: 29.723597764968872,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8aff9ceb-ea58-482a-8305-053f288ac686',
    CIDADE: 'Coroaci',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.17667579650879,
    MAXIMO: 29.290395736694336,
    MEDIA: 29.23191330766413,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d8521fd-1aa6-458e-8b46-49a1fe28d458',
    CIDADE: 'Coromandel',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.022220611572266,
    MAXIMO: 38.31678009033203,
    MEDIA: 37.1034631729126,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '227863ea-ef61-4350-ae31-ccdfc16979f4',
    CIDADE: 'Coronel Fabriciano',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.58746337890625,
    MAXIMO: 29.72550392150879,
    MEDIA: 29.695724720372407,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '99c7375d-bc6d-4eb6-8df8-0831d82d7741',
    CIDADE: 'Coronel Murta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.161945343017578,
    MAXIMO: 29.217987060546875,
    MEDIA: 29.194822311401367,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4776dd75-5681-411c-be7b-87caeb410e46',
    CIDADE: 'Coronel Pacheco',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.577484130859375,
    MAXIMO: 38.02883529663086,
    MEDIA: 37.934577597749055,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '91005220-0416-419e-bc93-169abe45d046',
    CIDADE: 'Coronel Xavier Chaves',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.31322479248047,
    MAXIMO: 34.027793884277344,
    MEDIA: 33.49630352097811,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2efc4a19-9602-4f74-bc0d-d79d617bd2bd',
    CIDADE: 'Córrego Danta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.48486328125,
    MAXIMO: 32.75510787963867,
    MEDIA: 32.647029876708984,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82cb914e-e9be-43cf-82ab-663c813ce14d',
    CIDADE: 'Córrego do Bom Jesus',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.0984992980957,
    MAXIMO: 40.07771301269531,
    MEDIA: 40.05292576289873,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82255de1-e461-4be5-a429-ab86779160da',
    CIDADE: 'Córrego Fundo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.48161315917969,
    MAXIMO: 32.718971252441406,
    MEDIA: 32.716211815880605,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3209b963-2336-4e4b-a403-762606344ccd',
    CIDADE: 'Córrego Novo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.187837600708008,
    MAXIMO: 30.738121032714844,
    MEDIA: 30.29297884543634,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7fea711-f3b9-439b-ade5-bdc8f8ae9d47',
    CIDADE: 'Couto de Magalhães de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.18190574645996,
    MAXIMO: 29.30400276184082,
    MEDIA: 29.244937795239654,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53de04ee-3e1d-41b4-90d1-32aeb9b8c230',
    CIDADE: 'Crisólita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.21959686279297,
    MAXIMO: 29.279457092285156,
    MEDIA: 29.245607376098633,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7e7b59a5-dea5-45f7-9bd2-5338f8d50faa',
    CIDADE: 'Cristais',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.004703521728516,
    MAXIMO: 33.66691589355469,
    MEDIA: 33.38777440155155,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b62e5d60-158a-40d3-8513-4bdfff3d4b0b',
    CIDADE: 'Cristália',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.15652084350586,
    MAXIMO: 29.188796997070312,
    MEDIA: 29.16993776957194,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b51de25d-25e7-4804-aa71-fced875f1762',
    CIDADE: 'Cristiano Otoni',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.8916015625,
    MAXIMO: 33.7545051574707,
    MEDIA: 32.96658365188922,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ad08e21-ca51-4952-a0cc-b9f797568281',
    CIDADE: 'Cristina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.64815139770508,
    MAXIMO: 36.42680358886719,
    MEDIA: 36.08751695090832,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e7c3f7d3-fee7-4e95-8422-fa7ab90fa312',
    CIDADE: 'Crucilândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.478979110717773,
    MAXIMO: 31.871835708618164,
    MEDIA: 31.722985160936535,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81f42ee3-f1c2-4dab-a045-c6a89483d2ad',
    CIDADE: 'Cruzeiro da Fortaleza',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.990989685058594,
    MAXIMO: 35.53757095336914,
    MEDIA: 34.78589251076626,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6764b7bb-8513-4915-ac23-c24404a71fad',
    CIDADE: 'Cruzília',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.68731689453125,
    MAXIMO: 35.343849182128906,
    MEDIA: 34.9030979392128,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '105130c2-9ef1-4bf7-a790-b024102478ac',
    CIDADE: 'Cuparaque',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.41803741455078,
    MAXIMO: 29.67594337463379,
    MEDIA: 29.523980623959606,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a795b64-e5ed-4227-90a3-785ff3f4ef48',
    CIDADE: 'Curral de Dentro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.48624038696289,
    MAXIMO: 29.689212799072266,
    MEDIA: 29.552729412785144,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0c0d141-c70e-45ac-92d6-fe06e8c4f793',
    CIDADE: 'Curvelo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.60804557800293,
    MAXIMO: 30.15092658996582,
    MEDIA: 29.897100289662678,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9d26f65-34c9-4e05-8a80-2d0cef8e3e65',
    CIDADE: 'Datas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.302814483642578,
    MAXIMO: 29.46090316772461,
    MEDIA: 29.367462332831373,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '908a7dc7-f3ad-4f59-975c-ec4cdf538747',
    CIDADE: 'Delfim Moreira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.075130462646484,
    MAXIMO: 36.66069412231445,
    MEDIA: 36.39405472687011,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0afc78ff-467b-4466-a77c-aa97d32e050a',
    CIDADE: 'Delfinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.041900634765625,
    MAXIMO: 34.836727142333984,
    MEDIA: 34.396165466308595,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3157d1c-0598-4f6a-83ba-d483c1b05ad9',
    CIDADE: 'Delta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.614383697509766,
    MAXIMO: 37.40400314331055,
    MEDIA: 36.72895848460941,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d4e2558-4fb0-4a06-b5d3-fa3c4ad57082',
    CIDADE: 'Descoberto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.459503173828125,
    MAXIMO: 38.24175262451172,
    MEDIA: 38.101661632778395,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3fe1aa97-fcda-461a-8727-d811ae57c655',
    CIDADE: 'Desterro de Entre Rios',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.768369674682617,
    MAXIMO: 32.748775482177734,
    MEDIA: 32.26166532916675,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '08893680-fe7e-4a84-bcb7-9d4a8f184718',
    CIDADE: 'Desterro do Melo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.795230865478516,
    MAXIMO: 35.09743118286133,
    MEDIA: 34.86970126138138,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a34f529-48b1-477d-bf86-258e670b8c5f',
    CIDADE: 'Diamantina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.13306427001953,
    MAXIMO: 29.41798210144043,
    MEDIA: 29.263745852879115,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a7e2d05-09df-4cac-b7d8-648f101dbdae',
    CIDADE: 'Diogo de Vasconcelos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.96735382080078,
    MAXIMO: 32.11799240112305,
    MEDIA: 32.042673110961914,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a53ccd09-2684-45f5-ad88-5da297031ca2',
    CIDADE: 'Dionísio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.167335510253906,
    MAXIMO: 30.187837600708008,
    MEDIA: 30.177586555480957,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33b5e455-0281-46a7-bd0c-bb81011f2e41',
    CIDADE: 'Divinésia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.955345153808594,
    MAXIMO: 36.44099807739258,
    MEDIA: 35.214976425704975,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ae6153d-021a-4fd1-ab79-49ecb82a3ef5',
    CIDADE: 'Divino',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.42061996459961,
    MAXIMO: 36.458499908447266,
    MEDIA: 35.745683727491276,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6439fb8-75c9-4cf8-ae0d-ab5beb6aad58',
    CIDADE: 'Divino das Laranjeiras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.19550895690918,
    MAXIMO: 29.365575790405273,
    MEDIA: 29.302588079056438,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae690dbd-e114-4242-9d43-dcb1143fafc5',
    CIDADE: 'Divinolândia de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.290395736694336,
    MAXIMO: 29.343772888183594,
    MEDIA: 29.3287322650604,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d904432-3c3c-4cae-ac0a-e39d7b64392b',
    CIDADE: 'Divinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.55558204650879,
    MAXIMO: 31.690244674682617,
    MEDIA: 31.622913360595703,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f85b12e-b097-4599-ad74-37701c9f8922',
    CIDADE: 'Divisa Alegre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.7283992767334,
    MAXIMO: 29.829206466674805,
    MEDIA: 29.75539606112717,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '197f3b0a-554b-46fe-b5d2-1a2b7640d9e5',
    CIDADE: 'Divisa Nova',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.700645446777344,
    MAXIMO: 38.12675476074219,
    MEDIA: 37.2176942469629,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41aaf6a1-981c-4e9f-b31b-e46c79300b83',
    CIDADE: 'Divisópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.851377487182617,
    MAXIMO: 29.871898651123047,
    MEDIA: 29.861638069152832,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88e0cd32-20a0-48e4-806a-40ff594e8186',
    CIDADE: 'Dom Bosco',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.145687103271484,
    MAXIMO: 31.59238624572754,
    MEDIA: 31.36903667449951,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01a1e293-c1c7-421e-bbfc-5a10058000c1',
    CIDADE: 'Dom Cavati',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.630014419555664,
    MAXIMO: 29.822473526000977,
    MEDIA: 29.655856625224366,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdc68173-2577-48fd-a7e7-56680deb99b9',
    CIDADE: 'Dom Joaquim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.410505294799805,
    MAXIMO: 29.4843692779541,
    MEDIA: 29.447437286376953,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e411e00-20e8-4d28-a701-88a3f64e7077',
    CIDADE: 'Dom Silvério',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.51515007019043,
    MAXIMO: 30.959413528442383,
    MEDIA: 30.90665700784337,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd23c80a5-aba7-49ef-ac25-52f2a70b2147',
    CIDADE: 'Dom Viçoso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.20684814453125,
    MAXIMO: 35.86198043823242,
    MEDIA: 35.720436938101464,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7bc393d3-762c-46cd-9070-a01111d1b960',
    CIDADE: 'Dona Euzébia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.459503173828125,
    MAXIMO: 38.85106658935547,
    MEDIA: 38.149110927098235,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a8907c8-fee0-4494-aad2-e95ea58d43b6',
    CIDADE: 'Dores de Campos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.407012939453125,
    MAXIMO: 34.266422271728516,
    MEDIA: 34.12099040422615,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '961c4aaf-aa16-4429-800f-b43a13e8e449',
    CIDADE: 'Dores de Guanhães',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.401199340820312,
    MAXIMO: 29.498687744140625,
    MEDIA: 29.440612048425034,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a1a0290-9cb0-4a07-abd5-660c1345670e',
    CIDADE: 'Dores do Indaiá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.593841552734375,
    MAXIMO: 31.89350128173828,
    MEDIA: 31.748228073120117,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba70e8bf-fddc-44fc-98a9-14bdd668224a',
    CIDADE: 'Dores do Turvo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.566436767578125,
    MAXIMO: 35.917110443115234,
    MEDIA: 35.077393520564755,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '081a6aa3-7919-462a-9b1f-9cc74d39f5ca',
    CIDADE: 'Doresópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.76258850097656,
    MAXIMO: 33.15901565551758,
    MEDIA: 32.973360249492835,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52775121-2a69-4c67-8b5b-7d4e3d20ef6d',
    CIDADE: 'Douradoquara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.9356803894043,
    MAXIMO: 41.171539306640625,
    MEDIA: 39.68271218261452,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f9b780a-e803-4a71-9690-acdfef45443b',
    CIDADE: 'Durandé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.32077980041504,
    MAXIMO: 34.441619873046875,
    MEDIA: 32.57988829549762,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6aa3ee49-0a02-45de-a7ef-8efec45609f5',
    CIDADE: 'Elói Mendes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.66474914550781,
    MAXIMO: 36.124420166015625,
    MEDIA: 35.89458465576172,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8b07a13-a44f-4779-a8b8-60c0552f2b12',
    CIDADE: 'Engenheiro Caldas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.391618728637695,
    MAXIMO: 29.630014419555664,
    MEDIA: 29.46266890880946,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4592934b-96c4-4285-bf19-a7785805c954',
    CIDADE: 'Engenheiro Navarro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.303104400634766,
    MAXIMO: 29.313125610351562,
    MEDIA: 29.308115005493164,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '553b664f-32a8-4146-b2f2-c614a718fba7',
    CIDADE: 'Entre Folhas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.05449676513672,
    MAXIMO: 30.05449676513672,
    MEDIA: 30.05449676513672,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69405526-4771-45b9-9dbe-6c0c46401201',
    CIDADE: 'Entre Rios de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.25432586669922,
    MAXIMO: 32.819236755371094,
    MEDIA: 32.536781311035156,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a56d80f8-db38-47b5-9639-0ce78a352f82',
    CIDADE: 'Ervália',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.43781661987305,
    MAXIMO: 36.6593017578125,
    MEDIA: 36.04855918884277,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6352caf8-5be1-4015-952a-95cab84ab81e',
    CIDADE: 'Esmeraldas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.36792755126953,
    MAXIMO: 30.669349670410156,
    MEDIA: 30.51858949661255,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a6138a7-2d96-40a6-8616-b31f4ae33943',
    CIDADE: 'Espera Feliz',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.7639045715332,
    MAXIMO: 38.67450714111328,
    MEDIA: 37.253998159212784,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1cd2635d-2092-4de3-824e-7f40307c9d24',
    CIDADE: 'Espinosa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.91925048828125,
    MAXIMO: 30.500747680664062,
    MEDIA: 30.245123863220215,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ce4f56cf-cf5d-46f6-922d-df7f42afac76',
    CIDADE: 'Espírito Santo do Dourado',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.879638671875,
    MAXIMO: 39.25932693481445,
    MEDIA: 38.78379928343836,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b331e265-3d8f-4779-a585-779b9dd4f673',
    CIDADE: 'Estiva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.00172805786133,
    MAXIMO: 40.85018539428711,
    MEDIA: 39.938840737366924,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56f7d01e-4d9b-4485-8e56-8e06b22cdfd8',
    CIDADE: 'Estrela Dalva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.1904411315918,
    MAXIMO: 41.44163131713867,
    MEDIA: 41.08318576434128,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aaace846-8a89-46b8-819c-20f87892f717',
    CIDADE: 'Estrela do Indaiá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.0396614074707,
    MAXIMO: 32.36425018310547,
    MEDIA: 32.212677001953125,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6e60847-3bb3-4f2b-8fc0-265df282baaf',
    CIDADE: 'Estrela do Sul',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.39937210083008,
    MAXIMO: 41.579124450683594,
    MEDIA: 40.989248275756836,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef1ab985-309a-41fd-96c7-e43692ed0d28',
    CIDADE: 'Eugenópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.4468879699707,
    MAXIMO: 39.5863151550293,
    MEDIA: 39.0166015625,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '07a91d1c-8795-43bb-beca-ebe6df82c17b',
    CIDADE: 'Ewbank da Câmara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.41107177734375,
    MAXIMO: 37.577484130859375,
    MEDIA: 37.12574716654831,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7964dbd-da47-48ca-b99d-8849a28f4e65',
    CIDADE: 'Extrema',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.29782485961914,
    MAXIMO: 41.62593078613281,
    MEDIA: 41.15369322372738,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e113a76-93f7-4a26-be49-991399fe8ba6',
    CIDADE: 'Fama',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.53099060058594,
    MAXIMO: 36.60164260864258,
    MEDIA: 35.89820693450884,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3246de44-82db-47c0-a2cf-9c7bd6aa1d80',
    CIDADE: 'Faria Lemos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.10019302368164,
    MAXIMO: 39.207908630371094,
    MEDIA: 38.65405082702637,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd8654f7-78a9-48c1-8629-e1d62c6464c5',
    CIDADE: 'Felício dos Santos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.140121459960938,
    MAXIMO: 29.30400276184082,
    MEDIA: 29.19677996214649,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35b9a488-c67f-4c37-ae3f-1760e3ec59a6',
    CIDADE: 'São Gonçalo do Rio Preto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.18190574645996,
    MAXIMO: 29.260480880737305,
    MEDIA: 29.23777931859479,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b9b23b1-b81d-44c7-8b52-5ff53ba77f6f',
    CIDADE: 'Felisburgo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.370969772338867,
    MAXIMO: 29.491544723510742,
    MEDIA: 29.446021451660737,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30fc4055-031c-461e-9616-0d872b720f40',
    CIDADE: 'Felixlândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.071809768676758,
    MAXIMO: 30.40992546081543,
    MEDIA: 30.205413818359375,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2aef3c20-3287-4741-a65f-48ed80f7e887',
    CIDADE: 'Fernandes Tourinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.39353370666504,
    MAXIMO: 29.491479873657227,
    MEDIA: 29.458013127651014,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0474e33-a35e-41b3-8b64-f6639f037f7e',
    CIDADE: 'Ferros',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.47119140625,
    MAXIMO: 29.628995895385742,
    MEDIA: 29.5582706451416,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16ced12d-c047-4c26-a09f-349650c61989',
    CIDADE: 'Fervedouro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.80709457397461,
    MAXIMO: 37.046817779541016,
    MEDIA: 35.56185301934516,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86280dfd-e3c5-4b6e-9951-aaf90a22e0ec',
    CIDADE: 'Florestal',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.669349670410156,
    MAXIMO: 31.015901565551758,
    MEDIA: 30.75793425557948,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09d4785c-b884-4d40-af0a-76b7fbadc189',
    CIDADE: 'Formiga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.57295608520508,
    MAXIMO: 33.16499328613281,
    MEDIA: 32.89891052246094,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9805c0d-921f-4a1e-b9d3-a3c0d5a6e78b',
    CIDADE: 'Formoso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.623638153076172,
    MAXIMO: 29.833192825317383,
    MEDIA: 29.705148379007976,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc0c334b-325a-4817-8e6f-4ba2f9b66f0a',
    CIDADE: 'Fortaleza de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.77306365966797,
    MAXIMO: 35.5992317199707,
    MEDIA: 35.25121162117658,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd342417d-00ea-44bd-a780-c5dbbcf08d6c',
    CIDADE: 'Fortuna de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.306039810180664,
    MAXIMO: 30.59090232849121,
    MEDIA: 30.460040967024128,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a61b5c1-617c-4bed-8af7-ad94bd40b4c0',
    CIDADE: 'Francisco Badaró',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.095216751098633,
    MAXIMO: 29.129106521606445,
    MEDIA: 29.11216163635254,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4733b11d-6398-4bbc-b190-83eaf61f3c7b',
    CIDADE: 'Francisco Dumont',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.347143173217773,
    MAXIMO: 29.43537712097168,
    MEDIA: 29.387598991394043,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e95b233c-e733-401c-b328-38dda7a44f33',
    CIDADE: 'Francisco Sá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.210227966308594,
    MAXIMO: 29.277250289916992,
    MEDIA: 29.246750937567818,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84690870-87c5-4e0d-83a0-2b43c6463deb',
    CIDADE: 'Franciscópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.08026123046875,
    MAXIMO: 29.20825958251953,
    MEDIA: 29.159351348876953,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ec0fb97-d757-4c63-861d-bc7dc80c6cfe',
    CIDADE: 'Frei Gaspar',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.19691276550293,
    MAXIMO: 29.208864212036133,
    MEDIA: 29.204341888427734,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e4d36b1-2bac-461b-80f4-6d394c5b7cc2',
    CIDADE: 'Frei Inocêncio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.148740768432617,
    MAXIMO: 29.26390838623047,
    MEDIA: 29.18560399206526,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0ba0e51-ff28-44ca-9309-e5275639da62',
    CIDADE: 'Frei Lagonegro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.17325210571289,
    MAXIMO: 29.184080123901367,
    MEDIA: 29.18271009878151,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94e952fd-eb22-4a4e-8ffa-d6a59d928589',
    CIDADE: 'Fronteira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.02275848388672,
    MAXIMO: 35.33488464355469,
    MEDIA: 35.185272658782516,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9da5e31-6209-4c72-a552-adc78df456d4',
    CIDADE: 'Fronteira dos Vales',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.329744338989258,
    MAXIMO: 29.36774444580078,
    MEDIA: 29.34874439239502,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5402bf75-4299-47f0-a972-23eb9d9b5783',
    CIDADE: 'Fruta de Leite',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.228120803833008,
    MAXIMO: 29.375261306762695,
    MEDIA: 29.30169105529785,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '46b586fb-d20a-4ac2-bcee-9a04351d5165',
    CIDADE: 'Frutal',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.94022750854492,
    MAXIMO: 35.37411880493164,
    MEDIA: 35.10642115275065,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bc9a9b8-f673-4b95-9da1-b795b2ddf125',
    CIDADE: 'Funilândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.888397216796875,
    MAXIMO: 30.100387573242188,
    MEDIA: 29.98018623430557,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9fd90d3a-0896-4088-b184-a2e20465c3b7',
    CIDADE: 'Galiléia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.337491989135742,
    MAXIMO: 29.44931983947754,
    MEDIA: 29.38412920633952,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4518b2bc-2854-4244-be0f-74b23d133cc1',
    CIDADE: 'Gameleiras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.785768508911133,
    MAXIMO: 29.889698028564453,
    MEDIA: 29.822111892700196,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b070dc2e-f594-4b68-b69e-93019f4aa83f',
    CIDADE: 'Glaucilândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.183483123779297,
    MAXIMO: 29.220151901245117,
    MEDIA: 29.2034426091627,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95aca748-2348-4011-bc9c-356f387ee68c',
    CIDADE: 'Goiabeira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.488353729248047,
    MAXIMO: 29.619455337524414,
    MEDIA: 29.504775522339024,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49513ab4-cbb4-4016-9baa-b6e53f62e92b',
    CIDADE: 'Goianá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.21334457397461,
    MAXIMO: 38.4815673828125,
    MEDIA: 37.942962661828446,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b588a4e0-a466-4b99-b5e8-17addc79f439',
    CIDADE: 'Gonçalves',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.04493713378906,
    MAXIMO: 39.92103576660156,
    MEDIA: 38.87191561383398,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb58aee2-3389-457d-86bb-6d461afe05a7',
    CIDADE: 'Gonzaga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.323347091674805,
    MAXIMO: 29.40219497680664,
    MEDIA: 29.347410357168478,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b20b3523-bb1a-4c4e-b5e4-e4dd3e05a4ee',
    CIDADE: 'Gouveia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.405925750732422,
    MAXIMO: 29.45817756652832,
    MEDIA: 29.43205165863037,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8113abde-d5ba-4526-9cb0-26fa138327e6',
    CIDADE: 'Governador Valadares',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.259523391723633,
    MAXIMO: 29.39353370666504,
    MEDIA: 29.3016168806288,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1918f5a3-06a4-4d6d-8bf4-808fcc7df96d',
    CIDADE: 'Grão Mogol',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.15819549560547,
    MAXIMO: 29.255943298339844,
    MEDIA: 29.217570304870605,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6fcbc597-a346-4a6b-80ba-dfa2c3deed1e',
    CIDADE: 'Grupiara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.22602081298828,
    MAXIMO: 41.171539306640625,
    MEDIA: 40.89100661373729,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a87b09d9-1571-4970-9206-81d93edf3a2d',
    CIDADE: 'Guanhães',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.328752517700195,
    MAXIMO: 29.401199340820312,
    MEDIA: 29.36262321472168,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29daf298-2531-4096-98dd-f4e12d5bc0d8',
    CIDADE: 'Guapé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.51152420043945,
    MAXIMO: 33.80965042114258,
    MEDIA: 33.65540313720703,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a08ef450-cf83-44f7-9f99-053783010a49',
    CIDADE: 'Guaraciaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.11799240112305,
    MAXIMO: 33.279991149902344,
    MEDIA: 32.66321361407124,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3f12409-a7cf-4fdc-80c5-0d7e917f47a3',
    CIDADE: 'Guaraciama',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.15018653869629,
    MAXIMO: 29.220151901245117,
    MEDIA: 29.19118609265935,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1cf07e2f-8328-462f-9a98-30cd64ff95aa',
    CIDADE: 'Guaranésia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.069400787353516,
    MAXIMO: 38.34721755981445,
    MEDIA: 37.19842624331216,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '828ba609-e766-4760-8d24-5fea2f5a94ab',
    CIDADE: 'Guarani',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.38624954223633,
    MAXIMO: 38.24175262451172,
    MEDIA: 37.29910255973116,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41f8bf76-8d6f-4177-b6bf-6bc977bee5bd',
    CIDADE: 'Guarará',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.2028694152832,
    MAXIMO: 39.662174224853516,
    MEDIA: 39.313966019083026,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a10e5ffd-fec9-4fe2-bb69-5287630447b8',
    CIDADE: 'Guarda-Mor',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.0762825012207,
    MAXIMO: 36.88965606689453,
    MEDIA: 35.796661376953125,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d53d530-0afe-47d8-b5b2-2a1dd088ae41',
    CIDADE: 'Guaxupé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.069400787353516,
    MAXIMO: 38.34721755981445,
    MEDIA: 36.98316672795722,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9a1f232-099a-4fef-977c-b6cc36b9f8d8',
    CIDADE: 'Guidoval',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.44099807739258,
    MAXIMO: 38.030479431152344,
    MEDIA: 37.192474040901864,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7281ab7-6e7d-4bba-9025-22ca3f043927',
    CIDADE: 'Guimarânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.68320083618164,
    MAXIMO: 35.46410369873047,
    MEDIA: 35.073652267456055,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c57e728-8c2e-479e-92f9-33a56ef7a7c8',
    CIDADE: 'Guiricema',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.98847579956055,
    MAXIMO: 37.76079559326172,
    MEDIA: 36.54882322414995,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1828c6c7-8d2a-4f8a-8bd2-0789f60e87b7',
    CIDADE: 'Gurinhatã',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.01021194458008,
    MAXIMO: 35.276092529296875,
    MEDIA: 35.09661293029785,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a240b8b-0476-4963-9bb7-3bf31a127aed',
    CIDADE: 'Heliodora',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.50172805786133,
    MAXIMO: 37.57450485229492,
    MEDIA: 36.84567032955251,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1cccf04e-eb27-4302-899a-468d3c00ed86',
    CIDADE: 'Iapu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.490798950195312,
    MAXIMO: 29.822473526000977,
    MEDIA: 29.657897361876376,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f50b7a1a-4891-43c4-b3ce-af7142b2a0d4',
    CIDADE: 'Ibertioga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.72945022583008,
    MAXIMO: 36.467838287353516,
    MEDIA: 35.58162471444786,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb1243a4-2727-40a7-b42f-5bead68af677',
    CIDADE: 'Ibiá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.53953552246094,
    MAXIMO: 35.36262893676758,
    MEDIA: 34.418840581720524,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '316a7463-e77f-4bb4-8096-915078130a12',
    CIDADE: 'Ibiaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.495725631713867,
    MAXIMO: 29.59039878845215,
    MEDIA: 29.541991710662842,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86de4872-b205-4f1e-8b6f-d58ac04f3fc6',
    CIDADE: 'Ibiracatu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.454561233520508,
    MAXIMO: 29.454587936401367,
    MEDIA: 29.454574584960938,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bf910ffe-37f2-4118-86c5-bf29133f14d0',
    CIDADE: 'Ibiraci',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.059364318847656,
    MAXIMO: 35.072723388671875,
    MEDIA: 35.066043853759766,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd604cf7-261c-46e8-bd9d-3f944e037307',
    CIDADE: 'Ibirité',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.701614379882812,
    MAXIMO: 31.001157760620117,
    MEDIA: 30.715347803717183,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7c4ee74-badb-432c-9826-9d4dfe9021c3',
    CIDADE: 'Ibitiúra de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.026668548583984,
    MAXIMO: 41.53323745727539,
    MEDIA: 41.00463939027966,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '097fcf1b-2874-41a1-b8bf-57817db9d993',
    CIDADE: 'Ibituruna',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.571224212646484,
    MAXIMO: 34.01081085205078,
    MEDIA: 33.61894574626885,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39c17f24-e8f4-422e-bbec-7ed26f47023b',
    CIDADE: 'Icaraí de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.63995361328125,
    MAXIMO: 29.67900848388672,
    MEDIA: 29.659481048583984,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12dd27fa-65fd-4944-9505-6f274d43331b',
    CIDADE: 'Igarapé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.795007705688477,
    MAXIMO: 31.166507720947266,
    MEDIA: 30.999908101327062,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e031ec54-d199-492f-9c5b-6d97fc8d74d0',
    CIDADE: 'Igaratinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.915573120117188,
    MAXIMO: 31.401508331298828,
    MEDIA: 31.124863458145967,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bca8745a-c616-4232-aa16-1c456399e570',
    CIDADE: 'Iguatama',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.33560562133789,
    MAXIMO: 32.94951248168945,
    MEDIA: 32.63303236941142,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0211c459-7d75-4e7b-a58a-161498c1ff3f',
    CIDADE: 'Ijaci',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.571224212646484,
    MAXIMO: 34.032264709472656,
    MEDIA: 33.67371028876232,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3efa4b26-1cf6-4a9b-98c0-07d02e508914',
    CIDADE: 'Ilicínea',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.88481903076172,
    MAXIMO: 34.067115783691406,
    MEDIA: 33.97596740722656,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bcbf627-e390-4859-aaf8-538d86f85334',
    CIDADE: 'Imbé de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.82094955444336,
    MAXIMO: 30.11404800415039,
    MEDIA: 30.057781469317963,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20cc1331-cd2f-48b3-ad29-e74801696baa',
    CIDADE: 'Inconfidentes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.57164001464844,
    MAXIMO: 41.7417106628418,
    MEDIA: 41.253877478803936,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a8997ca-ccad-40ca-a038-be48b7a7f01b',
    CIDADE: 'Indaiabira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.532136917114258,
    MAXIMO: 29.655593872070312,
    MEDIA: 29.58446502685547,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37fcd3b7-7f75-4fde-8337-93fe6c6639da',
    CIDADE: 'Indianópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 41.45844650268555,
    MAXIMO: 42.59972381591797,
    MEDIA: 41.889668782552086,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4cd6863-6730-474b-a0e2-bfaf0c97d4a5',
    CIDADE: 'Ingaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.98564910888672,
    MAXIMO: 34.390380859375,
    MEDIA: 34.30087605576158,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f30e94cf-36c2-4e67-a6f4-350548a6793f',
    CIDADE: 'Inhapim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.82094955444336,
    MAXIMO: 29.822473526000977,
    MEDIA: 29.821711540222168,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bd866b1-9157-455c-a0cf-8b662b3b355f',
    CIDADE: 'Inhaúma',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.15142250061035,
    MAXIMO: 30.47319793701172,
    MEDIA: 30.332353901511866,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28075fa0-4931-41f3-a4d2-5334715f9d1c',
    CIDADE: 'Inimutaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.60804557800293,
    MAXIMO: 29.93492317199707,
    MEDIA: 29.743724026852806,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dbec1c6f-5329-48fc-840b-4472711623a5',
    CIDADE: 'Ipaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.610382080078125,
    MAXIMO: 29.803558349609375,
    MEDIA: 29.707144543903617,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61314a82-7c1c-473d-b86b-36444109a843',
    CIDADE: 'Ipanema',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.088232040405273,
    MAXIMO: 30.577978134155273,
    MEDIA: 30.333105087280273,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f53fd65b-389b-4b4f-b610-8e414e10e433',
    CIDADE: 'Ipatinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.58746337890625,
    MAXIMO: 29.7691593170166,
    MEDIA: 29.692945730759824,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df2a1b79-0150-439c-8071-a4b06184e037',
    CIDADE: 'Ipiaçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.97589874267578,
    MAXIMO: 35.69409942626953,
    MEDIA: 35.21704945820604,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '212e5258-5443-42f6-9173-84ed504089be',
    CIDADE: 'Ipuiúna',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.630550384521484,
    MAXIMO: 40.12575912475586,
    MEDIA: 39.54053075468256,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ed20624-4fae-4f2c-96e4-44822b9dd291',
    CIDADE: 'Iraí de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.00343704223633,
    MAXIMO: 40.339054107666016,
    MEDIA: 39.015469621955845,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1fe7c7e6-8942-4e95-a968-897c24a4986c',
    CIDADE: 'Itabira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.771318435668945,
    MAXIMO: 29.94426727294922,
    MEDIA: 29.864792346954346,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c70b85d5-cc4c-4bac-91c2-41d9c83a336b',
    CIDADE: 'Itabirinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.273954391479492,
    MAXIMO: 29.31907081604004,
    MEDIA: 29.287454223714267,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4622d440-7fd9-4e06-a29c-9809aa1d16a8',
    CIDADE: 'Itabirito',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.840335845947266,
    MAXIMO: 31.746442794799805,
    MEDIA: 31.138495282727952,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b02de154-24de-4845-aa9c-4d2fa3e261ee',
    CIDADE: 'Itacambira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.1406192779541,
    MAXIMO: 29.173952102661133,
    MEDIA: 29.160604095458986,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7674f409-66d3-482e-990c-e00b24b746d2',
    CIDADE: 'Itacarambi',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.515623092651367,
    MAXIMO: 29.64681053161621,
    MEDIA: 29.597822189331055,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62595ffa-0eca-48ab-9ce1-f43b6ca352d5',
    CIDADE: 'Itaguara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.573348999023438,
    MAXIMO: 31.912281036376953,
    MEDIA: 31.742815017700195,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd14cadab-0428-43e0-b86e-552856027b85',
    CIDADE: 'Itaipé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.115413665771484,
    MAXIMO: 29.137588500976562,
    MEDIA: 29.126501083374023,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '221610a6-6577-48ca-8c3d-7c0dd22f96a7',
    CIDADE: 'Itajubá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.075130462646484,
    MAXIMO: 37.326637268066406,
    MEDIA: 36.826046832953715,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77f20a65-0033-47aa-bba3-de3c724cd754',
    CIDADE: 'Itamarandiba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.04792022705078,
    MAXIMO: 29.203350067138672,
    MEDIA: 29.122212727864582,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2ff2b25-0583-4504-8fc8-36f3f2b3d349',
    CIDADE: 'Itamarati de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.24175262451172,
    MAXIMO: 38.85106658935547,
    MEDIA: 38.47748873745013,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '970d4275-b5b0-4b95-8915-b843d24c58ee',
    CIDADE: 'Itambacuri',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.148740768432617,
    MAXIMO: 29.201440811157227,
    MEDIA: 29.185717391967774,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63cfd352-bc31-4ff4-b4e6-08d1a68ce1d3',
    CIDADE: 'Itambé do Mato Dentro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.659698486328125,
    MAXIMO: 29.802886962890625,
    MEDIA: 29.718911286959294,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e7ff993-7c5d-463e-acd7-a42ac452b06a',
    CIDADE: 'Itamogi',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.8616943359375,
    MAXIMO: 36.841819763183594,
    MEDIA: 36.54193091538842,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9201b3ab-308d-4ec3-961c-adbb1652c616',
    CIDADE: 'Itamonte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.352840423583984,
    MAXIMO: 35.93160629272461,
    MEDIA: 35.6422233581543,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c558023d-b280-4f4d-afae-998bd65a45b6',
    CIDADE: 'Itanhandu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.352840423583984,
    MAXIMO: 35.864158630371094,
    MEDIA: 35.50759838254005,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a8922aed-4fd8-41fc-8586-5f8e4ad7f9b0',
    CIDADE: 'Itanhomi',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.391618728637695,
    MAXIMO: 29.64308738708496,
    MEDIA: 29.491260639173362,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12dd341c-3584-42cb-9fe7-613ca606b349',
    CIDADE: 'Itaobim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.2918758392334,
    MAXIMO: 29.403244018554688,
    MEDIA: 29.358024803682564,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03fc2d9c-1c94-47d9-9e2b-2aba7562f186',
    CIDADE: 'Itapagipe',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.91753387451172,
    MAXIMO: 35.029296875,
    MEDIA: 34.98610019683838,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b986076e-df94-4175-9819-5aee7a1ec3ed',
    CIDADE: 'Itapecerica',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.24470901489258,
    MAXIMO: 32.3941535949707,
    MEDIA: 32.31943130493164,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa527cdf-fbc8-4395-82e4-3b1226103791',
    CIDADE: 'Itapeva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.749263763427734,
    MAXIMO: 41.62593078613281,
    MEDIA: 40.90291720677725,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fdd85d6-004d-464c-879d-cb86c524accb',
    CIDADE: 'Itatiaiuçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.166507720947266,
    MAXIMO: 31.573348999023438,
    MEDIA: 31.325105040541253,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'faa51f9f-4699-40a0-b57a-7bef547fc477',
    CIDADE: 'Itaú de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.44798278808594,
    MAXIMO: 34.97172164916992,
    MEDIA: 34.78651738081892,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96ebcc19-150e-4277-8010-80926a9b06a8',
    CIDADE: 'Itaúna',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.015901565551758,
    MAXIMO: 31.401508331298828,
    MEDIA: 31.198981773351907,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a39122c6-7362-44b7-9c1f-f5786af15ede',
    CIDADE: 'Itaverava',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.33933639526367,
    MAXIMO: 33.17351150512695,
    MEDIA: 32.64170541018896,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '097b2b67-2398-472c-ba6f-4a9c3332bc44',
    CIDADE: 'Itinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.235326766967773,
    MAXIMO: 29.384750366210938,
    MEDIA: 29.290947437286377,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba2e113b-73cb-4986-a98a-45d3f66934c9',
    CIDADE: 'Itueta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.885772705078125,
    MAXIMO: 29.943607330322266,
    MEDIA: 29.914690017700195,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b198463-c653-44c6-af16-b8fe95d76a13',
    CIDADE: 'Ituiutaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.89775085449219,
    MAXIMO: 37.138858795166016,
    MEDIA: 35.874075698852536,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a79aea5-2ec3-4618-a6bc-285ae1f3cb13',
    CIDADE: 'Itumirim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.571224212646484,
    MAXIMO: 34.411956787109375,
    MEDIA: 33.98837199003887,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0237a95c-c87c-46b5-80bd-1ed29739c16e',
    CIDADE: 'Iturama',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.577537536621094,
    MAXIMO: 36.26021194458008,
    MEDIA: 35.86006088256836,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24763a29-c375-40e1-af15-f4d418b35160',
    CIDADE: 'Itutinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.58332061767578,
    MAXIMO: 34.55313491821289,
    MEDIA: 34.187563792339986,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8f4ce17-7df2-4620-af9e-6e3c5e4ccb12',
    CIDADE: 'Jaboticatubas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.81087875366211,
    MAXIMO: 30.02306365966797,
    MEDIA: 29.925031026204426,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f62f3c4-ceab-41ad-8504-68a3b810367c',
    CIDADE: 'Jacinto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.60890769958496,
    MAXIMO: 29.766984939575195,
    MEDIA: 29.686485926310223,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71dc860a-4d83-47e7-badc-b60530a9bdcf',
    CIDADE: 'Jacuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.323577880859375,
    MAXIMO: 36.38322067260742,
    MEDIA: 35.74151485858197,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0b27a1d-d569-45ea-b8d5-cc23cc3fd969',
    CIDADE: 'Jacutinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 42.20219421386719,
    MAXIMO: 43.0093994140625,
    MEDIA: 42.605796813964844,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f37716bc-228f-4910-bafe-ef0902bb4715',
    CIDADE: 'Jaguaraçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.723064422607422,
    MAXIMO: 29.907873153686523,
    MEDIA: 29.898181511794654,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c75f7796-942f-4f1f-b6b6-d9850252fc8b',
    CIDADE: 'Jaíba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.51774024963379,
    MAXIMO: 30.0261173248291,
    MEDIA: 29.742839813232422,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76d3b4af-e3cc-482c-a8d9-fddf7fbaff35',
    CIDADE: 'Jampruca',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.148740768432617,
    MAXIMO: 29.274375915527344,
    MEDIA: 29.178862276361855,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df783702-2344-474e-8ac6-43e87a689d3c',
    CIDADE: 'Janaúba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.385038375854492,
    MAXIMO: 29.488012313842773,
    MEDIA: 29.430395126342773,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '596ffda3-6940-41f8-bfb4-89c6a33aba9e',
    CIDADE: 'Januária',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.48754119873047,
    MAXIMO: 29.835372924804688,
    MEDIA: 29.62070779800415,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2571847-9773-4fdb-a461-e23d6a083515',
    CIDADE: 'Japaraíba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.05757522583008,
    MAXIMO: 32.527408599853516,
    MEDIA: 32.311362758917156,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f45a1bba-7999-46cd-bb13-f80c5a88f424',
    CIDADE: 'Japonvar',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.347631454467773,
    MAXIMO: 29.3900089263916,
    MEDIA: 29.368820190429688,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5becaac1-2100-4b50-8b44-5bee187361d4',
    CIDADE: 'Jeceaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.746442794799805,
    MAXIMO: 32.29513168334961,
    MEDIA: 32.01193778082266,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5929687a-c9e1-430c-9777-ca18c62111b5',
    CIDADE: 'Jenipapo de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.06899070739746,
    MAXIMO: 29.166580200195312,
    MEDIA: 29.133816019143342,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a72bb0a-e22b-41ec-86b8-2f8de0d2d225',
    CIDADE: 'Jequeri',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.707048416137695,
    MAXIMO: 34.80709457397461,
    MEDIA: 32.93956016685543,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c23fc6f3-9ee6-43af-a5a7-80a46668e1bf',
    CIDADE: 'Jequitaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.38194465637207,
    MAXIMO: 29.486133575439453,
    MEDIA: 29.43872308731079,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ccbd21e4-14d9-46bb-8981-d367050aaafe',
    CIDADE: 'Jequitibá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.77609634399414,
    MAXIMO: 29.854257583618164,
    MEDIA: 29.815176963806152,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3e60b69-d5e5-49ef-970d-694768e33044',
    CIDADE: 'Jequitinhonha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.347558975219727,
    MAXIMO: 29.53508949279785,
    MEDIA: 29.44928741455078,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81c041e5-c6ea-499e-945e-e53725da1191',
    CIDADE: 'Jesuânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.45344543457031,
    MAXIMO: 36.192020416259766,
    MEDIA: 35.755846217066306,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3414db4-e7fc-411f-bcdb-02b28afb897e',
    CIDADE: 'Joaíma',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.318572998046875,
    MAXIMO: 29.43950843811035,
    MEDIA: 29.382876586914062,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c61ca3c-a679-4146-afe9-0e571f0afce4',
    CIDADE: 'Joanésia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.479930877685547,
    MAXIMO: 29.58746337890625,
    MEDIA: 29.509735877402196,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa838b29-5b01-4561-b855-b2b629c073fe',
    CIDADE: 'João Monlevade',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.16950225830078,
    MAXIMO: 30.178590774536133,
    MEDIA: 30.17418699614083,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9e2f24b-6ab3-49e4-b1fa-7f90911023b4',
    CIDADE: 'João Pinheiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.178422927856445,
    MAXIMO: 32.2303352355957,
    MEDIA: 31.12180039542062,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a082b38a-3391-4f24-83ce-5e97c8053c15',
    CIDADE: 'Joaquim Felício',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.3134765625,
    MAXIMO: 29.390636444091797,
    MEDIA: 29.344790776570637,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd46f067a-794b-461c-b360-840e0bb346d6',
    CIDADE: 'Jordânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.88385772705078,
    MAXIMO: 29.916744232177734,
    MEDIA: 29.900300979614258,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33b1d876-65e0-4d81-9afc-80bba7601522',
    CIDADE: 'José Gonçalves de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.12054443359375,
    MAXIMO: 29.136295318603516,
    MEDIA: 29.128419876098633,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61c22b34-e741-45dd-b992-bc5e6895aed7',
    CIDADE: 'José Raydan',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.143634796142578,
    MAXIMO: 29.162017822265625,
    MEDIA: 29.15422375428301,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4eac9f1-3720-4ebd-bfce-e18ed7547193',
    CIDADE: 'Josenópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.1556396484375,
    MAXIMO: 29.362415313720703,
    MEDIA: 29.20827776410046,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1380ea94-e788-4fab-ad68-2acbbc3c4934',
    CIDADE: 'Nova União',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.862716674804688,
    MAXIMO: 30.013586044311523,
    MEDIA: 29.938151359558105,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'efe48a96-01f7-4e53-a5a0-ceda553c7427',
    CIDADE: 'Juatuba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.795007705688477,
    MAXIMO: 30.903505325317383,
    MEDIA: 30.88837460962465,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d8add78-bbd6-4055-a262-8f468702da3b',
    CIDADE: 'Juiz de Fora',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.577484130859375,
    MAXIMO: 38.745792388916016,
    MEDIA: 38.253133392333986,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6846cb01-9aac-4910-808a-6ee8b1e4c864',
    CIDADE: 'Juramento',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.173952102661133,
    MAXIMO: 29.232437133789062,
    MEDIA: 29.198893771983066,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '325ff895-e867-40ab-a288-559ba1ef114c',
    CIDADE: 'Juruaia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.22565460205078,
    MAXIMO: 36.97334671020508,
    MEDIA: 36.3063466216927,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1ada8072-db9b-4a57-b00f-7b8220667c72',
    CIDADE: 'Juvenília',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.182750701904297,
    MAXIMO: 30.279972076416016,
    MEDIA: 30.235455830891926,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e59fc8e-1ca5-4fd3-8e31-bf0d1b9a1a9c',
    CIDADE: 'Ladainha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.0865535736084,
    MAXIMO: 29.106157302856445,
    MEDIA: 29.094820658365887,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f86e90e0-7a33-4f83-b059-72dd48a59202',
    CIDADE: 'Lagamar',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.79462814331055,
    MAXIMO: 34.9944953918457,
    MEDIA: 34.35098419189453,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b6a2064-0b7c-4f6e-9311-e5284272d0bf',
    CIDADE: 'Lagoa da Prata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.889785766601562,
    MAXIMO: 32.33560562133789,
    MEDIA: 32.08693071060449,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '432e81ee-99b6-4d05-870e-10dd830d9b6c',
    CIDADE: 'Lagoa dos Patos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.43793296813965,
    MAXIMO: 29.62062644958496,
    MEDIA: 29.498096930252583,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e78ab76b-88d4-495c-aff6-4c0c8d565762',
    CIDADE: 'Lagoa Dourada',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.748775482177734,
    MAXIMO: 33.5413703918457,
    MEDIA: 33.148046162608836,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5799a99e-b956-480f-99d6-a488d60319c6',
    CIDADE: 'Lagoa Formosa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.149658203125,
    MAXIMO: 33.90203094482422,
    MEDIA: 33.42226028442383,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '480b7af5-031a-416c-8e72-e262f5b75b0f',
    CIDADE: 'Lagoa Grande',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.71099853515625,
    MAXIMO: 33.052162170410156,
    MEDIA: 32.87618509928385,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3776a088-65c3-4c5a-b78f-fd150d3d5ed3',
    CIDADE: 'Lagoa Santa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.02306365966797,
    MAXIMO: 30.188066482543945,
    MEDIA: 30.16436519681118,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5b29175-c047-4f15-9c40-8572fc4a05a0',
    CIDADE: 'Lajinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.0161018371582,
    MAXIMO: 33.57740020751953,
    MEDIA: 33.29675102233887,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f93b89d1-1436-4110-8e0c-057917e0ad21',
    CIDADE: 'Lambari',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.45344543457031,
    MAXIMO: 36.84628677368164,
    MEDIA: 36.068397338751026,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb3b8dd1-6a40-4dba-809c-a2dd7bb898ab',
    CIDADE: 'Lamim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.17351150512695,
    MAXIMO: 33.38236999511719,
    MEDIA: 33.30896474148437,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7ef134e-4b6a-4b51-8a37-1343e6ef3f87',
    CIDADE: 'Laranjal',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.87071228027344,
    MAXIMO: 40.24751281738281,
    MEDIA: 39.85537179052451,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fac3716-8f42-4ac4-bc25-db0684842126',
    CIDADE: 'Lassance',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.44440269470215,
    MAXIMO: 29.84675407409668,
    MEDIA: 29.62908020019531,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6df05665-5244-4f5c-973c-b34b66a979b1',
    CIDADE: 'Lavras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.642822265625,
    MAXIMO: 34.390380859375,
    MEDIA: 33.984202461657375,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '353b5081-5d9c-4f00-9e3c-8ca2b517acb9',
    CIDADE: 'Leandro Ferreira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.86182975769043,
    MAXIMO: 31.45061492919922,
    MEDIA: 31.107115811885933,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '882e9e17-518e-4a11-85a0-0b2d851d5831',
    CIDADE: 'Leme do Prado',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.082561492919922,
    MAXIMO: 29.136295318603516,
    MEDIA: 29.097598837764828,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '272df57d-a18f-4a9c-8eba-cabbe69c9c6c',
    CIDADE: 'Leopoldina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.85106658935547,
    MAXIMO: 40.89302444458008,
    MEDIA: 39.81070404052734,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55feabda-6a91-4a7b-80e2-b9f2e236de51',
    CIDADE: 'Liberdade',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.41740798950195,
    MAXIMO: 37.6430549621582,
    MEDIA: 36.88963356930637,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56da9fc0-220a-44b2-8337-b7367a5f213a',
    CIDADE: 'Lima Duarte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.77390670776367,
    MAXIMO: 37.18814468383789,
    MEDIA: 36.98102569580078,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c80f99e4-9f92-4957-97a4-fdf7554e635e',
    CIDADE: 'Limeira do Oeste',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.86931610107422,
    MAXIMO: 36.352294921875,
    MEDIA: 36.10135459899902,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f118a93-1c2e-452f-9003-0f7849b058fc',
    CIDADE: 'Lontra',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.359329223632812,
    MAXIMO: 29.486812591552734,
    MEDIA: 29.38738064459548,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '184da5d3-6f4a-460d-978a-322e857240a3',
    CIDADE: 'Luisburgo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.127933502197266,
    MAXIMO: 35.62099838256836,
    MEDIA: 34.611403924229165,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9d4b7a0-45e1-4235-a9e0-0706d6f761be',
    CIDADE: 'Luislândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.42064094543457,
    MAXIMO: 29.508623123168945,
    MEDIA: 29.437389730486515,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '478ad3f6-2cc5-4464-a697-70d85a6e0ed1',
    CIDADE: 'Luminárias',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.390380859375,
    MAXIMO: 34.68731689453125,
    MEDIA: 34.538848876953125,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b13ce2a-8db1-4922-b073-66da2dbee216',
    CIDADE: 'Luz',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.925029754638672,
    MAXIMO: 32.33560562133789,
    MEDIA: 32.14907455444336,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a76eb1d9-6dd9-4c32-b8b6-fa3fd0536ea9',
    CIDADE: 'Machacalis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.279457092285156,
    MAXIMO: 29.36774444580078,
    MEDIA: 29.309617295041456,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '80dbc1cf-a642-4b59-9283-b5f8385b3357',
    CIDADE: 'Machado',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.60164260864258,
    MAXIMO: 37.1058464050293,
    MEDIA: 36.85374450683594,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '97f07f18-6abd-47be-9391-66d6b01d829c',
    CIDADE: 'Madre de Deus de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.55313491821289,
    MAXIMO: 35.72896194458008,
    MEDIA: 35.05117480919978,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7adbedc1-fb04-4e9d-80a3-e85f604db615',
    CIDADE: 'Malacacheta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.07990837097168,
    MAXIMO: 29.09557342529297,
    MEDIA: 29.087740898132324,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76175762-8640-47d8-884f-7ba57909d50b',
    CIDADE: 'Mamonas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.822946548461914,
    MAXIMO: 30.32426643371582,
    MEDIA: 30.06503945922361,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '264de02d-bfe8-46c0-ac8a-3da78f743045',
    CIDADE: 'Manga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.746110916137695,
    MAXIMO: 30.233131408691406,
    MEDIA: 30.030085881551106,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8fb04972-a788-4aef-ba4f-435795e3e670',
    CIDADE: 'Manhuaçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.860464096069336,
    MAXIMO: 33.127933502197266,
    MEDIA: 32.4941987991333,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c51a4e53-04c2-4dc6-a26d-17db21711365',
    CIDADE: 'Manhumirim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.127933502197266,
    MAXIMO: 35.62099838256836,
    MEDIA: 33.879661330267986,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a91232df-395f-402e-ae5b-68ba3db5a33f',
    CIDADE: 'Mantena',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.273954391479492,
    MAXIMO: 29.44781494140625,
    MEDIA: 29.359284358214232,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c1b1486-628c-4c22-bce0-d860eb0a4adb',
    CIDADE: 'Maravilhas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.4060115814209,
    MAXIMO: 30.716938018798828,
    MEDIA: 30.510650341930628,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac067f13-a351-459b-998b-dd5ed829008a',
    CIDADE: 'Mar de Espanha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.2028694152832,
    MAXIMO: 40.20524978637695,
    MEDIA: 39.75440694288533,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03b63171-e5f8-4f41-84f6-bd3d87dc439b',
    CIDADE: 'Maria da Fé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.86198043823242,
    MAXIMO: 37.281673431396484,
    MEDIA: 36.241837080431566,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '047cfa78-cf5c-47dc-85ca-ea08ef947a5a',
    CIDADE: 'Mariana',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.832195281982422,
    MAXIMO: 31.84943199157715,
    MEDIA: 31.322546005249023,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '103cc966-524e-4296-92a8-3268caea2a07',
    CIDADE: 'Marilac',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.150468826293945,
    MAXIMO: 29.176816940307617,
    MEDIA: 29.175264350954876,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b70221b-17f3-45eb-8202-c27d6da30524',
    CIDADE: 'Mário Campos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.795007705688477,
    MAXIMO: 31.06976318359375,
    MEDIA: 31.008018563188866,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b2d82ed9-6684-49fa-b89c-53b97c44f974',
    CIDADE: 'Maripá de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.4815673828125,
    MAXIMO: 39.662174224853516,
    MEDIA: 39.267492312102235,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77f675b9-d2cd-4921-8f97-440f07c2d765',
    CIDADE: 'Marliéria',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.72550392150879,
    MAXIMO: 30.29734992980957,
    MEDIA: 30.00952445284152,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '380c722b-b3d0-4f71-b3b1-bcc4b0fb44c3',
    CIDADE: 'Marmelópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.78862762451172,
    MAXIMO: 36.191162109375,
    MEDIA: 36.05581100725645,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9665acff-b3b8-46e9-a999-d36b9e4e4034',
    CIDADE: 'Martinho Campos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.904495239257812,
    MAXIMO: 31.341732025146484,
    MEDIA: 31.07612419128418,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b18c10b4-0dda-44a6-a3f0-0ae60b68330c',
    CIDADE: 'Martins Soares',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.180599212646484,
    MAXIMO: 34.441619873046875,
    MEDIA: 33.91942634770499,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b9016bb6-6eea-4abc-81ae-31b07fd52d6e',
    CIDADE: 'Mata Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.775691986083984,
    MAXIMO: 29.970502853393555,
    MEDIA: 29.873883116145336,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62277dc0-6d54-4f2c-81f6-6d670984b877',
    CIDADE: 'Materlândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.230892181396484,
    MAXIMO: 29.332616806030273,
    MEDIA: 29.275540231645458,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c50e0d79-31f1-4de8-bc24-d50dad5dcf88',
    CIDADE: 'Mateus Leme',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.903505325317383,
    MAXIMO: 31.273845672607422,
    MEDIA: 31.010296917196282,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92a51e33-f53b-4069-9563-d5d72b2258f4',
    CIDADE: 'Matias Barbosa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.35067367553711,
    MAXIMO: 39.39435577392578,
    MEDIA: 39.01488018412562,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40e51c0f-bd9f-42f5-87b2-ee03a80eb451',
    CIDADE: 'Matias Cardoso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.643144607543945,
    MAXIMO: 30.2082576751709,
    MEDIA: 30.031090021133423,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c0ed53d-2f34-4610-8021-77e420842245',
    CIDADE: 'Matipó',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.58228874206543,
    MAXIMO: 34.02351379394531,
    MEDIA: 32.50299669885646,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebe8d876-7197-4950-8cf0-67fe61c1db09',
    CIDADE: 'Mato Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.439355850219727,
    MAXIMO: 29.804210662841797,
    MEDIA: 29.49185033412707,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82ca8761-d40d-400b-b631-0d347fc86ac0',
    CIDADE: 'Matozinhos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.888397216796875,
    MAXIMO: 30.36792755126953,
    MEDIA: 30.13869092543469,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8883d412-6efd-4ecd-9c43-6ae68f42293c',
    CIDADE: 'Matutina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.920391082763672,
    MAXIMO: 32.32452392578125,
    MEDIA: 32.12245750427246,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0b95fdd-f3a0-4d8b-8942-dd9b46e4cc8c',
    CIDADE: 'Medeiros',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.18345260620117,
    MAXIMO: 33.88129425048828,
    MEDIA: 33.53847122192383,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a650792-b730-4497-8dee-f7cc4f94d674',
    CIDADE: 'Medina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.373958587646484,
    MAXIMO: 29.54155158996582,
    MEDIA: 29.446132278442384,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0d06056-7e07-417c-8705-ab751b0a5e2f',
    CIDADE: 'Mendes Pimentel',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.298675537109375,
    MAXIMO: 29.31907081604004,
    MEDIA: 29.308873176574707,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d978dc0-d31f-49e6-9113-2d810631e21a',
    CIDADE: 'Mercês',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.09743118286133,
    MAXIMO: 36.38624954223633,
    MEDIA: 35.66830572550905,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30361962-3d66-463f-87b0-ac1516d2fdb0',
    CIDADE: 'Mesquita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.479930877685547,
    MAXIMO: 29.603940963745117,
    MEDIA: 29.543975394796348,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86fbf5a6-e81e-4ae7-857c-796545f26a02',
    CIDADE: 'Minas Novas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.06899070739746,
    MAXIMO: 29.132877349853516,
    MEDIA: 29.098817552839005,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e52cd052-1f8f-4081-b355-3228f9deb845',
    CIDADE: 'Minduri',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.78030776977539,
    MAXIMO: 35.439632415771484,
    MEDIA: 35.05549541355536,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3a4e9a6-e334-46a9-a999-db728878df1a',
    CIDADE: 'Mirabela',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.326894760131836,
    MAXIMO: 29.347349166870117,
    MEDIA: 29.339075088500977,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5fb16ed-c871-4712-8a0f-c4243bf50204',
    CIDADE: 'Miradouro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.43781661987305,
    MAXIMO: 38.4468879699707,
    MEDIA: 36.681298479434425,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76802769-7f24-4dc4-8788-0cbe07cd99b0',
    CIDADE: 'Miraí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.05137634277344,
    MAXIMO: 38.87071228027344,
    MEDIA: 37.7632924502207,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74b831f8-7ae1-42c7-bc16-9d4b2eee10f6',
    CIDADE: 'Miravânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.713045120239258,
    MAXIMO: 29.80386734008789,
    MEDIA: 29.75776050085459,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e40214a-9511-42c7-8b7d-23ffa813f22d',
    CIDADE: 'Moeda',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.30262565612793,
    MAXIMO: 31.768369674682617,
    MEDIA: 31.34605512031127,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55b4c2bf-bbb1-4165-9498-4c53a13d4c48',
    CIDADE: 'Moema',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.675920486450195,
    MAXIMO: 31.925029754638672,
    MEDIA: 31.77012153045698,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c9c4656-1c4d-4745-a2d6-fb399f442c26',
    CIDADE: 'Monjolos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.358400344848633,
    MAXIMO: 29.54425811767578,
    MEDIA: 29.48187946335462,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9179d2e-201d-4b34-828d-2a71c44a9a83',
    CIDADE: 'Monsenhor Paulo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.25164794921875,
    MAXIMO: 36.12564468383789,
    MEDIA: 35.93433777092628,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c692ee2d-8a8e-4a55-a16a-d070c77a6078',
    CIDADE: 'Montalvânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.741601943969727,
    MAXIMO: 29.867347717285156,
    MEDIA: 29.80342721939087,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9af53f4-b88c-4d39-8ab4-4b01591dcd75',
    CIDADE: 'Monte Alegre de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.86391067504883,
    MAXIMO: 43.30624008178711,
    MEDIA: 40.79472732543945,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86c6e0da-fcc6-4398-afd4-08420371a5ec',
    CIDADE: 'Monte Azul',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.740564346313477,
    MAXIMO: 29.847492218017578,
    MEDIA: 29.782007694244385,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5552845-a6c5-441f-b3e2-575608c40be3',
    CIDADE: 'Monte Belo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.22565460205078,
    MAXIMO: 37.58192825317383,
    MEDIA: 36.28645265720597,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '997e44d7-5e86-4680-8c70-8fd524239396',
    CIDADE: 'Monte Carmelo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.34459686279297,
    MAXIMO: 40.22602081298828,
    MEDIA: 39.25697612762451,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '112655ac-6aa1-4dc7-ac3b-b8e47bb9df57',
    CIDADE: 'Monte Formoso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.2545223236084,
    MAXIMO: 29.369245529174805,
    MEDIA: 29.30253266125001,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee05a252-39d9-43d0-989a-02dd17a5fad9',
    CIDADE: 'Monte Santo de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.38322067260742,
    MAXIMO: 37.55061340332031,
    MEDIA: 36.96691703796387,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d2d95e8-f007-4533-87c2-f15b5c5898f3',
    CIDADE: 'Montes Claros',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.220151901245117,
    MAXIMO: 29.320377349853516,
    MEDIA: 29.272925567626952,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4dc0e2d4-950b-44eb-af3b-af4efe3a1a4f',
    CIDADE: 'Monte Sião',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 41.7417106628418,
    MAXIMO: 43.58402633666992,
    MEDIA: 42.7999485907465,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfc84968-9346-474f-b742-e336659237d5',
    CIDADE: 'Montezuma',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.947547912597656,
    MAXIMO: 30.357879638671875,
    MEDIA: 30.189252217610676,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dab32c5b-fae2-4f16-a4c8-4bddd59f641d',
    CIDADE: 'Morada Nova de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.42654037475586,
    MAXIMO: 30.886474609375,
    MEDIA: 30.641823053359985,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df90ef47-42f5-42f7-aa3a-dbcff16703e4',
    CIDADE: 'Morro da Garça',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.760845184326172,
    MAXIMO: 30.071809768676758,
    MEDIA: 29.88344914632189,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd814934e-fc9f-4112-872a-ac01eb7902c6',
    CIDADE: 'Morro do Pilar',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.584148406982422,
    MAXIMO: 29.68816566467285,
    MEDIA: 29.636157035827637,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f882e421-1152-48af-b846-a1cb33dd0b2f',
    CIDADE: 'Munhoz',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.749263763427734,
    MAXIMO: 41.836273193359375,
    MEDIA: 41.59768504934841,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f89f0d51-4bcf-4132-b68b-280896f93e44',
    CIDADE: 'Muriaé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.43781661987305,
    MAXIMO: 40.46804428100586,
    MEDIA: 38.43600893975867,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30d15efe-2794-4805-806e-cff379265def',
    CIDADE: 'Mutum',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.813396453857422,
    MAXIMO: 31.79126739501953,
    MEDIA: 31.37049166361491,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f2beb9f-185e-46a9-80de-7ce02a65558d',
    CIDADE: 'Muzambinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.57350540161133,
    MAXIMO: 37.58192825317383,
    MEDIA: 37.07771682739258,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8c87315-0a8c-4d8b-b99f-ead7d7536f01',
    CIDADE: 'Nacip Raydan',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.150468826293945,
    MAXIMO: 29.182382583618164,
    MEDIA: 29.175073190640962,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e5caf670-3709-45f6-9a82-9682648c03ea',
    CIDADE: 'Nanuque',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.27240753173828,
    MAXIMO: 29.305253982543945,
    MEDIA: 29.283857981363933,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1d66209-0d94-47be-8026-fd99e1eabab3',
    CIDADE: 'Naque',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.483074188232422,
    MAXIMO: 29.490798950195312,
    MEDIA: 29.488638716524388,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '943666e9-7778-4185-b97a-6803a8b74cad',
    CIDADE: 'Natalândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.25389289855957,
    MAXIMO: 32.091163635253906,
    MEDIA: 31.87387082511905,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e8ca53b-2024-498f-a9d7-03741458fe89',
    CIDADE: 'Natércia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.192020416259766,
    MAXIMO: 37.57450485229492,
    MEDIA: 36.76200079469994,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5eb8d555-32c3-4f4e-a99f-497a79a5e7cb',
    CIDADE: 'Nazareno',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.58332061767578,
    MAXIMO: 34.261966705322266,
    MEDIA: 33.85347979404005,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd375982c-7b3f-47d6-9a7f-3737aed5aa5c',
    CIDADE: 'Nepomuceno',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.72639846801758,
    MAXIMO: 34.33788299560547,
    MEDIA: 34.07107416788737,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b584fc1b-bb0d-4762-bf4b-238d38125fda',
    CIDADE: 'Ninheira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.243141174316406,
    MAXIMO: 30.933120727539062,
    MEDIA: 30.492197036743164,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0cc5c114-6b65-4cdb-a7cc-e0ec0dfbb116',
    CIDADE: 'Nova Belém',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.29267120361328,
    MAXIMO: 29.316755294799805,
    MEDIA: 29.295638010225645,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09c2a2ba-ff57-48e0-be67-e63899b2f31b',
    CIDADE: 'Nova Era',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.761234283447266,
    MAXIMO: 30.178590774536133,
    MEDIA: 30.0398362565974,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ce7c7c1-8bfa-4d7c-87ae-d994ffb91705',
    CIDADE: 'Nova Lima',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.63295555114746,
    MAXIMO: 30.930282592773438,
    MEDIA: 30.78161907196045,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef03fe03-3a2b-465c-aa96-44c033145b9f',
    CIDADE: 'Nova Módica',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.19550895690918,
    MAXIMO: 29.22228240966797,
    MEDIA: 29.208895683288574,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3334ebf-f62a-4e9c-a2fe-03d39d8398d8',
    CIDADE: 'Nova Ponte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.596412658691406,
    MAXIMO: 40.922367095947266,
    MEDIA: 39.82434616088867,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db91fa1a-8691-4bb3-8454-f845a72d3174',
    CIDADE: 'Nova Porteirinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.411867141723633,
    MAXIMO: 29.49833869934082,
    MEDIA: 29.458684710372296,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40d93259-77e4-4727-b401-cc09bad17458',
    CIDADE: 'Nova Resende',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.22565460205078,
    MAXIMO: 35.68479919433594,
    MEDIA: 35.45522689819336,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5097e2de-4f11-444a-abf4-b1484bece5af',
    CIDADE: 'Nova Serrana',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.0382022857666,
    MAXIMO: 31.45061492919922,
    MEDIA: 31.185626363678974,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35a3b1cc-ca37-40a9-acd2-bb35f123ed09',
    CIDADE: 'Novo Cruzeiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.09814453125,
    MAXIMO: 29.13717269897461,
    MEDIA: 29.11674270629883,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29c22428-4518-415a-a10c-d0e69cbeda75',
    CIDADE: 'Novo Oriente de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.192190170288086,
    MAXIMO: 29.2545223236084,
    MEDIA: 29.22142155965169,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4239cefd-a110-4723-8ab2-2301d8d30296',
    CIDADE: 'Novorizonte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.375261306762695,
    MAXIMO: 29.437320709228516,
    MEDIA: 29.40806277157955,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92f43da2-3451-4b90-b0db-69eb639f6898',
    CIDADE: 'Olaria',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.77390670776367,
    MAXIMO: 37.9173698425293,
    MEDIA: 37.73118216254464,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d184ed2-d58e-4070-b130-eace97d38077',
    CIDADE: "Olhos-d'Água",
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.16803550720215,
    MAXIMO: 29.2646484375,
    MEDIA: 29.212170600891113,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '068fc37d-e4e7-4e11-bb14-bb278c22defa',
    CIDADE: 'Olímpio Noronha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.64815139770508,
    MAXIMO: 36.192020416259766,
    MEDIA: 35.918177373449105,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e5adab3-9b65-41c8-9197-032b471828e8',
    CIDADE: 'Oliveira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.34408950805664,
    MAXIMO: 32.78657150268555,
    MEDIA: 32.65546226501465,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cc260767-176b-4a0a-ae6b-e2ed364c9bc7',
    CIDADE: 'Oliveira Fortes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.626976013183594,
    MAXIMO: 36.7841682434082,
    MEDIA: 35.76733913328548,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0c99c69a-2e10-4606-a056-be796bd1ab08',
    CIDADE: 'Onça de Pitangui',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.699113845825195,
    MAXIMO: 31.0382022857666,
    MEDIA: 30.778420014395994,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a91a58d-cff6-492b-8055-796f72e20f22',
    CIDADE: 'Oratórios',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.707048416137695,
    MAXIMO: 32.600914001464844,
    MEDIA: 32.46956780298121,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ec7a6db5-a84f-4a2a-8163-475aabb94793',
    CIDADE: 'Orizânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.02351379394531,
    MAXIMO: 35.567691802978516,
    MEDIA: 34.40394178409906,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da926bc3-2280-4fb8-b5ad-269bc60b3b73',
    CIDADE: 'Ouro Branco',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.776355743408203,
    MAXIMO: 32.419029235839844,
    MEDIA: 31.968411679570135,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e0ec7da-cf59-4e95-980b-419ed2bd6ba4',
    CIDADE: 'Ouro Fino',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.12575912475586,
    MAXIMO: 42.660926818847656,
    MEDIA: 41.54839460728488,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92c73a89-c123-48d2-a310-7ef5036d44bc',
    CIDADE: 'Ouro Preto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.267852783203125,
    MAXIMO: 31.79969596862793,
    MEDIA: 31.447274525960285,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd21a1fc1-a3d5-48e4-aaed-b7f8fabf0128',
    CIDADE: 'Ouro Verde de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.20724868774414,
    MAXIMO: 29.221778869628906,
    MEDIA: 29.218380053828074,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '133b4ba4-a71f-469e-9854-2c965338587a',
    CIDADE: 'Padre Carvalho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.248950958251953,
    MAXIMO: 29.26738929748535,
    MEDIA: 29.258170127868652,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17d684f0-d483-4afe-a65f-b08d6e34c04a',
    CIDADE: 'Padre Paraíso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.19921875,
    MAXIMO: 29.23693084716797,
    MEDIA: 29.218074798583984,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e55f256-e7c4-443e-893b-b433eba2a3d8',
    CIDADE: 'Paineiras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.519670486450195,
    MAXIMO: 31.415538787841797,
    MEDIA: 31.017453137502816,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3f71e52d-c3d6-4e14-8b96-36fb4cca077c',
    CIDADE: 'Pains',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.72466278076172,
    MAXIMO: 32.902565002441406,
    MEDIA: 32.81361389160156,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c89c82e4-a694-474f-9f18-0cd3574f054b',
    CIDADE: 'Pai Pedro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.4295654296875,
    MAXIMO: 29.800804138183594,
    MEDIA: 29.519375500530774,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e601964-255b-46e3-8bd4-76c858eccc03',
    CIDADE: 'Paiva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.97340393066406,
    MAXIMO: 35.97340393066406,
    MEDIA: 35.97340393066406,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8c8b31f-c051-4726-9a4c-3d291d264b29',
    CIDADE: 'Palma',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.64208984375,
    MAXIMO: 41.65203857421875,
    MEDIA: 40.646790325580895,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd5ceabf8-9738-43d3-b45a-4652b67465fe',
    CIDADE: 'Palmópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.395830154418945,
    MAXIMO: 29.47592544555664,
    MEDIA: 29.441741894595967,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '064ff8b4-e1b6-436a-b603-3759528ceddc',
    CIDADE: 'Papagaios',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.269996643066406,
    MAXIMO: 30.40154457092285,
    MEDIA: 30.33577060699463,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3adc2ec4-4b20-414b-80f8-7a8a37080390',
    CIDADE: 'Paracatu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.77757453918457,
    MAXIMO: 36.510799407958984,
    MEDIA: 34.01948017544217,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b2164fa0-db75-4a43-8bb4-54f751800c78',
    CIDADE: 'Pará de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.786531448364258,
    MAXIMO: 31.015901565551758,
    MEDIA: 30.906002044677734,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '345ec0ca-a19d-4b14-8234-33bae6519484',
    CIDADE: 'Paraguaçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.53099060058594,
    MAXIMO: 37.27698516845703,
    MEDIA: 36.08954372539765,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98b78cfb-c551-4e31-b12a-13adb82a89af',
    CIDADE: 'Paraisópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.09806442260742,
    MAXIMO: 40.07771301269531,
    MEDIA: 38.82706431890359,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c882a174-bed4-4cf5-979a-eb88f146be2e',
    CIDADE: 'Paraopeba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.93463134765625,
    MAXIMO: 30.4060115814209,
    MEDIA: 30.158556041993226,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfa43bf1-3852-4e01-9d20-a2477d5f3c45',
    CIDADE: 'Passabém',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.628995895385742,
    MAXIMO: 29.659698486328125,
    MEDIA: 29.646947110669267,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4026ba10-3694-4b39-88ed-43c727fcf997',
    CIDADE: 'Passa Quatro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.352840423583984,
    MAXIMO: 35.864158630371094,
    MEDIA: 35.78804786768683,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6663f59c-9ffc-49b1-8dd1-636d2eba6cb4',
    CIDADE: 'Passa Tempo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.2886962890625,
    MAXIMO: 32.33564376831055,
    MEDIA: 32.31217002868652,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29b14e72-9b29-4194-a12d-b4712fc20764',
    CIDADE: 'Passa Vinte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.97467803955078,
    MAXIMO: 38.343849182128906,
    MEDIA: 37.86859727120578,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebce7537-6694-4a1c-a6b4-de88243c408b',
    CIDADE: 'Passos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.44798278808594,
    MAXIMO: 34.77306365966797,
    MEDIA: 34.57285976409912,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4f45c2b-0147-4c20-822b-48b9325322f2',
    CIDADE: 'Patis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.30084800720215,
    MAXIMO: 29.438425064086914,
    MEDIA: 29.341590908922377,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a1a168c-069c-433a-a2a7-1f5267134baa',
    CIDADE: 'Patos de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.98178482055664,
    MAXIMO: 35.29572677612305,
    MEDIA: 33.94226408004761,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'edfa469f-2ae7-4b29-a344-0d38b4d5bd1e',
    CIDADE: 'Patrocínio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.514278411865234,
    MAXIMO: 38.24831771850586,
    MEDIA: 36.66956075032552,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89733601-2332-4377-8a95-e1fa2dccc4af',
    CIDADE: 'Patrocínio do Muriaé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.5863151550293,
    MAXIMO: 40.56550979614258,
    MEDIA: 39.641196240350496,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f8c6723-4c97-447f-94a5-bfaa0d4d77fd',
    CIDADE: 'Paula Cândido',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.93638229370117,
    MAXIMO: 35.41607666015625,
    MEDIA: 34.51401107394105,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd606fe5-ca91-4a73-b045-ec146abdc2a8',
    CIDADE: 'Paulistas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.20372200012207,
    MAXIMO: 29.261001586914062,
    MEDIA: 29.24454737415775,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29087653-ff05-4db4-8200-7524405a1d3f',
    CIDADE: 'Pavão',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.194429397583008,
    MAXIMO: 29.217844009399414,
    MEDIA: 29.20613670349121,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0eb91b3d-ebda-485d-8660-5e169c2e0878',
    CIDADE: 'Peçanha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.177165985107422,
    MAXIMO: 29.29726219177246,
    MEDIA: 29.24327516555786,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c53fb33c-61f2-4e2a-8708-96d616944d3d',
    CIDADE: 'Pedra Azul',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.567920684814453,
    MAXIMO: 29.65495491027832,
    MEDIA: 29.614530944824217,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60197d8e-5472-48aa-8f6e-e6751b16a99e',
    CIDADE: 'Pedra Bonita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.23147201538086,
    MAXIMO: 35.567691802978516,
    MEDIA: 33.60043315676657,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ec9ef58-8c82-44c6-b70c-500a085a7bb2',
    CIDADE: 'Pedra do Anta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.600914001464844,
    MAXIMO: 34.18052673339844,
    MEDIA: 33.58484969820873,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e28f96c-ee0b-4f4c-aa86-e550e4e0c44c',
    CIDADE: 'Pedra do Indaiá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.843490600585938,
    MAXIMO: 32.3941535949707,
    MEDIA: 32.12719774860796,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84b05da7-dd06-411c-977d-e22933678465',
    CIDADE: 'Pedra Dourada',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.046817779541016,
    MAXIMO: 38.10019302368164,
    MEDIA: 37.88346113354107,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6fabd356-9f0c-43ec-b34a-bd3ec081e9c2',
    CIDADE: 'Pedralva',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.192020416259766,
    MAXIMO: 37.10893249511719,
    MEDIA: 36.790815818092554,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6d73891-9608-4c25-95e4-903fd7c27d8a',
    CIDADE: 'Pedras de Maria da Cruz',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.48077964782715,
    MAXIMO: 29.51441764831543,
    MEDIA: 29.49634838104248,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10d5009f-c7e3-472e-9165-020a716981e7',
    CIDADE: 'Pedrinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.692840576171875,
    MAXIMO: 39.95075225830078,
    MEDIA: 39.087081258989485,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65ecef03-ff31-4ca8-8beb-c7bcbd70a7bc',
    CIDADE: 'Pedro Leopoldo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.02306365966797,
    MAXIMO: 30.472272872924805,
    MEDIA: 30.238742397667096,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b81e034c-846f-4316-8735-289983c67326',
    CIDADE: 'Pedro Teixeira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.84111404418945,
    MAXIMO: 37.57796096801758,
    MEDIA: 37.436447867726116,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '007c2eb1-e758-423a-889f-3783c81f2915',
    CIDADE: 'Pequeri',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.745792388916016,
    MAXIMO: 39.77190399169922,
    MEDIA: 39.24289199453928,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7590a09-2c76-412b-9cc2-f5fae7b87bb2',
    CIDADE: 'Pequi',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.4060115814209,
    MAXIMO: 30.699113845825195,
    MEDIA: 30.606385607926466,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22582c5d-af22-441e-b489-2bf1e3e6d7f0',
    CIDADE: 'Perdigão',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.231002807617188,
    MAXIMO: 31.624282836914062,
    MEDIA: 31.419748223441296,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c5dd7de-1815-481f-aa00-c4a5e3c4c59b',
    CIDADE: 'Perdizes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.1104850769043,
    MAXIMO: 38.00343704223633,
    MEDIA: 37.04229518345424,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4115acee-8ead-4d3d-b1c6-7ce410246fbc',
    CIDADE: 'Perdões',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.24784469604492,
    MAXIMO: 33.72639846801758,
    MEDIA: 33.58900757322152,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4764d91-e41a-4877-9576-35d2ba07d468',
    CIDADE: 'Periquito',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.384780883789062,
    MAXIMO: 29.491479873657227,
    MEDIA: 29.442535291238876,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'be4da6a2-6c73-4a97-b34f-cf1228fc76e0',
    CIDADE: 'Pescador',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.19550895690918,
    MAXIMO: 29.22228240966797,
    MEDIA: 29.21229148637909,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f614c002-78cf-4fa9-b516-feeddd43fb3c',
    CIDADE: 'Piau',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.7841682434082,
    MAXIMO: 38.02883529663086,
    MEDIA: 37.201979001466775,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37e82681-e6ed-4d27-b6ea-63203954f9c5',
    CIDADE: 'Piedade de Caratinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.101028442382812,
    MAXIMO: 30.547222137451172,
    MEDIA: 30.39645161045588,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18c454aa-9058-407e-9912-7ee3578a2eaf',
    CIDADE: 'Piedade de Ponte Nova',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.043109893798828,
    MAXIMO: 31.923423767089844,
    MEDIA: 31.52774005603508,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '799bebd5-e664-4886-82ad-c032596adf9d',
    CIDADE: 'Piedade do Rio Grande',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.07582092285156,
    MAXIMO: 35.39678192138672,
    MEDIA: 35.23630142211914,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7599325-7716-4c83-83a3-2dfa9387b714',
    CIDADE: 'Piedade dos Gerais',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.419147491455078,
    MAXIMO: 32.2886962890625,
    MEDIA: 31.828472015597438,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22db1483-0eb7-4bc6-a43b-305aad27b80b',
    CIDADE: 'Pimenta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.149200439453125,
    MAXIMO: 33.343753814697266,
    MEDIA: 33.246477127075195,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c59a369-6a00-40e8-975d-57df523fea80',
    CIDADE: "Pingo-d'Água",
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.97899627685547,
    MAXIMO: 30.29734992980957,
    MEDIA: 30.179693294774097,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f64db107-632a-4743-9e5a-c439158aa26c',
    CIDADE: 'Pintópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.684213638305664,
    MAXIMO: 29.7637996673584,
    MEDIA: 29.72168388366699,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12e8599f-9d6e-4e1f-9ddc-1fbc0124059e',
    CIDADE: 'Piracema',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.871835708618164,
    MAXIMO: 32.33564376831055,
    MEDIA: 31.996183291324442,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66b1257a-036a-4bd1-a7c0-af3108353f44',
    CIDADE: 'Pirajuba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.04497146606445,
    MAXIMO: 37.114097595214844,
    MEDIA: 35.686850523543406,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2296447-b831-45e0-917a-debb54cb1699',
    CIDADE: 'Piranga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.55413818359375,
    MAXIMO: 32.746280670166016,
    MEDIA: 32.65020942687988,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42ec6f52-eb91-4916-8d59-e77d3c8d8077',
    CIDADE: 'Piranguçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.60205841064453,
    MAXIMO: 37.326637268066406,
    MEDIA: 37.296402103406635,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0c9e41b-831e-40e6-8df5-a93c89be0eeb',
    CIDADE: 'Piranguinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.10893249511719,
    MAXIMO: 38.09806442260742,
    MEDIA: 37.46566275777024,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0247f43e-7cf3-412e-8e0f-afdcdc7df2d9',
    CIDADE: 'Pirapetinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.89302444458008,
    MAXIMO: 42.177215576171875,
    MEDIA: 41.35583712290485,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0a1094f-4426-48bc-a633-d9a77f51afee',
    CIDADE: 'Pirapora',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.548158645629883,
    MAXIMO: 29.77102279663086,
    MEDIA: 29.642351893026387,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94166e72-5a5b-4414-a977-c1095ff106e7',
    CIDADE: 'Piraúba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.917110443115234,
    MAXIMO: 37.459503173828125,
    MEDIA: 37.0168503505391,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f1740f5-0b85-492e-9b23-9318dd30acc9',
    CIDADE: 'Pitangui',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.716938018798828,
    MAXIMO: 30.86182975769043,
    MEDIA: 30.78938388824463,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f01fefac-3820-4eaf-a161-5232241e25ee',
    CIDADE: 'Piumhi',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.34624481201172,
    MAXIMO: 33.58009338378906,
    MEDIA: 33.46316909790039,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b2bc823-6b8d-41ee-8b8e-a0c9d83dbfa2',
    CIDADE: 'Planura',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.04497146606445,
    MAXIMO: 36.262550354003906,
    MEDIA: 35.464943311565975,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '61b3c5e5-0446-4ace-a451-32cfe4d80641',
    CIDADE: 'Poço Fundo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.27698516845703,
    MAXIMO: 38.630550384521484,
    MEDIA: 37.92945376435384,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b0c87a5-a2ff-4c3a-ad01-6cc8f111caf2',
    CIDADE: 'Poços de Caldas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.633941650390625,
    MAXIMO: 41.2880973815918,
    MEDIA: 40.10855671155333,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '97b1b4b7-fe96-4c8b-bb30-c90c08a50c21',
    CIDADE: 'Pocrane',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.80160140991211,
    MAXIMO: 30.222837448120117,
    MEDIA: 30.01470883687337,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c0f9f92-1e79-4033-b370-75cb8b76d89c',
    CIDADE: 'Pompéu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.269142150878906,
    MAXIMO: 30.740415573120117,
    MEDIA: 30.45490002632141,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '852f4e73-5cf0-49f4-b6a8-bd465d98ea27',
    CIDADE: 'Ponte Nova',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.547513961791992,
    MAXIMO: 32.3278694152832,
    MEDIA: 31.937691688537598,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a687596-5cde-40ce-b1a6-0a032e206ec1',
    CIDADE: 'Ponto Chique',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.562286376953125,
    MAXIMO: 29.79620361328125,
    MEDIA: 29.668436232424565,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a29362e4-6edd-4366-80f2-5c19ba029fd2',
    CIDADE: 'Ponto dos Volantes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.240562438964844,
    MAXIMO: 29.32769775390625,
    MEDIA: 29.28486728668213,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cc6dd44f-ee43-458e-b280-e62ba1e78b94',
    CIDADE: 'Porteirinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.396770477294922,
    MAXIMO: 29.49833869934082,
    MEDIA: 29.440805435180664,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ccb11e0-265c-4d0e-8665-97797202e768',
    CIDADE: 'Porto Firme',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.746280670166016,
    MAXIMO: 34.335548400878906,
    MEDIA: 33.11351391149333,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b95cfe5a-7854-4b66-be3f-cb7613abfa4f',
    CIDADE: 'Poté',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.08026123046875,
    MAXIMO: 29.112951278686523,
    MEDIA: 29.095850552418163,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '60cf28ff-9b40-4735-a536-58ca7d640f2d',
    CIDADE: 'Pouso Alegre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.75644302368164,
    MAXIMO: 39.683326721191406,
    MEDIA: 39.21988487243652,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8fdc1df0-f4ca-4028-a964-7bd11647f77b',
    CIDADE: 'Pouso Alto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.052642822265625,
    MAXIMO: 35.44657897949219,
    MEDIA: 35.224748711306844,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a4f739a8-cbe2-4d2a-85e2-6dd57f6762b5',
    CIDADE: 'Prados',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.31322479248047,
    MAXIMO: 34.72945022583008,
    MEDIA: 33.90503530850937,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e66ca283-bb91-4a88-afaa-e6dc0133d9b7',
    CIDADE: 'Prata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.355140686035156,
    MAXIMO: 41.35753631591797,
    MEDIA: 38.168104508343866,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '800f00ba-716c-4e1c-b43f-1eee1355b4da',
    CIDADE: 'Pratápolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.657981872558594,
    MAXIMO: 35.5992317199707,
    MEDIA: 35.00355400585244,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1454eea-c17c-4e1c-addf-3d99c64479b8',
    CIDADE: 'Pratinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.52016830444336,
    MAXIMO: 33.56031036376953,
    MEDIA: 33.540239334106445,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8a86425-b46e-4a76-ab0e-6ea68e028072',
    CIDADE: 'Presidente Bernardes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.62935256958008,
    MAXIMO: 33.93638229370117,
    MEDIA: 33.782867431640625,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52c7b4ae-844a-43ad-a645-35fc765eb1f8',
    CIDADE: 'Presidente Juscelino',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.6027774810791,
    MAXIMO: 29.67372703552246,
    MEDIA: 29.63825225830078,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e9d2cca-5129-48ca-9f1c-ca17e00bdf8d',
    CIDADE: 'Presidente Kubitschek',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.35291862487793,
    MAXIMO: 29.46090316772461,
    MEDIA: 29.431694543129,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c6bd9d4-e742-4bc1-98b9-ef15e71685ca',
    CIDADE: 'Presidente Olegário',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.08216857910156,
    MAXIMO: 34.335609436035156,
    MEDIA: 32.89153734842936,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2cd1f4c-0a1a-4d41-afc0-33f87155edaa',
    CIDADE: 'Alto Jequitibá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.73340606689453,
    MAXIMO: 36.62651062011719,
    MEDIA: 35.485053755400486,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '262ee1eb-40c6-45b6-990e-25adf2368157',
    CIDADE: 'Prudente de Morais',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.96411895751953,
    MAXIMO: 30.198732376098633,
    MEDIA: 30.119844188791003,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '772650e1-3723-49d7-b8c4-b11a2d5580ed',
    CIDADE: 'Quartel Geral',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.167329788208008,
    MAXIMO: 31.443763732910156,
    MEDIA: 31.305546760559082,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '804e5d15-a85b-41f6-9c87-ad0c859a1a11',
    CIDADE: 'Queluzito',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.29513168334961,
    MAXIMO: 32.99774932861328,
    MEDIA: 32.703193188996856,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '290773f4-55ca-4959-a5be-c986e1fedd06',
    CIDADE: 'Raposos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.559635162353516,
    MAXIMO: 30.63295555114746,
    MEDIA: 30.5692448541042,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b29ebbc7-e9e7-42e0-ba30-c0f27fc0dc33',
    CIDADE: 'Raul Soares',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.738121032714844,
    MAXIMO: 31.34589195251465,
    MEDIA: 31.042006492614746,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '242382a1-b6f2-4a33-bf57-0a79950d03f8',
    CIDADE: 'Recreio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.535850524902344,
    MAXIMO: 41.65203857421875,
    MEDIA: 40.45553012902637,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35ad909e-aee0-4ab4-b78b-699e8c5d3838',
    CIDADE: 'Reduto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.180599212646484,
    MAXIMO: 33.73340606689453,
    MEDIA: 33.11126826261752,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '46f474e6-f2bf-4c01-9ddd-5e2b93204925',
    CIDADE: 'Resende Costa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.732635498046875,
    MAXIMO: 33.31322479248047,
    MEDIA: 32.93154525756836,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01c6b69c-d3e7-4996-9ef9-0db8914579cb',
    CIDADE: 'Resplendor',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.619455337524414,
    MAXIMO: 29.7992000579834,
    MEDIA: 29.704315662384033,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13e8f9ac-5e53-4775-b17d-ff4bcac186a7',
    CIDADE: 'Ressaquinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.7545051574707,
    MAXIMO: 34.795230865478516,
    MEDIA: 34.3938494825825,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a99c1817-58da-4ded-a0a2-eae119896cb0',
    CIDADE: 'Riachinho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.01947593688965,
    MAXIMO: 30.406982421875,
    MEDIA: 30.230795288085936,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8921939-a432-46c1-8683-f3e057e44df6',
    CIDADE: 'Riacho dos Machados',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.38371467590332,
    MAXIMO: 29.417612075805664,
    MEDIA: 29.395210647583006,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b86f1e7-9487-43e3-ba08-0334b117a24c',
    CIDADE: 'Ribeirão das Neves',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.25873565673828,
    MAXIMO: 30.56388282775879,
    MEDIA: 30.461672313952604,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a3314e2-124c-4d0c-9909-031fdfef43c4',
    CIDADE: 'Ribeirão Vermelho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.642822265625,
    MAXIMO: 33.72639846801758,
    MEDIA: 33.64460076869809,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '026b2e11-8136-444a-beca-722414227b8a',
    CIDADE: 'Rio Acima',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.559635162353516,
    MAXIMO: 30.930282592773438,
    MEDIA: 30.83802956099994,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '438470be-8ee9-49b5-a2f1-ecd5a655d1f3',
    CIDADE: 'Rio Casca',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.550745010375977,
    MAXIMO: 31.923423767089844,
    MEDIA: 31.136359141792816,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8e189b6-dc55-46c3-8070-4ee8ed047b37',
    CIDADE: 'Rio Doce',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.959413528442383,
    MAXIMO: 31.547513961791992,
    MEDIA: 31.197356410443163,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2f81ff4-a1e4-4079-a216-1887638f879a',
    CIDADE: 'Rio do Prado',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.4235897064209,
    MAXIMO: 29.491544723510742,
    MEDIA: 29.45867919921875,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '062e762f-92a5-47e1-9bd9-cc7b37ead66e',
    CIDADE: 'Rio Espera',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.17351150512695,
    MAXIMO: 34.24858093261719,
    MEDIA: 33.600044714270936,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2319398d-4ee8-4172-bf67-7e53d78dac5d',
    CIDADE: 'Rio Manso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.06976318359375,
    MAXIMO: 31.478979110717773,
    MEDIA: 31.394304717212815,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0f60c22e-503d-4abf-bebb-1f2b83957478',
    CIDADE: 'Rio Novo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.21334457397461,
    MAXIMO: 37.71757888793945,
    MEDIA: 37.46546173095703,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '501cbca5-c060-404c-b394-bf8bd01edbe8',
    CIDADE: 'Rio Paranaíba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.84931182861328,
    MAXIMO: 34.03384780883789,
    MEDIA: 33.35586318969727,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c5be86d-025b-45e5-9835-039798c2829e',
    CIDADE: 'Rio Pardo de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.390718460083008,
    MAXIMO: 29.585485458374023,
    MEDIA: 29.468714396158855,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ab6ffa3-2f45-4f00-9c9a-83d2cc48519e',
    CIDADE: 'Rio Piracicaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.16950225830078,
    MAXIMO: 30.852371215820312,
    MEDIA: 30.451931332975146,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '883c7a4a-6aeb-403f-8e3f-50aded66e4da',
    CIDADE: 'Rio Pomba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.38624954223633,
    MAXIMO: 36.85936737060547,
    MEDIA: 36.6228084564209,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42e45f7b-7ece-4038-9e93-7075f44c15ad',
    CIDADE: 'Rio Preto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.47632598876953,
    MAXIMO: 38.986446380615234,
    MEDIA: 38.43102458701886,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7857f1d4-132a-4ed3-9ebd-9630b68dbce8',
    CIDADE: 'Rio Vermelho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.230892181396484,
    MAXIMO: 29.26315689086914,
    MEDIA: 29.246557235717773,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '453ae00e-ac73-4022-b56b-3c64d96a2b20',
    CIDADE: 'Ritápolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.732635498046875,
    MAXIMO: 33.851318359375,
    MEDIA: 33.29327541027207,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2510ffef-402e-41e6-8782-a9d9d780020d',
    CIDADE: 'Rochedo de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.4815673828125,
    MAXIMO: 39.2028694152832,
    MEDIA: 38.564010043975586,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '497e0140-ffa9-447c-9c15-06f468f72ef3',
    CIDADE: 'Rodeiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.44099807739258,
    MAXIMO: 38.030479431152344,
    MEDIA: 37.23686824334035,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c2fab84-cbff-4835-9b3f-a1f953ccf472',
    CIDADE: 'Romaria',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.365867614746094,
    MAXIMO: 40.46854019165039,
    MEDIA: 39.91720390319824,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a9bd62d-d292-4b92-8a92-d458e9364e6d',
    CIDADE: 'Rosário da Limeira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.6593017578125,
    MAXIMO: 37.76079559326172,
    MEDIA: 37.01335302178801,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '533ffb69-99f3-4d10-a573-15158e9b351c',
    CIDADE: 'Rubelita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.357505798339844,
    MAXIMO: 29.369665145874023,
    MEDIA: 29.363195419311523,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1db5fbac-25f6-4093-96a8-c5487e289a9b',
    CIDADE: 'Rubim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.5211238861084,
    MAXIMO: 29.654565811157227,
    MEDIA: 29.585580190022785,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82d04cdc-73d2-410a-9a55-0e086f66cfd0',
    CIDADE: 'Sabará',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.099794387817383,
    MAXIMO: 30.63295555114746,
    MEDIA: 30.38103568900318,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4678611c-2976-40d6-ae8d-c7ab8ab87de3',
    CIDADE: 'Sabinópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.261001586914062,
    MAXIMO: 29.410505294799805,
    MEDIA: 29.354089736585554,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '972e6a6e-a52f-489a-8c44-e00de8ac1313',
    CIDADE: 'Sacramento',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.59402847290039,
    MAXIMO: 37.28700256347656,
    MEDIA: 35.77257876925998,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77160c3d-dd7f-4570-a566-22fd940b7daf',
    CIDADE: 'Salinas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.36130142211914,
    MAXIMO: 29.45430564880371,
    MEDIA: 29.405030250549316,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6cfbb44-b9ae-4fde-a3f2-0c254b22fd2c',
    CIDADE: 'Salto da Divisa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.741811752319336,
    MAXIMO: 29.827693939208984,
    MEDIA: 29.79005495707194,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af6dd9e2-1dfd-421b-9356-dab701229782',
    CIDADE: 'Santa Bárbara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.469877243041992,
    MAXIMO: 30.840335845947266,
    MEDIA: 30.595279057820637,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c278b74-ede6-4f83-9a06-5a292e36bcde',
    CIDADE: 'Santa Bárbara do Leste',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.491039276123047,
    MAXIMO: 31.18048858642578,
    MEDIA: 31.013895589627978,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9865c5e-dc7c-4752-b529-c59abbb17d6d',
    CIDADE: 'Santa Bárbara do Monte Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.57796096801758,
    MAXIMO: 39.278892517089844,
    MEDIA: 38.48128742317326,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4f59b5c-c1f2-4141-812c-83d66eeb7222',
    CIDADE: 'Santa Bárbara do Tugúrio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.795230865478516,
    MAXIMO: 35.97340393066406,
    MEDIA: 35.58967432427711,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39abff4d-6c81-4e6e-92bf-5408c1e4f6a6',
    CIDADE: 'Santa Cruz de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.851318359375,
    MAXIMO: 33.851318359375,
    MEDIA: 33.851318359375,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a8b65a38-25c1-477a-ba9b-97157b426712',
    CIDADE: 'Santa Cruz de Salinas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.39763832092285,
    MAXIMO: 29.551727294921875,
    MEDIA: 29.478841301898406,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e3a9fd0-05f4-422a-8426-5482efcc476c',
    CIDADE: 'Santa Cruz do Escalvado',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.959413528442383,
    MAXIMO: 31.707048416137695,
    MEDIA: 31.343995379911497,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49af519f-dcd4-4861-be44-2d6f56f2a707',
    CIDADE: 'Santa Efigênia de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.3134765625,
    MAXIMO: 29.39130401611328,
    MEDIA: 29.324022746857953,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cda1b790-1772-4dbb-8b57-55dcdceefb77',
    CIDADE: 'Santa Fé de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.79620361328125,
    MAXIMO: 30.49250602722168,
    MEDIA: 30.098002751668293,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '91dfb386-f056-426a-8151-483be2d55806',
    CIDADE: 'Santa Helena de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.29971694946289,
    MAXIMO: 29.4235897064209,
    MEDIA: 29.367280426509172,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da57885b-8801-4839-9467-fcc56c9e8846',
    CIDADE: 'Santa Juliana',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.869041442871094,
    MAXIMO: 38.449031829833984,
    MEDIA: 38.15903663635254,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfade11e-02d7-4f24-9474-27a6e537c441',
    CIDADE: 'Santa Luzia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.099794387817383,
    MAXIMO: 30.381145477294922,
    MEDIA: 30.271844906944953,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d710c01-59ef-49ea-b8bf-594d65f161c0',
    CIDADE: 'Santa Margarida',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.23147201538086,
    MAXIMO: 35.567691802978516,
    MEDIA: 33.67466203259302,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc047653-7bf4-4bcd-ac36-86cfc43608c1',
    CIDADE: 'Santa Maria de Itabira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.74072265625,
    MAXIMO: 29.761234283447266,
    MEDIA: 29.750978469848633,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2852dd10-3788-4525-9f47-13a922f1d22c',
    CIDADE: 'Santa Maria do Salto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.640466690063477,
    MAXIMO: 29.715810775756836,
    MEDIA: 29.678138732910156,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8fc330d-b1f1-4c56-ac23-6a1902309655',
    CIDADE: 'Santa Maria do Suaçuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.143634796142578,
    MAXIMO: 29.154376983642578,
    MEDIA: 29.149005889892578,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7dffd42f-3870-4d57-b874-49a8ba3d341e',
    CIDADE: 'Santana da Vargem',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.091331481933594,
    MAXIMO: 34.916133880615234,
    MEDIA: 34.564958194596656,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4772652b-1286-432d-bf4a-cdfde33cd078',
    CIDADE: 'Santana de Cataguases',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.76079559326172,
    MAXIMO: 39.64208984375,
    MEDIA: 38.96970027100217,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c31ca07-eb4d-4f56-bf5d-131f391075bf',
    CIDADE: 'Santana de Pirapama',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.514673233032227,
    MAXIMO: 29.758329391479492,
    MEDIA: 29.638062953948975,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20dd393b-582f-4e14-98b4-4310a8d65af6',
    CIDADE: 'Santana do Deserto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.745792388916016,
    MAXIMO: 40.25653076171875,
    MEDIA: 39.50912907958192,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ce133ea-d7e1-4b2e-87de-7c7f64300b02',
    CIDADE: 'Santana do Garambéu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.72896194458008,
    MAXIMO: 36.77390670776367,
    MEDIA: 36.14374842271352,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2689b0cf-27f8-4572-b8bd-37276728b597',
    CIDADE: 'Santana do Jacaré',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.89241409301758,
    MAXIMO: 33.35071563720703,
    MEDIA: 33.10544892627158,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3335293-2390-4baa-9bdb-a20e83e4d1f5',
    CIDADE: 'Santana do Manhuaçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.18048858642578,
    MAXIMO: 32.56098556518555,
    MEDIA: 31.682230943910636,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78bda72a-8b23-435d-8e1e-5b7fbfe9cdae',
    CIDADE: 'Santana do Paraíso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.58746337890625,
    MAXIMO: 29.7691593170166,
    MEDIA: 29.64854309816542,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af70da92-179b-40d8-893d-be9fd99eb3d4',
    CIDADE: 'Santana do Riacho',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.634485244750977,
    MAXIMO: 29.73949432373047,
    MEDIA: 29.69198989868164,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3849bcbb-bfcb-4c40-9345-dcf0412a76a8',
    CIDADE: 'Santana dos Montes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.99774932861328,
    MAXIMO: 33.17351150512695,
    MEDIA: 33.064260410001374,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7e23191-775d-42b6-86ad-783b7d46d1fe',
    CIDADE: 'Santa Rita de Caldas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.3592414855957,
    MAXIMO: 40.818382263183594,
    MEDIA: 40.08881187438965,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f652a501-ab7a-4fc9-acc2-d6f70ddeee6e',
    CIDADE: 'Santa Rita de Jacutinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.97910690307617,
    MAXIMO: 38.94171905517578,
    MEDIA: 38.07679165225464,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf481c83-3571-471b-9409-a1f0b50eee6b',
    CIDADE: 'Santa Rita de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.491039276123047,
    MAXIMO: 31.036279678344727,
    MEDIA: 30.580396937931347,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a79aeb99-0a16-4fec-80cd-aefeafc0dcf0',
    CIDADE: 'Santa Rita de Ibitipoca',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.39678192138672,
    MAXIMO: 36.84111404418945,
    MEDIA: 36.275583395728994,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f63c75e-82f9-4e52-9160-12c9c01ee443',
    CIDADE: 'Santa Rita do Itueto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.906288146972656,
    MAXIMO: 30.058719635009766,
    MEDIA: 29.98250389099121,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ca651d20-defc-44a0-95b0-ac59e3f375ec',
    CIDADE: 'Santa Rita do Sapucaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.84628677368164,
    MAXIMO: 38.75644302368164,
    MEDIA: 37.845070961325995,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dda18457-42d4-40fe-9059-b8d4ea6840f3',
    CIDADE: 'Santa Rosa da Serra',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.2341194152832,
    MAXIMO: 33.11668395996094,
    MEDIA: 32.61787342598987,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '312552b2-d885-44c4-b1df-6a4dbe0b5b67',
    CIDADE: 'Santa Vitória',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.95897674560547,
    MAXIMO: 35.681827545166016,
    MEDIA: 35.1845157623291,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b0d1c51-2578-4f01-a72d-a3d492efbd9b',
    CIDADE: 'Santo Antônio do Amparo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.192970275878906,
    MAXIMO: 33.24784469604492,
    MEDIA: 33.220407485961914,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '616d35f4-edec-4674-b6b3-0d7708fd8130',
    CIDADE: 'Santo Antônio do Aventureiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.58313751220703,
    MAXIMO: 40.189701080322266,
    MEDIA: 39.94727367256068,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '91e0ae58-dfe9-4a81-b0d7-9c845b655bd4',
    CIDADE: 'Santo Antônio do Grama',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.707048416137695,
    MAXIMO: 31.923423767089844,
    MEDIA: 31.919877893400262,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f53ee629-4f58-4b94-b363-7b42fb052d29',
    CIDADE: 'Santo Antônio do Itambé',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.263004302978516,
    MAXIMO: 29.378232955932617,
    MEDIA: 29.331609617223034,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '507b6844-1c3b-45b2-9a35-2f90cedef266',
    CIDADE: 'Santo Antônio do Jacinto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.47592544555664,
    MAXIMO: 29.640466690063477,
    MEDIA: 29.574577927167134,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98e159a2-dbbf-4fea-a0ef-b584b1b322d7',
    CIDADE: 'Santo Antônio do Monte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.843490600585938,
    MAXIMO: 32.285423278808594,
    MEDIA: 32.01906871795654,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a833032-eb71-41b7-b8ce-d7c3e5d68075',
    CIDADE: 'Santo Antônio do Retiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.555248260498047,
    MAXIMO: 30.248201370239258,
    MEDIA: 29.8692200978597,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90c7e2b5-d025-449b-9390-b4cbf71e3cb1',
    CIDADE: 'Santo Antônio do Rio Abaixo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.539226531982422,
    MAXIMO: 29.659698486328125,
    MEDIA: 29.590020391005435,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f39fa78b-cf77-4ee9-a0e5-13f76d47054b',
    CIDADE: 'Santo Hipólito',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.544090270996094,
    MAXIMO: 29.54425811767578,
    MEDIA: 29.544174194335938,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53097903-24ed-4987-9d4c-7b248e0158ae',
    CIDADE: 'Santos Dumont',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.41107177734375,
    MAXIMO: 36.7841682434082,
    MEDIA: 36.59762001037598,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4729bf06-6454-40b6-98de-6d33314460bb',
    CIDADE: 'São Bento Abade',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.390380859375,
    MAXIMO: 34.72140884399414,
    MEDIA: 34.666597007388894,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eee8c0c0-69da-43fc-967c-99e0bb96bf87',
    CIDADE: 'São Brás do Suaçuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.25432586669922,
    MAXIMO: 32.29513168334961,
    MEDIA: 32.27685472656136,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e30c7b4-26c3-4574-8cea-a97f79777a58',
    CIDADE: 'São Domingos das Dores',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.82094955444336,
    MAXIMO: 29.822473526000977,
    MEDIA: 29.82190396445077,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c37bf126-ed34-4971-887f-71f938390fed',
    CIDADE: 'São Domingos do Prata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.177303314208984,
    MAXIMO: 30.550745010375977,
    MEDIA: 30.41439946492513,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5edb3ff0-43be-4dd3-ae35-9babe69d8543',
    CIDADE: 'São Félix de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.19550895690918,
    MAXIMO: 29.298675537109375,
    MEDIA: 29.2256609596012,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b0c3e00-c7fb-422c-a5e8-86f8cb4dca38',
    CIDADE: 'São Francisco',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.41726303100586,
    MAXIMO: 29.689056396484375,
    MEDIA: 29.580982367197674,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '141514bc-4b7f-4b76-ad8d-369f8ddf1727',
    CIDADE: 'São Francisco de Paula',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.562015533447266,
    MAXIMO: 32.89241409301758,
    MEDIA: 32.72721481323242,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f34aa65-b6ca-4433-9d9b-4e920e7be48e',
    CIDADE: 'São Francisco de Sales',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.00161361694336,
    MAXIMO: 35.33974075317383,
    MEDIA: 35.16016642252604,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe0e102b-c593-4f5d-b56b-a1483df7e3af',
    CIDADE: 'São Francisco do Glória',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.567691802978516,
    MAXIMO: 37.046817779541016,
    MEDIA: 37.01692079225575,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0dad9e11-785b-412c-9522-6b1e81f16d25',
    CIDADE: 'São Geraldo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.335548400878906,
    MAXIMO: 35.98847579956055,
    MEDIA: 35.58504326140332,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28d23238-d471-4ff4-b633-cb6b37e2acdd',
    CIDADE: 'São Geraldo da Piedade',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.3134765625,
    MAXIMO: 29.39130401611328,
    MEDIA: 29.33809606946925,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ce5e8bcc-a502-42b8-9410-47ef22f6557b',
    CIDADE: 'São Geraldo do Baixio',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.365575790405273,
    MAXIMO: 29.488353729248047,
    MEDIA: 29.418612607544965,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd03c136f-de39-4a1d-8009-d793e04c1a59',
    CIDADE: 'São Gonçalo do Abaeté',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.037433624267578,
    MAXIMO: 31.295364379882812,
    MEDIA: 30.579933166503906,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6309d731-7005-4985-991d-a71f5aacc38a',
    CIDADE: 'São Gonçalo do Pará',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.0382022857666,
    MAXIMO: 31.55558204650879,
    MEDIA: 31.261630167746606,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a13f6fa2-717e-4d05-b605-472a42d7ee03',
    CIDADE: 'São Gonçalo do Rio Abaixo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.940696716308594,
    MAXIMO: 30.475624084472656,
    MEDIA: 30.153363134420264,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '696c1e86-268b-43c6-ae31-3b96fbf6468f',
    CIDADE: 'São Gonçalo do Sapucaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.50172805786133,
    MAXIMO: 37.16448211669922,
    MEDIA: 36.83310508728027,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87cf297e-f557-44f1-9a64-34ff342208b7',
    CIDADE: 'São Gotardo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.102867126464844,
    MAXIMO: 32.600643157958984,
    MEDIA: 32.39939626057943,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b6035e8-6dd5-424e-861a-4771d5d0ba16',
    CIDADE: 'São João Batista do Glória',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.967803955078125,
    MAXIMO: 34.22793197631836,
    MEDIA: 34.09786796569824,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78869f47-82fe-4ebb-9159-5fd3d881d905',
    CIDADE: 'São João da Lagoa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.32855987548828,
    MAXIMO: 29.461925506591797,
    MEDIA: 29.4018292427063,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '009466d7-5faa-4747-bb05-59bd45752e01',
    CIDADE: 'São João da Mata',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.879638671875,
    MAXIMO: 38.630550384521484,
    MEDIA: 38.166642048773134,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9182860f-f517-4225-99a9-9558e6e10235',
    CIDADE: 'São João da Ponte',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.2863712310791,
    MAXIMO: 29.438425064086914,
    MEDIA: 29.374635696411133,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd086f8a1-03a6-426c-9e69-4c0a246d46c3',
    CIDADE: 'São João das Missões',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.685382843017578,
    MAXIMO: 29.740537643432617,
    MEDIA: 29.708951314290363,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e59d1e7f-0ca5-4f26-b010-c5a5be8d4715',
    CIDADE: 'São João del Rei',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.73591613769531,
    MAXIMO: 34.72945022583008,
    MEDIA: 34.29460525512695,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9c39b18f-9a68-4742-bf95-1b5fb8734b0d',
    CIDADE: 'São João do Manhuaçu',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.6292724609375,
    MAXIMO: 34.7639045715332,
    MEDIA: 33.64755876138006,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56881b4f-ba5f-4a41-9d5f-75f71df4b5b9',
    CIDADE: 'São João do Manteninha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.31907081604004,
    MAXIMO: 29.41803741455078,
    MEDIA: 29.375187338988347,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14286d41-1e2c-48e2-8219-c607640df031',
    CIDADE: 'São João do Oriente',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.62070655822754,
    MAXIMO: 29.822473526000977,
    MEDIA: 29.643020394036306,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b70de0b6-0461-426b-bec0-3d23015db711',
    CIDADE: 'São João do Pacuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.386991500854492,
    MAXIMO: 29.487558364868164,
    MEDIA: 29.427947572106852,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8049c0e4-dee0-46fd-9f6f-25733b3b80db',
    CIDADE: 'São João do Paraíso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.601036071777344,
    MAXIMO: 30.905698776245117,
    MEDIA: 30.02707176208496,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89172ad7-511b-4a2a-99a9-d4c5dc4450ad',
    CIDADE: 'São João Evangelista',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.20372200012207,
    MAXIMO: 29.23332405090332,
    MEDIA: 29.218523025512695,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a4747ffa-71be-4349-9e25-4cbd006dceb2',
    CIDADE: 'São João Nepomuceno',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.4815673828125,
    MAXIMO: 39.01167678833008,
    MEDIA: 38.74662208557129,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15823596-a3a9-491e-ad39-ea6e387daf0a',
    CIDADE: 'São Joaquim de Bicas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.795007705688477,
    MAXIMO: 31.06976318359375,
    MEDIA: 30.958711950759504,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d6f0e87-d7ec-499c-b14a-c317124071b9',
    CIDADE: 'São José da Barra',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.768314361572266,
    MAXIMO: 34.24967575073242,
    MEDIA: 34.04456373584361,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52e6998e-9e8e-4bc6-a16a-cb5faa328cae',
    CIDADE: 'São José da Lapa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.188066482543945,
    MAXIMO: 30.25873565673828,
    MEDIA: 30.234668193198456,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '390340f8-1d4d-4ac2-a811-326c35fb25aa',
    CIDADE: 'São José da Safira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.134544372558594,
    MAXIMO: 29.17667579650879,
    MEDIA: 29.147346287230167,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6a96c5b-50da-45e4-ae67-f833424e46b6',
    CIDADE: 'São José da Varginha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.59090232849121,
    MAXIMO: 30.786531448364258,
    MEDIA: 30.633068487067725,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59cfa868-685e-4bc5-b0df-2fc79c3666f1',
    CIDADE: 'São José do Alegre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.42680358886719,
    MAXIMO: 37.281673431396484,
    MEDIA: 37.155274256282155,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7381b74f-6f33-4bdc-a54b-f68354019bbc',
    CIDADE: 'São José do Divino',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.204071044921875,
    MAXIMO: 29.273954391479492,
    MEDIA: 29.22789419105061,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0753b692-c3dc-4887-a3e9-7baeeab94977',
    CIDADE: 'São José do Goiabal',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.167335510253906,
    MAXIMO: 30.617351531982422,
    MEDIA: 30.477196704437876,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a94e6b0e-d046-4853-84ec-742ba02ef825',
    CIDADE: 'São José do Jacuri',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.150278091430664,
    MAXIMO: 29.20372200012207,
    MEDIA: 29.17738190970791,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8d47fe8-7dcd-442b-9016-caa7c5b568c2',
    CIDADE: 'São José do Mantimento',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.32077980041504,
    MAXIMO: 32.56098556518555,
    MEDIA: 31.32532019394471,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22a78bdf-c193-4970-9562-1501cffd65ce',
    CIDADE: 'São Lourenço',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.20684814453125,
    MAXIMO: 35.20684814453125,
    MEDIA: 35.20684814453125,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25647e75-2f45-4672-8999-a25a3e6caba1',
    CIDADE: 'São Miguel do Anta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.68172836303711,
    MAXIMO: 35.43781661987305,
    MEDIA: 34.417364398401524,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c24d5aac-8501-421b-84c8-92651871eef1',
    CIDADE: 'São Pedro da União',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.323577880859375,
    MAXIMO: 36.38322067260742,
    MEDIA: 35.98317274221055,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f232a2b0-4ba5-4911-aec4-6f8b9739fa62',
    CIDADE: 'São Pedro dos Ferros',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.617351531982422,
    MAXIMO: 31.166019439697266,
    MEDIA: 30.891685485839844,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47d4a9f5-b807-4280-89b4-6221b106716a',
    CIDADE: 'São Pedro do Suaçuí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.143634796142578,
    MAXIMO: 29.23332405090332,
    MEDIA: 29.180793897906927,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '838674a7-0716-4e03-a255-6d5135380d87',
    CIDADE: 'São Romão',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.78139305114746,
    MAXIMO: 30.187204360961914,
    MEDIA: 29.981844266255695,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd68804f-bdc1-494e-8283-d7201c5e520d',
    CIDADE: 'São Roque de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.4461669921875,
    MAXIMO: 34.26261520385742,
    MEDIA: 33.80420430501302,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ae6723d-e962-432b-8ddb-5200d75d3dd4',
    CIDADE: 'São Sebastião da Bela Vista',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.57450485229492,
    MAXIMO: 38.75644302368164,
    MEDIA: 38.09557402259823,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a72b5ee-53ae-4243-94a5-f26594e39245',
    CIDADE: 'São Sebastião da Vargem Alegre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.6593017578125,
    MAXIMO: 37.76079559326172,
    MEDIA: 37.027173351009196,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49a2063d-ca0b-46b6-81fc-15f3c5c7550a',
    CIDADE: 'São Sebastião do Anta',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.82094955444336,
    MAXIMO: 29.82094955444336,
    MEDIA: 29.82094955444336,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '279024bd-036b-4eb2-b02d-210d4d8c2d49',
    CIDADE: 'São Sebastião do Maranhão',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.134809494018555,
    MAXIMO: 29.150278091430664,
    MEDIA: 29.14254379272461,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9ed9b7d-4de1-4897-9185-31cc97cf4500',
    CIDADE: 'São Sebastião do Oeste',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.55558204650879,
    MAXIMO: 32.114288330078125,
    MEDIA: 31.858756220663285,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16469acb-a5da-4e67-a05a-e9e9ed0e9a9b',
    CIDADE: 'São Sebastião do Paraíso',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.267860412597656,
    MAXIMO: 36.09072494506836,
    MEDIA: 35.704877853393555,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4acca68d-0a12-4770-8e04-f998b18229ae',
    CIDADE: 'São Sebastião do Rio Preto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.498687744140625,
    MAXIMO: 29.68816566467285,
    MEDIA: 29.651382696562674,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48c01e8e-00a3-4087-aa12-a4bf85df529f',
    CIDADE: 'São Sebastião do Rio Verde',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.20684814453125,
    MAXIMO: 35.44657897949219,
    MEDIA: 35.40949203887759,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b318ecc-bb3f-4b57-886d-5b04da37c5ed',
    CIDADE: 'São Tiago',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.729103088378906,
    MAXIMO: 33.629486083984375,
    MEDIA: 33.11034828743292,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '652eaf81-476a-4997-b13e-1dd1487e9e62',
    CIDADE: 'São Tomás de Aquino',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.94481658935547,
    MAXIMO: 36.09072494506836,
    MEDIA: 35.46909408973338,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8aeb4e1-0080-4152-8b20-e239e91dd349',
    CIDADE: 'São Tomé das Letras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.68731689453125,
    MAXIMO: 34.97422409057617,
    MEDIA: 34.87703916645472,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd5f82ebe-0cbe-4657-86e0-0c3182a528e9',
    CIDADE: 'São Vicente de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.55313491821289,
    MAXIMO: 35.88521194458008,
    MEDIA: 35.31603875443731,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e95f7044-04d3-4980-af6d-03f8cfdd53b0',
    CIDADE: 'Sapucaí-Mirim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.76216506958008,
    MAXIMO: 39.92103576660156,
    MEDIA: 38.88587151630036,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17269cbe-7fbd-4fcb-89a7-541ee0b63d6b',
    CIDADE: 'Sardoá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.262802124023438,
    MAXIMO: 29.323347091674805,
    MEDIA: 29.312568009554983,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dcbdecbb-9b35-4916-8055-6b73d400a216',
    CIDADE: 'Sarzedo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.701614379882812,
    MAXIMO: 31.06976318359375,
    MEDIA: 30.854582535495084,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dac1bb06-1f5b-4848-ab1d-fe1d1fdc8f60',
    CIDADE: 'Setubinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.0854549407959,
    MAXIMO: 29.132877349853516,
    MEDIA: 29.094834280373014,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c6bfd82-e945-4598-b9fa-da1453acdcfc',
    CIDADE: 'Sem-Peixe',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.51515007019043,
    MAXIMO: 31.043109893798828,
    MEDIA: 30.840098250687312,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eead1981-4ca9-48a8-9a36-1ac4a4c45287',
    CIDADE: 'Senador Amaral',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.85018539428711,
    MAXIMO: 41.836273193359375,
    MEDIA: 41.10295147989231,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0f834218-9894-4038-aaa1-07f3fd753873',
    CIDADE: 'Senador Cortes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.01167678833008,
    MAXIMO: 39.662174224853516,
    MEDIA: 39.658503883019605,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b2473eab-6e88-4bdd-934e-9bb4eb5742d0',
    CIDADE: 'Senador Firmino',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.93638229370117,
    MAXIMO: 34.955345153808594,
    MEDIA: 34.671734060658395,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9534a78-88e4-4cfc-abaf-bcf4c084c9c0',
    CIDADE: 'Senador José Bento',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.25932693481445,
    MAXIMO: 40.57164001464844,
    MEDIA: 40.20978075369628,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a6eaa6b-14e1-449a-9a31-bf33a1d21a3d',
    CIDADE: 'Senador Modestino Gonçalves',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.138137817382812,
    MAXIMO: 29.201576232910156,
    MEDIA: 29.16303825378418,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dbf7836-9cdb-4210-b81c-5080b5c7f93f',
    CIDADE: 'Senhora de Oliveira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.38236999511719,
    MAXIMO: 34.566436767578125,
    MEDIA: 33.45687669675488,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b483c82-d5ec-48c8-9c2f-32e05155b2ee',
    CIDADE: 'Senhora do Porto',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.390932083129883,
    MAXIMO: 29.445663452148438,
    MEDIA: 29.41829776763916,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0bab2efb-dc4f-4ba2-b5dc-e62a27bd8a22',
    CIDADE: 'Senhora dos Remédios',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.00690841674805,
    MAXIMO: 34.795230865478516,
    MEDIA: 34.40106964111328,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31411e88-fb09-4772-8c8d-0e91a6b58d35',
    CIDADE: 'Sericita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.95197296142578,
    MAXIMO: 34.80709457397461,
    MEDIA: 33.471321248239,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ceb71665-0037-42ca-8387-a2f48412f657',
    CIDADE: 'Seritinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.853511810302734,
    MAXIMO: 36.41740798950195,
    MEDIA: 36.135459899902344,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10985ada-0bda-49d3-9f76-180038fcef03',
    CIDADE: 'Serra Azul de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.263004302978516,
    MAXIMO: 29.332616806030273,
    MEDIA: 29.299623782406275,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9be4079-ec09-4f54-845d-c9c52402563f',
    CIDADE: 'Serra da Saudade',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.747072219848633,
    MAXIMO: 32.2341194152832,
    MEDIA: 31.97382633770152,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6656a267-e0db-47d8-ab5e-9a7eee122b6c',
    CIDADE: 'Serra dos Aimorés',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.305253982543945,
    MAXIMO: 29.338333129882812,
    MEDIA: 29.312515395258576,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66b97cdd-72f7-40e1-ac6d-b11e5dacda53',
    CIDADE: 'Serra do Salitre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.990989685058594,
    MAXIMO: 35.442047119140625,
    MEDIA: 34.72809982299805,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42d527f2-3ac5-47d5-8b36-9476b619915b',
    CIDADE: 'Serrania',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.297035217285156,
    MAXIMO: 37.61656188964844,
    MEDIA: 37.10100779312792,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17239d59-c26a-4dad-848c-0a547ac1e0a1',
    CIDADE: 'Serranópolis de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.39056968688965,
    MAXIMO: 29.43999671936035,
    MEDIA: 29.409764883570507,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10e2562a-1307-4ca5-b304-9f22b1d72466',
    CIDADE: 'Serranos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.09291458129883,
    MAXIMO: 36.41740798950195,
    MEDIA: 35.59536507785051,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e6b65c6-885c-4107-a176-cbb8189466ef',
    CIDADE: 'Serro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.263004302978516,
    MAXIMO: 29.400495529174805,
    MEDIA: 29.351588439941406,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a61dacb-76be-4e0a-9447-b5ebb6f107d0',
    CIDADE: 'Sete Lagoas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.04709243774414,
    MAXIMO: 30.198732376098633,
    MEDIA: 30.122912406921387,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c92cad8c-41e5-4e94-825e-476e7a85836c',
    CIDADE: 'Silveirânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.473880767822266,
    MAXIMO: 36.38624954223633,
    MEDIA: 35.57720438432815,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c190cd5a-8b10-4957-a756-97981abfd072',
    CIDADE: 'Silvianópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.16448211669922,
    MAXIMO: 38.3913688659668,
    MEDIA: 37.94552249574355,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '097b8a1b-6bda-4f36-92fd-54249a4f7a66',
    CIDADE: 'Simão Pereira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.00911331176758,
    MAXIMO: 39.93659591674805,
    MEDIA: 39.310941738769934,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '959b0ab6-7c80-4de8-8c76-93934226f290',
    CIDADE: 'Simonésia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.036279678344727,
    MAXIMO: 31.18048858642578,
    MEDIA: 31.108384132385254,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a4bcbf0-d885-41e4-ab47-5733d0917b82',
    CIDADE: 'Sobrália',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.490798950195312,
    MAXIMO: 29.630014419555664,
    MEDIA: 29.533259675007926,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b5fbeb3-5e00-4c00-b4be-3f935d4978c9',
    CIDADE: 'Soledade de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.05034637451172,
    MAXIMO: 35.64815139770508,
    MEDIA: 35.173033494945805,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc424224-78df-45c9-b4bb-9c030de2ed37',
    CIDADE: 'Tabuleiro',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.97340393066406,
    MAXIMO: 37.71757888793945,
    MEDIA: 36.74280668050114,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9112947-0525-4fcb-b8b5-e40c5a4c10c9',
    CIDADE: 'Taiobeiras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.458385467529297,
    MAXIMO: 29.55304718017578,
    MEDIA: 29.50047836303711,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a76efa9-727e-4eed-b3a5-b496aa6cbd71',
    CIDADE: 'Taparuba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.01968765258789,
    MAXIMO: 30.630661010742188,
    MEDIA: 30.31313602219394,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8ad677e-f90c-4889-a55a-8f259bcd5312',
    CIDADE: 'Tapira',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.324092864990234,
    MAXIMO: 35.3176155090332,
    MEDIA: 34.8495231628418,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86ee6bf8-da4f-4e97-93b7-ec2d5ec00737',
    CIDADE: 'Tapiraí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.75510787963867,
    MAXIMO: 33.55066680908203,
    MEDIA: 33.11149875560024,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ece9e8e-63a8-4027-ac8c-b2fe321e2028',
    CIDADE: 'Taquaraçu de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.862716674804688,
    MAXIMO: 30.30259895324707,
    MEDIA: 30.05230663892442,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7869d03-739a-4138-879c-68089fbfb564',
    CIDADE: 'Tarumirim',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.488019943237305,
    MAXIMO: 29.630014419555664,
    MEDIA: 29.59133243560791,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f96ff744-1b13-48be-95e8-a69672f71bd6',
    CIDADE: 'Teixeiras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.279991149902344,
    MAXIMO: 33.68172836303711,
    MEDIA: 33.392307618529095,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '607b1ea4-d142-442a-8636-4199640db632',
    CIDADE: 'Teófilo Otoni',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.10533905029297,
    MAXIMO: 29.18569564819336,
    MEDIA: 29.140079845081676,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fada8ffa-4632-4a6b-bc16-76d4561dccd0',
    CIDADE: 'Timóteo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.723064422607422,
    MAXIMO: 29.907873153686523,
    MEDIA: 29.779184886647172,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20ec60bc-bdcd-499f-9263-11c5da76d112',
    CIDADE: 'Tiradentes',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.851318359375,
    MAXIMO: 34.027793884277344,
    MEDIA: 33.92005629370438,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3091ad8a-f264-4ec0-bc77-40151e1d17b6',
    CIDADE: 'Tiros',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.06501007080078,
    MAXIMO: 32.195343017578125,
    MEDIA: 31.53049305507115,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfdd8d36-ba53-4a1b-9df7-614f83db74fc',
    CIDADE: 'Tocantins',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.917110443115234,
    MAXIMO: 37.459503173828125,
    MEDIA: 36.31229653362812,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50c9fec3-7c73-47ab-afa1-4a91f8876f74',
    CIDADE: 'Tocos do Moji',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.683326721191406,
    MAXIMO: 40.85018539428711,
    MEDIA: 40.58446030392451,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc2467a7-dc63-4760-b274-3b58afed6757',
    CIDADE: 'Toledo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 41.62593078613281,
    MAXIMO: 42.82511520385742,
    MEDIA: 41.85383448554324,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c58cc559-c883-453c-89c1-c77f9e180125',
    CIDADE: 'Tombos',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.046817779541016,
    MAXIMO: 40.58824157714844,
    MEDIA: 39.12546620525513,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4a45f89-1454-449e-97b1-a16c6579722f',
    CIDADE: 'Três Corações',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.928104400634766,
    MAXIMO: 35.22475051879883,
    MEDIA: 35.0764274597168,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db3f1d68-1d4b-4fc7-8222-6085f9516313',
    CIDADE: 'Três Marias',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.85944175720215,
    MAXIMO: 30.30539321899414,
    MEDIA: 30.0446965959337,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10ed726f-0d60-4fcc-b1f3-9f38ff8d4e55',
    CIDADE: 'Três Pontas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.8194465637207,
    MAXIMO: 35.195396423339844,
    MEDIA: 35.00742149353027,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '584c9d04-8f23-4369-90f7-117ec7d62e4a',
    CIDADE: 'Tumiritinga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.324798583984375,
    MAXIMO: 29.518491744995117,
    MEDIA: 29.421401225064887,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6dc2a498-1f97-4d15-a957-47ca91928258',
    CIDADE: 'Tupaciguara',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 39.991249084472656,
    MAXIMO: 42.896488189697266,
    MEDIA: 41.38907559712728,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c207b667-433c-4597-95c9-d244dde8dc84',
    CIDADE: 'Turmalina',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.06583595275879,
    MAXIMO: 29.098390579223633,
    MEDIA: 29.08226267496745,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b4cf682-0ed2-4a48-811a-ae39e9495189',
    CIDADE: 'Turvolândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.66914749145508,
    MAXIMO: 37.879638671875,
    MEDIA: 37.49114637649779,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7acdd03-2789-41f4-8d49-508b596a0eed',
    CIDADE: 'Ubá',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.955345153808594,
    MAXIMO: 37.459503173828125,
    MEDIA: 36.2328099273859,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '197e22cf-0622-4fd2-81c0-aee342f44cbc',
    CIDADE: 'Ubaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.470312118530273,
    MAXIMO: 29.7226619720459,
    MEDIA: 29.587180455525715,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a1ef1cb-dbe7-4a83-a687-22de22e2c22c',
    CIDADE: 'Ubaporanga',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.101028442382812,
    MAXIMO: 30.491039276123047,
    MEDIA: 30.105352622332138,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42286fea-37b1-4424-8879-c6f88f045b5e',
    CIDADE: 'Uberaba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.73826217651367,
    MAXIMO: 41.686527252197266,
    MEDIA: 38.95612089974539,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8dc0bbb4-85e1-47c4-9b25-091480b9a193',
    CIDADE: 'Uberlândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.899295806884766,
    MAXIMO: 45.032283782958984,
    MEDIA: 43.45295333862305,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b9d6f94-4552-4e37-9f87-a3704001c2f2',
    CIDADE: 'Umburatiba',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.266977310180664,
    MAXIMO: 29.304059982299805,
    MEDIA: 29.285518646240234,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b7ae949-eebd-463b-a7a6-76ebea14bee2',
    CIDADE: 'Unaí',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.103586196899414,
    MAXIMO: 35.252742767333984,
    MEDIA: 33.185037067958284,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f20dd365-aa42-4b7c-bdab-ae34e0ef5833',
    CIDADE: 'União de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.30984115600586,
    MAXIMO: 35.784908294677734,
    MEDIA: 35.534799575805664,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a76d8244-3acb-478b-81c0-74bae4967224',
    CIDADE: 'Uruana de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.874317169189453,
    MAXIMO: 31.090099334716797,
    MEDIA: 30.982208251953125,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13c6644b-6812-4c25-bc7a-2060a81976d9',
    CIDADE: 'Urucânia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.707048416137695,
    MAXIMO: 32.600914001464844,
    MEDIA: 31.735006664687663,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e26e84da-bf01-469f-b3e4-312484a43f0b',
    CIDADE: 'Urucuia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.777076721191406,
    MAXIMO: 29.933870315551758,
    MEDIA: 29.84997844696045,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3dd89405-ad0c-41f2-ac81-47360dca9f40',
    CIDADE: 'Vargem Alegre',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.7691593170166,
    MAXIMO: 30.05449676513672,
    MEDIA: 29.989389039492703,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28ae7762-2016-4a4a-beec-bc878b8c0037',
    CIDADE: 'Vargem Bonita',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.4461669921875,
    MAXIMO: 34.041900634765625,
    MEDIA: 33.74745003353623,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b864eb3a-7004-4174-a4f3-853c30cfde07',
    CIDADE: 'Vargem Grande do Rio Pardo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.618642807006836,
    MAXIMO: 30.27853775024414,
    MEDIA: 29.94859027862549,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '34a6231a-f4c5-40a0-96f2-9b87ffad0b05',
    CIDADE: 'Varginha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 34.8194465637207,
    MAXIMO: 35.66474914550781,
    MEDIA: 35.23144542365232,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a68cd17-a8e0-4fcf-9459-cd5a96bb90df',
    CIDADE: 'Varjão de Minas',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 31.439592361450195,
    MAXIMO: 31.79405403137207,
    MEDIA: 31.65380605061849,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e424de57-406f-4128-8378-a9fafd33be67',
    CIDADE: 'Várzea da Palma',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.492406845092773,
    MAXIMO: 29.70047378540039,
    MEDIA: 29.594136873881023,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '801c8fce-2799-4c00-8b59-cf34c68c3d83',
    CIDADE: 'Varzelândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.443222045898438,
    MAXIMO: 29.47617530822754,
    MEDIA: 29.46402931213379,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53932a63-29c5-4344-947b-6bff88346e91',
    CIDADE: 'Vazante',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 33.56104278564453,
    MAXIMO: 35.79732894897461,
    MEDIA: 34.766754913330075,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd593e55-1853-4a51-baa2-80101e059405',
    CIDADE: 'Verdelândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.440555572509766,
    MAXIMO: 29.537860870361328,
    MEDIA: 29.4950745900472,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5b5f5fd-5412-4c76-ad17-96ca4a9b864f',
    CIDADE: 'Veredinha',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.06127166748047,
    MAXIMO: 29.085464477539062,
    MEDIA: 29.073516845703125,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f9f1a4da-39e7-4f20-b05a-eaa4a1b549be',
    CIDADE: 'Veríssimo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 38.297061920166016,
    MAXIMO: 40.10932159423828,
    MEDIA: 39.038639068603516,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a2507fd-491f-4e09-968f-20a0e5c41683',
    CIDADE: 'Vermelho Novo',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.88622283935547,
    MAXIMO: 31.58228874206543,
    MEDIA: 31.00381795356032,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39f272e0-cc3e-4282-a223-36cbc77df74b',
    CIDADE: 'Vespasiano',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 30.188066482543945,
    MAXIMO: 30.472272872924805,
    MEDIA: 30.281183702111385,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6ed34f96-8b18-442f-bbce-4edb7ab633b2',
    CIDADE: 'Viçosa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 32.976375579833984,
    MAXIMO: 34.83203887939453,
    MEDIA: 34.11472862279029,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f86ce0f4-cda1-46cb-bfc7-ebe1f23e91d7',
    CIDADE: 'Vieiras',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 37.046817779541016,
    MAXIMO: 38.4468879699707,
    MEDIA: 38.07851602300013,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84c5b359-c2cb-447b-8471-452c5adbaa50',
    CIDADE: 'Mathias Lobato',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.176816940307617,
    MAXIMO: 29.26390838623047,
    MEDIA: 29.235530823714075,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e656ec5a-f17d-4aac-b3a7-147bf7979a47',
    CIDADE: 'Virgem da Lapa',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.1556396484375,
    MAXIMO: 29.201086044311523,
    MEDIA: 29.17372194925944,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9de42ed5-c243-43f9-b204-84a86ea1399b',
    CIDADE: 'Virgínia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.44657897949219,
    MAXIMO: 36.075130462646484,
    MEDIA: 35.73896480730346,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74da3101-b2a4-4441-ae8e-be20ac87d7cd',
    CIDADE: 'Virginópolis',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.29726219177246,
    MAXIMO: 29.40219497680664,
    MEDIA: 29.34050917790612,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92fee627-948c-4ce0-bb8d-85a8f1c06c87',
    CIDADE: 'Virgolândia',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 29.154376983642578,
    MAXIMO: 29.182382583618164,
    MEDIA: 29.17088489277405,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '57a14f22-5cc0-44e7-9bb1-ec9f7e813d75',
    CIDADE: 'Visconde do Rio Branco',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 35.41607666015625,
    MAXIMO: 37.05137634277344,
    MEDIA: 35.97812856201089,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6ad88ba9-531d-4035-b9d9-c21e2c6dac9c',
    CIDADE: 'Volta Grande',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 40.1904411315918,
    MAXIMO: 41.44163131713867,
    MEDIA: 40.8806064345345,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb51b360-0930-4da8-bf6d-717c4ba49fd1',
    CIDADE: 'Wenceslau Braz',
    SIGLA: 'MG',
    ESTADO: 'Minas Gerais',
    MINIMO: 36.60205841064453,
    MAXIMO: 37.326637268066406,
    MEDIA: 36.71257364514138,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6c5f60c-7db1-4346-a406-6747e198f7b4',
    CIDADE: 'Afonso Cláudio',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 32.504356384277344,
    MAXIMO: 34.590179443359375,
    MEDIA: 33.54726791381836,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '291af4c8-0224-44ec-b734-cd26b2698c96',
    CIDADE: 'Águia Branca',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.44781494140625,
    MAXIMO: 29.751968383789062,
    MEDIA: 29.57498126425423,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fa97b377-9ee1-4a4a-8ca7-df086b085059',
    CIDADE: 'Água Doce do Norte',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.316755294799805,
    MAXIMO: 29.370519638061523,
    MEDIA: 29.343637466430664,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2175954b-cacb-496f-8a03-5bcc36e8eea8',
    CIDADE: 'Alegre',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 41.16153335571289,
    MAXIMO: 43.301544189453125,
    MEDIA: 42.09137725830078,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0d0ad94c-0fc2-443c-b9b4-3dea225de22f',
    CIDADE: 'Alfredo Chaves',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 40.048099517822266,
    MAXIMO: 42.02069091796875,
    MEDIA: 41.03439521789551,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c25a0c9f-89ad-4d80-b8e6-89e286863ac7',
    CIDADE: 'Alto Rio Novo',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.528268814086914,
    MAXIMO: 29.72266387939453,
    MEDIA: 29.567053858382035,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '02f35d11-6f09-4267-a8df-f8004850957c',
    CIDADE: 'Anchieta',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 39.070556640625,
    MAXIMO: 44.04452133178711,
    MEDIA: 42.41427979368366,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '31ae61c9-0f08-4763-b71e-eea6f1cd92d8',
    CIDADE: 'Apiacá',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 43.252803802490234,
    MAXIMO: 45.54991149902344,
    MEDIA: 44.24249606718193,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e9c1010e-fca8-4ff5-8e66-314e5ab2d44f',
    CIDADE: 'Aracruz',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 30.877262115478516,
    MAXIMO: 32.586002349853516,
    MEDIA: 31.42361831665039,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '55fe1ca1-1320-43c7-b078-b24ec5b6e015',
    CIDADE: 'Atílio Vivácqua',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 44.83141326904297,
    MAXIMO: 47.86619186401367,
    MEDIA: 47.39464762373642,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fc37b341-1f89-45d6-a161-080602f0e3ec',
    CIDADE: 'Baixo Guandu',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 30.269649505615234,
    MAXIMO: 30.741159439086914,
    MEDIA: 30.5451602935791,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c30fa6cd-e47a-4377-b1fa-af2ee7e67063',
    CIDADE: 'Barra de São Francisco',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.358264923095703,
    MAXIMO: 29.47730255126953,
    MEDIA: 29.420814514160156,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'af724e34-c915-46d6-82c8-e128c255fcbe',
    CIDADE: 'Boa Esperança',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.379352569580078,
    MAXIMO: 29.484590530395508,
    MEDIA: 29.43782052996388,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fc421c1d-ce6e-449c-936a-4cc99f1e14f7',
    CIDADE: 'Bom Jesus do Norte',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 42.90146255493164,
    MAXIMO: 44.19345474243164,
    MEDIA: 43.9713127262435,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd9055cc8-b96f-4f54-8af6-8c259b31b1f6',
    CIDADE: 'Brejetuba',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 32.159427642822266,
    MAXIMO: 34.14327621459961,
    MEDIA: 33.15135192871094,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ed450d2b-68df-439f-a2b4-8576a42fbe04',
    CIDADE: 'Cachoeiro de Itapemirim',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 42.91423034667969,
    MAXIMO: 45.89164733886719,
    MEDIA: 44.545763651529946,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '62853b04-d320-4f83-9619-a9631fedf889',
    CIDADE: 'Cariacica',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 34.20334243774414,
    MAXIMO: 38.42538070678711,
    MEDIA: 36.340423882550944,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '52c16ecf-7d2c-40d5-90a1-3d287b83b3b0',
    CIDADE: 'Castelo',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 40.03864288330078,
    MAXIMO: 42.294586181640625,
    MEDIA: 41.1666145324707,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '17211818-30e5-4c07-9b34-4c6b45a91db9',
    CIDADE: 'Colatina',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.945579528808594,
    MAXIMO: 30.301069259643555,
    MEDIA: 30.12468719482422,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1e848cfa-2566-4b25-99c5-0127488307fe',
    CIDADE: 'Conceição da Barra',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.51661491394043,
    MAXIMO: 29.574264526367188,
    MEDIA: 29.54543972015381,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7b506cf7-9209-4506-a16d-de68dcbfddd7',
    CIDADE: 'Conceição do Castelo',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.77845764160156,
    MAXIMO: 39.53714370727539,
    MEDIA: 38.15780067443848,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '551963c5-706d-4583-bcc0-da0cc987584d',
    CIDADE: 'Divino de São Lourenço',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.62651062011719,
    MAXIMO: 39.89356994628906,
    MEDIA: 38.48576901542102,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0111daca-aa02-4b7a-88d7-fe2f5c54b2de',
    CIDADE: 'Domingos Martins',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.87703323364258,
    MAXIMO: 37.387386322021484,
    MEDIA: 37.162951151529946,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4b0b3ece-7646-437e-8af5-36b64f627a7e',
    CIDADE: 'Dores do Rio Preto',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.62651062011719,
    MAXIMO: 40.44245147705078,
    MEDIA: 38.58616198394014,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e361b878-55e0-4345-885c-add8d3defa2b',
    CIDADE: 'Ecoporanga',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.26334571838379,
    MAXIMO: 29.350360870361328,
    MEDIA: 29.29562783241272,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a4966b48-3ab2-4b80-a572-eaa3f573facb',
    CIDADE: 'Fundão',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 31.579320907592773,
    MAXIMO: 32.77642822265625,
    MEDIA: 32.598408526591356,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '018827ce-5dcb-4af1-a137-1f9187f426f2',
    CIDADE: 'Governador Lindenberg',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.696186065673828,
    MAXIMO: 29.953237533569336,
    MEDIA: 29.818166077912352,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e82d733c-5ba5-4eb3-804a-db10071f480c',
    CIDADE: 'Guaçuí',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 38.67450714111328,
    MAXIMO: 43.252803802490234,
    MEDIA: 40.640936037822335,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9a80fd9b-d68e-4061-97ec-d121f2f334d1',
    CIDADE: 'Guarapari',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 39.070556640625,
    MAXIMO: 41.13090515136719,
    MEDIA: 40.140570322672524,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3cbbdbb6-cf02-4868-8771-f0cb11050b64',
    CIDADE: 'Ibatiba',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 33.0161018371582,
    MAXIMO: 36.07257080078125,
    MEDIA: 35.07131013655575,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2c4f3c57-3f7f-4b91-8681-23224e425541',
    CIDADE: 'Ibiraçu',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 31.579320907592773,
    MAXIMO: 32.77642822265625,
    MEDIA: 31.62256594576515,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '518ecd3e-ffd5-4661-93b2-342f311e6e8a',
    CIDADE: 'Ibitirama',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.62651062011719,
    MAXIMO: 39.89356994628906,
    MEDIA: 38.260040283203125,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1447ec84-3d8a-44a3-b64a-964abcfe58b1',
    CIDADE: 'Iconha',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 42.02069091796875,
    MAXIMO: 45.225826263427734,
    MEDIA: 44.03917418299809,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bdbfd69f-1453-4f59-a66f-d10f824a5873',
    CIDADE: 'Irupi',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 34.441619873046875,
    MAXIMO: 37.661861419677734,
    MEDIA: 35.245206786669165,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b9132c02-22c1-47b2-8880-8adfb5c9cc36',
    CIDADE: 'Itaguaçu',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 30.79541778564453,
    MAXIMO: 31.55055809020996,
    MEDIA: 31.172987937927246,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c31c1f3d-6222-43cf-bca5-df6fe0917815',
    CIDADE: 'Itapemirim',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 44.04452133178711,
    MAXIMO: 47.86619186401367,
    MEDIA: 46.60845025713397,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7ed7501b-652f-4531-94b3-7fca350b32fc',
    CIDADE: 'Itarana',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 31.46611213684082,
    MAXIMO: 34.82606887817383,
    MEDIA: 32.533502037381005,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e9c828a4-007d-4273-9f05-e949d82a0182',
    CIDADE: 'Iúna',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 34.441619873046875,
    MAXIMO: 37.661861419677734,
    MEDIA: 36.051740646362305,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '294f9ea2-7110-4078-8f61-910a085659a7',
    CIDADE: 'Jaguaré',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.63208770751953,
    MAXIMO: 29.797372817993164,
    MEDIA: 29.714730262756348,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5b5a4bac-bab0-44e1-ba08-da6f7824e1cd',
    CIDADE: 'Jerônimo Monteiro',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 41.16153335571289,
    MAXIMO: 44.83141326904297,
    MEDIA: 43.678674032549395,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b6894f51-7a0f-40d7-8f89-face0937f6a8',
    CIDADE: 'João Neiva',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 30.82903289794922,
    MAXIMO: 31.588088989257812,
    MEDIA: 31.09535069318549,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b9bb9d6f-ea13-458e-9039-104aef7caea1',
    CIDADE: 'Laranja da Terra',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 31.30075454711914,
    MAXIMO: 31.46611213684082,
    MEDIA: 31.38343334197998,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5879a40d-dd94-4f9f-bca0-2e08f096b346',
    CIDADE: 'Linhares',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.953237533569336,
    MAXIMO: 30.99603271484375,
    MEDIA: 30.291569868723553,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2fa410ab-5cb3-406f-bdbf-e862165eaf5a',
    CIDADE: 'Mantenópolis',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.393728256225586,
    MAXIMO: 29.557859420776367,
    MEDIA: 29.444526290786307,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '15ec67a5-7df2-422a-9306-717353737057',
    CIDADE: 'Marataízes',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 45.45674514770508,
    MAXIMO: 47.12714767456055,
    MEDIA: 46.76626765338069,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c50a6bd6-af55-40ee-abf2-68061e1630aa',
    CIDADE: 'Marechal Floriano',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.87703323364258,
    MAXIMO: 40.048099517822266,
    MEDIA: 39.33552733152473,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b69596b3-c84e-4207-8e00-c02d7f0e25a9',
    CIDADE: 'Marilândia',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.945579528808594,
    MAXIMO: 30.391536712646484,
    MEDIA: 30.157677679450398,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3f1feb09-b2ca-4739-965b-6e4a0380cd92',
    CIDADE: 'Mimoso do Sul',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 45.54991149902344,
    MAXIMO: 46.80118179321289,
    MEDIA: 46.175546646118164,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7b7c24e0-3de9-402a-a8de-96a225aba037',
    CIDADE: 'Montanha',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.3603458404541,
    MAXIMO: 29.41570472717285,
    MEDIA: 29.386762619018555,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1ba777fa-ea63-478b-a28b-a84c5905ee78',
    CIDADE: 'Mucurici',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.304243087768555,
    MAXIMO: 29.331499099731445,
    MEDIA: 29.31787109375,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'db3f6fbe-a067-4ec8-8b96-9136fa52bcf3',
    CIDADE: 'Muniz Freire',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.07257080078125,
    MAXIMO: 38.683319091796875,
    MEDIA: 37.37794494628906,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'adb628e4-5350-4d4d-95a0-ad0efb143232',
    CIDADE: 'Muqui',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 44.78459167480469,
    MAXIMO: 46.456295013427734,
    MEDIA: 45.62044334411621,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '762c337f-c5bb-4bb7-9f4f-d67693713e20',
    CIDADE: 'Nova Venécia',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.416765213012695,
    MAXIMO: 29.547651290893555,
    MEDIA: 29.487088203430176,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5b597dac-8df0-4dc8-91e9-7fcd1fba1288',
    CIDADE: 'Pancas',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.557859420776367,
    MAXIMO: 29.96300506591797,
    MEDIA: 29.73066833641395,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '662f9d9b-fd15-4bb5-97af-0c3072fc30f5',
    CIDADE: 'Pedro Canário',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.400680541992188,
    MAXIMO: 29.477949142456055,
    MEDIA: 29.437026796473667,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd6b1b8df-a336-46c5-8a1b-d83d52f93507',
    CIDADE: 'Pinheiros',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.379352569580078,
    MAXIMO: 29.477949142456055,
    MEDIA: 29.427130222320557,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6f310970-80fa-4fb9-aa91-2b3f203aa41a',
    CIDADE: 'Piúma',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 42.90852355957031,
    MAXIMO: 45.45674514770508,
    MEDIA: 44.05836121159978,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '54dd90f4-14b3-44f1-b630-87d1cc00718a',
    CIDADE: 'Ponto Belo',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.315448760986328,
    MAXIMO: 29.379352569580078,
    MEDIA: 29.35911063288578,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a8083b98-79a5-4f20-9229-95f89111e945',
    CIDADE: 'Presidente Kennedy',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 47.12714767456055,
    MAXIMO: 47.49275207519531,
    MEDIA: 47.30994987487793,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f513412a-b175-4b25-b75b-ce700cb105dd',
    CIDADE: 'Rio Bananal',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.696186065673828,
    MAXIMO: 30.142505645751953,
    MEDIA: 29.937003636869363,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '02540159-0df8-45fb-b58f-3c3ef793ab31',
    CIDADE: 'Rio Novo do Sul',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 42.73373031616211,
    MAXIMO: 46.8139533996582,
    MEDIA: 44.89028456378563,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f28b2609-8679-49cf-b5c8-532c417db525',
    CIDADE: 'Santa Leopoldina',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 32.77642822265625,
    MAXIMO: 34.690921783447266,
    MEDIA: 33.982913970947266,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '61f19cea-b011-4e2d-9c1a-cfa78b1dcb11',
    CIDADE: 'Santa Maria de Jetibá',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 32.83926773071289,
    MAXIMO: 34.84956741333008,
    MEDIA: 33.84011650085449,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2ddd9225-2980-4a73-a7c2-c52db94a5199',
    CIDADE: 'Santa Teresa',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 30.809406280517578,
    MAXIMO: 32.84556198120117,
    MEDIA: 32.07570259378771,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f214cc90-b9ac-4b65-93ca-defde3264d59',
    CIDADE: 'São Domingos do Norte',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.615427017211914,
    MAXIMO: 29.945579528808594,
    MEDIA: 29.73689220952473,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '72600335-ead5-4d13-b43c-640f8a9aed7f',
    CIDADE: 'São Gabriel da Palha',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.615427017211914,
    MAXIMO: 29.649404525756836,
    MEDIA: 29.632415771484375,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '656f45a3-03f7-4c66-a9ae-31e5f2d96274',
    CIDADE: 'São José do Calçado',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 41.81105422973633,
    MAXIMO: 44.19345474243164,
    MEDIA: 43.12060676874129,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '214066e7-c3f7-4652-95fc-578bf02493b1',
    CIDADE: 'São Mateus',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.484590530395508,
    MAXIMO: 29.840456008911133,
    MEDIA: 29.61262378692627,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c7291cda-d9d5-455a-a22b-cc92846bb987',
    CIDADE: 'São Roque do Canaã',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 30.79541778564453,
    MAXIMO: 31.579320907592773,
    MEDIA: 31.07068640269751,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2f1a6185-3305-4dd5-b821-a9082e30f378',
    CIDADE: 'Serra',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 32.586002349853516,
    MAXIMO: 35.9647331237793,
    MEDIA: 34.02496635032933,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5d01a61d-c494-4e2b-8633-c23c49f116be',
    CIDADE: 'Sooretama',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.74827003479004,
    MAXIMO: 29.912769317626953,
    MEDIA: 29.830519676208496,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a16cb7f0-9afc-4c73-941f-9118cd9ab0fb',
    CIDADE: 'Vargem Alta',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 40.03864288330078,
    MAXIMO: 45.89164733886719,
    MEDIA: 42.87692581795528,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '688e25d2-b0a4-4cd0-86b7-0710a424c44a',
    CIDADE: 'Venda Nova do Imigrante',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.77845764160156,
    MAXIMO: 40.03864288330078,
    MEDIA: 38.22571047795527,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4a0dd747-48d0-43b0-b6d9-fcd72af050a7',
    CIDADE: 'Viana',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 36.4484748840332,
    MAXIMO: 39.070556640625,
    MEDIA: 37.7644937146697,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3268ece4-c4f2-46a4-9565-ebd59b7f11b3',
    CIDADE: 'Vila Pavão',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.385589599609375,
    MAXIMO: 29.42993927001953,
    MEDIA: 29.407764434814453,
    OFICIAL: 29,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9339d277-dc2a-441f-9054-0fc42ee7fc0e',
    CIDADE: 'Vila Valério',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 29.58894920349121,
    MAXIMO: 29.844717025756836,
    MEDIA: 29.658574713571692,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '855de2d6-f2b6-4bf3-9cf0-00ab2471d4ac',
    CIDADE: 'Vila Velha',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 35.9647331237793,
    MAXIMO: 38.42538070678711,
    MEDIA: 37.55948415020274,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '81277310-4b62-432b-b632-db515b6bf8ef',
    CIDADE: 'Vitória',
    SIGLA: 'ES',
    ESTADO: 'Espírito Santo',
    MINIMO: 35.4973258972168,
    MAXIMO: 35.9647331237793,
    MEDIA: 35.95736447500502,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ae0500d0-9d49-45b5-8c09-3bfcd5d6a2ce',
    CIDADE: 'Angra dos Reis',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 37.224849700927734,
    MAXIMO: 38.228206634521484,
    MEDIA: 37.72652816772461,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '2adbc4e2-6fc9-4eec-bfec-926203c249dc',
    CIDADE: 'Aperibé',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.65203857421875,
    MAXIMO: 42.89395523071289,
    MEDIA: 42.50507784758137,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '79af072d-3dbf-45d3-9b50-9fba1f9d87ba',
    CIDADE: 'Araruama',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.85189437866211,
    MAXIMO: 43.2525749206543,
    MEDIA: 43.0522346496582,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '463ff26b-853f-4e29-b7ef-e947eca79e0f',
    CIDADE: 'Areal',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.25653076171875,
    MAXIMO: 40.63776397705078,
    MEDIA: 40.58030094248237,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '0ca6ca39-eb17-41e7-81b7-d0eb938b9483',
    CIDADE: 'Armação dos Búzios',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 44.01918029785156,
    MAXIMO: 44.455482482910156,
    MEDIA: 44.022104188169074,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '372f252c-8b31-4005-b2ec-79fd2bbfdd69',
    CIDADE: 'Arraial do Cabo',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.19486999511719,
    MAXIMO: 43.997249603271484,
    MEDIA: 43.42541395065835,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '4318011b-03c0-4e35-88ca-a65e2d34d08b',
    CIDADE: 'Barra do Piraí',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 38.94171905517578,
    MAXIMO: 40.55756759643555,
    MEDIA: 39.928545268671826,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '29e08b5a-27c6-41b1-a356-519cc6b44f7c',
    CIDADE: 'Barra Mansa',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 38.14484786987305,
    MAXIMO: 40.52450942993164,
    MEDIA: 39.52433449041405,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '3e625e65-d588-45eb-bb4d-5d6d90d8efcd',
    CIDADE: 'Belford Roxo',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.743377685546875,
    MAXIMO: 40.98188018798828,
    MEDIA: 40.7449205556412,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'c24ca071-5d7e-4eec-b298-695ec22b7dcc',
    CIDADE: 'Bom Jardim',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.66286849975586,
    MAXIMO: 42.9947395324707,
    MEDIA: 42.55813208420612,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'ceebb23e-0025-4297-bb18-4e702a945e12',
    CIDADE: 'Bom Jesus do Itabapoana',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.85384750366211,
    MAXIMO: 42.90146255493164,
    MEDIA: 42.377655029296875,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '92886c91-de51-409c-b631-d1c22389cd8a',
    CIDADE: 'Cabo Frio',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.56306838989258,
    MAXIMO: 43.721031188964844,
    MEDIA: 43.64204978942871,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '34fe94a3-7666-43d3-9ad5-eb893d3600bd',
    CIDADE: 'Cachoeiras de Macacu',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.70352554321289,
    MAXIMO: 42.28856658935547,
    MEDIA: 42.00454139709473,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '660a3080-ccef-4809-8eed-474c2577737b',
    CIDADE: 'Cambuci',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.55906295776367,
    MAXIMO: 44.038936614990234,
    MEDIA: 43.223944346110024,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '63b83d51-5413-44dc-a78d-6effe98e185a',
    CIDADE: 'Carapebus',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 44.659854888916016,
    MAXIMO: 45.23003005981445,
    MEDIA: 45.040555475542796,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '7db86d51-2a06-4669-94c2-04a49da235a9',
    CIDADE: 'Comendador Levy Gasparian',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.00911331176758,
    MAXIMO: 40.25653076171875,
    MEDIA: 39.85945993428018,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '73f95845-75df-4393-9ab3-5eb19512c4a2',
    CIDADE: 'Campos dos Goytacazes',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 45.28654861450195,
    MAXIMO: 46.63585662841797,
    MEDIA: 45.93890800476074,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'cb2b93e4-e146-4fd7-88b7-1e60930e4c4e',
    CIDADE: 'Cantagalo',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.44163131713867,
    MAXIMO: 42.503787994384766,
    MEDIA: 42.00335216522217,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'f3e1a1ca-667e-4124-a788-c054e5dda266',
    CIDADE: 'Cardoso Moreira',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 45.88982391357422,
    MAXIMO: 45.94166564941406,
    MEDIA: 45.91574478149414,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'b77f47b0-cf54-482d-a4b0-955e43021a2b',
    CIDADE: 'Carmo',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.70011901855469,
    MAXIMO: 41.89077377319336,
    MEDIA: 41.27629136392598,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'a418b04a-c450-4a3b-bd0c-a9d65393ec4a',
    CIDADE: 'Casimiro de Abreu',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.22878646850586,
    MAXIMO: 43.7176628112793,
    MEDIA: 43.47322463989258,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '27235295-983f-4166-8651-73a57b33232d',
    CIDADE: 'Conceição de Macabu',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 44.1912841796875,
    MAXIMO: 44.73890686035156,
    MEDIA: 44.46509552001953,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '0c4d9212-cefa-49f3-9ab3-dc417f60a8e0',
    CIDADE: 'Cordeiro',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.89077377319336,
    MAXIMO: 42.85444641113281,
    MEDIA: 42.52248057541927,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'f5f31126-92b6-400f-88c1-4185296e4258',
    CIDADE: 'Duas Barras',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.66286849975586,
    MAXIMO: 42.18309783935547,
    MEDIA: 41.922983169555664,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '23b32c3c-6d6b-4dc4-8abb-57dc7a6c0e17',
    CIDADE: 'Duque de Caxias',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.68825912475586,
    MAXIMO: 40.87282180786133,
    MEDIA: 40.780540466308594,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '39322d5c-5e79-42c1-bbb9-9d19b45b8f78',
    CIDADE: 'Engenheiro Paulo de Frontin',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.16315841674805,
    MAXIMO: 40.60700225830078,
    MEDIA: 40.39470485436993,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '79c34022-c7b1-44ca-b985-d6a716d3ce1b',
    CIDADE: 'Guapimirim',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.949031829833984,
    MAXIMO: 41.60660934448242,
    MEDIA: 41.39140172060086,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '8543bf3c-9c4b-48c3-9f72-94ce29a3000f',
    CIDADE: 'Iguaba Grande',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.19486999511719,
    MAXIMO: 43.64554977416992,
    MEDIA: 43.23477823181726,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '33e7f631-0d50-4ce1-a861-63f0bc7d84e7',
    CIDADE: 'Itaboraí',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.234683990478516,
    MAXIMO: 42.026023864746094,
    MEDIA: 41.758108924979126,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '9039cd71-4dea-478a-a7a3-5e2c8030ba44',
    CIDADE: 'Itaguaí',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.522457122802734,
    MAXIMO: 40.653953552246094,
    MEDIA: 40.41507598319763,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'ea8d286c-6dd6-4ba0-a0d9-bf77afae13cc',
    CIDADE: 'Italva',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.58381652832031,
    MAXIMO: 45.21681594848633,
    MEDIA: 44.781107975031674,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '0966fc04-0cd6-4c90-a894-1f6677e63469',
    CIDADE: 'Itaocara',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.89395523071289,
    MAXIMO: 43.385440826416016,
    MEDIA: 43.13969802856445,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '2f7661d0-3c94-4f89-86a8-8d5c16303f0b',
    CIDADE: 'Itaperuna',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.56550979614258,
    MAXIMO: 44.73516082763672,
    MEDIA: 42.84505653381348,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'f2b73643-c245-4ec8-9ffc-28c63d1bfbfd',
    CIDADE: 'Itatiaia',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 36.37744903564453,
    MAXIMO: 37.5003547668457,
    MEDIA: 37.08073364495847,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '445f648a-d15c-43fd-8c1d-53de41ef093b',
    CIDADE: 'Japeri',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.52364730834961,
    MAXIMO: 40.665218353271484,
    MEDIA: 40.61922722779402,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '0abc6f0f-dbe3-49dc-8ecc-f88e8d55e530',
    CIDADE: 'Laje do Muriaé',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.5863151550293,
    MAXIMO: 41.46135711669922,
    MEDIA: 41.103766729619096,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '71481fec-ad90-4bbf-ab2c-bce7b9f30fb4',
    CIDADE: 'Macaé',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.66361618041992,
    MAXIMO: 44.659854888916016,
    MEDIA: 44.167076110839844,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '84646ac9-f3d3-4a28-ae90-3d6acb43a214',
    CIDADE: 'Macuco',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.503787994384766,
    MAXIMO: 42.85444641113281,
    MEDIA: 42.669576747438065,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '108c03ad-716d-4e8a-aa08-d13dacd331d1',
    CIDADE: 'Magé',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.87282180786133,
    MAXIMO: 41.234683990478516,
    MEDIA: 41.103977661038705,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '963af489-90f9-4aef-9ddf-17018f69524a',
    CIDADE: 'Mangaratiba',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.9359245300293,
    MAXIMO: 40.2087516784668,
    MEDIA: 40.07233810424805,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '8f07825b-6113-4fe3-b4fa-570b5743bc50',
    CIDADE: 'Maricá',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.651126861572266,
    MAXIMO: 42.026023864746094,
    MEDIA: 41.83857536315918,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '79d8eba3-baf5-4b2d-96c9-73ae36439bda',
    CIDADE: 'Mendes',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.16315841674805,
    MAXIMO: 40.52364730834961,
    MEDIA: 40.38121364121141,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'a405a0a7-4c88-43f7-87eb-806d1b8b0338',
    CIDADE: 'Mesquita',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.665218353271484,
    MAXIMO: 40.743377685546875,
    MEDIA: 40.739313352844526,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '3dcc2b28-4f78-4393-8ec6-e87e6f3ae3e6',
    CIDADE: 'Miguel Pereira',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.284481048583984,
    MAXIMO: 40.68825912475586,
    MEDIA: 40.504965087557125,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '0055d27a-ce53-4635-8e8e-1b80ad4de81f',
    CIDADE: 'Miracema',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.46804428100586,
    MAXIMO: 41.9660758972168,
    MEDIA: 41.530819065768625,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '784857ad-ed6d-4fe6-8339-573d49a551d8',
    CIDADE: 'Natividade',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.58824157714844,
    MAXIMO: 41.6805305480957,
    MEDIA: 41.13438606262207,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'dd6b2f1d-5474-41b1-aef5-b85663e8bf20',
    CIDADE: 'Nilópolis',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.673465728759766,
    MAXIMO: 40.743377685546875,
    MEDIA: 40.731343107091085,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'f22dd9a1-6c37-41a6-8dd8-57c80949f94e',
    CIDADE: 'Niterói',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.24867248535156,
    MAXIMO: 41.651126861572266,
    MEDIA: 41.28633979993245,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '04e2e370-1fd9-4571-a422-21c1a519fae3',
    CIDADE: 'Nova Friburgo',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.83641815185547,
    MAXIMO: 42.6279182434082,
    MEDIA: 42.29852040608724,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'bb7afacf-0a9f-4e30-b7a5-c78df95d7a11',
    CIDADE: 'Nova Iguaçu',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.60700225830078,
    MAXIMO: 40.665218353271484,
    MEDIA: 40.63611030578613,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '5bbe7acb-e66e-4784-83ff-30ea131ab8a5',
    CIDADE: 'Paracambi',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.52364730834961,
    MAXIMO: 40.653953552246094,
    MEDIA: 40.54439035405135,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '529aee44-739a-488d-898b-0a71e7e3c3d3',
    CIDADE: 'Paraíba do Sul',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.614723205566406,
    MAXIMO: 40.32787322998047,
    MEDIA: 39.97129821777344,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '3f507daf-5ec3-4b55-8696-e2d2f0a32230',
    CIDADE: 'Paraty',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 35.02876663208008,
    MAXIMO: 35.952789306640625,
    MEDIA: 35.52536646525065,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'c0601f9d-2885-4137-88f9-d4f3e30b970d',
    CIDADE: 'Paty do Alferes',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.83972930908203,
    MAXIMO: 40.67851257324219,
    MEDIA: 40.31859832589658,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '82653539-3e2e-46c4-b02b-720dfb211e0e',
    CIDADE: 'Petrópolis',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.63776397705078,
    MAXIMO: 40.949031829833984,
    MEDIA: 40.75510279337565,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'e1961bdc-2cb3-4baa-b44e-760755cd94e9',
    CIDADE: 'Pinheiral',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.729618072509766,
    MAXIMO: 40.55756759643555,
    MEDIA: 40.474567264353695,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'c6107a25-d8dd-4d56-964d-1bc2623eb4f2',
    CIDADE: 'Piraí',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.52450942993164,
    MAXIMO: 40.653953552246094,
    MEDIA: 40.57867685953776,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '6483a33f-c77e-4d55-8756-2601800fe9ab',
    CIDADE: 'Porciúncula',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.207908630371094,
    MAXIMO: 40.58824157714844,
    MEDIA: 39.81074741491232,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '9cb42ac8-ba1c-47d3-80f9-cf036857025f',
    CIDADE: 'Porto Real',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 38.14484786987305,
    MAXIMO: 39.071537017822266,
    MEDIA: 38.32853554937255,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '8325564b-1cab-4e2f-b852-fea567d57744',
    CIDADE: 'Quatis',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 38.343849182128906,
    MAXIMO: 39.071537017822266,
    MEDIA: 38.707693099975586,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '45958f25-9d9a-4eaf-b974-9c3cd2d24200',
    CIDADE: 'Queimados',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.665218353271484,
    MAXIMO: 40.665218353271484,
    MEDIA: 40.665218353271484,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'e9c97999-5248-457d-8bfb-26bae59268d3',
    CIDADE: 'Quissamã',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 45.23003005981445,
    MAXIMO: 45.77716827392578,
    MEDIA: 45.50047938028971,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '4ec78ffa-9c8a-4463-8b7d-e85e8cb50fe4',
    CIDADE: 'Resende',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 36.37744903564453,
    MAXIMO: 38.14484786987305,
    MEDIA: 37.34088389078776,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '832e5740-b93a-42f6-baef-e2abbaefb6cb',
    CIDADE: 'Rio Bonito',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.90534973144531,
    MAXIMO: 42.85189437866211,
    MEDIA: 42.4330622636321,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '33681c1e-7564-4cbe-ac9d-44c13e9b84c8',
    CIDADE: 'Rio Claro',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.23701858520508,
    MAXIMO: 40.779327392578125,
    MEDIA: 39.976078033447266,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '2c7d93d0-a8de-46ae-ab2a-bd264698f3eb',
    CIDADE: 'Rio das Flores',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.278892517089844,
    MAXIMO: 39.83972930908203,
    MEDIA: 39.55931091308594,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '30dbf6d9-ab8c-4ee4-b3e3-568cea131f18',
    CIDADE: 'Rio das Ostras',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.7176628112793,
    MAXIMO: 44.601200103759766,
    MEDIA: 44.16560665217334,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'd0995867-e1d4-44a0-bb12-631302e2dc5d',
    CIDADE: 'Rio de Janeiro',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.34514236450195,
    MAXIMO: 40.908756256103516,
    MEDIA: 40.60515213012695,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'ff10b237-50de-47b1-af8a-38bf9e55f6b4',
    CIDADE: 'Santa Maria Madalena',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.94499206542969,
    MAXIMO: 44.71139907836914,
    MEDIA: 44.328195571899414,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '60584e11-0b97-466e-b847-342622e8c9e3',
    CIDADE: 'Santo Antônio de Pádua',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.0732307434082,
    MAXIMO: 41.65203857421875,
    MEDIA: 41.36263465881348,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '2a735e20-e576-4707-9f98-4a539b1c9aa7',
    CIDADE: 'São Francisco de Itabapoana',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 46.707454681396484,
    MAXIMO: 47.235660552978516,
    MEDIA: 46.9085168838501,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '3a8aa41e-f46f-4d0f-831c-3e8925272e7f',
    CIDADE: 'São Fidélis',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.73710250854492,
    MAXIMO: 45.21681594848633,
    MEDIA: 44.461416244506836,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '77680474-b807-4954-a09a-bed4dc35f140',
    CIDADE: 'São Gonçalo',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.234683990478516,
    MAXIMO: 41.651126861572266,
    MEDIA: 41.441055351284234,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '626185d7-867a-4aa0-aaaf-588600fd0a74',
    CIDADE: 'São João da Barra',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 46.2249870300293,
    MAXIMO: 46.707454681396484,
    MEDIA: 46.4303139059832,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'fd77d91e-7690-415a-a9b1-96fccc814d97',
    CIDADE: 'São João de Meriti',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.743377685546875,
    MAXIMO: 40.743377685546875,
    MEDIA: 40.743377685546875,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '81b13f4f-85ad-4fb3-9e10-91136cb50787',
    CIDADE: 'São José de Ubá',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 41.46135711669922,
    MAXIMO: 43.58381652832031,
    MEDIA: 42.74682066221885,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '9a7b28ea-a090-4d66-bcdf-daac42318acb',
    CIDADE: 'São José do Vale do Rio Preto',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.25653076171875,
    MAXIMO: 41.104408264160156,
    MEDIA: 40.7707976895942,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'c102d482-0181-4861-858b-91c43787a4d8',
    CIDADE: 'São Pedro da Aldeia',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 43.19486999511719,
    MAXIMO: 43.721031188964844,
    MEDIA: 43.589671478728604,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '5a72d9dc-10f6-4ccd-ae42-d7ad7af27205',
    CIDADE: 'São Sebastião do Alto',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.503787994384766,
    MAXIMO: 43.94499206542969,
    MEDIA: 43.149576173830816,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'd328f3e9-70ba-45d9-a022-a9f404941135',
    CIDADE: 'Sapucaia',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.20524978637695,
    MAXIMO: 41.25590515136719,
    MEDIA: 40.725096928798,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '7e73df1a-4fda-4adf-b558-e957efa2b71b',
    CIDADE: 'Saquarema',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.43294143676758,
    MAXIMO: 42.815086364746094,
    MEDIA: 42.624013900756836,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '4be19550-19e7-4834-9ca4-981e9c104882',
    CIDADE: 'Seropédica',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.34514236450195,
    MAXIMO: 40.665218353271484,
    MEDIA: 40.59928283631337,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '407ddf10-fcae-4195-bebe-a4a3586bb951',
    CIDADE: 'Silva Jardim',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.839778900146484,
    MAXIMO: 43.29046630859375,
    MEDIA: 43.06512260437012,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '8712a44d-e871-4ac1-9044-c83ca578b537',
    CIDADE: 'Sumidouro',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.70011901855469,
    MAXIMO: 41.83641815185547,
    MEDIA: 41.40000349999095,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '98309750-7c92-44a7-b633-803f757ec832',
    CIDADE: 'Tanguá',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.02589416503906,
    MAXIMO: 42.4381103515625,
    MEDIA: 42.04991018760894,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '1bac58f7-2792-49e6-84a2-39a802abd35d',
    CIDADE: 'Teresópolis',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.99427795410156,
    MAXIMO: 41.49159240722656,
    MEDIA: 41.271846771240234,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '01af2311-3455-4db7-8e90-fdb3ac22531e',
    CIDADE: 'Trajano de Moraes',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 42.85444641113281,
    MAXIMO: 43.47377395629883,
    MEDIA: 43.16411018371582,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '5b551ffd-9920-4e28-a196-238bb0d25325',
    CIDADE: 'Três Rios',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.93659591674805,
    MAXIMO: 40.25653076171875,
    MEDIA: 40.0965633392334,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'ebce3999-3f1d-4242-a985-eb15e756f732',
    CIDADE: 'Valença',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 38.94171905517578,
    MAXIMO: 39.625614166259766,
    MEDIA: 39.227986335754395,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '32a7213b-b372-45c8-beb0-cdd384877600',
    CIDADE: 'Varre-Sai',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.207908630371094,
    MAXIMO: 41.85384750366211,
    MEDIA: 40.87455940169558,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: '246e659d-717c-40b3-917e-c0b125547a60',
    CIDADE: 'Vassouras',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 40.09485626220703,
    MAXIMO: 40.284481048583984,
    MEDIA: 40.18083190917969,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'aa8dd034-152d-49fe-a2cf-1a91c040c842',
    CIDADE: 'Volta Redonda',
    SIGLA: 'RJ',
    ESTADO: 'Rio de Janeiro',
    MINIMO: 39.071537017822266,
    MAXIMO: 40.52450942993164,
    MEDIA: 39.96288210619149,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '20,00%',
  },
  {
    id: 'e1982d5f-2f1f-4ce3-a00e-cdff91549b37',
    CIDADE: 'Adamantina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.39106750488281,
    MAXIMO: 40.58879852294922,
    MEDIA: 40.489933013916016,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95cbb993-84d8-4542-b56d-38019cfcd0c5',
    CIDADE: 'Adolfo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.39986801147461,
    MAXIMO: 37.925567626953125,
    MEDIA: 37.78909978316506,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '787ff160-3a04-438e-a005-ec1e774fa2a6',
    CIDADE: 'Aguaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.8289909362793,
    MAXIMO: 43.98227310180664,
    MEDIA: 43.26438314906351,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55950c06-af83-4efb-9fd4-ae18a7ff3c03',
    CIDADE: 'Águas da Prata',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.24063491821289,
    MAXIMO: 41.2880973815918,
    MEDIA: 41.11154311022819,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '444b5a4c-769d-4b53-9af8-3a167d16bb06',
    CIDADE: 'Águas de Lindóia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.660926818847656,
    MAXIMO: 43.8931884765625,
    MEDIA: 43.33034201140007,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2baa8ed0-2afd-45e8-945e-90290995692f',
    CIDADE: 'Águas de Santa Bárbara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.656768798828125,
    MAXIMO: 39.77497482299805,
    MEDIA: 39.69479091402298,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6200744e-7089-40f2-8b41-8693d177995a',
    CIDADE: 'Águas de São Pedro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 46.16374588012695,
    MAXIMO: 46.16374588012695,
    MEDIA: 46.16374588012695,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ede5e4c1-7a7c-4361-8231-1699d19dbbbe',
    CIDADE: 'Agudos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.656768798828125,
    MAXIMO: 40.280975341796875,
    MEDIA: 39.89860153198242,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e94924f-d8bb-41a4-8e61-39d8cffe2b56',
    CIDADE: 'Alambari',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.98659896850586,
    MAXIMO: 40.58489990234375,
    MEDIA: 40.28419846638327,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '344ada20-f016-4607-a9af-ae179065fe34',
    CIDADE: 'Alfredo Marcondes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.56743621826172,
    MAXIMO: 41.56743621826172,
    MEDIA: 41.56743621826172,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62ab48f3-df96-409f-bde8-00e9db7a793b',
    CIDADE: 'Altair',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.5,
    MAXIMO: 35.895843505859375,
    MEDIA: 35.66834286821643,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8d1c928-aeba-4883-8480-4804b2e97317',
    CIDADE: 'Altinópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.25038528442383,
    MAXIMO: 37.021339416503906,
    MEDIA: 36.63586235046387,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e71a05c-1e48-4e7d-be0e-a5d2265a3b70',
    CIDADE: 'Alto Alegre',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.90555953979492,
    MAXIMO: 39.10519027709961,
    MEDIA: 39.005374908447266,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d4c06f5-666d-4c30-9b24-29594b0e5eff',
    CIDADE: 'Alumínio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.548519134521484,
    MAXIMO: 41.731380462646484,
    MEDIA: 41.63345231469381,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3333984a-f77e-425c-ac7a-b0b47d77f0ec',
    CIDADE: 'Álvares Florence',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.56220245361328,
    MAXIMO: 36.35322570800781,
    MEDIA: 36.004771993534426,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b3871ef4-e5b2-47ea-bd06-6855b8ad3f69',
    CIDADE: 'Álvares Machado',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.56743621826172,
    MAXIMO: 42.30823516845703,
    MEDIA: 42.05783653287656,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44c69455-a002-4e40-9dae-68232dd55928',
    CIDADE: 'Álvaro de Carvalho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.15476989746094,
    MAXIMO: 39.22660446166992,
    MEDIA: 39.21137525981484,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55d44db2-646a-4f16-b984-46832cbed6b4',
    CIDADE: 'Alvinlândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.56255340576172,
    MAXIMO: 39.69622802734375,
    MEDIA: 39.565949150612276,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd636c24-abd2-4fb9-b2e4-09e7398b018c',
    CIDADE: 'Americana',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.15670394897461,
    MAXIMO: 45.855472564697266,
    MEDIA: 45.478477018574424,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5abd19b5-14b0-4fa1-90eb-f3ea7d9bf706',
    CIDADE: 'Américo Brasiliense',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.823734283447266,
    MAXIMO: 41.3417854309082,
    MEDIA: 41.20779979077802,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56860232-b88c-41b9-8f9f-c7572a41db56',
    CIDADE: 'Américo de Campos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.374473571777344,
    MAXIMO: 36.06241989135742,
    MEDIA: 35.80392197324883,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c0d5018-6ad8-43e6-8e6e-f8eef1c08d52',
    CIDADE: 'Amparo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.82510757446289,
    MAXIMO: 44.87501907348633,
    MEDIA: 44.85006332397461,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06378f51-b8c7-46f5-9caf-afc3187d9014',
    CIDADE: 'Analândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.22258377075195,
    MAXIMO: 45.69121170043945,
    MEDIA: 44.8948590278064,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff22c559-5ec3-46a3-82a6-9ea7a4c284f4',
    CIDADE: 'Andradina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.84805679321289,
    MAXIMO: 40.4249382019043,
    MEDIA: 40.14738464355469,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '490bb118-3321-458a-a600-dbc743c81fe7',
    CIDADE: 'Angatuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.661624908447266,
    MAXIMO: 39.79975509643555,
    MEDIA: 39.71884409586588,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9b871a3-baad-48da-b2f1-638cbca9fefb',
    CIDADE: 'Anhembi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.84268569946289,
    MAXIMO: 44.91790008544922,
    MEDIA: 43.880292892456055,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac500b62-2e62-422e-aa01-b6becac6da1d',
    CIDADE: 'Anhumas',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.975399017333984,
    MAXIMO: 42.12971878051758,
    MEDIA: 42.05255889892578,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58f6dcc4-27a5-4f49-818b-7276e99f4722',
    CIDADE: 'Aparecida',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.62594985961914,
    MAXIMO: 36.14076614379883,
    MEDIA: 35.9383201983072,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf06627f-00cb-4b7b-bbe7-cd5a0738cd48',
    CIDADE: "Aparecida d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.42200469970703,
    MAXIMO: 38.72272491455078,
    MEDIA: 38.42473937704054,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77b155c7-2495-41bc-ac34-859b81abac1b',
    CIDADE: 'Apiaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.84562301635742,
    MAXIMO: 40.0103874206543,
    MEDIA: 39.937265396118164,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3be8e86-b6a8-47d8-b640-eabf7ddb16bd',
    CIDADE: 'Araçariguama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.55588912963867,
    MAXIMO: 41.25457000732422,
    MEDIA: 40.8701201381704,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c4d21b6-6e51-422f-aaff-2182a21beca2',
    CIDADE: 'Araçatuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.53886795043945,
    MAXIMO: 38.95462417602539,
    MEDIA: 38.79080073038737,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aee9f741-6a4d-4435-aa92-fe5f53d75edc',
    CIDADE: 'Araçoiaba da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.44466781616211,
    MAXIMO: 41.824859619140625,
    MEDIA: 41.044638535386326,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8689662a-ff6a-4e55-a832-fb6cd77d950b',
    CIDADE: 'Aramina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.42697525024414,
    MAXIMO: 36.03468322753906,
    MEDIA: 35.92964601360344,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52617be1-70cc-4d6a-8e86-af5363d4ab55',
    CIDADE: 'Arandu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.69741439819336,
    MAXIMO: 39.8221435546875,
    MEDIA: 39.73609604706313,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb523d07-8e54-4e12-add4-abc1485a5c1c',
    CIDADE: 'Arapeí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.5003547668457,
    MAXIMO: 38.61796951293945,
    MEDIA: 38.33774762482847,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0189c16f-49d3-4a6a-8d51-d56d26b20a1c',
    CIDADE: 'Araraquara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.03886032104492,
    MAXIMO: 42.80677032470703,
    MEDIA: 41.59969234466553,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6e8c491-7a6e-4614-9b69-97ba71d836e1',
    CIDADE: 'Araras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.339324951171875,
    MAXIMO: 45.7880859375,
    MEDIA: 45.56370544433594,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7cbcfe7-3027-4683-a118-572a37ea51c2',
    CIDADE: 'Arco-Íris',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.32293701171875,
    MAXIMO: 39.85883712768555,
    MEDIA: 39.58630096725098,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d950090-09d9-4600-9c1c-fde430343886',
    CIDADE: 'Arealva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.35512161254883,
    MAXIMO: 40.74842834472656,
    MEDIA: 39.911311860143336,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f07746a4-3ae3-4d8e-adcc-1a5a5f93e2d8',
    CIDADE: 'Areias',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.65462112426758,
    MAXIMO: 36.658729553222656,
    MEDIA: 36.65667533874512,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f61265ec-6750-4cd3-8cf2-f86a1b99a4ad',
    CIDADE: 'Areiópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.60948944091797,
    MAXIMO: 42.03602600097656,
    MEDIA: 41.96159856828227,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89bfc79f-e02c-4f24-9ce6-60469148d7e9',
    CIDADE: 'Ariranha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.236045837402344,
    MAXIMO: 37.907989501953125,
    MEDIA: 37.410504812531514,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87afbdee-aadb-40fe-b70d-d2ba8058e154',
    CIDADE: 'Artur Nogueira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.04472351074219,
    MAXIMO: 45.55939865112305,
    MEDIA: 45.513510218298265,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b801062d-8f29-4bb5-bdec-90e879e5cbee',
    CIDADE: 'Arujá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.702762603759766,
    MAXIMO: 39.8795280456543,
    MEDIA: 39.44020427647743,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '597a1faa-d468-4a3d-9989-5dc7d06d6497',
    CIDADE: 'Aspásia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.38593292236328,
    MAXIMO: 37.71646499633789,
    MEDIA: 37.40773068885478,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9f602f9a-5c20-4e40-9654-b88abd0d65dc',
    CIDADE: 'Assis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.02827453613281,
    MAXIMO: 40.88288879394531,
    MEDIA: 40.46010287424441,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a14561d-c745-4b47-9e13-8a8f5a74ca7a',
    CIDADE: 'Atibaia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.823543548583984,
    MAXIMO: 41.6740608215332,
    MEDIA: 41.221421559651695,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '953a01e3-13df-41a0-9746-e717811700f2',
    CIDADE: 'Auriflama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.586151123046875,
    MAXIMO: 38.63897705078125,
    MEDIA: 37.96224575682748,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4858deb5-a281-4460-9f0f-4d6a70ddff73',
    CIDADE: 'Avaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.35918045043945,
    MAXIMO: 39.50662612915039,
    MEDIA: 39.43290328979492,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96e7fc90-3570-4328-ae33-4ea2227cfd62',
    CIDADE: 'Avanhandava',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.925567626953125,
    MAXIMO: 38.90555953979492,
    MEDIA: 38.4276198669696,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5754c55c-629c-40ef-b5b0-cf801959a69a',
    CIDADE: 'Avaré',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.69741439819336,
    MAXIMO: 39.98997497558594,
    MEDIA: 39.83981628417969,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6356b4de-0398-4139-b4db-dd9791aebaa4',
    CIDADE: 'Bady Bassitt',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.763858795166016,
    MAXIMO: 36.840370178222656,
    MEDIA: 36.83938902843742,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '122da788-c0ca-4c5d-a268-23c89b7af32c',
    CIDADE: 'Balbinos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.842857360839844,
    MAXIMO: 39.12864303588867,
    MEDIA: 39.0678874234038,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0a73afa1-e9fb-43d6-92f5-13139049e65a',
    CIDADE: 'Bálsamo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.10377502441406,
    MAXIMO: 36.60188293457031,
    MEDIA: 36.387012956990766,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd128bc5a-2d27-462f-b8b4-f847cae0be35',
    CIDADE: 'Bananal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.224849700927734,
    MAXIMO: 39.911888122558594,
    MEDIA: 38.9947798598503,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c87aa35a-c16c-4638-b9d1-7c15a2498f2e',
    CIDADE: 'Barão de Antonina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.034339904785156,
    MAXIMO: 40.28116989135742,
    MEDIA: 40.17944123838941,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dcd0b451-52ea-4782-8109-35f171afa113',
    CIDADE: 'Barbosa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.925567626953125,
    MAXIMO: 38.29319381713867,
    MEDIA: 38.0677531421881,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '389e0049-ec14-44f5-9191-d24b47831236',
    CIDADE: 'Bariri',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.74842834472656,
    MAXIMO: 41.653465270996094,
    MEDIA: 41.20094680786133,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9b35512f-91cb-4ae7-b8d0-9e8f189619e9',
    CIDADE: 'Barra Bonita',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.20054244995117,
    MAXIMO: 43.440006256103516,
    MEDIA: 42.832543875492654,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23b9701f-2cc5-4ac2-ad14-26ca751d6140',
    CIDADE: 'Barra do Chapéu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.0103874206543,
    MAXIMO: 40.32432174682617,
    MEDIA: 40.15660398039234,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd87dcdbc-63f2-4a2b-a965-537ed0e80bc4',
    CIDADE: 'Barra do Turvo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.92789840698242,
    MAXIMO: 39.96150588989258,
    MEDIA: 39.9447021484375,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b0b576b-f679-407b-b961-af8a24a9ea3a',
    CIDADE: 'Barretos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.48912811279297,
    MAXIMO: 35.809173583984375,
    MEDIA: 35.64491500854492,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a432e6f-6794-433b-8501-af1127cc5260',
    CIDADE: 'Barrinha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.14613342285156,
    MAXIMO: 37.95794677734375,
    MEDIA: 37.73556567450074,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8cb5183b-b555-4f4f-a706-2c400ea0c9ab',
    CIDADE: 'Barueri',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.55516815185547,
    MAXIMO: 41.25457000732422,
    MEDIA: 40.95615929527441,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd5e8c2b8-9f82-4222-b5f5-75b4efd0261f',
    CIDADE: 'Bastos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.047969818115234,
    MAXIMO: 40.661964416503906,
    MEDIA: 40.358871757274684,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f61ccd68-d76f-4922-8a62-4582ef0325eb',
    CIDADE: 'Batatais',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.79554748535156,
    MAXIMO: 36.33119201660156,
    MEDIA: 35.97693125406901,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4020dad8-a7ef-4a51-94a1-35a377696e04',
    CIDADE: 'Bauru',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.61701583862305,
    MAXIMO: 39.773075103759766,
    MEDIA: 39.695045471191406,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54d23ba4-4dcc-4c29-b21b-6a1461cd85d5',
    CIDADE: 'Bebedouro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.15862274169922,
    MAXIMO: 36.65347671508789,
    MEDIA: 36.406049728393555,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed865a65-b9a0-4958-b607-e4263fb1667d',
    CIDADE: 'Bento de Abreu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.2252197265625,
    MAXIMO: 40.0643424987793,
    MEDIA: 39.77925100572153,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '514267ae-8c82-4ae9-9807-246e72f4941f',
    CIDADE: 'Bernardino de Campos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.733253479003906,
    MAXIMO: 39.90153121948242,
    MEDIA: 39.78719153743618,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81d2076e-4a44-4987-ae62-6f2e2d4a4ce1',
    CIDADE: 'Bertioga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.11495590209961,
    MAXIMO: 36.90578079223633,
    MEDIA: 36.51036834716797,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9071606-f5d3-45eb-a2eb-3ada7ec7ddb9',
    CIDADE: 'Bilac',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.859859466552734,
    MAXIMO: 39.40508270263672,
    MEDIA: 39.13569301919763,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '308b0f26-429f-4d78-a46a-436dfe61a86b',
    CIDADE: 'Birigui',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.24363327026367,
    MAXIMO: 39.10316467285156,
    MEDIA: 38.73555246988932,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa876521-2d60-45d4-9064-b2517df56e09',
    CIDADE: 'Biritiba Mirim',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.11495590209961,
    MAXIMO: 38.28421401977539,
    MEDIA: 37.23130854474967,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86148262-ba1f-4cb4-bbd5-ee9e392b95a7',
    CIDADE: 'Boa Esperança do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.99310302734375,
    MAXIMO: 42.39038848876953,
    MEDIA: 41.706006368001304,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6f6de14d-ed30-4884-a974-14f574dd05ba',
    CIDADE: 'Bocaina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.99310302734375,
    MAXIMO: 43.26582717895508,
    MEDIA: 42.213745766160855,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0f63a10-c77e-4ad3-83ba-2db8a393aa01',
    CIDADE: 'Bofete',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.142452239990234,
    MAXIMO: 41.600791931152344,
    MEDIA: 40.757205963134766,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '184ee258-08b0-414a-8741-61b75d732253',
    CIDADE: 'Boituva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.58489990234375,
    MAXIMO: 42.3460807800293,
    MEDIA: 41.97450303652798,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0731c815-6ad5-419d-8e74-a632bb51e1b2',
    CIDADE: 'Bom Jesus dos Perdões',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.47551345825195,
    MAXIMO: 41.16666030883789,
    MEDIA: 40.7563555898949,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9f3edd3-155e-4c25-9952-3f8bd8879b02',
    CIDADE: 'Bom Sucesso de Itararé',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.06359100341797,
    MAXIMO: 40.32432174682617,
    MEDIA: 40.16671401995473,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '148f79e6-b794-4470-827d-d12cda2d577e',
    CIDADE: 'Borá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.93151092529297,
    MAXIMO: 40.383270263671875,
    MEDIA: 40.210280594847454,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f896e29-1ed8-4bd7-b1ab-f69c7b6d35bd',
    CIDADE: 'Boracéia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.74842834472656,
    MAXIMO: 41.054534912109375,
    MEDIA: 40.8565748382282,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '05efe7a8-76b2-4907-83a9-2009ec64e25c',
    CIDADE: 'Borborema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.17429733276367,
    MAXIMO: 39.30766296386719,
    MEDIA: 38.65800674227366,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2e35aaf-1f5d-440b-a608-8c882a91d0bb',
    CIDADE: 'Borebi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.758060455322266,
    MAXIMO: 40.075382232666016,
    MEDIA: 40.0125497848279,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '313dd2f9-6bbe-427b-8910-92e2acfcb193',
    CIDADE: 'Botucatu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.37648391723633,
    MAXIMO: 43.89946746826172,
    MEDIA: 41.77542241414388,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '395c67b1-3898-48a5-ac90-465225468dd0',
    CIDADE: 'Bragança Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.966552734375,
    MAXIMO: 42.78107833862305,
    MEDIA: 42.37381553649902,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c11bb871-6da9-4617-b64e-653d4705264d',
    CIDADE: 'Braúna',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.84400177001953,
    MAXIMO: 39.35905838012695,
    MEDIA: 39.13693018194925,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f4b4a5e-ba0a-4754-a987-fcbf0208bcd0',
    CIDADE: 'Brejo Alegre',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.98137664794922,
    MAXIMO: 38.53774642944336,
    MEDIA: 38.30741726642773,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd4171a8-2837-4898-b0b0-a4f8fe3dedab',
    CIDADE: 'Brodowski',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.36952590942383,
    MAXIMO: 37.05936050415039,
    MEDIA: 36.71444320678711,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '83d4ef7f-204b-4133-99ea-bc0824cff7e5',
    CIDADE: 'Brotas',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.51960372924805,
    MAXIMO: 45.907108306884766,
    MEDIA: 45.3018856048584,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'acc829ce-3470-4840-971d-6c8e4db562cd',
    CIDADE: 'Buri',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.593448638916016,
    MAXIMO: 39.64072799682617,
    MEDIA: 39.614251454671226,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7ad9faf2-e4a3-49ab-a829-c4486b82f486',
    CIDADE: 'Buritama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.664955139160156,
    MAXIMO: 38.53886795043945,
    MEDIA: 37.995372857822645,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '830854ff-2ce0-4d9c-b6b5-61361c708644',
    CIDADE: 'Buritizal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.42697525024414,
    MAXIMO: 35.949005126953125,
    MEDIA: 35.705418377757766,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9814073-b3dd-498d-bf97-4ae1ecc853e3',
    CIDADE: 'Cabrália Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.504940032958984,
    MAXIMO: 39.61272048950195,
    MEDIA: 39.56762505441313,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6739b7d-66c8-42eb-b946-18b434d25359',
    CIDADE: 'Cabreúva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.26749801635742,
    MAXIMO: 41.44625473022461,
    MEDIA: 40.886626025145446,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ffc9201f-b7aa-49ed-bf4e-45f1584bc57f',
    CIDADE: 'Caçapava',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.2956428527832,
    MAXIMO: 38.60262680053711,
    MEDIA: 37.40234276807295,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac77bea3-b85b-4770-bf93-f85c9d1bb288',
    CIDADE: 'Cachoeira Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.84036636352539,
    MAXIMO: 36.191162109375,
    MEDIA: 36.01290709008627,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7e19492b-cbb1-481e-8f2b-aa7afd9aac44',
    CIDADE: 'Caconde',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.58192825317383,
    MAXIMO: 39.48903274536133,
    MEDIA: 38.44581897387071,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6782fa3b-2868-46d8-b5c9-fb0124bcf26f',
    CIDADE: 'Cafelândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.47055435180664,
    MAXIMO: 38.71607971191406,
    MEDIA: 38.629581451416016,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '343aee90-65da-4c03-9562-1bc558408525',
    CIDADE: 'Caiabu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.98579788208008,
    MAXIMO: 41.48954391479492,
    MEDIA: 41.31146523036428,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12a6a01e-791c-4602-a2da-e26787af203c',
    CIDADE: 'Caieiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.48371887207031,
    MAXIMO: 40.55516815185547,
    MEDIA: 40.51839217462527,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd68f5d6a-b1c3-49de-88b2-b1ebc57f15b4',
    CIDADE: 'Caiuá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.25636672973633,
    MAXIMO: 42.9428825378418,
    MEDIA: 42.59962463378906,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59bcdb5f-6e39-458c-9bbb-2745637382cb',
    CIDADE: 'Cajamar',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.26749801635742,
    MAXIMO: 40.75758743286133,
    MEDIA: 40.59589719546882,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '59ef1548-9d16-4874-aae0-5e937acac448',
    CIDADE: 'Cajati',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.93098831176758,
    MAXIMO: 39.9446907043457,
    MEDIA: 39.93783950805664,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e75d1a60-597b-4d63-84fd-82c0501e80d2',
    CIDADE: 'Cajobi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.204078674316406,
    MAXIMO: 36.71491241455078,
    MEDIA: 36.40253446938159,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b1229f1-13e3-4160-84d1-e7353d441090',
    CIDADE: 'Cajuru',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.95963668823242,
    MAXIMO: 37.800899505615234,
    MEDIA: 37.38026809692383,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe0f0581-7ab4-459e-a0ee-c43b754b63aa',
    CIDADE: 'Campina do Monte Alegre',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.608577728271484,
    MAXIMO: 39.695152282714844,
    MEDIA: 39.62744775687215,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a59b7c2a-6a21-4dc0-91f9-9cc66d02b2fd',
    CIDADE: 'Campinas',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.735172271728516,
    MAXIMO: 44.9144401550293,
    MEDIA: 44.28083038330078,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '681b4241-7463-4184-953c-2bc8697d45fc',
    CIDADE: 'Campo Limpo Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.75758743286133,
    MAXIMO: 41.910640716552734,
    MEDIA: 40.79194487931775,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3569e184-0423-4ed5-bb63-d62408667757',
    CIDADE: 'Campos do Jordão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.58771896362305,
    MAXIMO: 38.04493713378906,
    MEDIA: 37.308692605989336,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4be2bae7-4edc-41f7-beba-6735e90d4143',
    CIDADE: 'Campos Novos Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.818267822265625,
    MAXIMO: 39.98739242553711,
    MEDIA: 39.90283012390137,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '564e8810-f1f7-45d8-bb59-8306613d9ffb',
    CIDADE: 'Cananéia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.86703109741211,
    MAXIMO: 39.941165924072266,
    MEDIA: 39.90483856201172,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef4ef521-09e4-43a4-900c-e231b6ef79f0',
    CIDADE: 'Canas',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.958152770996094,
    MAXIMO: 35.958152770996094,
    MEDIA: 35.958152770996094,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae4cbea9-fa6e-447f-a802-a69fa6a25ebc',
    CIDADE: 'Cândido Mota',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.604156494140625,
    MAXIMO: 40.73213195800781,
    MEDIA: 40.66814422607422,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f433f08-a2d1-44bf-a805-2a094846b965',
    CIDADE: 'Cândido Rodrigues',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.907989501953125,
    MAXIMO: 38.620704650878906,
    MEDIA: 38.03168065889685,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2e43def-fd3a-46a4-b0b7-71a632105493',
    CIDADE: 'Canitar',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.935115814208984,
    MAXIMO: 40.043861389160156,
    MEDIA: 40.01434319676442,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f23a7a7c-684c-4e54-b0ba-7f70b1a827ed',
    CIDADE: 'Capão Bonito',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.653621673583984,
    MAXIMO: 39.936302185058594,
    MEDIA: 39.76860122680664,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3bef7a1-4fe2-4923-84a3-0ddaa5e1b301',
    CIDADE: 'Capela do Alto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.44466781616211,
    MAXIMO: 41.39834213256836,
    MEDIA: 40.90560801473395,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '990c2bc2-771f-4f0d-ace9-557a9fd06d7e',
    CIDADE: 'Capivari',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.23837661743164,
    MAXIMO: 45.34160232543945,
    MEDIA: 44.78998947143555,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5bb6f5d2-b5f6-4ef4-b97e-2e8a0638634e',
    CIDADE: 'Caraguatatuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.06813049316406,
    MAXIMO: 35.08808898925781,
    MEDIA: 35.07810974121094,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd625803a-0dae-47ab-b522-d615b80a16bf',
    CIDADE: 'Carapicuíba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.97282791137695,
    MAXIMO: 40.97282791137695,
    MEDIA: 40.97282791137695,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aab4533f-2203-4317-8165-a6b96068e826',
    CIDADE: 'Cardoso',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.53689193725586,
    MAXIMO: 35.782588958740234,
    MEDIA: 35.65974044799805,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b486561f-b76e-44e0-9c3a-e5b3cc95a9fb',
    CIDADE: 'Casa Branca',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.03059005737305,
    MAXIMO: 42.66395950317383,
    MEDIA: 41.65291213989258,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76348db1-7959-4bf4-b751-6e8ab58ab379',
    CIDADE: 'Cássia dos Coqueiros',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.841819763183594,
    MAXIMO: 37.72081756591797,
    MEDIA: 37.65918463397544,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1c6a439-0123-4863-92cd-03c5f266568b',
    CIDADE: 'Castilho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.191871643066406,
    MAXIMO: 40.719688415527344,
    MEDIA: 40.46064376831055,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '884491aa-9124-4461-9643-3c7834091bfa',
    CIDADE: 'Catanduva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.6678466796875,
    MAXIMO: 37.74989318847656,
    MEDIA: 37.27258270409199,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '34b1a4a0-f7a6-45fc-af09-4044d0783526',
    CIDADE: 'Catiguá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.71491241455078,
    MAXIMO: 37.21758270263672,
    MEDIA: 37.08283222123675,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '73409b4a-c8f9-4c31-87d4-a0bc5d6e84f4',
    CIDADE: 'Cedral',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.277896881103516,
    MAXIMO: 36.840370178222656,
    MEDIA: 36.642790606121544,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43dd4afd-df74-43ec-8386-b12c220a230b',
    CIDADE: 'Cerqueira César',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.70199203491211,
    MAXIMO: 39.98997497558594,
    MEDIA: 39.759633567957295,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3a4442f-8c9d-424a-9065-987ecbb9cf50',
    CIDADE: 'Cerquilho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.34082794189453,
    MAXIMO: 43.8519172668457,
    MEDIA: 42.033117252636636,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b43fa698-3fcd-4e62-a3ac-86998c0ba10d',
    CIDADE: 'Cesário Lange',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.249900817871094,
    MAXIMO: 43.29587936401367,
    MEDIA: 40.7572585390468,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c69d1b6a-aa2c-47f6-add9-0bcefe7a515b',
    CIDADE: 'Charqueada',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 46.087669372558594,
    MAXIMO: 46.19719696044922,
    MEDIA: 46.15285571199867,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41b39784-4d52-4d44-9797-cda76eea263a',
    CIDADE: 'Clementina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.10316467285156,
    MAXIMO: 39.647186279296875,
    MEDIA: 39.36155093753345,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10ab0b40-a284-4d43-be73-945fe83fb65f',
    CIDADE: 'Colina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.796844482421875,
    MAXIMO: 36.204078674316406,
    MEDIA: 36.08628625370391,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd57ac6f-2077-40a6-9d38-0f5c06cfe079',
    CIDADE: 'Colômbia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.25429916381836,
    MAXIMO: 35.401912689208984,
    MEDIA: 35.32810592651367,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0fb23d48-ff76-4ad8-9a0c-255af1b3d54c',
    CIDADE: 'Conchal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.409454345703125,
    MAXIMO: 45.46883010864258,
    MEDIA: 45.277696059042576,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15fabcec-a113-4572-a432-2134109ec658',
    CIDADE: 'Conchas',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.24530029296875,
    MAXIMO: 45.047569274902344,
    MEDIA: 43.72607930501302,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '600628d7-8d8e-412f-a387-e736680381e0',
    CIDADE: 'Cordeirópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.7880859375,
    MAXIMO: 46.08815002441406,
    MEDIA: 45.93276025448107,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa5af237-f6db-47a1-b8d1-63457a60614b',
    CIDADE: 'Coroados',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.53774642944336,
    MAXIMO: 39.10316467285156,
    MEDIA: 38.75789664846905,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14243db9-fcfb-4199-ba12-58183141ca8d',
    CIDADE: 'Coronel Macedo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.770294189453125,
    MAXIMO: 40.034339904785156,
    MEDIA: 39.956734733208485,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b99f313-fe44-464e-9d57-9557778227f1',
    CIDADE: 'Corumbataí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.5643310546875,
    MAXIMO: 45.69121170043945,
    MEDIA: 45.554287935198786,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39ec208f-541e-4e83-9f1c-3c26666d2461',
    CIDADE: 'Cosmópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.15670394897461,
    MAXIMO: 45.855472564697266,
    MEDIA: 45.49227954881993,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6307b91e-685d-4955-a7f2-34e7006dbf4b',
    CIDADE: 'Cosmorama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.64634323120117,
    MAXIMO: 36.654090881347656,
    MEDIA: 36.09375659056838,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a9bc7b8-0dea-49c8-8a16-804582b7cf75',
    CIDADE: 'Cotia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.97282791137695,
    MAXIMO: 42.02537155151367,
    MEDIA: 41.60280828373628,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19f64c75-2b79-4e70-8b33-4746701c392e',
    CIDADE: 'Cravinhos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.05936050415039,
    MAXIMO: 38.81795883178711,
    MEDIA: 38.184043172093844,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '272d3f2b-2184-4fbf-8e1d-937dc87e0649',
    CIDADE: 'Cristais Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.22890090942383,
    MAXIMO: 35.40721130371094,
    MEDIA: 35.30873931043008,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6edc10cb-3397-4b87-89dd-83253ea80127',
    CIDADE: 'Cruzália',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.88288879394531,
    MAXIMO: 41.495079040527344,
    MEDIA: 41.20070462342332,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72f8f38a-3fc2-4cc0-80fa-7d2224597c9c',
    CIDADE: 'Cruzeiro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.78862762451172,
    MAXIMO: 36.12913513183594,
    MEDIA: 35.97045599191881,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8eb85b06-f3a2-4cf2-82f8-a8a0d6145150',
    CIDADE: 'Cubatão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.709110260009766,
    MAXIMO: 40.043235778808594,
    MEDIA: 39.20055145546723,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b9d539e2-ac0c-4fa2-949f-dd72e6b781ac',
    CIDADE: 'Cunha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.044193267822266,
    MAXIMO: 36.387046813964844,
    MEDIA: 35.58561782836914,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '341e10c0-33c4-454d-a670-86ecc0d5a246',
    CIDADE: 'Descalvado',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.460784912109375,
    MAXIMO: 43.22258377075195,
    MEDIA: 42.341684341430664,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e49d2df-3ea3-4d02-8528-b990a7a91fc2',
    CIDADE: 'Diadema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.0057373046875,
    MAXIMO: 40.92044448852539,
    MEDIA: 40.85705900608985,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e48f255d-989e-4a16-a8e1-76de71205ff7',
    CIDADE: 'Dirce Reis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.3068962097168,
    MAXIMO: 38.0285758972168,
    MEDIA: 37.64790130090136,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77904f31-aad8-446f-a693-ce073d5d78ac',
    CIDADE: 'Divinolândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.10432052612305,
    MAXIMO: 40.68234634399414,
    MEDIA: 39.39460965404933,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a2145dcc-cf65-4b52-8a02-b4b30d616e05',
    CIDADE: 'Dobrada',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.75826644897461,
    MAXIMO: 39.81913757324219,
    MEDIA: 39.041046025446825,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c1ba0549-b69c-44ad-b20e-e90091cc9055',
    CIDADE: 'Dois Córregos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.580081939697266,
    MAXIMO: 44.66493225097656,
    MEDIA: 44.622507095336914,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75c329df-8158-4b2b-a340-1e11d6d45774',
    CIDADE: 'Dolcinópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.67545700073242,
    MAXIMO: 37.010589599609375,
    MEDIA: 36.94687405670068,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7ca71da9-f62a-472e-aec6-6c8803ce004a',
    CIDADE: 'Dourado',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.67080307006836,
    MAXIMO: 44.51960372924805,
    MEDIA: 43.55809841062886,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e35735f5-52e6-484c-b531-5dfd078ceead',
    CIDADE: 'Dracena',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.062992095947266,
    MAXIMO: 41.92166519165039,
    MEDIA: 41.56180276668271,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa73d6e8-0563-4113-93f8-e990967a9c0b',
    CIDADE: 'Duartina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.38325500488281,
    MAXIMO: 39.57755661010742,
    MEDIA: 39.497389947774316,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb8ca1e9-3220-48c9-89ef-5beb044148eb',
    CIDADE: 'Dumont',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.14613342285156,
    MAXIMO: 37.92100524902344,
    MEDIA: 37.89300032185561,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3873bbf7-1cc9-40bf-be17-def49994b164',
    CIDADE: 'Echaporã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.65080261230469,
    MAXIMO: 40.2747688293457,
    MEDIA: 39.95756862241963,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2fc85e00-c675-4206-bbc3-194465d79ae4',
    CIDADE: 'Eldorado',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.89009094238281,
    MAXIMO: 39.9812126159668,
    MEDIA: 39.93770662943522,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7bb1053-10c5-4945-8c4e-f54c0e259030',
    CIDADE: 'Elias Fausto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.74741744995117,
    MAXIMO: 44.863037109375,
    MEDIA: 43.84413845066324,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37d86054-0065-4c1e-a469-98ab86deab12',
    CIDADE: 'Elisiário',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.20755386352539,
    MAXIMO: 37.669315338134766,
    MEDIA: 37.22345268438165,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '719675b7-ecba-4bed-82c3-d3c1ce1e3752',
    CIDADE: 'Embaúba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.6678466796875,
    MAXIMO: 36.71491241455078,
    MEDIA: 36.67774042767898,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7227532-2ff8-4f7a-9cb6-b2a985c14e89',
    CIDADE: 'Embu das Artes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.97282791137695,
    MAXIMO: 41.89336013793945,
    MEDIA: 41.263910373142025,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6dc84ff0-daa7-4567-904e-0bd028e5def6',
    CIDADE: 'Embu-Guaçu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.55913543701172,
    MAXIMO: 42.21146774291992,
    MEDIA: 42.07264326065125,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de1c3abd-e632-48a4-a51c-4cd4fdf23898',
    CIDADE: 'Emilianópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.37455749511719,
    MAXIMO: 41.90665054321289,
    MEDIA: 41.53656460993006,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84cfe51f-b904-4e82-a53c-c040753c3b93',
    CIDADE: 'Engenheiro Coelho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.46883010864258,
    MAXIMO: 45.7880859375,
    MEDIA: 45.48979247166987,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1748a965-18a2-4887-ae69-6f3e9a39a053',
    CIDADE: 'Espírito Santo do Pinhal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.87862014770508,
    MAXIMO: 43.75727081298828,
    MEDIA: 43.31794548034668,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c47f5d71-3141-4219-954c-b8f8e06ec3d9',
    CIDADE: 'Espírito Santo do Turvo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.59324264526367,
    MAXIMO: 39.65970230102539,
    MEDIA: 39.62554218570631,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f840b60f-a595-4d1d-a6d1-41ea1dbab734',
    CIDADE: "Estrela d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.654964447021484,
    MAXIMO: 37.26426696777344,
    MEDIA: 36.882885349036734,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '536574cc-d867-44de-af53-bc06856f9931',
    CIDADE: 'Estrela do Norte',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.49010467529297,
    MAXIMO: 43.03560256958008,
    MEDIA: 42.77315365177255,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4872a5a7-caf5-4fde-896d-679f6d723e0f',
    CIDADE: 'Euclides da Cunha Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.699851989746094,
    MAXIMO: 45.29746627807617,
    MEDIA: 44.97536595662435,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93751679-bdaf-40de-9634-c83ae6587a7c',
    CIDADE: 'Fartura',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.86724853515625,
    MAXIMO: 40.18932342529297,
    MEDIA: 40.00772039450269,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31ed1d98-dc94-4d24-adc4-fe4b32087dc4',
    CIDADE: 'Fernandópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.3397216796875,
    MAXIMO: 37.26426696777344,
    MEDIA: 36.625972525054834,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66d1732e-2410-473f-923f-bdc1ae27237d',
    CIDADE: 'Fernando Prestes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.825199127197266,
    MAXIMO: 38.620704650878906,
    MEDIA: 37.988232189701364,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6fbde66-fde6-4ccb-9dd0-3af1a26a5277',
    CIDADE: 'Fernão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.351505279541016,
    MAXIMO: 39.504940032958984,
    MEDIA: 39.461542579393274,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eee1201a-b0e3-48ea-890b-8bdabe68c028',
    CIDADE: 'Ferraz de Vasconcelos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.160457611083984,
    MAXIMO: 39.160457611083984,
    MEDIA: 39.160457611083984,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1a2ba80-034c-4bc7-8a4f-896330f49ac9',
    CIDADE: 'Flora Rica',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.92145919799805,
    MAXIMO: 41.70606231689453,
    MEDIA: 41.27090498629225,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '754eb21d-be50-45a8-8c7c-14533945c695',
    CIDADE: 'Floreal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.667198181152344,
    MAXIMO: 37.613868713378906,
    MEDIA: 37.11275429928645,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '337e60a3-e6e7-4546-81a7-6a802b6474b7',
    CIDADE: 'Flórida Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.39106750488281,
    MAXIMO: 41.0536003112793,
    MEDIA: 40.70208586074464,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '231f752a-ca91-40f7-856c-0da746226933',
    CIDADE: 'Florínea',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.88288879394531,
    MAXIMO: 41.298316955566406,
    MEDIA: 41.194228727502754,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd96b176c-797b-45dd-aeee-7be878c6895a',
    CIDADE: 'Franca',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.22890090942383,
    MAXIMO: 35.29241180419922,
    MEDIA: 35.26065635681152,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87a25d73-a32f-4f86-bef4-c8c7ae80e445',
    CIDADE: 'Francisco Morato',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.75758743286133,
    MAXIMO: 40.823543548583984,
    MEDIA: 40.81015706521572,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50607a94-c7d5-45aa-b090-030249820fff',
    CIDADE: 'Franco da Rocha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.48371887207031,
    MAXIMO: 40.823543548583984,
    MEDIA: 40.698780507072456,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ca31b6a-689a-4e83-9e29-21acf8e2d955',
    CIDADE: 'Gabriel Monteiro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.10316467285156,
    MAXIMO: 39.647186279296875,
    MEDIA: 39.43983893558399,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c871086-22f8-4e19-8e1a-5482c9e61412',
    CIDADE: 'Gália',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.351505279541016,
    MAXIMO: 39.38325500488281,
    MEDIA: 39.367380142211914,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42d2a0f7-7afb-46b0-a58d-fda34940b76e',
    CIDADE: 'Garça',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.15476989746094,
    MAXIMO: 39.66222381591797,
    MEDIA: 39.315046981981695,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '948a096a-7f17-4643-aa42-4eee07ffbb79',
    CIDADE: 'Gastão Vidigal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.303829193115234,
    MAXIMO: 37.613868713378906,
    MEDIA: 37.51771021201889,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c7c88d9-6cb9-4cd2-95b6-bf71fb05125d',
    CIDADE: 'Gavião Peixoto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.81913757324219,
    MAXIMO: 41.734527587890625,
    MEDIA: 40.72330142564952,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd5776c7-917a-4dec-889b-8a3d8ab6af18',
    CIDADE: 'General Salgado',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.255889892578125,
    MAXIMO: 38.25646209716797,
    MEDIA: 37.55660400059331,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7f21786-98d0-4d04-83d4-b93e11d7319c',
    CIDADE: 'Getulina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.89696502685547,
    MAXIMO: 39.11711502075195,
    MEDIA: 39.00704002380371,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf7c9d8e-5894-4d6b-9fbe-5d012df4444c',
    CIDADE: 'Glicério',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.98137664794922,
    MAXIMO: 38.84400177001953,
    MEDIA: 38.55347349033665,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e685361-4afd-48b7-b1a5-5e2746b721ec',
    CIDADE: 'Guaiçara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.925567626953125,
    MAXIMO: 38.89696502685547,
    MEDIA: 38.46617905581766,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bf96bf66-ecf3-413d-bd17-a67278e52e8e',
    CIDADE: 'Guaimbê',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.75071716308594,
    MAXIMO: 39.1818733215332,
    MEDIA: 39.00553511017954,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6773394b-495f-47c3-97b9-760d6ab189d1',
    CIDADE: 'Guaíra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.55170822143555,
    MAXIMO: 35.76605224609375,
    MEDIA: 35.630003929138184,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa58021d-c5cb-4caf-8a0a-7448f9e60176',
    CIDADE: 'Guapiaçu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.895843505859375,
    MAXIMO: 36.358726501464844,
    MEDIA: 36.190275880656394,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '551f0a5f-6c68-44d8-b4d5-665ba654de01',
    CIDADE: 'Guapiara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.75154495239258,
    MAXIMO: 39.76024627685547,
    MEDIA: 39.75589561462402,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ab82d80-fbb7-4dd3-babc-bb8aa6d041b2',
    CIDADE: 'Guará',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.10365676879883,
    MAXIMO: 35.264251708984375,
    MEDIA: 35.1839542388916,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '121a720a-5451-4a90-8f01-aaafe44237bf',
    CIDADE: 'Guaraçaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.45735549926758,
    MAXIMO: 41.170162200927734,
    MEDIA: 40.34956665847078,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8c23001-f73d-4f73-9f5b-0a88de113b13',
    CIDADE: 'Guaraci',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 34.997798919677734,
    MAXIMO: 35.82771682739258,
    MEDIA: 35.35356297208508,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ae8266e-4b40-4be5-bb5f-49dc02b8fd36',
    CIDADE: "Guarani d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.097572326660156,
    MAXIMO: 36.67545700073242,
    MEDIA: 36.4082838879827,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52782cdb-fd4f-4ad0-98db-0d1502d73f2a',
    CIDADE: 'Guarantã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.702110290527344,
    MAXIMO: 39.22660446166992,
    MEDIA: 38.91346631290428,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94c628ac-c533-46c3-8f46-638b87d6f44a',
    CIDADE: 'Guararapes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.163673400878906,
    MAXIMO: 39.733604431152344,
    MEDIA: 39.374165852864586,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71a9012b-1e0d-4a1a-8dd5-ff6a1ae8530a',
    CIDADE: 'Guararema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.42306900024414,
    MAXIMO: 39.17539596557617,
    MEDIA: 38.050320140949786,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56480393-31f4-4a39-961d-3c5cac89ef98',
    CIDADE: 'Guaratinguetá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.710975646972656,
    MAXIMO: 36.58771896362305,
    MEDIA: 36.09218692779541,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75b5f92c-3a1c-43f6-a386-64d98bc371d1',
    CIDADE: 'Guareí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.91879653930664,
    MAXIMO: 39.923973083496094,
    MEDIA: 39.92138481140137,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39714646-df57-49d4-a649-117cbefac942',
    CIDADE: 'Guariba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.95794677734375,
    MAXIMO: 38.81720733642578,
    MEDIA: 38.586367780168835,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '690822ab-0c04-4807-a0aa-cb5afeeca28d',
    CIDADE: 'Guarujá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.9725341796875,
    MAXIMO: 38.709110260009766,
    MEDIA: 37.80087084924114,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d0a51b9-fd25-4d63-8e2d-a13cf75a4521',
    CIDADE: 'Guarulhos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.45594024658203,
    MAXIMO: 40.48808288574219,
    MEDIA: 39.882711755310496,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e0061c0-84d6-4f40-bfef-81bd3ae82d60',
    CIDADE: 'Guatapará',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.92100524902344,
    MAXIMO: 39.823734283447266,
    MEDIA: 38.834971406282186,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8578237-517a-42e7-a255-9785540a5cb9',
    CIDADE: 'Guzolândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.9388313293457,
    MAXIMO: 38.63897705078125,
    MEDIA: 38.24348656489788,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e799b19-0e19-4e03-b774-ce4710bf5c17',
    CIDADE: 'Herculândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.32293701171875,
    MAXIMO: 40.21969985961914,
    MEDIA: 39.72601352381711,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55a7b4cd-900b-4bba-b5c9-c62b138f6f0c',
    CIDADE: 'Holambra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.9144401550293,
    MAXIMO: 45.55939865112305,
    MEDIA: 45.334491373038524,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '900993a5-5df1-4ca3-843f-9fdd2b39df11',
    CIDADE: 'Hortolândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.19287872314453,
    MAXIMO: 45.15670394897461,
    MEDIA: 44.27174321599764,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52831a1f-852a-450e-817f-d7deb314592e',
    CIDADE: 'Iacanga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.0096435546875,
    MAXIMO: 39.706180572509766,
    MEDIA: 39.356981913248696,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11dff0c7-46ab-4002-b5a7-4ae94a46c48d',
    CIDADE: 'Iacri',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.647186279296875,
    MAXIMO: 40.35125732421875,
    MEDIA: 39.95372234002025,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b961bf80-1a0e-447e-824c-acc9fbbd8ce0',
    CIDADE: 'Iaras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.773826599121094,
    MAXIMO: 39.77497482299805,
    MEDIA: 39.77440071105957,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e82f531-bc5e-4cff-963f-eb1a322fd13d',
    CIDADE: 'Ibaté',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.3417854309082,
    MAXIMO: 44.50520706176758,
    MEDIA: 42.986730615685346,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb09a7d9-50a8-412d-9699-e48145d6dcee',
    CIDADE: 'Ibirá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.737789154052734,
    MAXIMO: 37.20755386352539,
    MEDIA: 37.07423523351972,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47ff2681-3cbc-4e39-8084-011a4b82f587',
    CIDADE: 'Ibirarema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.14301300048828,
    MAXIMO: 40.29408264160156,
    MEDIA: 40.21854782104492,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf80b3cb-70c1-4edb-b3be-d7b7ee678885',
    CIDADE: 'Ibitinga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.30766296386719,
    MAXIMO: 39.72114944458008,
    MEDIA: 39.51440620422363,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb4a76a2-a186-43a3-9fc8-92015f532543',
    CIDADE: 'Ibiúna',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.7778205871582,
    MAXIMO: 42.14434814453125,
    MEDIA: 41.982513427734375,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5993b249-3c4e-4ed2-88b6-cfb4d07497b7',
    CIDADE: 'Icém',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.27801513671875,
    MAXIMO: 35.33488464355469,
    MEDIA: 35.30644989013672,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b0cfbf0-3c31-46c7-97c9-483ae3beb3c4',
    CIDADE: 'Iepê',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.62110900878906,
    MAXIMO: 41.824981689453125,
    MEDIA: 41.723045349121094,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '602b58f9-149b-45b4-a1c3-dcd95ab1b99c',
    CIDADE: 'Igaraçu do Tietê',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.03602600097656,
    MAXIMO: 43.440006256103516,
    MEDIA: 42.33272938665905,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d75e631-3f83-408b-974c-85fc8d9fe5d9',
    CIDADE: 'Igarapava',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.72697067260742,
    MAXIMO: 36.614383697509766,
    MEDIA: 36.17025755523491,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fbc2ed73-6b7f-4519-a791-81d1b3286386',
    CIDADE: 'Igaratá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.42868423461914,
    MAXIMO: 40.29782485961914,
    MEDIA: 39.439191453422254,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41471ce6-b600-4299-beb1-bfa646b916b0',
    CIDADE: 'Iguape',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.08820724487305,
    MAXIMO: 41.13163375854492,
    MEDIA: 40.604880809783936,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09cda662-aa22-437a-bd3a-d84c51ec1018',
    CIDADE: 'Ilhabela',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 34.56462478637695,
    MAXIMO: 34.78792190551758,
    MEDIA: 34.64082108011692,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e7d0ed4b-b4e2-46cf-8ccd-da77e46d0090',
    CIDADE: 'Ilha Comprida',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.76472091674805,
    MAXIMO: 40.09817123413086,
    MEDIA: 39.93111376493956,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b561e603-d53e-41cf-bc2e-abef77033da2',
    CIDADE: 'Ilha Solteira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.47136688232422,
    MAXIMO: 39.86701583862305,
    MEDIA: 39.15726490693296,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06adf477-a5c9-417e-9a85-79cc6daffe91',
    CIDADE: 'Indaiatuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.44625473022461,
    MAXIMO: 44.863037109375,
    MEDIA: 42.827839629775404,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95a63616-9390-415b-ac19-ea48efdd8385',
    CIDADE: 'Indiana',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.48954391479492,
    MAXIMO: 41.81912612915039,
    MEDIA: 41.52160840161649,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86a0a281-63c8-41b9-84ec-427d010aafe6',
    CIDADE: 'Indiaporã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.577537536621094,
    MAXIMO: 36.39699172973633,
    MEDIA: 36.05470538083955,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac4dd07e-74f1-4e2e-99e1-804bafb25436',
    CIDADE: 'Inúbia Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.2868766784668,
    MAXIMO: 40.786983489990234,
    MEDIA: 40.45086375091271,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a04ded79-f215-4897-aa94-b4102ce05173',
    CIDADE: 'Ipaussu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.811805725097656,
    MAXIMO: 39.90153121948242,
    MEDIA: 39.89736416243591,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94c06d54-8687-4216-8441-23f4563f1850',
    CIDADE: 'Iperó',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.58489990234375,
    MAXIMO: 41.824859619140625,
    MEDIA: 41.43412372473055,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70d3da11-02bc-428b-9dd4-82508185ca28',
    CIDADE: 'Ipeúna',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.63957977294922,
    MAXIMO: 46.19719696044922,
    MEDIA: 46.08923577505699,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a18c27f5-68e9-44da-b4e3-4fcf6b903e85',
    CIDADE: 'Ipiguá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.781715393066406,
    MAXIMO: 36.470218658447266,
    MEDIA: 36.103397994511745,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4e3dce6-b0e2-40f8-a909-81c686521955',
    CIDADE: 'Iporanga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.87028121948242,
    MAXIMO: 39.91728591918945,
    MEDIA: 39.89236704508463,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '451e5aa7-3b96-4434-ab8d-986e58e87641',
    CIDADE: 'Ipuã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.153785705566406,
    MAXIMO: 35.40559387207031,
    MEDIA: 35.27968978881836,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7927271a-e92a-4c1c-8040-e7b78389519f',
    CIDADE: 'Iracemápolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.84468078613281,
    MAXIMO: 46.19719696044922,
    MEDIA: 46.07219255996224,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58d7d740-d621-45cf-b6f4-8d008859d5e7',
    CIDADE: 'Irapuã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.300193786621094,
    MAXIMO: 38.060062408447266,
    MEDIA: 37.663490183329884,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '80a925de-d684-4250-b52b-83c12042cdbf',
    CIDADE: 'Irapuru',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.51762771606445,
    MAXIMO: 41.25814437866211,
    MEDIA: 41.059930427329505,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f4b1120-e461-4c71-aa6d-772047bf31fe',
    CIDADE: 'Itaberá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.848262786865234,
    MAXIMO: 39.99897766113281,
    MEDIA: 39.94039249420166,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8b95ca9-e7c4-4449-8e61-ba19101bf3d8',
    CIDADE: 'Itaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.667747497558594,
    MAXIMO: 39.770294189453125,
    MEDIA: 39.71792475382487,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '172522a6-5515-4d77-81ac-cd0bc4892fde',
    CIDADE: 'Itajobi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.669315338134766,
    MAXIMO: 38.291725158691406,
    MEDIA: 37.90727807866059,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0d1e58f-b23a-4230-9ba7-4de8340fe1bb',
    CIDADE: 'Itaju',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.706180572509766,
    MAXIMO: 40.99310302734375,
    MEDIA: 40.28318640068401,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e856ffa-862b-40d6-921b-a88ea51fb7d2',
    CIDADE: 'Itanhaém',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.86351776123047,
    MAXIMO: 42.9182014465332,
    MEDIA: 42.382533701122995,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '162de6e8-02ab-45a0-9fb3-1462687f553b',
    CIDADE: 'Itaoca',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.986976623535156,
    MAXIMO: 40.2081298828125,
    MEDIA: 40.05428055324449,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ec685a14-876a-448a-9181-eb29ad6d73bb',
    CIDADE: 'Itapecerica da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.55913543701172,
    MAXIMO: 41.89336013793945,
    MEDIA: 41.582420073407725,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c85f3d1-f231-492c-872d-ec0d28d67fa5',
    CIDADE: 'Itapetininga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.67286682128906,
    MAXIMO: 40.06093978881836,
    MEDIA: 39.861124420166014,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0eb69b2-c23a-4096-9c2c-b61f593b0b93',
    CIDADE: 'Itapeva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.65127182006836,
    MAXIMO: 39.84644317626953,
    MEDIA: 39.73422132219587,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a51aa05-54a6-4ab3-a960-1be597a5fac3',
    CIDADE: 'Itapevi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.97282791137695,
    MAXIMO: 41.25457000732422,
    MEDIA: 41.25162224022776,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70824303-c306-4832-b53b-8d98ead81802',
    CIDADE: 'Itapira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.58402633666992,
    MAXIMO: 44.412986755371094,
    MEDIA: 43.99850654602051,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8dd527e7-11cc-44f7-b28d-f2a34d5ee758',
    CIDADE: 'Itapirapuã Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.16029357910156,
    MAXIMO: 40.47648620605469,
    MEDIA: 40.33785024363513,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a9cdc70-2bb9-487a-ab65-23e5e855ad61',
    CIDADE: 'Itápolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.291725158691406,
    MAXIMO: 39.396236419677734,
    MEDIA: 38.815730285644534,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fca3650a-e2cf-4f8b-b378-6cdcef1d8025',
    CIDADE: 'Itaporanga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.034339904785156,
    MAXIMO: 40.132144927978516,
    MEDIA: 40.083242416381836,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f15ad5c6-7186-42e2-b636-f07db70784a5',
    CIDADE: 'Itapuí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.74842834472656,
    MAXIMO: 42.076717376708984,
    MEDIA: 41.64296897727245,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23bc9eda-bb0c-479c-aa82-a27abe1db2d1',
    CIDADE: 'Itapura',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.56370162963867,
    MAXIMO: 40.191871643066406,
    MEDIA: 39.85744907228393,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '864dd5c7-5fcc-4fb3-a798-6486950c4a6e',
    CIDADE: 'Itaquaquecetuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.160457611083984,
    MAXIMO: 39.45594024658203,
    MEDIA: 39.42295908426641,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74fb5feb-b9b1-4cd9-b53a-c085b3344915',
    CIDADE: 'Itararé',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.06523132324219,
    MAXIMO: 40.25226974487305,
    MEDIA: 40.15232594807943,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bef60ec3-8217-4d96-b0df-b0554f2354ca',
    CIDADE: 'Itariri',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.99115753173828,
    MAXIMO: 41.99952697753906,
    MEDIA: 41.51436728310853,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '365032c0-94ff-4be5-9598-28672c3d71fd',
    CIDADE: 'Itatiba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.6740608215332,
    MAXIMO: 43.735172271728516,
    MEDIA: 42.553760259594554,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a0cbf38-6bdc-459d-a82a-5c12ef0c64bb',
    CIDADE: 'Itatinga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.785736083984375,
    MAXIMO: 40.209266662597656,
    MEDIA: 39.997501373291016,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb4253da-2cc3-4e95-84fd-9f2100132012',
    CIDADE: 'Itirapina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.63957977294922,
    MAXIMO: 46.087669372558594,
    MEDIA: 45.863624572753906,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d3bdd80-1857-4f3d-b0fd-7909fdd52a79',
    CIDADE: 'Itirapuã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.190582275390625,
    MAXIMO: 35.54071044921875,
    MEDIA: 35.20104323156483,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e755c2d4-2271-46d6-abac-f69955ba8279',
    CIDADE: 'Itobi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.68234634399414,
    MAXIMO: 41.03059005737305,
    MEDIA: 40.885795018439836,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd088b29b-871d-4a36-b6c7-910d9dac066e',
    CIDADE: 'Itu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.15605545043945,
    MAXIMO: 42.4221305847168,
    MEDIA: 41.688955307006836,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b84fd541-b9a2-450f-a7d5-46b30132ca32',
    CIDADE: 'Itupeva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.26749801635742,
    MAXIMO: 42.784183502197266,
    MEDIA: 41.72824359672709,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '966dc8ce-b7b1-49d3-a945-c347195732c1',
    CIDADE: 'Ituverava',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.42697525024414,
    MAXIMO: 35.474876403808594,
    MEDIA: 35.446966807047524,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43b8dd01-5cb9-406d-b8ba-85d87f1e3f70',
    CIDADE: 'Jaborandi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.72153091430664,
    MAXIMO: 36.15862274169922,
    MEDIA: 35.811642866726785,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f4ee6190-86bd-419c-bd76-179b3c7da3fb',
    CIDADE: 'Jaboticabal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.23713302612305,
    MAXIMO: 37.96636199951172,
    MEDIA: 37.60174751281738,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dbfbff2-b4a8-431a-939b-7176b447c5e6',
    CIDADE: 'Jacareí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.16084289550781,
    MAXIMO: 39.17539596557617,
    MEDIA: 38.119992710244105,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2849713f-8874-41ca-9f6f-75e9623c3086',
    CIDADE: 'Jaci',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.60188293457031,
    MAXIMO: 37.02569580078125,
    MEDIA: 36.99375362678922,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7cbd07f-17c5-493c-8eff-9a1e6d3a89d6',
    CIDADE: 'Jacupiranga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.91428756713867,
    MAXIMO: 40.00432586669922,
    MEDIA: 39.959306716918945,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c8a1240-a106-464b-9438-a3b4605b8bc7',
    CIDADE: 'Jaguariúna',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.9144401550293,
    MAXIMO: 45.265838623046875,
    MEDIA: 45.03303338023613,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0ec8ec6-0c8b-4d7b-9acd-15dd40b7bee0',
    CIDADE: 'Jales',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.010589599609375,
    MAXIMO: 37.3068962097168,
    MEDIA: 37.158742904663086,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a2c9750-2e43-4d93-a092-5388648c5880',
    CIDADE: 'Jambeiro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.47538375854492,
    MAXIMO: 37.669761657714844,
    MEDIA: 36.981238181766756,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba310730-02c3-4353-9d56-a0787c89562c',
    CIDADE: 'Jandira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.97282791137695,
    MAXIMO: 41.25457000732422,
    MEDIA: 41.01575664897712,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a0b05af-fcc9-4310-9e74-1af07cf07e67',
    CIDADE: 'Jardinópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.40536117553711,
    MAXIMO: 36.42570114135742,
    MEDIA: 36.415531158447266,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c39a095-6143-4a3a-bb9a-a3bfe1d83068',
    CIDADE: 'Jarinu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.75758743286133,
    MAXIMO: 43.40546417236328,
    MEDIA: 41.57971929491938,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bf5a51d6-9750-416f-9604-71c5a677f079',
    CIDADE: 'Jaú',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.076717376708984,
    MAXIMO: 43.26582717895508,
    MEDIA: 42.51436233520508,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e326f361-bfa4-475e-a48e-94b93a2f36b0',
    CIDADE: 'Jeriquara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.264251708984375,
    MAXIMO: 35.439048767089844,
    MEDIA: 35.41738793037719,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01d00f17-4e0b-4a7e-b736-49ca3066fcc3',
    CIDADE: 'Joanópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.29782485961914,
    MAXIMO: 41.124271392822266,
    MEDIA: 40.7110481262207,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0b4441e-401a-400d-b8eb-e3549fdf1637',
    CIDADE: 'João Ramalho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.51640319824219,
    MAXIMO: 41.17477798461914,
    MEDIA: 40.79334513346354,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6e03d93-e417-4298-bc17-ae488a7f7adc',
    CIDADE: 'José Bonifácio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.1514892578125,
    MAXIMO: 37.759918212890625,
    MEDIA: 37.49404271443685,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '270af551-1df0-4910-a160-9997efdc93b0',
    CIDADE: 'Júlio Mesquita',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.98672103881836,
    MAXIMO: 39.22660446166992,
    MEDIA: 39.02432948223998,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7239b2bb-aa87-42f8-b004-c1d0259764cb',
    CIDADE: 'Jumirim',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.29587936401367,
    MAXIMO: 43.29587936401367,
    MEDIA: 43.29587936401367,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aab4899c-4114-4c60-8b6f-44802cb4fe29',
    CIDADE: 'Jundiaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.26749801635742,
    MAXIMO: 42.09383773803711,
    MEDIA: 41.011999479558256,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75c584ec-eecb-47d2-a5b5-d27d538f8dad',
    CIDADE: 'Junqueirópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.839054107666016,
    MAXIMO: 41.062992095947266,
    MEDIA: 40.95102310180664,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '445b7942-9396-4157-ad48-7c7d18a0fcb1',
    CIDADE: 'Juquiá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.72189712524414,
    MAXIMO: 40.833648681640625,
    MEDIA: 40.77777290344238,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22a28f70-adce-4c2c-8c6f-132901cd365f',
    CIDADE: 'Juquitiba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.09140396118164,
    MAXIMO: 42.9182014465332,
    MEDIA: 42.33977121983844,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '00d10018-67f2-402e-a987-4dc6e31c8419',
    CIDADE: 'Lagoinha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.33061599731445,
    MAXIMO: 36.087318420410156,
    MEDIA: 35.626386812753374,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9edd9c6b-20d2-4f47-b80b-26d8c53de428',
    CIDADE: 'Laranjal Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.34082794189453,
    MAXIMO: 45.087745666503906,
    MEDIA: 44.0361217762758,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6993bd83-27e6-4ef5-a507-f3d17e862b35',
    CIDADE: 'Lavínia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.374691009521484,
    MAXIMO: 40.726036071777344,
    MEDIA: 39.87343780573154,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ba78b9c-48d9-4cef-9688-d193a3157ece',
    CIDADE: 'Lavrinhas',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.864158630371094,
    MAXIMO: 36.12913513183594,
    MEDIA: 35.996646881103516,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '705f91ca-4e10-4be8-b579-2b44ccc880e3',
    CIDADE: 'Leme',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.98227310180664,
    MAXIMO: 45.531822204589844,
    MEDIA: 44.75183883989879,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f40b1e36-5a8e-4d58-ab07-fec9ae84f0a4',
    CIDADE: 'Lençóis Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.075382232666016,
    MAXIMO: 40.9490852355957,
    MEDIA: 40.580169677734375,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e5445de-9719-4254-8806-3eb43d921f47',
    CIDADE: 'Limeira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.855472564697266,
    MAXIMO: 46.08815002441406,
    MEDIA: 45.971811294555664,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3df888b-131c-4d9b-813e-ba4018bcf05e',
    CIDADE: 'Lindóia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.58402633666992,
    MAXIMO: 43.8931884765625,
    MEDIA: 43.75471322480964,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '26daa81e-1728-45b3-91f9-0c2d4cac993a',
    CIDADE: 'Lins',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.47209548950195,
    MAXIMO: 38.75071716308594,
    MEDIA: 38.611406326293945,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e74c4d95-f266-4e58-a255-5c3a65e2713b',
    CIDADE: 'Lorena',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.710975646972656,
    MAXIMO: 36.191162109375,
    MEDIA: 35.923854642193,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '944d19a1-b40b-455c-a4a8-2395cda18c08',
    CIDADE: 'Lourdes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.664955139160156,
    MAXIMO: 37.938804626464844,
    MEDIA: 37.93432511638107,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd46b4a7-6094-46dd-8cba-eb60c8d7f75d',
    CIDADE: 'Louveira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.910640716552734,
    MAXIMO: 42.09383773803711,
    MEDIA: 42.041386764511564,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c27c9d3b-f44a-4eab-bd95-f121dbe1e666',
    CIDADE: 'Lucélia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.0643424987793,
    MAXIMO: 40.786983489990234,
    MEDIA: 40.45313906330904,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98d2eaae-a977-4fd3-92d2-2a034cb765c3',
    CIDADE: 'Lucianópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.50041961669922,
    MAXIMO: 39.613075256347656,
    MEDIA: 39.52774838167082,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7feeeadb-f2cf-41ea-bda9-2aeedf595899',
    CIDADE: 'Luís Antônio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.81795883178711,
    MAXIMO: 39.98113250732422,
    MEDIA: 39.399545669555664,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2cad9bad-452e-47b8-9229-6f61fc194cd8',
    CIDADE: 'Luiziânia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.10519027709961,
    MAXIMO: 39.57204055786133,
    MEDIA: 39.295832367108105,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43d914c1-ee8f-47b7-97d8-c7dfdc739ebf',
    CIDADE: 'Lupércio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.56255340576172,
    MAXIMO: 39.818267822265625,
    MEDIA: 39.58094771451607,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ba00bdc-04be-497d-b5e6-7269644b6446',
    CIDADE: 'Lutécia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.10016632080078,
    MAXIMO: 40.2747688293457,
    MEDIA: 40.18746757507324,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0531be29-3440-417d-a8f0-ec2306fc903b',
    CIDADE: 'Macatuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.9490852355957,
    MAXIMO: 42.20054244995117,
    MEDIA: 41.549476620628944,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db872880-5aa1-4847-a590-fa41593218e0',
    CIDADE: 'Macaubal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.04746627807617,
    MAXIMO: 37.664955139160156,
    MEDIA: 37.17892427952882,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e1cab7e-4afd-45cd-ba0f-32cdcb5caee8',
    CIDADE: 'Macedônia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.813377380371094,
    MAXIMO: 36.35713195800781,
    MEDIA: 36.16818250061599,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb5e40f8-ff45-4bcd-b9ca-3aa29fa9c73c',
    CIDADE: 'Magda',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.667198181152344,
    MAXIMO: 37.613868713378906,
    MEDIA: 37.14826795120859,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6c742fbd-50ce-4f41-b00d-e7c5963f4b40',
    CIDADE: 'Mairinque',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.15605545043945,
    MAXIMO: 41.731380462646484,
    MEDIA: 41.51386789257677,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '38c11135-2981-4d77-a649-7243826a3f91',
    CIDADE: 'Mairiporã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.8795280456543,
    MAXIMO: 40.823543548583984,
    MEDIA: 40.46977432580916,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '055952da-4871-4e92-8a27-ca25f142f4c7',
    CIDADE: 'Manduri',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.70199203491211,
    MAXIMO: 39.787628173828125,
    MEDIA: 39.73104546207685,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f54e9012-2e29-497e-aee4-8632afa5e4d7',
    CIDADE: 'Marabá Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.80748748779297,
    MAXIMO: 43.75844192504883,
    MEDIA: 43.281386375427246,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '033dc905-b0ff-4ae9-85be-714503e126db',
    CIDADE: 'Maracaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.708831787109375,
    MAXIMO: 41.495079040527344,
    MEDIA: 41.07372156778971,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f0acae9-e158-4091-9a83-dd6b26cbddd7',
    CIDADE: 'Marapoama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.20755386352539,
    MAXIMO: 37.674339294433594,
    MEDIA: 37.648207962013345,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62426cb7-4c9d-4709-90f7-bb59aa50a33c',
    CIDADE: 'Mariápolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.786983489990234,
    MAXIMO: 41.29881286621094,
    MEDIA: 40.960336970416044,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3236186-52b3-417d-96e5-0955fc2826f8',
    CIDADE: 'Marília',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.32653045654297,
    MAXIMO: 39.82461929321289,
    MEDIA: 39.57450866699219,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9af6b67c-7e15-4a75-af0f-a17989791ab0',
    CIDADE: 'Marinópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.0285758972168,
    MAXIMO: 38.42200469970703,
    MEDIA: 38.24182884642861,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '835719b2-9fdd-4061-a82b-456877ff62bc',
    CIDADE: 'Martinópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.98579788208008,
    MAXIMO: 41.79897689819336,
    MEDIA: 41.312825520833336,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '198dcc07-61ac-44af-a30d-83b181588bc4',
    CIDADE: 'Matão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.66611099243164,
    MAXIMO: 39.81913757324219,
    MEDIA: 39.742624282836914,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eeab7812-4cce-4073-ae76-fb1232501b47',
    CIDADE: 'Mauá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.9421501159668,
    MAXIMO: 40.0057373046875,
    MEDIA: 39.74001881627459,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b718484-0236-4ee9-a686-6ce658e1cd15',
    CIDADE: 'Mendonça',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.300193786621094,
    MAXIMO: 37.755428314208984,
    MEDIA: 37.488334566295464,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85892a29-25ee-4a5f-9766-0a3b946fac0d',
    CIDADE: 'Meridiano',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.3397216796875,
    MAXIMO: 36.96439743041992,
    MEDIA: 36.692498636778616,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65dbaf15-6678-4853-9d2a-4de9a6013bbe',
    CIDADE: 'Mesópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.493370056152344,
    MAXIMO: 37.10480880737305,
    MEDIA: 36.71115616184754,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1889be94-8fcf-413b-b44f-c49b0d215ca0',
    CIDADE: 'Miguelópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.59706115722656,
    MAXIMO: 36.07275390625,
    MEDIA: 35.92425060272217,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '638fc547-ebf7-413a-86a0-8af47c05f9bb',
    CIDADE: 'Mineiros do Tietê',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.27085876464844,
    MAXIMO: 44.66493225097656,
    MEDIA: 43.52140081023206,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c9caaa8-1286-41dd-b938-e81496d06b71',
    CIDADE: 'Miracatu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.05709457397461,
    MAXIMO: 41.66997146606445,
    MEDIA: 41.379198710123696,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87245959-adb0-431f-a2c7-d985abf586db',
    CIDADE: 'Mira Estrela',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.577537536621094,
    MAXIMO: 36.097572326660156,
    MEDIA: 35.80030477829121,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31af9822-ad4c-4586-9b6e-14764fe45a4e',
    CIDADE: 'Mirandópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.72113800048828,
    MAXIMO: 40.51762771606445,
    MEDIA: 40.1219425201416,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '272325ed-b342-4c84-817e-439d1a85f080',
    CIDADE: 'Mirante do Paranapanema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.0340461730957,
    MAXIMO: 43.5899658203125,
    MEDIA: 43.280128479003906,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cdc76674-6626-4b67-bd20-2e1dd8628a35',
    CIDADE: 'Mirassol',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.10377502441406,
    MAXIMO: 37.02569580078125,
    MEDIA: 36.57751272982729,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c52b5dc9-18cf-4dd0-8c31-471e3bd74790',
    CIDADE: 'Mirassolândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.781715393066406,
    MAXIMO: 36.2624626159668,
    MEDIA: 36.05235660800824,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e66f909-67fb-4f7c-916c-b74ba4cb1670',
    CIDADE: 'Mococa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.34721755981445,
    MAXIMO: 38.73891830444336,
    MEDIA: 38.561238606770836,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6435b133-f81f-4885-8da2-1a5c53d5a561',
    CIDADE: 'Mogi das Cruzes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.87972640991211,
    MAXIMO: 38.702762603759766,
    MEDIA: 38.288901011149086,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5d8b2fa-14c8-4580-9905-4f4fc9bcb8bc',
    CIDADE: 'Mogi Guaçu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.98227310180664,
    MAXIMO: 44.97618865966797,
    MEDIA: 44.455972035725914,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '933f2059-c0ae-419c-b956-2f38922a4ae2',
    CIDADE: 'Mogi Mirim',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.409454345703125,
    MAXIMO: 45.46883010864258,
    MEDIA: 44.979735726807824,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9bd0696d-e951-42c5-994b-1e41cec74a48',
    CIDADE: 'Mombuca',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.1910514831543,
    MAXIMO: 45.34160232543945,
    MEDIA: 45.233989058308595,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '390beeae-a0cb-445d-9d09-ed75a6457a60',
    CIDADE: 'Monções',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.303829193115234,
    MAXIMO: 37.664955139160156,
    MEDIA: 37.397917041751484,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c7cfb54-b343-4d9e-8797-de7d6d3247ac',
    CIDADE: 'Mongaguá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.84493637084961,
    MAXIMO: 41.518524169921875,
    MEDIA: 41.51801160603626,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93a63441-b827-4bee-973a-d0f2bbffacdf',
    CIDADE: 'Monte Alegre do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.69400405883789,
    MAXIMO: 43.8931884765625,
    MEDIA: 43.73756000285784,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29664ed9-9dc5-4f69-a663-a6b0788e234f',
    CIDADE: 'Monte Alto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.23838424682617,
    MAXIMO: 37.95930862426758,
    MEDIA: 37.8546577065289,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fc84547-6716-4a41-82f3-75841918a424',
    CIDADE: 'Monte Aprazível',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.450016021728516,
    MAXIMO: 36.80298614501953,
    MEDIA: 36.62650108337402,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6744de3-7e1a-4a8c-9c2f-c4104db4638a',
    CIDADE: 'Monte Azul Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.18436050415039,
    MAXIMO: 36.6678466796875,
    MEDIA: 36.531884770403984,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9b85b72-89c9-4e0d-abf5-003ae60c972b',
    CIDADE: 'Monte Castelo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.94111251831055,
    MAXIMO: 41.48634338378906,
    MEDIA: 41.134318818825506,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '55aafb1e-3da9-4807-8c56-f8548be4098a',
    CIDADE: 'Monteiro Lobato',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.76216506958008,
    MAXIMO: 38.60262680053711,
    MEDIA: 38.182395935058594,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94121f5b-b897-4870-bf25-18135ade61fe',
    CIDADE: 'Monte Mor',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.784183502197266,
    MAXIMO: 45.34160232543945,
    MEDIA: 44.65090993437425,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12be52bb-4bd1-4318-a110-f846883f9829',
    CIDADE: 'Morro Agudo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.552703857421875,
    MAXIMO: 35.95824432373047,
    MEDIA: 35.76767826080322,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '385c8eb3-2d2b-4cd0-8fef-2bb132fb6c09',
    CIDADE: 'Morungaba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.78107833862305,
    MAXIMO: 44.87501907348633,
    MEDIA: 43.21832468160015,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06cd4f79-8f5f-4667-8957-7886ceabb97a',
    CIDADE: 'Motuca',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.79793167114258,
    MAXIMO: 39.81913757324219,
    MEDIA: 39.14247539446177,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a259e7f-535b-42c2-aac6-b385f10ad83c',
    CIDADE: 'Murutinga do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.08816909790039,
    MAXIMO: 40.629249572753906,
    MEDIA: 40.35870933532715,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '46d7ccc7-32e4-4442-a967-2ac3c51c2b45',
    CIDADE: 'Nantes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.46099853515625,
    MAXIMO: 42.127037048339844,
    MEDIA: 41.90100142400087,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69d3d696-a6d4-48b9-a2c6-04eae21f1a2d',
    CIDADE: 'Narandiba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.12971878051758,
    MAXIMO: 42.84934997558594,
    MEDIA: 42.56956046897285,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '86f3ffcb-7e4f-4c62-891b-c4b03cae0f28',
    CIDADE: 'Natividade da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.08230209350586,
    MAXIMO: 35.881229400634766,
    MEDIA: 35.45593388875326,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '777e105a-4540-42ac-b430-96a3796de8b2',
    CIDADE: 'Nazaré Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.8795280456543,
    MAXIMO: 40.48808288574219,
    MEDIA: 40.18380546569824,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '68136ded-df46-4113-8f93-bc038d02bc4e',
    CIDADE: 'Neves Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.60188293457031,
    MAXIMO: 37.1514892578125,
    MEDIA: 36.80847812745514,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a697bd87-790a-4cb6-9717-4f143f455ea7',
    CIDADE: 'Nhandeara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.35322570800781,
    MAXIMO: 37.303829193115234,
    MEDIA: 36.97965158367034,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a63d6bbe-3bca-4ca1-a5fc-d68136843a3d',
    CIDADE: 'Nipoã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.80298614501953,
    MAXIMO: 37.1514892578125,
    MEDIA: 37.02488516891559,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c7cd4327-ef08-4d4a-9644-373cb1700a2b',
    CIDADE: 'Nova Aliança',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.840370178222656,
    MAXIMO: 37.39986801147461,
    MEDIA: 37.17300029771236,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14cf3d38-76b3-446a-992c-4451a46e5cc7',
    CIDADE: 'Nova Campina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.92782974243164,
    MAXIMO: 40.06359100341797,
    MEDIA: 39.995710372924805,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e456c1a4-3bd1-4b41-80d9-38e28a2e2a48',
    CIDADE: 'Nova Canaã Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.084495544433594,
    MAXIMO: 38.42200469970703,
    MEDIA: 38.195818130503355,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aefc9045-56c1-4397-bc1a-8e7507150c1c',
    CIDADE: 'Nova Castilho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.255889892578125,
    MAXIMO: 37.90904235839844,
    MEDIA: 37.768473901341714,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d760fc9-3664-4004-bef9-b217e044ba65',
    CIDADE: 'Nova Europa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.2154655456543,
    MAXIMO: 40.684146881103516,
    MEDIA: 40.44108850236792,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4972e3d-0d84-422a-919f-fad123b12584',
    CIDADE: 'Nova Granada',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.33488464355469,
    MAXIMO: 36.10377502441406,
    MEDIA: 35.67669641515379,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8295932e-861b-48fd-8fcd-2fbdbdb1119b',
    CIDADE: 'Nova Guataporanga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.170162200927734,
    MAXIMO: 41.48634338378906,
    MEDIA: 41.31999474403469,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bdc16825-1fe2-49fd-8ab4-50c28e5ddc84',
    CIDADE: 'Nova Independência',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.629249572753906,
    MAXIMO: 41.170162200927734,
    MEDIA: 40.83078367816162,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '895960ec-2f12-4e2f-8e2c-ef7aa1b4eb24',
    CIDADE: 'Novais',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.6678466796875,
    MAXIMO: 37.21758270263672,
    MEDIA: 36.742317162388,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '648a8459-814c-43ec-be09-105b3269d0cb',
    CIDADE: 'Nova Luzitânia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.303829193115234,
    MAXIMO: 37.938804626464844,
    MEDIA: 37.70005067770498,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7eed27c4-3bda-4958-9ca1-d2805f6c5474',
    CIDADE: 'Nova Odessa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.15670394897461,
    MAXIMO: 45.54442596435547,
    MEDIA: 45.461741114079366,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a8911f69-6ac3-417d-ae91-f44bbccf4da0',
    CIDADE: 'Novo Horizonte',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.674339294433594,
    MAXIMO: 38.47542953491211,
    MEDIA: 38.095332336425784,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b1b8f23e-3220-4159-a77d-b93d91953156',
    CIDADE: 'Nuporanga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.317081451416016,
    MAXIMO: 35.804054260253906,
    MEDIA: 35.541383234872846,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33f7b6e2-6314-4b13-872e-6dec36fc58e4',
    CIDADE: 'Ocauçu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.4960823059082,
    MAXIMO: 39.98739242553711,
    MEDIA: 39.678642561377686,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6741b5a-56dc-4f1c-b4cb-3d2a6e9e65e7',
    CIDADE: 'Óleo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.70199203491211,
    MAXIMO: 39.787628173828125,
    MEDIA: 39.732337222768074,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15a22b98-4803-41aa-92a3-d01ac32be366',
    CIDADE: 'Olímpia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.82771682739258,
    MAXIMO: 36.218685150146484,
    MEDIA: 35.98074849446615,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12a1eefd-d06e-490e-85c1-eb079a04822a',
    CIDADE: 'Onda Verde',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.55366516113281,
    MAXIMO: 36.10377502441406,
    MEDIA: 35.94433591033413,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b6b7155-e1f3-4c05-ab1f-59cad6ac22a9',
    CIDADE: 'Oriente',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.32653045654297,
    MAXIMO: 39.65080261230469,
    MEDIA: 39.53063395092244,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c7691c6-1247-4cdd-9e14-a6bb64b54793',
    CIDADE: 'Orindiúva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.13456726074219,
    MAXIMO: 35.457763671875,
    MEDIA: 35.235030523185614,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b25bf31-d6ee-4d27-8d6e-094830565422',
    CIDADE: 'Orlândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.317081451416016,
    MAXIMO: 35.3659553527832,
    MEDIA: 35.34151840209961,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d16d878-6839-4498-a52a-d873b0205452',
    CIDADE: 'Osasco',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.55516815185547,
    MAXIMO: 40.97282791137695,
    MEDIA: 40.91945224430997,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84cf4ce3-53be-494b-afaa-c9ed61fb1a39',
    CIDADE: 'Oscar Bressane',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.65080261230469,
    MAXIMO: 40.02827453613281,
    MEDIA: 39.86945748654517,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6863733-4f61-415f-8d62-95e00b3abf4b',
    CIDADE: 'Osvaldo Cruz',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.980281829833984,
    MAXIMO: 40.661964416503906,
    MEDIA: 40.37328641793874,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a873209-f372-48d1-aa9e-e907259d04e1',
    CIDADE: 'Ourinhos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.935115814208984,
    MAXIMO: 40.21922302246094,
    MEDIA: 40.04474368707186,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a14aa944-dc9b-463e-b33f-f73c78d16b65',
    CIDADE: 'Ouroeste',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.184078216552734,
    MAXIMO: 36.39699172973633,
    MEDIA: 36.29053497314453,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03033236-3e1f-43ff-88dc-35d0104d1da6',
    CIDADE: 'Ouro Verde',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.70519256591797,
    MAXIMO: 42.25636672973633,
    MEDIA: 41.81746098996743,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '54fc5a06-0a93-4313-9d14-e1c0df5adc7d',
    CIDADE: 'Pacaembu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.726036071777344,
    MAXIMO: 40.92145919799805,
    MEDIA: 40.823747634887695,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0c80576b-4450-49a8-b3da-7749ae85cb1e',
    CIDADE: 'Palestina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.457763671875,
    MAXIMO: 35.781715393066406,
    MEDIA: 35.628607432047524,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad1a914c-0a08-478a-9a37-cb329d56913e',
    CIDADE: 'Palmares Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.6678466796875,
    MAXIMO: 37.236045837402344,
    MEDIA: 37.23563257282747,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa7ee233-311a-4b15-a269-918d76ed48ba',
    CIDADE: "Palmeira d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.644187927246094,
    MAXIMO: 38.42200469970703,
    MEDIA: 37.95963238594197,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee8b2238-8948-4261-a4f5-06bde441bf54',
    CIDADE: 'Palmital',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.354026794433594,
    MAXIMO: 40.5277214050293,
    MEDIA: 40.440874099731445,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b1f86dfb-22a7-497e-9c65-69a9a53b3f9f',
    CIDADE: 'Panorama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.70519256591797,
    MAXIMO: 42.25636672973633,
    MEDIA: 41.99334409969556,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6c61548-6418-418d-853d-0d920f3e9498',
    CIDADE: 'Paraguaçu Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.554874420166016,
    MAXIMO: 40.8572883605957,
    MEDIA: 40.70608139038086,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a9f8096-0591-487f-b3ce-637b025b43a1',
    CIDADE: 'Paraibuna',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.46821212768555,
    MAXIMO: 36.47538375854492,
    MEDIA: 35.98107020060221,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70f5b4c8-7fbc-4644-9a60-6de90648826f',
    CIDADE: 'Paraíso',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.66480255126953,
    MAXIMO: 37.236045837402344,
    MEDIA: 36.832501750570735,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0164a228-6b60-4354-875e-987f772b69b2',
    CIDADE: 'Paranapanema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.607017517089844,
    MAXIMO: 39.65354537963867,
    MEDIA: 39.62358379364014,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b43eaae4-4a0e-481f-bb6d-0684d518ce63',
    CIDADE: 'Paranapuã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.72189712524414,
    MAXIMO: 37.010589599609375,
    MEDIA: 36.86190548794191,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'daa929dd-648a-4d30-addf-56fc189c225d',
    CIDADE: 'Parapuã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.17010498046875,
    MAXIMO: 40.661964416503906,
    MEDIA: 40.41603469848633,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '280b2d6f-2b29-4fcb-b0ba-c612eed0f268',
    CIDADE: 'Pardinho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.142452239990234,
    MAXIMO: 41.840267181396484,
    MEDIA: 40.990230415238535,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '652bbfa4-f751-4969-96f6-a21c81b5fe1b',
    CIDADE: 'Pariquera-Açu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.894474029541016,
    MAXIMO: 40.3334846496582,
    MEDIA: 40.06979581228809,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c004ca27-4ffb-4b83-b65b-274d61b18703',
    CIDADE: 'Parisi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.782588958740234,
    MAXIMO: 36.3397216796875,
    MEDIA: 36.27449628067726,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15d30b9c-b482-4704-9ea2-59068561af4f',
    CIDADE: 'Patrocínio Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.361331939697266,
    MAXIMO: 35.70772171020508,
    MEDIA: 35.53452682495117,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3aa7430e-4a08-484e-91df-c12397fca1e2',
    CIDADE: 'Paulicéia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.259857177734375,
    MAXIMO: 42.032344818115234,
    MEDIA: 41.47528235362795,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6cfe54d5-dfd7-4316-8da7-d2a2c91c0025',
    CIDADE: 'Paulínia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.9144401550293,
    MAXIMO: 45.15670394897461,
    MEDIA: 45.150158863795774,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '943215f6-f088-4aa9-826f-d68af13e3b15',
    CIDADE: 'Paulistânia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.59324264526367,
    MAXIMO: 39.61272048950195,
    MEDIA: 39.60298156738281,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11e22362-c4a3-4606-b60e-397fdd549b42',
    CIDADE: 'Paulo de Faria',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.01615905761719,
    MAXIMO: 35.374473571777344,
    MEDIA: 35.15791575113932,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a943620f-ccc6-48d7-8d8e-711bf5ed7660',
    CIDADE: 'Pederneiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.27737045288086,
    MAXIMO: 41.10812759399414,
    MEDIA: 40.81334431966146,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '12b775c9-f9bf-4437-a378-0da7990c92bb',
    CIDADE: 'Pedra Bela',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.62593078613281,
    MAXIMO: 42.59624481201172,
    MEDIA: 42.24608164671211,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49aac54c-2b71-4c56-b6bd-216a007cbb5b',
    CIDADE: 'Pedranópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.06898880004883,
    MAXIMO: 36.3397216796875,
    MEDIA: 36.204355239868164,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b405146d-ef43-4c03-b4d0-0358e47ff654',
    CIDADE: 'Pedregulho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.40721130371094,
    MAXIMO: 35.72697067260742,
    MEDIA: 35.54243723551432,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b04778c-00a7-47c2-aa18-59c0a4a3cef6',
    CIDADE: 'Pedreira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.40546417236328,
    MAXIMO: 44.9144401550293,
    MEDIA: 44.874035083795185,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0f16ba9f-5a95-46d1-92f5-701002332f79',
    CIDADE: 'Pedrinhas Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.181007385253906,
    MAXIMO: 41.59192657470703,
    MEDIA: 41.30839610658583,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8ecd506-a26f-4049-976a-9d797acd6b5c',
    CIDADE: 'Pedro de Toledo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.09140396118164,
    MAXIMO: 42.51658630371094,
    MEDIA: 42.30399513244629,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'faf1bdcb-b238-471c-a157-a44b89105304',
    CIDADE: 'Penápolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.29319381713867,
    MAXIMO: 38.573020935058594,
    MEDIA: 38.43310737609863,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c4ef3bd-dff2-49e1-a62a-f165f1e304f4',
    CIDADE: 'Pereira Barreto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.12649154663086,
    MAXIMO: 39.525299072265625,
    MEDIA: 39.328590393066406,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02e8587d-4a82-46d2-a3d5-2a4caba8c535',
    CIDADE: 'Pereiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.249900817871094,
    MAXIMO: 42.80611038208008,
    MEDIA: 42.313864040842475,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52b6cd24-2791-43ac-8593-16a7f9a94664',
    CIDADE: 'Peruíbe',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.08820343017578,
    MAXIMO: 42.51658630371094,
    MEDIA: 41.670693359064416,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27c5bf26-688a-4ecc-80e5-c28b9ee145c7',
    CIDADE: 'Piacatu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.40508270263672,
    MAXIMO: 39.980281829833984,
    MEDIA: 39.714995709148596,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6093273-d010-4f0e-93f4-303bf9be6b16',
    CIDADE: 'Piedade',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.36533737182617,
    MAXIMO: 41.73828887939453,
    MEDIA: 41.55181312561035,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '32375e68-deeb-48c1-b63c-b5fbc79396ef',
    CIDADE: 'Pilar do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.46628189086914,
    MAXIMO: 40.91645050048828,
    MEDIA: 40.7585194905599,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0224afd-c6c4-4c62-89d9-9a78d4f9723a',
    CIDADE: 'Pindamonhangaba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.38010025024414,
    MAXIMO: 37.00897979736328,
    MEDIA: 36.69454002380371,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b645cbcf-a8f0-4ed4-ba58-353686236f77',
    CIDADE: 'Pindorama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.21758270263672,
    MAXIMO: 37.825199127197266,
    MEDIA: 37.520788176866986,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e2fb7f8-b7f9-4ac3-89f3-21c37a544f69',
    CIDADE: 'Pinhalzinho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.966552734375,
    MAXIMO: 43.69400405883789,
    MEDIA: 42.97304700926035,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '674faf8d-ae22-4e30-af60-751056af9337',
    CIDADE: 'Piquerobi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.04169845581055,
    MAXIMO: 42.248104095458984,
    MEDIA: 42.144901275634766,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6575e40-8451-4777-94d8-3c9f95432b9e',
    CIDADE: 'Piquete',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.78862762451172,
    MAXIMO: 36.191162109375,
    MEDIA: 36.1711441753122,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5b5867cf-c416-4402-8e9a-3456f8108b7a',
    CIDADE: 'Piracaia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.464637756347656,
    MAXIMO: 41.16666030883789,
    MEDIA: 40.31961296907925,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ac74bca-5a2b-4ab4-b715-d71b4894e261',
    CIDADE: 'Piracicaba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.62734603881836,
    MAXIMO: 46.19719696044922,
    MEDIA: 45.90886974334717,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3e977cb-9764-427b-9657-d725e3f8f549',
    CIDADE: 'Piraju',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.718082427978516,
    MAXIMO: 39.9988899230957,
    MEDIA: 39.804133487995934,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29e19b87-0c25-4726-896e-c749b4347a00',
    CIDADE: 'Pirajuí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.968955993652344,
    MAXIMO: 39.15476989746094,
    MEDIA: 39.08412297566732,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd36cd992-742c-40ae-a223-0ea2492b3e30',
    CIDADE: 'Pirangi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.66480255126953,
    MAXIMO: 37.23838424682617,
    MEDIA: 37.19629577658032,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ee52228-9ec4-4aa4-9f16-2628e2a952c8',
    CIDADE: 'Pirapora do Bom Jesus',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.26749801635742,
    MAXIMO: 40.55588912963867,
    MEDIA: 40.55460559307963,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5aceee2c-df6a-448d-9597-242332e3db84',
    CIDADE: 'Pirapozinho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.30823516845703,
    MAXIMO: 43.03560256958008,
    MEDIA: 42.611314137776695,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '362309e2-a716-4a49-9b2f-71ef6e1595a6',
    CIDADE: 'Pirassununga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.90349197387695,
    MAXIMO: 43.075401306152344,
    MEDIA: 42.98944664001465,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30fb346a-931f-477c-bcc3-120d557d4e7c',
    CIDADE: 'Piratininga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.57755661010742,
    MAXIMO: 39.81058883666992,
    MEDIA: 39.69407272338867,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c348e96-9b2d-46ba-ac4b-fb86ecd81e57',
    CIDADE: 'Pitangueiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.95824432373047,
    MAXIMO: 37.255958557128906,
    MEDIA: 36.73288817186352,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8b94e5a-952c-43e5-b14d-799510469563',
    CIDADE: 'Planalto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.1514892578125,
    MAXIMO: 37.759918212890625,
    MEDIA: 37.46909451692938,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81902bd2-fc5c-4294-b1a8-fa9ca5f4c813',
    CIDADE: 'Platina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.98739242553711,
    MAXIMO: 40.445701599121094,
    MEDIA: 40.15894601922268,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cdbeb837-bd33-4fd2-b028-3f6de5ad29d9',
    CIDADE: 'Poá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.160457611083984,
    MAXIMO: 39.160457611083984,
    MEDIA: 39.160457611083984,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53c4f5f5-09b4-448a-aae2-24f6371c82c7',
    CIDADE: 'Poloni',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.450016021728516,
    MAXIMO: 37.04746627807617,
    MEDIA: 36.79623314241507,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37ab8347-fe89-4bb3-be15-4dcf7fdab617',
    CIDADE: 'Pompéia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.3304557800293,
    MAXIMO: 39.68155288696289,
    MEDIA: 39.512648264567055,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6ac6faa-f8f4-46a6-a883-c8960cfa43e0',
    CIDADE: 'Pongaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.47055435180664,
    MAXIMO: 38.842857360839844,
    MEDIA: 38.68321253646212,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c030bfef-2aae-4d13-bdd6-2bc08ada58c7',
    CIDADE: 'Pontal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.838233947753906,
    MAXIMO: 37.17673873901367,
    MEDIA: 36.61506171147704,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '752266b9-eedc-4da7-a99b-fa9ded477dab',
    CIDADE: 'Pontalinda',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.97331619262695,
    MAXIMO: 37.644187927246094,
    MEDIA: 37.50288435115507,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9b0ec56-96ef-4a85-967b-affbc5a8c5fb',
    CIDADE: 'Pontes Gestal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.374473571777344,
    MAXIMO: 35.835777282714844,
    MEDIA: 35.60358105381109,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '232e9f0e-3e4f-4c14-8a21-83a77004eae6',
    CIDADE: 'Populina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.184078216552734,
    MAXIMO: 36.72189712524414,
    MEDIA: 36.48344597236615,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbdc9e1f-a2e7-4c3b-867b-e3b6c906d515',
    CIDADE: 'Porangaba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.249900817871094,
    MAXIMO: 42.80611038208008,
    MEDIA: 41.255196686883814,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48728d5e-319d-4cc7-8bac-4648c61db26e',
    CIDADE: 'Porto Feliz',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.39834213256836,
    MAXIMO: 44.23837661743164,
    MEDIA: 42.895701118461254,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c6da348-c2bb-4cea-b467-2c333a23cbef',
    CIDADE: 'Porto Ferreira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.345420837402344,
    MAXIMO: 43.075401306152344,
    MEDIA: 41.93972963631732,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e2e6c06c-67f2-4994-a4c7-23fd4238e717',
    CIDADE: 'Potim',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.92928695678711,
    MAXIMO: 36.58771896362305,
    MEDIA: 36.38967935569361,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1304882b-6903-41fc-b0ed-9b43cc59d421',
    CIDADE: 'Potirendaba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.763858795166016,
    MAXIMO: 37.300193786621094,
    MEDIA: 37.127118588940014,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5abc90e-8b1d-4d5a-8381-7dea10497cc6',
    CIDADE: 'Pracinha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.786983489990234,
    MAXIMO: 40.98579788208008,
    MEDIA: 40.83568659171065,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd19dbd0-854f-4fbc-a4df-ac74184cbbb8',
    CIDADE: 'Pradópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.92100524902344,
    MAXIMO: 38.79793167114258,
    MEDIA: 38.20877855683995,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93a75a92-12f3-493e-9543-4b4f9dc1e6a0',
    CIDADE: 'Praia Grande',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.36162567138672,
    MAXIMO: 41.518524169921875,
    MEDIA: 39.918273988606266,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '699f0015-b85d-4783-b73d-b9230591aad9',
    CIDADE: 'Pratânia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.37648391723633,
    MAXIMO: 41.60948944091797,
    MEDIA: 41.05315820319957,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4062d17-136c-4707-90ba-178a3968df0f',
    CIDADE: 'Presidente Alves',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.12864303588867,
    MAXIMO: 39.38325500488281,
    MEDIA: 39.26668406873494,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c51e7aac-b97a-4bdb-a4bc-6a907847e571',
    CIDADE: 'Presidente Bernardes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.90665054321289,
    MAXIMO: 42.66251754760742,
    MEDIA: 42.28086280822754,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '285d2278-0324-4c4e-8e06-eee361a41d9f',
    CIDADE: 'Presidente Epitácio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.567710876464844,
    MAXIMO: 43.522789001464844,
    MEDIA: 43.024617195129395,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6c52b78-782f-4400-91d8-e97eca6d3d71',
    CIDADE: 'Presidente Prudente',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.0536003112793,
    MAXIMO: 42.101985931396484,
    MEDIA: 41.53162117390231,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e39260bb-109c-4e3a-9209-5d8ef2e9e718',
    CIDADE: 'Presidente Venceslau',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.92166519165039,
    MAXIMO: 42.59328079223633,
    MEDIA: 42.30058288574219,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11472fcf-7751-406a-afe3-9ecfe7bf4f06',
    CIDADE: 'Promissão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.240196228027344,
    MAXIMO: 38.68159484863281,
    MEDIA: 38.46089553833008,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9782814-33a2-4d27-bb8b-258094c22b3a',
    CIDADE: 'Quadra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.923973083496094,
    MAXIMO: 40.645164489746094,
    MEDIA: 40.27254306236995,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79f743d9-7f8b-4f48-bed3-99f43d389d3a',
    CIDADE: 'Quatá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.21969985961914,
    MAXIMO: 40.383270263671875,
    MEDIA: 40.30148506164551,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b7e878f-ed97-4ba1-8be6-edc0ee63a673',
    CIDADE: 'Queiroz',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.11711502075195,
    MAXIMO: 39.525936126708984,
    MEDIA: 39.30377572349807,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb754e90-5573-452b-9e10-e2b22d8c4f14',
    CIDADE: 'Queluz',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.864158630371094,
    MAXIMO: 36.65462112426758,
    MEDIA: 36.2742724788385,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd53f256e-5bc1-4ee3-bd1c-16e6cffdafd1',
    CIDADE: 'Quintana',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.525936126708984,
    MAXIMO: 40.21969985961914,
    MEDIA: 39.817595997919895,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de356312-d7c6-4dd3-9941-9c0d9c0904ae',
    CIDADE: 'Rafard',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.8519172668457,
    MAXIMO: 45.34160232543945,
    MEDIA: 44.044099770595004,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7667c28-5be2-4260-a93e-b4de081d081e',
    CIDADE: 'Rancharia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.781776428222656,
    MAXIMO: 41.46099853515625,
    MEDIA: 41.17577438354492,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1db1b721-a9b6-4678-94c4-841332ef5705',
    CIDADE: 'Redenção da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.751094818115234,
    MAXIMO: 36.945892333984375,
    MEDIA: 36.26123506595998,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f212e51e-286e-4268-9943-9d0ec24138d1',
    CIDADE: 'Regente Feijó',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.48954391479492,
    MAXIMO: 41.975399017333984,
    MEDIA: 41.762742831534084,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '427c10d3-a334-4e48-88aa-8306683b5402',
    CIDADE: 'Reginópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.47542953491211,
    MAXIMO: 39.61701583862305,
    MEDIA: 39.16403275851996,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b958f6a-c92b-4c7b-afac-d59012918017',
    CIDADE: 'Registro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.10136413574219,
    MAXIMO: 40.32713317871094,
    MEDIA: 40.207925160725914,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7c2898a-b5ab-443c-abff-beeb10b53a5b',
    CIDADE: 'Restinga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.361331939697266,
    MAXIMO: 35.804054260253906,
    MEDIA: 35.45671815456978,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c447da1b-55f7-489b-b58a-f8a561d56332',
    CIDADE: 'Ribeira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.10054397583008,
    MAXIMO: 40.22920608520508,
    MEDIA: 40.16487503051758,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d0bc6e0-6acd-4310-97f5-1dcb372916ff',
    CIDADE: 'Ribeirão Bonito',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.39038848876953,
    MAXIMO: 44.50520706176758,
    MEDIA: 43.71701002124078,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8e9fd9eb-3538-449b-a1ed-4df65109c696',
    CIDADE: 'Ribeirão Branco',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.700828552246094,
    MAXIMO: 40.0103874206543,
    MEDIA: 39.83725381150453,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8baf9322-94a7-4f36-9d40-130b7a2244dc',
    CIDADE: 'Ribeirão Corrente',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.264251708984375,
    MAXIMO: 35.40721130371094,
    MEDIA: 35.27751851497957,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3899bee3-463b-4252-adc8-0fe19bf3169e',
    CIDADE: 'Ribeirão do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.81671142578125,
    MAXIMO: 39.96613693237305,
    MEDIA: 39.96123785710357,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e759dbe-d631-4512-a8d9-da470b6bbae9',
    CIDADE: 'Ribeirão dos Índios',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.58863830566406,
    MAXIMO: 42.04169845581055,
    MEDIA: 41.729212118677495,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06be3ee8-eaba-4d00-b050-259f06c7756c',
    CIDADE: 'Ribeirão Grande',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.722572326660156,
    MAXIMO: 39.91619873046875,
    MEDIA: 39.825305560931646,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eaff5b3d-0bc3-4f1b-894d-c0db13ef0a4f',
    CIDADE: 'Ribeirão Pires',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.9421501159668,
    MAXIMO: 40.0057373046875,
    MEDIA: 39.30860255424854,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f63b4951-73cc-49b3-9af9-1f23f1ec5462',
    CIDADE: 'Ribeirão Preto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.100765228271484,
    MAXIMO: 37.894893646240234,
    MEDIA: 37.49782943725586,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '814bc699-d25c-44aa-92f9-716943daae1a',
    CIDADE: 'Riversul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.99897766113281,
    MAXIMO: 40.40099334716797,
    MEDIA: 40.21127188848379,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b0bb749-edf6-4c32-b494-36b7dec08f26',
    CIDADE: 'Rifaina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.49312973022461,
    MAXIMO: 36.107051849365234,
    MEDIA: 35.851797997243665,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9aab509-7fb0-43fd-9c20-56c3bf560120',
    CIDADE: 'Rincão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.799644470214844,
    MAXIMO: 39.823734283447266,
    MEDIA: 39.811689376831055,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '38c98f17-3cc1-4e07-9864-4ea13c1807aa',
    CIDADE: 'Rinópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.647186279296875,
    MAXIMO: 40.2868766784668,
    MEDIA: 39.999086737414046,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9eedcf79-1fc6-4b4d-8813-c9850240e6e3',
    CIDADE: 'Rio Claro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.531822204589844,
    MAXIMO: 46.11471176147461,
    MEDIA: 45.82326698303223,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b276d09e-60a1-40ad-9e1b-ce7e0ae34249',
    CIDADE: 'Rio das Pedras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.1910514831543,
    MAXIMO: 45.92257308959961,
    MEDIA: 45.48184017205686,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48569b61-e484-4d79-8b94-3936025f69a0',
    CIDADE: 'Rio Grande da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.9421501159668,
    MAXIMO: 40.0057373046875,
    MEDIA: 38.95052334306079,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41cc1207-c9a4-44e2-a557-8c592fd4c9f7',
    CIDADE: 'Riolândia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.17616653442383,
    MAXIMO: 35.322059631347656,
    MEDIA: 35.24911308288574,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7327bf4-1c72-4372-a10f-2f557a651dca',
    CIDADE: 'Rosana',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.05833053588867,
    MAXIMO: 46.015872955322266,
    MEDIA: 45.578625996907554,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e027e700-254f-403b-b70b-11cd11482607',
    CIDADE: 'Roseira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.62594985961914,
    MAXIMO: 36.38010025024414,
    MEDIA: 36.2511160965603,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88b165b0-ef07-41e2-877b-a140235b7f82',
    CIDADE: 'Rubiácea',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.481937408447266,
    MAXIMO: 40.0643424987793,
    MEDIA: 39.77313995361328,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c63b4cd-a78f-4017-a85b-8a06a383580e',
    CIDADE: 'Rubinéia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.77390670776367,
    MAXIMO: 38.79694366455078,
    MEDIA: 38.268001599562645,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ddf7474-254e-4921-8a47-2d3a8c0fecb3',
    CIDADE: 'Sabino',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.755428314208984,
    MAXIMO: 38.47209548950195,
    MEDIA: 38.19519204611621,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3f14fba-8629-4ff8-b07e-c67d56facdf0',
    CIDADE: 'Sagres',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.480323791503906,
    MAXIMO: 40.98579788208008,
    MEDIA: 40.747000113059805,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79e23fc5-25bd-4166-87a5-a4de7e9a5fc2',
    CIDADE: 'Sales',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.696136474609375,
    MAXIMO: 38.16059112548828,
    MEDIA: 37.8537077188609,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40d83bf3-bb01-4064-bf9e-a2ae9052b3ad',
    CIDADE: 'Sales Oliveira',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.789485931396484,
    MAXIMO: 36.42570114135742,
    MEDIA: 35.87845252130474,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4401063-aeb5-4fec-a79f-d576947e4560',
    CIDADE: 'Salesópolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.518489837646484,
    MAXIMO: 37.42306900024414,
    MEDIA: 36.56921675602724,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8de43e63-7a09-4092-a331-3c42c09c0f90',
    CIDADE: 'Salmourão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.980281829833984,
    MAXIMO: 40.2868766784668,
    MEDIA: 40.23972502717847,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d9432b3-94d4-44f0-9d2b-46cbff838a4a',
    CIDADE: 'Saltinho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.087745666503906,
    MAXIMO: 45.92257308959961,
    MEDIA: 45.19223672870258,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b6d2476-54cf-463d-bd68-4253c9a2b032',
    CIDADE: 'Salto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.44625473022461,
    MAXIMO: 43.70583724975586,
    MEDIA: 42.73232764377347,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3cbe02e2-f994-4564-8f0c-2bd861e5ddb5',
    CIDADE: 'Salto de Pirapora',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.91645050048828,
    MAXIMO: 41.451026916503906,
    MEDIA: 41.147376525217815,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6ef1929-2eb7-40a6-8468-a54ae7c176f0',
    CIDADE: 'Salto Grande',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.96613693237305,
    MAXIMO: 40.29408264160156,
    MEDIA: 40.09747620125194,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70998b35-c4fe-4e47-b822-54875178d371',
    CIDADE: 'Sandovalina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.66251754760742,
    MAXIMO: 43.792442321777344,
    MEDIA: 43.182851625080524,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e596d67-c9fa-4434-a547-d2d355f90de0',
    CIDADE: 'Santa Adélia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.74989318847656,
    MAXIMO: 38.45064926147461,
    MEDIA: 37.92855385996269,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a32c8638-b395-4da8-ad3f-e0786dc01787',
    CIDADE: 'Santa Albertina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.72189712524414,
    MAXIMO: 37.46046447753906,
    MEDIA: 37.151423886556984,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b58b8814-3bec-4606-a2a6-08b45bffb7a2',
    CIDADE: "Santa Bárbara d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.863037109375,
    MAXIMO: 45.84468078613281,
    MEDIA: 45.592433726787334,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e49f040-88d3-4a07-a061-63498b722246',
    CIDADE: 'Santa Branca',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.47538375854492,
    MAXIMO: 37.90723419189453,
    MEDIA: 37.21791459368932,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d2d6bff-ae56-4fc1-856f-89e04d954d05',
    CIDADE: "Santa Clara d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.10480880737305,
    MAXIMO: 38.15447998046875,
    MEDIA: 37.63251056276984,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84d157eb-9b79-4fb7-ab06-12dfcd50236a',
    CIDADE: 'Santa Cruz da Conceição',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.32831954956055,
    MAXIMO: 45.531822204589844,
    MEDIA: 44.568556973870685,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9836253-4317-4a53-aa19-506de52a20aa',
    CIDADE: 'Santa Cruz da Esperança',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.021339416503906,
    MAXIMO: 38.740665435791016,
    MEDIA: 37.82700119286889,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfd20e18-48d4-464e-b507-d1eb7a386608',
    CIDADE: 'Santa Cruz das Palmeiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.26418685913086,
    MAXIMO: 42.90349197387695,
    MEDIA: 41.962844479682445,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14e1a2f7-5716-4321-b429-d36ddecd6794',
    CIDADE: 'Santa Cruz do Rio Pardo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.65970230102539,
    MAXIMO: 39.811805725097656,
    MEDIA: 39.728319803873696,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a61bd9e-2782-4fb6-8233-0fc0bb5e729a',
    CIDADE: 'Santa Ernestina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.75826644897461,
    MAXIMO: 38.81720733642578,
    MEDIA: 38.79655825858791,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df62c27f-ce31-46d3-bb66-94602fe0fc26',
    CIDADE: 'Santa Fé do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.77390670776367,
    MAXIMO: 38.47136688232422,
    MEDIA: 37.99006716217537,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f215d1a6-56e3-4328-b5cb-b5ff5e48e013',
    CIDADE: 'Santa Gertrudes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.99991226196289,
    MAXIMO: 46.19719696044922,
    MEDIA: 46.01959684958308,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a7742b5-5ede-4f79-83aa-5483aa6317fc',
    CIDADE: 'Santa Isabel',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.702762603759766,
    MAXIMO: 39.8795280456543,
    MEDIA: 39.335844990720204,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5b5a4eb-9622-41c4-a08c-152157b0050a',
    CIDADE: 'Santa Lúcia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.799644470214844,
    MAXIMO: 41.3417854309082,
    MEDIA: 39.947021972871596,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36c7e81d-aebe-4ffc-9d48-2f7d17c75bf3',
    CIDADE: 'Santa Maria da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.580081939697266,
    MAXIMO: 45.936283111572266,
    MEDIA: 45.35873194130725,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ee5daf9-93f4-4da9-9a74-1321e5a540b6',
    CIDADE: 'Santa Mercedes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.48634338378906,
    MAXIMO: 41.70519256591797,
    MEDIA: 41.5153165550433,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '04ba9580-f381-419d-9a0c-83f8e0a39a93',
    CIDADE: 'Santana da Ponte Pensa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.38593292236328,
    MAXIMO: 38.084495544433594,
    MEDIA: 37.6703651924603,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8240eda4-9811-4ed0-bf27-89b5128cb403',
    CIDADE: 'Santana de Parnaíba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.55516815185547,
    MAXIMO: 41.25457000732422,
    MEDIA: 40.585906580656676,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7667fd5e-efc7-4810-a783-6b5adbae6bfc',
    CIDADE: "Santa Rita d'Oeste",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.10480880737305,
    MAXIMO: 37.77390670776367,
    MEDIA: 37.425364477302175,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37630429-6a04-4a1a-a7d9-80fe4572a32e',
    CIDADE: 'Santa Rita do Passa Quatro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.74932098388672,
    MAXIMO: 41.35149383544922,
    MEDIA: 40.588932037353516,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24c4e309-011a-4a9f-8a60-1677c1311dcb',
    CIDADE: 'Santa Rosa de Viterbo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.740665435791016,
    MAXIMO: 39.917877197265625,
    MEDIA: 39.116812729946574,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '135ee7f1-a1b5-407e-a1fc-c52050832d0e',
    CIDADE: 'Santa Salete',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.38593292236328,
    MAXIMO: 37.71646499633789,
    MEDIA: 37.655658275108046,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0f0af89-9d88-47ff-ae35-749134415069',
    CIDADE: 'Santo Anastácio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.70606231689453,
    MAXIMO: 42.80748748779297,
    MEDIA: 42.36501250355669,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71daac77-6f5a-47f6-93c5-83915da7a5bd',
    CIDADE: 'Santo André',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.709110260009766,
    MAXIMO: 40.0057373046875,
    MEDIA: 39.56421833515686,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '272d934a-434b-482d-aa35-d6dda292f4ca',
    CIDADE: 'Santo Antônio da Alegria',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.09072494506836,
    MAXIMO: 37.72081756591797,
    MEDIA: 36.66754098196822,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3da6ad5c-f8e2-4c34-8af3-f5ac3cbe2081',
    CIDADE: 'Santo Antônio de Posse',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.82510757446289,
    MAXIMO: 45.265838623046875,
    MEDIA: 45.15695628696201,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ee36675-194c-42bb-b6ee-501459a92ab4',
    CIDADE: 'Santo Antônio do Aracanguá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.21815872192383,
    MAXIMO: 38.63897705078125,
    MEDIA: 38.41813850402832,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71d840e0-a22f-454b-9198-878f16e01904',
    CIDADE: 'Santo Antônio do Jardim',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.23779296875,
    MAXIMO: 42.87862014770508,
    MEDIA: 42.245459520575125,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2138bbb6-8845-49c8-9137-fe2603a5eaf8',
    CIDADE: 'Santo Antônio do Pinhal',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.76216506958008,
    MAXIMO: 38.04493713378906,
    MEDIA: 37.92376947742739,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23e56b7d-ed2b-4d7b-8326-ea3d9621befa',
    CIDADE: 'Santo Expedito',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.37455749511719,
    MAXIMO: 41.56743621826172,
    MEDIA: 41.42076007042631,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2400bdb4-dde7-43b1-9ae5-b257a60bc15e',
    CIDADE: 'Santópolis do Aguapeí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.35905838012695,
    MAXIMO: 39.85883712768555,
    MEDIA: 39.55081530411663,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4de694b8-14f7-4800-a95d-cc4d6d1077c6',
    CIDADE: 'Santos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.02619934082031,
    MAXIMO: 38.9421501159668,
    MEDIA: 38.36934568341104,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a223bdd-3619-4eb4-a2fb-ac653cdefeb0',
    CIDADE: 'São Bento do Sapucaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.04493713378906,
    MAXIMO: 38.152793884277344,
    MEDIA: 38.0988655090332,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e63c5cd4-ca47-48f0-9f4a-18db478450c6',
    CIDADE: 'São Bernardo do Campo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.709110260009766,
    MAXIMO: 41.34004211425781,
    MEDIA: 40.29354716707619,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a89ac953-43b4-4f9a-a0ee-1d94095d28a9',
    CIDADE: 'São Caetano do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.96968460083008,
    MAXIMO: 39.96968460083008,
    MEDIA: 39.96968460083008,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e385f4c-2dfb-48d0-88cf-a33ceacaaaaf',
    CIDADE: 'São Carlos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.492244720458984,
    MAXIMO: 44.69639587402344,
    MEDIA: 43.469449043273926,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '015cd4ca-c8ae-479d-a17d-e97f047faa64',
    CIDADE: 'São Francisco',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.3068962097168,
    MAXIMO: 38.0285758972168,
    MEDIA: 37.63372585608979,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '34d41bbc-3795-4150-b7ed-ecabdc1dd5b8',
    CIDADE: 'São João da Boa Vista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.24063491821289,
    MAXIMO: 42.87862014770508,
    MEDIA: 41.98533630030004,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea2c3c76-f31d-40eb-8dc1-84e4b80ad8ec',
    CIDADE: 'São João das Duas Pontes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.654964447021484,
    MAXIMO: 37.26426696777344,
    MEDIA: 37.1359016394112,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f2f1660-2e9f-40bc-8895-e8f125f53c0b',
    CIDADE: 'São João de Iracema',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.96439743041992,
    MAXIMO: 37.586151123046875,
    MEDIA: 37.189751201299885,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '05a1daef-bb91-462b-a928-1794c05f164f',
    CIDADE: "São João do Pau d'Alho",
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.94111251831055,
    MAXIMO: 41.48634338378906,
    MEDIA: 41.360831647593386,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5886b987-3de1-4797-b7c0-25e41dd0b2d3',
    CIDADE: 'São Joaquim da Barra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.10365676879883,
    MAXIMO: 35.552703857421875,
    MEDIA: 35.27292380042461,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b71b14b9-da35-4363-900e-5089e435ada7',
    CIDADE: 'São José da Bela Vista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.264251708984375,
    MAXIMO: 35.441715240478516,
    MEDIA: 35.36737614744784,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '383c6b2c-a6d1-4e07-a380-4204b7a9862a',
    CIDADE: 'São José do Barreiro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.387046813964844,
    MAXIMO: 38.61796951293945,
    MEDIA: 37.23643519342338,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2f0ed44-20f8-4f6f-9740-e70cd516744e',
    CIDADE: 'São José do Rio Pardo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.48903274536133,
    MAXIMO: 39.77000427246094,
    MEDIA: 39.62951850891113,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '092de7de-2364-4777-8ff5-e6ae9e4e018e',
    CIDADE: 'São José do Rio Preto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.358726501464844,
    MAXIMO: 36.470218658447266,
    MEDIA: 36.414472579956055,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '00a6e7a3-4b32-402e-a06c-a3fb35bb3523',
    CIDADE: 'São José dos Campos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.669761657714844,
    MAXIMO: 39.464637756347656,
    MEDIA: 38.56064033508301,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3248dade-bb85-4424-8496-2180e372e579',
    CIDADE: 'São Lourenço da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.55913543701172,
    MAXIMO: 42.35102844238281,
    MEDIA: 42.20787939283487,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ec992cf-1505-4fbd-906e-3dbdcf04a772',
    CIDADE: 'São Luiz do Paraitinga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.33061599731445,
    MAXIMO: 35.751094818115234,
    MEDIA: 35.540855407714844,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ace5ef9f-e790-4c8e-bdb3-21e90f1a67db',
    CIDADE: 'São Manuel',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.60948944091797,
    MAXIMO: 43.27085876464844,
    MEDIA: 42.305458068847656,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '232221f4-19e9-4de7-a894-1f47a1bbed56',
    CIDADE: 'São Miguel Arcanjo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.872440338134766,
    MAXIMO: 40.16160202026367,
    MEDIA: 40.050638834635414,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebf3f5fe-8582-4eaa-a0b3-06317758aacb',
    CIDADE: 'São Paulo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.96968460083008,
    MAXIMO: 41.34004211425781,
    MEDIA: 40.70279121398926,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fce5c6a2-b337-4889-a449-d91df97f903c',
    CIDADE: 'São Pedro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 45.936283111572266,
    MAXIMO: 46.16374588012695,
    MEDIA: 46.05001449584961,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d4526d4-058e-44bf-b869-ac2a31115490',
    CIDADE: 'São Pedro do Turvo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.69622802734375,
    MAXIMO: 39.81671142578125,
    MEDIA: 39.7564697265625,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc4c3383-f574-41ce-9924-c6341bb94086',
    CIDADE: 'São Roque',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.55588912963867,
    MAXIMO: 42.02537155151367,
    MEDIA: 41.40297013766631,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'de32cb57-2f95-4ba6-9c2e-1b4b12071751',
    CIDADE: 'São Sebastião',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 34.58901596069336,
    MAXIMO: 36.11495590209961,
    MEDIA: 35.298380802787406,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dee02cc6-b940-42b8-a0ef-c2be1c46fe0e',
    CIDADE: 'São Sebastião da Grama',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.10432052612305,
    MAXIMO: 40.68234634399414,
    MEDIA: 40.403593680472994,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50db6267-863c-48d2-8ab7-0551c6b56a27',
    CIDADE: 'São Simão',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.740665435791016,
    MAXIMO: 38.78281021118164,
    MEDIA: 38.76173782348633,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bc10cf1-b28b-48c6-96a9-1ab083536543',
    CIDADE: 'São Vicente',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.36162567138672,
    MAXIMO: 41.518524169921875,
    MEDIA: 39.916787897156546,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd39c01db-f0bc-4e40-8143-ed0a6043968a',
    CIDADE: 'Sarapuí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.98659896850586,
    MAXIMO: 41.016788482666016,
    MEDIA: 40.53146214524659,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b57225e-6f6e-418b-a144-4d10027c394e',
    CIDADE: 'Sarutaiá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.86724853515625,
    MAXIMO: 39.9988899230957,
    MEDIA: 39.878656484062475,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49adaa37-154b-46dd-8e51-e11d43079f2f',
    CIDADE: 'Sebastianópolis do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.450016021728516,
    MAXIMO: 36.654090881347656,
    MEDIA: 36.645821238706205,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5256f114-80b9-4d0b-88b8-749eaefebf72',
    CIDADE: 'Serra Azul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.831912994384766,
    MAXIMO: 37.86475372314453,
    MEDIA: 37.84833335876465,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '65238193-512b-4276-9242-7e859ad581e8',
    CIDADE: 'Serrana',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.021339416503906,
    MAXIMO: 37.86475372314453,
    MEDIA: 37.55387171786059,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ddb0a1e-81e6-475c-9302-ca45cd1e3a5b',
    CIDADE: 'Serra Negra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.58402633666992,
    MAXIMO: 44.82510757446289,
    MEDIA: 43.954133261675224,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b5fc45ee-2fd5-4ba6-9d5b-85e07b7badce',
    CIDADE: 'Sertãozinho',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.14613342285156,
    MAXIMO: 37.17673873901367,
    MEDIA: 37.16143608093262,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93100959-87fb-4b0d-b81c-9c49ed67dd78',
    CIDADE: 'Sete Barras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.1298828125,
    MAXIMO: 40.41325378417969,
    MEDIA: 40.23335520426432,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8e97ccd-2a4e-41ea-9d72-60faa229972e',
    CIDADE: 'Severínia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.204078674316406,
    MAXIMO: 36.218685150146484,
    MEDIA: 36.20441357327615,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eafce307-dff3-42a4-81a6-c5a8367631bb',
    CIDADE: 'Silveiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.84036636352539,
    MAXIMO: 36.658729553222656,
    MEDIA: 36.18660211348721,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f363209c-b2a6-4adb-915a-9bdf100f6980',
    CIDADE: 'Socorro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.836273193359375,
    MAXIMO: 43.8931884765625,
    MEDIA: 42.886506010616,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0be40330-5d1b-4b94-be3a-6e7f46fc441d',
    CIDADE: 'Sorocaba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.39834213256836,
    MAXIMO: 41.824859619140625,
    MEDIA: 41.695204827328396,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a4e491f-88ff-4140-b58c-79ea521b541e',
    CIDADE: 'Sud Mennucci',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.72272491455078,
    MAXIMO: 39.0284538269043,
    MEDIA: 38.87558937072754,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b3b12807-a784-452e-b004-af9f4e2eaffd',
    CIDADE: 'Sumaré',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.19287872314453,
    MAXIMO: 45.54442596435547,
    MEDIA: 45.03659561401392,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4311529-73a8-4485-9647-7745c55e0f9b',
    CIDADE: 'Suzano',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.87972640991211,
    MAXIMO: 39.45594024658203,
    MEDIA: 39.09476931442975,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7db789fa-3797-4938-976f-54a55d675730',
    CIDADE: 'Suzanápolis',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.42200469970703,
    MAXIMO: 39.20521545410156,
    MEDIA: 38.81748318491033,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3cfa957-0034-4aa3-9fb6-aebac83f332d',
    CIDADE: 'Tabapuã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.218685150146484,
    MAXIMO: 37.21758270263672,
    MEDIA: 36.644594578304805,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '120c1de0-591c-4a95-814b-94360e5d1486',
    CIDADE: 'Tabatinga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.101722717285156,
    MAXIMO: 40.684146881103516,
    MEDIA: 39.88854901912591,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb6118bb-f723-4cd7-898e-d0fd5ea32628',
    CIDADE: 'Taboão da Serra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.58099365234375,
    MAXIMO: 40.97282791137695,
    MEDIA: 40.971759388897496,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5f81399-f847-4593-abfc-d5162b113b42',
    CIDADE: 'Taciba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.68598556518555,
    MAXIMO: 42.3112907409668,
    MEDIA: 42.089323445913706,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f610c602-b388-417e-bb44-eb7ec51a8a71',
    CIDADE: 'Taguaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.82320022583008,
    MAXIMO: 40.034339904785156,
    MEDIA: 39.95761051758245,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a40b00d0-24de-4f23-bf6a-64a9a9d5b1e6',
    CIDADE: 'Taiaçu',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.23838424682617,
    MAXIMO: 37.255958557128906,
    MEDIA: 37.2502994041793,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '513fde46-8a32-4427-a61e-58c9f73d4d8b',
    CIDADE: 'Taiúva',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.23713302612305,
    MAXIMO: 37.95930862426758,
    MEDIA: 37.25794490873029,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76171a45-08b2-49a0-8d70-79fd5763e04f',
    CIDADE: 'Tambaú',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.917877197265625,
    MAXIMO: 39.928489685058594,
    MEDIA: 39.92318344116211,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e14dad5-ad1d-4c55-9758-c038f42bac9f',
    CIDADE: 'Tanabi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.951560974121094,
    MAXIMO: 36.2624626159668,
    MEDIA: 36.107011795043945,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1c9e43ea-a9b8-46ea-a8c6-7380b7adaf46',
    CIDADE: 'Tapiraí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.468772888183594,
    MAXIMO: 41.33509063720703,
    MEDIA: 41.01873652140299,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78f450be-3316-41bf-bb15-0187233d7885',
    CIDADE: 'Tapiratiba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.97334671020508,
    MAXIMO: 39.48903274536133,
    MEDIA: 38.180433705551366,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ef196fee-5e26-490b-a876-b1584e92d240',
    CIDADE: 'Taquaral',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.65347671508789,
    MAXIMO: 37.255958557128906,
    MEDIA: 37.24798643608958,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44364677-90d7-4549-a617-a5b30e5f83dd',
    CIDADE: 'Taquaritinga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.620704650878906,
    MAXIMO: 38.75826644897461,
    MEDIA: 38.68948554992676,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63e0446f-4e2c-428a-80e8-5586fe649a6e',
    CIDADE: 'Taquarituba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.71573257446289,
    MAXIMO: 39.99897766113281,
    MEDIA: 39.838893050269284,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31b7746a-4f92-4ef9-9dcb-745ccc0ff545',
    CIDADE: 'Taquarivaí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.64072799682617,
    MAXIMO: 39.748844146728516,
    MEDIA: 39.66900217996007,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2600c684-5066-4612-ab28-e0eafd54d8c7',
    CIDADE: 'Tarabai',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.30823516845703,
    MAXIMO: 42.84564971923828,
    MEDIA: 42.54841518434702,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66775cbc-405a-4a15-96c4-63c21332009a',
    CIDADE: 'Tarumã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.604156494140625,
    MAXIMO: 41.181007385253906,
    MEDIA: 40.91958503147648,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7df8e43d-1cd6-41b1-9c58-ed0d422c9054',
    CIDADE: 'Tatuí',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.94062423706055,
    MAXIMO: 41.34082794189453,
    MEDIA: 40.622117360432945,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbaf42be-9dcd-43b7-9d23-742b14dd9bde',
    CIDADE: 'Taubaté',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.087318420410156,
    MAXIMO: 36.68129348754883,
    MEDIA: 36.38430595397949,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aad2e685-63e1-4b7d-b8cd-d80574bce3a9',
    CIDADE: 'Tejupá',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.7735595703125,
    MAXIMO: 39.945560455322266,
    MEDIA: 39.8271705988232,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30d9bb3b-b227-4f21-bae6-787fe0e67ecb',
    CIDADE: 'Teodoro Sampaio',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.96556091308594,
    MAXIMO: 44.557228088378906,
    MEDIA: 44.26998202006022,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6676dd90-5396-440a-8895-f3d22cb22416',
    CIDADE: 'Terra Roxa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.72153091430664,
    MAXIMO: 36.15862274169922,
    MEDIA: 36.10708428050795,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72a891d3-d910-496d-a4c3-81ec3f659c49',
    CIDADE: 'Tietê',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.3460807800293,
    MAXIMO: 45.1910514831543,
    MEDIA: 44.04820000472418,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9cf2545-58d8-4aba-95b2-53e2c8342a1b',
    CIDADE: 'Timburi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.86724853515625,
    MAXIMO: 40.14894104003906,
    MEDIA: 39.978492686957615,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c022f3ab-b285-4f7a-a8b3-d16e3e6293bc',
    CIDADE: 'Torre de Pedra',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.52837371826172,
    MAXIMO: 40.645164489746094,
    MEDIA: 40.59643549119681,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '08ddef81-aec1-472f-bc77-b24b5eea38c0',
    CIDADE: 'Torrinha',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 44.580081939697266,
    MAXIMO: 45.936283111572266,
    MEDIA: 45.31244843756091,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7896d721-4a34-48c7-9494-ef10034e3cfe',
    CIDADE: 'Trabiju',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.39038848876953,
    MAXIMO: 43.714263916015625,
    MEDIA: 43.17021167391386,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '05950381-4c77-4225-990b-761183b5e75c',
    CIDADE: 'Tremembé',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.00897979736328,
    MAXIMO: 37.76216506958008,
    MEDIA: 37.36984768452065,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5797e5ec-e961-4e1a-8a56-ba0b0a4e9c41',
    CIDADE: 'Três Fronteiras',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.38593292236328,
    MAXIMO: 38.084495544433594,
    MEDIA: 38.003793536387704,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c83ba311-d74d-4d45-af2a-acee6a580a93',
    CIDADE: 'Tuiuti',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 42.78107833862305,
    MAXIMO: 44.87501907348633,
    MEDIA: 43.31430386054786,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74f26f86-9bca-4a83-8cab-e6d69ef085cb',
    CIDADE: 'Tupã',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.76755905151367,
    MAXIMO: 40.047969818115234,
    MEDIA: 39.90776443481445,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c1fcf455-549f-4f63-a42e-01e1840e6099',
    CIDADE: 'Tupi Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.062992095947266,
    MAXIMO: 41.70519256591797,
    MEDIA: 41.39410161608312,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '805ffc2a-7cee-4986-b6f0-256ecaec6e5a',
    CIDADE: 'Turiúba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.303829193115234,
    MAXIMO: 37.938804626464844,
    MEDIA: 37.66685374165253,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24cab920-a098-49fe-a3ee-3d2df1640912',
    CIDADE: 'Turmalina',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.39699172973633,
    MAXIMO: 37.010589599609375,
    MEDIA: 36.60568620011144,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a107129f-4e5b-46a0-a1c7-2741ce15edf0',
    CIDADE: 'Ubarana',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.39986801147461,
    MAXIMO: 38.08261489868164,
    MEDIA: 37.781660029404655,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18e76356-dbd4-4902-ba11-80d8ee1fab34',
    CIDADE: 'Ubatuba',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 34.82716751098633,
    MAXIMO: 35.59454345703125,
    MEDIA: 34.99632549157153,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '83c68e4f-07db-4e78-bb58-9f13f8364da1',
    CIDADE: 'Ubirajara',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.50041961669922,
    MAXIMO: 39.69622802734375,
    MEDIA: 39.608002880128375,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '446613c0-9d56-45c6-90e1-2f191700aa84',
    CIDADE: 'Uchoa',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.277896881103516,
    MAXIMO: 37.20755386352539,
    MEDIA: 36.668416475238494,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '00d9b97c-4acf-4cab-b255-4512165e4041',
    CIDADE: 'União Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.80298614501953,
    MAXIMO: 37.383392333984375,
    MEDIA: 37.24481307453735,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1554e60-352b-45b2-a466-ee470dfe4401',
    CIDADE: 'Urânia',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.72189712524414,
    MAXIMO: 37.71646499633789,
    MEDIA: 37.263144527106554,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dbd633eb-24a1-489b-bca0-d27893f03e73',
    CIDADE: 'Uru',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 38.47542953491211,
    MAXIMO: 39.12864303588867,
    MEDIA: 38.791287597496684,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28f5afe0-6b69-4e03-954c-d169752c2d3f',
    CIDADE: 'Urupês',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.18239212036133,
    MAXIMO: 37.696136474609375,
    MEDIA: 37.42480622591339,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a92b5700-789b-479d-a0c1-da69280d001f',
    CIDADE: 'Valentim Gentil',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.3397216796875,
    MAXIMO: 36.96439743041992,
    MEDIA: 36.59811753334693,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '649482a9-e357-4378-a739-9539f58ec63f',
    CIDADE: 'Valinhos',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.910640716552734,
    MAXIMO: 43.735172271728516,
    MEDIA: 43.27567638469411,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b278846c-f337-47f0-8b9c-ea07bd5777f5',
    CIDADE: 'Valparaíso',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.57792282104492,
    MAXIMO: 40.17782211303711,
    MEDIA: 39.86090596516927,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab94cc8b-0b46-4dca-8931-973031f1510d',
    CIDADE: 'Vargem',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.124271392822266,
    MAXIMO: 42.59624481201172,
    MEDIA: 41.480499895491214,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'acdf7094-643e-4f3c-b781-ae5865a3ad84',
    CIDADE: 'Vargem Grande do Sul',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.68234634399414,
    MAXIMO: 42.2940559387207,
    MEDIA: 41.48820114135742,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95447461-4c97-463d-a303-13f4766cc4f0',
    CIDADE: 'Vargem Grande Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.25457000732422,
    MAXIMO: 41.89336013793945,
    MEDIA: 41.30448595988919,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e7f6eb46-adb7-4434-93aa-682e8f942700',
    CIDADE: 'Várzea Paulista',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 40.75758743286133,
    MAXIMO: 40.75758743286133,
    MEDIA: 40.75758743286133,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dbd5f7c9-4cb9-40ca-9f31-d371dbe74afe',
    CIDADE: 'Vera Cruz',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.22660446166992,
    MAXIMO: 39.66222381591797,
    MEDIA: 39.37702746045159,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '463328a3-5cf7-4eb3-970c-d72937bff03f',
    CIDADE: 'Vinhedo',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.910640716552734,
    MAXIMO: 42.09383773803711,
    MEDIA: 42.089790814100795,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d25fd5e-d1f1-4d35-b951-7169517f66a5',
    CIDADE: 'Viradouro',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 35.95824432373047,
    MAXIMO: 36.65347671508789,
    MEDIA: 36.37049830554809,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '151c251f-5979-4eb2-8ff4-76bd82749d3c',
    CIDADE: 'Vista Alegre do Alto',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.236045837402344,
    MAXIMO: 37.907989501953125,
    MEDIA: 37.27262271252275,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea94409f-4bfb-4ab9-b416-8872d0a94f97',
    CIDADE: 'Vitória Brasil',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.67545700073242,
    MAXIMO: 37.3068962097168,
    MEDIA: 36.76940359863111,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d300d68-a112-4353-9c9b-d7bf3b7b044b',
    CIDADE: 'Votorantim',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 41.451026916503906,
    MAXIMO: 41.627838134765625,
    MEDIA: 41.539432525634766,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9b2f3ed4-69e9-4b27-bed2-7ec6ee260720',
    CIDADE: 'Votuporanga',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 36.06241989135742,
    MAXIMO: 36.667198181152344,
    MEDIA: 36.42535031936731,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e853ee2-7cb5-4c78-9617-196c78f349bd',
    CIDADE: 'Zacarias',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 37.383392333984375,
    MAXIMO: 38.29319381713867,
    MEDIA: 37.915944068820814,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8798857-56a3-4f46-9115-e91156871a13',
    CIDADE: 'Chavantes',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 39.811805725097656,
    MAXIMO: 40.043861389160156,
    MEDIA: 40.005949298157205,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ca26968-fdaf-403d-b828-fc9802ebe8a6',
    CIDADE: 'Estiva Gerbi',
    SIGLA: 'SP',
    ESTADO: 'São Paulo',
    MINIMO: 43.465885162353516,
    MAXIMO: 44.409454345703125,
    MEDIA: 44.340556701770694,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '18,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75391eff-5ba3-45e2-b0ed-d80ac23c3408',
    CIDADE: 'Abatiá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.784950256347656,
    MAXIMO: 41.13580322265625,
    MEDIA: 40.89890985150122,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c445aecd-d747-427a-a7a1-0b7a59d4a8a1',
    CIDADE: 'Adrianópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 39.9748420715332,
    MAXIMO: 40.354339599609375,
    MEDIA: 40.16058794657389,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '410c8eea-a3a5-414e-9412-4cd4d7152901',
    CIDADE: 'Agudos do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.757049560546875,
    MAXIMO: 42.05923080444336,
    MEDIA: 41.77636845124517,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3510001-f1ae-4a79-87a8-5e5c8edc4b12',
    CIDADE: 'Almirante Tamandaré',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.85530090332031,
    MAXIMO: 41.12932586669922,
    MEDIA: 40.98261167296614,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbb27cb6-2c62-43f3-a752-4ec30c8ee6e8',
    CIDADE: 'Altamira do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.694305419921875,
    MAXIMO: 47.79678726196289,
    MEDIA: 47.19485128232602,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e680c780-3104-42fd-ab17-b1844bdb4be1',
    CIDADE: 'Altônia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.77174758911133,
    MAXIMO: 50.0450439453125,
    MEDIA: 49.908395767211914,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d16d081-9178-4cb4-8c6f-f3bd5c12a43d',
    CIDADE: 'Alto Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.572357177734375,
    MAXIMO: 45.3203010559082,
    MEDIA: 45.00556020912267,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a4f8ea8-1f2e-4203-8928-6f77648929e9',
    CIDADE: 'Alto Piquiri',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.914039611816406,
    MAXIMO: 49.09585189819336,
    MEDIA: 49.00494575500488,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'decc0220-4773-4f3b-bbd0-903d6a27855d',
    CIDADE: 'Alvorada do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.127037048339844,
    MAXIMO: 42.28675079345703,
    MEDIA: 42.20689392089844,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e76fad2-9fdf-46cc-bde1-ffd76184ca6f',
    CIDADE: 'Amaporã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.36297607421875,
    MAXIMO: 46.5612678527832,
    MEDIA: 46.46212196350098,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0536a2af-6228-4afa-971a-8d8e8d49ec2d',
    CIDADE: 'Ampére',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.77627944946289,
    MAXIMO: 49.99186325073242,
    MEDIA: 49.30328540165676,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '192a2bad-c398-46f3-a4a7-25546125fe96',
    CIDADE: 'Anahy',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.29738235473633,
    MAXIMO: 48.87287139892578,
    MEDIA: 48.75882937099977,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c27d4a9-8234-43db-8dbf-c7c23d9b3752',
    CIDADE: 'Andirá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.42034149169922,
    MAXIMO: 40.88166427612305,
    MEDIA: 40.62071566798579,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c36621b-8286-4f3b-8217-d497f2bb96fe',
    CIDADE: 'Ângulo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.941795349121094,
    MAXIMO: 44.50222396850586,
    MEDIA: 44.072110835173795,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fd02fb7-9168-48d7-80be-9e712342d02b',
    CIDADE: 'Antonina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.367088317871094,
    MAXIMO: 40.52296447753906,
    MEDIA: 40.42598088582357,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed66ac4c-9b81-4819-a8ef-e22286a515b5',
    CIDADE: 'Antônio Olinto',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.89210891723633,
    MAXIMO: 42.4383430480957,
    MEDIA: 42.24484613570077,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78762635-9b8b-403e-8a82-db5fbd4f32d4',
    CIDADE: 'Apucarana',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.87223434448242,
    MAXIMO: 43.59502410888672,
    MEDIA: 43.23259607950846,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '185f7e12-e5b4-4e1c-809d-a27c95b9a853',
    CIDADE: 'Arapongas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.756805419921875,
    MAXIMO: 43.59502410888672,
    MEDIA: 43.215301962856834,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e343bdc6-1217-4ccd-90b0-c6347711bc10',
    CIDADE: 'Arapoti',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.64048385620117,
    MAXIMO: 41.22446060180664,
    MEDIA: 40.90629119873047,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd58179ce-d7cd-44b3-99e5-5725650d0c39',
    CIDADE: 'Arapuã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.330406188964844,
    MAXIMO: 44.75448226928711,
    MEDIA: 44.566386271106026,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3659e9ed-b6cc-49e5-90a1-28e6a51eaa60',
    CIDADE: 'Araruna',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.34978103637695,
    MAXIMO: 46.46758270263672,
    MEDIA: 46.408681869506836,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b06cfe52-e9e7-43d9-b1c6-477b4b76f42d',
    CIDADE: 'Araucária',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.350460052490234,
    MAXIMO: 41.50760269165039,
    MEDIA: 41.42903137207031,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8aa772c-e539-4654-b07b-caa9ca7bf8aa',
    CIDADE: 'Ariranha do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.696292877197266,
    MAXIMO: 44.38481140136719,
    MEDIA: 43.962794046978495,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5bd5e66a-776f-441c-80e0-c6fbc57e4ec8',
    CIDADE: 'Assaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.629966735839844,
    MAXIMO: 42.41082000732422,
    MEDIA: 42.01180226709296,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a20ce2e-cbd0-4c23-81f2-c6764e43f1b8',
    CIDADE: 'Assis Chateaubriand',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.73114776611328,
    MAXIMO: 50.203426361083984,
    MEDIA: 49.95659891764323,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '575d5d40-8347-4d14-af3e-dff1a1ad7417',
    CIDADE: 'Astorga',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.16780471801758,
    MAXIMO: 44.248111724853516,
    MEDIA: 43.65882623286745,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19617153-3da7-4e7e-a300-8571f129daf9',
    CIDADE: 'Atalaia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.941795349121094,
    MAXIMO: 44.73973083496094,
    MEDIA: 44.37190878693579,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d8b7490-5810-4a43-aa4f-54059ca02195',
    CIDADE: 'Balsa Nova',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.543006896972656,
    MAXIMO: 41.71472930908203,
    MEDIA: 41.628868103027344,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'afd618a6-e377-4bec-b90b-42492159dd73',
    CIDADE: 'Bandeirantes',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.784950256347656,
    MAXIMO: 40.88166427612305,
    MEDIA: 40.83330726623535,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '617ef783-011e-46c5-a348-3007e603d81e',
    CIDADE: 'Barbosa Ferraz',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.07246780395508,
    MAXIMO: 45.12928009033203,
    MEDIA: 45.100873947143555,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e07f68a-55ae-4cbe-9973-60dbe05204a6',
    CIDADE: 'Barracão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.224449157714844,
    MAXIMO: 49.48472213745117,
    MEDIA: 48.9949612499452,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '379b3ae5-7c27-4897-ba76-17de865e9fc4',
    CIDADE: 'Barra do Jacaré',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.42034149169922,
    MAXIMO: 40.66424560546875,
    MEDIA: 40.516060196132905,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc28e07d-c227-4532-819a-347776b2a744',
    CIDADE: 'Bela Vista da Caroba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.28596496582031,
    MAXIMO: 49.99186325073242,
    MEDIA: 49.8819704181386,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee359c4e-bffa-4028-962d-b5b59b67b17e',
    CIDADE: 'Bela Vista do Paraíso',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.28675079345703,
    MAXIMO: 42.7923698425293,
    MEDIA: 42.36607454318191,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b882756-64b0-4ee3-8200-3f2d28383a16',
    CIDADE: 'Bituruna',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.91862487792969,
    MAXIMO: 44.118465423583984,
    MEDIA: 44.018545150756836,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48778d46-4f98-4820-9d7f-ab4bfc0f5dba',
    CIDADE: 'Boa Esperança',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.97522735595703,
    MAXIMO: 47.683048248291016,
    MEDIA: 47.269109085218055,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a87e865c-2667-42a1-85f4-61991e07c2de',
    CIDADE: 'Boa Esperança do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.62744140625,
    MAXIMO: 49.32408142089844,
    MEDIA: 48.701862175018846,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37f7c209-93ba-4132-af74-426d92ed5c31',
    CIDADE: 'Boa Ventura de São Roque',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.74946975708008,
    MAXIMO: 44.432228088378906,
    MEDIA: 44.10787757567094,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f8ed9ea-4652-478b-8ba1-b646a003bc99',
    CIDADE: 'Boa Vista da Aparecida',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.32408142089844,
    MAXIMO: 49.376556396484375,
    MEDIA: 49.350318908691406,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd73d2e11-2fec-4e5c-a6b0-2213a9acdc6d',
    CIDADE: 'Bocaiúva do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.25885009765625,
    MAXIMO: 40.709903717041016,
    MEDIA: 40.50642649332682,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5328757d-f494-4928-8f8c-ad0205875ed8',
    CIDADE: 'Bom Jesus do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.012962341308594,
    MAXIMO: 49.48472213745117,
    MEDIA: 49.09766629098948,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '599af83d-e0bf-4774-aee5-2e86a18fb74d',
    CIDADE: 'Bom Sucesso',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.08412551879883,
    MAXIMO: 44.47856521606445,
    MEDIA: 44.28134536743164,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac606385-604b-4be8-935d-50502aa294ec',
    CIDADE: 'Bom Sucesso do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.45872497558594,
    MAXIMO: 47.157676696777344,
    MEDIA: 46.980167370057345,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0672b8d9-11b5-4a2c-8a42-8febd609d76a',
    CIDADE: 'Borrazópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.45585632324219,
    MAXIMO: 44.267364501953125,
    MEDIA: 43.90544805600404,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ffb248f7-4c9e-4cf0-9068-a2b5a54f4b4f',
    CIDADE: 'Braganey',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.78782272338867,
    MAXIMO: 48.88431167602539,
    MEDIA: 48.552292824166074,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ec6fd7a-89bd-45d6-b495-cdb233d53a4e',
    CIDADE: 'Brasilândia do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.34590530395508,
    MAXIMO: 49.560794830322266,
    MEDIA: 49.45335006713867,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f926fb39-daab-4db5-990c-8e85a7e6cb6f',
    CIDADE: 'Cafeara',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.84934997558594,
    MAXIMO: 43.3896484375,
    MEDIA: 43.26091315873567,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8bc0214c-b41f-418b-b311-091300a764cd',
    CIDADE: 'Cafelândia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.82817459106445,
    MAXIMO: 49.8525505065918,
    MEDIA: 49.36542531215973,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a0d2aef-e97a-456c-9274-020ac57ddf16',
    CIDADE: 'Cafezal do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.10993957519531,
    MAXIMO: 49.70646667480469,
    MEDIA: 49.24440487320286,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '50aa26de-3958-4b86-b043-ceec6b265ef1',
    CIDADE: 'Califórnia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.87223434448242,
    MAXIMO: 43.33952713012695,
    MEDIA: 43.16353616628004,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4a71f02-a818-41ff-91d9-5ee45a33860c',
    CIDADE: 'Cambará',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.09907913208008,
    MAXIMO: 40.66424560546875,
    MEDIA: 40.34110377344869,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a291c01-165b-4c9b-9240-b7c7a83740d3',
    CIDADE: 'Cambé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.42771911621094,
    MAXIMO: 42.57054138183594,
    MEDIA: 42.49913024902344,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4df9a42b-dadb-4c88-bb09-316a9038f84b',
    CIDADE: 'Cambira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.33952713012695,
    MAXIMO: 43.70599365234375,
    MEDIA: 43.641509426686454,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4f5a528a-23c9-4d59-9829-24492577b2c7',
    CIDADE: 'Campina da Lagoa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.20781707763672,
    MAXIMO: 47.78782272338867,
    MEDIA: 47.49583053588867,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b368df8-4f96-4d10-97ec-8f59bf491982',
    CIDADE: 'Campina do Simão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.173099517822266,
    MAXIMO: 44.760372161865234,
    MEDIA: 44.57099235082979,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33a2d65b-cc77-42e0-afc8-2654561a1181',
    CIDADE: 'Campina Grande do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.12561798095703,
    MAXIMO: 40.844486236572266,
    MEDIA: 40.48505210876465,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '838afcbf-d55a-40f7-8c0f-537e5e664541',
    CIDADE: 'Campo Bonito',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.34019470214844,
    MAXIMO: 48.3450813293457,
    MEDIA: 48.34263801574707,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3084b4fc-e196-4250-b8c4-a10dc7ac16e6',
    CIDADE: 'Campo do Tenente',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.019371032714844,
    MAXIMO: 42.127803802490234,
    MEDIA: 42.07358741760254,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0968feb2-05cf-4a78-a9f2-b1a6045029b3',
    CIDADE: 'Campo Largo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.165931701660156,
    MAXIMO: 41.395755767822266,
    MEDIA: 41.283461570739746,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3e9a637-e0dd-4d2e-917a-ebda5fcd963f',
    CIDADE: 'Campo Magro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.01115417480469,
    MAXIMO: 41.242618560791016,
    MEDIA: 41.12142721324585,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a63103fe-2e3e-4bd0-a03b-85cc4c08376c',
    CIDADE: 'Campo Mourão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.51799774169922,
    MAXIMO: 45.98877716064453,
    MEDIA: 45.753387451171875,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3be5e232-881f-484a-ad36-2cb8288541fd',
    CIDADE: 'Cândido de Abreu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.11776351928711,
    MAXIMO: 43.74946975708008,
    MEDIA: 43.424818674723305,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '176d3327-850a-47d0-bda4-103fea790457',
    CIDADE: 'Candói',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.728755950927734,
    MAXIMO: 45.89186477661133,
    MEDIA: 45.232126235961914,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e43d63df-e896-4103-8210-10bc102173f3',
    CIDADE: 'Cantagalo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.12088394165039,
    MAXIMO: 45.58723831176758,
    MEDIA: 45.354061126708984,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9d8a8856-1f35-4ab0-8aa7-94322b81b748',
    CIDADE: 'Capanema',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.28106689453125,
    MAXIMO: 50.51631546020508,
    MEDIA: 50.398691177368164,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '26ece219-94c1-4b6a-9992-4de85ab1bf90',
    CIDADE: 'Capitão Leônidas Marques',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.85944366455078,
    MAXIMO: 50.29612350463867,
    MEDIA: 50.07450794403643,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad0ba9b6-7796-4ce4-9a80-1cccbfbd5207',
    CIDADE: 'Carambeí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.73476028442383,
    MAXIMO: 41.95746612548828,
    MEDIA: 41.846113204956055,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fcac4c88-431e-4616-ac88-32b3226b3b99',
    CIDADE: 'Carlópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.093135833740234,
    MAXIMO: 40.25189208984375,
    MEDIA: 40.17251396179199,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f5b05e3f-c3b1-42a4-8c69-b410225c1df9',
    CIDADE: 'Cascavel',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.88363265991211,
    MAXIMO: 49.869258880615234,
    MEDIA: 49.52136720929827,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d60f0c6-b0ec-4c64-8fc4-7e6984525743',
    CIDADE: 'Castro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.80037307739258,
    MAXIMO: 41.52309036254883,
    MEDIA: 41.28128094143338,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '584813ee-5b71-41d3-a60e-ea1b389a1918',
    CIDADE: 'Catanduvas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.313663482666016,
    MAXIMO: 48.86846160888672,
    MEDIA: 48.59106254577637,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba64808b-9fee-4ff9-a06b-f9008d1637ad',
    CIDADE: 'Centenário do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.483150482177734,
    MAXIMO: 43.3896484375,
    MEDIA: 42.90518098050857,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fe1974b-efa6-417d-85b2-e408e5b0df08',
    CIDADE: 'Cerro Azul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.47597885131836,
    MAXIMO: 40.823463439941406,
    MEDIA: 40.656124877929685,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3d88a682-aa3b-47c0-b728-c3b36814bfc4',
    CIDADE: 'Céu Azul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.27130889892578,
    MAXIMO: 50.646156311035156,
    MEDIA: 50.37567615509033,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7d0b1c67-547b-4925-9c21-6eebebc6a6e0',
    CIDADE: 'Chopinzinho',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.792152404785156,
    MAXIMO: 46.28242874145508,
    MEDIA: 46.03729057312012,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2cfa301-c705-423a-86b4-d37e0b7a0f8d',
    CIDADE: 'Cianorte',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.70074462890625,
    MAXIMO: 46.83964157104492,
    MEDIA: 46.770193099975586,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '67f514c7-7258-4661-a38e-b6285d884cc4',
    CIDADE: 'Cidade Gaúcha',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.5612678527832,
    MAXIMO: 47.40142822265625,
    MEDIA: 46.98534535670581,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b1447ab-68b6-4bfd-bced-ef957dbc53f6',
    CIDADE: 'Clevelândia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.08354949951172,
    MAXIMO: 45.997318267822266,
    MEDIA: 45.50224177042643,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c8adb787-dcb3-4f70-a716-57197a19e20c',
    CIDADE: 'Colombo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.709903717041016,
    MAXIMO: 41.01115417480469,
    MEDIA: 40.86149955265871,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bcda41c6-5b66-41b3-8eb9-5d21c8f51dd3',
    CIDADE: 'Colorado',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.77179718017578,
    MAXIMO: 43.982032775878906,
    MEDIA: 43.876914978027344,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e75ff056-93f1-4a58-8659-c7b1aae9874b',
    CIDADE: 'Congonhinhas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.24281311035156,
    MAXIMO: 41.603736877441406,
    MEDIA: 41.423274993896484,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0ccdff0a-ea69-4da7-969d-4ed43ac0adf3',
    CIDADE: 'Conselheiro Mairinck',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.616493225097656,
    MAXIMO: 41.10638427734375,
    MEDIA: 40.782436344694595,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5038c5b6-778c-4ff4-89b4-079b8818c321',
    CIDADE: 'Contenda',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.50760269165039,
    MAXIMO: 41.819000244140625,
    MEDIA: 41.59510257807152,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '134d6d05-e659-4af1-aa7f-f85e53a8d3eb',
    CIDADE: 'Corbélia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.82817459106445,
    MAXIMO: 48.88431167602539,
    MEDIA: 48.861785888671875,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66771104-b316-4065-b6dc-beb5e55d789b',
    CIDADE: 'Cornélio Procópio',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.046749114990234,
    MAXIMO: 41.77851104736328,
    MEDIA: 41.37290294058793,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '789d4e8d-6de7-44d9-a0ad-cc4c3fb4d6d6',
    CIDADE: 'Coronel Domingos Soares',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.33441925048828,
    MAXIMO: 44.9135627746582,
    MEDIA: 44.605625697544646,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6909b0ec-fd4a-4b53-8683-58c0238f5b93',
    CIDADE: 'Coronel Vivida',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.68657684326172,
    MAXIMO: 46.148677825927734,
    MEDIA: 45.91762733459473,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17b7bc34-c7da-4272-93e2-8a388c58b455',
    CIDADE: 'Corumbataí do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.07246780395508,
    MAXIMO: 45.58987045288086,
    MEDIA: 45.34739541676462,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27cf1644-8748-416c-a703-238dc3b75926',
    CIDADE: 'Cruzeiro do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.05266189575195,
    MAXIMO: 48.7587776184082,
    MEDIA: 48.50275086111188,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '849d5a49-d225-408b-95eb-3694df38f1bb',
    CIDADE: 'Cruzeiro do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.60184860229492,
    MAXIMO: 48.26103973388672,
    MEDIA: 47.88706588745117,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3afb201-0d71-4fdb-8247-de3fe86afa23',
    CIDADE: 'Cruzeiro do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.16658020019531,
    MAXIMO: 44.73973083496094,
    MEDIA: 44.491030379467105,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22a3a928-d269-4ab9-9bcb-28f987f5b853',
    CIDADE: 'Cruz Machado',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.46348571777344,
    MAXIMO: 44.12059020996094,
    MEDIA: 43.760491943359376,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5db15d0-fa90-4fdb-9989-ad8f05b7fb83',
    CIDADE: 'Cruzmaltina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.54660415649414,
    MAXIMO: 43.898292541503906,
    MEDIA: 43.72244834899902,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e20d0d07-9c80-4bbd-85aa-010963d3b11b',
    CIDADE: 'Curitiba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.01115417480469,
    MAXIMO: 41.195255279541016,
    MEDIA: 41.10320472717285,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '603014ba-55c3-4c74-ad21-90b35a51e186',
    CIDADE: 'Curiúva',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.57651138305664,
    MAXIMO: 41.91302490234375,
    MEDIA: 41.744768142700195,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e0bf89af-c54e-4023-b851-c0ca95059a3f',
    CIDADE: 'Diamante do Norte',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.29746627807617,
    MAXIMO: 46.26422882080078,
    MEDIA: 45.76595746474423,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eab0152d-f5f5-4345-aca2-74401af43681',
    CIDADE: 'Diamante do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.672950744628906,
    MAXIMO: 47.79678726196289,
    MEDIA: 47.20761137054093,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53c70892-1099-46cf-a9c5-e13d9e9aebe4',
    CIDADE: "Diamante D'Oeste",
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.912132263183594,
    MAXIMO: 51.195472717285156,
    MEDIA: 51.06495453137894,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53462551-43d1-4b49-84cc-f8bebd7bf2a5',
    CIDADE: 'Dois Vizinhos',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.8884162902832,
    MAXIMO: 48.05266189575195,
    MEDIA: 47.97053909301758,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cfe2dfb3-3b0f-47f6-9e21-dbb8515e6dc7',
    CIDADE: 'Douradina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.37480163574219,
    MAXIMO: 48.24397659301758,
    MEDIA: 47.74395301272067,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8cf1a002-a0ec-426e-ba4b-f67edbd65994',
    CIDADE: 'Doutor Camargo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.76304244995117,
    MAXIMO: 45.627655029296875,
    MEDIA: 45.1560907677336,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77a549b5-954d-45c3-b79b-b98918364abc',
    CIDADE: 'Enéas Marques',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.69575881958008,
    MAXIMO: 48.453914642333984,
    MEDIA: 48.29066184625895,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c1308985-4c67-4ec7-a9bf-30cad38f7798',
    CIDADE: 'Engenheiro Beltrão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.87843322753906,
    MAXIMO: 45.87549591064453,
    MEDIA: 45.47336268598445,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '24a6378f-39ef-43bc-ac68-049700d03500',
    CIDADE: 'Esperança Nova',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.21213912963867,
    MAXIMO: 49.53018569946289,
    MEDIA: 49.448223889496724,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58d864ee-6b14-47b1-8f8f-60599babecb4',
    CIDADE: 'Entre Rios do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.1078987121582,
    MAXIMO: 51.31973648071289,
    MEDIA: 51.17250444198857,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e63a6fb-56c0-492b-84b2-0c361c4dc9bf',
    CIDADE: 'Espigão Alto do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.039207458496094,
    MAXIMO: 47.74751663208008,
    MEDIA: 47.396218987788295,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39ad947b-3989-4d66-b694-2df57b4bab34',
    CIDADE: 'Farol',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.46758270263672,
    MAXIMO: 47.084938049316406,
    MEDIA: 46.78296141141607,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea475fcb-f741-42d6-97a3-c066df7c55e3',
    CIDADE: 'Faxinal',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.202632904052734,
    MAXIMO: 43.45585632324219,
    MEDIA: 43.32924461364746,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79d97b2b-d0d0-40a0-8f1f-817dc296c39c',
    CIDADE: 'Fazenda Rio Grande',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.34480667114258,
    MAXIMO: 41.50760269165039,
    MEDIA: 41.34522153030664,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '107e83e9-7bc9-4eee-8949-ae1a9180099a',
    CIDADE: 'Fênix',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.57767868041992,
    MAXIMO: 45.42425537109375,
    MEDIA: 44.94739945642172,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98b364d7-b166-4273-9345-8a963f5845ae',
    CIDADE: 'Fernandes Pinheiro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.521034240722656,
    MAXIMO: 42.848838806152344,
    MEDIA: 42.70588175455729,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ececf81c-6125-44ed-88b5-81120ceda466',
    CIDADE: 'Figueira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.366485595703125,
    MAXIMO: 41.42075729370117,
    MEDIA: 41.41255449090327,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '99eb822c-0911-43f0-a8f3-e5a0e8eefda3',
    CIDADE: 'Floraí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.90629959106445,
    MAXIMO: 45.48045349121094,
    MEDIA: 45.32439574141473,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a84b0a6-ac4c-49e0-8c53-a2b9624a23f3',
    CIDADE: 'Flor da Serra do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.725067138671875,
    MAXIMO: 49.012962341308594,
    MEDIA: 48.21086714227466,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db83d917-b233-4b39-a4ed-ead35068cfc8',
    CIDADE: 'Floresta',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.76304244995117,
    MAXIMO: 45.30516052246094,
    MEDIA: 44.84265918528208,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b94be476-f47a-4d3c-8697-71c6e2a5a50d',
    CIDADE: 'Florestópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.127037048339844,
    MAXIMO: 43.009307861328125,
    MEDIA: 42.61665320818664,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '52c44e01-a78d-4724-8257-e445bf030e1f',
    CIDADE: 'Flórida',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.941795349121094,
    MAXIMO: 44.337486267089844,
    MEDIA: 44.05485948127294,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8a1d886b-4a83-4cee-b20d-80c182093584',
    CIDADE: 'Formosa do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.60918426513672,
    MAXIMO: 49.560794830322266,
    MEDIA: 49.11861224954885,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '355e600a-fa51-4330-aed3-e347d6c90cb4',
    CIDADE: 'Foz do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.25979232788086,
    MAXIMO: 51.809288024902344,
    MEDIA: 51.5614247338437,
    OFICIAL: 52,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8cc8311b-2bcc-48e5-b3c5-4f1bd72ceae2',
    CIDADE: 'Francisco Alves',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.93522262573242,
    MAXIMO: 50.2775764465332,
    MEDIA: 50.10294273663685,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed226841-900d-41e5-9a35-ce80c51c0c96',
    CIDADE: 'Francisco Beltrão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.69575881958008,
    MAXIMO: 48.77627944946289,
    MEDIA: 48.237545013427734,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9d3b9884-622c-4ebf-b05c-aaf0e0d5d720',
    CIDADE: 'Foz do Jordão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.69590377807617,
    MAXIMO: 45.4264030456543,
    MEDIA: 45.120508076569216,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a877f72-18d2-4091-bcb5-05201311cf6f',
    CIDADE: 'General Carneiro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.92353439331055,
    MAXIMO: 44.11811828613281,
    MEDIA: 43.98703098297119,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '682cbbc4-7d5e-4e96-9a1e-f6ec287ef924',
    CIDADE: 'Godoy Moreira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.65735626220703,
    MAXIMO: 45.12928009033203,
    MEDIA: 44.72301728226967,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ecc220b-74b8-4894-8ad8-8f24289bdf11',
    CIDADE: 'Goioerê',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.10223388671875,
    MAXIMO: 48.60918426513672,
    MEDIA: 48.355709075927734,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5dc90f56-d5c8-4942-b9d7-3918353403b0',
    CIDADE: 'Goioxim',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.7422981262207,
    MAXIMO: 45.14733123779297,
    MEDIA: 44.94114685058594,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6dca3d2-b30b-4121-ae6f-5aa386f417b3',
    CIDADE: 'Grandes Rios',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.54660415649414,
    MAXIMO: 43.96876907348633,
    MEDIA: 43.71712021208143,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10316bcf-8b6a-492c-9b1f-432e632b6d74',
    CIDADE: 'Guaíra',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.51825714111328,
    MAXIMO: 50.72263717651367,
    MEDIA: 50.62044715881348,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0baa1ff8-dc33-47e9-9b72-b3495b4a283e',
    CIDADE: 'Guairaçá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.74571228027344,
    MAXIMO: 46.12869644165039,
    MEDIA: 45.937204360961914,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7ca937b-f719-41b9-bf75-fbcf726380b5',
    CIDADE: 'Guamiranga',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.72089385986328,
    MAXIMO: 43.18086242675781,
    MEDIA: 43.04514670533425,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62a7d44e-4bed-4ca2-910b-4f0534d88e78',
    CIDADE: 'Guapirama',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.42021179199219,
    MAXIMO: 40.88407897949219,
    MEDIA: 40.64446566262409,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0665f21c-7878-4a51-a269-074426ca237c',
    CIDADE: 'Guaporema',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.15097427368164,
    MAXIMO: 46.76634216308594,
    MEDIA: 46.552914212940806,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e04c8fe-59a1-46fa-b4df-71de1c239bca',
    CIDADE: 'Guaraci',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.009307861328125,
    MAXIMO: 43.55072021484375,
    MEDIA: 43.29037364294768,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1410b417-704e-4c88-9f71-8581833db3db',
    CIDADE: 'Guaraniaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.18434143066406,
    MAXIMO: 47.79678726196289,
    MEDIA: 47.62789058685303,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '582c7e6f-cd6d-4fef-ac0d-be445c9a94d8',
    CIDADE: 'Guarapuava',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.64333724975586,
    MAXIMO: 44.42612838745117,
    MEDIA: 44.03354759216309,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c448765a-bcb2-4299-8936-62a84feef517',
    CIDADE: 'Guaraqueçaba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 39.9369010925293,
    MAXIMO: 40.2299919128418,
    MEDIA: 40.07133756365095,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b20c921-010c-44da-88b8-40a3b6206527',
    CIDADE: 'Guaratuba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.807498931884766,
    MAXIMO: 41.20869827270508,
    MEDIA: 40.98898696899414,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb8aa828-59c3-4e2d-9637-9229b88012c4',
    CIDADE: 'Honório Serpa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.19007873535156,
    MAXIMO: 45.56733322143555,
    MEDIA: 45.378705978393555,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba159bad-c215-4352-981d-191cb6819dcb',
    CIDADE: 'Ibaiti',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.10638427734375,
    MAXIMO: 41.366485595703125,
    MEDIA: 41.200828552246094,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '102eb01f-d51f-40eb-9c63-4c2459ed8f59',
    CIDADE: 'Ibema',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.74751663208008,
    MAXIMO: 48.34019470214844,
    MEDIA: 48.318230329454224,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63883151-f96b-402e-9fae-375e28e53f66',
    CIDADE: 'Ibiporã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.740875244140625,
    MAXIMO: 42.57054138183594,
    MEDIA: 42.17114707458562,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1b9fa276-eda6-49ff-83d1-f170629e5da7',
    CIDADE: 'Icaraíma',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.37795639038086,
    MAXIMO: 48.700225830078125,
    MEDIA: 48.53909111022949,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7a9b4000-f80c-4ec2-ab21-de3bdff18faf',
    CIDADE: 'Iguaraçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.71881866455078,
    MAXIMO: 44.104740142822266,
    MEDIA: 44.019210723769014,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7cf88ccb-a487-471d-b9da-d9d78cc74352',
    CIDADE: 'Iguatu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.29738235473633,
    MAXIMO: 48.87287139892578,
    MEDIA: 48.423222231279276,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8bf2b7f-410f-4ccf-a242-4b60a6c5d699',
    CIDADE: 'Imbaú',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.09101486206055,
    MAXIMO: 42.66250991821289,
    MEDIA: 42.39006491734111,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39ad603b-b430-4576-8d67-d464b7aedd77',
    CIDADE: 'Imbituva',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.457786560058594,
    MAXIMO: 42.95568084716797,
    MEDIA: 42.77514415517811,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a4dcf8f8-66c1-45cb-9fdd-f75a722645d1',
    CIDADE: 'Inácio Martins',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.50882339477539,
    MAXIMO: 43.76439666748047,
    MEDIA: 43.633261362711586,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e77c4c8-3422-4c2c-b5c0-76c53da055ab',
    CIDADE: 'Inajá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.17597579956055,
    MAXIMO: 44.37411117553711,
    MEDIA: 44.33304904178201,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '440fce5e-c57f-4b8e-ba69-0bc16627017b',
    CIDADE: 'Indianópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.9244270324707,
    MAXIMO: 46.54178237915039,
    MEDIA: 46.38271662389242,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6f3d4ee1-aca5-4ce9-b934-690b9dc8d560',
    CIDADE: 'Ipiranga',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.16914367675781,
    MAXIMO: 42.635528564453125,
    MEDIA: 42.42985153198242,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '53bf7310-b753-4159-8035-62525a4b73a2',
    CIDADE: 'Iporã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.10993957519531,
    MAXIMO: 50.2775764465332,
    MEDIA: 49.744197808429305,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0bdec47b-2f54-4910-85cb-8f7b0f8ff3cf',
    CIDADE: 'Iracema do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.24169921875,
    MAXIMO: 49.329734802246094,
    MEDIA: 49.26161443701449,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '362112b4-b992-4d71-b4d2-87c6685947c5',
    CIDADE: 'Irati',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.92457580566406,
    MAXIMO: 43.508907318115234,
    MEDIA: 43.17412757873535,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71e34866-8c58-4fa6-93f6-d829e41bb226',
    CIDADE: 'Iretama',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.712825775146484,
    MAXIMO: 45.66663360595703,
    MEDIA: 45.329699520949454,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3df9fcf9-8d8c-4803-b7df-3c268fb80da7',
    CIDADE: 'Itaguajé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.41144943237305,
    MAXIMO: 43.982032775878906,
    MEDIA: 43.659376399454,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2132eed-71d5-4748-a276-42211f74464b',
    CIDADE: 'Itaipulândia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.21712875366211,
    MAXIMO: 51.68793487548828,
    MEDIA: 51.46764031204761,
    OFICIAL: 52,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '14342c42-aef0-4109-a2f9-390a81c5ee3a',
    CIDADE: 'Itambaracá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.5277214050293,
    MAXIMO: 40.99807357788086,
    MEDIA: 40.79392586217874,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b918b2a5-cd8a-4db1-afbb-c4599c523dbe',
    CIDADE: 'Itambé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.36788558959961,
    MAXIMO: 44.989253997802734,
    MEDIA: 44.769995590901075,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c24c35e0-fd73-4ef3-854c-843e11e9c790',
    CIDADE: "Itapejara d'Oeste",
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.45872497558594,
    MAXIMO: 47.69575881958008,
    MEDIA: 47.028289384457615,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b6a0944-2937-4e8b-ae1c-b31b2c75545a',
    CIDADE: 'Itaperuçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.85530090332031,
    MAXIMO: 41.242618560791016,
    MEDIA: 41.0798861715193,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd602da36-6d36-4ba3-826e-75b1c1193675',
    CIDADE: 'Itaúna do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.90009307861328,
    MAXIMO: 46.26422882080078,
    MEDIA: 45.926262933250776,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6338c31-4d81-4c78-b89b-0340e2b749c3',
    CIDADE: 'Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.72089385986328,
    MAXIMO: 42.9716911315918,
    MEDIA: 42.8642463684082,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '144dbd31-e8da-49d8-a418-1b28a1151f58',
    CIDADE: 'Ivaiporã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.96876907348633,
    MAXIMO: 44.38481140136719,
    MEDIA: 44.17679023742676,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4992b623-bd94-4659-9fdb-2c9774ad8c72',
    CIDADE: 'Ivaté',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.998756408691406,
    MAXIMO: 48.11783218383789,
    MEDIA: 48.05829429626465,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0201623d-05aa-4969-ad99-8f4f0b3e2efb',
    CIDADE: 'Ivatuba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.76304244995117,
    MAXIMO: 45.30516052246094,
    MEDIA: 45.106786630944164,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9312a432-24c2-4b13-a7bb-77674adb56d4',
    CIDADE: 'Jaboti',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.694793701171875,
    MAXIMO: 40.88981628417969,
    MEDIA: 40.886432428782854,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d762c02-6d8c-400b-837b-af9c086c72b1',
    CIDADE: 'Jacarezinho',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.21922302246094,
    MAXIMO: 40.320953369140625,
    MEDIA: 40.27008819580078,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a5d8373-9123-487c-8497-eeb5f97fa5db',
    CIDADE: 'Jaguapitã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.009307861328125,
    MAXIMO: 43.16780471801758,
    MEDIA: 43.08855628967285,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3307c5d-421e-4c24-a638-8966a61bead6',
    CIDADE: 'Jaguariaíva',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.574363708496094,
    MAXIMO: 41.02602767944336,
    MEDIA: 40.77818489074707,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9bb0455-0d24-49a4-9aef-ff0f7b62dc7c',
    CIDADE: 'Jandaia do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.59502410888672,
    MAXIMO: 44.08412551879883,
    MEDIA: 43.90831970439419,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9b173681-d74f-4b1e-9b0f-24ed6ce6a7e3',
    CIDADE: 'Janiópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.97522735595703,
    MAXIMO: 47.683048248291016,
    MEDIA: 47.3749922486565,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd099167b-4f81-4fe0-be04-ba424c364a60',
    CIDADE: 'Japira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.739593505859375,
    MAXIMO: 41.129615783691406,
    MEDIA: 40.96074145082514,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dea9788-0ee1-49a6-a196-a454a16e025d',
    CIDADE: 'Japurá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.9244270324707,
    MAXIMO: 46.08755874633789,
    MEDIA: 45.93649478668652,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd05cec25-92aa-48bb-933e-7d33269c1f95',
    CIDADE: 'Jardim Alegre',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.330406188964844,
    MAXIMO: 44.712825775146484,
    MEDIA: 44.521615982055664,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '256f1867-4101-44cc-8f2f-4119ce38f193',
    CIDADE: 'Jardim Olinda',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.792442321777344,
    MAXIMO: 44.17597579956055,
    MEDIA: 43.81213638125809,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af85e5aa-bb5c-498f-beb4-03e452b4254d',
    CIDADE: 'Jataizinho',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.740875244140625,
    MAXIMO: 42.089786529541016,
    MEDIA: 41.92805305220687,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '57790399-de81-4d77-b77f-dd9c67b9a047',
    CIDADE: 'Jesuítas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.09585189819336,
    MAXIMO: 49.803279876708984,
    MEDIA: 49.35582751394547,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '87e5db8d-31ea-446c-9516-9d39c69b72fa',
    CIDADE: 'Joaquim Távora',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.25189208984375,
    MAXIMO: 40.616493225097656,
    MEDIA: 40.437024648383975,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '087bc5ad-e899-4b92-8a75-15a6cabb5366',
    CIDADE: 'Jundiaí do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.88407897949219,
    MAXIMO: 40.98917007446289,
    MEDIA: 40.93662452697754,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a441d93-0f95-4810-96cc-5478bc99c26f',
    CIDADE: 'Juranda',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.084938049316406,
    MAXIMO: 48.212890625,
    MEDIA: 47.55934037290582,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b1961453-1974-438d-b6ae-7cccb7ae8517',
    CIDADE: 'Jussara',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.627655029296875,
    MAXIMO: 46.235595703125,
    MEDIA: 45.93162536621094,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '375447dc-3f26-424a-b886-3394a1f49059',
    CIDADE: 'Kaloré',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.70599365234375,
    MAXIMO: 44.18842697143555,
    MEDIA: 44.09068013295435,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1904b318-d9b2-4ccd-8467-de809da99e19',
    CIDADE: 'Lapa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.712345123291016,
    MAXIMO: 42.126583099365234,
    MEDIA: 41.94250706263951,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f176bccd-b97e-44fa-a823-e1ff4b85e7ab',
    CIDADE: 'Laranjal',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.672950744628906,
    MAXIMO: 46.694305419921875,
    MEDIA: 46.68362808227539,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '845dbc0a-7515-4223-9769-40c3f01d7bfe',
    CIDADE: 'Laranjeiras do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.0504150390625,
    MAXIMO: 46.09760284423828,
    MEDIA: 46.07400894165039,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebe5dfae-3902-4141-8cce-81794e818000',
    CIDADE: 'Leópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.99807357788086,
    MAXIMO: 41.740875244140625,
    MEDIA: 41.32150600493212,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '580d34a9-0601-4940-a400-a183f0e509a1',
    CIDADE: 'Lidianópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.898292541503906,
    MAXIMO: 44.330406188964844,
    MEDIA: 44.07528729386869,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1595ae8d-8190-4b18-b8b4-1e52a32d8c19',
    CIDADE: 'Lindoeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.376556396484375,
    MAXIMO: 50.29612350463867,
    MEDIA: 49.98199293897711,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85ef4e36-c760-4516-85da-29cf225b1a57',
    CIDADE: 'Loanda',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.50627136230469,
    MAXIMO: 46.88483428955078,
    MEDIA: 46.713513692220054,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f2022364-6136-436f-abd8-70281e03e2d5',
    CIDADE: 'Lobato',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.77179718017578,
    MAXIMO: 44.337486267089844,
    MEDIA: 44.0842547259132,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '76048b0b-7916-4f1c-be7a-23d4edeb132e',
    CIDADE: 'Londrina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.33070755004883,
    MAXIMO: 42.992122650146484,
    MEDIA: 42.60633773803711,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '04848851-37e3-4833-b1a1-86c767723382',
    CIDADE: 'Luiziana',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.58987045288086,
    MAXIMO: 46.119197845458984,
    MEDIA: 45.851810455322266,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '799baa7a-0e47-439e-80c0-852d50cc4e35',
    CIDADE: 'Lunardelli',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.18842697143555,
    MAXIMO: 44.712825775146484,
    MEDIA: 44.37716452953575,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd163e10b-b2a5-4f93-8f26-1e705f562a1b',
    CIDADE: 'Lupionópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.66389465332031,
    MAXIMO: 43.19804000854492,
    MEDIA: 43.08764311615324,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd11ea86e-5d9a-49db-9243-9031bf66fc4d',
    CIDADE: 'Mallet',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.09551239013672,
    MAXIMO: 43.33286666870117,
    MEDIA: 43.22883605957031,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '916ad88c-b2cc-4d63-8599-819c478ba018',
    CIDADE: 'Mamborê',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.57184600830078,
    MAXIMO: 47.16124725341797,
    MEDIA: 46.78825759887695,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '95281ecb-ebe8-4bb7-8fc9-0f8ad0cf2408',
    CIDADE: 'Mandaguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.941795349121094,
    MAXIMO: 45.05573654174805,
    MEDIA: 44.56286984603701,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f8b746f-c2b6-43c3-bbf4-44179befb53e',
    CIDADE: 'Mandaguari',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.85935974121094,
    MAXIMO: 43.98076248168945,
    MEDIA: 43.920061111450195,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e5acc2f2-6e8a-4d62-abe9-9c0fa96e0452',
    CIDADE: 'Mandirituba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.34480667114258,
    MAXIMO: 41.88393020629883,
    MEDIA: 41.53658510671369,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6a1e4ea-14cb-49f9-b2fa-8f6c640a6c96',
    CIDADE: 'Manfrinópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.992523193359375,
    MAXIMO: 49.28596496582031,
    MEDIA: 48.64466287465547,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5d2cf65-b5df-49df-8675-eca8807fa19d',
    CIDADE: 'Mangueirinha',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.26827621459961,
    MAXIMO: 45.33232116699219,
    MEDIA: 45.3002986907959,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0089e89e-a930-4bbe-a34d-7f40c498d248',
    CIDADE: 'Manoel Ribas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.07536315917969,
    MAXIMO: 44.42190933227539,
    MEDIA: 44.24863624572754,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31a9229e-e1bb-4f37-9fe9-abacfb268a9e',
    CIDADE: 'Marechal Cândido Rondon',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.76240158081055,
    MAXIMO: 50.9947624206543,
    MEDIA: 50.87858200073242,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '58e320d4-1aa1-4ea6-b27b-b17659be8d80',
    CIDADE: 'Maria Helena',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.61098861694336,
    MAXIMO: 48.477901458740234,
    MEDIA: 47.94795563923488,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'af78eb77-37d4-44d1-bb9e-c2eab1767c32',
    CIDADE: 'Marialva',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.71881866455078,
    MAXIMO: 44.87843322753906,
    MEDIA: 44.277011575227505,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6ef55ee1-7024-4f96-8215-720055e178c9',
    CIDADE: 'Marilândia do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.65683364868164,
    MAXIMO: 43.45585632324219,
    MEDIA: 43.12227800652918,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78a45f56-96d9-489a-ae1a-563f8b0fddd9',
    CIDADE: 'Marilena',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.015872955322266,
    MAXIMO: 46.63050079345703,
    MEDIA: 46.23474374142364,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e021138-2354-4221-a316-df2bb35f0c00',
    CIDADE: 'Mariluz',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.798309326171875,
    MAXIMO: 49.09585189819336,
    MEDIA: 48.49504301008648,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c87f410-ba2e-4235-9c70-bd8f13e6a8a9',
    CIDADE: 'Maringá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.248111724853516,
    MAXIMO: 44.644405364990234,
    MEDIA: 44.446258544921875,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e27d058e-67d5-4b88-b4e5-fc09fc905130',
    CIDADE: 'Mariópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.82587814331055,
    MAXIMO: 46.2606201171875,
    MEDIA: 45.92795694296519,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02fe5221-75d0-452c-bb2b-29b338c042a9',
    CIDADE: 'Maripá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.12795639038086,
    MAXIMO: 50.5154914855957,
    MEDIA: 50.32087961064915,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b263e59f-c6ba-4c80-a043-c65fe0801199',
    CIDADE: 'Marmeleiro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.22261047363281,
    MAXIMO: 47.47007751464844,
    MEDIA: 47.346343994140625,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ae8f67f-5012-4092-b84f-21c399861447',
    CIDADE: 'Marquinho',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.114585876464844,
    MAXIMO: 46.1368293762207,
    MEDIA: 45.72876764532657,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a72b44dc-3334-41b2-b8e4-bd3afc552801',
    CIDADE: 'Marumbi',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.70599365234375,
    MAXIMO: 44.18842697143555,
    MEDIA: 43.91558626740172,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c25f626f-e3e5-483a-97f2-dcc619be4d84',
    CIDADE: 'Matelândia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.60617446899414,
    MAXIMO: 50.64228820800781,
    MEDIA: 50.62423133850098,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9acdcb08-5579-442e-a4ce-064edf6ebf25',
    CIDADE: 'Matinhos',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.34238052368164,
    MAXIMO: 40.904781341552734,
    MEDIA: 40.6673376878597,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25bb3786-c234-4cff-b9ec-d1862f953bcc',
    CIDADE: 'Mato Rico',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.68109893798828,
    MAXIMO: 46.17235565185547,
    MEDIA: 45.926727294921875,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae56ce8d-4db7-4ec6-9cf8-2ba735bc389b',
    CIDADE: 'Mauá da Serra',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.786773681640625,
    MAXIMO: 43.11065673828125,
    MEDIA: 42.993594982953226,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89114627-b0fe-43d5-9f9b-dd579b641eb1',
    CIDADE: 'Medianeira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.95417404174805,
    MAXIMO: 51.21712875366211,
    MEDIA: 51.08565139770508,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e98f91f6-726f-4aa9-9d17-d95e8fe12d2f',
    CIDADE: 'Mercedes',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.65849685668945,
    MAXIMO: 51.069061279296875,
    MEDIA: 50.910115991903,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c868fab-e3c9-488e-a87e-be16864adbf9',
    CIDADE: 'Mirador',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.961509704589844,
    MAXIMO: 46.5612678527832,
    MEDIA: 46.18319057670774,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8c9cfcc-9b85-43ce-8e7f-bb7cbc863343',
    CIDADE: 'Miraselva',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.638004302978516,
    MAXIMO: 43.16780471801758,
    MEDIA: 42.883463454270746,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78221736-913d-47c0-8848-2ec71a9f14c5',
    CIDADE: 'Missal',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.195472717285156,
    MAXIMO: 51.417015075683594,
    MEDIA: 51.306243896484375,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd7a7193-859d-43b6-9d34-e2af753afa68',
    CIDADE: 'Moreira Sales',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.32175827026367,
    MAXIMO: 48.441463470458984,
    MEDIA: 47.82171894832911,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a37659b-5abe-4275-80ad-79820c398a9c',
    CIDADE: 'Morretes',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.67308807373047,
    MAXIMO: 41.046695709228516,
    MEDIA: 40.85501607259115,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81b8c82f-04c2-46bd-905d-5dd2ccf9f062',
    CIDADE: 'Munhoz de Melo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.55072021484375,
    MAXIMO: 43.71881866455078,
    MEDIA: 43.59219180738818,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6f9b2f54-b56c-4cb9-b66f-c7cefafb1628',
    CIDADE: 'Nossa Senhora das Graças',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.19804000854492,
    MAXIMO: 43.941795349121094,
    MEDIA: 43.542890129790244,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '172829f3-412d-48c1-8ba4-a7b1a5a07906',
    CIDADE: 'Nova Aliança do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.5549201965332,
    MAXIMO: 46.15097427368164,
    MEDIA: 45.80584027233296,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aff72afa-15c1-4afd-8119-506eb2080ba8',
    CIDADE: 'Nova América da Colina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.32365417480469,
    MAXIMO: 41.77851104736328,
    MEDIA: 41.68806368324825,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '16352558-dec2-4785-af4d-d0efa8f0e742',
    CIDADE: 'Nova Aurora',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.735965728759766,
    MAXIMO: 49.329734802246094,
    MEDIA: 49.03285026550293,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2bfa5f75-cd6d-4a4d-bc39-b8ab89f4cfd1',
    CIDADE: 'Nova Cantu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.68375015258789,
    MAXIMO: 46.69486618041992,
    MEDIA: 46.689308166503906,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43e576bc-de28-43de-a7f0-61ee5ae53375',
    CIDADE: 'Nova Esperança',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.73973083496094,
    MAXIMO: 45.3203010559082,
    MEDIA: 44.98877716064453,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a5dac477-9ab6-4e36-8acd-60dd473d61da',
    CIDADE: 'Nova Esperança do Sudoeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.240596771240234,
    MAXIMO: 49.015750885009766,
    MEDIA: 48.60372427013789,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdb4eb61-7236-40bc-adbc-ed3b19c5687e',
    CIDADE: 'Nova Fátima',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.046749114990234,
    MAXIMO: 41.603736877441406,
    MEDIA: 41.36241184540467,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90cf6066-f054-4f48-82f4-4e84d4402563',
    CIDADE: 'Nova Laranjeiras',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.57577133178711,
    MAXIMO: 47.12046432495117,
    MEDIA: 46.77655029296875,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9cc8a439-485a-4724-a558-a806a5d318b8',
    CIDADE: 'Nova Londrina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.90009307861328,
    MAXIMO: 46.50627136230469,
    MEDIA: 46.14772532307895,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70e63a97-7250-42f1-a8dd-51c651adba05',
    CIDADE: 'Nova Olímpia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.1894645690918,
    MAXIMO: 47.83822250366211,
    MEDIA: 47.20948478136094,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa203edc-9cff-4980-8653-ed7f9653609f',
    CIDADE: 'Nova Santa Bárbara',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.603736877441406,
    MAXIMO: 42.21754837036133,
    MEDIA: 41.90723561975603,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3a5f7b5-b695-4265-bf42-8e3952cf19a6',
    CIDADE: 'Nova Santa Rosa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.40324401855469,
    MAXIMO: 50.870941162109375,
    MEDIA: 50.60831907074356,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88160f2f-f624-4ec6-bef6-d0adcb5757bd',
    CIDADE: 'Nova Prata do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.62744140625,
    MAXIMO: 49.85944366455078,
    MEDIA: 49.370475285714065,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e6dff953-eaf7-4cda-8a3d-de8d023402aa',
    CIDADE: 'Nova Tebas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.75448226928711,
    MAXIMO: 44.793983459472656,
    MEDIA: 44.77423286437988,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84899fc2-5203-4acd-92f9-f1bccce88a6c',
    CIDADE: 'Novo Itacolomi',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.33952713012695,
    MAXIMO: 43.81464767456055,
    MEDIA: 43.72529427174356,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f7d20a76-f58b-4600-80d5-f2a83bbe6c91',
    CIDADE: 'Ortigueira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.20161056518555,
    MAXIMO: 42.88191604614258,
    MEDIA: 42.55578088760376,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7993cdf6-7d06-44ae-ae85-195afa358065',
    CIDADE: 'Ourizona',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.05573654174805,
    MAXIMO: 45.627655029296875,
    MEDIA: 45.1168321592778,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81cee4f4-9263-43d8-b708-b47a03f917a7',
    CIDADE: 'Ouro Verde do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.56594467163086,
    MAXIMO: 50.912132263183594,
    MEDIA: 50.690369939062734,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '004acb23-e62b-48cc-a37a-57693ab307cd',
    CIDADE: 'Paiçandu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.644405364990234,
    MAXIMO: 45.18275451660156,
    MEDIA: 44.8400525048686,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2dc8f06-cde4-47ce-b114-16ba75c0d03b',
    CIDADE: 'Palmas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.11545181274414,
    MAXIMO: 44.798744201660156,
    MEDIA: 44.45786221822103,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41f1115f-64fe-496e-9ae7-38ae80e3838b',
    CIDADE: 'Palmeira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.51219177246094,
    MAXIMO: 42.314876556396484,
    MEDIA: 41.94157600402832,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c38bb785-d1ea-48fe-a169-44369a80031c',
    CIDADE: 'Palmital',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.20242691040039,
    MAXIMO: 46.16401672363281,
    MEDIA: 45.790937423706055,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '786a08d7-31c5-47da-a26b-0d0e9b12a221',
    CIDADE: 'Palotina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.12795639038086,
    MAXIMO: 50.2324104309082,
    MEDIA: 50.18018341064453,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1203fd38-d952-4cb3-9ec7-bb4a245f91f1',
    CIDADE: 'Paraíso do Norte',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.73154830932617,
    MAXIMO: 46.336055755615234,
    MEDIA: 45.972917575181505,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff6c182f-78d4-49e2-a0b3-075e52a5ceb4',
    CIDADE: 'Paranacity',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.982032775878906,
    MAXIMO: 44.572357177734375,
    MEDIA: 44.307936508315784,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe3a5307-bd0f-4277-b050-d5d5f7c47178',
    CIDADE: 'Paranaguá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.19448471069336,
    MAXIMO: 40.678184509277344,
    MEDIA: 40.4298095703125,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '017f7f07-00c2-46e1-a92a-2abcf90a1079',
    CIDADE: 'Paranapoema',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.792442321777344,
    MAXIMO: 44.37411117553711,
    MEDIA: 43.977414015713215,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '634ca077-0561-4b2a-8694-11e48a2588f4',
    CIDADE: 'Paranavaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.14830017089844,
    MAXIMO: 45.961509704589844,
    MEDIA: 45.50524616241455,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '78890973-87e2-4a79-978c-dcb69b2835d6',
    CIDADE: 'Pato Bragado',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.9947624206543,
    MAXIMO: 51.31973648071289,
    MEDIA: 51.150478405030476,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6538f735-c738-48c8-89af-370460218fc3',
    CIDADE: 'Pato Branco',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.45872497558594,
    MAXIMO: 46.64107131958008,
    MEDIA: 46.54989814758301,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56d3ef9b-c5f9-44e8-95d8-1dd2e9959abc',
    CIDADE: 'Paula Freitas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.13581466674805,
    MAXIMO: 43.37543487548828,
    MEDIA: 43.255624771118164,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a04a0a77-8e71-4914-91c7-8d99b4853e79',
    CIDADE: 'Paulo Frontin',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.820247650146484,
    MAXIMO: 43.37543487548828,
    MEDIA: 43.15401212311245,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1dff9a6-6ca7-4b7d-9210-0d41032a7f10',
    CIDADE: 'Peabiru',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.989253997802734,
    MAXIMO: 46.34978103637695,
    MEDIA: 45.74003719535197,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9731824a-b89b-4244-a48a-1607d2fc324a',
    CIDADE: 'Perobal',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.26103973388672,
    MAXIMO: 49.34590530395508,
    MEDIA: 48.777792732543745,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cafea2b6-903c-4f01-a7a8-706526d219ce',
    CIDADE: 'Pérola',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.21213912963867,
    MAXIMO: 49.77174758911133,
    MEDIA: 49.37766217262729,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fb390e3d-bd16-41a6-8718-3879545031e2',
    CIDADE: "Pérola d'Oeste",
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.54410171508789,
    MAXIMO: 50.51631546020508,
    MEDIA: 50.162465763431946,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f8e83f2-a674-4ac3-ab9c-af8dbf1f2734',
    CIDADE: 'Piên',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.757049560546875,
    MAXIMO: 42.05923080444336,
    MEDIA: 41.968054247608016,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69afa67b-4088-49c0-822d-1b9243e80178',
    CIDADE: 'Pinhais',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.844486236572266,
    MAXIMO: 41.195255279541016,
    MEDIA: 40.860645057944,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0fd148d2-6905-4ed1-8844-e51731dac15e',
    CIDADE: 'Pinhalão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.729068756103516,
    MAXIMO: 41.11149215698242,
    MEDIA: 40.91259240233793,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '114723c6-959e-4d9f-b000-24913fb28505',
    CIDADE: 'Pinhal de São Bento',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.77627944946289,
    MAXIMO: 49.28596496582031,
    MEDIA: 49.16234205284562,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1899008d-b83f-4597-86ed-da2e17ac2502',
    CIDADE: 'Pinhão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.90608596801758,
    MAXIMO: 44.69590377807617,
    MEDIA: 44.22775377546038,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4095cb9e-6429-4fae-b032-122fdc595a0f',
    CIDADE: 'Piraí do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.94245147705078,
    MAXIMO: 41.44304275512695,
    MEDIA: 41.2058822631836,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33682ec3-1e6c-4324-acfd-af8060da27dc',
    CIDADE: 'Piraquara',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.73273468017578,
    MAXIMO: 41.00740432739258,
    MEDIA: 40.91009431209441,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b466359e-71e6-4d0b-b4c7-27f7e75820ff',
    CIDADE: 'Pitanga',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.077205657958984,
    MAXIMO: 45.221134185791016,
    MEDIA: 44.59724578857422,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aecb3efc-d670-4267-93cf-0ae8d6df78f2',
    CIDADE: 'Pitangueiras',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.16780471801758,
    MAXIMO: 43.33682632446289,
    MEDIA: 43.315052075829264,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6e8bfae-541f-4c97-b84e-355417f7031c',
    CIDADE: 'Planaltina do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.12869644165039,
    MAXIMO: 46.96767807006836,
    MEDIA: 46.54623948021134,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '709c38ad-21a7-462d-b4a4-014d21c1f70c',
    CIDADE: 'Planalto',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.54410171508789,
    MAXIMO: 50.51631546020508,
    MEDIA: 50.20863650480694,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88e98412-686b-4519-9fd6-50c162b5ef37',
    CIDADE: 'Ponta Grossa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.69731140136719,
    MAXIMO: 42.235660552978516,
    MEDIA: 41.904180908203124,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ecd57034-8f9a-4678-bb28-8e3c1e388e14',
    CIDADE: 'Pontal do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.19448471069336,
    MAXIMO: 40.678184509277344,
    MEDIA: 40.46114677847786,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb1d95f8-d8eb-4ae1-ad38-90104f9c5ce8',
    CIDADE: 'Porecatu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.127037048339844,
    MAXIMO: 42.84934997558594,
    MEDIA: 42.49266932341954,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1539ccdf-f7f5-4674-a87d-bf13aacd7897',
    CIDADE: 'Porto Amazonas',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.664920806884766,
    MAXIMO: 41.955142974853516,
    MEDIA: 41.79390644759838,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40ff13d8-bbb5-4f59-92fe-b50dec4461bb',
    CIDADE: 'Porto Barreiro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.4264030456543,
    MAXIMO: 46.49442672729492,
    MEDIA: 45.964231371530374,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'def99f99-26af-4456-8aa7-2c727d6562f0',
    CIDADE: 'Porto Rico',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.63050079345703,
    MAXIMO: 47.237186431884766,
    MEDIA: 47.01954022227904,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd405caee-cc4a-4cbb-b549-a1ec5335cb32',
    CIDADE: 'Porto Vitória',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.53649139404297,
    MAXIMO: 43.74604034423828,
    MEDIA: 43.71380045286267,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd48b462a-4730-4ebe-b99f-a221e77c0c6a',
    CIDADE: 'Prado Ferreira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.28675079345703,
    MAXIMO: 43.16780471801758,
    MEDIA: 42.754872489640945,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7f5b0e4d-cd0b-435e-934b-e9e37d2ba010',
    CIDADE: 'Pranchita',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.28596496582031,
    MAXIMO: 50.35645294189453,
    MEDIA: 49.869616006481856,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '80e615d7-a1e7-47a9-a985-d9015691b83e',
    CIDADE: 'Presidente Castelo Branco',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.50222396850586,
    MAXIMO: 45.05573654174805,
    MEDIA: 44.74558223698932,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e64ead7-4d5b-4288-8c56-33e945cb4c0e',
    CIDADE: 'Primeiro de Maio',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.495079040527344,
    MAXIMO: 42.28675079345703,
    MEDIA: 41.89501784581953,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ade43ac3-11f8-411b-b9b9-dc67c63f8a4b',
    CIDADE: 'Prudentópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.18086242675781,
    MAXIMO: 43.52870559692383,
    MEDIA: 43.35929979596819,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'beeb191f-cba6-4447-b510-bc22715388d6',
    CIDADE: 'Quarto Centenário',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.10223388671875,
    MAXIMO: 49.24169921875,
    MEDIA: 48.514659255641455,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '83b9e97c-4e19-47fe-85a4-c6fde4d4eb93',
    CIDADE: 'Quatiguá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.362892150878906,
    MAXIMO: 40.739593505859375,
    MEDIA: 40.52998050835171,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbc27601-cd32-4d3d-870d-153abe04c7a1',
    CIDADE: 'Quatro Barras',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.73273468017578,
    MAXIMO: 40.844486236572266,
    MEDIA: 40.75286868281106,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '922316c9-4da1-498c-a877-b3fe2cb2146c',
    CIDADE: 'Quatro Pontes',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.5154914855957,
    MAXIMO: 50.8389778137207,
    MEDIA: 50.766003400685456,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c199e363-db21-4ad9-89cb-3464ce689963',
    CIDADE: 'Quedas do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.601051330566406,
    MAXIMO: 48.263275146484375,
    MEDIA: 47.93169021606445,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e7cb7c5-c017-4263-aad7-493d38b408cb',
    CIDADE: 'Querência do Norte',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.237186431884766,
    MAXIMO: 48.43334197998047,
    MEDIA: 47.77510070800781,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d75e758-2a61-448b-a7fa-a48584fb7e27',
    CIDADE: 'Quinta do Sol',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.87843322753906,
    MAXIMO: 45.42425537109375,
    MEDIA: 45.17086448869644,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '99df142f-b463-41f4-a3cc-9da8f4db729f',
    CIDADE: 'Quitandinha',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.66048812866211,
    MAXIMO: 41.88393020629883,
    MEDIA: 41.78780619303385,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5369b89e-89af-45ae-9124-71d65e7bbc08',
    CIDADE: 'Ramilândia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.9384880065918,
    MAXIMO: 51.21712875366211,
    MEDIA: 51.03416209100932,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d3f305b-5885-4b82-8f5d-8710218c5e44',
    CIDADE: 'Rancho Alegre',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.59192657470703,
    MAXIMO: 42.06832504272461,
    MEDIA: 41.73707890866478,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d4d56e3-c511-4f10-ac0b-24255b36f0b8',
    CIDADE: "Rancho Alegre D'Oeste",
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.47502136230469,
    MAXIMO: 48.212890625,
    MEDIA: 47.90709548775844,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1530732f-9653-4ac9-aa53-da2350ecd684',
    CIDADE: 'Realeza',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.54410171508789,
    MAXIMO: 50.28106689453125,
    MEDIA: 49.786216993867846,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6699363-54bd-4170-806a-881c86565f88',
    CIDADE: 'Rebouças',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.74884796142578,
    MAXIMO: 43.1380729675293,
    MEDIA: 42.97295142108075,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89a25d56-c62e-4637-b12d-8e3df65e056c',
    CIDADE: 'Renascença',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.73000717163086,
    MAXIMO: 47.69575881958008,
    MEDIA: 47.13927582244817,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a80c9f3-661c-438e-b1b2-d1e10a9b5ec7',
    CIDADE: 'Reserva',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.45048522949219,
    MAXIMO: 43.04286575317383,
    MEDIA: 42.809410095214844,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ce72027-d5af-4ba4-962e-d71a6777e836',
    CIDADE: 'Reserva do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.65290832519531,
    MAXIMO: 44.93434143066406,
    MEDIA: 44.79362487792969,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72ff46e3-1dae-43aa-8dac-3d12b5990ea3',
    CIDADE: 'Ribeirão Claro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 39.9988899230957,
    MAXIMO: 40.42021179199219,
    MEDIA: 40.15048714768142,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82915290-adb3-4a45-af58-36b20d76223b',
    CIDADE: 'Ribeirão do Pinhal',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.88407897949219,
    MAXIMO: 41.32365417480469,
    MEDIA: 41.09494484743603,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '223827df-23b1-44e2-a37e-d80e3237c356',
    CIDADE: 'Rio Azul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.1380729675293,
    MAXIMO: 43.32630157470703,
    MEDIA: 43.232187271118164,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79958ff9-f8fd-4d61-bd2e-3c7eb596acd1',
    CIDADE: 'Rio Bom',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.33952713012695,
    MAXIMO: 43.81464767456055,
    MEDIA: 43.41100665148152,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc23b683-30d5-4872-a31e-b78da9ad9f5a',
    CIDADE: 'Rio Bonito do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.395809173583984,
    MAXIMO: 47.039207458496094,
    MEDIA: 46.643147786458336,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72a6ceb3-d209-4803-bba6-066e4710c06b',
    CIDADE: 'Rio Branco do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.28508377075195,
    MAXIMO: 44.0323486328125,
    MEDIA: 43.52551084192551,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2beb763-fe8f-4fa8-af09-414e2ce62ba9',
    CIDADE: 'Rio Branco do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.85530090332031,
    MAXIMO: 40.9608039855957,
    MEDIA: 40.90805244445801,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '43bb3267-85a7-4eb0-8705-ed0ef868b52c',
    CIDADE: 'Rio Negro',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.88393020629883,
    MAXIMO: 42.1888427734375,
    MEDIA: 42.11375403217466,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff6928a7-7e70-4529-a248-271d1bc3af93',
    CIDADE: 'Rolândia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.57054138183594,
    MAXIMO: 43.48469161987305,
    MEDIA: 43.02799136339356,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9478960-958f-4521-a9bd-7021247e82b3',
    CIDADE: 'Roncador',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.20682907104492,
    MAXIMO: 46.16149139404297,
    MEDIA: 45.67831802368164,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9f8529a-854b-4755-8045-bf0a8eb75068',
    CIDADE: 'Rondon',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.336055755615234,
    MAXIMO: 46.96723175048828,
    MEDIA: 46.65164375305176,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb6e6fb0-b55e-4d51-8a1f-2d33959ee1c0',
    CIDADE: 'Rosário do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.28508377075195,
    MAXIMO: 43.298255920410156,
    MEDIA: 43.291669845581055,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee51f948-2220-4b0c-813f-2970d44d2814',
    CIDADE: 'Sabáudia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.33682632446289,
    MAXIMO: 43.85935974121094,
    MEDIA: 43.46962178025361,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ea94f673-6c13-484e-b106-18a524d24f74',
    CIDADE: 'Salgado Filho',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.513118743896484,
    MAXIMO: 49.28596496582031,
    MEDIA: 48.74402613557581,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '182e0c7f-5627-4dcf-824b-ec5082635823',
    CIDADE: 'Salto do Itararé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.18932342529297,
    MAXIMO: 40.46609115600586,
    MEDIA: 40.29993917898342,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'feaa6ffb-592e-47b0-a5d1-da8dad9fc4d7',
    CIDADE: 'Salto do Lontra',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.453914642333984,
    MAXIMO: 49.211978912353516,
    MEDIA: 48.88467322968153,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd4b93be6-e630-4d15-9083-57c5bab771f4',
    CIDADE: 'Santa Amélia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.784950256347656,
    MAXIMO: 41.046749114990234,
    MEDIA: 41.02812919050506,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e3f9f40-e5ea-4d27-ab00-9361244e339c',
    CIDADE: 'Santa Cecília do Pavão',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.77851104736328,
    MAXIMO: 42.21754837036133,
    MEDIA: 41.98121420958208,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8805325f-e752-4022-8e5b-31094f251a61',
    CIDADE: 'Santa Cruz de Monte Castelo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.88483428955078,
    MAXIMO: 48.11783218383789,
    MEDIA: 47.5281643172537,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2cc25fc2-7062-4696-bc81-ff40916d8c44',
    CIDADE: 'Santa Fé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.55072021484375,
    MAXIMO: 44.104740142822266,
    MEDIA: 43.81114952835892,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a8fab92-e3c3-4247-a9a7-70e46bdf4dc6',
    CIDADE: 'Santa Helena',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.31973648071289,
    MAXIMO: 51.38200378417969,
    MEDIA: 51.35087013244629,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c6f5b31-c7f5-456d-b945-af8192b6b4e0',
    CIDADE: 'Santa Inês',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.41144943237305,
    MAXIMO: 43.58592224121094,
    MEDIA: 43.52412591890379,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd9a57413-be3b-43e7-a16d-4272d5144e27',
    CIDADE: 'Santa Isabel do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.88483428955078,
    MAXIMO: 47.76309585571289,
    MEDIA: 47.29180115066824,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe79500e-6427-4a8e-895f-e292ac792702',
    CIDADE: 'Santa Izabel do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.015750885009766,
    MAXIMO: 49.77194595336914,
    MEDIA: 49.297713652394016,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f6b60fb-edc9-455f-b387-79c7bee1e3be',
    CIDADE: 'Santa Lúcia',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.873531341552734,
    MAXIMO: 50.29612350463867,
    MEDIA: 49.907798564570406,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3dc2ba7e-0a45-482c-80ff-8e302e857748',
    CIDADE: 'Santa Maria do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.427677154541016,
    MAXIMO: 44.8116455078125,
    MEDIA: 44.61966133117676,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22f3308b-1a09-48bc-85b5-93384e3fb7aa',
    CIDADE: 'Santa Mariana',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.99807357788086,
    MAXIMO: 41.167118072509766,
    MEDIA: 41.08259582519531,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4629f711-baff-4c46-b434-88cdf08ac9d6',
    CIDADE: 'Santa Mônica',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.74943542480469,
    MAXIMO: 47.37480163574219,
    MEDIA: 47.03571613263582,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3a7676e-e79b-4f3e-bcae-2ec2cdc57527',
    CIDADE: 'Santana do Itararé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.18932342529297,
    MAXIMO: 40.46609115600586,
    MEDIA: 40.313607387324375,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dd363bb9-e92f-49dd-ad12-1731801683a7',
    CIDADE: 'Santa Tereza do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.8525505065918,
    MAXIMO: 50.28911590576172,
    MEDIA: 50.07148492270352,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e3fbe9a-f078-4b5d-aa36-76a366bdb07c',
    CIDADE: 'Santa Terezinha de Itaipu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.33201599121094,
    MAXIMO: 51.62556076049805,
    MEDIA: 51.47878837585449,
    OFICIAL: 52,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e57b5f2b-bccf-491b-8354-c47d77a2e871',
    CIDADE: 'Santo Antônio da Platina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.42021179199219,
    MAXIMO: 40.53485870361328,
    MEDIA: 40.477535247802734,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70383736-85bd-420b-a903-6c3cfd616593',
    CIDADE: 'Santo Antônio do Caiuá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.17597579956055,
    MAXIMO: 44.76496124267578,
    MEDIA: 44.668148544857374,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '70d59b80-7dd5-4778-9337-54fd3391b5ab',
    CIDADE: 'Santo Antônio do Paraíso',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.476478576660156,
    MAXIMO: 41.90386199951172,
    MEDIA: 41.63836249664245,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c48fc5c0-ce6d-4910-9a36-1b6eb05e0f6b',
    CIDADE: 'Santo Antônio do Sudoeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.012962341308594,
    MAXIMO: 49.75238037109375,
    MEDIA: 49.528493351195024,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6c3a73a-9eb9-457e-a41a-b83dc3eb4291',
    CIDADE: 'Santo Inácio',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.03560256958008,
    MAXIMO: 43.58592224121094,
    MEDIA: 43.326655218204145,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fac8b1ef-9cc4-4352-bca6-951f9651084c',
    CIDADE: 'São Carlos do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.3203010559082,
    MAXIMO: 45.9244270324707,
    MEDIA: 45.67263962282221,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '34669eeb-76ae-4c41-95d6-6e14b07cafc9',
    CIDADE: 'São Jerônimo da Serra',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.65071487426758,
    MAXIMO: 42.21754837036133,
    MEDIA: 41.962416330973305,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c915058e-635d-4700-83b0-5315bd7c953f',
    CIDADE: 'São João',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.79814147949219,
    MAXIMO: 47.601051330566406,
    MEDIA: 47.17201555864114,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '620f1fee-6b45-47bb-b4c3-1cb4cf9c8806',
    CIDADE: 'São João do Caiuá',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.37411117553711,
    MAXIMO: 44.9648323059082,
    MEDIA: 44.73968688674985,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '476c9b61-e33d-448a-9ed0-5cebd261dbf7',
    CIDADE: 'São João do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.18842697143555,
    MAXIMO: 44.65735626220703,
    MEDIA: 44.509813436626054,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4b2f05a-43e1-40ac-be1c-f1c1c37b5fd3',
    CIDADE: 'São João do Triunfo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.32453918457031,
    MAXIMO: 42.74884796142578,
    MEDIA: 42.53669357299805,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd62de800-8caf-4e3d-b4e4-c167f8d783b0',
    CIDADE: "São Jorge d'Oeste",
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.33478546142578,
    MAXIMO: 48.7587776184082,
    MEDIA: 47.74728190752452,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbc559af-a963-4fc9-b221-b042e5a914c0',
    CIDADE: 'São Jorge do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.90629959106445,
    MAXIMO: 45.627655029296875,
    MEDIA: 45.35339605605796,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '801b6ba2-267e-4a21-bb39-dc9cae149f7d',
    CIDADE: 'São Jorge do Patrocínio',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.21213912963867,
    MAXIMO: 50.0450439453125,
    MEDIA: 49.69084151099776,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7c0eedf9-2cf5-44d2-8868-94a5bf7b59fc',
    CIDADE: 'São José da Boa Vista',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.40099334716797,
    MAXIMO: 40.64048385620117,
    MEDIA: 40.484404312016444,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63b7a876-d99f-4a32-b229-629e973ad75a',
    CIDADE: 'São José das Palmeiras',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.8389778137207,
    MAXIMO: 51.1650505065918,
    MEDIA: 51.06652572214437,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb384e4d-bfef-4c04-9306-ca9a99a7543f',
    CIDADE: 'São José dos Pinhais',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.00740432739258,
    MAXIMO: 41.186607360839844,
    MEDIA: 41.09700584411621,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9b8970ba-558b-41f4-86be-e4edc35e7c4c',
    CIDADE: 'São Manoel do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.73154830932617,
    MAXIMO: 46.336055755615234,
    MEDIA: 46.08499393224179,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fe0261aa-6e8a-4fc5-b462-46bd0a7718b4',
    CIDADE: 'São Mateus do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.28847122192383,
    MAXIMO: 42.90629577636719,
    MEDIA: 42.54529647827148,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b85f2762-5b88-496d-9bcd-432c54d6fa53',
    CIDADE: 'São Miguel do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 51.15785217285156,
    MAXIMO: 51.43052673339844,
    MEDIA: 51.26531982421875,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fd198dfd-0e39-493f-aed9-e9c69ff3d893',
    CIDADE: 'São Pedro do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.25864028930664,
    MAXIMO: 50.912132263183594,
    MEDIA: 50.67631588586815,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1715dec-db8f-4251-9608-e94f86ee406b',
    CIDADE: 'São Pedro do Ivaí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.08412551879883,
    MAXIMO: 44.989253997802734,
    MEDIA: 44.490714509126875,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc03080d-f737-47ea-a23c-8d1e43af566e',
    CIDADE: 'São Pedro do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.26422882080078,
    MAXIMO: 47.237186431884766,
    MEDIA: 46.666176724975145,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '10e86ac7-0ba0-4f54-b99c-ce442d66354f',
    CIDADE: 'São Sebastião da Amoreira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.476478576660156,
    MAXIMO: 41.90386199951172,
    MEDIA: 41.77431689928074,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63cce15e-87bb-4479-b84d-830eff3fcc8a',
    CIDADE: 'São Tomé',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.48045349121094,
    MAXIMO: 46.54178237915039,
    MEDIA: 46.025689252846405,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '944a12d0-898c-4a4f-bc43-cba3d0a381d7',
    CIDADE: 'Sapopema',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.81237030029297,
    MAXIMO: 42.10759353637695,
    MEDIA: 41.95998191833496,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4abdb358-ae07-44fb-8eb6-69ae4b8faba2',
    CIDADE: 'Sarandi',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.85935974121094,
    MAXIMO: 44.36788558959961,
    MEDIA: 44.27828113665828,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f44f94d-aaed-4c90-af39-1a3e5722d2c7',
    CIDADE: 'Saudade do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.28242874145508,
    MAXIMO: 46.93116760253906,
    MEDIA: 46.52588029204914,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c533755-5cec-47ea-8d51-268e037bf01b',
    CIDADE: 'Sengés',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.310401916503906,
    MAXIMO: 40.475921630859375,
    MEDIA: 40.400143432617185,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '229e9074-1813-4f44-a7d1-decb076b886c',
    CIDADE: 'Serranópolis do Iguaçu',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.905029296875,
    MAXIMO: 50.946353912353516,
    MEDIA: 50.92569160461426,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6b88788-ad68-4aac-b4f3-b13b32e0fe56',
    CIDADE: 'Sertaneja',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.298316955566406,
    MAXIMO: 41.959102630615234,
    MEDIA: 41.548248646222326,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d34c25d-a6ec-45b0-a84b-749f5b32cb84',
    CIDADE: 'Sertanópolis',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.59192657470703,
    MAXIMO: 42.57054138183594,
    MEDIA: 42.03817522528556,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2d734e43-cdbe-4d41-93ad-30c7626acd44',
    CIDADE: 'Siqueira Campos',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.362892150878906,
    MAXIMO: 40.46609115600586,
    MEDIA: 40.41449165344238,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c2a1dcfb-32ee-4c77-a66e-ae159026afa0',
    CIDADE: 'Sulina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.395809173583984,
    MAXIMO: 47.039207458496094,
    MEDIA: 46.930836021456564,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fcd10f5a-ca6b-47aa-9e34-e94e215018e3',
    CIDADE: 'Tamarana',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.65683364868164,
    MAXIMO: 42.786773681640625,
    MEDIA: 42.72180366516113,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa913d51-e256-4240-bdd9-c9f6238e1a90',
    CIDADE: 'Tamboara',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.14588165283203,
    MAXIMO: 45.73154830932617,
    MEDIA: 45.558283415152,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8abd5d4d-9b62-4058-96ec-1da8a756b761',
    CIDADE: 'Tapejara',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.54178237915039,
    MAXIMO: 47.40142822265625,
    MEDIA: 47.036322275797524,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '333c0acb-e206-4c99-99d1-6c3b68c34625',
    CIDADE: 'Tapira',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.96767807006836,
    MAXIMO: 47.61098861694336,
    MEDIA: 47.317822774251304,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9cb7773-abc4-4221-aafa-1e144a645113',
    CIDADE: 'Teixeira Soares',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 42.457786560058594,
    MAXIMO: 42.523475646972656,
    MEDIA: 42.490631103515625,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a16f23ed-af7a-4604-a348-d6631b7844d7',
    CIDADE: 'Telêmaco Borba',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.732994079589844,
    MAXIMO: 42.09101486206055,
    MEDIA: 41.89739990234375,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ce3556ce-5a81-469c-b87b-f8593da95679',
    CIDADE: 'Terra Boa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.18275451660156,
    MAXIMO: 46.34978103637695,
    MEDIA: 45.77490450067679,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '191356b2-4364-49c3-b2ae-b20889356e70',
    CIDADE: 'Terra Rica',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.92877960205078,
    MAXIMO: 45.90009307861328,
    MEDIA: 45.40791078132706,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebe59095-78c4-49b6-94db-4f2b993fefb0',
    CIDADE: 'Terra Roxa',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.2775764465332,
    MAXIMO: 50.65849685668945,
    MEDIA: 50.47383244832357,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19cef655-38dc-4b7c-8c83-d1636ae8735a',
    CIDADE: 'Tibagi',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.688201904296875,
    MAXIMO: 42.51786422729492,
    MEDIA: 42.061880588531494,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e08316a-a7a8-49bf-92c2-fec5919d85f6',
    CIDADE: 'Tijucas do Sul',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.186607360839844,
    MAXIMO: 41.757049560546875,
    MEDIA: 41.4344454993175,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b67de0d-afff-4a30-9dd4-d4768d96e51c',
    CIDADE: 'Toledo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.242794036865234,
    MAXIMO: 50.8389778137207,
    MEDIA: 50.48436965942383,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36dbcf6d-e1e3-45f5-98c8-24bc65ade3eb',
    CIDADE: 'Tomazina',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.46609115600586,
    MAXIMO: 40.95046615600586,
    MEDIA: 40.698291132942515,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '336df470-f50d-4c91-a39d-5a73a86dce4d',
    CIDADE: 'Três Barras do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.17619705200195,
    MAXIMO: 49.376556396484375,
    MEDIA: 48.91275619171548,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a694b2ee-3462-40fd-a480-7a29535496e5',
    CIDADE: 'Tunas do Paraná',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.16474914550781,
    MAXIMO: 40.435420989990234,
    MEDIA: 40.30008506774902,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '750bb4a9-1f02-4585-a8db-280c594c1b46',
    CIDADE: 'Tuneiras do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.70074462890625,
    MAXIMO: 47.798309326171875,
    MEDIA: 47.212392049705244,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df2bb5fb-fea1-4c18-ae4c-54f6b48bbbe1',
    CIDADE: 'Tupãssi',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 49.803279876708984,
    MAXIMO: 49.83604049682617,
    MEDIA: 49.81966018676758,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '68bdebb3-d90f-41fa-b67c-e68bddca48ac',
    CIDADE: 'Turvo',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.78757095336914,
    MAXIMO: 44.115577697753906,
    MEDIA: 43.902940114339195,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8865f86-a2d9-4b01-9e87-49b7e942fb16',
    CIDADE: 'Ubiratã',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.212890625,
    MAXIMO: 48.29738235473633,
    MEDIA: 48.255136489868164,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '502ebf89-542b-4fe3-af4d-505c0f299ad1',
    CIDADE: 'Umuarama',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.05571365356445,
    MAXIMO: 48.62364959716797,
    MEDIA: 48.35031032562256,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '438dfc31-8c24-47a0-947c-29fdbdad7067',
    CIDADE: 'União da Vitória',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 43.47909164428711,
    MAXIMO: 43.53649139404297,
    MEDIA: 43.50779151916504,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '230503ed-d2f3-459c-84a4-341648d29f0e',
    CIDADE: 'Uniflor',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 44.337486267089844,
    MAXIMO: 44.73973083496094,
    MEDIA: 44.469987551788385,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f286b7e0-26f8-4d8e-8603-11f60fbd933a',
    CIDADE: 'Uraí',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.44010925292969,
    MAXIMO: 41.93062973022461,
    MEDIA: 41.756278937334656,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a623ce2-bc79-4cd7-89cf-bbf0836066b7',
    CIDADE: 'Wenceslau Braz',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.28116989135742,
    MAXIMO: 40.729068756103516,
    MEDIA: 40.53562502280283,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28ef58a1-d5bf-4c61-ae81-c548dace351e',
    CIDADE: 'Ventania',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 41.36928176879883,
    MAXIMO: 41.5755729675293,
    MEDIA: 41.47077941894531,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bb22e50c-dba9-4b1a-9c0a-081465365c43',
    CIDADE: 'Vera Cruz do Oeste',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 50.60253143310547,
    MAXIMO: 50.9384880065918,
    MEDIA: 50.770020019129944,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e315a742-7853-4352-8df9-993aa0a2284b',
    CIDADE: 'Verê',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 47.157676696777344,
    MAXIMO: 48.05266189575195,
    MEDIA: 47.61853932093853,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '902d5e69-1da1-47b2-9e4f-dcbb94d1d6b6',
    CIDADE: 'Alto Paraíso',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.96295928955078,
    MAXIMO: 49.55393600463867,
    MEDIA: 49.20162010192871,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '050b3335-bc67-4474-9715-ba8505d29738',
    CIDADE: 'Doutor Ulysses',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 40.47648620605469,
    MAXIMO: 40.61267852783203,
    MEDIA: 40.53592427571615,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ac6a398-5e02-4eb2-a6e7-dc99c04fdc5b',
    CIDADE: 'Virmond',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 45.508384704589844,
    MAXIMO: 46.0504150390625,
    MEDIA: 45.54787356520525,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18a90c95-a5ed-4d66-a0b2-4cba061c1a54',
    CIDADE: 'Vitorino',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 46.2606201171875,
    MAXIMO: 46.73000717163086,
    MEDIA: 46.49531364440918,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5074156d-cbf4-42d1-9acd-d5f734dfb3d0',
    CIDADE: 'Xambrê',
    SIGLA: 'PR',
    ESTADO: 'Paraná',
    MINIMO: 48.87057113647461,
    MAXIMO: 49.21213912963867,
    MEDIA: 49.04135513305664,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '290faee7-3cc7-4612-9454-47bf743b71ce',
    CIDADE: 'Abdon Batista',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.967376708984375,
    MAXIMO: 44.14594650268555,
    MEDIA: 44.08041720600493,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4c2687ee-4fa0-47cc-a8d4-b2fbb2a2689c',
    CIDADE: 'Abelardo Luz',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.84672546386719,
    MAXIMO: 45.2701301574707,
    MEDIA: 45.04459476470947,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fda606f3-4f22-422b-8f97-f5cbf98c5f72',
    CIDADE: 'Agrolândia',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.860130310058594,
    MAXIMO: 44.16682434082031,
    MEDIA: 43.96330353679634,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8b2d3629-b375-4723-a029-bfa0a4fdf3c4',
    CIDADE: 'Agronômica',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.805110931396484,
    MAXIMO: 43.860130310058594,
    MEDIA: 43.852751767643035,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7109fb7b-b3a2-424b-a591-d696bf4c9574',
    CIDADE: 'Água Doce',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.100460052490234,
    MAXIMO: 44.269771575927734,
    MEDIA: 44.15307903289795,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a8792210-6927-427d-9341-a88b53725610',
    CIDADE: 'Águas de Chapecó',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.5430793762207,
    MAXIMO: 46.150638580322266,
    MEDIA: 45.87263747491605,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f6f11360-02a8-4a4d-b8b6-b937e74271ac',
    CIDADE: 'Águas Frias',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.594886779785156,
    MAXIMO: 45.99928665161133,
    MEDIA: 45.99804907645383,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86b7ce53-a2a9-4b89-b013-a8787c18b611',
    CIDADE: 'Águas Mornas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.81843566894531,
    MAXIMO: 44.0244255065918,
    MEDIA: 43.921430587768555,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0631bce6-ab7d-42b5-830a-beff885bae02',
    CIDADE: 'Alfredo Wagner',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.16122817993164,
    MAXIMO: 44.298805236816406,
    MEDIA: 44.22840372721354,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3ef53291-1273-49cd-bd56-9ad066939f65',
    CIDADE: 'Alto Bela Vista',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.32990264892578,
    MAXIMO: 44.42488098144531,
    MEDIA: 44.340304384893635,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '63b5383a-5eb0-4ebb-878c-199dd9c06f75',
    CIDADE: 'Anchieta',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.43940734863281,
    MAXIMO: 48.390254974365234,
    MEDIA: 47.815727766290145,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '02c38746-9f30-4365-840d-9c77f6030018',
    CIDADE: 'Angelina',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.561973571777344,
    MAXIMO: 44.16122817993164,
    MEDIA: 43.72823758676879,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cb8f2737-c295-4d8f-89ee-8a57dbabcee2',
    CIDADE: 'Anita Garibaldi',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.09500503540039,
    MAXIMO: 44.288185119628906,
    MEDIA: 44.16155233126435,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '589b116d-25a7-46ff-a17c-e771db321a71',
    CIDADE: 'Anitápolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.08018493652344,
    MAXIMO: 44.2481803894043,
    MEDIA: 44.16418266296387,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e2d4bbdf-a79b-4b1f-96d7-109c1945e73a',
    CIDADE: 'Antônio Carlos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.22058868408203,
    MAXIMO: 43.81843566894531,
    MEDIA: 43.52938411213018,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5adfebae-dd0b-4fd6-b808-2431919eaf48',
    CIDADE: 'Apiúna',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.485198974609375,
    MAXIMO: 43.553279876708984,
    MEDIA: 43.51923942565918,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '64966eed-1781-4b70-8664-c8a501df1631',
    CIDADE: 'Arabutã',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.456512451171875,
    MAXIMO: 44.65158462524414,
    MEDIA: 44.57163010491096,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a82febc5-2f70-4a71-9506-38182ec41064',
    CIDADE: 'Araquari',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.59910583496094,
    MAXIMO: 42.15473937988281,
    MEDIA: 41.844297898647056,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'da3e3b65-38ae-4f21-b9f9-987d046fa479',
    CIDADE: 'Araranguá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.957801818847656,
    MAXIMO: 45.209800720214844,
    MEDIA: 45.07516739820462,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bab69f23-236d-475f-9d23-b09a114d5ac1',
    CIDADE: 'Armazém',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.35866165161133,
    MAXIMO: 44.5485725402832,
    MEDIA: 44.479717431684556,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2b657480-d914-403d-9077-31bb0b8fd0d3',
    CIDADE: 'Arroio Trinta',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.85273742675781,
    MAXIMO: 43.99296188354492,
    MEDIA: 43.94969548306049,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f6f6fb9b-6757-46b3-9201-e5edcfeda682',
    CIDADE: 'Arvoredo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.75417709350586,
    MAXIMO: 45.0742301940918,
    MEDIA: 44.8410533617978,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c9d5e4e8-a4c6-47b6-91e2-db1e989d5670',
    CIDADE: 'Ascurra',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.00154113769531,
    MAXIMO: 43.50188064575195,
    MEDIA: 43.12547792497748,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0f56dc4d-460e-421a-a5ae-4af86a2d5a65',
    CIDADE: 'Atalanta',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.860130310058594,
    MAXIMO: 44.05905532836914,
    MEDIA: 43.974978432017274,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3d9ca8ea-12b9-4aab-96c5-64f8c96d56e8',
    CIDADE: 'Aurora',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.7243537902832,
    MAXIMO: 43.860130310058594,
    MEDIA: 43.796724702103425,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2b34d5ad-ad6d-48fa-bf4f-97e7c8740b8e',
    CIDADE: 'Balneário Arroio do Silva',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.07871627807617,
    MAXIMO: 45.214481353759766,
    MEDIA: 45.130695775452075,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7b741a30-95a6-42b5-920b-a5e8f1a11e1d',
    CIDADE: 'Balneário Camboriú',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.59900665283203,
    MAXIMO: 42.59900665283203,
    MEDIA: 42.59900665283203,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7bc7d983-3da5-4c24-8191-8f141c4cf44a',
    CIDADE: 'Balneário Barra do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.59910583496094,
    MAXIMO: 41.901485443115234,
    MEDIA: 41.70911211276473,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef11357b-fa96-45a1-bcc0-ce94ce070d8b',
    CIDADE: 'Balneário Gaivota',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.18280792236328,
    MAXIMO: 45.31365966796875,
    MEDIA: 45.22788412847136,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '842bd47c-4244-4eb7-a5ca-035326cdd1ad',
    CIDADE: 'Bandeirante',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.720794677734375,
    MAXIMO: 48.502925872802734,
    MEDIA: 48.20716411228138,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5ffa1d99-c17d-4bee-8b8e-86ee41ac4227',
    CIDADE: 'Barra Bonita',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.60508728027344,
    MAXIMO: 48.06116485595703,
    MEDIA: 47.73367524140499,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a6c89adb-b74b-46b2-9384-09c66400b027',
    CIDADE: 'Barra Velha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.759063720703125,
    MAXIMO: 42.15473937988281,
    MEDIA: 42.09803514439641,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b294c1d9-ee36-4fb7-9d1e-d8a662027e17',
    CIDADE: 'Bela Vista do Toldo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.78565979003906,
    MAXIMO: 43.52737045288086,
    MEDIA: 42.98569080322679,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e08ff5ad-23e8-4fb3-ae2d-0a3ea07b7158',
    CIDADE: 'Belmonte',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.720794677734375,
    MAXIMO: 48.14982223510742,
    MEDIA: 47.989367124017086,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cf104ad3-3403-4c95-8770-2ac6da234fbd',
    CIDADE: 'Benedito Novo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.90654754638672,
    MAXIMO: 43.12607192993164,
    MEDIA: 43.01630973815918,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bda4f8b5-01e3-44e5-99f4-b3ecd87cb8f7',
    CIDADE: 'Biguaçu',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.08033752441406,
    MAXIMO: 43.52072525024414,
    MEDIA: 43.31552778947156,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a3198358-237f-4f15-b762-65350d2e9f00',
    CIDADE: 'Blumenau',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.347694396972656,
    MAXIMO: 43.01272964477539,
    MEDIA: 42.66361999511719,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8b3e405d-9576-47d7-b98f-8fd743e27744',
    CIDADE: 'Bocaina do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.16682434082031,
    MAXIMO: 44.433284759521484,
    MEDIA: 44.290979137956306,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b833db35-0df7-42d5-8b84-687d50f3c7d2',
    CIDADE: 'Bombinhas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.687255859375,
    MAXIMO: 42.85736846923828,
    MEDIA: 42.72181407318796,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bfae04af-4330-4afd-915a-614a81b601a0',
    CIDADE: 'Bom Jardim da Serra',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.64879608154297,
    MAXIMO: 44.75368881225586,
    MEDIA: 44.69349161783854,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '213bc742-a173-4e87-a180-b88166cdf16b',
    CIDADE: 'Bom Jesus',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.936492919921875,
    MAXIMO: 45.10717010498047,
    MEDIA: 45.03880550490836,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4a9b6044-4f9c-4048-9f19-3549a1cb850b',
    CIDADE: 'Bom Jesus do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.68925094604492,
    MAXIMO: 47.145103454589844,
    MEDIA: 46.77277829941203,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '94af54c1-d540-4a2e-9153-7d76ff81e7c1',
    CIDADE: 'Bom Retiro',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.22092056274414,
    MAXIMO: 44.3048095703125,
    MEDIA: 44.26438776652018,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd2c220dc-9cb1-47b9-9059-fa33d6000c58',
    CIDADE: 'Botuverá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.113258361816406,
    MAXIMO: 43.667598724365234,
    MEDIA: 43.274780187560324,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6718c82d-b5c9-4873-a09a-4203b4611a31',
    CIDADE: 'Braço do Norte',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.41884994506836,
    MAXIMO: 44.59477233886719,
    MEDIA: 44.50634111956425,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '270e0cb6-e241-4440-acf4-71070464c59e',
    CIDADE: 'Braço do Trombudo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.860130310058594,
    MAXIMO: 44.011844635009766,
    MEDIA: 43.907137505614074,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '29975c94-c27a-4bce-bf77-99390453ffc9',
    CIDADE: 'Brunópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.77166748046875,
    MAXIMO: 43.967376708984375,
    MEDIA: 43.904525314662195,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0f874ffa-d885-4c1f-b4d0-e77a1e743b01',
    CIDADE: 'Brusque',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.73234939575195,
    MAXIMO: 43.34029006958008,
    MEDIA: 43.031089050839384,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'aef51470-7846-4136-84c4-3ccde5766b2d',
    CIDADE: 'Caçador',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.66294860839844,
    MAXIMO: 43.85273742675781,
    MEDIA: 43.77309322357178,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2bc1ad87-5afd-45c2-99f0-3543e14f20b2',
    CIDADE: 'Caibi',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.26498794555664,
    MAXIMO: 46.96644592285156,
    MEDIA: 46.61833038592359,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e641d572-1dab-4038-a225-3769a2dfceb5',
    CIDADE: 'Calmon',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.56627655029297,
    MAXIMO: 43.6931037902832,
    MEDIA: 43.629690170288086,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e3e18777-65b1-4038-b754-70ac68fb7986',
    CIDADE: 'Camboriú',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.59900665283203,
    MAXIMO: 42.97321319580078,
    MEDIA: 42.77014485819929,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '44b70759-f4bd-4bfe-be61-d3aab83ff8d3',
    CIDADE: 'Capão Alto',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.32500457763672,
    MAXIMO: 44.44032287597656,
    MEDIA: 44.38318157196045,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ced29956-3c11-4d0b-a82f-0a41d80234ba',
    CIDADE: 'Campo Alegre',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.60786056518555,
    MAXIMO: 41.91537094116211,
    MEDIA: 41.76161575317383,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8f0a1886-72b0-46f5-995e-63678db2e931',
    CIDADE: 'Campo Belo do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.16915512084961,
    MAXIMO: 44.352081298828125,
    MEDIA: 44.2769136428833,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ee3f5fe8-d07f-441f-845c-d3d99a9b6be4',
    CIDADE: 'Campo Erê',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.73000717163086,
    MAXIMO: 48.224449157714844,
    MEDIA: 47.35754613053446,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1fee22f6-23d5-45ca-9926-35e1d32c19a2',
    CIDADE: 'Campos Novos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.98604965209961,
    MAXIMO: 44.1292839050293,
    MEDIA: 44.04440498352051,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ee2cf105-14c1-4bd3-9e3f-7210b144dc8e',
    CIDADE: 'Canelinha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.97321319580078,
    MAXIMO: 43.22058868408203,
    MEDIA: 43.086719080304306,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '434eec76-ed52-4589-9e4b-f8a08d63a3a6',
    CIDADE: 'Canoinhas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.78565979003906,
    MAXIMO: 43.08102798461914,
    MEDIA: 42.944358825683594,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '297be44c-943b-4704-86e7-a1b1fa601da4',
    CIDADE: 'Capinzal',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.1292839050293,
    MAXIMO: 44.23854446411133,
    MEDIA: 44.18323448675235,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b2b2019e-8bec-4a4a-a923-2a892114b475',
    CIDADE: 'Capivari de Baixo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.66834259033203,
    MAXIMO: 44.66834259033203,
    MEDIA: 44.66834259033203,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'aaebe039-4b35-45d4-83a2-23e185716b64',
    CIDADE: 'Catanduvas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.10924530029297,
    MAXIMO: 44.356903076171875,
    MEDIA: 44.215828691757345,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '535a6c09-041b-4b67-82da-29e826629ca0',
    CIDADE: 'Caxambu do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.5430793762207,
    MAXIMO: 45.89842224121094,
    MEDIA: 45.63273732878797,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7d3a1f43-8134-4936-8571-0d198e0004d6',
    CIDADE: 'Celso Ramos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.0933837890625,
    MAXIMO: 44.205169677734375,
    MEDIA: 44.16201759552906,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7c6fc7bf-8ebb-4e74-a581-9e3249f861f0',
    CIDADE: 'Cerro Negro',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.09500503540039,
    MAXIMO: 44.288185119628906,
    MEDIA: 44.19159507751465,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6d70c4ab-022d-4bc0-9900-d0baa6e37687',
    CIDADE: 'Chapadão do Lageado',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.98073196411133,
    MAXIMO: 44.22517776489258,
    MEDIA: 44.09747981084576,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5d58c820-2622-4979-b9b8-5d4777b2d556',
    CIDADE: 'Chapecó',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.96358871459961,
    MAXIMO: 45.4016227722168,
    MEDIA: 45.198594411214195,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '359ce997-17b4-41ac-b852-681c44667177',
    CIDADE: 'Cocal do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.83973693847656,
    MAXIMO: 44.847232818603516,
    MEDIA: 44.84147200913532,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e8fd36b9-2f9d-43fa-bc7a-404cb63a9f7b',
    CIDADE: 'Concórdia',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.33066177368164,
    MAXIMO: 44.53813171386719,
    MEDIA: 44.437546730041504,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6e2ff6ec-917e-48e2-a810-14f515f014ad',
    CIDADE: 'Cordilheira Alta',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.0742301940918,
    MAXIMO: 45.4016227722168,
    MEDIA: 45.27401963124426,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6dcac095-51bb-4d95-ba3d-09bda7cf492f',
    CIDADE: 'Coronel Freitas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.0742301940918,
    MAXIMO: 45.99928665161133,
    MEDIA: 45.600879731447584,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '827792c0-0cb9-429f-944f-ba23d93bf414',
    CIDADE: 'Coronel Martins',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.63447189331055,
    MAXIMO: 46.04430389404297,
    MEDIA: 46.00330955039022,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '20c19996-a37b-4472-a564-f4d852b6a463',
    CIDADE: 'Corupá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.94880294799805,
    MAXIMO: 42.3985710144043,
    MEDIA: 42.242629464121876,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '235482d6-b9d0-4396-a4db-7fb80a5a2888',
    CIDADE: 'Correia Pinto',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.99197006225586,
    MAXIMO: 44.113765716552734,
    MEDIA: 44.07257843017578,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '405c61f3-dba0-4ab2-b60b-fee78125e681',
    CIDADE: 'Criciúma',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.83973693847656,
    MAXIMO: 44.95808029174805,
    MEDIA: 44.89530236720448,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '44a1f5d8-6325-477f-840c-a2a039eaa37c',
    CIDADE: 'Cunha Porã',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.150638580322266,
    MAXIMO: 47.290802001953125,
    MEDIA: 46.79177441635506,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4a64ad12-b958-4cd9-95c1-5819e92b6139',
    CIDADE: 'Cunhataí',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.150638580322266,
    MAXIMO: 46.55867004394531,
    MEDIA: 46.27035052797573,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '646b08eb-2388-482f-ac03-67f560dad458',
    CIDADE: 'Curitibanos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.794010162353516,
    MAXIMO: 43.85866928100586,
    MEDIA: 43.82633972167969,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4762bd89-919a-40c3-9e58-bc081c32eac1',
    CIDADE: 'Descanso',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.96644592285156,
    MAXIMO: 48.14982223510742,
    MEDIA: 47.555154153702425,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a0938aee-d54d-47d6-a392-624e99fee88d',
    CIDADE: 'Dionísio Cerqueira',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 48.71218490600586,
    MAXIMO: 49.17732238769531,
    MEDIA: 48.944753646850586,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cabfcb2c-2035-4e78-8803-ee91dd94ed67',
    CIDADE: 'Dona Emma',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.20176315307617,
    MAXIMO: 43.64122009277344,
    MEDIA: 43.56916773858325,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dd764a18-1b9f-4984-bfaa-ef7c1b1a865a',
    CIDADE: 'Doutor Pedrinho',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.3985710144043,
    MAXIMO: 43.20176315307617,
    MEDIA: 43.01770210175426,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '230ad3b2-52a0-4f5d-a839-74fcb9634429',
    CIDADE: 'Entre Rios',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.21706771850586,
    MAXIMO: 45.81749725341797,
    MEDIA: 45.364889278025395,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8503b249-8932-4689-8894-4ac2cbd9907d',
    CIDADE: 'Ermo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.06642532348633,
    MAXIMO: 45.07871627807617,
    MEDIA: 45.07591052774544,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd881a9d1-528d-4e76-b6db-68606a921d23',
    CIDADE: 'Erval Velho',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.98604965209961,
    MAXIMO: 44.1292839050293,
    MEDIA: 44.058690844463236,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '96bcfb21-c070-4920-850e-58d164663453',
    CIDADE: 'Faxinal dos Guedes',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.63221740722656,
    MAXIMO: 44.936492919921875,
    MEDIA: 44.75932733893534,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b7a9ff6b-7244-430f-8247-2cc36fe83afa',
    CIDADE: 'Flor do Sertão',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.290802001953125,
    MAXIMO: 47.60508728027344,
    MEDIA: 47.39015924900966,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '05eb519b-933b-4935-9c17-e72440d75b9a',
    CIDADE: 'Florianópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.79995346069336,
    MAXIMO: 43.814186096191406,
    MEDIA: 43.389284230923465,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a4be649c-38b2-446f-a0e0-6535f174a2ad',
    CIDADE: 'Formosa do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.81749725341797,
    MAXIMO: 46.244728088378906,
    MEDIA: 46.06129673320879,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4c6a467d-77b4-42a8-9364-9a498186b94f',
    CIDADE: 'Forquilhinha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.957801818847656,
    MAXIMO: 44.961822509765625,
    MEDIA: 44.958796691627015,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9a395707-b5a0-4c0c-a2aa-8f916d558cb1',
    CIDADE: 'Fraiburgo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.66294860839844,
    MAXIMO: 43.876617431640625,
    MEDIA: 43.75293627225881,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '808e8c10-1aca-4903-920e-25f25fab04b9',
    CIDADE: 'Frei Rogério',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.77166748046875,
    MAXIMO: 43.85866928100586,
    MEDIA: 43.78414422816824,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '572ab93b-5b2f-4f5a-8038-0672503be6a5',
    CIDADE: 'Galvão',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.63447189331055,
    MAXIMO: 46.2606201171875,
    MEDIA: 45.99578985326333,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '30582b56-3296-4d29-bc49-d18e30906734',
    CIDADE: 'Garopaba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.01425552368164,
    MAXIMO: 44.28288650512695,
    MEDIA: 44.0944420489439,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2042769b-8b7b-470a-be7c-5a18e693cff5',
    CIDADE: 'Garuva',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.246761322021484,
    MAXIMO: 41.42046356201172,
    MEDIA: 41.3336124420166,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd27b48a9-8d74-4c22-8463-6112dfb90a39',
    CIDADE: 'Gaspar',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.50249099731445,
    MAXIMO: 43.01272964477539,
    MEDIA: 42.742217571788856,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6d7ba874-69b5-47f5-a4f1-b1059dcd5574',
    CIDADE: 'Governador Celso Ramos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.94568634033203,
    MAXIMO: 43.332122802734375,
    MEDIA: 43.12514999235859,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8590ba96-1ebc-4351-a7ff-18e2fd4e922f',
    CIDADE: 'Grão-Pará',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.18362045288086,
    MAXIMO: 44.622154235839844,
    MEDIA: 44.465371992691395,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3a193483-2f01-47e3-8008-83db5648f998',
    CIDADE: 'Gravatal',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.522216796875,
    MAXIMO: 44.698455810546875,
    MEDIA: 44.562857393489466,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '09d83028-4caa-4437-ac09-23567b43ccb9',
    CIDADE: 'Guabiruba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.884029388427734,
    MAXIMO: 43.23076248168945,
    MEDIA: 43.086473028954636,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '21557c22-c2e2-41b8-947e-be72a3463675',
    CIDADE: 'Guaraciaba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.60508728027344,
    MAXIMO: 48.84703826904297,
    MEDIA: 48.404556884687175,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b4665484-f3a8-48ef-ba52-61ab22be453d',
    CIDADE: 'Guaramirim',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.80632019042969,
    MAXIMO: 42.239952087402344,
    MEDIA: 42.053540286139736,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c4a29dee-261e-438a-964f-c22e40df3537',
    CIDADE: 'Guarujá do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.91986083984375,
    MAXIMO: 48.71218490600586,
    MEDIA: 48.573994915776,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4da6aca4-59bb-48a8-8a0f-3f8d47a9d7dc',
    CIDADE: 'Guatambú',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.23057174682617,
    MAXIMO: 45.76513671875,
    MEDIA: 45.422927186834286,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1acfe290-fe66-4c45-b34f-878e2cf8d4b9',
    CIDADE: "Herval d'Oeste",
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.92531967163086,
    MAXIMO: 44.1292839050293,
    MEDIA: 44.02856632237032,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f4a7602e-ffa2-421b-b0fb-df3aae286e1d',
    CIDADE: 'Ibiam',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.876617431640625,
    MAXIMO: 44.022056579589844,
    MEDIA: 43.934711400871834,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'de1f097a-b5d9-4a14-bc51-f79ef58e8727',
    CIDADE: 'Ibicaré',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.88025665283203,
    MAXIMO: 44.022056579589844,
    MEDIA: 43.99991075988036,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eb8d348a-baec-444c-ab5c-867160b77c9f',
    CIDADE: 'Ibirama',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.00154113769531,
    MAXIMO: 43.553279876708984,
    MEDIA: 43.25233518058571,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e8425a80-7a47-4884-80ba-992ca57fa23a',
    CIDADE: 'Içara',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.83973693847656,
    MAXIMO: 44.981231689453125,
    MEDIA: 44.930796041516984,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef5d5da6-7be4-4f7f-b1a3-dd6117516511',
    CIDADE: 'Ilhota',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.50249099731445,
    MAXIMO: 42.50724792480469,
    MEDIA: 42.50486946105957,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '93729f35-9b66-44fb-8545-73ab0e8da07b',
    CIDADE: 'Imaruí',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.28288650512695,
    MAXIMO: 44.441261291503906,
    MEDIA: 44.36207389831543,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7a31d811-34d4-4cac-b9a9-92c32a65db12',
    CIDADE: 'Imbituba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.205657958984375,
    MAXIMO: 44.441261291503906,
    MEDIA: 44.304668730137244,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ab70f6a1-c8b1-4f28-9602-fc29e0104593',
    CIDADE: 'Imbuia',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.92827224731445,
    MAXIMO: 44.16122817993164,
    MEDIA: 43.98245932752358,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1a7f0067-9ca3-4eaf-80d5-aef3622a0ef8',
    CIDADE: 'Indaial',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.630435943603516,
    MAXIMO: 43.50188064575195,
    MEDIA: 43.22156228907025,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '16fc82db-9105-4794-9c5a-35ee1f3140eb',
    CIDADE: 'Iomerê',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.88025665283203,
    MAXIMO: 43.99296188354492,
    MEDIA: 43.89656572627325,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bb14ec63-8c7e-4ca8-b03f-c2295718f51d',
    CIDADE: 'Ipira',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.23280334472656,
    MAXIMO: 44.33274459838867,
    MEDIA: 44.27237047709272,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f65fc2e3-970e-4ca4-9f73-17f5be466421',
    CIDADE: 'Iporã do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.96644592285156,
    MAXIMO: 47.37332534790039,
    MEDIA: 47.295566485394104,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '73f628de-861c-48f6-8fb4-5d9e3d101a6e',
    CIDADE: 'Ipuaçu',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.936492919921875,
    MAXIMO: 45.63447189331055,
    MEDIA: 45.29399316991059,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2811cdce-1ae4-4b34-b526-1d57abc1780c',
    CIDADE: 'Ipumirim',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.456512451171875,
    MAXIMO: 44.65158462524414,
    MEDIA: 44.58297434076627,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '121468d8-59b7-47c5-be30-c058baae741f',
    CIDADE: 'Iraceminha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.846553802490234,
    MAXIMO: 47.290802001953125,
    MEDIA: 47.227384872979506,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '21cade39-1527-4848-8982-ae59d2d8f3d7',
    CIDADE: 'Irani',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.220455169677734,
    MAXIMO: 44.501251220703125,
    MEDIA: 44.377477356978055,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4c7f1399-5fa8-4e11-a5e0-ddf719519d74',
    CIDADE: 'Irati',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.244728088378906,
    MAXIMO: 46.49116516113281,
    MEDIA: 46.25373604166721,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2f324281-208b-440d-b3db-0de41a9a8b2c',
    CIDADE: 'Irineópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.145992279052734,
    MAXIMO: 43.21830368041992,
    MEDIA: 43.18214797973633,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '45ba5f67-5966-48cd-bc6e-e7d910132b79',
    CIDADE: 'Itá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.53813171386719,
    MAXIMO: 44.75417709350586,
    MEDIA: 44.668766836914756,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ee5a0d9c-0fbf-4eec-a8de-d150a6c51c70',
    CIDADE: 'Itaiópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.496620178222656,
    MAXIMO: 42.78605270385742,
    MEDIA: 42.67438583374023,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '89733bb3-31c9-4ad1-9a09-6fddefd35b66',
    CIDADE: 'Itajaí',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.36595153808594,
    MAXIMO: 42.97321319580078,
    MEDIA: 42.6587202164449,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'da411d50-f502-4cf0-8cdb-2d4245f14084',
    CIDADE: 'Itapema',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.59900665283203,
    MAXIMO: 42.85736846923828,
    MEDIA: 42.824308534639066,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8eff9fff-c013-459d-bc5f-d2245d7f0ba8',
    CIDADE: 'Itapiranga',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.02418518066406,
    MAXIMO: 48.20103454589844,
    MEDIA: 47.574754871968544,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c1267369-8432-4a59-b05e-8b2793f1f3bf',
    CIDADE: 'Itapoá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.052734375,
    MAXIMO: 41.38861846923828,
    MEDIA: 41.22067642211914,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '56cd3a66-e5c3-497a-9ff0-f9996bdde117',
    CIDADE: 'Ituporanga',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.7243537902832,
    MAXIMO: 44.22517776489258,
    MEDIA: 43.9734940341823,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '972e94f1-55d3-493e-b263-e1aec3e9d29f',
    CIDADE: 'Jaborá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.220455169677734,
    MAXIMO: 44.33066177368164,
    MEDIA: 44.26234332703298,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '27db1d76-f2b6-4151-aae7-23240bf89c95',
    CIDADE: 'Jacinto Machado',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.03987503051758,
    MAXIMO: 45.06642532348633,
    MEDIA: 45.05315017700195,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f614ad96-6b9f-47f5-9dbd-a38a10f024b7',
    CIDADE: 'Jaguaruna',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.66834259033203,
    MAXIMO: 44.981231689453125,
    MEDIA: 44.86989742815358,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e179b939-881d-40b6-a922-2fabdb8dba96',
    CIDADE: 'Jaraguá do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.91537094116211,
    MAXIMO: 42.4691047668457,
    MEDIA: 42.196727807136554,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9cd125bf-63fe-42b6-809f-c94f4290239c',
    CIDADE: 'Jardinópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.99928665161133,
    MAXIMO: 46.244728088378906,
    MEDIA: 46.218436490657204,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6373b38e-0add-4c96-843d-ccb2774989fb',
    CIDADE: 'Joaçaba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.022056579589844,
    MAXIMO: 44.23978042602539,
    MEDIA: 44.13091289082744,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6f0567c4-9e54-4f0b-aa8e-a0e83cc1b83b',
    CIDADE: 'Joinville',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.580726623535156,
    MAXIMO: 41.97821807861328,
    MEDIA: 41.81290740966797,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b04e8a4b-2aa7-4d28-be0a-c5f252376c45',
    CIDADE: 'José Boiteux',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.20176315307617,
    MAXIMO: 43.37215042114258,
    MEDIA: 43.286956787109375,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '376e1bec-e879-48b5-8718-cee56cc89ffd',
    CIDADE: 'Jupiá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.04430389404297,
    MAXIMO: 46.2606201171875,
    MEDIA: 46.229994318464264,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b0bfdfed-b849-40ff-aec2-f9ffe710420a',
    CIDADE: 'Lacerdópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.10924530029297,
    MAXIMO: 44.23978042602539,
    MEDIA: 44.1250219731905,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5313b17d-cdad-4154-bc19-de7f996deeef',
    CIDADE: 'Lages',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.194580078125,
    MAXIMO: 44.556846618652344,
    MEDIA: 44.38530502319336,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b5ddd905-4437-4f63-9000-4a52c4eb4233',
    CIDADE: 'Laguna',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.410369873046875,
    MAXIMO: 44.8447151184082,
    MEDIA: 44.66453721901671,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '50e20d15-f48a-423b-b81a-6d972642cb93',
    CIDADE: 'Lajeado Grande',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.21706771850586,
    MAXIMO: 45.21706771850586,
    MEDIA: 45.21706771850586,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e81d904f-b40d-47da-bcbc-f8fbca310d9b',
    CIDADE: 'Laurentino',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.541831970214844,
    MAXIMO: 43.860130310058594,
    MEDIA: 43.735326514766264,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2da80bb3-a41b-4ff8-898f-419913d3ba51',
    CIDADE: 'Lauro Müller',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.59477233886719,
    MAXIMO: 44.75368881225586,
    MEDIA: 44.68406506125844,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c249489d-7c4f-4d81-944b-05f458c109eb',
    CIDADE: 'Lebon Régis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.55118179321289,
    MAXIMO: 43.716800689697266,
    MEDIA: 43.654641469319664,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4bccdcd3-6d34-4f80-95c4-fbc338549148',
    CIDADE: 'Leoberto Leal',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.27286148071289,
    MAXIMO: 44.16122817993164,
    MEDIA: 43.8698125337783,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e285bd2f-cd8b-40fc-9f07-998aa094c0ab',
    CIDADE: 'Lindóia do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.456512451171875,
    MAXIMO: 44.501251220703125,
    MEDIA: 44.49249946254604,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd81b947c-e7ea-4a40-b0be-0c2b6052f6bb',
    CIDADE: 'Lontras',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.541831970214844,
    MAXIMO: 43.805110931396484,
    MEDIA: 43.586780451568345,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cd45ef51-699e-42ad-9c36-d5151af369e7',
    CIDADE: 'Luiz Alves',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.15473937988281,
    MAXIMO: 42.630435943603516,
    MEDIA: 42.327984000344145,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a395e6ec-3fea-498e-91d3-8cee218d3789',
    CIDADE: 'Luzerna',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.99296188354492,
    MAXIMO: 44.13703536987305,
    MEDIA: 44.08623976250459,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c98e2e86-9695-450d-89b5-bc2f0b48576d',
    CIDADE: 'Macieira',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.8159294128418,
    MAXIMO: 43.97687911987305,
    MEDIA: 43.93747506681531,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '66828177-821f-413e-947d-cbc39a396e54',
    CIDADE: 'Mafra',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.04296112060547,
    MAXIMO: 42.48799514770508,
    MEDIA: 42.3091516494751,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd4e082b3-7b27-47d3-9435-44e5c7a53419',
    CIDADE: 'Major Gercino',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.27286148071289,
    MAXIMO: 43.92827224731445,
    MEDIA: 43.53150828961816,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '254e27e7-b244-454b-b64e-8fa5a16eda4f',
    CIDADE: 'Major Vieira',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.9280891418457,
    MAXIMO: 42.982879638671875,
    MEDIA: 42.95548439025879,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86bf27a1-370e-486c-a881-f730be4893a1',
    CIDADE: 'Maracajá',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.957801818847656,
    MAXIMO: 45.07948303222656,
    MEDIA: 45.00053310488127,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0e5988e3-354a-49ee-a1d4-cad87e246348',
    CIDADE: 'Maravilha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.41690444946289,
    MAXIMO: 47.60508728027344,
    MEDIA: 46.97653858959364,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd1d49cd3-d1d6-4316-b6e6-f48ceee253f1',
    CIDADE: 'Marema',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.21706771850586,
    MAXIMO: 45.594886779785156,
    MEDIA: 45.429976533602186,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7f3f6832-7337-4052-889e-57271301855d',
    CIDADE: 'Massaranduba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.901485443115234,
    MAXIMO: 42.630435943603516,
    MEDIA: 42.26597796926529,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '61418e34-23b8-4a2b-a244-ea3387e7f6df',
    CIDADE: 'Matos Costa',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.53305435180664,
    MAXIMO: 43.79816818237305,
    MEDIA: 43.665611267089844,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '540811ff-724b-4218-b99f-85f430097623',
    CIDADE: 'Meleiro',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.957801818847656,
    MAXIMO: 45.07948303222656,
    MEDIA: 44.993541847450516,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fc119e4a-2005-475f-9377-6d335ea36db6',
    CIDADE: 'Mirim Doce',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.777496337890625,
    MAXIMO: 43.821922302246094,
    MEDIA: 43.79970932006836,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bdd790be-fab7-471e-8a92-f3226cda9521',
    CIDADE: 'Modelo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.41690444946289,
    MAXIMO: 46.846553802490234,
    MEDIA: 46.47549801460279,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1b27d1df-a7a8-4965-a683-e92d5f202cf6',
    CIDADE: 'Mondaí',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.6419677734375,
    MAXIMO: 47.37332534790039,
    MEDIA: 46.94962903501201,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8fca63ac-5da2-45c3-96ed-2161a0902ff0',
    CIDADE: 'Monte Carlo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.77166748046875,
    MAXIMO: 43.98624038696289,
    MEDIA: 43.8570117106496,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ec00c23a-b878-4a33-990d-4e947c82b715',
    CIDADE: 'Monte Castelo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.42184066772461,
    MAXIMO: 43.55259323120117,
    MEDIA: 43.50060145060221,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '52e1b0b3-48ed-462e-b492-364cbad6238b',
    CIDADE: 'Morro da Fumaça',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.83973693847656,
    MAXIMO: 44.84028625488281,
    MEDIA: 44.83981145958621,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2c596412-d1a7-44f5-a353-37e0e7383531',
    CIDADE: 'Morro Grande',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.82720184326172,
    MAXIMO: 44.961822509765625,
    MEDIA: 44.894309917346185,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1bb527cd-ed1c-4555-bc6c-cfbc1c997f62',
    CIDADE: 'Navegantes',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.15473937988281,
    MAXIMO: 42.50724792480469,
    MEDIA: 42.44922190261233,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef3ab017-c2d1-421e-845f-1d242395e6da',
    CIDADE: 'Nova Erechim',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.76513671875,
    MAXIMO: 46.41690444946289,
    MEDIA: 45.914154851767606,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '44dd873c-98d4-4564-922e-815aa269bcac',
    CIDADE: 'Nova Itaberaba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.4016227722168,
    MAXIMO: 45.99928665161133,
    MEDIA: 45.72775359546745,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '09db45f0-d25e-4ecb-92e7-10b710e869db',
    CIDADE: 'Nova Trento',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.27286148071289,
    MAXIMO: 43.34029006958008,
    MEDIA: 43.306575775146484,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c6a4496d-d918-4bf7-8817-e7ddaf00f92e',
    CIDADE: 'Nova Veneza',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.82720184326172,
    MAXIMO: 44.961822509765625,
    MEDIA: 44.88428199074537,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6451abea-83b9-46e5-9b55-6b0e889041b1',
    CIDADE: 'Novo Horizonte',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.81749725341797,
    MAXIMO: 46.73000717163086,
    MEDIA: 46.28705183318463,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '44e546f3-84ab-43b9-bff0-92e8c8341f21',
    CIDADE: 'Orleans',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.494842529296875,
    MAXIMO: 44.622154235839844,
    MEDIA: 44.57058970133463,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c96157d1-69de-4843-8446-dbf14515eb5c',
    CIDADE: 'Otacílio Costa',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.011844635009766,
    MAXIMO: 44.21133041381836,
    MEDIA: 44.112831115722656,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dfafbae8-dbe9-49cf-8f2f-ea3e9fb330b8',
    CIDADE: 'Ouro',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.10924530029297,
    MAXIMO: 44.23978042602539,
    MEDIA: 44.21642654529007,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '47e9c490-7b6a-4579-ab5d-26481ad72f4f',
    CIDADE: 'Ouro Verde',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.737464904785156,
    MAXIMO: 45.10717010498047,
    MEDIA: 44.93368758761302,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e2509f26-82cb-4d66-a859-6fad582e095c',
    CIDADE: 'Paial',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.75417709350586,
    MAXIMO: 44.96358871459961,
    MEDIA: 44.9012239619266,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd07ce537-f080-475f-a7bd-6c09401e08ef',
    CIDADE: 'Painel',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.194580078125,
    MAXIMO: 44.553958892822266,
    MEDIA: 44.41920047592013,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ffc1b810-991f-4191-803d-5041e63db82f',
    CIDADE: 'Palhoça',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.57433319091797,
    MAXIMO: 44.08817672729492,
    MEDIA: 43.75816654804244,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9535a871-6ee8-44e0-b7cb-518e806b7da9',
    CIDADE: 'Palma Sola',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.725067138671875,
    MAXIMO: 48.224449157714844,
    MEDIA: 47.97475814819336,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1ba6cb18-3249-4810-9934-1c6ce37bbf2a',
    CIDADE: 'Palmeira',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.983177185058594,
    MAXIMO: 44.234981536865234,
    MEDIA: 44.109079360961914,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '97cf3956-fd67-48e2-b9eb-70073df780b1',
    CIDADE: 'Palmitos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.26498794555664,
    MAXIMO: 46.55867004394531,
    MEDIA: 46.41182899475098,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '01f8227d-0691-4b03-9cec-4dea3a2bc5e3',
    CIDADE: 'Papanduva',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.74608612060547,
    MAXIMO: 42.94797134399414,
    MEDIA: 42.847028732299805,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '31228b0d-cdf7-42ff-9a5d-8f999d91c6e1',
    CIDADE: 'Paraíso',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 48.06116485595703,
    MAXIMO: 48.84703826904297,
    MEDIA: 48.50222932273516,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '331a121e-d3f9-4e72-acc2-4dcf116917fa',
    CIDADE: 'Passo de Torres',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.287715911865234,
    MAXIMO: 45.31365966796875,
    MEDIA: 45.29148596968503,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '70e17349-f2a3-4ff3-95e2-960dac8e41c4',
    CIDADE: 'Passos Maia',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.44593811035156,
    MAXIMO: 44.63221740722656,
    MEDIA: 44.54579289754232,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4218bc7c-bfa9-42a3-af1e-820907e677a1',
    CIDADE: 'Paulo Lopes',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.88170623779297,
    MAXIMO: 44.35866165161133,
    MEDIA: 44.06955312593178,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6d165042-9296-4fe6-9f61-7e49c82df5a8',
    CIDADE: 'Pedras Grandes',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.698455810546875,
    MAXIMO: 44.83973693847656,
    MEDIA: 44.71636296367829,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0c950cd5-0ec6-4d56-aa83-79654e643c42',
    CIDADE: 'Penha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.02168655395508,
    MAXIMO: 42.50724792480469,
    MEDIA: 42.36549127237092,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '481bdc4c-4896-4871-99e0-b17ec43f5cd3',
    CIDADE: 'Peritiba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.32990264892578,
    MAXIMO: 44.33274459838867,
    MEDIA: 44.32998883235016,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '728207be-f4f1-4c5f-87c0-e009825f0d39',
    CIDADE: 'Pescaria Brava',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.441261291503906,
    MAXIMO: 44.66834259033203,
    MEDIA: 44.60255699085834,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ceb8c202-281a-4a7a-a5ff-97365ec6ea89',
    CIDADE: 'Petrolândia',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.98332977294922,
    MAXIMO: 44.05905532836914,
    MEDIA: 44.02119255065918,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b4b4b9ef-b733-450a-9d0b-e3cb4580a118',
    CIDADE: 'Balneário Piçarras',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.02168655395508,
    MAXIMO: 42.50724792480469,
    MEDIA: 42.31403564365497,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c7be1980-2776-4e06-a65b-3f71cb989ecf',
    CIDADE: 'Pinhalzinho',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.99928665161133,
    MAXIMO: 46.41690444946289,
    MEDIA: 46.31702515204831,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bb269493-2445-4bd2-bb9c-39d6448b9588',
    CIDADE: 'Pinheiro Preto',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.88025665283203,
    MAXIMO: 43.92531967163086,
    MEDIA: 43.881849663462425,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4aee3840-e574-43b7-8f5b-63ff86fe3a48',
    CIDADE: 'Piratuba',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.23280334472656,
    MAXIMO: 44.33274459838867,
    MEDIA: 44.2600308672837,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9c73ac19-7075-4fb7-95b8-31d438c97311',
    CIDADE: 'Planalto Alegre',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.5430793762207,
    MAXIMO: 45.76513671875,
    MEDIA: 45.71118053915134,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c770be9d-906c-4fdc-97f8-e06ef2bbd01f',
    CIDADE: 'Pomerode',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.347694396972656,
    MAXIMO: 42.723175048828125,
    MEDIA: 42.47217312466402,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6447810a-0f7d-4501-b209-cbda15c9eaf3',
    CIDADE: 'Ponte Alta',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.88837432861328,
    MAXIMO: 44.06132507324219,
    MEDIA: 43.95610151815521,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5566a56c-afaf-45a4-8332-72f83c53e785',
    CIDADE: 'Ponte Alta do Norte',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.643836975097656,
    MAXIMO: 43.72040557861328,
    MEDIA: 43.68212127685547,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dfe5bcbb-f8cf-4214-8712-1ca4ac2705a9',
    CIDADE: 'Ponte Serrada',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.239715576171875,
    MAXIMO: 44.390625,
    MEDIA: 44.31517028808594,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4d6b0fc0-38d3-4f28-bf6c-36081181e0c1',
    CIDADE: 'Porto Belo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.687255859375,
    MAXIMO: 42.85736846923828,
    MEDIA: 42.84438765127875,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a57df3f4-3777-4027-8f34-10a9a5c9edfc',
    CIDADE: 'Porto União',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.2857551574707,
    MAXIMO: 43.74604034423828,
    MEDIA: 43.49581813812256,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9d78cefc-a1c9-483d-a0f0-4a6850b43de5',
    CIDADE: 'Pouso Redondo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.71185302734375,
    MAXIMO: 44.011844635009766,
    MEDIA: 43.88344006597606,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1e80854c-7da1-49fa-873e-ddc537b66e40',
    CIDADE: 'Praia Grande',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.12059783935547,
    MAXIMO: 45.28578567504883,
    MEDIA: 45.18750621600968,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3fda16b2-2e84-46dd-8e3f-aa9464cee38b',
    CIDADE: 'Presidente Castello Branco',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.23854446411133,
    MAXIMO: 44.33066177368164,
    MEDIA: 44.25430982596285,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3dcfc26-57de-423b-a1e1-64c8f9a85321',
    CIDADE: 'Presidente Getúlio',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.37215042114258,
    MAXIMO: 43.71185302734375,
    MEDIA: 43.54090649610339,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0ed263f6-004b-40d9-a29e-65edb7ec4763',
    CIDADE: 'Presidente Nereu',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.23076248168945,
    MAXIMO: 43.7243537902832,
    MEDIA: 43.645210107465424,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bcd127ac-01ff-4af1-bed7-c18143ad3c02',
    CIDADE: 'Princesa',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 48.390254974365234,
    MAXIMO: 49.17732238769531,
    MEDIA: 48.78175017752695,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '701090de-e46c-40f3-a6dd-71c6b96027bd',
    CIDADE: 'Quilombo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.21706771850586,
    MAXIMO: 46.244728088378906,
    MEDIA: 45.783069437145585,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b5613831-17a5-411f-9e67-765f49b17712',
    CIDADE: 'Rancho Queimado',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.81843566894531,
    MAXIMO: 44.16122817993164,
    MEDIA: 44.02073008869751,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '13886b60-71a8-49c4-b696-2c173e9c1b3b',
    CIDADE: 'Rio das Antas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.66294860839844,
    MAXIMO: 43.88025665283203,
    MEDIA: 43.763904728979504,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a2d44d5c-5200-4c3c-9097-1710db80b56d',
    CIDADE: 'Rio do Campo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.56228256225586,
    MAXIMO: 43.72934341430664,
    MEDIA: 43.64581298828125,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e880b90e-e41c-4437-a35f-b335b298768e',
    CIDADE: 'Rio do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.562721252441406,
    MAXIMO: 43.860130310058594,
    MEDIA: 43.73304695890629,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '10f79738-3493-4cf8-b395-dea6eca65424',
    CIDADE: 'Rio dos Cedros',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.3985710144043,
    MAXIMO: 42.4691047668457,
    MEDIA: 42.433837890625,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cb3836dc-1766-452a-ad50-3b1e3b8a61fc',
    CIDADE: 'Rio do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.541831970214844,
    MAXIMO: 43.860130310058594,
    MEDIA: 43.61804503055835,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2e6286b8-30a3-417f-bda3-fe4a36223e93',
    CIDADE: 'Rio Fortuna',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.18362045288086,
    MAXIMO: 44.45370101928711,
    MEDIA: 44.37888330163016,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0688d4fd-b5f8-4d86-a740-68a7c967ec0c',
    CIDADE: 'Rio Negrinho',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.365989685058594,
    MAXIMO: 42.53652572631836,
    MEDIA: 42.45125770568848,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '85056b99-c784-46cb-a253-6abc01a05607',
    CIDADE: 'Rio Rufino',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.267433166503906,
    MAXIMO: 44.436973571777344,
    MEDIA: 44.3837283628837,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '40174191-9e4d-4552-ad44-4e75484e3d29',
    CIDADE: 'Riqueza',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.6419677734375,
    MAXIMO: 47.290802001953125,
    MEDIA: 47.00061009581738,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '03c68ce3-bfac-433f-87c8-4369dc7576e8',
    CIDADE: 'Rodeio',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.723175048828125,
    MAXIMO: 43.50188064575195,
    MEDIA: 43.03458357706167,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2981842d-de7c-4011-9f65-6306ae1932dc',
    CIDADE: 'Romelândia',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.145103454589844,
    MAXIMO: 47.91986083984375,
    MEDIA: 47.519287050426996,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '07d87b5b-c0ad-427e-9dd1-5bb10d25ac72',
    CIDADE: 'Salete',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.491817474365234,
    MAXIMO: 43.72934341430664,
    MEDIA: 43.65842391936051,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5844b061-fe4b-435d-811d-69e421771d2b',
    CIDADE: 'Saltinho',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.244728088378906,
    MAXIMO: 47.43940734863281,
    MEDIA: 46.792548739244864,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '239c11ec-2c58-479f-ac94-72dbddf86638',
    CIDADE: 'Salto Veloso',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.97687911987305,
    MAXIMO: 44.100460052490234,
    MEDIA: 43.986911648279424,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '084efce1-49cb-44c4-8b4a-ad6fbafb2604',
    CIDADE: 'Sangão',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.84028625488281,
    MAXIMO: 44.84028625488281,
    MEDIA: 44.84028625488281,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '52875378-e73b-4acc-b268-b48911eae31a',
    CIDADE: 'Santa Cecília',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.532630920410156,
    MAXIMO: 43.62240982055664,
    MEDIA: 43.58757400512695,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'df6837bb-85e3-413c-88b0-a16789ad5b76',
    CIDADE: 'Santa Helena',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.37332534790039,
    MAXIMO: 48.14982223510742,
    MEDIA: 47.767803871476865,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8cc24797-28fc-4255-9a50-375d464e80b3',
    CIDADE: 'Santa Rosa de Lima',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.18362045288086,
    MAXIMO: 44.41884994506836,
    MEDIA: 44.23718349274677,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2b9caaea-fbd9-4ba8-9d9b-6cc17a099d9d',
    CIDADE: 'Santa Rosa do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.18280792236328,
    MAXIMO: 45.287715911865234,
    MEDIA: 45.18496560127062,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '132333d4-e1db-49c8-b8c0-8f5d59ce03cc',
    CIDADE: 'Santa Terezinha',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.2950325012207,
    MAXIMO: 43.41719436645508,
    MEDIA: 43.35611343383789,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '344d976f-1115-4e52-bb22-f202d7487743',
    CIDADE: 'Santa Terezinha do Progresso',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.68925094604492,
    MAXIMO: 47.43940734863281,
    MEDIA: 47.2427264304032,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9e9bc5d1-e048-48ea-b68c-116744d015fa',
    CIDADE: 'Santiago do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.43022537231445,
    MAXIMO: 46.04430389404297,
    MEDIA: 45.82118416694096,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1367cb4d-c512-4e90-9bc5-2bc1f6a7e0a0',
    CIDADE: 'Santo Amaro da Imperatriz',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.7158203125,
    MAXIMO: 43.91658401489258,
    MEDIA: 43.81620216369629,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '06826e85-f07c-466d-a90c-a1833911a784',
    CIDADE: 'São Bernardino',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.49116516113281,
    MAXIMO: 47.22261047363281,
    MEDIA: 46.877355817188494,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd97e5dea-1e38-4aa7-b908-4d95ce2545f9',
    CIDADE: 'São Bento do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.05923080444336,
    MAXIMO: 42.218814849853516,
    MEDIA: 42.12834676106771,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0f2a16a6-1d61-4bfa-a8d3-1df5f2d75b85',
    CIDADE: 'São Bonifácio',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.91658401489258,
    MAXIMO: 44.35866165161133,
    MEDIA: 44.16169981124007,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6b28d496-4da1-4753-adc1-e15f7e3f4604',
    CIDADE: 'São Carlos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.76513671875,
    MAXIMO: 46.150638580322266,
    MEDIA: 46.12749939062922,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '67454619-4875-4231-8490-11ab2afba7e4',
    CIDADE: 'São Cristóvão do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.88837432861328,
    MAXIMO: 43.92966842651367,
    MEDIA: 43.90902137756348,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd1c50e17-980e-4dc4-9209-325f0b7d6394',
    CIDADE: 'São Domingos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.2701301574707,
    MAXIMO: 46.04430389404297,
    MEDIA: 45.61233687492624,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2987a355-78f1-4c18-a66f-5b4801b3f6f4',
    CIDADE: 'São Francisco do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.16855239868164,
    MAXIMO: 41.80632019042969,
    MEDIA: 41.508691577793094,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3524feac-3ee4-48d5-87a1-eed808a81506',
    CIDADE: 'São João do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.02418518066406,
    MAXIMO: 47.78835678100586,
    MEDIA: 47.3132126557868,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c1db2282-79c6-433c-a686-1f87d337e86a',
    CIDADE: 'São João Batista',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.97321319580078,
    MAXIMO: 43.561973571777344,
    MEDIA: 43.27234263707289,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '97387e7c-fc58-4d8a-a12f-090c3022f860',
    CIDADE: 'São João do Itaperiú',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.901485443115234,
    MAXIMO: 42.15473937988281,
    MEDIA: 42.030350249626984,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a82cd5fa-1ab5-43d0-99f2-8977b83f7327',
    CIDADE: 'São João do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.16182327270508,
    MAXIMO: 45.287715911865234,
    MEDIA: 45.24206096940407,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5143a04a-d8d7-45f0-a1c7-69c1cbbae1d3',
    CIDADE: 'São Joaquim',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.54313278198242,
    MAXIMO: 44.81321334838867,
    MEDIA: 44.660786946614586,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7350e88a-5b26-47e2-9676-612f3eed3eaf',
    CIDADE: 'São José',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.332122802734375,
    MAXIMO: 43.7158203125,
    MEDIA: 43.496027577311345,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a3593633-86a1-4faa-944f-3d95c3070e05',
    CIDADE: 'São José do Cedro',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.91986083984375,
    MAXIMO: 48.84703826904297,
    MEDIA: 48.43986650200966,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b596324a-9b7c-4d80-9311-defbd11e61e0',
    CIDADE: 'São José do Cerrito',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.915306091308594,
    MAXIMO: 44.13747787475586,
    MEDIA: 44.019307454427086,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1e780a9b-b94e-4d7d-8b0e-31bba76fc0fc',
    CIDADE: 'São Lourenço do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.81749725341797,
    MAXIMO: 47.22261047363281,
    MEDIA: 46.547337947390716,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2c8f06ef-2d61-4dc1-8134-8e12dcdfbcb1',
    CIDADE: 'São Ludgero',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.5485725402832,
    MAXIMO: 44.712364196777344,
    MEDIA: 44.58319390058621,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86631e7a-8d38-44f3-b46c-b80ea4da699c',
    CIDADE: 'São Martinho',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.20307922363281,
    MAXIMO: 44.522216796875,
    MEDIA: 44.36544887217405,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '707617df-715b-4550-9cfd-f809db135b6a',
    CIDADE: 'São Miguel da Boa Vista',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.145103454589844,
    MAXIMO: 47.60508728027344,
    MEDIA: 47.218541982070825,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ae8ffaf7-15da-4d19-a66e-8208fd7f8ae1',
    CIDADE: 'São Miguel do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.290802001953125,
    MAXIMO: 48.06116485595703,
    MEDIA: 47.905019405542866,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd0048a54-c995-496f-8325-bb611a31e0c4',
    CIDADE: 'São Pedro de Alcântara',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.52072525024414,
    MAXIMO: 43.81843566894531,
    MEDIA: 43.72396128767338,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1e5b1d74-eca6-4f47-95ce-80944f854c44',
    CIDADE: 'Saudades',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.76513671875,
    MAXIMO: 46.846553802490234,
    MEDIA: 46.308720643979726,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ff2f451a-ff5b-41eb-b27e-b98f05c3a430',
    CIDADE: 'Schroeder',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 41.74592590332031,
    MAXIMO: 42.239952087402344,
    MEDIA: 41.979518119413996,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '899ba043-e79a-4b7b-b878-e3090bf00884',
    CIDADE: 'Seara',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.58979797363281,
    MAXIMO: 44.96358871459961,
    MEDIA: 44.71815485275878,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ea8e11f7-9469-41d4-9db8-2e0f941eee76',
    CIDADE: 'Serra Alta',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.41690444946289,
    MAXIMO: 46.68925094604492,
    MEDIA: 46.68876730721772,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5de2eb15-5d24-4199-8b7f-735e8507de1a',
    CIDADE: 'Siderópolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.73984909057617,
    MAXIMO: 44.85869598388672,
    MEDIA: 44.839891279776644,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5dc5362b-417a-44cc-ae75-11c078e3667f',
    CIDADE: 'Sombrio',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.06642532348633,
    MAXIMO: 45.209800720214844,
    MEDIA: 45.18684868895888,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9ffb9765-8782-4b3f-a5d1-d3c736fa5449',
    CIDADE: 'Sul Brasil',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.99928665161133,
    MAXIMO: 46.68925094604492,
    MEDIA: 46.4240404001472,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '69e9dcbd-507e-4eac-a9fe-4d13a6421174',
    CIDADE: 'Taió',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.45395278930664,
    MAXIMO: 43.779441833496094,
    MEDIA: 43.61669731140137,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8cec9a27-5c90-4c34-a7ad-8b1d8d914d5d',
    CIDADE: 'Tangará',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.84823989868164,
    MAXIMO: 44.022056579589844,
    MEDIA: 43.89861542984473,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '797e53ba-2914-4e27-8794-10c3f14192bd',
    CIDADE: 'Tigrinhos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 46.68925094604492,
    MAXIMO: 47.145103454589844,
    MEDIA: 47.144624764733216,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '13d66d10-0ff1-4584-8db4-af20ef4a4238',
    CIDADE: 'Tijucas',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.97321319580078,
    MAXIMO: 43.08033752441406,
    MEDIA: 43.02677536010742,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a4804549-06f0-41e9-9053-ed6e41c3b8f7',
    CIDADE: 'Timbé do Sul',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.928199768066406,
    MAXIMO: 44.946048736572266,
    MEDIA: 44.937124252319336,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '02a1c45a-b69c-48c7-b0c2-7173cec3ce6d',
    CIDADE: 'Timbó',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.4691047668457,
    MAXIMO: 42.723175048828125,
    MEDIA: 42.72180574013375,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6a68cb89-13c5-4555-b226-e49dd5ebdedb',
    CIDADE: 'Timbó Grande',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.321128845214844,
    MAXIMO: 43.44387435913086,
    MEDIA: 43.38250160217285,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '96f88f5b-7aff-4a89-aefe-00c06f5e7c88',
    CIDADE: 'Três Barras',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 42.4383430480957,
    MAXIMO: 42.78565979003906,
    MEDIA: 42.592254789361554,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '445c4074-09b7-490d-bdd3-d92453dedf04',
    CIDADE: 'Treviso',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.73984909057617,
    MAXIMO: 44.85869598388672,
    MEDIA: 44.751942848911725,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f58ef909-40a4-4863-8706-ac0ac81ec3b2',
    CIDADE: 'Treze de Maio',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.698455810546875,
    MAXIMO: 44.84028625488281,
    MEDIA: 44.800116746700006,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '15d36447-c81c-4b1b-8180-e12aa8314456',
    CIDADE: 'Treze Tílias',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.97687911987305,
    MAXIMO: 44.13703536987305,
    MEDIA: 44.036382592759544,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bdbe3388-1a51-4494-98cc-d6380bcaa392',
    CIDADE: 'Trombudo Central',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.860130310058594,
    MAXIMO: 43.886573791503906,
    MEDIA: 43.863745185092185,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4124f6d0-23f6-4336-b800-7d8c9e8e82c2',
    CIDADE: 'Tubarão',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.5485725402832,
    MAXIMO: 44.84028625488281,
    MEDIA: 44.69554064891807,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2e41aeca-78ef-48e3-a4d4-4666ddeb2261',
    CIDADE: 'Tunápolis',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 47.37332534790039,
    MAXIMO: 47.78835678100586,
    MEDIA: 47.71575856260387,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e898911c-8288-4d2f-9cd4-0bfb76850e9e',
    CIDADE: 'Turvo',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.946048736572266,
    MAXIMO: 45.07871627807617,
    MEDIA: 45.04426070804024,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e3657497-c682-4f47-88bd-ad0ff61b35dd',
    CIDADE: 'União do Oeste',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 45.99928665161133,
    MAXIMO: 46.244728088378906,
    MEDIA: 46.019115864132274,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4cd23a33-281e-4c40-9afe-08eb315b834f',
    CIDADE: 'Urubici',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.36772918701172,
    MAXIMO: 44.69133758544922,
    MEDIA: 44.53174114227295,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '199065ea-7482-4b3c-87a2-99a1e72be9a1',
    CIDADE: 'Urupema',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.427894592285156,
    MAXIMO: 44.69133758544922,
    MEDIA: 44.47037028935992,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2a06290c-a4c4-4ef1-a64d-d948dfb22331',
    CIDADE: 'Urussanga',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.712364196777344,
    MAXIMO: 44.847232818603516,
    MEDIA: 44.73975966353459,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2b75cec3-8b7a-4b4b-b7a3-fce1643866f9',
    CIDADE: 'Vargeão',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.55922317504883,
    MAXIMO: 44.84672546386719,
    MEDIA: 44.66570697954663,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '48b43e11-4f28-44a7-932f-b358a0540faf',
    CIDADE: 'Vargem',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.915306091308594,
    MAXIMO: 44.00888442993164,
    MEDIA: 43.97586482170797,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '19f33f3e-7107-4442-91f0-b637edbf7eee',
    CIDADE: 'Vargem Bonita',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.13703536987305,
    MAXIMO: 44.390625,
    MEDIA: 44.24522639958343,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '29b00273-7c27-4e08-b745-78cfcee459d2',
    CIDADE: 'Vidal Ramos',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.485198974609375,
    MAXIMO: 44.16122817993164,
    MEDIA: 43.79399287480768,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f6f5ec8d-1c5e-47db-9347-1e7770a7e9e5',
    CIDADE: 'Videira',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.72425842285156,
    MAXIMO: 43.92531967163086,
    MEDIA: 43.82938220973114,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e7e348cb-b529-4766-82ac-019f08956af7',
    CIDADE: 'Vitor Meireles',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.407474517822266,
    MAXIMO: 43.491817474365234,
    MEDIA: 43.44964599609375,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '06824c64-e75e-4e33-b244-07ac5f583b8a',
    CIDADE: 'Witmarsum',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 43.407474517822266,
    MAXIMO: 43.64122009277344,
    MEDIA: 43.57399627526261,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9d92649a-ede7-454f-9148-1bdb3108efa8',
    CIDADE: 'Xanxerê',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.737464904785156,
    MAXIMO: 45.43022537231445,
    MEDIA: 44.95303166984654,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6423cc59-5d50-4749-a6d5-fca6d3915f29',
    CIDADE: 'Xavantina',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.58979797363281,
    MAXIMO: 44.82769012451172,
    MEDIA: 44.76176633530354,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fc447a88-781b-430f-8ace-3089f53500bd',
    CIDADE: 'Xaxim',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.75417709350586,
    MAXIMO: 45.594886779785156,
    MEDIA: 45.09940580976611,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1ed2ac40-cc36-429a-8bb1-ce1b004543b3',
    CIDADE: 'Zortéa',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.04552459716797,
    MAXIMO: 44.209774017333984,
    MEDIA: 44.15393932352081,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1518115b-e45b-4509-8690-d6671c83d6bd',
    CIDADE: 'Balneário Rincão',
    SIGLA: 'SC',
    ESTADO: 'Santa Catarina',
    MINIMO: 44.95808029174805,
    MAXIMO: 45.09207534790039,
    MEDIA: 44.96290401647218,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ed4396c2-483c-43fc-8f29-2a39e87e345b',
    CIDADE: 'Lagoa Mirim',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.39441680908203,
    MAXIMO: 48.833900451660156,
    MEDIA: 46.41165438565341,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '510cbc21-18f3-415e-ad37-b87fe5b9ed5a',
    CIDADE: 'Lagoa dos Patos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.33464813232422,
    MAXIMO: 46.24467468261719,
    MEDIA: 45.799571087485866,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aeeb7d23-6ce1-44d3-aecc-c2e11348e620',
    CIDADE: 'Aceguá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.49238204956055,
    MAXIMO: 43.620243072509766,
    MEDIA: 43.54982312520345,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a8d566d7-9f4a-4156-8583-8b10718f30e8',
    CIDADE: 'Água Santa',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.39387893676758,
    MAXIMO: 44.39457702636719,
    MEDIA: 44.39422798156738,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e74590c-7179-4ed7-b67a-d171a9062f40',
    CIDADE: 'Agudo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.11104202270508,
    MAXIMO: 44.126712799072266,
    MEDIA: 44.11887741088867,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2fc11da1-7a1a-4f9b-87e3-6e6979295f97',
    CIDADE: 'Ajuricaba',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.937374114990234,
    MAXIMO: 45.45781707763672,
    MEDIA: 45.17535158842522,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f45dfc02-51df-476b-916d-d2f3f0f8ee8d',
    CIDADE: 'Alecrim',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.62835693359375,
    MAXIMO: 49.465736389160156,
    MEDIA: 48.95256745377761,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f530317d-8472-455b-a1a1-e5c7028c9dfe',
    CIDADE: 'Alegrete',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.61195373535156,
    MAXIMO: 49.080135345458984,
    MEDIA: 46.33916303846571,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1ac25492-3ffb-4d81-bf5b-9bf7c3e1b577',
    CIDADE: 'Alegria',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.58988571166992,
    MAXIMO: 46.97153091430664,
    MEDIA: 46.68689461142147,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02b13eca-cd52-4256-bed5-88f8e59488fc',
    CIDADE: 'Almirante Tamandaré do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.507259368896484,
    MAXIMO: 44.817569732666016,
    MEDIA: 44.62304918970245,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5e6c9a6-eeba-4ad9-b40b-51a06d8e370f',
    CIDADE: 'Alpestre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.34111785888672,
    MAXIMO: 46.26498794555664,
    MEDIA: 45.89466833923834,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c3ac4086-5dac-4a63-bcaa-82128238f5ab',
    CIDADE: 'Alto Alegre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.273014068603516,
    MAXIMO: 44.324623107910156,
    MEDIA: 44.32427128393334,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f95693af-9cdb-4dda-8078-05b6576f8bc6',
    CIDADE: 'Alto Feliz',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.777549743652344,
    MAXIMO: 44.9102783203125,
    MEDIA: 44.85211149260612,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c95175a5-90ec-4a5a-86e0-e738a8089b10',
    CIDADE: 'Alvorada',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.25271987915039,
    MAXIMO: 45.354652404785156,
    MEDIA: 45.28510847887929,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '84cc3791-255b-4c8a-86eb-693048a22163',
    CIDADE: 'Amaral Ferrador',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.6678581237793,
    MAXIMO: 45.05342102050781,
    MEDIA: 44.78569126896149,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3cb618ad-89f6-4d2e-80c9-b0cdab430df3',
    CIDADE: 'Ametista do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.650550842285156,
    MAXIMO: 45.97792053222656,
    MEDIA: 45.970735132362705,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cafa0950-f287-4788-9422-77dbdeb57324',
    CIDADE: 'André da Rocha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.522953033447266,
    MAXIMO: 44.554588317871094,
    MEDIA: 44.53877067565918,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3e851b6-63e3-4cc8-acc2-743e4f16c749',
    CIDADE: 'Anta Gorda',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.44805145263672,
    MAXIMO: 44.53153610229492,
    MEDIA: 44.46836092020242,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f51110b-1f58-4017-9939-dcc1b15c0f6e',
    CIDADE: 'Antônio Prado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.56623077392578,
    MAXIMO: 44.742088317871094,
    MEDIA: 44.62844107108999,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '960bb4f8-609b-40f6-9b23-cdad124fccc1',
    CIDADE: 'Arambaré',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.49787902832031,
    MAXIMO: 45.61213684082031,
    MEDIA: 45.55500793457031,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee9e5150-a22b-4d00-8db1-66746d06d37e',
    CIDADE: 'Araricá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.14378356933594,
    MAXIMO: 45.245784759521484,
    MEDIA: 45.14403613374884,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6bfa510e-a14e-4989-81a3-c7fcf4784d71',
    CIDADE: 'Aratiba',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.49693298339844,
    MAXIMO: 44.68125534057617,
    MEDIA: 44.589973332278134,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '100aec16-2ae7-4385-87a6-ef4266f503b0',
    CIDADE: 'Arroio do Meio',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.54253387451172,
    MAXIMO: 44.652462005615234,
    MEDIA: 44.59656477061292,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5250af96-4249-47ad-bb8e-d1ffad583e0a',
    CIDADE: 'Arroio do Sal',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.39249038696289,
    MAXIMO: 45.48494338989258,
    MEDIA: 45.43733190418069,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5aeeedfd-134e-4d13-a4cf-f0d4ce7e820d',
    CIDADE: 'Arroio do Padre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.77696990966797,
    MAXIMO: 45.05414581298828,
    MEDIA: 44.92574351056257,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ff94ee46-fd12-4a87-b845-e61f9f0a70f0',
    CIDADE: 'Arroio dos Ratos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.97836685180664,
    MAXIMO: 45.02183532714844,
    MEDIA: 45.00010108947754,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '37f2b16d-36e4-420d-994d-154e9b7ae7b4',
    CIDADE: 'Arroio do Tigre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.15296173095703,
    MAXIMO: 44.25774002075195,
    MEDIA: 44.20761719090768,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '008e715b-636f-45da-bafa-e42ef78d98d6',
    CIDADE: 'Arroio Grande',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.624916076660156,
    MAXIMO: 45.45734405517578,
    MEDIA: 45.035384284125435,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb5585f0-bc5c-4f2f-8ae3-69d579a912f9',
    CIDADE: 'Arvorezinha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.350372314453125,
    MAXIMO: 44.44805145263672,
    MEDIA: 44.3879718675715,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49f2fe15-98a7-418e-8dd0-6fc0fe63ec96',
    CIDADE: 'Augusto Pestana',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.74421310424805,
    MAXIMO: 45.43866729736328,
    MEDIA: 45.06235148926603,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bf5e289d-2dd9-4bbb-adbb-9f7e84195bfc',
    CIDADE: 'Áurea',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.374481201171875,
    MAXIMO: 44.4633903503418,
    MEDIA: 44.388067679234204,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7cdf08fc-5a08-4ebb-974e-1bdbed12dbd5',
    CIDADE: 'Bagé',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.474334716796875,
    MAXIMO: 43.841064453125,
    MEDIA: 43.60656153361003,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '31fe2aba-192a-46a7-9615-b40b99822d1b',
    CIDADE: 'Balneário Pinhal',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.64698028564453,
    MAXIMO: 45.87181854248047,
    MEDIA: 45.81878140706706,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e8652f77-4d57-451b-bc2a-99dc51ea1b41',
    CIDADE: 'Barão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.71853256225586,
    MAXIMO: 44.840476989746094,
    MEDIA: 44.798400398288855,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0d4fe6d4-a251-4ab7-8f18-c6e57720614d',
    CIDADE: 'Barão de Cotegipe',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.56045150756836,
    MAXIMO: 44.75556564331055,
    MEDIA: 44.614332150785856,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e9aac33c-edb5-4513-bb84-c801e212981f',
    CIDADE: 'Barão do Triunfo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.97330856323242,
    MAXIMO: 45.09812545776367,
    MEDIA: 45.03571701049805,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'caf0f743-4e51-4e1f-809c-5e60aafe9dac',
    CIDADE: 'Barracão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.18547439575195,
    MAXIMO: 44.23172378540039,
    MEDIA: 44.20859909057617,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c25e8026-c050-43a1-acb3-f4c0e8cb0d4b',
    CIDADE: 'Barra do Guarita',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.04436111450195,
    MAXIMO: 47.82065963745117,
    MEDIA: 47.55276529517043,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b045f9fa-5279-4d2d-82ee-18279a10a9c0',
    CIDADE: 'Barra do Quaraí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 49.357913970947266,
    MAXIMO: 49.928470611572266,
    MEDIA: 49.65010070800781,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '89bbc170-b992-469e-8651-60b36dad242d',
    CIDADE: 'Barra do Ribeiro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.232662200927734,
    MAXIMO: 45.43224334716797,
    MEDIA: 45.330336252848305,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d4c3321-754c-4f0a-9fef-24edb7a84087',
    CIDADE: 'Barra do Rio Azul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.618080139160156,
    MAXIMO: 44.68125534057617,
    MEDIA: 44.64718988735891,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a57a6f6-ea67-4f21-b8a5-0636928fe653',
    CIDADE: 'Barra Funda',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.817569732666016,
    MAXIMO: 45.00040817260742,
    MEDIA: 44.82985686406575,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0e309904-ed1a-4d3f-b976-022f3f530398',
    CIDADE: 'Barros Cassal',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.27738952636719,
    MAXIMO: 44.314090728759766,
    MEDIA: 44.29574012756348,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bdfbf45b-e5b2-444e-8c8b-9222a6534b63',
    CIDADE: 'Benjamin Constant do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.75556564331055,
    MAXIMO: 44.93135070800781,
    MEDIA: 44.872200740562874,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0931215-170a-44a8-bfc3-9778fe21640a',
    CIDADE: 'Bento Gonçalves',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.53764724731445,
    MAXIMO: 44.777549743652344,
    MEDIA: 44.638658483446996,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9a1866e-d6c7-42f8-9c56-217d7047f333',
    CIDADE: 'Boa Vista das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.16412353515625,
    MAXIMO: 45.67521286010742,
    MEDIA: 45.565820009784204,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74baabf3-c791-494b-9e3f-d7bfa0a90b1c',
    CIDADE: 'Boa Vista do Buricá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.58988571166992,
    MAXIMO: 47.39850997924805,
    MEDIA: 47.21190996352444,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44f903c9-5357-4f24-be60-56e95082725c',
    CIDADE: 'Boa Vista do Cadeado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.6060676574707,
    MAXIMO: 44.74421310424805,
    MEDIA: 44.675140380859375,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '01a567cb-5454-4d8a-bcd5-7bee3c87cf9e',
    CIDADE: 'Boa Vista do Incra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.33295440673828,
    MAXIMO: 44.42530059814453,
    MEDIA: 44.378289540608726,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3951046a-94db-471b-abd8-c1b0a30ad255',
    CIDADE: 'Boa Vista do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.661590576171875,
    MAXIMO: 44.77471923828125,
    MEDIA: 44.69073376347897,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5a02b14c-f29d-448a-9657-2ddfd0fc5299',
    CIDADE: 'Bom Jesus',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.629459381103516,
    MAXIMO: 44.8570671081543,
    MEDIA: 44.75124931335449,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8030916-ad24-485d-813d-758861e567d2',
    CIDADE: 'Bom Princípio',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.840476989746094,
    MAXIMO: 44.9102783203125,
    MEDIA: 44.84602548145475,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c87ac104-d156-4411-b832-d184eccaf060',
    CIDADE: 'Bom Progresso',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.6314811706543,
    MAXIMO: 47.03294372558594,
    MEDIA: 46.93834769399698,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '332a0e27-ee8f-4492-9339-3a05534a9fab',
    CIDADE: 'Bom Retiro do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.602169036865234,
    MAXIMO: 44.744144439697266,
    MEDIA: 44.72214669810083,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '45f7a9b9-ace4-4239-83fa-06fe21d957fd',
    CIDADE: 'Boqueirão do Leão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.32883834838867,
    MAXIMO: 44.4713249206543,
    MEDIA: 44.37814863676359,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f8202f8-42d4-45bb-aa16-325b9f474d9a',
    CIDADE: 'Bossoroca',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.82624053955078,
    MAXIMO: 47.495059967041016,
    MEDIA: 46.64307556152344,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40a7ee3a-4ff6-45a2-93d6-7a69f85c14fa',
    CIDADE: 'Bozano',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.745296478271484,
    MAXIMO: 45.08949279785156,
    MEDIA: 44.94750072543338,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dc3bf862-0ec8-470e-902e-cb200bfa92a4',
    CIDADE: 'Braga',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.281063079833984,
    MAXIMO: 47.03294372558594,
    MEDIA: 46.52179917147843,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f9e5e86-f2f6-46c2-a879-6881ca91570a',
    CIDADE: 'Brochier',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.708778381347656,
    MAXIMO: 44.834842681884766,
    MEDIA: 44.79429871098458,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40a37d28-242e-4973-ae73-f2ad70ff1922',
    CIDADE: 'Butiá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.808250427246094,
    MAXIMO: 44.85967254638672,
    MEDIA: 44.833961486816406,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a28c64fd-af62-4163-a847-9db649b2ca0e',
    CIDADE: 'Caçapava do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.782562255859375,
    MAXIMO: 44.019004821777344,
    MEDIA: 43.90476913452149,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8e93e89-6238-4a28-bd97-448b240a6155',
    CIDADE: 'Cacequi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.00674057006836,
    MAXIMO: 44.435943603515625,
    MEDIA: 44.148872799343536,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f8ef24eb-0180-4237-bc4c-06d843976a05',
    CIDADE: 'Cachoeira do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.07036209106445,
    MAXIMO: 44.35995101928711,
    MEDIA: 44.1983152117048,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aaedbc5b-3abe-49d4-b848-46498c09bc05',
    CIDADE: 'Cachoeirinha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.25271987915039,
    MAXIMO: 45.25271987915039,
    MEDIA: 45.25271987915039,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3a901346-d3b0-4ff2-9d0a-be14482ed65b',
    CIDADE: 'Cacique Doble',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.24164962768555,
    MAXIMO: 44.29943084716797,
    MEDIA: 44.256625854679896,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebc93ae8-fa44-4db9-ab7c-213bcc90eafc',
    CIDADE: 'Caibaté',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.06904220581055,
    MAXIMO: 47.21831512451172,
    MEDIA: 46.77344769238576,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e3396135-b24c-481d-aef7-41d30e61296c',
    CIDADE: 'Caiçara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.31892013549805,
    MAXIMO: 47.02418518066406,
    MEDIA: 46.69589002058775,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3378fa54-cfa5-4135-b4cb-c339bf855dff',
    CIDADE: 'Camaquã',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.05342102050781,
    MAXIMO: 45.57938003540039,
    MEDIA: 45.29341833932059,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '69c4bcad-ed6a-4b39-891c-8510d3d822a9',
    CIDADE: 'Camargo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.365638732910156,
    MAXIMO: 44.416175842285156,
    MEDIA: 44.3737360767512,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ebc27e9-bb58-4469-9abd-159d7f701e5b',
    CIDADE: 'Cambará do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.00420379638672,
    MAXIMO: 45.18245315551758,
    MEDIA: 45.10241826375326,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6ddf7f9-783c-4e97-a190-c6863d8948f8',
    CIDADE: 'Campestre da Serra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.6030387878418,
    MAXIMO: 44.67127227783203,
    MEDIA: 44.637155532836914,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '46f3813a-e06c-4016-bf7c-267c48f7030e',
    CIDADE: 'Campina das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.29676055908203,
    MAXIMO: 48.62835693359375,
    MEDIA: 48.2056747831957,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9e9f144-aad0-494d-b71c-430ad1cde09a',
    CIDADE: 'Campinas do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.598575592041016,
    MAXIMO: 44.823612213134766,
    MEDIA: 44.72932755776542,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '734e8e36-f14c-44d6-8ac6-3da0a7734ad0',
    CIDADE: 'Campo Bom',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.06090545654297,
    MAXIMO: 45.15282440185547,
    MEDIA: 45.088648982868946,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fac8f43e-4814-4b72-9b81-e244f7a59ec5',
    CIDADE: 'Campo Novo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.90415573120117,
    MAXIMO: 46.6314811706543,
    MEDIA: 46.57376836720177,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '57d94604-1ba8-402e-b9c8-aa3713d272cd',
    CIDADE: 'Campos Borges',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.273014068603516,
    MAXIMO: 44.337432861328125,
    MEDIA: 44.28451000340023,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '75aa90bc-0fb4-4edb-af0d-053ce3fd6f25',
    CIDADE: 'Candelária',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.22079086303711,
    MAXIMO: 44.2939338684082,
    MEDIA: 44.24804941813151,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd3534ffb-5197-44f2-b710-eca61dd7754a',
    CIDADE: 'Cândido Godói',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.75870895385742,
    MAXIMO: 48.62835693359375,
    MEDIA: 47.9989415260616,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'feaab2d0-26e3-4199-b326-907a70cf187d',
    CIDADE: 'Candiota',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.693660736083984,
    MAXIMO: 43.76216125488281,
    MEDIA: 43.73157628377279,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbd54fe4-1903-4f5c-bd5a-9edc70c665ed',
    CIDADE: 'Canela',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9629020690918,
    MAXIMO: 45.1679801940918,
    MEDIA: 45.04514490674277,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9489f5f0-d224-4999-b493-32b22f317f55',
    CIDADE: 'Canguçu',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.33806610107422,
    MAXIMO: 44.816131591796875,
    MEDIA: 44.56655326256385,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9d7f17e0-80cf-43b3-8613-6954f95bb575',
    CIDADE: 'Canoas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.14076232910156,
    MAXIMO: 45.25271987915039,
    MEDIA: 45.174025260080995,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bc1f62e1-ae34-41f9-a041-5c7db3c208ea',
    CIDADE: 'Canudos do Vale',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.39561462402344,
    MAXIMO: 44.4713249206543,
    MEDIA: 44.46836196345396,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ddd1b5a1-145b-4994-b387-b9b51dc7c44a',
    CIDADE: 'Capão Bonito do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.25697326660156,
    MAXIMO: 44.41712188720703,
    MEDIA: 44.338080942961255,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5d41c25a-b150-4e3e-a209-168c1d522011',
    CIDADE: 'Capão da Canoa',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.452144622802734,
    MAXIMO: 45.61402130126953,
    MEDIA: 45.50968717667079,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fec64955-13de-4643-abac-7a2c6fbb198d',
    CIDADE: 'Capão do Cipó',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.73857116699219,
    MAXIMO: 45.559059143066406,
    MEDIA: 45.11866760253906,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '36eea5f6-0924-43d5-94f4-c35b20edb736',
    CIDADE: 'Capão do Leão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.02647399902344,
    MAXIMO: 45.205078125,
    MEDIA: 45.11577606201172,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c61aa0e3-1e57-46da-894f-da32445b359b',
    CIDADE: 'Capivari do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.61027908325195,
    MAXIMO: 45.64698028564453,
    MEDIA: 45.62862968444824,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3da70a2c-2791-4d7e-a14b-003e306920c4',
    CIDADE: 'Capela de Santana',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.90524673461914,
    MAXIMO: 45.05891418457031,
    MEDIA: 44.970333324432,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23f5a974-d976-4662-a13c-1f71d916ff4e',
    CIDADE: 'Capitão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.54253387451172,
    MAXIMO: 44.608131408691406,
    MEDIA: 44.56802729873918,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42987f4f-6445-4508-8a33-837fadabf3c6',
    CIDADE: 'Carazinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.507259368896484,
    MAXIMO: 44.55210494995117,
    MEDIA: 44.52968215942383,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '39a3a624-42c2-4b7a-97d2-2b9f659a2f22',
    CIDADE: 'Caraá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.43842315673828,
    MAXIMO: 45.48303985595703,
    MEDIA: 45.460731506347656,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64dd1e8c-742f-4341-b812-c03892ddb62a',
    CIDADE: 'Carlos Barbosa',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.661590576171875,
    MAXIMO: 44.777549743652344,
    MEDIA: 44.74644684510539,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a1475730-5f4e-4b32-905e-f69b9e8a128c',
    CIDADE: 'Carlos Gomes',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.31268310546875,
    MAXIMO: 44.38267517089844,
    MEDIA: 44.326771105454256,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8ac6f8c0-af4d-4faa-8cdc-978b9c0ebfa5',
    CIDADE: 'Casca',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.406715393066406,
    MAXIMO: 44.446502685546875,
    MEDIA: 44.43135693019507,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41301a6b-2902-43c0-b50b-891e8c717100',
    CIDADE: 'Caseiros',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.3389892578125,
    MAXIMO: 44.39760971069336,
    MEDIA: 44.37066267170826,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd644b005-ddbc-4659-a990-815d71fc3ee6',
    CIDADE: 'Catuípe',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.409664154052734,
    MAXIMO: 46.141334533691406,
    MEDIA: 45.774776458740234,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a394837c-944c-4e8f-812b-d32908a0a075',
    CIDADE: 'Caxias do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.75141525268555,
    MAXIMO: 44.9629020690918,
    MEDIA: 44.840658823649086,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8cc0a695-2b8a-4333-8c9c-d857b13b5a5b',
    CIDADE: 'Centenário',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.31268310546875,
    MAXIMO: 44.38267517089844,
    MEDIA: 44.36098749883456,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8140477-9a64-46fe-aca6-5b151da936e5',
    CIDADE: 'Cerrito',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.52303695678711,
    MAXIMO: 44.9595832824707,
    MEDIA: 44.714667816422825,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '88ef5d25-5c7a-4bea-ac1a-f308bb6b626d',
    CIDADE: 'Cerro Branco',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.16547393798828,
    MAXIMO: 44.22942352294922,
    MEDIA: 44.176396438954924,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b001d248-c88a-49fc-8fbf-41e7196e6f9d',
    CIDADE: 'Cerro Grande',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.20620346069336,
    MAXIMO: 45.42662048339844,
    MEDIA: 45.41171113500748,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ae6cdc2f-85be-4d38-b230-f5a85ca419c0',
    CIDADE: 'Cerro Grande do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.04725646972656,
    MAXIMO: 45.176780700683594,
    MEDIA: 45.11201858520508,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fda3a50f-e068-42a4-9c9a-3cc0d76b0de1',
    CIDADE: 'Cerro Largo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.84375,
    MAXIMO: 48.16511917114258,
    MEDIA: 47.48674271506814,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e33ef302-531c-46b3-99cf-05ba313c2a91',
    CIDADE: 'Chapada',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.668270111083984,
    MAXIMO: 44.743377685546875,
    MEDIA: 44.70582389831543,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '492acde3-aeb0-4300-814b-93d76d9829e7',
    CIDADE: 'Charqueadas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.937870025634766,
    MAXIMO: 45.14658737182617,
    MEDIA: 45.018558997558564,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18bceb2a-de89-4867-b4fa-93a459d7e21b',
    CIDADE: 'Charrua',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.31268310546875,
    MAXIMO: 44.374481201171875,
    MEDIA: 44.35736827677822,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd72f17a-9fee-4d80-9f21-9ac6a5bb6f38',
    CIDADE: 'Chiapetta',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.837215423583984,
    MAXIMO: 46.1756706237793,
    MEDIA: 46.00644302368164,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '879a6cea-9e9e-4226-979d-992f45f17b99',
    CIDADE: 'Chuí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 49.61447525024414,
    MAXIMO: 50.09526824951172,
    MEDIA: 49.85487174987793,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7475f728-192f-432d-b58c-7de8974617d8',
    CIDADE: 'Chuvisca',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.928348541259766,
    MAXIMO: 45.20481872558594,
    MEDIA: 45.03279863319479,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c065891b-08b8-4172-b228-06282ac3e181',
    CIDADE: 'Cidreira',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.64698028564453,
    MAXIMO: 45.87181854248047,
    MEDIA: 45.72123029668585,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2e2903f9-5962-4c5f-a7a6-b31fa376f360',
    CIDADE: 'Ciríaco',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.39457702636719,
    MAXIMO: 44.42578887939453,
    MEDIA: 44.40460892993047,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c43bf4e0-61d7-455c-8a68-b4c511392744',
    CIDADE: 'Colinas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.608131408691406,
    MAXIMO: 44.708778381347656,
    MEDIA: 44.646363059143006,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2859b567-f345-4dd6-8625-d20dd6371eb4',
    CIDADE: 'Colorado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.378631591796875,
    MAXIMO: 44.55210494995117,
    MEDIA: 44.45526822502268,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db143afb-5368-4701-bcd3-2afc6bf28412',
    CIDADE: 'Condor',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.84283447265625,
    MAXIMO: 45.00994873046875,
    MEDIA: 44.9263916015625,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd99a1d6-4741-47a6-8139-ce37025f63cd',
    CIDADE: 'Constantina',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.85322189331055,
    MAXIMO: 45.20620346069336,
    MEDIA: 45.11872903325481,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5007a50b-6c36-4067-a5ff-8c3aa508feb2',
    CIDADE: 'Coqueiro Baixo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.41049575805664,
    MAXIMO: 44.54253387451172,
    MEDIA: 44.45401840628779,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4caecde4-ce73-4905-b3dd-145a1afcdcbb',
    CIDADE: 'Coqueiros do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.46055221557617,
    MAXIMO: 44.71641159057617,
    MEDIA: 44.55888301044501,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '73ecad7e-e324-4648-bb35-926a02e7136c',
    CIDADE: 'Coronel Barros',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.138221740722656,
    MAXIMO: 45.76173400878906,
    MEDIA: 45.31062680832113,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e84952da-48a4-4247-9e87-e2c1f057e4ea',
    CIDADE: 'Coronel Bicaco',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.60284423828125,
    MAXIMO: 45.90415573120117,
    MEDIA: 45.75349998474121,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '652c4395-c6b7-45ba-a1ce-4c3233663438',
    CIDADE: 'Coronel Pilar',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.661590576171875,
    MAXIMO: 44.661590576171875,
    MEDIA: 44.66159057617188,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2befc747-43ae-42a7-bd9f-3fea143149c3',
    CIDADE: 'Cotiporã',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.53764724731445,
    MAXIMO: 44.636436462402344,
    MEDIA: 44.552742582158324,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '134cc615-ecbb-4609-a2b5-8312a459f5da',
    CIDADE: 'Coxilha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.38982009887695,
    MAXIMO: 44.42938232421875,
    MEDIA: 44.40960121154785,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4b1bc86d-721a-449a-a7f4-9815c4b51d2b',
    CIDADE: 'Crissiumal',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.420265197753906,
    MAXIMO: 47.82236099243164,
    MEDIA: 47.62131309509277,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47dc7a77-8d5c-4339-84cc-be91e8ff786e',
    CIDADE: 'Cristal',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.906673431396484,
    MAXIMO: 45.12832260131836,
    MEDIA: 45.01749801635742,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '48358fc8-f3d0-4eb1-8046-7bb4de27987e',
    CIDADE: 'Cristal do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.698692321777344,
    MAXIMO: 46.31892013549805,
    MEDIA: 45.80013510390764,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '624662c1-b9bb-433d-844d-2c3380e5fd69',
    CIDADE: 'Cruz Alta',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.46682357788086,
    MAXIMO: 44.61278533935547,
    MEDIA: 44.526978492736816,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15c29d29-3c54-43ea-94f5-bfc5888d478c',
    CIDADE: 'Cruzaltense',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.67599105834961,
    MAXIMO: 44.93135070800781,
    MEDIA: 44.7691199795987,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac991ed7-d4b0-458e-b357-b94d3c6c3ff4',
    CIDADE: 'Cruzeiro do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.493568420410156,
    MAXIMO: 44.724327087402344,
    MEDIA: 44.59175466215812,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '94e4d430-b0da-469d-ad43-f41677b18147',
    CIDADE: 'David Canabarro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.37417984008789,
    MAXIMO: 44.43299865722656,
    MEDIA: 44.42393001737984,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2ca3d9c2-3342-43ee-8d51-88bcfe9312b6',
    CIDADE: 'Derrubadas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.04436111450195,
    MAXIMO: 48.2269172668457,
    MEDIA: 47.74362563990326,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c4ce9f1c-25c0-401a-bc15-2d06fa875260',
    CIDADE: 'Dezesseis de Novembro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.603206634521484,
    MAXIMO: 48.468406677246094,
    MEDIA: 48.092752244159726,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebef9001-90a7-4422-be40-db5b704d1c45',
    CIDADE: 'Dilermando de Aguiar',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.929561614990234,
    MAXIMO: 44.12184143066406,
    MEDIA: 44.005135368512846,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5bd0170c-96da-4d77-9661-4295236c84b6',
    CIDADE: 'Dois Irmãos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.06090545654297,
    MAXIMO: 45.06090545654297,
    MEDIA: 45.06090545654297,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '239423d2-c3d6-472e-a9f4-66dc41101b10',
    CIDADE: 'Dois Irmãos das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.3546142578125,
    MAXIMO: 45.95871353149414,
    MEDIA: 45.89002341826591,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7d23653-b0e1-4e2b-8b2e-9f04b08d9c8e',
    CIDADE: 'Dois Lajeados',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.49784851074219,
    MAXIMO: 44.53764724731445,
    MEDIA: 44.50795323214342,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd6f6c262-08a9-4cda-8af6-53b5111456e0',
    CIDADE: 'Dom Feliciano',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.6678581237793,
    MAXIMO: 44.928348541259766,
    MEDIA: 44.81870651245117,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b1f235f-3f2a-45d7-ae80-37799714ca84',
    CIDADE: 'Dom Pedro de Alcântara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.28578567504883,
    MAXIMO: 45.397029876708984,
    MEDIA: 45.347133924197244,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4345b72b-de4c-4018-a572-5b102efa298c',
    CIDADE: 'Dom Pedrito',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.48854064941406,
    MAXIMO: 43.77028274536133,
    MEDIA: 43.58664884065327,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6689a682-43a9-45fc-9b71-8f5ffd803c78',
    CIDADE: 'Dona Francisca',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.099002838134766,
    MAXIMO: 44.13628005981445,
    MEDIA: 44.10986940440633,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f6a9c86-cf51-4a56-a0d5-7fc18222d873',
    CIDADE: 'Doutor Maurício Cardoso',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.80780029296875,
    MAXIMO: 48.639617919921875,
    MEDIA: 48.30585021562537,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd725435e-e16e-4569-ae2a-d8acddc4b018',
    CIDADE: 'Doutor Ricardo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.44805145263672,
    MAXIMO: 44.53153610229492,
    MEDIA: 44.498108280610346,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a9c6ed8-35b5-47bb-a104-b5e0b1e4fd43',
    CIDADE: 'Eldorado do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.06060791015625,
    MAXIMO: 45.14658737182617,
    MEDIA: 45.10359764099121,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a7bffc8a-57dd-4777-8c35-1df5263ef143',
    CIDADE: 'Encantado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.47125244140625,
    MAXIMO: 44.608131408691406,
    MEDIA: 44.572499801260044,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0e8c001-c2eb-41bb-8a5b-8ad429f1524f',
    CIDADE: 'Encruzilhada do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.277713775634766,
    MAXIMO: 44.62356185913086,
    MEDIA: 44.432546615600586,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9f27af1-d529-43b1-9527-d9ec39867454',
    CIDADE: 'Engenho Velho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.85322189331055,
    MAXIMO: 45.20620346069336,
    MEDIA: 44.999457393078494,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e534de2-f31b-4822-89be-3386905715af',
    CIDADE: 'Entre-Ijuís',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.4151725769043,
    MAXIMO: 46.120155334472656,
    MEDIA: 45.68177998994124,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4ea60cc0-9c05-4816-9efa-89b6b183f917',
    CIDADE: 'Entre Rios do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.823612213134766,
    MAXIMO: 45.16249084472656,
    MEDIA: 44.92166210840135,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a9d7c96-ece0-48e7-bacf-1f412d71a3fe',
    CIDADE: 'Erebango',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40845489501953,
    MAXIMO: 44.510738372802734,
    MEDIA: 44.48647839398631,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'afe76610-7c89-4eea-8841-324189148652',
    CIDADE: 'Erechim',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.43240737915039,
    MAXIMO: 44.618080139160156,
    MEDIA: 44.48940738546144,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c014ddb-aaea-4bd5-a6a6-91dd250eb96e',
    CIDADE: 'Ernestina',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.37883377075195,
    MAXIMO: 44.46055221557617,
    MEDIA: 44.39355894744937,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '23237020-d2af-4953-bc4d-5e195fffaa6d',
    CIDADE: 'Herval',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.93815612792969,
    MAXIMO: 44.53561019897461,
    MEDIA: 44.225974559783936,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f0bb65b6-4c51-4459-a9d4-49e8e1ebb319',
    CIDADE: 'Erval Grande',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.75556564331055,
    MAXIMO: 44.848148345947266,
    MEDIA: 44.801856994628906,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ccde2b75-3037-4594-a267-121b2f6e0c13',
    CIDADE: 'Erval Seco',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.67521286010742,
    MAXIMO: 47.04436111450195,
    MEDIA: 46.25041490474531,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b536a6a9-facf-4027-a06d-7dc6a11bf3cc',
    CIDADE: 'Esmeralda',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.325565338134766,
    MAXIMO: 44.38861846923828,
    MEDIA: 44.36267344156901,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6e57b445-afbd-49cf-8b36-7c78f3a0dbfc',
    CIDADE: 'Esperança do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.430519104003906,
    MAXIMO: 48.23212814331055,
    MEDIA: 47.83353298196188,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93c09916-a5fd-41dd-8e90-ad3fdb155a87',
    CIDADE: 'Espumoso',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.28277587890625,
    MAXIMO: 44.32269287109375,
    MEDIA: 44.302734375,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29d61757-3a3e-4181-8d0e-94ef660ff951',
    CIDADE: 'Estação',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40845489501953,
    MAXIMO: 44.510738372802734,
    MEDIA: 44.434859754231525,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6af83223-134f-4890-9713-1736fec82ce4',
    CIDADE: 'Estância Velha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.98099136352539,
    MAXIMO: 45.06090545654297,
    MEDIA: 44.992005659730154,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee4025df-72a5-4d7b-90ec-fb61e1ee214c',
    CIDADE: 'Esteio',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.05891418457031,
    MAXIMO: 45.25271987915039,
    MEDIA: 45.13023644228863,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '03825717-6942-4f72-abe3-9612aada025e',
    CIDADE: 'Estrela',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.566200256347656,
    MAXIMO: 44.724327087402344,
    MEDIA: 44.67215438530988,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9969b715-3768-41af-9cf3-0fe27c40e443',
    CIDADE: 'Estrela Velha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.15296173095703,
    MAXIMO: 44.2360725402832,
    MEDIA: 44.199423633538075,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5f2e7c18-692d-4a02-8113-e3851e51c4f5',
    CIDADE: 'Eugênio de Castro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.4151725769043,
    MAXIMO: 45.43866729736328,
    MEDIA: 45.42691993713379,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15881a80-7c06-41e4-86b4-c005c9cff383',
    CIDADE: 'Fagundes Varela',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.490203857421875,
    MAXIMO: 44.58185958862305,
    MEDIA: 44.52346174694422,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d3acddc-c2a0-4277-ba05-bb66895528b9',
    CIDADE: 'Farroupilha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.69151306152344,
    MAXIMO: 44.777549743652344,
    MEDIA: 44.73453140258789,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47bf8ae5-ed56-48e5-9a4f-19906c2338a1',
    CIDADE: 'Faxinal do Soturno',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.08095932006836,
    MAXIMO: 44.13628005981445,
    MEDIA: 44.09371975194409,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd5cd946-4c7d-42d7-8cad-cf0c7e7f3c7a',
    CIDADE: 'Faxinalzinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.75556564331055,
    MAXIMO: 45.05030822753906,
    MEDIA: 45.004609674482005,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfb10b9a-fd57-4e9c-b4e9-58535918e7ac',
    CIDADE: 'Fazenda Vilanova',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.724327087402344,
    MAXIMO: 44.76531219482422,
    MEDIA: 44.72833804925587,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f70de2e-9bc3-4144-9ba3-88f8f32638e4',
    CIDADE: 'Feliz',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.840476989746094,
    MAXIMO: 44.98099136352539,
    MEDIA: 44.90140231960214,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35d6e095-538b-4745-8614-fc60ca5130de',
    CIDADE: 'Flores da Cunha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.679996490478516,
    MAXIMO: 44.80378341674805,
    MEDIA: 44.7206744164111,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09135b26-f581-4ebd-9f35-19012d42abb2',
    CIDADE: 'Floriano Peixoto',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.31268310546875,
    MAXIMO: 44.43240737915039,
    MEDIA: 44.36612387372849,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a13ac39-f6c1-4f45-9266-4fc69f944c86',
    CIDADE: 'Fontoura Xavier',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.350372314453125,
    MAXIMO: 44.368167877197266,
    MEDIA: 44.359270095825195,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'df8cb3ce-8ae7-42a9-9b52-553cc952d492',
    CIDADE: 'Formigueiro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.00950622558594,
    MAXIMO: 44.04696273803711,
    MEDIA: 44.02823448181152,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1a9b5f71-602b-4977-9c7a-0327a0e44298',
    CIDADE: 'Forquetinha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.4713249206543,
    MAXIMO: 44.566200256347656,
    MEDIA: 44.53035835987478,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ab839f32-ac28-4601-944e-2be793a5c394',
    CIDADE: 'Fortaleza dos Valos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.27552795410156,
    MAXIMO: 44.337432861328125,
    MEDIA: 44.3011105855306,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f03c5f91-4576-407c-aa87-2b3d537ec8d3',
    CIDADE: 'Frederico Westphalen',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.97792053222656,
    MAXIMO: 46.67139434814453,
    MEDIA: 46.32166420972445,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b26bf832-f598-4f15-85e4-1f274dd0b5c5',
    CIDADE: 'Garibaldi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.58097839355469,
    MAXIMO: 44.71853256225586,
    MEDIA: 44.71071747556774,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79e1128b-7920-4049-8f45-96e98a53c6c7',
    CIDADE: 'Garruchos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 49.20619201660156,
    MAXIMO: 49.241146087646484,
    MEDIA: 49.22366905212402,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a06c997-42d7-4992-a207-6fa8b66f4dcd',
    CIDADE: 'Gaurama',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.38267517089844,
    MAXIMO: 44.49693298339844,
    MEDIA: 44.416020845985415,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd34db92-6c5b-442a-9b0d-da34422f3cef',
    CIDADE: 'General Câmara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.6530647277832,
    MAXIMO: 44.937870025634766,
    MEDIA: 44.77380201549835,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77bffefe-988c-4729-81c2-68892cf5f6ce',
    CIDADE: 'Gentil',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.39457702636719,
    MAXIMO: 44.42578887939453,
    MEDIA: 44.40098648711722,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3fb1d573-8255-4dfd-8b14-31d087130cf7',
    CIDADE: 'Getúlio Vargas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.367523193359375,
    MAXIMO: 44.43240737915039,
    MEDIA: 44.41735726352699,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8e2ea75-ebd0-4b11-9471-0a5c77cb115b',
    CIDADE: 'Giruá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.521522521972656,
    MAXIMO: 46.947601318359375,
    MEDIA: 46.734561920166016,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f837e032-2149-4166-b9e2-a3fbbee4a80c',
    CIDADE: 'Glorinha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.245784759521484,
    MAXIMO: 45.43913650512695,
    MEDIA: 45.39809295051255,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ad996ed8-02d4-4a0d-af90-c57a3c3b23b2',
    CIDADE: 'Gramado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9629020690918,
    MAXIMO: 45.10675048828125,
    MEDIA: 45.015622100803384,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac2fcd14-27ac-4632-9e34-2a5019ca1ab0',
    CIDADE: 'Gramado dos Loureiros',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.16249084472656,
    MAXIMO: 45.650550842285156,
    MEDIA: 45.24712985621443,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd8c11ee7-4c3f-4901-8873-f3ccc49d0cd9',
    CIDADE: 'Gramado Xavier',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.28047180175781,
    MAXIMO: 44.33755874633789,
    MEDIA: 44.313963887448885,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '29e4e468-31b7-4104-ba87-120cbecd506f',
    CIDADE: 'Gravataí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.25271987915039,
    MAXIMO: 45.354652404785156,
    MEDIA: 45.30368614196777,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd74b06b-2566-47c4-b898-a85d26ba5dd6',
    CIDADE: 'Guabiju',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.43299865722656,
    MAXIMO: 44.522953033447266,
    MEDIA: 44.49163127602693,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '379c540c-b7a7-4d71-b7e5-7c691f55dbd8',
    CIDADE: 'Guaíba',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.06060791015625,
    MAXIMO: 45.33464813232422,
    MEDIA: 45.164514456839086,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5079838d-0344-4924-a625-f9fa9e4ef70e',
    CIDADE: 'Guaporé',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.42036819458008,
    MAXIMO: 44.53764724731445,
    MEDIA: 44.471886003472264,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '929cac61-f1ac-4393-af92-225e31e7d60c',
    CIDADE: 'Guarani das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.90571975708008,
    MAXIMO: 47.29676055908203,
    MEDIA: 47.101240158081055,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9b672a53-e819-4d1c-a690-fd9106d23074',
    CIDADE: 'Harmonia',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.840476989746094,
    MAXIMO: 44.90524673461914,
    MEDIA: 44.89546288684148,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5e571556-78ef-4b6e-9393-60d8ff8b89f4',
    CIDADE: 'Herveiras',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.2795524597168,
    MAXIMO: 44.33755874633789,
    MEDIA: 44.28860185956057,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1d0852b0-cb3e-4143-b67d-6e902074546b',
    CIDADE: 'Horizontina',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.39850997924805,
    MAXIMO: 48.22966384887695,
    MEDIA: 47.849946576792775,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fa824fb1-fc24-4e74-991d-a3dc80ddc95b',
    CIDADE: 'Hulha Negra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.6497802734375,
    MAXIMO: 43.65910720825195,
    MEDIA: 43.65389506022135,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b16ee037-7f58-4e3c-a55c-0999206d7017',
    CIDADE: 'Humaitá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.6314811706543,
    MAXIMO: 47.420265197753906,
    MEDIA: 47.10133903046016,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0cccd545-1a98-4ed5-ab7f-2b66d97b7b32',
    CIDADE: 'Ibarama',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.13628005981445,
    MAXIMO: 44.2044563293457,
    MEDIA: 44.158671098309036,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f1e09e9b-3a7d-4b87-8e15-83d02b50e11f',
    CIDADE: 'Ibiaçá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.27618408203125,
    MAXIMO: 44.358924865722656,
    MEDIA: 44.34102857316057,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8f933ed6-eeed-4c98-b236-560958734f25',
    CIDADE: 'Ibiraiaras',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.37417984008789,
    MAXIMO: 44.485069274902344,
    MEDIA: 44.418197116758925,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c13c6cf5-defd-4f22-9dca-a7cd35d11336',
    CIDADE: 'Ibirapuitã',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.36453628540039,
    MAXIMO: 44.365638732910156,
    MEDIA: 44.36508750915527,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a516ad12-1e55-46cf-ab15-376c50305196',
    CIDADE: 'Ibirubá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.38970184326172,
    MAXIMO: 44.503238677978516,
    MEDIA: 44.43597284952799,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0f16837-b8e7-491f-b761-7d85ce538c56',
    CIDADE: 'Igrejinha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.041099548339844,
    MAXIMO: 45.21976089477539,
    MEDIA: 45.19420668103897,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '389944be-7e29-47d1-a76c-9abc33f4ace8',
    CIDADE: 'Ijuí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.88350296020508,
    MAXIMO: 45.45781707763672,
    MEDIA: 45.14360427856445,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a5431bc-f00c-4d7c-8d9d-114d9b29bfcf',
    CIDADE: 'Ilópolis',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.38191604614258,
    MAXIMO: 44.44805145263672,
    MEDIA: 44.41872826565836,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5321cb4b-e249-4f91-aa9c-f740454f4122',
    CIDADE: 'Imbé',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.63949203491211,
    MAXIMO: 45.63949203491211,
    MEDIA: 45.63949203491211,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6f9c71b0-05b8-4b2e-bd33-f62284213841',
    CIDADE: 'Imigrante',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.608131408691406,
    MAXIMO: 44.708778381347656,
    MEDIA: 44.66309252697953,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c85ab793-94ad-4eb4-915e-e78b2ab376f8',
    CIDADE: 'Independência',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.54740524291992,
    MAXIMO: 46.97153091430664,
    MEDIA: 46.75946807861328,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '292be639-b2a3-4ad1-85d9-1c9cca342614',
    CIDADE: 'Inhacorá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.1756706237793,
    MAXIMO: 46.97153091430664,
    MEDIA: 46.388111941576334,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9439f56c-7846-4aac-938e-7e8a8ee7a74f',
    CIDADE: 'Ipê',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.56623077392578,
    MAXIMO: 44.60938262939453,
    MEDIA: 44.58819580078125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c56d1780-1508-4f4f-bb5d-9964d8acfd8e',
    CIDADE: 'Ipiranga do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.467018127441406,
    MAXIMO: 44.5275764465332,
    MEDIA: 44.48408487313288,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6da2cdb9-5d62-42b9-853d-f7a840bbc777',
    CIDADE: 'Iraí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.97792053222656,
    MAXIMO: 46.6419677734375,
    MEDIA: 46.17088551433752,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fbe6dea-f57a-4fc8-aa2a-68d3e0fb81da',
    CIDADE: 'Itaara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.068702697753906,
    MAXIMO: 44.15470886230469,
    MEDIA: 44.07975400971484,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1ff4b5b2-ef6c-4502-a07e-51aa9b733c12',
    CIDADE: 'Itacurubi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.13559341430664,
    MAXIMO: 47.361263275146484,
    MEDIA: 46.66174163818359,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '199ca0ec-756e-431a-ba7e-7b5e56493118',
    CIDADE: 'Itapuca',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.35556411743164,
    MAXIMO: 44.42036819458008,
    MEDIA: 44.38212154331351,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '510524db-bbf7-469d-8f47-b285756fd47e',
    CIDADE: 'Itaqui',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.93561935424805,
    MAXIMO: 49.454689025878906,
    MEDIA: 48.451940363103695,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0426e3c9-9bf8-486c-86c6-a81398be896e',
    CIDADE: 'Itati',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.18245315551758,
    MAXIMO: 45.452144622802734,
    MEDIA: 45.343617998934675,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b6d1d3b-216d-4a53-b50b-96092ae9aa50',
    CIDADE: 'Itatiba do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.618080139160156,
    MAXIMO: 44.96358871459961,
    MEDIA: 44.774693752811665,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '564603fd-4d90-4bdf-8446-27a45fa35652',
    CIDADE: 'Ivorá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.068702697753906,
    MAXIMO: 44.133995056152344,
    MEDIA: 44.11302149433876,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a2e2dea-8eb2-42b4-bef5-721386c2be71',
    CIDADE: 'Ivoti',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.98099136352539,
    MAXIMO: 45.06090545654297,
    MEDIA: 45.02107809781687,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '68d25960-bc19-497a-9464-3f069b9f69f8',
    CIDADE: 'Jaboticaba',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.42662048339844,
    MAXIMO: 45.99693298339844,
    MEDIA: 45.53957244531284,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd598351-4732-4451-9c7a-78f7938f8f38',
    CIDADE: 'Jacuizinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.21942138671875,
    MAXIMO: 44.28277587890625,
    MEDIA: 44.2585355771007,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbcd817e-71c8-44d3-a665-caa16f00c18c',
    CIDADE: 'Jacutinga',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.510738372802734,
    MAXIMO: 44.716556549072266,
    MEDIA: 44.61513587801058,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9e7e66b-568b-4b2c-811b-4779845a48a5',
    CIDADE: 'Jaguarão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.20247268676758,
    MAXIMO: 45.51976013183594,
    MEDIA: 44.7297248840332,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8136f8c8-6593-4071-95fb-3c35dca2aa67',
    CIDADE: 'Jaguari',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.35234451293945,
    MAXIMO: 44.435333251953125,
    MEDIA: 44.39383888244629,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e1a7b7d3-eb8b-4b38-b60d-809cbb1e0247',
    CIDADE: 'Jaquirana',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9018669128418,
    MAXIMO: 45.06832504272461,
    MEDIA: 44.98344802856445,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e82a27ef-3f1f-4557-b0ad-6fe131699232',
    CIDADE: 'Jari',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.36842346191406,
    MAXIMO: 44.437557220458984,
    MEDIA: 44.40299034118652,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c6df9bb1-cb28-4a2e-8038-5ab606987b9d',
    CIDADE: 'Jóia',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.75031280517578,
    MAXIMO: 45.163883209228516,
    MEDIA: 44.97605895996094,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '05393f10-33a7-47a5-8847-9f390333578f',
    CIDADE: 'Júlio de Castilhos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.1326904296875,
    MAXIMO: 44.29488754272461,
    MEDIA: 44.20964540754046,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '13f4ec18-da6c-493a-8299-2df1473777c6',
    CIDADE: 'Lagoa Bonita do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.15296173095703,
    MAXIMO: 44.18229675292969,
    MEDIA: 44.1793690776858,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee9d3a5c-6213-4c3c-b4bb-737da763cab2',
    CIDADE: 'Lagoão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.2480583190918,
    MAXIMO: 44.28047180175781,
    MEDIA: 44.264265060424805,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd2c14a49-cc5e-4218-9cac-a2fcc0628c99',
    CIDADE: 'Lagoa dos Três Cantos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.36039352416992,
    MAXIMO: 44.4361686706543,
    MEDIA: 44.38607229370719,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1e16ee45-cd00-45d7-b3e1-a4bb418f688a',
    CIDADE: 'Lagoa Vermelha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.25697326660156,
    MAXIMO: 44.50447082519531,
    MEDIA: 44.39354133605957,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '67254a0c-cf10-4b1e-b182-b70d616639be',
    CIDADE: 'Lajeado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.566200256347656,
    MAXIMO: 44.652462005615234,
    MEDIA: 44.583959612137,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7394299-1332-497c-b0df-591c21b58860',
    CIDADE: 'Lajeado do Bugre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.16412353515625,
    MAXIMO: 45.42662048339844,
    MEDIA: 45.39145049397205,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a03128c8-a337-4109-a02e-53fe9e66708f',
    CIDADE: 'Lavras do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.568973541259766,
    MAXIMO: 43.746620178222656,
    MEDIA: 43.64634847640991,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '20f9823d-7014-4420-90ce-dd26a96ecb26',
    CIDADE: 'Liberato Salzano',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.00334548950195,
    MAXIMO: 45.698692321777344,
    MEDIA: 45.35791331247436,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '402ceafe-0413-4f4a-a00c-ed0c3c22bcf0',
    CIDADE: 'Lindolfo Collor',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.98099136352539,
    MAXIMO: 44.98099136352539,
    MEDIA: 44.98099136352539,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8cc4fd58-555d-48ab-b78e-0577dea42cc7',
    CIDADE: 'Linha Nova',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9102783203125,
    MAXIMO: 44.9102783203125,
    MEDIA: 44.9102783203125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '62ff0c05-5724-42e5-a4e3-3e9368a5cbe3',
    CIDADE: 'Machadinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.16399002075195,
    MAXIMO: 44.24427032470703,
    MEDIA: 44.22524624361903,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '610b415c-9809-47cb-b0ca-5d4bb118f756',
    CIDADE: 'Maçambará',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.959022521972656,
    MAXIMO: 48.42720031738281,
    MEDIA: 47.42383702596029,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c283d9c-990e-4485-9911-7cd813fbd051',
    CIDADE: 'Mampituba',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.234066009521484,
    MAXIMO: 45.28578567504883,
    MEDIA: 45.26229376765162,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9961f71a-5f73-4d2c-a496-110c389ab945',
    CIDADE: 'Manoel Viana',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.415157318115234,
    MAXIMO: 46.54327392578125,
    MEDIA: 45.98109893798828,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6fec94b7-4cc2-4bd5-8019-e76f3115fb92',
    CIDADE: 'Maquiné',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.38398361206055,
    MAXIMO: 45.512855529785156,
    MEDIA: 45.44841957092285,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '92779816-1463-4077-b6e8-5373be218ebe',
    CIDADE: 'Maratá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.77471923828125,
    MAXIMO: 44.834842681884766,
    MEDIA: 44.81706617817857,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9b39cc7d-745d-4ed3-a844-9485ebdee41d',
    CIDADE: 'Marau',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.3741569519043,
    MAXIMO: 44.37883377075195,
    MEDIA: 44.376495361328125,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ca8c9b30-f279-488d-9444-6aa1b61294af',
    CIDADE: 'Marcelino Ramos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.32655715942383,
    MAXIMO: 44.42488098144531,
    MEDIA: 44.35946490960951,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7b3dc761-5278-48a0-aace-ada8fbb91ad1',
    CIDADE: 'Mariana Pimentel',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.97836685180664,
    MAXIMO: 45.32610321044922,
    MEDIA: 45.15296549662597,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5fb475fd-414d-4eb4-8c7f-5734f13024c5',
    CIDADE: 'Mariano Moro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.42488098144531,
    MAXIMO: 44.53813171386719,
    MEDIA: 44.514968405474754,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'efe42fe7-fe41-4ec8-8605-a2a09b84d44c',
    CIDADE: 'Marques de Souza',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.41049575805664,
    MAXIMO: 44.566200256347656,
    MEDIA: 44.500016656308176,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '66102c87-e53d-412d-a84e-b2bc363ec5fc',
    CIDADE: 'Mata',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.16545867919922,
    MAXIMO: 44.35234451293945,
    MEDIA: 44.26339735338974,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '511ea7c4-458d-45a1-95a8-f4643dbdfca8',
    CIDADE: 'Mato Castelhano',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.37841033935547,
    MAXIMO: 44.39797592163086,
    MEDIA: 44.381920679552714,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9e6caeb5-c5aa-47e9-954c-cd4da33291c3',
    CIDADE: 'Mato Leitão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.493568420410156,
    MAXIMO: 44.602169036865234,
    MEDIA: 44.54349291450634,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3783f1d0-84a1-4ea5-88a6-f1a691543a7a',
    CIDADE: 'Mato Queimado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.4771728515625,
    MAXIMO: 47.6962890625,
    MEDIA: 46.90458468173865,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3711cffa-2d2d-48ab-85dc-80d5ca3c2487',
    CIDADE: 'Maximiliano de Almeida',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.23280334472656,
    MAXIMO: 44.33274459838867,
    MEDIA: 44.28911666367595,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '612e46bc-e2c4-43a9-ad4a-1500e5feabc9',
    CIDADE: 'Minas do Leão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.62344741821289,
    MAXIMO: 44.85967254638672,
    MEDIA: 44.741306948251015,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3b3ff93c-2a6c-4f75-9d08-dc7f6f6d281d',
    CIDADE: 'Miraguaí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.6644401550293,
    MAXIMO: 47.03294372558594,
    MEDIA: 46.78670861775177,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '25274d28-9726-4b4e-b9f1-e9c440f40c4e',
    CIDADE: 'Montauri',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.416175842285156,
    MAXIMO: 44.42036819458008,
    MEDIA: 44.416217726954066,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '131a2da5-f15c-46bb-9069-1f07ac6a2b80',
    CIDADE: 'Monte Alegre dos Campos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.67570495605469,
    MAXIMO: 44.717384338378906,
    MEDIA: 44.6965446472168,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '944f6288-ce17-4a57-aa97-c4b442b6c01f',
    CIDADE: 'Monte Belo do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.58097839355469,
    MAXIMO: 44.636436462402344,
    MEDIA: 44.60362951814276,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cdf4f848-cbad-45ac-9789-c63efb7b2cd9',
    CIDADE: 'Montenegro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.90592575073242,
    MAXIMO: 44.97840881347656,
    MEDIA: 44.94216728210449,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db4eac2c-3e8a-45cd-8407-08a6aeb83aee',
    CIDADE: 'Mormaço',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.31896209716797,
    MAXIMO: 44.36453628540039,
    MEDIA: 44.346044074292315,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ab0df80-0cea-4a01-baeb-dc4456b317a9',
    CIDADE: 'Morrinhos do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.234066009521484,
    MAXIMO: 45.39249038696289,
    MEDIA: 45.31599425121073,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd67c4a9-0842-48c4-b5c9-c2af9fa3cbff',
    CIDADE: 'Morro Redondo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.697017669677734,
    MAXIMO: 45.02647399902344,
    MEDIA: 44.831476703062684,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6a8cce7f-1153-492a-bfbe-39e06a99b8fa',
    CIDADE: 'Morro Reuter',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.97659683227539,
    MAXIMO: 45.14378356933594,
    MEDIA: 45.0310803872999,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a0d59e58-0d7c-4d0b-a68d-70855235ec06',
    CIDADE: 'Mostardas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.94361877441406,
    MAXIMO: 46.33017349243164,
    MEDIA: 46.09636960710798,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2a66536d-3ddc-4519-9412-46a3888d3b13',
    CIDADE: 'Muçum',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.53153610229492,
    MAXIMO: 44.58097839355469,
    MEDIA: 44.555799229668644,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a69f02a1-5b80-4d30-bb36-47ed6cfecd84',
    CIDADE: 'Muitos Capões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.43606185913086,
    MAXIMO: 44.51491165161133,
    MEDIA: 44.475486755371094,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '09111bbe-adc7-4f25-8ca6-88f7d9f4e52e',
    CIDADE: 'Muliterno',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.37417984008789,
    MAXIMO: 44.39760971069336,
    MEDIA: 44.38007321374088,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ebdb61d1-92c3-44d0-aeda-e14820255ade',
    CIDADE: 'Não-Me-Toque',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40469741821289,
    MAXIMO: 44.4361686706543,
    MEDIA: 44.420433044433594,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c40d75ec-39f0-4e3e-a1dc-052fc55303af',
    CIDADE: 'Nicolau Vergueiro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.36453628540039,
    MAXIMO: 44.38246154785156,
    MEDIA: 44.37781574023382,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4d18f391-694c-4b37-913a-9a8b06c1161a',
    CIDADE: 'Nonoai',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.93135070800781,
    MAXIMO: 45.698692321777344,
    MEDIA: 45.25400786837085,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c456990c-9390-4e6e-bc6a-cb58131fc773',
    CIDADE: 'Nova Alvorada',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.3698844909668,
    MAXIMO: 44.42036819458008,
    MEDIA: 44.384050618172026,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '68b24370-3d6e-4d61-8e4b-2f99b67eacef',
    CIDADE: 'Nova Araçá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.446502685546875,
    MAXIMO: 44.49140930175781,
    MEDIA: 44.48472599332577,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed5ad650-53d8-4349-83c9-c23bdec6719d',
    CIDADE: 'Nova Bassano',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.446502685546875,
    MAXIMO: 44.49140930175781,
    MEDIA: 44.483947669676816,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '21e05acf-56a1-42d9-8480-d348833970e5',
    CIDADE: 'Nova Boa Vista',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.71641159057617,
    MAXIMO: 44.817569732666016,
    MEDIA: 44.794024214589726,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ceb438b-85c6-4578-9c3c-fe7fb4f75fda',
    CIDADE: 'Nova Bréscia',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.47125244140625,
    MAXIMO: 44.608131408691406,
    MEDIA: 44.53153646696059,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a6929f30-7332-45c2-8b91-3f0e2b36e915',
    CIDADE: 'Nova Candelária',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.00554275512695,
    MAXIMO: 47.82236099243164,
    MEDIA: 47.36191928088242,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8403b132-feac-4489-a321-9e18afa8a51d',
    CIDADE: 'Nova Esperança do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.435333251953125,
    MAXIMO: 44.85000991821289,
    MEDIA: 44.58761438870099,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'eb5d8dde-b613-4b71-af9b-fa6dbdf5ee6d',
    CIDADE: 'Nova Hartz',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.14378356933594,
    MAXIMO: 45.14378356933594,
    MEDIA: 45.14378356933594,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63c39e7b-10b1-49e1-b2fb-7b351da158ca',
    CIDADE: 'Nova Pádua',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.62879180908203,
    MAXIMO: 44.75141525268555,
    MEDIA: 44.666407232078654,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4791f6ec-ae4e-4a44-8ea8-d13344a7a495',
    CIDADE: 'Nova Palma',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.08095932006836,
    MAXIMO: 44.192237854003906,
    MEDIA: 44.137385771853374,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '33f0d4d5-267b-459c-8adf-91ded87068f8',
    CIDADE: 'Nova Petrópolis',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.84040069580078,
    MAXIMO: 45.041099548339844,
    MEDIA: 44.93672447877109,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b793bd25-d4f2-4725-9dc6-92632b3fb66b',
    CIDADE: 'Nova Prata',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.490203857421875,
    MAXIMO: 44.527889251708984,
    MEDIA: 44.517819598934985,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e331af5a-cee0-46aa-bf1c-35826051e9af',
    CIDADE: 'Nova Ramada',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.00994873046875,
    MAXIMO: 45.837215423583984,
    MEDIA: 45.35354171617522,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d27d422-6121-4c8c-ba5a-9262f810d852',
    CIDADE: 'Nova Roma do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.62879180908203,
    MAXIMO: 44.69151306152344,
    MEDIA: 44.63895763103124,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b53cb1b0-7136-4382-88b2-a8655027e69f',
    CIDADE: 'Nova Santa Rita',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.97840881347656,
    MAXIMO: 45.14076232910156,
    MEDIA: 45.071162218178955,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '944dd21b-ff2d-4bd1-a3cb-ce5f867cf594',
    CIDADE: 'Novo Cabrais',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.16547393798828,
    MAXIMO: 44.22942352294922,
    MEDIA: 44.177706857744205,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6136ce9-74a5-4fda-8a36-f574d0f61d63',
    CIDADE: 'Novo Hamburgo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.98099136352539,
    MAXIMO: 45.245784759521484,
    MEDIA: 45.158067296985585,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '447bb9f0-dbe1-48b4-a1f1-0e336285a19b',
    CIDADE: 'Novo Machado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.223297119140625,
    MAXIMO: 49.05203628540039,
    MEDIA: 48.59461906041588,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7bd18ef9-b53f-4e65-a569-7f16296061b2',
    CIDADE: 'Novo Tiradentes',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.20620346069336,
    MAXIMO: 45.698692321777344,
    MEDIA: 45.5669268170336,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '083cee49-ebe7-4ad1-9a12-16fe5601a284',
    CIDADE: 'Novo Xingu',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.00040817260742,
    MAXIMO: 45.20620346069336,
    MEDIA: 45.0686365257712,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '560d7e41-313e-409f-9fde-3372c9562ff5',
    CIDADE: 'Novo Barreiro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.817569732666016,
    MAXIMO: 45.16412353515625,
    MEDIA: 44.949224384053736,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3bba8a73-f7b9-4848-9c36-ebac305f55dd',
    CIDADE: 'Osório',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.43842315673828,
    MAXIMO: 45.717281341552734,
    MEDIA: 45.57778209454057,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '585efaa5-50f9-4598-ba27-956186cc1584',
    CIDADE: 'Paim Filho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.24427032470703,
    MAXIMO: 44.32655715942383,
    MEDIA: 44.274899543434174,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2026a925-923c-4b12-8562-ae7679207b5e',
    CIDADE: 'Palmares do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.771392822265625,
    MAXIMO: 45.92430114746094,
    MEDIA: 45.84784698486328,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a433c87-9453-4942-b0a5-6b7983e7ba71',
    CIDADE: 'Palmeira das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.03750991821289,
    MAXIMO: 45.3546142578125,
    MEDIA: 45.2208251953125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42f3c2a1-fe89-4bde-9277-81ba9041fefa',
    CIDADE: 'Palmitinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.67139434814453,
    MAXIMO: 47.04436111450195,
    MEDIA: 46.79903649315402,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8811e951-9685-4a74-9b12-f3c7d513fd95',
    CIDADE: 'Panambi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.569454193115234,
    MAXIMO: 45.00994873046875,
    MEDIA: 44.7739398297525,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72fd9ceb-c5fb-4d5d-adc9-a747265d63ee',
    CIDADE: 'Pantano Grande',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.58614730834961,
    MAXIMO: 44.75297927856445,
    MEDIA: 44.680642445882164,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f0362e6-af8a-495e-9c25-65901607e8c8',
    CIDADE: 'Paraí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.42578887939453,
    MAXIMO: 44.49140930175781,
    MEDIA: 44.475908177541015,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85e43e83-4475-4d0d-a3b9-235b4cfd6046',
    CIDADE: 'Paraíso do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.11104202270508,
    MAXIMO: 44.17146682739258,
    MEDIA: 44.14081244878063,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f6d928d7-4c38-454b-bf69-3137b7971690',
    CIDADE: 'Pareci Novo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.90524673461914,
    MAXIMO: 44.90524673461914,
    MEDIA: 44.90524673461914,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a45c0b62-01f9-4ed6-b7ac-31db3ce5f030',
    CIDADE: 'Parobé',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.14378356933594,
    MAXIMO: 45.32978820800781,
    MEDIA: 45.17782739851607,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e85468bd-e46f-41f6-94a6-1b61cd3c024a',
    CIDADE: 'Passa Sete',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.18229675292969,
    MAXIMO: 44.28047180175781,
    MEDIA: 44.22633973375425,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11496f9a-aa59-48f8-a4b7-de94d95262f9',
    CIDADE: 'Passo do Sobrado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.42852783203125,
    MAXIMO: 44.6530647277832,
    MEDIA: 44.54633930018331,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '581f730d-2aa5-4caa-87e4-d55e140fc6d6',
    CIDADE: 'Passo Fundo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.39797592163086,
    MAXIMO: 44.47275924682617,
    MEDIA: 44.430779774983726,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ba4cb8f8-0b2a-4327-91c2-0ed10e7dcace',
    CIDADE: 'Paulo Bento',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.510738372802734,
    MAXIMO: 44.67599105834961,
    MEDIA: 44.55601194886647,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b3aca397-c8cf-4c38-bedb-f2c4126f39ad',
    CIDADE: 'Paverama',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.652462005615234,
    MAXIMO: 44.834842681884766,
    MEDIA: 44.75889178809062,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17a7a4c2-89c6-44b4-a971-1912a5fc0cba',
    CIDADE: 'Pedras Altas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.73765182495117,
    MAXIMO: 43.99124526977539,
    MEDIA: 43.86435775756836,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b620bdb-ae7e-452e-8f9b-41e0d9b4c846',
    CIDADE: 'Pedro Osório',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.7297248840332,
    MAXIMO: 44.9595832824707,
    MEDIA: 44.84465408325195,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '265140cc-ce5a-41d5-9383-f6194a1ae66e',
    CIDADE: 'Pejuçara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.64968490600586,
    MAXIMO: 44.745296478271484,
    MEDIA: 44.69749069213867,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5c300d83-af0c-4a27-920e-10c2be0d1702',
    CIDADE: 'Pelotas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.77696990966797,
    MAXIMO: 45.47261428833008,
    MEDIA: 45.16199547903879,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '02652ec1-1b9d-4bc0-9e5c-cc20e754f202',
    CIDADE: 'Picada Café',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9102783203125,
    MAXIMO: 44.97659683227539,
    MEDIA: 44.96874461959392,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dff3cdf1-fd7c-4b9f-a32f-d95bc0c6823a',
    CIDADE: 'Pinhal',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.42662048339844,
    MAXIMO: 45.99693298339844,
    MEDIA: 45.68072385946237,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5903a9cf-8e05-4a2b-9652-21d19b29a639',
    CIDADE: 'Pinhal da Serra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.205169677734375,
    MAXIMO: 44.26743698120117,
    MEDIA: 44.23630332946777,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '954ab7d4-43d3-45c9-8502-3c07c9df0c25',
    CIDADE: 'Pinhal Grande',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.13628005981445,
    MAXIMO: 44.2555046081543,
    MEDIA: 44.18014210245987,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '849d199f-1cab-4700-9a7c-c90b3b6b15b7',
    CIDADE: 'Pinheirinho do Vale',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.67139434814453,
    MAXIMO: 47.41960144042969,
    MEDIA: 47.21938708597729,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e16c3bf4-0782-439a-8a87-e40318fcc964',
    CIDADE: 'Pinheiro Machado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.851585388183594,
    MAXIMO: 44.0898551940918,
    MEDIA: 43.956947326660156,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'db98599b-3769-4aea-a1b1-517762e67557',
    CIDADE: 'Pinto Bandeira',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.58185958862305,
    MAXIMO: 44.69151306152344,
    MEDIA: 44.675321494826825,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '85e90b36-acdf-4052-a611-fc9d88dec6bf',
    CIDADE: 'Pirapó',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.468406677246094,
    MAXIMO: 49.299747467041016,
    MEDIA: 48.88147080966917,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c9966b1b-55cc-4e44-a34a-6f79c3234df4',
    CIDADE: 'Piratini',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.1071891784668,
    MAXIMO: 44.419246673583984,
    MEDIA: 44.22975654602051,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c97cd63c-db85-4aee-94bc-4859165e91a8',
    CIDADE: 'Planalto',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.34111785888672,
    MAXIMO: 45.97792053222656,
    MEDIA: 45.743665792168194,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '96015455-431b-4405-bf11-8dc5798f93b9',
    CIDADE: 'Poço das Antas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.708778381347656,
    MAXIMO: 44.77471923828125,
    MEDIA: 44.74323685664698,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9eed006b-879d-44ba-9554-5dafb9af077f',
    CIDADE: 'Pontão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.47275924682617,
    MAXIMO: 44.71641159057617,
    MEDIA: 44.55707265562697,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e59fe744-d500-455b-bef8-7b14ce19dae8',
    CIDADE: 'Ponte Preta',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.56045150756836,
    MAXIMO: 44.67599105834961,
    MEDIA: 44.66756634115275,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4a36de51-5ba9-4424-ab1a-f849f8f26f3a',
    CIDADE: 'Portão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.98099136352539,
    MAXIMO: 45.05891418457031,
    MEDIA: 45.01995277404785,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f3bff74e-3b72-487c-9ed7-f7c34586dd35',
    CIDADE: 'Porto Alegre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.14076232910156,
    MAXIMO: 45.42762756347656,
    MEDIA: 45.28050833908498,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '400fa05c-2ed5-4bbd-9147-d11cdd39158b',
    CIDADE: 'Porto Lucena',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.16511917114258,
    MAXIMO: 49.39018249511719,
    MEDIA: 48.86084688837787,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6bfcf4a-ee8e-4107-b77d-70d100a7e19d',
    CIDADE: 'Porto Mauá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.64902114868164,
    MAXIMO: 49.05203628540039,
    MEDIA: 48.73410848224944,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71761ffa-4109-41b8-abcf-b88208e04569',
    CIDADE: 'Porto Vera Cruz',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.62835693359375,
    MAXIMO: 49.06904983520508,
    MEDIA: 48.867401260911876,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '67abb3e9-516d-4fb5-8d06-af2787c1ce4b',
    CIDADE: 'Porto Xavier',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.56175231933594,
    MAXIMO: 49.72165298461914,
    MEDIA: 49.03105754923955,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49d178f6-192b-47cf-9f65-220f7afe10b7',
    CIDADE: 'Pouso Novo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.41049575805664,
    MAXIMO: 44.4713249206543,
    MEDIA: 44.41464175365024,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4c45f35f-3f30-4dc1-b63d-76d452ec0692',
    CIDADE: 'Presidente Lucena',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9102783203125,
    MAXIMO: 45.06090545654297,
    MEDIA: 44.966347004821685,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '234ef6e3-1270-4859-afa3-a68a62b6a87d',
    CIDADE: 'Progresso',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.368167877197266,
    MAXIMO: 44.4713249206543,
    MEDIA: 44.415341967308706,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cc30a502-4a6c-4ce0-861e-f958e52cafdb',
    CIDADE: 'Protásio Alves',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.522953033447266,
    MAXIMO: 44.56623077392578,
    MEDIA: 44.544047589273084,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bfd99dc9-04b0-4c93-8504-ae66b3840280',
    CIDADE: 'Putinga',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.38191604614258,
    MAXIMO: 44.47125244140625,
    MEDIA: 44.42324235888671,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '7db126e3-ecdf-42ee-8b9b-f95269772896',
    CIDADE: 'Quaraí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.093841552734375,
    MAXIMO: 47.46693420410156,
    MEDIA: 46.00905990600586,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '32fd8859-9334-4738-8dda-7aa72ba4c29a',
    CIDADE: 'Quatro Irmãos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.467018127441406,
    MAXIMO: 44.598575592041016,
    MEDIA: 44.544771558597446,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '11f8005d-053c-464b-b742-0b06d81c37f3',
    CIDADE: 'Quevedos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.23249435424805,
    MAXIMO: 44.29774856567383,
    MEDIA: 44.26512145996094,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64316359-5e50-4df0-9ac3-85a264eebaab',
    CIDADE: 'Quinze de Novembro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.273014068603516,
    MAXIMO: 44.41497802734375,
    MEDIA: 44.33903653198914,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4905c40c-13f3-4949-83c9-f25a9c21ec80',
    CIDADE: 'Redentora',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.95871353149414,
    MAXIMO: 46.6644401550293,
    MEDIA: 46.38292413475844,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '79f8c47c-94f0-47c1-acfc-38406e899d3d',
    CIDADE: 'Relvado',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.41049575805664,
    MAXIMO: 44.47125244140625,
    MEDIA: 44.46843469952556,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '497a952e-870c-499f-9c44-1d95b3580eb4',
    CIDADE: 'Restinga Sêca',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.07393264770508,
    MAXIMO: 44.09034729003906,
    MEDIA: 44.08213996887207,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8b8436f2-dfa8-409e-9ee4-e61b3ba1738e',
    CIDADE: 'Rio dos Índios',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.23057174682617,
    MAXIMO: 45.89842224121094,
    MEDIA: 45.44919108882712,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '27683c72-3d92-4202-9b20-7af5e7266575',
    CIDADE: 'Rio Grande',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.449588775634766,
    MAXIMO: 46.491058349609375,
    MEDIA: 45.96749725341797,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'aa527338-1154-468d-99c7-25addd413c34',
    CIDADE: 'Rio Pardo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.407989501953125,
    MAXIMO: 44.66558837890625,
    MEDIA: 44.521925245012554,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '158d2110-f9bc-4e32-89af-3e7b9fec0db1',
    CIDADE: 'Riozinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.20964431762695,
    MAXIMO: 45.48303985595703,
    MEDIA: 45.32535470229126,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '650e3ff8-928a-406f-9257-2e26927cf8a9',
    CIDADE: 'Roca Sales',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.53153610229492,
    MAXIMO: 44.661590576171875,
    MEDIA: 44.610511345665415,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '952f02bb-9b95-42a4-84cd-f3aaeb738a10',
    CIDADE: 'Rodeio Bonito',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.698692321777344,
    MAXIMO: 45.97792053222656,
    MEDIA: 45.69944227621558,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c0f6bdab-879f-4252-ae69-59d91911c40e',
    CIDADE: 'Rolador',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.21831512451172,
    MAXIMO: 48.08833312988281,
    MEDIA: 47.401440972434926,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '49ec3426-d6b7-4703-9a7b-53cb9bbc5dd6',
    CIDADE: 'Rolante',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.21976089477539,
    MAXIMO: 45.43842315673828,
    MEDIA: 45.302520084152775,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '74d2516a-ea66-4b7b-b50b-d8c3d7541a66',
    CIDADE: 'Ronda Alta',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.5275764465332,
    MAXIMO: 45.00334548950195,
    MEDIA: 44.760759116511565,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ecd4a075-a48e-401e-83cd-8c1cd6ac4b98',
    CIDADE: 'Rondinha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.61609649658203,
    MAXIMO: 45.00040817260742,
    MEDIA: 44.873150271062194,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '005dab08-c5ae-48a7-a995-41fc4bbe7cdb',
    CIDADE: 'Roque Gonzales',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.08833312988281,
    MAXIMO: 49.299747467041016,
    MEDIA: 48.694040298461914,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '40ddbd6d-945b-4f47-872c-09e93ac77f0a',
    CIDADE: 'Rosário do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.75267028808594,
    MAXIMO: 44.803348541259766,
    MEDIA: 44.197593180338544,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0b9c7f79-4d5c-4557-9601-43cf18ffbc46',
    CIDADE: 'Sagrada Família',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.00040817260742,
    MAXIMO: 45.42662048339844,
    MEDIA: 45.303854921236564,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3263eaaa-7998-4c50-80cb-c933a586d095',
    CIDADE: 'Saldanha Marinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.46177673339844,
    MAXIMO: 44.610107421875,
    MEDIA: 44.52072623450686,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '623b3141-1a97-4a26-ab9b-743f7b02f1ea',
    CIDADE: 'Salto do Jacuí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.21942138671875,
    MAXIMO: 44.2291145324707,
    MEDIA: 44.22426795959473,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '82d3f244-2be1-4703-8a3a-a67e7a3eec27',
    CIDADE: 'Salvador das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.6962890625,
    MAXIMO: 48.16511917114258,
    MEDIA: 47.799421933162996,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f71a7760-3e64-4486-8250-e9ce6df8a028',
    CIDADE: 'Salvador do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.77471923828125,
    MAXIMO: 44.840476989746094,
    MEDIA: 44.78350261016166,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fc80ee66-6468-44b1-942c-adc8a5c267b7',
    CIDADE: 'Sananduva',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.29943084716797,
    MAXIMO: 44.33456039428711,
    MEDIA: 44.31699562072754,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e45c5264-c3a0-4494-9aef-d96880830805',
    CIDADE: 'Santa Bárbara do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.569454193115234,
    MAXIMO: 44.694190979003906,
    MEDIA: 44.62458419799805,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd0201fb8-f043-42e2-ba1d-03dceb02078b',
    CIDADE: 'Santa Cecília do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.3389892578125,
    MAXIMO: 44.39760971069336,
    MEDIA: 44.36464993243474,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '63fb0a2c-e386-46b5-9914-10d60d534939',
    CIDADE: 'Santa Clara do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.493568420410156,
    MAXIMO: 44.566200256347656,
    MEDIA: 44.53146969501791,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3ab79d3c-480b-4cb1-ba10-705f31374f81',
    CIDADE: 'Santa Cruz do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.41189956665039,
    MAXIMO: 44.463775634765625,
    MEDIA: 44.43473434448242,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '26f406f4-3b1a-4180-8611-236c4fb335a0',
    CIDADE: 'Santa Maria',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.929561614990234,
    MAXIMO: 44.0761604309082,
    MEDIA: 44.01345729827881,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '671f8820-8b2f-419b-be0c-5daf5f19fd42',
    CIDADE: 'Santa Maria do Herval',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.97659683227539,
    MAXIMO: 45.14378356933594,
    MEDIA: 45.03146666240721,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56e1f0ca-964f-4663-b237-49f7ca24617b',
    CIDADE: 'Santa Margarida do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.748104095458984,
    MAXIMO: 43.79754638671875,
    MEDIA: 43.772482554117836,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c99e013e-0777-493e-b9db-1d3d50bed6ac',
    CIDADE: 'Santana da Boa Vista',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.98231506347656,
    MAXIMO: 44.196231842041016,
    MEDIA: 44.10061581929525,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '420a624f-bbe6-4804-8ab8-75df252314e0',
    CIDADE: "Sant'Ana do Livramento",
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.749046325683594,
    MAXIMO: 45.414207458496094,
    MEDIA: 44.355230255126955,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6048fc1c-7294-4e19-a7fb-01fd90b319ff',
    CIDADE: 'Santa Rosa',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.947601318359375,
    MAXIMO: 48.223297119140625,
    MEDIA: 47.62847749508728,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ac339674-54d6-4e5d-89fa-3d2a4a575e70',
    CIDADE: 'Santa Tereza',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.58097839355469,
    MAXIMO: 44.661590576171875,
    MEDIA: 44.592093577450306,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c06cf94a-49b1-4322-87ff-5caa3070e668',
    CIDADE: 'Santa Vitória do Palmar',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.550498962402344,
    MAXIMO: 49.680023193359375,
    MEDIA: 47.95011880662706,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd60e2549-fe8e-45f2-94e2-c137e806b117',
    CIDADE: 'Santiago',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.52402877807617,
    MAXIMO: 45.646934509277344,
    MEDIA: 44.997995376586914,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e1f7d64-a8b3-488f-a689-20da9077b158',
    CIDADE: 'Santo Ângelo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.76173400878906,
    MAXIMO: 46.4771728515625,
    MEDIA: 46.11968739827474,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '06d02cfb-2bd4-44a1-960d-378d7a3f08de',
    CIDADE: 'Santo Antônio do Palma',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.406715393066406,
    MAXIMO: 44.42578887939453,
    MEDIA: 44.41102986341318,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b697e133-9d1a-4f9e-9227-c9e5bd604f33',
    CIDADE: 'Santo Antônio da Patrulha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.36790084838867,
    MAXIMO: 45.54351043701172,
    MEDIA: 45.47466532389323,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '092e7956-ecae-4169-a2ca-5f4b2b2625e3',
    CIDADE: 'Santo Antônio das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.40571594238281,
    MAXIMO: 48.79751968383789,
    MEDIA: 48.137450626918245,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'dcfb9295-95bc-4c7e-a222-9b6df0816307',
    CIDADE: 'Santo Antônio do Planalto',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.38246154785156,
    MAXIMO: 44.46055221557617,
    MEDIA: 44.43720676045241,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ed6b4185-efe1-47cd-abfb-c9fb0eb7c579',
    CIDADE: 'Santo Augusto',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.54146194458008,
    MAXIMO: 46.23020553588867,
    MEDIA: 45.885833740234375,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd53ab3e3-55db-4a24-a1d6-bec432d5f525',
    CIDADE: 'Santo Cristo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 48.211341857910156,
    MAXIMO: 48.62835693359375,
    MEDIA: 48.41984939575195,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cbacd2a0-dfcc-4d50-a34c-63f5c2f00f8c',
    CIDADE: 'Santo Expedito do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.24164962768555,
    MAXIMO: 44.29943084716797,
    MEDIA: 44.28463142987627,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '694e2cda-4ea5-4833-9f9c-0636a7cc157c',
    CIDADE: 'São Borja',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.88794708251953,
    MAXIMO: 49.18750762939453,
    MEDIA: 48.361170682040125,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '47ddf47f-eefa-4a36-9581-3e3b77620a06',
    CIDADE: 'São Domingos do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.42578887939453,
    MAXIMO: 44.49140930175781,
    MEDIA: 44.43405075981696,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '070183bb-39e2-4ad3-ae69-9605f769dd4e',
    CIDADE: 'São Francisco de Assis',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40238952636719,
    MAXIMO: 45.59115219116211,
    MEDIA: 44.93824424743652,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '30839b74-21ea-4311-8259-79bd14d0827b',
    CIDADE: 'São Francisco de Paula',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.75105667114258,
    MAXIMO: 45.29931640625,
    MEDIA: 44.999629974365234,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56118f1b-8db0-4333-8321-fc8d768f7f38',
    CIDADE: 'São Gabriel',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.62149429321289,
    MAXIMO: 43.972232818603516,
    MEDIA: 43.79671297575298,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'baa238de-7970-48f2-ad2c-0b8689138487',
    CIDADE: 'São Jerônimo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.86037063598633,
    MAXIMO: 44.91331100463867,
    MEDIA: 44.8868408203125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7614c84-7dd4-4da3-84eb-edff3a59c7d3',
    CIDADE: 'São João da Urtiga',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.26192092895508,
    MAXIMO: 44.32655715942383,
    MEDIA: 44.29564815858413,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6d61d82b-b102-4e22-aa82-7bfe7841e191',
    CIDADE: 'São João do Polêsine',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.04586410522461,
    MAXIMO: 44.099002838134766,
    MEDIA: 44.085071200958524,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6512caba-f9b3-491a-8afc-e44d578723e4',
    CIDADE: 'São Jorge',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.43299865722656,
    MAXIMO: 44.49140930175781,
    MEDIA: 44.44054284500107,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a3acf8c8-3eec-4ebb-b776-d66519b28894',
    CIDADE: 'São José das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.00040817260742,
    MAXIMO: 45.16412353515625,
    MEDIA: 45.10816898179273,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cb5afe65-b345-418f-b550-b59280fee58b',
    CIDADE: 'São José do Herval',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.350372314453125,
    MAXIMO: 44.41049575805664,
    MEDIA: 44.39581833717186,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b75d998b-9033-460b-98cd-6f31600873bb',
    CIDADE: 'São José do Hortêncio',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.9102783203125,
    MAXIMO: 44.98099136352539,
    MEDIA: 44.95450438725744,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e245e075-5b3c-4fcc-abc0-f2b6fcad78d6',
    CIDADE: 'São José do Inhacorá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.58988571166992,
    MAXIMO: 47.39850997924805,
    MEDIA: 47.04118960346237,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b4c0a087-ca2a-4c11-8947-7c50c5a73a68',
    CIDADE: 'São José do Norte',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.14724349975586,
    MAXIMO: 46.29962921142578,
    MEDIA: 46.234544118245445,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2b8b5fb0-0f08-4266-806f-2f632ab40862',
    CIDADE: 'São José do Ouro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.209774017333984,
    MAXIMO: 44.24164962768555,
    MEDIA: 44.225711822509766,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e4f40b6e-a8cc-4e3d-b26d-a76a8d7b4a93',
    CIDADE: 'São José do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.77471923828125,
    MAXIMO: 44.90524673461914,
    MEDIA: 44.84668533470214,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '484a64fe-f701-4f88-b53d-fd2ff9b2a3c0',
    CIDADE: 'São José dos Ausentes',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.768253326416016,
    MAXIMO: 44.89516830444336,
    MEDIA: 44.827866872151695,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '353ff922-75fe-4957-8b48-2dffa281fb78',
    CIDADE: 'São Leopoldo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.98099136352539,
    MAXIMO: 45.15282440185547,
    MEDIA: 45.109413921416596,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dcebc39-7cf7-49d3-b96e-deb99e90917b',
    CIDADE: 'São Lourenço do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.85011291503906,
    MAXIMO: 45.3967170715332,
    MEDIA: 45.15002277919224,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '328a0426-f595-4d01-ac71-7a093db9234e',
    CIDADE: 'São Luiz Gonzaga',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.74580001831055,
    MAXIMO: 47.603206634521484,
    MEDIA: 47.153054555257164,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '26f9eb0e-cde3-4855-a2b0-4ae24a9f7f4a',
    CIDADE: 'São Marcos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.679996490478516,
    MAXIMO: 44.80378341674805,
    MEDIA: 44.746539238189236,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e22ad82-e88c-40b2-9697-c30a11b0e558',
    CIDADE: 'São Martinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.23020553588867,
    MAXIMO: 47.00554275512695,
    MEDIA: 46.667429570988745,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6970861c-f2bf-4864-85b8-678d23cd8fd4',
    CIDADE: 'São Martinho da Serra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.15470886230469,
    MAXIMO: 44.18622970581055,
    MEDIA: 44.17046928405762,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7af5ec6-5fc7-4725-8340-6c498f11b805',
    CIDADE: 'São Miguel das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.31801223754883,
    MAXIMO: 46.40104293823242,
    MEDIA: 45.8877685546875,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '44ab1dd1-be64-4671-9da5-de09ef288bd7',
    CIDADE: 'São Nicolau',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.98683547973633,
    MAXIMO: 48.85565948486328,
    MEDIA: 48.421247482299805,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '28166ed4-139a-4bcf-8653-68423335cff8',
    CIDADE: 'São Paulo das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.6962890625,
    MAXIMO: 48.9371337890625,
    MEDIA: 48.52381088672166,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1a76482-a696-4919-9eb6-54e384d109c7',
    CIDADE: 'São Pedro da Serra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.77471923828125,
    MAXIMO: 44.840476989746094,
    MEDIA: 44.79784703182564,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e16dca12-2e78-4120-9652-566daa1f8ecd',
    CIDADE: 'São Pedro das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.16412353515625,
    MAXIMO: 45.42662048339844,
    MEDIA: 45.17721514580086,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '22dde833-8981-485b-8568-77c99db58a0b',
    CIDADE: 'São Pedro do Butiá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.6962890625,
    MAXIMO: 48.16511917114258,
    MEDIA: 47.840864007016,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fea28f52-a1cc-4234-a065-31f395d7ce48',
    CIDADE: 'São Pedro do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.0988883972168,
    MAXIMO: 44.16545867919922,
    MEDIA: 44.128729502360024,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '717c8b93-2ee3-4c0e-8e16-42bd96c24b0a',
    CIDADE: 'São Sebastião do Caí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.840476989746094,
    MAXIMO: 44.98099136352539,
    MEDIA: 44.927280299154006,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1cc80cc4-937a-4291-a8ac-ce854c4b376c',
    CIDADE: 'São Sepé',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.84604263305664,
    MAXIMO: 44.02790451049805,
    MEDIA: 43.92919063568115,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '673df64b-3d46-48ef-ba34-4b42fc2c41bb',
    CIDADE: 'São Valentim',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.67599105834961,
    MAXIMO: 44.93135070800781,
    MEDIA: 44.746586854109275,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '17000619-4198-4aa7-b991-c151c799271a',
    CIDADE: 'São Valentim do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.49784851074219,
    MAXIMO: 44.58097839355469,
    MEDIA: 44.56824612096761,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3e03f001-3257-444c-966c-d569ecf7103e',
    CIDADE: 'São Valério do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.837215423583984,
    MAXIMO: 46.58988571166992,
    MEDIA: 46.333903655162146,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '35cfed24-18de-4360-a058-0b601b15ee34',
    CIDADE: 'São Vendelino',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.777549743652344,
    MAXIMO: 44.840476989746094,
    MEDIA: 44.83037047477495,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a9e3352a-cd5c-40a2-8df9-4ca60fd9e0b4',
    CIDADE: 'São Vicente do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.17863845825195,
    MAXIMO: 44.41973114013672,
    MEDIA: 44.29306602478027,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '56578ee2-3665-40b9-a9b5-823c4955716c',
    CIDADE: 'Sapiranga',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.06090545654297,
    MAXIMO: 45.245784759521484,
    MEDIA: 45.10695392785121,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '084bfdca-1f1b-4e6d-b8f5-640fe9f5793b',
    CIDADE: 'Sapucaia do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.05891418457031,
    MAXIMO: 45.25271987915039,
    MEDIA: 45.13436123431709,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '18432a67-296a-45ee-80b0-321f3f04330b',
    CIDADE: 'Sarandi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.71641159057617,
    MAXIMO: 45.00040817260742,
    MEDIA: 44.8584098815918,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'e83f3a1e-087c-4c94-a981-b01d84ced560',
    CIDADE: 'Seberi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.67521286010742,
    MAXIMO: 46.31892013549805,
    MEDIA: 45.9746241732345,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4e23566c-1872-48f8-8199-6fcd42248999',
    CIDADE: 'Sede Nova',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.6314811706543,
    MAXIMO: 47.00554275512695,
    MEDIA: 46.87744318631423,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '90f47e35-95ea-472f-ae0e-b1e7809c9520',
    CIDADE: 'Segredo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.18229675292969,
    MAXIMO: 44.25774002075195,
    MEDIA: 44.23048265670029,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fef7424f-f74c-4eed-81a2-a3c20ca09001',
    CIDADE: 'Selbach',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.32269287109375,
    MAXIMO: 44.38970184326172,
    MEDIA: 44.37081404636939,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '767aa3b8-feab-4d73-bfef-133c1a98c960',
    CIDADE: 'Senador Salgado Filho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.90571975708008,
    MAXIMO: 47.75870895385742,
    MEDIA: 47.26942481674607,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'da78f4d6-489d-4c58-9cbe-dc2649b01b64',
    CIDADE: 'Sentinela do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.176780700683594,
    MAXIMO: 45.40156555175781,
    MEDIA: 45.316797446137414,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2f91db4f-2071-41c8-99ff-7cf0d6b641ad',
    CIDADE: 'Serafina Corrêa',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.416175842285156,
    MAXIMO: 44.460540771484375,
    MEDIA: 44.44485482669532,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '42cbac79-a55d-40a6-a70c-b3ad87a5c7a9',
    CIDADE: 'Sério',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.39561462402344,
    MAXIMO: 44.493568420410156,
    MEDIA: 44.48715429549025,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b7f65f4f-1475-4c6a-9067-e7b8b5f0d491',
    CIDADE: 'Sertão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40845489501953,
    MAXIMO: 44.5275764465332,
    MEDIA: 44.46801567077637,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a398c189-c2cb-4ccf-b624-fe3d5c312e51',
    CIDADE: 'Sertão Santana',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.09812545776367,
    MAXIMO: 45.323917388916016,
    MEDIA: 45.184422202966125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '93a777c6-1802-41b8-a9b9-6cec41e92237',
    CIDADE: 'Sete de Setembro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.4771728515625,
    MAXIMO: 46.90571975708008,
    MEDIA: 46.85868695758213,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '72be0b25-4291-4619-9fc4-66ebfe27d0a2',
    CIDADE: 'Severiano de Almeida',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40254592895508,
    MAXIMO: 44.53813171386719,
    MEDIA: 44.44266557793961,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee38aaba-72c8-4a06-a204-12ed0187ba48',
    CIDADE: 'Silveira Martins',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.04586410522461,
    MAXIMO: 44.08095932006836,
    MEDIA: 44.07734106029981,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '353c99ce-c523-4b15-95ce-cf75f35710a3',
    CIDADE: 'Sinimbu',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.2795524597168,
    MAXIMO: 44.42852783203125,
    MEDIA: 44.33029099879587,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bbad9c6a-ae61-42d2-a0bc-6b1d7d5a35ef',
    CIDADE: 'Sobradinho',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.18229675292969,
    MAXIMO: 44.226322174072266,
    MEDIA: 44.18449888591761,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '64631d42-a486-447e-a540-7ce60519f441',
    CIDADE: 'Soledade',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.29155731201172,
    MAXIMO: 44.35556411743164,
    MEDIA: 44.32316207885742,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4fbcf571-4951-45fb-a209-a26791d3a5fa',
    CIDADE: 'Tabaí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.76531219482422,
    MAXIMO: 44.8439826965332,
    MEDIA: 44.78685405433542,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '5ad91159-c483-497b-838f-394de0c65a81',
    CIDADE: 'Tapejara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.33456039428711,
    MAXIMO: 44.39387893676758,
    MEDIA: 44.371318785277005,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '523b5197-e163-4ddc-bf7f-e83ab36c1efd',
    CIDADE: 'Tapera',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.32269287109375,
    MAXIMO: 44.378631591796875,
    MEDIA: 44.37290138527139,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd7671007-0d9f-4fdc-8ad2-25ec35f4d86d',
    CIDADE: 'Tapes',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.40156555175781,
    MAXIMO: 45.42073059082031,
    MEDIA: 45.41114807128906,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c510001f-5134-4658-a237-507fa8692b8a',
    CIDADE: 'Taquara',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.21976089477539,
    MAXIMO: 45.32978820800781,
    MEDIA: 45.2651112874349,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3c68dcf3-a10b-433f-8082-a68caa461769',
    CIDADE: 'Taquari',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.724327087402344,
    MAXIMO: 44.937870025634766,
    MEDIA: 44.77630649583884,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '1f88c48e-772a-4609-96da-3df94bd8a384',
    CIDADE: 'Taquaruçu do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.99693298339844,
    MAXIMO: 46.67139434814453,
    MEDIA: 46.43489502878867,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2046fac2-3f7e-4d83-9a8b-756a37e848d8',
    CIDADE: 'Tavares',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.21244430541992,
    MAXIMO: 46.385948181152344,
    MEDIA: 46.29919624328613,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '3534bdb7-2b56-4b21-8a4c-52d7d27d4ae4',
    CIDADE: 'Tenente Portela',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.04436111450195,
    MAXIMO: 47.430519104003906,
    MEDIA: 47.23744010925293,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a167deb6-ef9d-4f1d-8319-8da5384f1bbb',
    CIDADE: 'Terra de Areia',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.452144622802734,
    MAXIMO: 45.48494338989258,
    MEDIA: 45.468544006347656,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b8dfc4f4-fbd5-4e4c-98e2-a851bb9f248e',
    CIDADE: 'Teutônia',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.652462005615234,
    MAXIMO: 44.724327087402344,
    MEDIA: 44.69258908074125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2dd1110a-dc98-44db-b33c-8eb6a97f4a0f',
    CIDADE: 'Tio Hugo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.36039352416992,
    MAXIMO: 44.40469741821289,
    MEDIA: 44.369048496222725,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '373b8926-9c9b-4b4c-8e49-cb322e6080b7',
    CIDADE: 'Tiradentes do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.420265197753906,
    MAXIMO: 48.23212814331055,
    MEDIA: 48.00577837421173,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cf204176-8ef1-400f-8211-682631989b6e',
    CIDADE: 'Toropi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.16545867919922,
    MAXIMO: 44.287715911865234,
    MEDIA: 44.2668107581677,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f65a45d8-aaab-41e1-88c6-caa141aade6d',
    CIDADE: 'Torres',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.28578567504883,
    MAXIMO: 45.397029876708984,
    MEDIA: 45.315478291614056,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a753dce3-e189-4e51-8edf-3639fc2e8b82',
    CIDADE: 'Tramandaí',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.59486770629883,
    MAXIMO: 45.794410705566406,
    MEDIA: 45.692766285705844,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cda67e27-89ff-4a2c-ab2b-a35b4879459f',
    CIDADE: 'Travesseiro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.4713249206543,
    MAXIMO: 44.54253387451172,
    MEDIA: 44.53207597295325,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9ebc06fb-7599-4960-b4ff-a855c89bc490',
    CIDADE: 'Três Arroios',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.40254592895508,
    MAXIMO: 44.49693298339844,
    MEDIA: 44.48088650601191,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '567cd8c2-d005-40dc-adb3-3b934811d614',
    CIDADE: 'Três Cachoeiras',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.35794448852539,
    MAXIMO: 45.48494338989258,
    MEDIA: 45.40465173350733,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '04631646-1ddc-4f29-abd4-9f4a3be12758',
    CIDADE: 'Três Coroas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.041099548339844,
    MAXIMO: 45.21976089477539,
    MEDIA: 45.10024064325322,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8c9d46f2-38dc-4f88-a205-232ab3517179',
    CIDADE: 'Três de Maio',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.37211608886719,
    MAXIMO: 47.39850997924805,
    MEDIA: 47.38531303405762,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '77abce63-64b1-4604-99c2-8fbb10bedfed',
    CIDADE: 'Três Forquilhas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.234066009521484,
    MAXIMO: 45.48494338989258,
    MEDIA: 45.353547952149846,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '828dc943-c4f1-4316-a6e9-9fd04c03cbff',
    CIDADE: 'Três Palmeiras',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.823612213134766,
    MAXIMO: 45.20620346069336,
    MEDIA: 44.97700590499577,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '8d7fe9bf-e5b3-4c9e-b4e4-eb2b4b68ebd4',
    CIDADE: 'Três Passos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.03294372558594,
    MAXIMO: 47.829673767089844,
    MEDIA: 47.31237188729238,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '81562807-ea41-488a-b9f6-05a539d9c95e',
    CIDADE: 'Trindade do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.823612213134766,
    MAXIMO: 45.42422103881836,
    MEDIA: 45.20669666621428,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '329c6f50-354b-4ae4-9592-9d1b56727619',
    CIDADE: 'Triunfo',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.8439826965332,
    MAXIMO: 45.065673828125,
    MEDIA: 44.962093353271484,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '19b186c2-21fe-42b4-834e-451e6df5f8ed',
    CIDADE: 'Tucunduva',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.80780029296875,
    MAXIMO: 48.639617919921875,
    MEDIA: 48.06469958740602,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6b386268-98eb-4489-bb9a-97727845173e',
    CIDADE: 'Tunas',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.2360725402832,
    MAXIMO: 44.28277587890625,
    MEDIA: 44.255016495948176,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '71c77807-16ee-4d64-9702-5ed195200178',
    CIDADE: 'Tupanci do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.23172378540039,
    MAXIMO: 44.27618408203125,
    MEDIA: 44.27059764861157,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1198b32-c159-4511-84fc-972864a5bafe',
    CIDADE: 'Tupanciretã',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.35436248779297,
    MAXIMO: 44.84176254272461,
    MEDIA: 44.54680421617296,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2c766ea0-bc08-4e62-b579-09a563255994',
    CIDADE: 'Tupandi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.840476989746094,
    MAXIMO: 44.90524673461914,
    MEDIA: 44.84047758359941,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'b6dc4a59-62ec-4775-9816-1dd55a3d03c3',
    CIDADE: 'Tuparendi',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.79454803466797,
    MAXIMO: 48.64902114868164,
    MEDIA: 48.24032607543847,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '830e6715-b018-4a59-86bd-bd6ceeb87a41',
    CIDADE: 'Turuçu',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.91799545288086,
    MAXIMO: 45.47261428833008,
    MEDIA: 45.25815132507332,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '824b5b0d-6144-4844-9e8e-e61223e82143',
    CIDADE: 'Ubiretama',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.29676055908203,
    MAXIMO: 47.75870895385742,
    MEDIA: 47.628119201756256,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '699d6092-d2cd-411d-8954-3be84ede22ac',
    CIDADE: 'União da Serra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.416175842285156,
    MAXIMO: 44.460540771484375,
    MEDIA: 44.42322814450259,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'ee6360d8-2b09-4c26-a845-ea678e5f6a3d',
    CIDADE: 'Unistalda',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.49094009399414,
    MAXIMO: 45.9839973449707,
    MEDIA: 45.73746871948242,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '41848c1f-f098-48b9-967f-7bb02de5aa97',
    CIDADE: 'Uruguaiana',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.720664978027344,
    MAXIMO: 49.81647872924805,
    MEDIA: 48.544335572615914,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '822518df-fbc3-4d25-91b6-154f687e2cee',
    CIDADE: 'Vacaria',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.438716888427734,
    MAXIMO: 44.64794921875,
    MEDIA: 44.5360951423645,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '0de8a553-ac58-4098-838d-d28f9527069a',
    CIDADE: 'Vale Verde',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.561527252197266,
    MAXIMO: 44.71625900268555,
    MEDIA: 44.64361699422201,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '15aa3742-feb2-4ab4-b021-5b648be91f35',
    CIDADE: 'Vale do Sol',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.226322174072266,
    MAXIMO: 44.373741149902344,
    MEDIA: 44.286366226515526,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c5768f2c-5805-4245-88a8-a8b8a60f40a1',
    CIDADE: 'Vale Real',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.84040069580078,
    MAXIMO: 44.9102783203125,
    MEDIA: 44.881557891506354,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c883b58d-1117-44df-b141-9828334414b6',
    CIDADE: 'Vanini',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.42578887939453,
    MAXIMO: 44.43299865722656,
    MEDIA: 44.42774476601278,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'fdec7579-1cff-4e31-9000-dc3a600f7d05',
    CIDADE: 'Venâncio Aires',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.521427154541016,
    MAXIMO: 44.602169036865234,
    MEDIA: 44.561798095703125,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'd1245527-9afe-4104-85f0-f170f17d6ede',
    CIDADE: 'Vera Cruz',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.35196304321289,
    MAXIMO: 44.373741149902344,
    MEDIA: 44.36285209655762,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '6858bb6f-8960-4bfd-91ac-ee7cb8096252',
    CIDADE: 'Veranópolis',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.53764724731445,
    MAXIMO: 44.636436462402344,
    MEDIA: 44.58986697321513,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '4de43b82-134e-4b4d-8ff2-a55043d43ca5',
    CIDADE: 'Vespasiano Corrêa',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.49784851074219,
    MAXIMO: 44.58097839355469,
    MEDIA: 44.5291762082023,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '362a5eb0-48b0-4278-966f-4c34889002cc',
    CIDADE: 'Viadutos',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.32655715942383,
    MAXIMO: 44.40254592895508,
    MEDIA: 44.36455154418945,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '9a339775-85ba-40bf-897a-c0a6e6cdccbb',
    CIDADE: 'Viamão',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.32688522338867,
    MAXIMO: 45.641178131103516,
    MEDIA: 45.50069580078125,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '800a2525-a994-484d-88c5-c3d0610c7b9d',
    CIDADE: 'Vicente Dutra',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.31892013549805,
    MAXIMO: 47.02418518066406,
    MEDIA: 46.708780253303594,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'bd6cf9f1-bb33-4bd0-8d56-bf51bbd9018b',
    CIDADE: 'Victor Graeff',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.36039352416992,
    MAXIMO: 44.40469741821289,
    MEDIA: 44.37821687050195,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '094338eb-1db4-46fa-bd77-c604a68df237',
    CIDADE: 'Vila Flores',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.490203857421875,
    MAXIMO: 44.62879180908203,
    MEDIA: 44.543655185731915,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '98b0aa0c-3394-427c-adee-b7eaee651463',
    CIDADE: 'Vila Lângaro',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.367523193359375,
    MAXIMO: 44.40845489501953,
    MEDIA: 44.388910188246946,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '288b4b81-b058-48c9-863f-90a522ca1b3f',
    CIDADE: 'Vila Maria',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.3736457824707,
    MAXIMO: 44.416175842285156,
    MEDIA: 44.389541292222695,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'c68704a5-2910-4a73-9556-fb0273eb9fe5',
    CIDADE: 'Vila Nova do Sul',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 43.80358123779297,
    MAXIMO: 43.84757995605469,
    MEDIA: 43.82158406575521,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'a60936d4-1870-4206-b14a-c1bd34d48bb5',
    CIDADE: 'Vista Alegre',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 46.67139434814453,
    MAXIMO: 47.02418518066406,
    MEDIA: 46.672863057852105,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'f95d65fe-939c-493c-8630-e27bf1254e86',
    CIDADE: 'Vista Alegre do Prata',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.460540771484375,
    MAXIMO: 44.53764724731445,
    MEDIA: 44.482485441135154,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '184726bb-aa8e-48c4-b8c9-cfdd26086f89',
    CIDADE: 'Vista Gaúcha',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 47.04436111450195,
    MAXIMO: 47.41960144042969,
    MEDIA: 47.12197558658104,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '592f67ae-316e-4d74-aacb-b10a3785b76b',
    CIDADE: 'Vitória das Missões',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.75093078613281,
    MAXIMO: 46.4771728515625,
    MEDIA: 46.20305625308344,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '129a2d8b-8bc2-40d2-9406-5b21e7a58382',
    CIDADE: 'Westfália',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 44.708778381347656,
    MAXIMO: 44.708778381347656,
    MEDIA: 44.708778381347656,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: '2068434c-0404-49fa-b88e-04bf62da6d16',
    CIDADE: 'Xangri-lá',
    SIGLA: 'RS',
    ESTADO: 'Rio Grande do Sul',
    MINIMO: 45.512855529785156,
    MAXIMO: 45.63949203491211,
    MEDIA: 45.517088018280816,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '12,00%',
    ICMSNCONTR: '18,00%',
  },
  {
    id: 'cd608097-ec13-427f-a147-e7a466350b8f',
    CIDADE: 'Água Clara',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 40.13431167602539,
    MAXIMO: 42.24893569946289,
    MEDIA: 41.50484228134155,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5bcc6b90-8ac8-45bc-ba6c-ea375b53b03f',
    CIDADE: 'Alcinópolis',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 38.51607131958008,
    MAXIMO: 40.40384292602539,
    MEDIA: 39.382635498046874,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b2f07631-a2ce-433b-b86d-f468eabc294d',
    CIDADE: 'Amambai',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 49.45207977294922,
    MAXIMO: 50.65226745605469,
    MEDIA: 50.09747568766276,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0ab967c9-df9b-4fa8-92a0-14143c7715d0',
    CIDADE: 'Anastácio',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.61598587036133,
    MAXIMO: 45.4861946105957,
    MEDIA: 44.98857307434082,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '007e8fde-3b71-4848-95e8-a21e7805c3aa',
    CIDADE: 'Anaurilândia',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.22594451904297,
    MAXIMO: 45.48040008544922,
    MEDIA: 44.871801202947445,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1abbd923-d7f6-435a-b7ee-82983842ca30',
    CIDADE: 'Angélica',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.24429702758789,
    MAXIMO: 46.82152557373047,
    MEDIA: 46.491668701171875,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '25f9e0ac-9b65-41dd-8cc9-73710d02b165',
    CIDADE: 'Antônio João',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.571372985839844,
    MAXIMO: 49.014583587646484,
    MEDIA: 48.87127494812012,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5162eaf2-1817-447e-9cd4-1fd536a07f40',
    CIDADE: 'Aparecida do Taboado',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 37.55918884277344,
    MAXIMO: 39.289119720458984,
    MEDIA: 38.495286560058595,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0202eff2-c26b-44e1-a046-ca4dfc045fa0',
    CIDADE: 'Aquidauana',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 41.81356430053711,
    MAXIMO: 44.58893585205078,
    MEDIA: 43.386398249659045,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ff5ba804-359e-49a1-bbc2-75f1a2a1cd43',
    CIDADE: 'Aral Moreira',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 49.762001037597656,
    MAXIMO: 50.47464370727539,
    MEDIA: 50.085567474365234,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1132af81-8b4e-4dea-90c3-3e27c6d403f6',
    CIDADE: 'Bandeirantes',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 42.43718719482422,
    MAXIMO: 43.09270477294922,
    MEDIA: 42.76154604825106,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5b8f66a2-2bdb-4f4d-875b-e879a9af8494',
    CIDADE: 'Bataguassu',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 43.05577850341797,
    MAXIMO: 44.64888000488281,
    MEDIA: 43.85655254787869,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b3f24b64-66d7-4b76-a134-56f747933a3a',
    CIDADE: 'Batayporã',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 45.51411819458008,
    MAXIMO: 46.35793685913086,
    MEDIA: 45.901432037353516,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '379109c1-5512-4454-ac61-0dbf1922ca6c',
    CIDADE: 'Bela Vista',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.34394454956055,
    MAXIMO: 48.64830017089844,
    MEDIA: 48.13771104812622,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cf5252dc-d51a-4a27-b7e4-46afe0d82d4f',
    CIDADE: 'Bodoquena',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.644771575927734,
    MAXIMO: 45.32770538330078,
    MEDIA: 44.93686730521066,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c5803e5b-a094-4180-af58-44bc59a36470',
    CIDADE: 'Bonito',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.98527908325195,
    MAXIMO: 46.517215728759766,
    MEDIA: 45.82880648444681,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '85d76b6b-0e13-4288-8eb3-3d01250f5e6e',
    CIDADE: 'Brasilândia',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 41.80219268798828,
    MAXIMO: 42.772396087646484,
    MEDIA: 42.40220686968635,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ddd03833-a881-475f-a496-359622b28ba9',
    CIDADE: 'Caarapó',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.41613006591797,
    MAXIMO: 49.32558822631836,
    MEDIA: 48.82970264979771,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a9e1912c-0728-4b75-b03c-4068e5c2e5ac',
    CIDADE: 'Camapuã',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 40.72454071044922,
    MAXIMO: 42.31065368652344,
    MEDIA: 41.63947175678454,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9e20f807-9a14-49fa-bddf-80bcf496d193',
    CIDADE: 'Campo Grande',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 43.84652328491211,
    MAXIMO: 45.34178924560547,
    MEDIA: 44.602510929107666,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e7660343-3872-47aa-81b3-8ace669c5f83',
    CIDADE: 'Caracol',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.727413177490234,
    MAXIMO: 48.587467193603516,
    MEDIA: 48.21211099624634,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '36319c79-47d9-4ab6-a667-4e2355b8ff6a',
    CIDADE: 'Cassilândia',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 37.52229309082031,
    MAXIMO: 39.541770935058594,
    MEDIA: 38.685774289644684,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '43af6360-c69e-425e-a75d-9fa71df7fa59',
    CIDADE: 'Chapadão do Sul',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 38.64535903930664,
    MAXIMO: 40.119205474853516,
    MEDIA: 39.448062896728516,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7fe0bfae-4ebb-48c4-a87e-ce03aae87a37',
    CIDADE: 'Corguinho',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 42.89438247680664,
    MAXIMO: 43.64167022705078,
    MEDIA: 43.27708148956299,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '795d2ef6-8712-4011-87d5-0e1d225ae8db',
    CIDADE: 'Coronel Sapucaia',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 50.7589225769043,
    MAXIMO: 51.01798629760742,
    MEDIA: 50.892407735188804,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f6004779-2646-4224-b5f5-e6cb464526ad',
    CIDADE: 'Corumbá',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 37.754825592041016,
    MAXIMO: 44.84484100341797,
    MEDIA: 41.36010246650845,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '54f9af6d-331b-4a33-85fc-eda3c1c1d0ea',
    CIDADE: 'Costa Rica',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 38.53329849243164,
    MAXIMO: 40.235836029052734,
    MEDIA: 39.363303844745346,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cf8e103a-558e-4291-ab7c-ead14d8dab16',
    CIDADE: 'Coxim',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 39.63725280761719,
    MAXIMO: 41.098045349121094,
    MEDIA: 40.395297095889134,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0e113753-d8e5-4fa2-a59b-76f1719e7c00',
    CIDADE: 'Deodápolis',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.67488479614258,
    MAXIMO: 47.40678024291992,
    MEDIA: 47.046180725097656,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '04ff678b-2907-4462-b6e1-5972a21a35e1',
    CIDADE: 'Dois Irmãos do Buriti',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.17558288574219,
    MAXIMO: 45.40196228027344,
    MEDIA: 44.76344060897827,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9af761d0-3f3b-4246-9138-832867e05ee6',
    CIDADE: 'Douradina',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.06652069091797,
    MAXIMO: 47.65604782104492,
    MEDIA: 47.34936221983715,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '810d203d-2fea-4bac-8551-62f381a575e2',
    CIDADE: 'Dourados',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.27220916748047,
    MAXIMO: 48.380489349365234,
    MEDIA: 47.93102900187174,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6c2e6952-2d43-43cd-9e77-79ee634186e2',
    CIDADE: 'Eldorado',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 50.053035736083984,
    MAXIMO: 50.29518127441406,
    MEDIA: 50.20773442586263,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7d150511-0b81-4a97-a3f4-32c64ca9d2fd',
    CIDADE: 'Fátima do Sul',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.64204788208008,
    MAXIMO: 48.41613006591797,
    MEDIA: 47.92880691322478,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '13c5cdf8-c3c9-4b65-b030-81cf6eaf096f',
    CIDADE: 'Figueirão',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 39.815513610839844,
    MAXIMO: 41.390689849853516,
    MEDIA: 40.66100647870232,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a7680edd-8233-49e6-83b0-8d16b8583c4f',
    CIDADE: 'Glória de Dourados',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.773193359375,
    MAXIMO: 48.00797653198242,
    MEDIA: 47.89058494567871,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0f8b9fb6-178f-4fb2-842f-15761a55acdb',
    CIDADE: 'Guia Lopes da Laguna',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.86404037475586,
    MAXIMO: 47.73707962036133,
    MEDIA: 47.20805740356445,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6e105715-3cf4-468a-b721-a76e77d6878e',
    CIDADE: 'Iguatemi',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 49.767723083496094,
    MAXIMO: 50.49169921875,
    MEDIA: 50.15923097398546,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '680f0ac2-1cc0-4170-a958-46f4cce2e3b5',
    CIDADE: 'Inocência',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 38.52531814575195,
    MAXIMO: 40.06629180908203,
    MEDIA: 39.36377357034122,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '452673aa-30d0-4360-baf0-9b828d93f3c0',
    CIDADE: 'Itaporã',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.43272399902344,
    MAXIMO: 47.98512649536133,
    MEDIA: 47.70797634124756,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ff617403-2b5d-4fb3-8a5f-2bec85633627',
    CIDADE: 'Itaquiraí',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.74333953857422,
    MAXIMO: 49.80030822753906,
    MEDIA: 49.317804609026226,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8728d5ac-2e65-48a3-b3dd-a33ce94dbde8',
    CIDADE: 'Ivinhema',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.66651916503906,
    MAXIMO: 47.52503967285156,
    MEDIA: 47.03898239135742,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a9f6d8bd-5ca8-4403-9b5e-1ceb97d858d0',
    CIDADE: 'Japorã',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 50.274986267089844,
    MAXIMO: 50.9237060546875,
    MEDIA: 50.64902585596998,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '263e918d-9747-47b2-826c-bf981d0f18d3',
    CIDADE: 'Jaraguari',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 43.21210861206055,
    MAXIMO: 43.873687744140625,
    MEDIA: 43.51313951280382,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '606e9971-603f-44c4-b52b-8f26de0e5151',
    CIDADE: 'Jardim',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.906341552734375,
    MAXIMO: 47.770137786865234,
    MEDIA: 47.199450969696045,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '75c4389f-05a4-41eb-a8dd-0199beaf992e',
    CIDADE: 'Jateí',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.58842849731445,
    MAXIMO: 48.34844207763672,
    MEDIA: 47.96941048758371,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '075b6a42-8fcd-44c1-bb53-a5e17ae940be',
    CIDADE: 'Juti',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.4150276184082,
    MAXIMO: 49.22114944458008,
    MEDIA: 48.86594161987305,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5a7fc194-fe61-4ab8-909a-88608894e60d',
    CIDADE: 'Ladário',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 42.02764892578125,
    MAXIMO: 42.307861328125,
    MEDIA: 42.167755126953125,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '21b6eccf-bf08-453a-bfdc-175ef865a5eb',
    CIDADE: 'Laguna Carapã',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.77348327636719,
    MAXIMO: 49.64620590209961,
    MEDIA: 49.257385889689125,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c2ed9bf9-b591-4637-b93a-d30810726a38',
    CIDADE: 'Maracaju',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 45.86543273925781,
    MAXIMO: 47.61905288696289,
    MEDIA: 46.8752806186676,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b98f34ff-68ac-4337-9388-162648a3e5f2',
    CIDADE: 'Miranda',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 43.525367736816406,
    MAXIMO: 44.67521286010742,
    MEDIA: 44.04955650778378,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86fbb43b-e375-460d-b091-653bc2386ac8',
    CIDADE: 'Mundo Novo',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 50.0450439453125,
    MAXIMO: 50.739810943603516,
    MEDIA: 50.49098288840642,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4dc9933a-5344-4994-9374-683bc560901c',
    CIDADE: 'Naviraí',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.15953826904297,
    MAXIMO: 49.272987365722656,
    MEDIA: 48.66579013400607,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b8f8fd4e-792e-48fa-a6e5-67736b4ce70f',
    CIDADE: 'Nioaque',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 45.215057373046875,
    MAXIMO: 46.81721115112305,
    MEDIA: 45.98940922663762,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a45afd03-9bcd-453b-b61f-de2e47c4c23a',
    CIDADE: 'Nova Alvorada do Sul',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 45.01177978515625,
    MAXIMO: 46.107421875,
    MEDIA: 45.69177000863211,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c26eac4c-262d-4f02-869b-502cc3424c22',
    CIDADE: 'Nova Andradina',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.366676330566406,
    MAXIMO: 46.4030647277832,
    MEDIA: 45.45480298995972,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '85bc46be-7c85-4341-914d-1821aab48c45',
    CIDADE: 'Novo Horizonte do Sul',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.020511627197266,
    MAXIMO: 47.58284378051758,
    MEDIA: 47.30007298787435,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '98df7bae-569f-4c23-a5e5-0bbf182355c3',
    CIDADE: 'Paraíso das Águas',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 39.79951477050781,
    MAXIMO: 41.498687744140625,
    MEDIA: 40.54756680656882,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c89dcf1e-95f4-441b-917e-9ea633569282',
    CIDADE: 'Paranaíba',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 36.77409744262695,
    MAXIMO: 38.67014694213867,
    MEDIA: 37.76146899952608,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '845afe12-9a73-4b44-8eaa-ffffff8a7b65',
    CIDADE: 'Paranhos',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 51.14418411254883,
    MAXIMO: 51.55335235595703,
    MEDIA: 51.32741737365723,
    OFICIAL: 52,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f73f390d-857b-4d21-902a-ae58fa8f6d9d',
    CIDADE: 'Pedro Gomes',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 38.42607498168945,
    MAXIMO: 40.131813049316406,
    MEDIA: 39.370873191139914,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '502d5158-7a2a-4031-a840-c4f726885caa',
    CIDADE: 'Ponta Porã',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 47.68592071533203,
    MAXIMO: 49.60832977294922,
    MEDIA: 48.63896369934082,
    OFICIAL: 50,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f141748d-bebc-49fb-87cb-470bc987e911',
    CIDADE: 'Porto Murtinho',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.23301315307617,
    MAXIMO: 48.52750015258789,
    MEDIA: 46.180559094746904,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eb56f23f-d3ad-487c-b735-dc081f5d7cf5',
    CIDADE: 'Ribas do Rio Pardo',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 42.0092887878418,
    MAXIMO: 44.817726135253906,
    MEDIA: 43.2690235546657,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fd65ee41-2fa1-475c-8c06-bff97a3206e5',
    CIDADE: 'Rio Brilhante',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.21615219116211,
    MAXIMO: 47.251708984375,
    MEDIA: 46.65710989634196,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'af2228dc-416c-4e9a-a081-a3572bc47d0b',
    CIDADE: 'Rio Negro',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 42.377262115478516,
    MAXIMO: 42.97957229614258,
    MEDIA: 42.66387176513672,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fd84352b-6e97-4381-b8a1-10bea56227a6',
    CIDADE: 'Rio Verde de Mato Grosso',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 40.80104446411133,
    MAXIMO: 42.543174743652344,
    MEDIA: 41.61162889920748,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9b9e7231-6632-46a2-848f-4d964e3fabbc',
    CIDADE: 'Rochedo',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 43.0567626953125,
    MAXIMO: 43.76295852661133,
    MEDIA: 43.40944862365723,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b0369ed8-6450-4a02-92e9-7b2d880749f4',
    CIDADE: 'Santa Rita do Pardo',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 42.821327209472656,
    MAXIMO: 44.07754135131836,
    MEDIA: 43.38566789172945,
    OFICIAL: 44,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '14540488-9541-4919-ba22-6ec7b738827a',
    CIDADE: 'São Gabriel do Oeste',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 41.25831985473633,
    MAXIMO: 42.54237365722656,
    MEDIA: 41.91668828328451,
    OFICIAL: 43,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0aac17b4-91cc-4ba3-85d3-cf2ad25e721b',
    CIDADE: 'Sete Quedas',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 51.291175842285156,
    MAXIMO: 51.43321990966797,
    MEDIA: 51.36219787597656,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1bff4e7b-014a-4f03-a074-3af20a95ccd5',
    CIDADE: 'Selvíria',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 39.2495002746582,
    MAXIMO: 40.599849700927734,
    MEDIA: 40.008780288696286,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'db2cfc22-7a0b-4e3a-921f-4eb35f86d8ef',
    CIDADE: 'Sidrolândia',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 44.66460037231445,
    MAXIMO: 46.477657318115234,
    MEDIA: 45.49118985627827,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5d53a5b6-0b60-4a5b-9abe-7c49eb89112c',
    CIDADE: 'Sonora',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 37.351173400878906,
    MAXIMO: 39.5992546081543,
    MEDIA: 38.63186997633714,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fca7782e-cf10-4f5f-a451-9998a5a38e00',
    CIDADE: 'Tacuru',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 50.68458938598633,
    MAXIMO: 51.24322509765625,
    MEDIA: 50.93604115077427,
    OFICIAL: 51,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1475d350-863b-4691-b86a-d015fac6b11c',
    CIDADE: 'Taquarussu',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 46.40150451660156,
    MAXIMO: 47.29201889038086,
    MEDIA: 46.83357238769531,
    OFICIAL: 47,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9f636b2d-94a2-44e7-a25c-b8eb3b2dea60',
    CIDADE: 'Terenos',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 43.94260025024414,
    MAXIMO: 44.58298110961914,
    MEDIA: 44.257861614227295,
    OFICIAL: 45,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd35af35a-2906-4115-b700-4d580be19946',
    CIDADE: 'Três Lagoas',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 40.098487854003906,
    MAXIMO: 41.92298889160156,
    MEDIA: 41.065888192918564,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b3ec16e8-e42f-452e-ac0b-f9df9e352559',
    CIDADE: 'Vicentina',
    SIGLA: 'MS',
    ESTADO: 'Mato Grosso do Sul',
    MINIMO: 48.00797653198242,
    MAXIMO: 48.5665168762207,
    MEDIA: 48.21953171565074,
    OFICIAL: 49,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c298776c-29ac-4d5e-b546-8773c436b432',
    CIDADE: 'Acorizal',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.996580123901367,
    MAXIMO: 31.05068016052246,
    MEDIA: 31.023630142211914,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '71b20958-0d3f-407d-9c76-994433f10ccc',
    CIDADE: 'Água Boa',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.04043197631836,
    MAXIMO: 30.352401733398438,
    MEDIA: 30.166858326305043,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f53ff174-be8d-4e68-9025-5744a9fbfc61',
    CIDADE: 'Alta Floresta',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.40564727783203,
    MAXIMO: 33.091670989990234,
    MEDIA: 31.98900577000209,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '40e7ffe7-b58c-4ba3-949c-932103f50b01',
    CIDADE: 'Alto Araguaia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 35.24445724487305,
    MAXIMO: 38.7005615234375,
    MEDIA: 37.120137666401114,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd43b7d2b-a0cd-4ce4-832f-5494e053e4c1',
    CIDADE: 'Alto Boa Vista',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.963010787963867,
    MAXIMO: 30.16168975830078,
    MEDIA: 30.04405927658081,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '57b824a3-5c80-4565-91a1-f8eb0e547633',
    CIDADE: 'Alto Garças',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.38369369506836,
    MAXIMO: 36.73945236206055,
    MEDIA: 35.606510162353516,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '767f5ecd-f4af-4366-a6f1-e252d7bd9404',
    CIDADE: 'Alto Paraguai',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.37868309020996,
    MAXIMO: 30.878150939941406,
    MEDIA: 30.586414019266766,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eaf07ae0-baaf-4984-87f4-5ea683a25e80',
    CIDADE: 'Alto Taquari',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 37.73231506347656,
    MAXIMO: 38.29069900512695,
    MEDIA: 38.02129364013672,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '64b99015-dd40-40c5-ac61-99123ef89253',
    CIDADE: 'Apiacás',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.41029357910156,
    MAXIMO: 34.57605743408203,
    MEDIA: 33.59089815616608,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7f66755a-a34c-4e4b-aedf-4ab1f9c0e933',
    CIDADE: 'Araguaiana',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.35659408569336,
    MAXIMO: 32.0363883972168,
    MEDIA: 31.20509910583496,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4e1560b9-d519-4e35-9b0d-add6c1b0d174',
    CIDADE: 'Araguainha',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.7480354309082,
    MAXIMO: 34.87744903564453,
    MEDIA: 34.81274223327637,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8074920a-769d-4964-b126-ae6e2e1bebc0',
    CIDADE: 'Araputanga',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.224885940551758,
    MAXIMO: 30.730133056640625,
    MEDIA: 30.46210708618164,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '90052324-d00a-436a-9da1-80ba9ae27d5d',
    CIDADE: 'Arenápolis',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.18697166442871,
    MAXIMO: 30.336700439453125,
    MEDIA: 30.261836051940918,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '19c2c9e7-c09f-4ca5-af82-92aa5eb00af0',
    CIDADE: 'Aripuanã',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 36.556583404541016,
    MAXIMO: 40.752410888671875,
    MEDIA: 38.53402160050033,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '06c85055-0c02-4afd-b6b2-8ea8a0b21e70',
    CIDADE: 'Barão de Melgaço',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.07548522949219,
    MAXIMO: 37.75474548339844,
    MEDIA: 36.27011326381138,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '39702ea4-02c0-41a6-a3a3-d37d6d1df576',
    CIDADE: 'Barra do Bugres',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.112098693847656,
    MAXIMO: 31.13446807861328,
    MEDIA: 30.63283338044819,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a78f99d5-cd11-41eb-b343-35d6920b752b',
    CIDADE: 'Barra do Garças',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.934524536132812,
    MAXIMO: 32.18877410888672,
    MEDIA: 31.56476563673753,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6dac4b53-85ab-4d05-85c8-d51789c10e0d',
    CIDADE: 'Bom Jesus do Araguaia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.93121910095215,
    MAXIMO: 30.010969161987305,
    MEDIA: 29.97828022638957,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1ff5a75b-b72a-49a0-a2b0-a1ee9c8f5b09',
    CIDADE: 'Brasnorte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.57832908630371,
    MAXIMO: 38.0960693359375,
    MEDIA: 34.76077055423818,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '46eba654-c4cc-401e-8c03-507df057233e',
    CIDADE: 'Cáceres',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.36863136291504,
    MAXIMO: 38.5101203918457,
    MEDIA: 34.49623644351959,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd6de645a-0b25-4005-be73-74456ad3504c',
    CIDADE: 'Campinápolis',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.08827018737793,
    MAXIMO: 30.910831451416016,
    MEDIA: 30.480850668514478,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4f84c445-4bcb-4356-a95d-f023f4ef8679',
    CIDADE: 'Campo Novo do Parecis',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.120637893676758,
    MAXIMO: 34.328224182128906,
    MEDIA: 31.224849085653982,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '198fbd4a-9200-4edd-aa60-bf63c3a1e189',
    CIDADE: 'Campo Verde',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.341827392578125,
    MAXIMO: 32.59317398071289,
    MEDIA: 31.794765090942384,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5d895cc5-c971-490d-b2e0-e9d78fc580d9',
    CIDADE: 'Campos de Júlio',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.156490325927734,
    MAXIMO: 41.239192962646484,
    MEDIA: 35.683598258278586,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '461cc22e-3ce8-42e6-8bf6-ca5816cc2bb2',
    CIDADE: 'Canabrava do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.081554412841797,
    MAXIMO: 30.25865364074707,
    MEDIA: 30.16154193878174,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1ae7c4e2-a23e-4947-871a-91a6de4ae95b',
    CIDADE: 'Canarana',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.854509353637695,
    MAXIMO: 30.018465042114258,
    MEDIA: 29.943735066582175,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '76d7a2da-1a57-41c7-9573-e73200f7ec60',
    CIDADE: 'Carlinda',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.155372619628906,
    MAXIMO: 31.664325714111328,
    MEDIA: 31.3718683719635,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fa75037e-3acd-4608-8507-c32d2b013c19',
    CIDADE: 'Castanheira',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 37.13139724731445,
    MAXIMO: 39.185218811035156,
    MEDIA: 38.05509393865412,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5c40c2a3-2207-47eb-85b9-1f1533f9bebc',
    CIDADE: 'Chapada dos Guimarães',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.792879104614258,
    MAXIMO: 32.18115997314453,
    MEDIA: 31.34361905636995,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f8883597-cd26-4801-afea-8569402f428e',
    CIDADE: 'Cláudia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.28766441345215,
    MAXIMO: 30.624114990234375,
    MEDIA: 30.470278167724608,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6af67d0c-0466-46c1-8f83-45712861d93a',
    CIDADE: 'Cocalinho',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.910858154296875,
    MAXIMO: 30.867292404174805,
    MEDIA: 30.148618184603176,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '647780ae-ad70-43d9-ad72-c206ca3e2920',
    CIDADE: 'Colíder',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.757659912109375,
    MAXIMO: 31.182994842529297,
    MEDIA: 30.940459781222874,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '90706fb9-54aa-4b5a-83be-0e9b94ed0f83',
    CIDADE: 'Colniza',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 35.01905822753906,
    MAXIMO: 37.66435241699219,
    MEDIA: 36.12199523028205,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f6698e8e-7f24-410e-9980-63b2d786ab4e',
    CIDADE: 'Comodoro',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.36208724975586,
    MAXIMO: 48.450775146484375,
    MEDIA: 40.06948583266314,
    OFICIAL: 48,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '987ef3c3-0368-45dd-91df-f25dda529e8b',
    CIDADE: 'Confresa',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.236074447631836,
    MAXIMO: 30.42698097229004,
    MEDIA: 30.336135652330185,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6c8d37dc-3248-4e1a-8463-42b146feeaf8',
    CIDADE: "Conquista D'Oeste",
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.153356552124023,
    MAXIMO: 30.759416580200195,
    MEDIA: 30.45715675354004,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3073ba05-36c1-4676-824b-91fd01b508bd',
    CIDADE: 'Cotriguaçu',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.86558532714844,
    MAXIMO: 37.062843322753906,
    MEDIA: 35.93390418743265,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ea497e7d-223c-456a-9fa1-ac57de8f50e8',
    CIDADE: 'Cuiabá',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.31574821472168,
    MAXIMO: 34.700042724609375,
    MEDIA: 32.10342129794034,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e9160c04-063f-446d-899d-688f3aa8baa1',
    CIDADE: 'Curvelândia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.892940521240234,
    MAXIMO: 31.631946563720703,
    MEDIA: 31.295472758532185,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '304cd0e5-8ec8-4e45-be2a-4d88540e093f',
    CIDADE: 'Denise',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.2939453125,
    MAXIMO: 30.434581756591797,
    MEDIA: 30.37119738260905,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '508e454d-44ba-4657-9576-a24d8969e25e',
    CIDADE: 'Diamantino',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.022981643676758,
    MAXIMO: 30.46480369567871,
    MEDIA: 30.176450658727575,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c55b7631-28ca-4bad-80d2-725910e1518a',
    CIDADE: 'Dom Aquino',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.9335994720459,
    MAXIMO: 33.06188201904297,
    MEDIA: 32.51297351292202,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f4271e5e-1567-4e90-8726-9608811f0856',
    CIDADE: 'Feliz Natal',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.92867660522461,
    MAXIMO: 30.155088424682617,
    MEDIA: 30.038979394095286,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd443f440-b843-4eac-89a9-832cf22b6e52',
    CIDADE: "Figueirópolis D'Oeste",
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.507038116455078,
    MAXIMO: 30.80141830444336,
    MEDIA: 30.674055099487305,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5e4bfb5e-ec8c-4be2-b761-587eb071ac00',
    CIDADE: 'Gaúcha do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.827091217041016,
    MAXIMO: 30.117929458618164,
    MEDIA: 29.924785218148862,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7a687c2a-dc10-4dad-9311-acb83a00c6ce',
    CIDADE: 'General Carneiro',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.64470100402832,
    MAXIMO: 32.5459098815918,
    MEDIA: 32.071842193603516,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '998b72ef-dc7d-4c16-b5cd-9d0733d0d19d',
    CIDADE: "Glória D'Oeste",
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.182790756225586,
    MAXIMO: 31.66872787475586,
    MEDIA: 31.425759315490723,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6133f28f-b550-404a-ab00-3efa426e5f62',
    CIDADE: 'Guarantã do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.252002716064453,
    MAXIMO: 30.62510871887207,
    MEDIA: 30.45568733215332,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '57be9e6d-7535-4e05-b87a-2de675ceae29',
    CIDADE: 'Guiratinga',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 33.03901290893555,
    MAXIMO: 35.24091339111328,
    MEDIA: 34.089202642440796,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3c7055e-27e9-4d36-8821-ebeea8e1cab7',
    CIDADE: 'Indiavaí',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.395505905151367,
    MAXIMO: 30.577421188354492,
    MEDIA: 30.48646354675293,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '130eeb68-84bd-4d34-ba60-630ac2f57551',
    CIDADE: 'Ipiranga do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.70438575744629,
    MAXIMO: 30.976816177368164,
    MEDIA: 30.841932106018067,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ca431e1e-3093-406f-ad14-6016a147f211',
    CIDADE: 'Itanhangá',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.155414581298828,
    MAXIMO: 32.08250045776367,
    MEDIA: 31.55898560418023,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3bfdc3e9-b2b7-4aef-8da1-fd7005f904c3',
    CIDADE: 'Itaúba',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.476716995239258,
    MAXIMO: 31.525428771972656,
    MEDIA: 30.91181615193685,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0247a6fe-6909-41e7-bd9b-45dcd5bf23f2',
    CIDADE: 'Itiquira',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 36.48917770385742,
    MAXIMO: 38.698997497558594,
    MEDIA: 37.72054177743417,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '18de3c1c-c11b-4bc0-bb83-9bc708ae1f2a',
    CIDADE: 'Jaciara',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.61335754394531,
    MAXIMO: 34.06541442871094,
    MEDIA: 33.38273451063368,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '17f1e180-3432-4f7c-9688-c6a3accf701b',
    CIDADE: 'Jangada',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.194799423217773,
    MAXIMO: 31.5606746673584,
    MEDIA: 31.376962661743164,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '494e3f78-72a0-4ca1-809f-a09ec4f91987',
    CIDADE: 'Jauru',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.294523239135742,
    MAXIMO: 30.628318786621094,
    MEDIA: 30.426276206970215,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0daf3de0-88d0-4b15-a7c4-d1b0059e8ed0',
    CIDADE: 'Juara',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.61728286743164,
    MAXIMO: 37.005218505859375,
    MEDIA: 34.73380034310477,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '842dc9ff-b799-4536-abcf-1d3b77e3f965',
    CIDADE: 'Juína',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 37.84607696533203,
    MAXIMO: 46.4936408996582,
    MEDIA: 41.606547937160585,
    OFICIAL: 46,
    ISOPLETA: 5,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cc82b2b2-8c5a-4935-8e1a-a96b2f433c00',
    CIDADE: 'Juruena',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 36.51171112060547,
    MAXIMO: 38.29341506958008,
    MEDIA: 37.31753311157227,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '55aa3072-1832-479d-932d-2f6376a15539',
    CIDADE: 'Juscimeira',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 33.48923873901367,
    MAXIMO: 34.67620086669922,
    MEDIA: 34.009023666381836,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '61351e60-f7bf-4e44-82bf-c4464f69c842',
    CIDADE: "Lambari D'Oeste",
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.655227661132812,
    MAXIMO: 31.275117874145508,
    MEDIA: 30.930251757303875,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c49030b3-6f1c-4a84-988b-33693d055ae2',
    CIDADE: 'Lucas do Rio Verde',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.113727569580078,
    MAXIMO: 30.588886260986328,
    MEDIA: 30.328567504882812,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ed4a7643-b75a-4f2e-90f8-95dc06ef2373',
    CIDADE: 'Luciara',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.31818199157715,
    MAXIMO: 30.66864776611328,
    MEDIA: 30.492579460144043,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c73a3e0b-8e76-451a-8c45-edb20980f49b',
    CIDADE: 'Vila Bela da Santíssima Trindade',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.04576301574707,
    MAXIMO: 32.05852508544922,
    MEDIA: 30.53054324063388,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6fe1e800-da42-465f-97f6-b8cd096e04f5',
    CIDADE: 'Marcelândia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.04289436340332,
    MAXIMO: 30.43111801147461,
    MEDIA: 30.18806115174905,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '53f58b42-421a-4184-b41d-703924e770e1',
    CIDADE: 'Matupá',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.211654663085938,
    MAXIMO: 30.728622436523438,
    MEDIA: 30.370039531162806,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f44e7233-143f-4b41-9cf1-8be194ac2f6f',
    CIDADE: "Mirassol d'Oeste",
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.08311653137207,
    MAXIMO: 31.40967559814453,
    MEDIA: 31.26329231262207,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b52ddc51-45f8-4608-932e-b38b4e2ad84a',
    CIDADE: 'Nobres',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.226181030273438,
    MAXIMO: 30.62921142578125,
    MEDIA: 30.3937438084529,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '68078a71-3e18-4085-97fe-01e6313bf0a0',
    CIDADE: 'Nortelândia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.1326904296875,
    MAXIMO: 30.253036499023438,
    MEDIA: 30.189724922180176,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0d0146ac-85d4-4d9a-a0c6-f90f750d50a2',
    CIDADE: 'Nossa Senhora do Livramento',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.705289840698242,
    MAXIMO: 34.71351623535156,
    MEDIA: 32.662723440872995,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9414bba0-d666-4fc7-9448-81627a56fad2',
    CIDADE: 'Nova Bandeirantes',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.322959899902344,
    MAXIMO: 36.34967803955078,
    MEDIA: 35.11828104654948,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '76aadae1-7f75-4420-b0bf-239097267ff3',
    CIDADE: 'Nova Nazaré',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.97280502319336,
    MAXIMO: 30.60297203063965,
    MEDIA: 30.23403803507487,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '47adb12f-678a-4c2d-8428-66c0f6575798',
    CIDADE: 'Nova Lacerda',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.696252822875977,
    MAXIMO: 32.39056396484375,
    MEDIA: 31.54111644199916,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b92e2d9b-cfa3-4fdd-82c1-01534ce4aef7',
    CIDADE: 'Nova Santa Helena',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.367870330810547,
    MAXIMO: 30.63886260986328,
    MEDIA: 30.49027156829834,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '67cc0d92-7f33-48da-8a0e-dafa4a77d682',
    CIDADE: 'Nova Brasilândia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.667762756347656,
    MAXIMO: 31.11900520324707,
    MEDIA: 30.884530809190537,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4f5257ad-cdc1-44c2-a297-1bd4ba475a8e',
    CIDADE: 'Nova Canaã do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.15810775756836,
    MAXIMO: 31.97065544128418,
    MEDIA: 31.5044807434082,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0bd1a7de-a830-4aba-ba1f-66a58272b9db',
    CIDADE: 'Nova Mutum',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.034780502319336,
    MAXIMO: 30.452167510986328,
    MEDIA: 30.146126202174596,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3c012bac-473c-4dbb-b936-a3904cf3f8eb',
    CIDADE: 'Nova Olímpia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.259971618652344,
    MAXIMO: 30.499902725219727,
    MEDIA: 30.348164558410645,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7c6d57bf-3279-49a5-a6f8-b46c06f67872',
    CIDADE: 'Nova Ubiratã',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.926969528198242,
    MAXIMO: 30.107912063598633,
    MEDIA: 30.007158083793446,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0deede02-6c07-48d7-9900-cf0ac5c351f9',
    CIDADE: 'Nova Xavantina',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.364564895629883,
    MAXIMO: 31.214162826538086,
    MEDIA: 30.6699583871024,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2ded742f-d8dc-4cd7-a5c4-ce1aa37a35ff',
    CIDADE: 'Novo Mundo',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.582229614257812,
    MAXIMO: 31.265905380249023,
    MEDIA: 30.907767346030788,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a5b075c7-31e2-41d4-9048-49e747844752',
    CIDADE: 'Novo Horizonte do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 33.519744873046875,
    MAXIMO: 34.06389617919922,
    MEDIA: 33.79182052612305,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7fbefe36-2915-4306-9313-9e639d97eeaa',
    CIDADE: 'Novo São Joaquim',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.865276336669922,
    MAXIMO: 31.727863311767578,
    MEDIA: 31.379886627197266,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e1f89231-7aa6-49be-a9e9-d554de9bffc6',
    CIDADE: 'Paranaíta',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.991531372070312,
    MAXIMO: 32.96319580078125,
    MEDIA: 32.380795405461235,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '375b7d8c-966e-4807-980a-d8309f90e553',
    CIDADE: 'Paranatinga',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.905298233032227,
    MAXIMO: 30.765457153320312,
    MEDIA: 30.200457611083984,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'de6081e3-05b6-4c85-a077-97fe0136db68',
    CIDADE: 'Novo Santo Antônio',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.034400939941406,
    MAXIMO: 30.31551742553711,
    MEDIA: 30.12607220241002,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e845660c-81c2-4602-be8b-85356da0fc5a',
    CIDADE: 'Pedra Preta',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.89884948730469,
    MAXIMO: 36.7747802734375,
    MEDIA: 35.80315223106971,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1837d9f5-1cdb-4826-acb5-401b6d3291b0',
    CIDADE: 'Peixoto de Azevedo',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.06059455871582,
    MAXIMO: 30.634132385253906,
    MEDIA: 30.20181423684825,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '889a3860-bd87-4ccb-8205-1a09f89c01d8',
    CIDADE: 'Planalto da Serra',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.555814743041992,
    MAXIMO: 30.934402465820312,
    MEDIA: 30.737754276820592,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '855af42a-43c0-45bc-b023-f8e77d8d0996',
    CIDADE: 'Poconé',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.58767318725586,
    MAXIMO: 39.537803649902344,
    MEDIA: 36.119313166691704,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3652ce00-7e01-4c23-b0bb-21361fb2af14',
    CIDADE: 'Pontal do Araguaia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.24563217163086,
    MAXIMO: 32.974632263183594,
    MEDIA: 32.58921146392822,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7100ec19-23fa-4c04-9738-b9f3c661dcc1',
    CIDADE: 'Ponte Branca',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.01520919799805,
    MAXIMO: 35.12278366088867,
    MEDIA: 34.48484524967988,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8ac05907-eb30-4c99-b45f-fbb55f27518a',
    CIDADE: 'Pontes e Lacerda',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.056171417236328,
    MAXIMO: 31.05735206604004,
    MEDIA: 30.44452329782339,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ec1f1512-dce9-42d5-a8eb-75bcf5356e83',
    CIDADE: 'Porto Alegre do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.195810317993164,
    MAXIMO: 30.524534225463867,
    MEDIA: 30.315873579545453,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '82ed1e76-e400-479e-af49-80bb0f17bf4c',
    CIDADE: 'Porto dos Gaúchos',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.02501678466797,
    MAXIMO: 33.42052459716797,
    MEDIA: 32.05384081060236,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'daf78ed4-1764-4659-9b36-55395ab02b07',
    CIDADE: 'Porto Esperidião',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.546842575073242,
    MAXIMO: 31.906301498413086,
    MEDIA: 31.247863167210628,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '26484b1a-ff92-4cc0-b84e-9046ce13d218',
    CIDADE: 'Porto Estrela',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.21277618408203,
    MAXIMO: 31.940860748291016,
    MEDIA: 31.55925042288644,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2e72f6a9-5994-4683-a927-5faa5b99c93f',
    CIDADE: 'Poxoréu',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.905601501464844,
    MAXIMO: 33.95158767700195,
    MEDIA: 32.902429060502485,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e14a3001-6274-41a3-b54f-a5e13476c009',
    CIDADE: 'Primavera do Leste',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.940940856933594,
    MAXIMO: 32.2643928527832,
    MEDIA: 31.44777140897863,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '62a2394f-7d1c-4af8-8b8d-3d54d039184c',
    CIDADE: 'Querência',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.835527420043945,
    MAXIMO: 29.975370407104492,
    MEDIA: 29.898568656227805,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e5db2203-5d44-43e9-b112-b81df56bdab8',
    CIDADE: 'São José dos Quatro Marcos',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.810781478881836,
    MAXIMO: 30.98788833618164,
    MEDIA: 30.89689000447591,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd9d6b9c4-2930-4a90-ba39-cf52ce10201f',
    CIDADE: 'Reserva do Cabaçal',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.077884674072266,
    MAXIMO: 30.29145622253418,
    MEDIA: 30.17005681991577,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fa2ffd94-bc8a-4cb9-bea2-243845b2f69c',
    CIDADE: 'Ribeirão Cascalheira',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.902873992919922,
    MAXIMO: 30.048831939697266,
    MEDIA: 29.94045693533761,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ce5a0382-cea0-4f05-ba59-a64acb4d9db6',
    CIDADE: 'Ribeirãozinho',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 33.61067581176758,
    MAXIMO: 34.13774871826172,
    MEDIA: 33.92121124267578,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3521533c-5d8f-46cf-a172-4137b314aaa9',
    CIDADE: 'Rio Branco',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.517024993896484,
    MAXIMO: 30.584632873535156,
    MEDIA: 30.55082893371582,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fb2de8d4-0303-4299-9e7b-e3c468ed5ea0',
    CIDADE: 'Santa Carmem',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.118932723999023,
    MAXIMO: 30.44076156616211,
    MEDIA: 30.27597125371297,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6c80e344-1c2f-493c-bacc-7d914009aba5',
    CIDADE: 'Santo Afonso',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.077945709228516,
    MAXIMO: 30.151687622070312,
    MEDIA: 30.105984687805176,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ace424b0-91b2-4350-b37f-038e38aa790a',
    CIDADE: 'São José do Povo',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 34.39354705810547,
    MAXIMO: 35.06148910522461,
    MEDIA: 34.64144515991211,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f662a616-818f-465c-8472-71286d38134d',
    CIDADE: 'São José do Rio Claro',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.031761169433594,
    MAXIMO: 30.555395126342773,
    MEDIA: 30.2497798204422,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '24d15487-a31d-4c78-ae14-d1fdeec5ebec',
    CIDADE: 'São José do Xingu',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.02136993408203,
    MAXIMO: 30.212627410888672,
    MEDIA: 30.122009701199,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bdeebf81-1460-4936-889a-da8ac5fd50b1',
    CIDADE: 'São Pedro da Cipa',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 33.05541229248047,
    MAXIMO: 33.537200927734375,
    MEDIA: 33.14746445235507,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b8b9cbf9-ca3b-4e8c-9c4f-8f7a520f23a9',
    CIDADE: 'Rondolândia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 37.36591339111328,
    MAXIMO: 40.344818115234375,
    MEDIA: 38.75211524963379,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '669bba6e-fe31-42e8-9096-23cbdab0b667',
    CIDADE: 'Rondonópolis',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 33.98849105834961,
    MAXIMO: 36.922576904296875,
    MEDIA: 35.38065256391253,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e72ecd04-e692-4d51-a618-c1cc2aa6d00b',
    CIDADE: 'Rosário Oeste',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.388063430786133,
    MAXIMO: 31.429006576538086,
    MEDIA: 30.72681817141446,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eb4adef8-a6f1-4012-9f87-2b6f604d4f51',
    CIDADE: 'Santa Cruz do Xingu',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.12785530090332,
    MAXIMO: 30.322790145874023,
    MEDIA: 30.196709913365982,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '80a9a27d-36aa-408e-ad27-80efdf2dbb9e',
    CIDADE: 'Salto do Céu',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.21009063720703,
    MAXIMO: 30.520654678344727,
    MEDIA: 30.3662592569987,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '387011dc-6d5c-4522-9d81-805ef51e68de',
    CIDADE: 'Santa Rita do Trivelato',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.004926681518555,
    MAXIMO: 30.27122688293457,
    MEDIA: 30.127893039158412,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1c645e35-f4ad-4ff8-a241-59bad0af85a6',
    CIDADE: 'Santa Terezinha',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.474721908569336,
    MAXIMO: 31.25813865661621,
    MEDIA: 30.78115289861506,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e5c10f57-3593-4a70-b163-e3dfa0ae1de7',
    CIDADE: 'Santo Antônio do Leste',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.74919319152832,
    MAXIMO: 31.384765625,
    MEDIA: 31.009939511617024,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '59bca2ac-01d2-479f-9ee7-fa57d4e7b35a',
    CIDADE: 'Santo Antônio de Leverger',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.47689437866211,
    MAXIMO: 37.67219161987305,
    MEDIA: 35.053091972104966,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a9221172-00a8-4de9-9346-2d251ab6d736',
    CIDADE: 'São Félix do Araguaia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 29.938766479492188,
    MAXIMO: 30.487895965576172,
    MEDIA: 30.131569312169002,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ae249001-c59c-47a4-bdf5-8f0a2147d24a',
    CIDADE: 'Sapezal',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.800430297851562,
    MAXIMO: 40.24762725830078,
    MEDIA: 35.37579505388127,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '55f8468d-9785-48c5-9098-631fca8501ea',
    CIDADE: 'Serra Nova Dourada',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.05497169494629,
    MAXIMO: 30.173709869384766,
    MEDIA: 30.093437671661377,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3270eddb-465c-460f-adc7-ed0d6f8d6e9b',
    CIDADE: 'Sinop',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.436302185058594,
    MAXIMO: 31.025348663330078,
    MEDIA: 30.666895646315353,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7fa26c2c-761a-44a5-bda3-baf7dd87454b',
    CIDADE: 'Sorriso',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.008285522460938,
    MAXIMO: 30.73494529724121,
    MEDIA: 30.2816157187185,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c45ac365-f4bf-4a5c-b973-cba941190101',
    CIDADE: 'Tabaporã',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.087671279907227,
    MAXIMO: 33.08562469482422,
    MEDIA: 32.04796868783456,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6e75419f-4553-4dd6-85ba-6e59c671a7e8',
    CIDADE: 'Tangará da Serra',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.01966094970703,
    MAXIMO: 31.18202018737793,
    MEDIA: 30.32375865512424,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '775cda46-ed5c-491b-8937-63b6c84ebfb2',
    CIDADE: 'Tapurah',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.514324188232422,
    MAXIMO: 31.68546485900879,
    MEDIA: 30.879899724324545,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '21108c3a-b7a9-4bf0-9779-04734fa99d54',
    CIDADE: 'Terra Nova do Norte',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.537307739257812,
    MAXIMO: 30.820310592651367,
    MEDIA: 30.644929885864258,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6cd36006-503e-416b-84fc-6c5dd1925583',
    CIDADE: 'Tesouro',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.45524597167969,
    MAXIMO: 33.314697265625,
    MEDIA: 32.802133386785336,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '17bc2f2b-011a-4dd4-90ff-a9e3b823718f',
    CIDADE: 'Torixoréu',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.950096130371094,
    MAXIMO: 34.2650032043457,
    MEDIA: 33.53019986833845,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '70480aa9-4403-4306-b6c5-a1d51454c4fd',
    CIDADE: 'União do Sul',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.042879104614258,
    MAXIMO: 30.364133834838867,
    MEDIA: 30.139746220906577,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ba06afbc-8a38-494c-80bb-278964695661',
    CIDADE: 'Vale de São Domingos',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.162586212158203,
    MAXIMO: 30.367387771606445,
    MEDIA: 30.236597696940105,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'de1aaf7b-9591-4e11-83ef-06ed916b3b58',
    CIDADE: 'Várzea Grande',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 31.621768951416016,
    MAXIMO: 32.432735443115234,
    MEDIA: 32.027252197265625,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8ce4f427-5468-4d21-bb90-db7772ba964b',
    CIDADE: 'Vera',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.008270263671875,
    MAXIMO: 30.325824737548828,
    MEDIA: 30.213659459894355,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '51176b5c-fbd1-42b0-9686-0a969fc3093c',
    CIDADE: 'Vila Rica',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.234973907470703,
    MAXIMO: 30.82284927368164,
    MEDIA: 30.499386700716887,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b9489487-6f43-42d9-b15e-83deea8ca126',
    CIDADE: 'Nova Guarita',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.850168228149414,
    MAXIMO: 30.98332405090332,
    MEDIA: 30.900034586588543,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '674eacac-4cc1-46f5-aaa3-07a7259c8e67',
    CIDADE: 'Nova Marilândia',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.025800704956055,
    MAXIMO: 30.115816116333008,
    MEDIA: 30.06821632385254,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '70f13fd4-80f1-4548-b7d0-88894c848ee4',
    CIDADE: 'Nova Maringá',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 30.422229766845703,
    MAXIMO: 33.662757873535156,
    MEDIA: 31.632132829938616,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0881700a-0285-46ba-955e-9d602797de11',
    CIDADE: 'Nova Monte Verde',
    SIGLA: 'MT',
    ESTADO: 'Mato Grosso',
    MINIMO: 32.86668014526367,
    MAXIMO: 34.350685119628906,
    MEDIA: 33.52952814102173,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3ca62944-94d2-4152-8ac2-4c4385448408',
    CIDADE: 'Abadia de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.90978240966797,
    MAXIMO: 35.62866973876953,
    MEDIA: 35.231144895781696,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bc3f6cba-2398-4169-8a38-ba4c32758a95',
    CIDADE: 'Abadiânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.69583511352539,
    MAXIMO: 35.164329528808594,
    MEDIA: 34.94094543457031,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd7fe8d87-421e-496f-926b-d4fc47d6ab9c',
    CIDADE: 'Acreúna',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.76875305175781,
    MAXIMO: 35.3691291809082,
    MEDIA: 35.0062759399414,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f16c4c76-b1c2-4abc-bd7a-ae434ecb96fb',
    CIDADE: 'Adelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.231021881103516,
    MAXIMO: 33.69993591308594,
    MEDIA: 33.560712102670585,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e45d5ba2-d8ec-4f7e-b9f8-ae264e179143',
    CIDADE: 'Água Fria de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.23662757873535,
    MAXIMO: 31.880279541015625,
    MEDIA: 30.971641540527344,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1f52638e-bee2-46c4-81fe-91b254cb4c11',
    CIDADE: 'Água Limpa',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 39.77360916137695,
    MAXIMO: 40.31310272216797,
    MEDIA: 40.04335594177246,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '08ffa777-e658-40ea-9e7d-2a706cea2f4f',
    CIDADE: 'Águas Lindas de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.308189392089844,
    MAXIMO: 35.56032180786133,
    MEDIA: 35.3812369049774,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '32fc55dc-f0d3-4aaf-b515-2790cdc4f5f8',
    CIDADE: 'Alexânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.755672454833984,
    MAXIMO: 35.357826232910156,
    MEDIA: 35.0252448442486,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3e419f5-f55f-431d-8020-d3adfc3fcd91',
    CIDADE: 'Aloândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.999229431152344,
    MAXIMO: 36.999229431152344,
    MEDIA: 36.999229431152344,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3adc91f9-b65a-440f-915e-0dff52686738',
    CIDADE: 'Alto Horizonte',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.98143196105957,
    MAXIMO: 30.08304786682129,
    MEDIA: 30.03223991394043,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7125946d-a8a0-416c-b5dd-fbac475b99a7',
    CIDADE: 'Alto Paraíso de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.711769104003906,
    MAXIMO: 29.87933921813965,
    MEDIA: 29.7828586101532,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '83b7b1c5-d4bd-4dae-91c0-e1e7cbf8f2f1',
    CIDADE: 'Alvorada do Norte',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.640167236328125,
    MAXIMO: 29.678142547607422,
    MEDIA: 29.661378224690754,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8ca62194-d042-45e3-862e-d7da357ac771',
    CIDADE: 'Amaralina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.934667587280273,
    MAXIMO: 29.96578025817871,
    MEDIA: 29.94689130783081,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9097b0b1-647e-41bd-a442-ab0f5d9bd966',
    CIDADE: 'Americano do Brasil',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.36750030517578,
    MAXIMO: 33.881282806396484,
    MEDIA: 33.440988923788396,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '947f922c-cd34-41ad-a6be-27c9d60c8892',
    CIDADE: 'Amorinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.35418701171875,
    MAXIMO: 33.619441986083984,
    MEDIA: 33.443748474121094,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0a76cf9e-e96a-4f67-8e2f-17f8257f53b4',
    CIDADE: 'Anápolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.81028747558594,
    MAXIMO: 35.09055709838867,
    MEDIA: 34.950422286987305,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '75dab7ba-6013-4b47-a80e-a8b7a4b44765',
    CIDADE: 'Anhanguera',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 41.864593505859375,
    MAXIMO: 42.030582427978516,
    MEDIA: 41.963343691602915,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '191d19cb-8b7a-454a-a1ee-07629deeee7c',
    CIDADE: 'Anicuns',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.69993591308594,
    MAXIMO: 33.881282806396484,
    MEDIA: 33.79060935974121,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ef34ea01-cc61-48c2-b186-aa9f7e185e21',
    CIDADE: 'Aparecida de Goiânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.363834381103516,
    MAXIMO: 36.128055572509766,
    MEDIA: 35.719753025221586,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9e249c93-9916-4a47-9163-f7b625fd0938',
    CIDADE: 'Aparecida do Rio Doce',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.62922286987305,
    MAXIMO: 35.842308044433594,
    MEDIA: 35.766560872395836,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3921dfc2-b59e-43b0-b0aa-5607bd718209',
    CIDADE: 'Aporé',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.359867095947266,
    MAXIMO: 38.3299674987793,
    MEDIA: 37.842323303222656,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8e554ba4-11b8-4e0a-b174-601a23c1a8e5',
    CIDADE: 'Araçu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.62143325805664,
    MAXIMO: 34.265037536621094,
    MEDIA: 34.02673527457644,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '64c708ac-1a11-44d6-a819-a283492aa496',
    CIDADE: 'Aragarças',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.30773162841797,
    MAXIMO: 32.680484771728516,
    MEDIA: 32.41904067993164,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'abcde60b-fb33-4744-9ed7-ea53d2b05433',
    CIDADE: 'Aragoiânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.62866973876953,
    MAXIMO: 35.946224212646484,
    MEDIA: 35.76506030665733,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '08b2ded3-ee40-444b-ae2a-e2cce4821117',
    CIDADE: 'Araguapaz',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.021574020385742,
    MAXIMO: 31.289352416992188,
    MEDIA: 31.133103942871095,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'eddf382f-45b7-4100-8e88-0cde346ade6c',
    CIDADE: 'Arenópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.83496856689453,
    MAXIMO: 33.41676330566406,
    MEDIA: 33.1396484375,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6828ce77-ef73-4d5c-8c8e-7f00b3cd0237',
    CIDADE: 'Aruanã',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.195680618286133,
    MAXIMO: 31.233699798583984,
    MEDIA: 30.74863338470459,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7f4e8219-d16c-4f0c-b9bb-b9684dfe6b08',
    CIDADE: 'Aurilândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.503177642822266,
    MAXIMO: 33.954410552978516,
    MEDIA: 33.741844177246094,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1c03dc46-5bd2-402f-8f08-a1240a72c09a',
    CIDADE: 'Avelinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.05413055419922,
    MAXIMO: 34.35613250732422,
    MEDIA: 34.26584393517332,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3680acc-1347-4c88-ad04-c7168d6164d9',
    CIDADE: 'Baliza',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.09880065917969,
    MAXIMO: 33.504066467285156,
    MEDIA: 33.29096508026123,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'da0da69a-053b-434f-bef1-ba6189e93a8a',
    CIDADE: 'Barro Alto',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.87755584716797,
    MAXIMO: 31.622085571289062,
    MEDIA: 31.238648732503254,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '05718757-19c8-40e8-a78f-3d51910916f5',
    CIDADE: 'Bela Vista de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.128055572509766,
    MAXIMO: 36.81781768798828,
    MEDIA: 36.497557640075684,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '74618d5e-145b-4a09-a77d-d100e374a8cf',
    CIDADE: 'Bom Jardim de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.64126205444336,
    MAXIMO: 33.3807258605957,
    MEDIA: 32.93205795288086,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b906031a-f6bd-46c1-ad70-c90b65956140',
    CIDADE: 'Bom Jesus de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.43367385864258,
    MAXIMO: 36.754676818847656,
    MEDIA: 36.03943729400635,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8c9d1977-8d94-4c65-b158-e6ff275ff1a9',
    CIDADE: 'Bonfinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.228919982910156,
    MAXIMO: 35.953369140625,
    MEDIA: 35.528398655310504,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '65427af5-6bfc-4198-b9a7-ed511f435ed6',
    CIDADE: 'Bonópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.9348087310791,
    MAXIMO: 29.95359992980957,
    MEDIA: 29.94365119934082,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '399576e8-094a-498e-a09e-fba4d1c15d6c',
    CIDADE: 'Brazabrantes',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.011474609375,
    MAXIMO: 34.70623779296875,
    MEDIA: 34.533191066643816,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'acb5d18c-c5f2-497b-a858-1c8371100c4a',
    CIDADE: 'Britânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.041419982910156,
    MAXIMO: 31.49150848388672,
    MEDIA: 31.259783426920574,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ec284c4d-af1e-4592-b862-a3f3b8de5952',
    CIDADE: 'Buriti Alegre',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 38.56756591796875,
    MAXIMO: 39.63469314575195,
    MEDIA: 39.14390563964844,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ba1dece3-f433-4e45-9201-fbaab66d3a35',
    CIDADE: 'Buriti de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.74570846557617,
    MAXIMO: 33.12446212768555,
    MEDIA: 32.98764634030216,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5de8688e-5cb3-476f-898a-13551c85026d',
    CIDADE: 'Buritinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.613990783691406,
    MAXIMO: 29.619768142700195,
    MEDIA: 29.6168794631958,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e8a7cde0-f8ef-47c9-9d12-f77d11ecf721',
    CIDADE: 'Cabeceiras',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.05594253540039,
    MAXIMO: 34.23136901855469,
    MEDIA: 33.150552113850914,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '768ce653-5007-416e-91dc-61d63acb961c',
    CIDADE: 'Cachoeira Alta',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.38641357421875,
    MAXIMO: 35.873931884765625,
    MEDIA: 35.66262969970703,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b9200871-7f76-4b7e-9d3f-48af4f9b5006',
    CIDADE: 'Cachoeira de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.4188232421875,
    MAXIMO: 34.07358932495117,
    MEDIA: 33.71301417025166,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7a001fbc-5928-427d-9b0c-717f7bb639f9',
    CIDADE: 'Cachoeira Dourada',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.544803619384766,
    MAXIMO: 37.325435638427734,
    MEDIA: 36.93511962890625,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '794c8976-2df4-4d18-8d32-c84b0a6b77a1',
    CIDADE: 'Caçu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.74396514892578,
    MAXIMO: 36.27711868286133,
    MEDIA: 36.05868312290737,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cff49f2b-f0e3-4175-b335-b54e7f1f7ff9',
    CIDADE: 'Caiapônia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.64082717895508,
    MAXIMO: 35.53852844238281,
    MEDIA: 34.4899708884103,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e7aa1d08-67c3-4987-b111-8b053d81c742',
    CIDADE: 'Caldas Novas',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 38.396507263183594,
    MAXIMO: 39.53839111328125,
    MEDIA: 38.92201614379883,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '31e49cb0-821a-4156-bd5f-ef8cccfa10b7',
    CIDADE: 'Caldazinha',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.708316802978516,
    MAXIMO: 36.32307052612305,
    MEDIA: 35.95599691377741,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0c21d341-8522-43f8-b868-a3d1ea0687d4',
    CIDADE: 'Campestre de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.6753044128418,
    MAXIMO: 35.13082504272461,
    MEDIA: 34.87681417083264,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b9739365-77b8-423d-863b-9245467eecce',
    CIDADE: 'Campinaçu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.928529739379883,
    MAXIMO: 30.01848793029785,
    MEDIA: 29.955608050028484,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fe960678-84e8-482a-91c7-703e8dd8bb15',
    CIDADE: 'Campinorte',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.959339141845703,
    MAXIMO: 30.15667152404785,
    MEDIA: 30.038097858428955,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7abbc18d-3ca2-4bb9-b5d0-a2de96bfbf7a',
    CIDADE: 'Campo Alegre de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.04294204711914,
    MAXIMO: 38.94945526123047,
    MEDIA: 37.866952078683035,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5ae1c3cf-ca79-41cb-b710-1b6343c340f6',
    CIDADE: 'Campo Limpo de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.460899353027344,
    MAXIMO: 35.09055709838867,
    MEDIA: 34.55867717028799,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '78b49d97-6570-48e7-9152-1277e8727c8f',
    CIDADE: 'Campos Belos',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.12013053894043,
    MAXIMO: 30.434423446655273,
    MEDIA: 30.262133692440074,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0ffdfc1f-9a1e-4674-8994-97b6fb7379ec',
    CIDADE: 'Campos Verdes',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.997610092163086,
    MAXIMO: 30.105579376220703,
    MEDIA: 30.055009932175718,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '93eda4f2-2c83-470a-90e3-81c651c0230a',
    CIDADE: 'Carmo do Rio Verde',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.50290298461914,
    MAXIMO: 32.198402404785156,
    MEDIA: 31.897846083470444,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '489455cc-64c4-4cf9-83b6-ac18c63d33e5',
    CIDADE: 'Castelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.016090393066406,
    MAXIMO: 35.49968719482422,
    MEDIA: 35.14615909043418,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f33b1c95-4774-42a2-8a89-8a274575aab5',
    CIDADE: 'Catalão',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.187713623046875,
    MAXIMO: 40.69782638549805,
    MEDIA: 38.13763173421224,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '672be6f0-f4bd-4534-84cd-1605df83c39f',
    CIDADE: 'Caturaí',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.05413055419922,
    MAXIMO: 34.80634689331055,
    MEDIA: 34.386094905248186,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f5f02438-3e62-460c-babf-701bb38ceeca',
    CIDADE: 'Cavalcante',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.750896453857422,
    MAXIMO: 29.980125427246094,
    MEDIA: 29.864378669045188,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '369d5545-9662-4cf8-bbfd-6d71dc659cbb',
    CIDADE: 'Ceres',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.50290298461914,
    MAXIMO: 31.9666748046875,
    MEDIA: 31.610728426979463,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '27d07762-fb72-4961-914e-6555f585cdf9',
    CIDADE: 'Cezarina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.75615692138672,
    MAXIMO: 36.172096252441406,
    MEDIA: 35.36352623390715,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3a3cf606-7021-4a72-8e97-b4bcd4c5d84a',
    CIDADE: 'Chapadão do Céu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.9490852355957,
    MAXIMO: 38.935386657714844,
    MEDIA: 38.314347403390066,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '94ce13e8-2efd-41ca-9e04-fb8d0744a6e9',
    CIDADE: 'Cidade Ocidental',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.33096694946289,
    MAXIMO: 35.50757598876953,
    MEDIA: 35.41927146911621,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ca6df482-aae5-488a-b642-a16ccb636640',
    CIDADE: 'Cocalzinho de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.432777404785156,
    MAXIMO: 35.05710983276367,
    MEDIA: 34.12773513793945,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fe40ea7e-4b2c-4fca-b5b5-9dc6e3fc1e1d',
    CIDADE: 'Colinas do Sul',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.823482513427734,
    MAXIMO: 29.86465835571289,
    MEDIA: 29.848543548583983,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7ad9af7c-8615-48c1-b37f-86e5485a5d67',
    CIDADE: 'Córrego do Ouro',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.03266525268555,
    MAXIMO: 33.2626953125,
    MEDIA: 33.14768028259277,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b39904bb-6880-4e39-8f36-bac66fbd860f',
    CIDADE: 'Corumbá de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.40203857421875,
    MAXIMO: 35.07931900024414,
    MEDIA: 34.78735160827637,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '11732dc3-ab47-4bd5-92dd-065c1d56634c',
    CIDADE: 'Corumbaíba',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 39.695735931396484,
    MAXIMO: 41.93049240112305,
    MEDIA: 40.82752482096354,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '155b0f17-89ac-414b-937b-6b4b8cec596f',
    CIDADE: 'Cristalina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.07307815551758,
    MAXIMO: 36.262481689453125,
    MEDIA: 35.5400641305106,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '67560e6c-8643-4037-8ede-b327c20b880c',
    CIDADE: 'Cristianópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.252506256103516,
    MAXIMO: 37.40520095825195,
    MEDIA: 37.328853607177734,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '90793190-0f61-478e-8ffb-f13e0a5ad0d4',
    CIDADE: 'Crixás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.086973190307617,
    MAXIMO: 31.078405380249023,
    MEDIA: 30.484632043277514,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '729710c7-8cda-43ac-bf3e-e258d9232b59',
    CIDADE: 'Cromínia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.24201965332031,
    MAXIMO: 36.87507247924805,
    MEDIA: 36.490323468982155,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0d7b8782-55a7-4e6c-9abe-87927c5478c8',
    CIDADE: 'Cumari',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 41.384132385253906,
    MAXIMO: 42.030582427978516,
    MEDIA: 41.759769439697266,
    OFICIAL: 42,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '651be51c-72df-42d5-acec-291666dedcd3',
    CIDADE: 'Damianópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.593835830688477,
    MAXIMO: 29.624858856201172,
    MEDIA: 29.611842195908533,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '66927374-c89c-47c2-ab5d-ac489798bb91',
    CIDADE: 'Damolândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.011474609375,
    MAXIMO: 34.70623779296875,
    MEDIA: 34.19496251733227,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9a5f00c5-8804-4ce5-831e-754fa5cdc72e',
    CIDADE: 'Davinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 38.16423034667969,
    MAXIMO: 39.37980270385742,
    MEDIA: 38.79315041077183,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7fa6b636-d12b-42c7-b907-9ab2539975aa',
    CIDADE: 'Diorama',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.85397720336914,
    MAXIMO: 32.86520767211914,
    MEDIA: 32.85959243774414,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f48877c7-cc28-48be-843a-c1076eb30881',
    CIDADE: 'Doverlândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.797027587890625,
    MAXIMO: 35.27811813354492,
    MEDIA: 34.46253089904785,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b15e5253-f90b-4a67-a286-dbd99c7cca6b',
    CIDADE: 'Edealina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.62527084350586,
    MAXIMO: 36.39027404785156,
    MEDIA: 35.92607803168805,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c33d0159-a340-44f9-a0aa-0d5758d0ba59',
    CIDADE: 'Edéia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.472408294677734,
    MAXIMO: 35.83728790283203,
    MEDIA: 35.622727394104004,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e9cc198b-1cb2-4edb-8c99-edba55e6da49',
    CIDADE: 'Estrela do Norte',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.957706451416016,
    MAXIMO: 29.996118545532227,
    MEDIA: 29.96417995063226,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7df23c22-404a-43c9-9859-973a2bac9bd4',
    CIDADE: 'Faina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.313066482543945,
    MAXIMO: 31.90657615661621,
    MEDIA: 31.59369604928153,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '71d356e1-a27b-4719-b058-dacfbe185bde',
    CIDADE: 'Fazenda Nova',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.551918029785156,
    MAXIMO: 33.174381256103516,
    MEDIA: 32.81209945678711,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b94ca91b-3c49-44bf-92d6-53e895326aaa',
    CIDADE: 'Firminópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.82483673095703,
    MAXIMO: 34.09821701049805,
    MEDIA: 33.96152687072754,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '453001ca-51f3-4ac7-841a-ceafab4fa0de',
    CIDADE: 'Flores de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.69773292541504,
    MAXIMO: 29.93853759765625,
    MEDIA: 29.763361136118572,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '45b2b17e-1557-41d2-951d-c4e153a4389b',
    CIDADE: 'Formosa',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.76712417602539,
    MAXIMO: 35.5233154296875,
    MEDIA: 32.10947147168611,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '56d13738-b64e-4903-9bf2-509e14302896',
    CIDADE: 'Formoso',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.96346664428711,
    MAXIMO: 29.965669631958008,
    MEDIA: 29.96456813812256,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'df92847d-eee4-47c9-9b22-40c8ce72a206',
    CIDADE: 'Gameleira de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.376529693603516,
    MAXIMO: 35.43528366088867,
    MEDIA: 35.405906677246094,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '046771d1-82c6-403c-aaa3-334c815a807b',
    CIDADE: 'Divinópolis de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.026987075805664,
    MAXIMO: 30.164794921875,
    MEDIA: 30.084611256917317,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9adb6192-cd30-4f5c-bbcb-469b19b00472',
    CIDADE: 'Goianápolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.81028747558594,
    MAXIMO: 35.59111022949219,
    MEDIA: 35.24694101943823,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bd178b69-24a0-4383-8c52-b8c843f82829',
    CIDADE: 'Goiandira',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 40.32301712036133,
    MAXIMO: 41.06467819213867,
    MEDIA: 40.69384765625,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e347444a-6633-4e20-b1f4-4510e0a69d1f',
    CIDADE: 'Goianésia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.397676467895508,
    MAXIMO: 32.45652389526367,
    MEDIA: 31.989129066467285,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '23559e3c-9a63-4880-bda9-43780228ec2f',
    CIDADE: 'Goiânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.920005798339844,
    MAXIMO: 35.363834381103516,
    MEDIA: 35.14192008972168,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c42539d1-888e-4246-9534-20635ae1bcd9',
    CIDADE: 'Goianira',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.489925384521484,
    MAXIMO: 34.920005798339844,
    MEDIA: 34.71055285749863,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '43ac7881-525e-4c0e-9e58-918cf892ac5a',
    CIDADE: 'Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.812612533569336,
    MAXIMO: 32.84035873413086,
    MEDIA: 32.43129482269287,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f3dea228-4ec2-4fdc-ac5d-dd9d792d5c7f',
    CIDADE: 'Goiatuba',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.49968719482422,
    MAXIMO: 37.93050003051758,
    MEDIA: 36.505332946777344,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a8991c93-5444-4c3d-88d6-da4be1cbcd15',
    CIDADE: 'Gouvelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.00940704345703,
    MAXIMO: 35.325618743896484,
    MEDIA: 35.14165019989014,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '877f4762-73fe-49e3-891c-7ed7fd499dce',
    CIDADE: 'Guapó',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.13082504272461,
    MAXIMO: 35.666507720947266,
    MEDIA: 35.47533416748047,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd26fd3fb-33de-41d8-969c-2022613cd95d',
    CIDADE: 'Guaraíta',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.982948303222656,
    MAXIMO: 32.44894790649414,
    MEDIA: 32.17848705766685,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '36b574ce-f50f-4bc9-8779-67637eeaf607',
    CIDADE: 'Guarani de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.77496337890625,
    MAXIMO: 29.847379684448242,
    MEDIA: 29.805702845255535,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '00ec261f-8f71-4f9a-b9e6-edcd559fd788',
    CIDADE: 'Guarinos',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.417991638183594,
    MAXIMO: 30.650836944580078,
    MEDIA: 30.534414291381836,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ab9cfb9c-89a7-4413-997d-7af62dfdcf64',
    CIDADE: 'Heitoraí',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.198402404785156,
    MAXIMO: 32.719669342041016,
    MEDIA: 32.546790242487795,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bf2667e3-8c29-4c76-a106-78043fcabfb4',
    CIDADE: 'Hidrolândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.90205383300781,
    MAXIMO: 36.496063232421875,
    MEDIA: 36.148396492004395,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9a7ddc46-eb72-4a3b-a65b-02ddbaea9bd8',
    CIDADE: 'Hidrolina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.696834564208984,
    MAXIMO: 30.748262405395508,
    MEDIA: 30.722548484802246,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '55212590-5b27-4334-8c84-088542605699',
    CIDADE: 'Iaciara',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.692134857177734,
    MAXIMO: 29.767436981201172,
    MEDIA: 29.728334808349608,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4db5332a-3bd9-46f0-b830-80e886267dc4',
    CIDADE: 'Inaciolândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.433265686035156,
    MAXIMO: 35.915794372558594,
    MEDIA: 35.674530029296875,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '07de1943-9482-485a-b49b-8cbb324b2eea',
    CIDADE: 'Indiara',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.99422073364258,
    MAXIMO: 35.62527084350586,
    MEDIA: 35.16856994628906,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9c65d35c-deac-49d1-a4d2-d9cdf6fe3d0a',
    CIDADE: 'Inhumas',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.799495697021484,
    MAXIMO: 34.265037536621094,
    MEDIA: 34.025335947672524,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '689f0e2c-6fef-47b0-808d-35020d982600',
    CIDADE: 'Ipameri',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.56532669067383,
    MAXIMO: 39.704856872558594,
    MEDIA: 38.2683844933143,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e60d0f79-ce37-4d44-95c1-bea892b7c29e',
    CIDADE: 'Ipiranga de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.13288116455078,
    MAXIMO: 31.59674835205078,
    MEDIA: 31.392215017360005,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dcd4f733-ff94-4afd-a914-a935e8dc32c7',
    CIDADE: 'Iporá',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.10199737548828,
    MAXIMO: 33.17195129394531,
    MEDIA: 33.14159266153971,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c037a1f1-64e5-4de5-a972-be1b1a65a9fd',
    CIDADE: 'Israelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.876129150390625,
    MAXIMO: 33.4188232421875,
    MEDIA: 33.08959688133405,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3ffc6fcc-d77f-4565-bb1e-a9a999f0ce34',
    CIDADE: 'Itaberaí',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.8489990234375,
    MAXIMO: 34.05413055419922,
    MEDIA: 33.394213104248045,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '89f229ce-f552-43e6-a7dd-f38b04d52554',
    CIDADE: 'Itaguari',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.11903762817383,
    MAXIMO: 33.522483825683594,
    MEDIA: 33.138316387638895,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '865ffa5d-8117-4311-9db5-bab1711cbeab',
    CIDADE: 'Itaguaru',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.32728958129883,
    MAXIMO: 33.11903762817383,
    MEDIA: 32.81585150177161,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9fd95188-046b-4874-b74f-5e1f257938e0',
    CIDADE: 'Itajá',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.41878128051758,
    MAXIMO: 37.30649948120117,
    MEDIA: 36.85240249633789,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd5936c7c-f1fa-4400-a2e6-4ea2ba82ff20',
    CIDADE: 'Itapaci',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.86625862121582,
    MAXIMO: 30.977312088012695,
    MEDIA: 30.91713269551595,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'becfba6d-133b-4fa4-87d0-0afe6ca9b840',
    CIDADE: 'Itapirapuã',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.76903533935547,
    MAXIMO: 32.35135269165039,
    MEDIA: 32.05449703761509,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f919993e-b931-46cd-80fa-0d94c6b55109',
    CIDADE: 'Itapuranga',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.66390037536621,
    MAXIMO: 32.44894790649414,
    MEDIA: 32.023159980773926,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '29333395-0794-4ec3-a75f-18f7a6803ff9',
    CIDADE: 'Itarumã',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.95779800415039,
    MAXIMO: 37.10236358642578,
    MEDIA: 36.56395492553711,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3b359abf-a662-476f-8580-358525971c94',
    CIDADE: 'Itauçu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.522483825683594,
    MAXIMO: 34.265037536621094,
    MEDIA: 33.77221853917239,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9d8c3b6a-0307-48a7-bdde-148212801c4e',
    CIDADE: 'Itumbiara',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.63945770263672,
    MAXIMO: 39.100135803222656,
    MEDIA: 37.53034257888794,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7f0c2bd7-0c69-448a-8b87-743ef8549c14',
    CIDADE: 'Ivolândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.37942886352539,
    MAXIMO: 33.87543487548828,
    MEDIA: 33.632816314697266,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4e896bbf-8dbd-44d9-a667-b8b0d9332415',
    CIDADE: 'Jandaia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.58394241333008,
    MAXIMO: 34.782588958740234,
    MEDIA: 34.712685902913414,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '52c43ef4-d3b8-43c2-b055-c96f572d0e06',
    CIDADE: 'Jaraguá',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.49076843261719,
    MAXIMO: 33.31486129760742,
    MEDIA: 32.91032333374024,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e6b7bb18-7917-415c-8125-de6161728219',
    CIDADE: 'Jataí',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.35541915893555,
    MAXIMO: 36.661014556884766,
    MEDIA: 35.976784415867016,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3cf0b74f-1962-4092-a6d7-83bcc12006c6',
    CIDADE: 'Jaupaci',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.876129150390625,
    MAXIMO: 32.902313232421875,
    MEDIA: 32.88922119140625,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8c48b198-351c-40df-b873-ea24fd83438a',
    CIDADE: 'Jesúpolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.31486129760742,
    MAXIMO: 33.95036697387695,
    MEDIA: 33.426237772189836,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9ed248d5-18be-4bf3-96ef-9c62a7b693ad',
    CIDADE: 'Joviânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.13124465942383,
    MAXIMO: 37.19427490234375,
    MEDIA: 36.60133763626954,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cc51d355-272a-41ec-babe-d771152cc275',
    CIDADE: 'Jussara',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.255266189575195,
    MAXIMO: 32.27312088012695,
    MEDIA: 31.814695358276367,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd4615eda-297c-4680-806f-c7e9e966d39a',
    CIDADE: 'Lagoa Santa',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.89317321777344,
    MAXIMO: 37.18256759643555,
    MEDIA: 37.03787040710449,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f95033a4-75e9-41a5-89ca-9cc498ba7da9',
    CIDADE: 'Leopoldo de Bulhões',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.09055709838867,
    MAXIMO: 36.08109664916992,
    MEDIA: 35.65800108819519,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b43574b0-c926-4314-a6d5-7a2e906ae11b',
    CIDADE: 'Luziânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.19864273071289,
    MAXIMO: 36.34950256347656,
    MEDIA: 35.60308424631754,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '90899a34-ccca-4c19-ad44-65f66ef53658',
    CIDADE: 'Mairipotaba',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.930335998535156,
    MAXIMO: 37.14329528808594,
    MEDIA: 36.39323088625734,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '59ec02f9-7a27-48a4-922f-dbddf8165eab',
    CIDADE: 'Mambaí',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.593835830688477,
    MAXIMO: 29.631546020507812,
    MEDIA: 29.610474904378254,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0be42647-a54a-4d9b-a916-9e6244fad284',
    CIDADE: 'Mara Rosa',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.95514488220215,
    MAXIMO: 30.013856887817383,
    MEDIA: 29.984635670979817,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3bc617af-bbd8-42cd-ab3b-a6f1e6afe07d',
    CIDADE: 'Marzagão',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 39.20553207397461,
    MAXIMO: 40.210811614990234,
    MEDIA: 39.94490170270509,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '71fd5c79-f124-4e53-9610-76bcc4921018',
    CIDADE: 'Matrinchã',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.23712921142578,
    MAXIMO: 31.49863052368164,
    MEDIA: 31.408101399739582,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '80370dac-3a68-4d84-b8a0-2babd7c4378c',
    CIDADE: 'Maurilândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.95527648925781,
    MAXIMO: 35.22870635986328,
    MEDIA: 35.10419145989429,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ed40a626-612d-491c-9d37-3bc85d3e4fc7',
    CIDADE: 'Mimoso de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.249727249145508,
    MAXIMO: 32.0970458984375,
    MEDIA: 31.781674067179363,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bc192807-9f91-4a82-9a36-449f3f56ddf8',
    CIDADE: 'Minaçu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.888935089111328,
    MAXIMO: 30.103261947631836,
    MEDIA: 29.97534942626953,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'babde04e-c23f-4047-a2d4-36f2d6e8a2c0',
    CIDADE: 'Mineiros',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.12278366088867,
    MAXIMO: 38.87660598754883,
    MEDIA: 36.85128258014547,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '36fbc149-f243-4176-959b-ca27cd81a128',
    CIDADE: 'Moiporá',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.123863220214844,
    MAXIMO: 33.503177642822266,
    MEDIA: 33.351793995526144,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd31f0e14-2239-40d5-a77f-6e2074dcb32d',
    CIDADE: 'Monte Alegre de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.924352645874023,
    MAXIMO: 30.164541244506836,
    MEDIA: 30.03065978156196,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '52ea953c-16a5-4943-a3cf-ba47a2c9e5e9',
    CIDADE: 'Montes Claros de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.763370513916016,
    MAXIMO: 32.59364318847656,
    MEDIA: 32.292730967203774,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '56323dce-e797-4214-8b9b-d90fbb5c6e1e',
    CIDADE: 'Montividiu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.12623596191406,
    MAXIMO: 34.723304748535156,
    MEDIA: 34.480892181396484,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86e86536-aa60-4a41-8329-76acce7946c0',
    CIDADE: 'Montividiu do Norte',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.087900161743164,
    MAXIMO: 30.239870071411133,
    MEDIA: 30.160696029663086,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cdc80691-8d63-48ed-8d16-3bdc394229c6',
    CIDADE: 'Morrinhos',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.14329528808594,
    MAXIMO: 38.75142288208008,
    MEDIA: 37.802542792426216,
    OFICIAL: 39,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'de36311c-dce1-4838-aa1a-1aa05e207e58',
    CIDADE: 'Morro Agudo de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.35945701599121,
    MAXIMO: 31.753196716308594,
    MEDIA: 31.49875901470412,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '92c74e1c-f2b3-46de-be68-59b9749b06c2',
    CIDADE: 'Mossâmedes',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.947410583496094,
    MAXIMO: 33.36750030517578,
    MEDIA: 33.15745544433594,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ee1875f2-3058-470f-9a49-8c5e495299eb',
    CIDADE: 'Mozarlândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.597562789916992,
    MAXIMO: 30.800785064697266,
    MEDIA: 30.71923713684082,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '690da46b-86df-4eea-b3a1-957c30396504',
    CIDADE: 'Mundo Novo',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.93411636352539,
    MAXIMO: 29.963289260864258,
    MEDIA: 29.94957817925347,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e7933a5c-c46a-465e-9c77-dd661a9864b6',
    CIDADE: 'Mutunópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.93682289123535,
    MAXIMO: 29.960853576660156,
    MEDIA: 29.948296070098877,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'bafbb3f0-b78b-435a-9b8d-e0e5592ebc1f',
    CIDADE: 'Nazário',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.17390823364258,
    MAXIMO: 34.6753044128418,
    MEDIA: 34.27754521077343,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7aac0d72-2c00-4b68-9c59-fb18b6d7b744',
    CIDADE: 'Nerópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.70623779296875,
    MAXIMO: 35.228919982910156,
    MEDIA: 34.8599103442079,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '94592a83-e86d-4a3a-a4d9-b97f28950980',
    CIDADE: 'Niquelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.88325309753418,
    MAXIMO: 31.350942611694336,
    MEDIA: 30.342233780891664,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86bf1097-c56a-47d1-bc59-321599784879',
    CIDADE: 'Nova América',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.828208923339844,
    MAXIMO: 31.13288116455078,
    MEDIA: 31.08313327443824,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ebbdb8d5-3c58-4eae-b15e-dd86b0a653e4',
    CIDADE: 'Nova Aurora',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 40.45053482055664,
    MAXIMO: 41.16131591796875,
    MEDIA: 40.805925369262695,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '5c348494-e42e-4222-ab49-ed7a7caf698e',
    CIDADE: 'Nova Crixás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.93785285949707,
    MAXIMO: 30.44171142578125,
    MEDIA: 30.117257981073287,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3a2419d4-965e-4f55-a87a-3d194963a66e',
    CIDADE: 'Nova Glória',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.19795799255371,
    MAXIMO: 31.277036666870117,
    MEDIA: 31.237497329711914,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd6826314-551f-412c-8643-9a942452119b',
    CIDADE: 'Nova Iguaçu de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.013856887817383,
    MAXIMO: 30.493528366088867,
    MEDIA: 30.161503285463162,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1843661e-02cc-4768-81a1-e8499a8bd2a7',
    CIDADE: 'Nova Roma',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.73419189453125,
    MAXIMO: 29.856178283691406,
    MEDIA: 29.781832695007324,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd025ca35-d8f1-42cc-ae66-92939a1db099',
    CIDADE: 'Nova Veneza',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.23705291748047,
    MAXIMO: 34.70623779296875,
    MEDIA: 34.67397155702124,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6256f060-bf3c-4637-8cc1-4141789b4c2d',
    CIDADE: 'Novo Brasil',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.603919982910156,
    MAXIMO: 32.67158889770508,
    MEDIA: 32.63775444030762,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a9cc9313-65e9-490a-8727-3fad8edaecdb',
    CIDADE: 'Novo Gama',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.19864273071289,
    MAXIMO: 35.443199157714844,
    MEDIA: 35.31748613337285,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8754afe5-248d-4537-aa39-b2900595caef',
    CIDADE: 'Novo Planalto',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.974342346191406,
    MAXIMO: 30.019060134887695,
    MEDIA: 29.993985493977863,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e26ffb64-6eec-45cc-bcbc-31e930226bb7',
    CIDADE: 'Orizona',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.50993347167969,
    MAXIMO: 37.42061996459961,
    MEDIA: 36.886603673299156,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '19974756-1307-487f-85af-440b73b10c69',
    CIDADE: 'Ouro Verde de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.19937515258789,
    MAXIMO: 34.81028747558594,
    MEDIA: 34.36357578274526,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd390dc87-3a97-45af-a335-ad985b180ded',
    CIDADE: 'Ouvidor',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 39.37980270385742,
    MAXIMO: 40.09074401855469,
    MEDIA: 39.735273361206055,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1597c5be-1cd7-41bb-a3e0-4e07206062a2',
    CIDADE: 'Padre Bernardo',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.94265365600586,
    MAXIMO: 35.2537956237793,
    MEDIA: 34.15645429823134,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '12b144ac-3e85-4aa3-90c3-e697d058beef',
    CIDADE: 'Palestina de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.392723083496094,
    MAXIMO: 33.72257995605469,
    MEDIA: 33.60197734832764,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '69ae7dc3-6d60-4a29-880e-0e11a8f7859c',
    CIDADE: 'Palmeiras de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.277706146240234,
    MAXIMO: 34.969383239746094,
    MEDIA: 34.614919662475586,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'af10409d-bdf1-4f86-a658-1d70f25dc54c',
    CIDADE: 'Palmelo',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.34996032714844,
    MAXIMO: 37.90886306762695,
    MEDIA: 37.71796581562528,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b6ce656a-0e51-45cc-a923-6f9443a377d2',
    CIDADE: 'Palminópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.09821701049805,
    MAXIMO: 34.53561782836914,
    MEDIA: 34.332328385504745,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '234563bc-489d-4f38-8d48-316d1708924a',
    CIDADE: 'Panamá',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.385215759277344,
    MAXIMO: 38.10246658325195,
    MEDIA: 37.74384117126465,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a3a8df3c-56b1-4703-9a13-3d1c707b457e',
    CIDADE: 'Paranaiguara',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.26190185546875,
    MAXIMO: 35.59345245361328,
    MEDIA: 35.411513010660805,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a3cb48d6-fb39-4fcf-9142-300db12bd9e7',
    CIDADE: 'Paraúna',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.90275955200195,
    MAXIMO: 34.6283073425293,
    MEDIA: 34.27992734042081,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3ed9f76e-9554-4774-b829-541d1674be10',
    CIDADE: 'Perolândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.837711334228516,
    MAXIMO: 36.03507614135742,
    MEDIA: 35.919026692708336,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '249f1ed3-1cec-4030-b637-0b193f25f597',
    CIDADE: 'Petrolina de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.730255126953125,
    MAXIMO: 34.460899353027344,
    MEDIA: 33.97928630498425,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8008ebf5-7bb6-4d8a-b013-a0e8005afe38',
    CIDADE: 'Pilar de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.255664825439453,
    MAXIMO: 30.65449333190918,
    MEDIA: 30.457300821940105,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '45a066bc-949c-4b41-902a-4cf67fd88639',
    CIDADE: 'Piracanjuba',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.864051818847656,
    MAXIMO: 38.17471694946289,
    MEDIA: 37.436102549235024,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e132efbf-a7c0-4c1a-83df-93adb5bd85af',
    CIDADE: 'Piranhas',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.85661697387695,
    MAXIMO: 33.567073822021484,
    MEDIA: 33.224420819963726,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1bcc3bf0-b05e-41e6-a855-ef8f314a0eb5',
    CIDADE: 'Pirenópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 32.934391021728516,
    MAXIMO: 34.347286224365234,
    MEDIA: 33.70685958862305,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1bda005c-7d44-4186-9678-6bd4ce9062ed',
    CIDADE: 'Pires do Rio',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.07663345336914,
    MAXIMO: 38.46902847290039,
    MEDIA: 37.714569091796875,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '78025e71-ca3d-44ef-919a-08f7eb04d42b',
    CIDADE: 'Planaltina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.27309226989746,
    MAXIMO: 34.08113479614258,
    MEDIA: 32.92431776864188,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '3fe9576a-eedd-49ef-84e2-24e4803aa5c8',
    CIDADE: 'Pontalina',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.172096252441406,
    MAXIMO: 36.805458068847656,
    MEDIA: 36.48757553100586,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'f8a52f55-8913-46bf-83be-97baabeccedc',
    CIDADE: 'Porangatu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.935415267944336,
    MAXIMO: 30.265262603759766,
    MEDIA: 30.058621215820313,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'cce87d81-6a0d-48ff-acb1-ca09037cf71b',
    CIDADE: 'Porteirão',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.19035720825195,
    MAXIMO: 35.824607849121094,
    MEDIA: 35.41095528111547,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'dd94d836-6ec0-4431-92b0-d2ef9a2ecae3',
    CIDADE: 'Portelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.05489730834961,
    MAXIMO: 36.23240280151367,
    MEDIA: 36.14365005493164,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a3579486-20ae-43ae-a19d-21af8e4c1a18',
    CIDADE: 'Posse',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.62877655029297,
    MAXIMO: 29.74320411682129,
    MEDIA: 29.69227472941081,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9aa0c55e-dee5-49a2-9426-350881d88e0d',
    CIDADE: 'Professor Jamil',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.249244689941406,
    MAXIMO: 37.16257858276367,
    MEDIA: 36.70507160586384,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '9e4e93ee-fc58-421e-83fc-3a3b97d88e05',
    CIDADE: 'Quirinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.016090393066406,
    MAXIMO: 35.52875518798828,
    MEDIA: 35.20409838358561,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '529554c6-db1d-4e73-b452-695fe476107b',
    CIDADE: 'Rialma',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.59674835205078,
    MAXIMO: 32.100406646728516,
    MEDIA: 31.870174655152113,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'a6d463c1-9cfd-41ef-9364-b813023b793b',
    CIDADE: 'Rianápolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.9666748046875,
    MAXIMO: 32.69782638549805,
    MEDIA: 32.17304249816299,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '80ae34d0-e6d8-4941-bdcd-b963e41dc38f',
    CIDADE: 'Rio Quente',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 38.67230987548828,
    MAXIMO: 39.53839111328125,
    MEDIA: 39.07357639937648,
    OFICIAL: 40,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4f8f7b2e-86d1-42c9-b0b9-715c54a52950',
    CIDADE: 'Rio Verde',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.339515686035156,
    MAXIMO: 35.42463684082031,
    MEDIA: 34.94913640515558,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '89178b87-342b-42a7-9f93-a6e47e632b4e',
    CIDADE: 'Rubiataba',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.13288116455078,
    MAXIMO: 31.50290298461914,
    MEDIA: 31.353644053141277,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '83c8b04b-86ab-4a12-90f1-c619920e1a17',
    CIDADE: 'Sanclerlândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.12446212768555,
    MAXIMO: 33.231021881103516,
    MEDIA: 33.17774200439453,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4c2d4d99-f6cd-42fe-be92-554314513f8d',
    CIDADE: 'Santa Bárbara de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.35613250732422,
    MAXIMO: 34.90978240966797,
    MEDIA: 34.54013701845479,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e4defe76-38ad-45b9-8b22-e46f83dd1ed5',
    CIDADE: 'Santa Cruz de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.34996032714844,
    MAXIMO: 37.90886306762695,
    MEDIA: 37.71273326873779,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '43d2944f-31ed-4e20-8254-5f8784b35c2c',
    CIDADE: 'Santa Fé de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.75859260559082,
    MAXIMO: 31.982501983642578,
    MEDIA: 31.90767542521159,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'd5ff4f00-488c-445b-a863-d0bd634f1d16',
    CIDADE: 'Santa Helena de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.855960845947266,
    MAXIMO: 35.04826736450195,
    MEDIA: 34.94253412882487,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1f45a6f0-2fe0-43ea-82d4-6474f84a7c61',
    CIDADE: 'Santa Isabel',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.70987892150879,
    MAXIMO: 32.100406646728516,
    MEDIA: 31.905142784118652,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ce3dae5e-fec0-4f35-a0b8-94256266b46e',
    CIDADE: 'Santa Rita do Araguaia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.71827697753906,
    MAXIMO: 37.16823959350586,
    MEDIA: 36.44223213195801,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '86e1162e-95c9-4786-9ba7-46f70cac718b',
    CIDADE: 'Santa Rita do Novo Destino',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.792177200317383,
    MAXIMO: 31.501312255859375,
    MEDIA: 31.04795773824056,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '479129bf-1dd2-441a-ac41-5ba54c7509d5',
    CIDADE: 'Santa Rosa de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.11903762817383,
    MAXIMO: 34.011474609375,
    MEDIA: 33.713578471160595,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0feed9ce-d04f-454b-bc54-560498eaad02',
    CIDADE: 'Santa Tereza de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.966535568237305,
    MAXIMO: 29.989898681640625,
    MEDIA: 29.978217124938965,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0c2ba20e-b798-40df-92ff-a301340bd760',
    CIDADE: 'Santa Terezinha de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.013227462768555,
    MAXIMO: 30.438541412353516,
    MEDIA: 30.200589656829834,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8d3661f8-eab9-479d-9de7-5e3ae85642ba',
    CIDADE: 'Santo Antônio da Barra',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.70576477050781,
    MAXIMO: 34.76732635498047,
    MEDIA: 34.73654556274414,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '6504f6a8-2e1d-4db9-abdb-84cc368328f8',
    CIDADE: 'Santo Antônio de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.70623779296875,
    MAXIMO: 34.920005798339844,
    MEDIA: 34.81737185328905,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '34687bad-5858-423c-8afd-cd5e2cc2d1b9',
    CIDADE: 'Santo Antônio do Descoberto',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.04532241821289,
    MAXIMO: 35.308189392089844,
    MEDIA: 35.19542694091797,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '33d6af90-8f6d-4664-aacd-09691404a3e2',
    CIDADE: 'São Domingos',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.793066024780273,
    MAXIMO: 30.17985725402832,
    MEDIA: 29.949740886688232,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c5d6e167-64d4-4fdc-8536-7030b5732a19',
    CIDADE: 'São Francisco de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.128902435302734,
    MAXIMO: 34.19937515258789,
    MEDIA: 33.7171655870319,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '50022e70-2e62-41da-9436-2fea15ff63e8',
    CIDADE: "São João d'Aliança",
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.727092742919922,
    MAXIMO: 30.013273239135742,
    MEDIA: 29.872714360555012,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2ed30907-3729-40a0-b43c-2b4d2e093aba',
    CIDADE: 'São João da Paraúna',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.954410552978516,
    MAXIMO: 34.351959228515625,
    MEDIA: 34.148477652860834,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8e19d8da-0f14-4e27-8bc0-33c42f5e6e4d',
    CIDADE: 'São Luís de Montes Belos',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.35332489013672,
    MAXIMO: 33.604366302490234,
    MEDIA: 33.47803243001302,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'db754fff-d2ca-4f02-a23b-943d74ffc7e7',
    CIDADE: 'São Luiz do Norte',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.047840118408203,
    MAXIMO: 31.11998748779297,
    MEDIA: 31.083913803100586,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '16afebab-a853-4490-91a3-11a54ce656d5',
    CIDADE: 'São Miguel do Araguaia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.943927764892578,
    MAXIMO: 30.221633911132812,
    MEDIA: 29.997534847259523,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '03c0b1ab-d1b8-42a5-8651-08334c2bb304',
    CIDADE: 'São Miguel do Passa Quatro',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.472843170166016,
    MAXIMO: 36.943214416503906,
    MEDIA: 36.70802879333496,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'ea490c6f-6adf-4146-90ed-825fd35d9e15',
    CIDADE: 'São Patrício',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.425148010253906,
    MAXIMO: 31.85163116455078,
    MEDIA: 31.74705780865253,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '8fce2e15-37a0-4663-a4f6-04d22f2fd4bb',
    CIDADE: 'São Simão',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.17229461669922,
    MAXIMO: 35.74396514892578,
    MEDIA: 35.535190356040005,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '25e28e35-d025-477a-a2d4-6004a09f0214',
    CIDADE: 'Senador Canedo',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.228919982910156,
    MAXIMO: 36.128055572509766,
    MEDIA: 35.758416275511905,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '1ea061b3-4c70-43e3-a045-8b7ee6fe4664',
    CIDADE: 'Serranópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.60222625732422,
    MAXIMO: 37.76133728027344,
    MEDIA: 37.17076725429959,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b3e366b9-8e8d-412a-b463-4d9e752ba080',
    CIDADE: 'Silvânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.37412643432617,
    MAXIMO: 36.5018310546875,
    MEDIA: 35.89655256271362,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '055efedf-1225-465c-a1bc-8fbfbaa122b1',
    CIDADE: 'Simolândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.645689010620117,
    MAXIMO: 29.68584632873535,
    MEDIA: 29.665767669677734,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'fe4be2e5-ebae-46da-a96d-6b48ccb1dbf3',
    CIDADE: "Sítio d'Abadia",
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.616090774536133,
    MAXIMO: 29.645593643188477,
    MEDIA: 29.62908058166504,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '25a8b2ca-5e12-47a6-b756-dedd6b724f00',
    CIDADE: 'Taquaral de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.11903762817383,
    MAXIMO: 33.730255126953125,
    MEDIA: 33.50643784861557,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '43ab2e74-24fa-4ddc-ab06-eb1034da794c',
    CIDADE: 'Teresina de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.792882919311523,
    MAXIMO: 29.920181274414062,
    MEDIA: 29.855708440144856,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'e3c4e505-68e7-4ede-b617-a4ce30b9f60b',
    CIDADE: 'Terezópolis de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.81028747558594,
    MAXIMO: 35.228919982910156,
    MEDIA: 34.940175762132,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '874aa156-3afd-4208-a4a8-9a18e6644f85',
    CIDADE: 'Três Ranchos',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 39.83658981323242,
    MAXIMO: 41.384132385253906,
    MEDIA: 40.48370729762745,
    OFICIAL: 41,
    ISOPLETA: 4,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'af471d71-af53-4996-960d-6ebb0a943823',
    CIDADE: 'Trindade',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.80634689331055,
    MAXIMO: 34.90978240966797,
    MEDIA: 34.85806465148926,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'b2674f6a-8e7d-42f6-980a-3ed0160153c3',
    CIDADE: 'Trombas',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.026960372924805,
    MAXIMO: 30.07660484313965,
    MEDIA: 30.045413970947266,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '0e0e088a-4838-4cc2-98ff-ae8df808cb5f',
    CIDADE: 'Turvânia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 33.47640609741211,
    MAXIMO: 34.277706146240234,
    MEDIA: 33.98526799172964,
    OFICIAL: 34,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '4bccf8ad-6de6-4281-81fb-4074135e80a7',
    CIDADE: 'Turvelândia',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.99117660522461,
    MAXIMO: 35.22870635986328,
    MEDIA: 35.15946102142334,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: 'c6ac474a-1500-4ca5-a4d7-590e3780bdaa',
    CIDADE: 'Uirapuru',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.002368927001953,
    MAXIMO: 30.105207443237305,
    MEDIA: 30.040660858154297,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '507591dd-4802-4d1b-a668-98a61f4690ad',
    CIDADE: 'Uruaçu',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 30.050447463989258,
    MAXIMO: 30.538057327270508,
    MEDIA: 30.27181543622698,
    OFICIAL: 31,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2d92fd59-0029-474f-b522-d54ab8f46941',
    CIDADE: 'Uruana',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.9666748046875,
    MAXIMO: 32.32728958129883,
    MEDIA: 32.146982192993164,
    OFICIAL: 32,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '336b8305-e977-4dde-9649-0d010c17691f',
    CIDADE: 'Urutaí',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 37.906341552734375,
    MAXIMO: 38.460792541503906,
    MEDIA: 38.18356704711914,
    OFICIAL: 38,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '2e6d9529-55b1-498a-b44d-63ac1dfb59b1',
    CIDADE: 'Valparaíso de Goiás',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.443199157714844,
    MAXIMO: 35.443199157714844,
    MEDIA: 35.443199157714844,
    OFICIAL: 35,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '29c3f870-51e3-4ae3-9fe0-f1bd95e8f608',
    CIDADE: 'Varjão',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 34.969383239746094,
    MAXIMO: 36.24201965332031,
    MEDIA: 35.68919360141142,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '7db4fefc-e6ec-445a-8b2e-b272df26220e',
    CIDADE: 'Vianópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 36.18470001220703,
    MAXIMO: 36.61627960205078,
    MEDIA: 36.331170399983726,
    OFICIAL: 37,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '905198f9-149f-4f3e-8855-4fd3938d6bf4',
    CIDADE: 'Vicentinópolis',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 35.76461410522461,
    MAXIMO: 36.13124465942383,
    MEDIA: 35.94792938232422,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '799f62ad-b4f2-4e9a-8a20-aab090507436',
    CIDADE: 'Vila Boa',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 29.896442413330078,
    MAXIMO: 30.37671661376953,
    MEDIA: 30.122735500335693,
    OFICIAL: 30,
    ISOPLETA: 1,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '07cff2f0-a319-4d3e-bb36-00d6bd1f27cc',
    CIDADE: 'Vila Propício',
    SIGLA: 'GO',
    ESTADO: 'Goiás',
    MINIMO: 31.29172134399414,
    MAXIMO: 33.028255462646484,
    MEDIA: 32.29983139038086,
    OFICIAL: 33,
    ISOPLETA: 2,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '17,00%',
  },
  {
    id: '17b25288-d00e-4a28-ae65-e64db3686b59',
    CIDADE: 'Brasília',
    SIGLA: 'DF',
    ESTADO: 'Distrito Federal',
    MINIMO: 34.929447174072266,
    MAXIMO: 35.67753601074219,
    MEDIA: 35.43878722190857,
    OFICIAL: 36,
    ISOPLETA: 3,
    ICMSCONTR: '7,00%',
    ICMSNCONTR: '18,00%',
  },
];

export default tabelaEstados;
