import React from "react";
import styles from "./style.module.scss";
import Input from "../Inputs";
const ModalRepresentante = ({ dataModal, onClose }) => {
  const { telefone, celular, email, endereco, nome_contato, cnpj, inscricao_estadual } =
    dataModal;

  return (
    <div className={styles.modalBackdrop} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.headerModal}>
          <h1>Dados do representante</h1>
          <div className={styles.containerButtons}>
            <button className={styles.closeButton} onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
        <div className={styles.bodyModal}>
          <div className={styles.rowBodyModal}>
            <Input label="Representante" placeHolder="" color="laranja" onChange={null} value={nome_contato} disable={true} />
            <Input label="Documentos" placeHolder="" color="laranja" onChange={null} value={cnpj} disable={true} />
          </div>
          <div className={styles.rowBodyModal}>
            <Input label="Telefone" placeHolder="" color="laranja" onChange={null} value={telefone} disable={true} />
            <Input label="Celular" placeHolder="" color="laranja" onChange={null} value={celular} disable={true} />
            <Input label="E-mail" placeHolder="" color="laranja" onChange={null} value={email} disable={true} />
          </div>
          <div className={styles.rowBodyModal}>
            <Input label="Logradouro" placeHolder="" color="laranja" onChange={null} value={endereco.logradouro} disable={true} />
            <Input label="Número" placeHolder="" color="laranja" onChange={null} value={endereco.numero} disable={true} /> 
            <Input label="Complemento" placeHolder="" color="laranja" onChange={null} value={endereco.complemento} disable={true} /> 
            <Input label="Bairro" placeHolder="" color="laranja" onChange={null} value={endereco.bairro} disable={true} /> 
          </div> 
          <div className={styles.rowBodyModal}>
            <Input label="CEP" placeHolder="" color="laranja" onChange={null} value={endereco.cep} disable={true} />
            <Input label="Cidade" placeHolder="" color="laranja" onChange={null} value={endereco.cidade} disable={true} /> 
            <Input label="Estado" placeHolder="" color="laranja" onChange={null} value={endereco.estado} disable={true} /> 
          </div> 
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModalRepresentante);