import React, { useMemo } from 'react';
import { Select } from 'antd';
import styles from './style.module.scss';

const MultipleSelectClientes = ({ options, handleSelectCliente, selectedValue, isRepresentante = false }) => {

  const selectOptions = useMemo(() => (
    (options || []).map((item) => ({
      value: item?.id,
      label: !isRepresentante ?  item?.nome_cliente : item?.nome_contato
    }))
  ), [options]);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const filterSort = (optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());

  return (
    <div className={styles.container}>
      <Select
        showSearch
        className={styles.selectOption}
        placeholder="Procure para selecionar"
        optionFilterProp="children"
        filterOption={filterOption}
        filterSort={filterSort}
        options={selectOptions}
        onChange={handleSelectCliente}
        value={selectedValue}
      />
    </div>
  );
};

export default MultipleSelectClientes;