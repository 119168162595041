// menuData.js
import { useAuth } from './contexts/AuthContext';
import { RxDashboard } from 'react-icons/rx';
import { BsClipboardPlus, BsBox, BsPerson } from 'react-icons/bs';
import { IoClipboardOutline } from 'react-icons/io5';

export const useMenuData = () => {
  const { user } = useAuth();

  const menuData = [
    {
      key: 'dashboard',
      icon: <RxDashboard style={{ fontSize: '24px' }} />,
      title: 'Dashboard',
      to: '/',
      isVisible: true,
      pageTitle: 'Dashboard',
    },
    {
      key: 'orcamentos',
      icon: <BsClipboardPlus style={{ fontSize: '24px' }} />,
      title: 'Orçamento',
      to: '/orcamento',
      isVisible: true,
      pageTitle: 'Orçamento',
    },
    {
      key: 'cadastro',
      icon: <IoClipboardOutline style={{ fontSize: '24px' }} />,
      title: 'Cadastro',
      isVisible: true,
      pageTitle: 'Cadastros',
      children: [
        { key: 'cadastroCliente', title: 'Clientes', to: '/cadastro/cliente', isVisible: true },
        { key: 'cadastroRepresentante', title: 'Representante', to: '/cadastro/representante', isVisible: true },
        { key: 'cadastroProdutos', title: 'Produtos', to: '/cadastro/produtos', isVisible: true },
      ],
    },
    {
      key: 'bases',
      icon: <BsBox style={{ fontSize: '24px' }} />,
      title: 'Bases',
      isVisible: true,
      pageTitle: 'Base Produtos',
      children: [
        { key: 'baseProdutos', title: 'Produtos', to: '/base/produtos', isVisible: true },
        { key: 'baseClientes', title: 'Clientes', to: '/base/clientes', isVisible: true },
        { key: 'baseRepresentantes', title: 'Representantes', to: '/base/representantes', isVisible: true },
      ],
    },
    {
      key: 'usuarios',
      icon: <BsPerson style={{ fontSize: '24px' }} />,
      title: 'Usuário',
      to: '/usuarios',
      isVisible: true,
      pageTitle: 'Usuário',
    },
  ];

  return menuData;
};
