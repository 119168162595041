import React from "react";
import styles from "./style.module.scss";

const ButtonConcluir = ({ label, onClickFunction, isLoading = false }) => {
  return (
    <button className={styles["button-finish"]} onClick={onClickFunction} disabled={isLoading}>
      {isLoading ? (
        <div className={styles.loading}></div>
      ) : (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M3.5 0C2.70435 0 1.94129 0.316071 1.37868 0.87868C0.816071 1.44129 0.5 2.20435 0.5 3L0.5 21C0.5 21.7956 0.816071 22.5587 1.37868 23.1213C1.94129 23.6839 2.70435 24 3.5 24H21.5C22.2956 24 23.0587 23.6839 23.6213 23.1213C24.1839 22.5587 24.5 21.7956 24.5 21V3C24.5 2.20435 24.1839 1.44129 23.6213 0.87868C23.0587 0.316071 22.2956 0 21.5 0L3.5 0ZM18.545 7.455C18.7533 7.66313 18.8718 7.94465 18.8749 8.23913C18.8779 8.53361 18.7654 8.81755 18.5615 9.03L12.5735 16.515C12.4706 16.6258 12.3464 16.7148 12.2083 16.7766C12.0702 16.8383 11.9211 16.8716 11.7699 16.8744C11.6186 16.8772 11.4684 16.8494 11.3281 16.7928C11.1879 16.7363 11.0604 16.652 10.9535 16.545L6.986 12.576C6.87547 12.473 6.78682 12.3488 6.72533 12.2108C6.66384 12.0728 6.63078 11.9238 6.62811 11.7728C6.62545 11.6217 6.65323 11.4717 6.70982 11.3316C6.7664 11.1915 6.85061 11.0643 6.95744 10.9574C7.06427 10.8506 7.19152 10.7664 7.3316 10.7098C7.47169 10.6532 7.62173 10.6254 7.77278 10.6281C7.92384 10.6308 8.07281 10.6638 8.21081 10.7253C8.34881 10.7868 8.47301 10.8755 8.576 10.986L11.717 14.1255L16.9265 7.488C17.0292 7.37695 17.1533 7.28776 17.2912 7.22575C17.4292 7.16375 17.5783 7.1302 17.7295 7.12712C17.8807 7.12404 18.031 7.15149 18.1714 7.20782C18.3118 7.26416 18.4394 7.34823 18.5465 7.455H18.545Z"
              fill="white"
            />
          </svg>
          {label}
        </>
      )}
    </button>
  );
};

export default ButtonConcluir;
