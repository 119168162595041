import React, { useState } from 'react';
import styles from './style.module.scss';
import Input from '../../Components/Inputs';
import ButtonConcluir from '../../Components/ButtonConcluir';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';

const Representante = () => {
  const [codigo, setCodigo] = useState('');
  const [representante, setRepresentante] = useState('');
  const [foneFixo, setFoneFixo] = useState('');
  const [nomeContato, setNomeContat] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [uf, setUf] = useState('');
  const [cnpj, setCnpj] = useState('');

  const [endereco, setEndereco] = useState('');
  const [cep, setCep] = useState('');
  const [numero, setNumero] = useState('');
  const [cidade, setCidade] = useState('');

  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCadastro = async () => {
    setLoading(true);
    const data = {
      telefone: foneFixo,
      celular: celular,
      nome_contato: representante,
      email: email,
      uf: uf,
      cnpj: cnpj,
      logradouro: endereco,
      cep: cep,
      bairro: bairro,
      cidade: cidade,
      numero: numero,
      complemento: complemento === '' ? ' ' : complemento,
    };
    for (let key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        toast.warn(`O campo ${key} é inválido!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setLoading(false);
        return;
      }
    }
    const response = await api.post('/representantes', data);

    if (response.status === 200) {
      toast.success(`Representante adicionado com sucesso!!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);

      setRepresentante('');
      setFoneFixo('');
      setCelular('');
      setEmail('');
      setUf('');
      setCnpj('');
      setEndereco('');
      setCep('');
      setNumero('');
      setCidade('');
      setBairro('');
      setComplemento('');
    } else {
      toast.warn(`Não foi possível adicionar representante confirme os dados`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
    }

    setLoading(false);
    console.log('response', response);
  };

  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <div className={styles.contato}>
          <h1>Contato</h1>
          <div className={styles['first-row']}>
            <Input label='Representante' placeHolder='Digite o Representante' color='laranja' onChange={setRepresentante} value={representante} />
            <Input label='Fone fixo' type='telefone' color='laranja' onChange={setFoneFixo} value={foneFixo} />
          </div>
          <div className={styles['first-row']}>
            <Input label='Celular' type='celular' color='laranja' onChange={setCelular} value={celular} />
            <Input label='E-mail' type='email ' placeHolder='Digite o E-mail' color='laranja' onChange={setEmail} value={email} />
          </div>
          <div className={styles['first-row']}>
            <Input label='UF' placeHolder='Digite o UF' color='laranja' onChange={setUf} value={uf} />
            <Input label='CNPJ' type='cnpj_cpf' color='laranja' onChange={setCnpj} value={cnpj} />
          </div>
          <div className={styles['first-row']}>
            <Input label='Endereço' placeHolder='Digite o Endereço' color='laranja' onChange={setEndereco} value={endereco} />
            <Input label='Bairro' placeHolder='Digite o Bairro' color='laranja' onChange={setBairro} value={bairro} />
          </div>
          <div className={styles['first-row']}>
            <Input label='Cidade' placeHolder='Digite a Cidade' color='laranja' onChange={setCidade} value={cidade} />
            <Input label='CEP' type='cep' color='laranja' onChange={setCep} value={cep} />
          </div>
          <div className={styles['first-row']}>
            <Input label='Número' placeHolder='Digite o Número' color='laranja' onChange={setNumero} value={numero} />
            <Input label='Complemento' placeHolder='Caso não houver deixar em branco' color='laranja' onChange={setComplemento} value={complemento} />
          </div>
        </div>
        <ButtonConcluir label='Concluir cadastro' onClickFunction={handleCadastro} isLoading={loading} />
      </div>
    </div>
  );
};

export default Representante;
