import React, { useMemo } from 'react';
import styles from './style.module.scss';
import { Select } from 'antd';

const MultipleSelectRepresentante = ({ options, handleSelectEstado, selectedValue, disabled, selectedEstadoId }) => {
  console.log('options', options)
  const selectOptions = useMemo(
    () =>
      options.map(
        ({ id, nome_contato }) => (
          {
            value: id,
            label: `${nome_contato}`,
          }
        )
      ),
    [options]
  );

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const filterSort = (optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());

  return (
    <div className={styles.container}>
      <Select
        showSearch
        className={styles.selectOption}
        placeholder='Procure para selecionar'
        optionFilterProp='children'
        filterOption={filterOption}
        filterSort={filterSort}
        options={selectOptions}
        onChange={handleSelectEstado}
        value={selectedValue || selectedEstadoId}
        disabled={disabled}
      />
    </div>
  );
};

export default MultipleSelectRepresentante;