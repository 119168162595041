import React from 'react';
import { Layout, Menu, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logosidebar from '../../assets/logosidebar.png';
import styles from './style.module.scss';
import { useMenuData } from '../../menuData';
import { CiLogin } from 'react-icons/ci';

const { Sider } = Layout;

const SidebarOfficial = ({ collapsed, toggleCollapsed }) => {
  const { user, logout, isAuthenticated } = useAuth();
  const menuData = useMenuData(); // Usando o hook customizado para obter os dados do menu
  const location = useLocation(); // hook do react-router-dom

  const currentPageTitle = menuData.find((item) => item.to === location.pathname)?.pageTitle || 'Tessa';

  const handleLogout = () => {
    logout(); // Lógica de logout
  };

  const renderMenuItem = (item) => (
    <Menu.Item key={item.key} icon={item.icon} className={styles.leftAlign}>
      {item.to ? <Link to={item.to}>{item.title}</Link> : item.title}
    </Menu.Item>
  );

  const renderSubMenu = (submenu) => {
    const visibleChildren = submenu.children.filter((child) => {
      return child.isVisible ;
    });

    if (!submenu.isVisible || visibleChildren.length === 0) {
      return null;
    }

    return (
      <Menu.SubMenu key={submenu.key} icon={submenu.icon} title={submenu.title} className={styles.leftAlign}>
        {visibleChildren.map((child) => renderMenuItem(child))}
      </Menu.SubMenu>
    );
  };

  const renderMenuItems = () => {
    const filteredItems = menuData.filter((item) => {
      return item.isVisible;
    });
    
    return filteredItems.flatMap((item) => {
      const menuItem = item.children ? renderSubMenu(item) : renderMenuItem(item);
      return menuItem;
    });
  };

  return (
    <Sider width={256} collapsible reverseArrow={false} collapsed={collapsed} onCollapse={toggleCollapsed} breakpoint='md' collapsedWidth='0' theme='light' >
      <div className={styles.sidebar}>
        <div>
          <div className={styles.containerLogo}>
            <img src={logosidebar} alt='logo tessa' />
          </div>
          <Menu inlineCollapsed={true} theme='light' mode='inline' defaultSelectedKeys={['dashboard']} style={{ borderRight: 0, color: '#3E3E3D', backgroundColor: '#D67D32', padding: '1.5rem' }}>
            {renderMenuItems()}
          </Menu>
        </div>
        <div className={styles.sidebarFooter}>
          <Button icon={<UserOutlined style={{ fontSize: ' 24px' }} />} type='text' onClick={handleLogout} className={styles.buttonAuth}>
            Sair da conta
          </Button>
        </div>
      </div>
    </Sider>
  );
};

export default SidebarOfficial;
