import React from 'react';
import ReactDOM from 'react-dom';
import styles from './style.module.scss';
import Input from '../Inputs';
import InputMask from 'react-input-mask';

const ModalParameters = ({ onClose, distanciaEntrePlacas, setDistanciaEntrePlacas, valorFrete, setValorFrete, mostrarValoresProdutos, setMostrarValoresProdutos }) => {

  return ReactDOM.createPortal(
    <div className={styles.modalBackdrop} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.containerButtons}>
          <h1>Adicione seus parametros aqui</h1>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
          <div className={styles.inputDistanciaEntreModulos}>
            <label>Distância entre módulos</label>
            <InputMask
              mask=''
              placeholder='Digite a distancia entre modulos'
              value={distanciaEntrePlacas}
              onChange={(e) => setDistanciaEntrePlacas(e.target.value)}
              className={styles[`input-laranja`]}
            />
          </div>
        <div className={styles.inputContainer}>
          <Input label='Custo Frete' type='custo' placeHolder='Digite o Custo do frete' color='laranja' onChange={setValorFrete} value={valorFrete} />
          <div>
            <input type='checkbox' id='mostrar' className={styles.customCheckbox} checked={mostrarValoresProdutos} onChange={() => setMostrarValoresProdutos(!mostrarValoresProdutos)} />
            <label htmlFor='mostrar' className={styles.customCheckboxLabel}>
              Mostrrar itens
            </label>
          </div>
        </div>
        <div className={styles.inputContainer}>
        </div>

        {/* <Input label='Distancia entre módulos' type='limite' limit={4} color='laranja' onChange={setDistanciaEntrePlacas} value={distanciaEntrePlacas} /> */}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default React.memo(ModalParameters);
